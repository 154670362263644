import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  public goToLanding() {
    window.location.href = environment && environment.landingUrl;
  }
}
