<div class="project-switch" [class.settings]="isSettings">
  <div class="project-select"
       *ngIf="projects && projects.length === 0">
    {{ currentProjectName | unescape }}
  </div>
  <div dropdown class="dropdown" *ngIf="projects && projects.length > 0" (click)="handleSwitch()">
    <button class="btn btn-transparent no-hover project-select dropdown-toggle"
            id="projectDropdown"
            dropdownToggle>
      <span class="new-message" *ngIf="hasNewMessages"></span>{{ currentProjectName | unescape }}
    </button>
    <div *dropdownMenu class="dropdown-menu" aria-labelledby="projectDropdown">
      <a [href]="getLink(project)" (click)="clickToLink($event, project)" class="dropdown-item" *ngFor="let project of projects;">
        <button type="button" [innerHTML]="project.saniatzedName">
        </button>
        <span class="unread" [hidden]="!project.unreadCount" >
          {{ project.unreadCount }}
        </span>
        <!--<button class="shape" (click)="openProject(project);"></button>-->
      </a>
    </div>
  </div>
</div>
