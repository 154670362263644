import { PermissionsService } from '@app/core/permissions.service';
import {
  of, BehaviorSubject, Observable, Subject, Subscription,
} from 'rxjs';
import { Injectable, Optional } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
import { TariffService } from '@app/core/tariff.service';
import { ChannelService } from '@app/core/channel.service';
import { OperatorService } from '@app/core/operator.service';
import { GroupService } from '@app/core/group.service';
import { AnswerPatternService } from '@app/core/answer-pattern.service';
import { Router } from '@angular/router';
import { UserService } from '@app/core/user.service';
import { ProjectService } from '@app/core/project.service';
import { CrmService } from '@app/core/crm.service';

export const NAME_SPACES = {
  widget: 'onlineChat',
  chat: 'onlineChat',
  'messengers-button': 'messengersButton',
  'telegram-bot': 'telegramBot',
  avito: 'avito',
  mail: 'email',
  whatsapp_teletype: 'whatsapp',
  whatsapp_edna: 'whatsapp-edna',
  instagram_comment: 'instagramComments',
  instagram_comments: 'instagramComments',
  instagram_direct: 'instagramDirect',
  vk_direct: 'vkDirect',
  'instagram-comments-business': 'instagramCommentsBusiness',
  instagram_comments_business: 'instagramCommentsBusiness',
  'instagram-direct-business': 'instagramDirectBusiness',
  instagram_direct_business: 'instagramDirectBusiness',
};

export const BINARY_FEATURE = [
  'statistic',
  'messagesTemplates',
  'amoCrm',
  'telegramNotifier',
  'publicApi',
  'brandingLink',
  'bitrix24',
  'roistat',
  'whatsappFree',
];

/** по факту это сервис для определения доступных фич исходя их тарифов */
@Injectable({
  providedIn: 'root',
})
export class FeatureAccessService {
  public masterOperator: boolean;
  public isOperatorRole: boolean;

  public balance: number;
  public dailyPayment: number;
  public availableDays: number;
  public allowOperatorsId = [];
  public allowChannelsId = [];

  private _declinePopup: boolean;
  private subjectDeclinePopup = new BehaviorSubject<Boolean>(undefined);
  private subjectIsOperator = new BehaviorSubject<Boolean>(undefined);
  private subjectIsAllowOperator = new BehaviorSubject<Boolean>(undefined);
  private subjectOperators = new BehaviorSubject<any>(undefined);
  private subjectAllowChannels = new BehaviorSubject<any>(undefined);
  private unsub$ = new Subject<void>();
  private tariffs: any;
  private operatorsLoaded: boolean;
  private operators: Array<any>;
  private channels: Array<any>;
  private paid: boolean;
  private active: boolean;
  private user: any;
  private projects: any;

  constructor(
    private tariffService: TariffService,
    @Optional() private channelService: ChannelService,
    private operatorService: OperatorService,
    private groupService: GroupService,
    private answerService: AnswerPatternService,
    private userService: UserService,
    @Optional() private projectService: ProjectService,
    private crmService: CrmService,
    private router: Router,
    private permissionsService: PermissionsService,
  ) {
    this.tariffService.getTariffs().pipe(takeUntil(this.unsub$))
      .subscribe((tariffs: any) => {
        if (!tariffs) {
          return;
        }

        this.tariffs = tariffs;
        this.setAllowOperators();
        this.setAllowChannels();
        this.setMasterOperator();
      });

    this.tariffService.getBalance().pipe(takeUntil(this.unsub$))
      .subscribe((balance: number) => {
        if (balance === undefined) {
          return;
        }

        this.balance = balance;
      });

    this.tariffService.getDailyPayment().pipe(takeUntil(this.unsub$))
      .subscribe((dailyPayment: number) => {
        if (!dailyPayment) {
          return;
        }

        this.dailyPayment = dailyPayment;
      });

    this.tariffService.getAvailableDays().pipe(takeUntil(this.unsub$))
      .subscribe((availableDays: number) => {
        if (!availableDays) {
          return;
        }

        this.availableDays = availableDays;
      });

    this.tariffService.getPaidState().pipe(takeUntil(this.unsub$))
      .subscribe((paid: boolean) => {
        if (paid !== undefined) {
          this.paid = paid;
          this.setAllowOperators();
          this.setAllowChannels();
        }
      });

    this.tariffService.getActiveState().pipe(takeUntil(this.unsub$))
      .subscribe((active: boolean) => {
        if (active !== undefined) {
          this.active = active;
          this.setAllowChannels();
        }
      });

    this.channelService.getChannels().pipe(takeUntil(this.unsub$))
      .subscribe((channels: any) => {
        if (!channels) {
          return;
        }

        this.channels = channels;

        this.setAllowChannels();
      });

    this.operatorService.getAllOperators().pipe(takeUntil(this.unsub$))
      .subscribe((teammates: any) => {
        if (!teammates) {
          return;
        }

        this.operators = teammates.filter((teammate) => teammate.roles.includes('operator')
          && teammate.status !== 'Deleted');
        this.operatorsLoaded = true;
        this.setAllowOperators();
        this.setMasterOperator();
      });

    this.projectService.getProjects().pipe(takeUntil(this.unsub$))
      .subscribe((projects: any) => {
        if (!projects) {
          return;
        }

        this.projects = projects;

        this.setMasterOperator();
      });

    this.userService.getUserData().pipe(takeUntil(this.unsub$))
      .subscribe((user: any) => {
        if (!user) {
          return;
        }
        this.user = user;
        this.projects = this.projectService.getCurrentProject();

        this.setMasterOperator();
      });
  }

  public isUnPayedOperator(id: string): boolean {
    return this.allowOperatorsId.indexOf(id) === -1;
  }

  public getTariffsOperatorsCount(): number {
    return this.tariffs.operators.count;
  }

  public getPaidOperatorsCount(): number {
    return this.allowOperatorsId.length;
  }

  public isUnPayedChannel(id: string): boolean {
    return this.allowChannelsId.indexOf(id) === -1;
  }

  // TODD: Проверить нужно ли оно еще
  private setAllowOperators() {
    if (this.tariffs && this.tariffs.hasOwnProperty('operators') && this.operatorsLoaded !== undefined) {
      this.allowOperatorsId = this.tariffService.getAllowOperatorsId();
      this.subjectOperators.next(this.allowOperatorsId);
    }
  }

  private getFeaturePaidCounts(featureName: string): number | boolean {
    const name = this.getType(featureName);
    const { paidEntities } = this.tariffService;
    return Array.isArray(paidEntities[name]) ? paidEntities[name].length : paidEntities[name];
  }

  private setMasterOperator() {
    const { user } = this;
    const project = this.projectService.getCurrentProject();
    if (this.operatorsLoaded && this.allowOperatorsId !== undefined && project && user !== undefined
      && this.tariffService.paidEntities !== undefined) {
      this.masterOperator = project && this.getFeaturePaidCounts('operators') !== 0
          && (user.id === project.ownerId && project.operator) || this.allowOperatorsId.indexOf(user.id) !== -1;
      // подразумевается, что не админская роль
      this.isOperatorRole = !this.permissionsService.isAdmin();
      this.subjectIsOperator.next(this.isOperatorRole);
      this.subjectIsAllowOperator.next(this.masterOperator);
    } else {
      setTimeout(() => {
        this.setMasterOperator();
      }, 250);
    }
  }

  private getType(name: string) {
    return NAME_SPACES[name] || name;
  }

  // TODD: Это больше не нужно, надо переделать с использованием paidEntities
  public setAllowChannels() {
    if (!this.tariffs) {
      return;
    }

    this.allowChannelsId = this.tariffService.getAllowChannelsId();
    this.subjectAllowChannels.next(this.allowChannelsId);
  }

  public updateAllowUnits() {
    if (this.tariffs !== undefined) {
      this.setAllowOperators();
      this.setAllowChannels();
      this.setMasterOperator();
    }
  }

  public getAllowChannelsCount(type: string) {
    type = NAME_SPACES[type] || type;
    return this.tariffs[type].count;
  }

  /*
    TODO: переписать данный метод таким образом, чтобы он действительно стал асинхронным.
    В настоящий момент он ловит ситуации, когда тарифы и сущности paid и active еще не определены
    и поэтому возвращает неверное значение
  */
  public getAccessStatus(name: string, channelId: string = ''): Observable<boolean> {
    name = this.getType(name);
    const { paidEntities } = this.tariffService;
    const item = paidEntities && paidEntities[name];
    const tariffs = this.tariffs && this.tariffs[name];

    if (tariffs && this.paid !== undefined && this.active !== undefined) {
      const { count } = tariffs;
      const free = tariffs.promoPrice === 0 ? 9999 : tariffs.quantityForFree;
      const currentChannels = (this.channels || []).filter((channel) => this.getType(channel.type) === name);
      const createdCount = currentChannels && currentChannels.length || 0;
      if (channelId && channelId !== 'demoOnlineChat') {
        return of(Array.isArray(item) && item.indexOf(channelId) !== -1);
      }

      return of(createdCount < free || count > createdCount);
    }
    return of(false);
  }

  /** метод, который пришлет уведомление, если опция добавлена в тариф.
   * Нужна для оптимизации некоторых роутов, чтобы мы не запрашивали данные для
   * функций, которые не добавлены в тариф
   *
   * завершиться автоматически после успешного получения информации
   * */
  public optionAddedInTariff(optionName: string): Observable<void> {
    let sub = new Subscription();
    return new Observable((subscriber) => {
      const watchTariff = this.tariffService.getTariffs()
        .pipe(takeUntil(this.unsub$))
        .subscribe((tariff: unknown) => {
          const name = this.getType(optionName);
          const tariffOption = tariff[name];

          if (tariffOption.count > 0) {
            subscriber.next();
            subscriber.complete();
            sub.unsubscribe();
          }
        });

      sub.add(watchTariff);
    });
  }

  public showDeclinePopup() {
    this._declinePopup = true;
    this.subjectDeclinePopup.next(this._declinePopup);
  }

  public subscribeDeclinePopUp(): Observable<any> {
    return this.subjectDeclinePopup.asObservable();
  }

  public goToSettings() {
    this.showDeclinePopup();
    this.router.navigate(['settings/start']);
  }

  public goPayment() {
    this.router.navigate(['settings/payment']);
  }

  public goTariff() {
    this.router.navigate(['settings/tariff-setup']);
  }

  public goProject() {
    this.router.navigate(['settings/edit-project']);
  }

  public goChannels() {
    this.router.navigate(['settings/channels']);
  }

  public isOperator(): Observable<any> {
    return this.subjectIsOperator.asObservable();
  }

  public getAllowOperators(): Observable<any> {
    return this.subjectOperators.asObservable();
  }

  public getAllowChannels(): Observable<any> {
    return this.subjectAllowChannels.asObservable();
  }

  public getAllowOperator(): Observable<any> {
    return this.subjectIsAllowOperator.asObservable();
  }
}
