import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { I18nService } from '@app/core';
import { CacheService } from '@app/core/cache.service';
import { isNavigationEnd } from '@app/core/helpers/isNavigationEnd';
import { TagsService } from '@app/core/tags.service';
import { TariffService } from '@app/core/tariff.service';
import { Tag } from 'konva';
import { each } from 'lodash-es';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
  @Input() hasProject: boolean;
  @Input() channels: any;
  @Input() operators: any;
  @Input() tags: Tag[];

  public language: any;

  private isInsideClick = false;
  private subs$: Subscription;
  private unsub$ = new Subject<void>();

  public searchSaveState = {
    searchText: '',
    selectedFilter: '',
    selectedOperator: '',
    selectedSorting: 'newest',
    selectedChannel: '',
    selectedTags: [],
  };

  public filters = [
    // {name: 'All Messages and persons', id: 'all'},
    { name: 'Only by phone', id: 'phone' },
    { name: 'Only in messages', id: 'messageText' },
    { name: 'Only by people', id: 'personName' },
    { name: 'Only by note', id: 'noteText' },
    // { name: 'Only by email', id: 'email' },
  ];
  public sorting = [
    { name: 'newest', id: 'newest' },
    { name: 'oldest', id: 'oldest' },
  ]
  public selectedFilter: string;
  public selectedChannel: string;
  public selectedOperator: string;
  public selectedSorting: string = 'newest';
  public selectedTags: string[];
  public searchText: string;
  private lastChannel: string;
  public isConversationPage: boolean;
  public isTagAvailable;
  public isVisible: boolean;

  isTagsPaid$ = this.tariffService.isOptionPaid$('tags');

  constructor(
    private router: Router,
    private i18nService: I18nService,
    private cacheService: CacheService,
    private detector: ChangeDetectorRef,
    private tagsService: TagsService,
    private tariffService: TariffService,
  ) {
    this.isConversationPage = this.router.url.startsWith('/conversations');
  }

  ngOnInit(): void {
    this.router.events.pipe(takeUntil(this.unsub$)).subscribe((event: any) => {
      if (isNavigationEnd(event)) {

        this.isConversationPage = this.router.url.startsWith('/conversations');
        this.isVisible = false;
        this.detector.detectChanges();
      }
    });

    // переделал получения языка на асинхронное, потому что эта компонента успевает инициализироваться до
    // того, как файл перевода сохраниться в translateService
    this.i18nService.translateService.getTranslation(this.i18nService.language)
      .pipe(takeUntil(this.unsub$))
      .subscribe(language => {
        this.language = language;

        if (this.language) {
          each(this.filters, selected => {
            selected.name = this.language.header.typeSelect[selected.id];
          });

          this.sorting = this.sorting.map((selected) => {
            selected.name = this.language.header.sorting[selected.id];
            return selected;
          });
          this.detector.detectChanges();
        }
      });

    this.tagsService.isTagsAvailable.asObservable().subscribe((val) => {
      this.isTagAvailable = val;
    })

    this.defaultSearchState();
  }

  ngOnDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  onFocus() {
    this.isVisible = true;
  }

  hide() {
    this.isVisible = false;
  }

  goTo() {
    // приходиться получать параметры так, т к в ActivatedRoute нет инфы о текущих параметрах
    let [, urlPrefix, page, filterBy, additionalFilter] = document.location.pathname.split('/');

    if (this.selectedChannel !== 'all') {
      page = this.selectedChannel;
      filterBy = 'all';
    }

    if (this.selectedOperator !== 'all') {
      page = this.selectedChannel === 'all' ? this.selectedOperator : this.selectedChannel;
      filterBy = 'all';
      additionalFilter = 'user';
    }

    const url = `/${urlPrefix}/${page}/${filterBy}` + (additionalFilter ? `/${additionalFilter}` : '');

    this.router.navigateByUrl(url, { replaceUrl: true });
    this.isVisible = false;
  }

  searchHandler(value: string) {
    if (value === '' && !this.isVisible) {
      this.clearSearch();
      return;
    }
    this.searchText = value;
    if (this.router.url.startsWith('/conversations')) {
      if (value && value.length >= 1) {
        this.isVisible = true;
      }
    }
    this.detector.detectChanges();
  }

  search() {
    this.searchText = this.searchText.substring(0, 50);
    this.searchSaveState.searchText = this.searchText;
    this.searchSaveState.selectedFilter = this.selectedFilter;
    this.searchSaveState.selectedOperator = this.selectedOperator;
    this.searchSaveState.selectedSorting = this.selectedSorting;
    this.searchSaveState.selectedChannel = this.selectedChannel;
    this.searchSaveState.selectedTags = this.selectedTags;
    this.cacheService.set('search', this.searchSaveState);
    this.cacheService.set('hasActiveSearch', true);
    this.lastChannel = this.selectedChannel;
    this.goTo();
    this.isVisible = false;
  }

  clearSearch() {
    const hasActiveSearch = this.cacheService.get('hasActiveSearch');
    this.isVisible = false;
    this.defaultSearchState();

    if (hasActiveSearch) {
      this.goTo();
    }
  }

  defaultSearchState() {
    this.selectedFilter = this.filters[0].id;
    this.selectedChannel = 'all';
    this.selectedOperator = 'all';
    this.selectedSorting = 'newest';
    this.selectedTags = [];
    this.searchText = '';
    this.cacheService.delete('hasActiveSearch');
    this.cacheService.delete('search');
  }

  onSearchClick(e: any) {
    e.stopPropagation();
    if (this.isVisible && !this.isInsideClick) {
      this.subs$ = fromEvent(document, 'click')
        .pipe(takeUntil(this.unsub$))
        .subscribe(() => {
          this.isVisible = false;
          if (this.cacheService.get('hasActiveSearch')) {
            this.searchText = this.searchSaveState.searchText;
            this.selectedFilter = this.searchSaveState.selectedFilter;
            this.selectedOperator = this.searchSaveState.selectedOperator;
            this.selectedSorting = this.searchSaveState.selectedSorting;
            this.selectedChannel = this.searchSaveState.selectedChannel;
            this.selectedTags = this.searchSaveState.selectedTags;
          } else {
            this.defaultSearchState();
          }
          this.detector.detectChanges();
          if (!this.cacheService.get('hasActiveSearch') && this.subs$ && !this.subs$.closed) {
            this.isInsideClick = false;
            this.subs$.unsubscribe();
          }
        });
    }
    this.isInsideClick = true;
  }

  onPaste(event: ClipboardEvent) {
    // пока отключили это поведение
    // при вставке текста в поле поиска не заменяет выделенный текст, а добавляет
    // event.preventDefault();
    // let pastedText: any = event.clipboardData.getData('text/plain').trim();
    // pastedText = pastedText.replace(/(\r?\n)?(<br\/?>)(\r?\n)?/, '');
    // if (pastedText) {
    //   this.searchText += pastedText;
    // }
  }

  removeTag(event: MouseEvent, id) {
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();
    this.selectedTags = this.selectedTags.filter(tagId => tagId !== id);
  }
}
