export const DOMAIN_REGEX = /^(?!(api|cdn|websocket|help|admin|cloud)\b(?!-))[a-z0-9\-_]{3,255}$/i;
export const PASSWORD_REGEX = /[^\s]{6,}$/;
// eslint-disable-next-line max-len
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_REGEX = /^\s*\+?([- _():=+]?\d[- _():=+]?){10,14}\s*$/;
export const COLONS_REGEX = /(?:\:([^\: ]+)\:)(?:\:skin-tone-(\d)\:)?/;
export const COLONS_REGEX_REVERT = /^((?!(?:\:([^\:]+)\:)(?:\:skin-tone-(\d)\:)?).)*$/;
export const ALT_COLONS_REGEX = /alt=\"(?:\:([^\:]+)\:)(?:\:skin-tone-(\d)\:)?\"/;
export const EMOJI_RANGE = /([\uD800-\uDBFF][\uDC00-\uDFFF])/;
export const EMOJI_RANGE_REVERT = /^((?!([\uD800-\uDBFF][\uDC00-\uDFFF])).)*$/;
export const NAME_REGEX = /^[a-zа-яё0-9`~!@#№$%^&*()\-_=+\/\|\\\\\[\]{};:'"<>?., ]+$/i;
// eslint-disable-next-line max-len
export const ADDRESS_URL_REGEX = /^[ ]*((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9а-яёА-ЯЁ_\-@]+(\.[a-zA-Z0-9а-яёА-ЯЁ\-]+)+((\/)[\wа-яёА-ЯЁ0-9\-\.]+)*?(\/)?(\?)?([a-zA-Z0-9а-яёА-ЯЁ_\-]+(\=)[a-zA-Z0-9а-яёА-ЯЁ_\-]+(\&)?)*?[ ]*$/;
export const SIMPLIFIED_URL_REGEX = /^https?:\/\/(www\.)?[-a-zA-Zа-яёА-ЯЁ0-9@:%._\+~#=]{1,256}\.[a-zA-Zа-яёА-ЯЁ0-9()]{1,6}\b([-a-zA-Zа-яёА-ЯЁ0-9()@:%_\+.~#?&//=]*)$/;
// eslint-disable-next-line max-len
export const BITRIX24_URL = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)+[a-zA-Z0-9_\-@]+(\.bitrix24\.)*/;
export const ONLY_LETTERS_REGEX = /^[a-zA-Zа-яёА-ЯЁ\- ]*$/;
export const PROMO_CODE = /^[A-Z0-9_-]+$/;
export const AMO_CRM_TOKEN = /^([a-z0-9]){40}$/;
export const SUBDOMAIN = /^[0-9a-zA-Z]+$/;
export const CHANNEL_NAME = /^.{0,30}$/;
export const INSTAGRAM_NICK = /@([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\\.(?!\\.))){0,28}(?:[A-Za-z0-9_]))?)|([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\\.(?!\\.))){0,28}(?:[A-Za-z0-9_]))?)/;
export const TELEGRAM_NICK = /^@(?=\w{5,64}\b)[a-zA-Z0-9]+(?:_[a-zA-Z0-9]+)*$/u;
export const VK_ID = /^id[0-9]{8}$/u;
export const NICKNAME = /^(?=.{5,64}$)[a-zA-Z0-9]*\.?[a-zA-Z0-9._]*$/u;
