export const env = {
    "version": "1.0.0 - develop-env (06ab12b4)",
    "baseDomain": "panel.teletype.app",
    "landingUrl": "https://teletype.app",
    "wsUrl": "https://websocket.teletype.app",
    "cookiesOrigin": ".teletype.app",
    "widgetBaseDomain": "teletype.app",
    "supportWidgetId": "9lSDCf0ffAmLpLhXKrIloJ9Af3p3Hns9Tq7PSHugbGG9vgzcvf4VENsYIY2A",
    "vkAppId": "7009563",
    "facebookId": "378158379475893",
    "accessToRoistatIdsList": [
        "6A0D4bVm0GgncjhBsUDY0BQu2j6RPKKhAyL98GPCFUOsyn4QM5F4Q5kEcBqFTJ7F"
    ]
};
