import { Injectable } from '@angular/core';
import { UtilsService } from '@app/core/utils.service';
import { Observable } from 'rxjs';
import { ApiService } from '@app/core/api/api.service';
import { Response } from '@app/core/interfaces/api.response';
import { routes } from '@app/core/config/apiRoutes';

export interface ChannelIdContext {
  channelId: string;
}

export interface PhoneContext {
  phoneNumber?: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class ChannelApiService {
  private routes = routes;
  constructor(
    private api: ApiService,
    private utils: UtilsService,
  ) {

  }

  public getChannelsList(): any {
    return this.api.httpGet(this.routes.channels, true);
  }

  public switchChannelState(id: ChannelIdContext): Observable<Response> {
    return this.api.httpPost(this.routes.switchChannelState(id), null);
  }

  public completeChannelCreation(id: string) {
    return this.api.httpGet(this.routes.completeChannelCreation(id));
  }

  public cancelChannelCreation(id: string) {
    return this.api.httpGet(this.routes.cancelChannelCreation(id));
  }

  public updateChannelsOrder(order: string[]) {
    return this.api.httpPost(this.routes.changeChannelSortOrder, this.utils.snakeKeys({ order }));
  }
}
