<div class="login-form-container">
  <div class="form-container register-container">
    <form class="register-form" (submit)="completeRegistration()"
          [formGroup]="completeRegistrationForm" #registerFormForm="ngForm" novalidate>
      <div class="logo pointer" (click)="goToLanding()"></div>
      <h1 [innerHTML]="'newAccountSetup.setupYourAccount' | translate"></h1>
      <app-alerts></app-alerts>

      <ng-container *ngIf="invitedEmail">
        <label>{{ 'newAccountSetup.yourEmail' | translate }}</label>
        <div class="form-group filled">
          <input type="text" class="ym-record-keys form-control" autocomplete="off"
                 [value]="invitedEmail"
                 disabled
                 [placeholder]="'newAccountSetup.email' | translate"
          />
          <span class="floating-label">{{ 'newAccountSetup.email' | translate }}</span>
        </div>
      </ng-container>

      <label>{{ 'newAccountSetup.yourName' | translate }}</label>
      <div class="form-group"
          [ngClass]="{'filled': completeRegistrationForm.value.name, 'error': showErrorToView()}">
        <input type="text" class="ym-record-keys form-control" formControlName="name" autocomplete="off"
                [placeholder]="'newAccountSetup.nameAnsLastName' | translate"
                (blur)="handleFieldBlur('name'); sendAnalyticsEvent('PROFILE_NAME')" required />
        <span class="floating-label">{{ 'newAccountSetup.nameAnsLastName' | translate }}</span>
        <span *ngIf="completeRegistrationForm.get('name').hasError('required')"
              class="form-group-error">
          {{ 'error.firstName' | translate }}
        </span>
        <span *ngIf="completeRegistrationForm.get('name').hasError('pattern')"
              class="form-group-error">
          {{ 'error.onlyLetter' | translate }}
        </span>
      </div>

      <label>{{ 'newAccountSetup.setYourPassword' | translate }}</label>
      <div class="form-group"
          [ngClass]="{'filled': completeRegistrationForm.value.password, 'error': errorsList.password}">
        <input type="password"
                class="form-control"
                formControlName="password"
                autocomplete="off"
                [placeholder]="'password.self' | translate"
                (blur)="handleFieldBlur('password')"
                [reliability]="true"
                required />
        <span class="floating-label">{{ 'password.self' | translate }}</span>

        <span *ngIf="completeRegistrationForm.get('password').hasError('required')" class="form-group-error">
          {{ 'password.error.blank' | translate }}
        </span>
        <span *ngIf="completeRegistrationForm.get('password').hasError('notEqual')" class="form-group-error">
          {{ 'password.error.match' | translate }}
        </span>
        <span *ngIf="completeRegistrationForm.get('password').hasError('pattern')" class="form-group-error">
          {{ 'password.error.valid' | translate }}
        </span>
      </div>

      <div class="form-group"
            [ngClass]="{'filled': completeRegistrationForm.value.passwordRepeat, 'error': errorsList.passwordRepeat}">
        <input type="password"
                class="form-control"
                formControlName="passwordRepeat"
                autocomplete="off"
                [placeholder]="'password.confirm' | translate"
                (blur)="handleFieldBlur('passwordRepeat')"
                required />
        <span class="floating-label">{{ 'password.confirm' | translate }}</span>
        <span *ngIf="completeRegistrationForm.get('passwordRepeat').hasError('required')" class="form-group-error">
          {{ 'password.error.blankConfirm' | translate }}
        </span>
        <span *ngIf="completeRegistrationForm.get('passwordRepeat').hasError('notEqual')" class="form-group-error">
          {{ 'password.error.match' | translate }}
        </span>
      </div>
      <div class="buttons-container">
        <button class="btn btn-primary with-spinner" type="submit" [disabled]="isLoading">
          <app-spinner [inProgress]="isLoading" [btnText]="'newAccountSetup.JoinProject' | translate"></app-spinner>
        </button>
      </div>
      <p class="legal-info">
        {{ 'newRegistration.termsText' | translate }}&nbsp;<a target="_blank" href="/assets/pdf/terms.pdf">{{ 'newRegistration.terms' | translate }}</a>&nbsp;
        {{ 'newRegistration.and' | translate }}&nbsp;
        <a target="_blank" href="/assets/pdf/policy.pdf">{{ 'newRegistration.policy' | translate }}</a>
      </p>
    </form>
  </div>
  <div class="bottom-bg"></div>
</div>
