export class NgSelectHelper {

  static onSelectBlur(id: string) {
    let inputRef: any = document.getElementById(id);
    if (inputRef) {
      inputRef = inputRef.getElementsByClassName('ng-input')[0];
      (inputRef.children[0] as HTMLInputElement).blur();
    }
  }
}
