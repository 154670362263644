import { Pipe, PipeTransform } from '@angular/core';
import each from 'lodash-es/each';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

  transform(value: string, search: string): any {
    if (!search) {
      return value;
    }

    each(search.split(' ').filter(s => s !== ''), (s) => {
      const re = new RegExp(s, 'gi');
      value = value.replace(re, '<mark>$&</mark>');
    });

    return value;
  }
}
