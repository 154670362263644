import { Injectable } from '@angular/core';
import { PermissionsService } from '@app/core/permissions.service';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { delay, filter, switchMap, take } from 'rxjs/operators';
import { ChannelService } from './channel.service';
import { UserService } from './user.service';

export const GREETING_TIMER_DELAY = 10 * 1000 * 60;

// export const GREETING_TIMER_DELAY = 5000;

@Injectable({
  providedIn: 'root',
})
export class ChatService {

  public get chatLoaded() {
    return this._chatLoadedSubject.asObservable();
  }

  public get hideLoaderButton() {
    return this._chatLoadedSubject.asObservable();
  }

  private _chatLoaded = false;

  private _chatLoadedSubject = new BehaviorSubject(this._chatLoaded);

  private _hideLoaderButton = false;

  private _hideLoaderButtonSubject = new BehaviorSubject(this._hideLoaderButton);

  private _greetingObservable: Subscription;

  constructor(
    private translateService: TranslateService,
    private user: UserService,
    private channels: ChannelService,
    private i18n: TranslateService,
    private permissionsService: PermissionsService
  ) {
    this.loadChat();

    document.addEventListener('teletype.ready', () => {
      this._chatLoaded = true;
      this._chatLoadedSubject.next(this._chatLoaded);
    });

    this.startTimerForGreeting();
  }

  public startChat(greetingMessage: boolean = false) {

    if (this._chatLoaded) {
      this.showChat(greetingMessage);
      return;
    }

    this.chatLoaded.pipe(filter(isLoaded => isLoaded), take(1)).subscribe(() => {
      this.showChat(greetingMessage);
    });
  }

  public showChat(greetingMessage: boolean = false) {
    if (greetingMessage && window['_teletypeWidget'].greetingMessages !== undefined) {
      window['_teletypeWidget'].greetingMessages([
        this.translateService.instant('sidebar.widgetGreeting')
      ]);
    }

    this._hideLoaderButtonSubject.next(true);
    window['_teletypeWidget'].show();
  }

  public sendMessage(message: string) {
    if (window['_teletypeWidget']['setMessage'] !== undefined) {
      window['_teletypeWidget'].setMessage(message);
    }
    window['_teletypeWidget'].show();
  }

  public startTimerForGreeting() {
    this._greetingObservable = of(true).pipe(delay(GREETING_TIMER_DELAY), switchMap(() => this.user.getUserData())).subscribe((userData) => {

      if (this.permissionsService.isAdmin() && this.channels.channels?.length === 0) {

        const userName = userData?.fullName ? ` ${userData.fullName}` : '';

        const widgetMessages = window['_teletypeWidget'].getMessages()?.filter(m => m.isOwn === true);

        if (widgetMessages.length > 0) {
          return;
        }

        window['_teletypeWidget'].show();
        window['_teletypeWidget'].greetingMessages([this.i18n.instant('emptyChannelsGreetingMessage', {'name': userName})]);
      }
    });
  }

  public stopTimerForGreeting() {
    this._greetingObservable.unsubscribe();
  }

  private loadChat() {
    window['teletypeButton'] = 'invisible';
    if (!window['teletypeDispatcher'] && environment && environment.supportWidgetId) {
      window['_teletypeWidget'] = window['_teletypeWidget'] || {};
      const t = document.getElementsByTagName('app-teletype-root');
      if (t.length > 0 && window['_teletypeWidget'].init) {
        return;
      }
      const d = new Date().getTime();
      const n = document.createElement('script');
      const c = document.getElementsByTagName('script')[0];
      n.id = 'teletype-widget-embed';
      n.src = `https://widget.${environment.widgetBaseDomain}/init.js?_=${d}`;
      n.async = !0, n.setAttribute('data-embed-version', '0.1');
      c.parentNode.insertBefore(n, c);

      window['teletypeExternalId'] = environment.supportWidgetId;
    }
  }
}
