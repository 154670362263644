<div class="form-group">
  <div class="dropdown-container">
    <ng-template #rt let-r="result">
      <app-channel-type-icon class="channel-icon" [channelType]="r['type']"></app-channel-type-icon>

      {{ r.name }}
    </ng-template>

    <input
      id="typeahead-template"
      type="text"
      class="form-control"
      [placeholder]="'settings.groups.addChannelToGroupPlaceholder' | translate"
      (selectItem)="selectItem($event)"
      (focus)="focus$.next($any($event).target.value)"
      [disabled]="channels.length === maxLength"
      [(ngModel)]="model"
      [ngbTypeahead]="search"
      [resultTemplate]="rt"
      [inputFormatter]="formatter"
    />
  </div>
</div>

<div class="checked-channels" *ngIf="channelsConfigForm.length">
  <div
    *ngFor="let channelControl of channelsConfigForm; let i = index;"
    class="checked-channel"
  >
    <div class="checked-channel__info">
      <app-channel-type-icon class="channel-icon" [channelId]="channelControl.value"></app-channel-type-icon>

      <p class="checked-channel__name">
        {{ getChannelById(channelControl.value).name }}
      </p>

      <button class="btn btn-grid checked-channel__delete" (click)="deleteChannel(i)">
        {{ 'button.delete' | translate }}
      </button>
    </div>
  </div>
</div>
