import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { MessageProvider } from '@app/core/interfaces/message';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent implements OnInit {

  @Input() animal = '';
  @Input() avatar: string;
  @Input() isOnline = false;
  @Input() displayStatus = false;
  @Input() size: number | string;
  @Input() fontSize: number | string;
  @Input() bg: string | null = null;
  @Input() withPreviewer = false;
  @Input() originalAvatar: string;
  @Input() provider: MessageProvider;

  @Output() openPreviewer: EventEmitter<string> = new EventEmitter();

  get _animal() {
    return (this.animal || '').toLowerCase();
  }

  get backgroundColor(): string {
    return this.zone.runOutsideAngular(() => {
      return this.avatar || 'transparent';
    });
  }

  avatarUrl(): SafeStyle {
    let url: string = this.avatar;

    switch (this.provider) {
      case MessageProvider.Bot: url = '/assets/icons/bot-avatar.svg'; break;
      case MessageProvider.PublicApi: url = '/assets/icons/public-api-avatar.svg'; break;
    }

    return url ? this.sanitizer.bypassSecurityTrustStyle(`url(${url})`) : '';
  }

  showPreviewer() {
    this.openPreviewer.emit(this.originalAvatar);
  }

  constructor(
    private zone: NgZone,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {

  }

}
