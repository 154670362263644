import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ChatService } from '@app/core/chat.service';
import { Alert } from '@app/core/alerts/alerts.service';
import { UtilsService } from '@app/core/utils.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  @Input() alertId?: string = null;
  @Input() alertType = 'error';
  @Input() alertTitle?: string = null;
  @Input() alertContent?: string = null;
  @Input() alertDismissable = false;


  @Output() close: EventEmitter<string> = new EventEmitter();

  constructor(
    private chatService: ChatService,
    private utils: UtilsService,
    private translationService: TranslateService
  ) { }

  ngOnInit() {
  }

  closeFn() {
    this.close.emit(this.alertId);
  }

  showWidget() {
    if (this.alertType !== 'error') {
      return;
    }

    const errorMessage = this.utils.trimTextFromHtml(this.alertContent);

    const widgetMessage = this.translationService.instant('alert.errorMessage', {
      errorMessage
    });

    this.chatService.sendMessage(widgetMessage);
  }

}
