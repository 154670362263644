import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { TariffService } from '@app/core/tariff.service';

@Injectable({
  providedIn: 'root'
})
export class TariffGuard implements CanActivate {

  constructor(
    private tariffService: TariffService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> | boolean {
    const tariffs = this.tariffService.tariffs$.getValue();
    if (tariffs) {
      return true;
    }
    return new Promise<boolean>(resolve => {
      this.tariffService.isTariffsLoaded$.subscribe(isLoaded => {
        if (isLoaded) {
          resolve(true);
        }
      });
    });
  }

}
