import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { PermissionsService } from '@app/core/permissions.service';
import { takeUntil } from 'rxjs/operators';
import { FeatureAccessService, BINARY_FEATURE } from '@app/core/feature-access.service';
import { NAMES, TariffService } from '@app/core/tariff.service';
import { I18nService } from '@app/core';
import { Response } from '@app/core/interfaces/api.response';
import { Subject } from 'rxjs';

const channelsList = [
  'whatsapp', 'whatsapp-edna', 'whatsappFree', 'telegramBot', 'vk', 'vk-direct', 'facebook', 'viber', 'avito', 'email', 'instagramDirect',
  'instagramComments', 'telegram'
];

const integrationList = ['amoCrm', 'bitrix24', 'roistat'];

@Component({
  selector: 'app-force-pay',
  templateUrl: './force-pay.component.html',
  styleUrls: ['./force-pay.component.scss'],
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForcePayComponent implements OnInit, OnDestroy, OnChanges {
  @Input() tariffName: string;
  @Input() channelId: string;
  @Input() create = false;
  @Input() edit = undefined;
  @Input() showDialog = true;
  @Input('forceCheck') forceCheck = false;
  @Output() success = new EventEmitter();

  public hasAccess: boolean;
  public inProgress: boolean;
  public cost: number;
  public quantityForFree: boolean;
  public tariffTitle: string;
  public isBinaryFeature: boolean;
  public title: string;
  public caption: string;
  public costText: string;
  public show: boolean;
  public isAdmin = this.permissionsService.isAdmin();

  private unsub$ = new Subject<void>();
  private tariffs: any;
  private balance: string;
  private currentTariff: any;
  private translations: any;

  constructor(
    private featureAccessService: FeatureAccessService,
    private tariffService: TariffService,
    private detector: ChangeDetectorRef,
    private i18nService: I18nService,
    private permissionsService: PermissionsService,
  ) { }

  ngOnInit() {
    this.translations = this.i18nService.translateService.translations[this.i18nService.language];
    this.tariffService.getTariffs().pipe(takeUntil(this.unsub$))
      .subscribe((tariffs: any) => {
        if (!tariffs) {
          return;
        }

        this.tariffs = tariffs;
        this.setCurrentTariff();
      });

    this.tariffService.getBalance().pipe(takeUntil(this.unsub$))
      .subscribe((balance: any) => {
        if (balance === undefined) {
          return;
        }

        this.balance = this.translations.forcePay.balanceSum.replace('#[sum]#', balance);
      });
    this.inProgress = false;
    this.checkFeatureAccess();
  }

  ngOnDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    const previousValue = changes.forceCheck && changes.forceCheck.previousValue;
    const currentValue = changes.forceCheck && changes.forceCheck.currentValue;
    if (changes.forceCheck !== undefined && (currentValue || previousValue !== undefined &&
      previousValue !== currentValue)) {
        this.inProgress = false;
        this.setCurrentTariff();
        this.checkFeatureAccess();
    }
  }

  public add() {
    if (!this.tariffName || this.inProgress) {
      return;
    }

    this.inProgress = true;
    const data = {};
    const propName = NAMES[this.tariffName];
    if (propName) {
      data[propName] = this.currentTariff.count === 0 ? 1 : this.currentTariff.count + 1;
      this.tariffService.saveTariff(data).pipe(takeUntil(this.unsub$))
        .subscribe((response: Response) => {
          if (response && response.success) {
            this.featureAccessService.updateAllowUnits();
            this.checkFeatureAccess();
            this.success.emit(true);
          } else {
            this.success.emit(false);
            if (this.showDialog) {
              this.featureAccessService.showDeclinePopup();
            }
          }
        }, () => {}, () => {
          this.inProgress = false;
          if (!this.detector['destroyed']) {
            this.detector.detectChanges();
          }
        });
    } else {
      this.inProgress = false;
    }
  }

  private setCurrentTariff() {
    if (this.tariffName && this.tariffs.hasOwnProperty(this.tariffName) && this.hasAccess !== undefined) {
      this.currentTariff = this.tariffs[this.tariffName];
      const isPromo = this.currentTariff.promoPrice !== null && this.currentTariff.promoPrice !== '';
      this.cost = isPromo ? this.currentTariff.promoPrice : this.tariffService.getPrice(this.currentTariff);
      this.quantityForFree = this.currentTariff.quantityForFree > 0;
      if (this.currentTariff.count < this.currentTariff.quantityForFree && this.currentTariff.quantityForFree !== 0) {
        this.cost = 0;
      }
      this.tariffTitle = this.translations.forcePay.titles[this.tariffName];
      this.isBinaryFeature = BINARY_FEATURE.indexOf(this.tariffName) !== -1;

      this.title = '';
      if (channelsList.indexOf(this.tariffName) !== -1) {
        this.title = this.translations.forcePay.channelPrefix;
      } else if (integrationList.indexOf(this.tariffName) !== -1) {
        this.title = this.translations.forcePay.integrationPrefix;
      }

      let suffix = '';
      if (integrationList.indexOf(this.tariffName) !== -1 || this.tariffName === 'statistic' ||
        this.tariffName === 'messengersButton') {
        suffix = this.translations.forcePay.plural;
      } else if (['groups', 'messagesTemplates'].indexOf(this.tariffName) !== -1) {
        suffix = this.translations.forcePay.pluralTeammate;
      }

      this.title += this.translations.forcePay.title
        .replace('#[channelName]#', this.translations.forcePay.titles[this.tariffName])
        .replace('#[suffix]#', suffix);

      let name = this.translations.forcePay.titles[this.tariffName];
      if (integrationList.indexOf(this.tariffName) !== -1 || this.tariffName === 'roistat') {
        name = this.translations.forcePay.integrationPrefixButton + name;
      } else if (this.tariffName === 'statistic') {
        name = this.translations.forcePay.statistic;
      }
      this.caption = this.translations.forcePay.buttonText
        .replace('#[channelName]#', name);
      this.costText = ` (+ ${this.cost} ₽/${this.translations.settings.bill.month})`;

      const firstLetter = this.title.charAt(0);
      const upperCase = firstLetter.toLocaleUpperCase();
      this.title = this.title.replace(firstLetter, upperCase);

      if (this.tariffName === 'operators') {
        this.title = this.translations.forcePay.teammateTitle + this.currentTariff.count;
        this.caption = this.translations.forcePay.teammateCaption;
      }

      if (this.currentTariff.count === 0 && this.cost === 0 && !this.inProgress &&
        ((this.edit && this.edit.id) || this.create || (this.edit === undefined && !this.create)) ||
        (isPromo && this.currentTariff.promoPrice === 0 &&
        ((this.currentTariff.category === 'binary' && this.currentTariff.count === 0) ||
        (this.currentTariff.category !== 'binary' && this.hasAccess === false)))) {
      } else {
        this.show = true;
      }

      this.detector.detectChanges();
    } else {
      setTimeout(() => {
        this.setCurrentTariff();
      }, 250);
    }
  }

  private checkFeatureAccess() {
    if (!this.tariffName) {
      return;
    }

    this.featureAccessService.getAccessStatus(this.tariffName, this.channelId)
      .subscribe((hasAccess) => {
        this.hasAccess = hasAccess;
        this.detector.detectChanges();
    });
  }

}
