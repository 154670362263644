import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/api/api.service';
import { routes } from '@app/core/config/apiRoutes';
import { Group } from '@app/core/group.service';
import { Response } from '@app/core/interfaces/api.response';
import { UtilsService } from '@app/core/utils.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GroupApiService {
  constructor(
    private api: ApiService,
    private utils: UtilsService,
  ) {

  }

  public getListGroup(): Observable<Response> {
    return this.api.httpGet(routes.listGroup);
  }

  public groupCreate(data: Group): Observable<Response> {
    return this.api.httpPost(routes.createGroup, this.utils.snakeKeys({ ...data }));
  }

  public groupUpdate(id: string, data: Group): Observable<Response> {
    return this.api.httpPost(routes.updateGroup(id), this.utils.snakeKeys({ ...data }));
  }

  public groupDelete(id: string): Observable<Response> {
    return this.api.httpGet(routes.deleteGroup(id));
  }
}
