import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxLength'
})
export class MaxLength implements PipeTransform {

  transform(value: any, limit: number = 15, showEllipsis: boolean = false): any {
    return (value && value.length > limit) ? value.substring(0, limit) + (showEllipsis ? '...' : '') : value;
  }
}

