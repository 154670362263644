<div #container class="teletype-tabs-container">
    <div class="teletype-tabs-anchors" *ngIf="!isInstagramPost">
      <button [ngClass]="{'teletype-active': activeTab === 'emoji'}"
              (click)="changeTab('emoji')"
              type="button">Emoji</button>
      <button [ngClass]="{'teletype-active': activeTab === 'giphy'}"
              (click)="changeTab('giphy')"
              type="button">Gifs</button>
    </div>
    <div class="teletype-tab"
         [hidden]="activeTab !== 'emoji'"
         [ngClass]="{'teletype-active': activeTab === 'emoji'}"
    >
      <emoji-mart
        [showPreview]="false"
        [emojiTooltip]="true"
        class="teletype-emoji-picker teletype-visible"
        [set]="emojiSet"
        [isNative]="useNative"
        [perLine]="windowWidth > 767 ? 7 : 5"
        [include]="['all']"
        [emojiSize]="28"
        (emojiClick)="handleEmojiSelect($event)"></emoji-mart>
    </div>
    <div class="teletype-tab"
         [hidden]="activeTab !== 'giphy'"
         [ngClass]="{'teletype-active': activeTab === 'giphy'}"
    >
      <div class="teletype-giphy-search-container">
        <input type="text" [formControl]="giphySearchQuery" #giphySearchControl placeholder="Search GIFS…" />
      </div>
      <div class="teletype-loading-spinner" *ngIf="giphyLoading"></div>
      <ng-scrollbar class="teletype-giphy-results scroll-container _inline-scroll" *ngIf="pickerVisible && giphySearchResults.length > 0">
        <div class="teletype-giphy-result-row" *ngFor="let row of giphySearchResults">
          <div (click)="sendGiphy(item)"
               class="teletype-giphy-item-container"
               [ngStyle]="{'width.px': item.width, 'height.px': item.height}"
               *ngFor="let item of row">
            <img [ngSrc]="item.image" width="{{ item.width }}"  height="{{ item.height }}" />
          </div>
        </div>
      </ng-scrollbar>
    </div>
    <div class="arrow-bottom">
      <div class="shadow-wrapper">
        <div class="shadow">▲</div>
      </div>
    </div>
</div>
