import {
  Component,
  OnInit,
  ViewChild,
  NgZone,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, NgForm, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertsService } from '@app/core/alerts/alerts.service';
import { extract, I18nService } from '@app/core';
import { filter, take, takeUntil } from 'rxjs/operators';
import remove from 'lodash-es/remove';
import each from 'lodash-es/each';
import { CookiesService } from '@app/core/cookies.service';
import { RegistrationApiService } from '@app/core/api/api.registration';
import { Title } from '@angular/platform-browser';
import { LocationService } from '@app/core/location.service';
import { MetrikaService, ANALYTICS_EVENT } from '@app/core/metrika.service';
import { FaceBookPixelService } from '@app/core/fbpixel.service';
import { GoogleTagService } from '@app/core/gtag.service';
import {Subject} from 'rxjs';

const CODE_LENGTH = 6;

@Component({
  selector: 'app-confirm-code',
  templateUrl: './confirm-code.component.html',
  styleUrls: ['./confirm-code.component.scss']
})
export class ConfirmCodeComponent implements OnInit, OnDestroy {

  confirmEmailForm: UntypedFormGroup;
  isLoading = false;
  resendCode = false;
  errorsList: any = {};
  public email: string;
  public code: Array<any>;
  public group: number[];

  private token: string;
  private unsub$ = new Subject<void>();

  @ViewChild('confirmEmailFormForm', { static: true }) confirmEmailFormForm: NgForm;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private api: RegistrationApiService,
    private alerts: AlertsService,
    private zone: NgZone,
    private route: ActivatedRoute,
    private detector: ChangeDetectorRef,
    private cookies: CookiesService,
    private i18nService: I18nService,
    private titleService: Title,
    private locationService: LocationService,
    private metrika: MetrikaService,
    private fbPixel: FaceBookPixelService,
    private gtag: GoogleTagService,
  ) {

  }

  ngOnInit() {
    const title = this.route.snapshot.data['title'];
    if (title) {
      this.i18nService.translateService.getTranslation(this.i18nService.language)
        .pipe(take(1))
        .subscribe((language: any) => {
          if (language) {
            this.titleService.setTitle(language.route[title.toLowerCase()]);
          }
        });
    }
    this.alerts.removeAll();
    const data = this.route.snapshot.data.customerData;
    this.email = data.email || '';

    const group = {};

    for (let i = 1; i <= CODE_LENGTH; i++) {
      group[i] = ['', [Validators.required]];
    }

    this.group = Array.from(Array(CODE_LENGTH).keys()).map(x => ++x);

    this.confirmEmailForm = this.formBuilder.group(group);

    this.code = new Array(CODE_LENGTH);
    this.zone.runOutsideAngular(() => {
      this.route.params.pipe(
        filter((params: any) => params.token)
      ).pipe(takeUntil(this.unsub$)).subscribe((params: any) => {
        this.token = params.token;
      });
    });

    const promo: string = this.route.snapshot.queryParamMap.get('p') || this.route.snapshot.queryParamMap.get('promo');
    if (promo) {
      this.cookies.setPromoCode(promo);
    }
  }

  ngOnDestroy() {
    this.unsub$.next();
this.unsub$.complete();
  }

  extract(string: string) {
    return extract(string);
  }

  resend() {
    if (!this.email) {
      return;
    }
    this.resendCode = false;
    this.api.resend({ email: this.email }).subscribe((data: any) => {
      this.resendCode = true;
      this.isLoading = false;
      this.detector.detectChanges();
    });
  }

  confirmEmail(code: string) {
    this.alerts.removeAll();

    this.isLoading = true;

    this.api.confirmEmail(this.token, code).subscribe((data: any) => {
      this.isLoading = false;
      this.metrika.goal(ANALYTICS_EVENT.MAIL_CONFIRM);
      this.gtag.track(ANALYTICS_EVENT.MAIL_CONFIRM);
      this.fbPixel.track(ANALYTICS_EVENT.MAIL_CONFIRM);
      this.router.navigate([`/registration/profile/${data.data.token}`], { replaceUrl: true });
    }, () => {},
    () => {
      this.isLoading = false;
      this.detector.detectChanges();
    });
  }

  checkInputs() {
    let code = '';
    const reg = new RegExp('^[0-9]$');
    this.code.forEach((field) => {
      if (reg.test(field)) {
        code += field.toString();
      }
    });

    if (code.length === CODE_LENGTH) {
      this.confirmEmail(code);
    }
  }

  onInput(event: any, i: number) {
    let value = event.target.value;
    if (value && value.length > 1) {
      value = value[value.length - 1];
      event.target.value = value;
    }
    this.code[parseInt(event.target.name, 10)] = value;
    if (value) {
      const name = i + 1;
      if (name <= 6) {
        const nextFieldName = name.toString();
        const nextElement = document.getElementsByName(nextFieldName)[0];
        if (nextElement !== undefined) {
          nextElement.focus();
        }
      }
    }
    this.checkInputs();
  }

  onPaste(event: ClipboardEvent, index: number) {
    event.preventDefault();

    const text: any = event.clipboardData.getData('text/plain')
      .replace(/[ \-\t\r\n]/g, '').split('');

    remove(text, (item: any) => isNaN(item));

    each(text, (code: string, idx: number) => {

      const newIndex = index + idx;

      if (newIndex > CODE_LENGTH) {
        return;
      }

      const control = this.confirmEmailForm.controls[newIndex];

      if (control !== undefined) {
        control.setValue(code);

        this.code[idx] = code;

        const nextFieldName = newIndex.toString();

        const elms = document.getElementsByName(nextFieldName);

        if (elms[0] !== undefined) {
          elms[0].focus();
        }
      }

    });

    this.checkInputs();
  }

  onPress(event: KeyboardEvent) {
    if (isNaN(event.key as any)) {
      event.preventDefault();
      return;
    }
  }

  onBackspace (item: number) {
    if (!(document.getElementsByName(item.toString())[0] as HTMLInputElement).value) {
      const nextFieldName = (item - 1).toString();
      const nextElement = document.getElementsByName(nextFieldName)[0];
      if (nextElement !== undefined) {
        nextElement.focus();
      }
    }
  }

  public goToLanding() {
    this.locationService.goToLanding();
  }
}
