import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { ReliabilityDirectiveModule } from '@app/core/directives/reliability/reliability.module';
import { AlertsComponentModule } from '@app/shared/alerts/alerts.module';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { SharedModule } from '@app/shared';
import { ElamaLoginButtonComponent } from '@app/shared/elama-login-button/elama-login-button.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    LoginRoutingModule,
    AlertsComponentModule,
    SharedModule,
    NgSelectModule,
    ReliabilityDirectiveModule,
    ElamaLoginButtonComponent
  ],
  declarations: [
    LoginComponent,
    ResetPasswordComponent,
    SetPasswordComponent,
  ]
})
export class LoginModule { }
