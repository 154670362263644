import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  SimpleChanges,
  OnChanges,
  NgZone,
  OnDestroy, ViewChild, ElementRef, HostListener, TemplateRef
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { Message } from '@app/core/interfaces/message';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomModalService } from '@app/core/custom-modal.service';
import { EmojiService } from '@app/shared/emoji/emoji/emoji.service';
import { Subject, Subscription } from 'rxjs';
import { environment } from '@env/environment';
import { COLONS_REGEX } from '@app/core/config/regExValidators';
import { UtilsService } from '@app/core/utils.service';
import { DateTime } from 'luxon';
import { I18nService } from '@app/core';
import { UserService } from '@app/core/user.service';
import { AudioPlayerService } from '@app/core/player';

const VALIDATORS = {
  // eslint-disable-next-line max-len
  email: new RegExp('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'),
  sms: new RegExp('^[\s()+-]*([0-9][\s()+-]*){6,20}$')
};

const PLACEHOLDERS = {
  email: 'email@test.com',
  sms: '+44'
};

const EMOJI_RANGE = /([\uD800-\uDBFF][\uDC00-\uDFFF])/;

const TIME_PLACE = '{{%DATE%}}';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false
})
export class MessageComponent implements OnInit, OnChanges, OnDestroy {

  public environment = environment;
  public modalRef: BsModalRef;
  public charactersCount = 0;
  public currentSelection: any;
  public lastMessageValue: string;
  public contentEditable = false;
  public contactForm: any;
  public messageClass: any;
  public dateToDisplay: string;
  public displayMessage: string;
  public contactFormValue: UntypedFormControl;
  // private dateInterval: any;
  public formSent = false;

  private statusCheckPlanned = false;
  public emojiPickerVisible = false;
  public imageWidth = 0;
  public imageHeight = 0;
  public emojiOnly = false;
  public translations: any;

  public size: string;

  // Audio message area
  public duration = '-:-';
  public totalDuration = this.duration;
  public totalDurationTime = 0;
  public playing = false;
  public percentDuration = 0;
  public durationInt = 0;
  public audioSubscription: Subscription;
  public repliedAudioDuration = '-:-';
  // /Audio message area

  get messageType(): string {
    return this.message ? (this.message.messageType === 'gif' ? 'image' : this.message.messageType) : 'text';
  }

  get _withoutTail(): boolean {
    return this.messageType === 'typing' || this.message.type === 12 ? false : this.withoutTail;
  }

  get repliedMessageText(): string {
    const { repliedMessage } = this.message;

    if (!repliedMessage) {
      return '';
    }

    switch (repliedMessage.messageType) {
      case 'audio':
        return this.translations.conversation.voiceMessage;
      case 'image':
      case 'gif':
      case 'video':
      case 'attachment':
        return repliedMessage.attachment;
      default:
        return repliedMessage.message || '';
    }
  }

  get isMessageInGroupChat(): boolean {
    return !!this.message.appeal?.channelSpecific?.groupChat;
  }

  get nameOfAuthorMessage(): string {
    const { name, phone } = this.message.from;

    return name || phone || '';
  }

  public useNativeEmoji: boolean;

  public messageStatus: string;
  public avatar: string;
  public zindex = false;

  private unsub$ = new Subject<void>();
  public withoutTail: boolean;
  public date: any;
  public startDate: any;
  private parentElementWidth: number;

  @ViewChild('messageInputEdit', { read: ElementRef, static: false }) messageInputEdit: ElementRef;
  @ViewChild('messageContainer', { read: ElementRef, static: false }) messageContainer: ElementRef;
  @Input() limit = 1000;
  @Input() message: Message;
  @Input() seen: boolean;
  @Input() hasTail: boolean;
  @Input() progress: number;
  @Input() status: string;
  @Input() failedMessage: string;
  @Input() class: any;
  @Input() isAddDate = true;
  @Input() hasQueue: boolean;
  // прикрепленный оператор
  @Input() selectedClientId: string;
  /** имя пользователя с которым общаемся (нужно для цитирования)*/
  @Input() userName?: string;
  /** возможно ли процитировать это сообщение (определяется типом канала) */
  @Input() isChannelSupportReplay: boolean = false;

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() showPreviewer: EventEmitter<string> = new EventEmitter();
  @Output() retry: EventEmitter<Message> = new EventEmitter();
  @Output() remove: EventEmitter<Message> = new EventEmitter();
  @Output() changeMessage = new EventEmitter<string>();
  @Output() toggleEmojiPicker = new EventEmitter<boolean>();
  @Output() floatError = new EventEmitter<boolean>();
  @Output() sendEmoji = new EventEmitter<string>();
  /** хук, который вызывается при цитировании сообщения */
  @Output() onReplay = new EventEmitter<any>()
  @Output() onClickToPerson = new EventEmitter<any>()

  @HostListener('keyup', ['$event'])
  @HostListener('keydown', ['$event'])
  @HostListener('mousedown', ['$event'])
  onMouseDown(e: Event) {
    // @ts-ignore
    if (e.target && e.target.className === 'teletype-icon-close') {
      return;
    }
    // 8 -backspace 47 - delete
    // @ts-ignore
    if (this.getCharactersLen() >= this.limit && e.type !== 'mousedown' && ![8, 46].includes(e.keyCode)) {
      e.preventDefault();
    }
    this.currentSelection = this.saveSelection();
    this.charactersCount = this.getCharactersLen();
  }

  get isCanReplay(): boolean {
    return Boolean(this.isChannelSupportReplay && !this.message.isOwn && this.message.sourceExternalId);
  }

  constructor(
    private modalService: BsModalService,
    private dialog: CustomModalService,
    private detector: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private zone: NgZone,
    private emojiService: EmojiService,
    private utils: UtilsService,
    private i18nService: I18nService,
    private userService: UserService,
    private audioPlayerService: AudioPlayerService,
  ) {
    this.useNativeEmoji = this.utils.useNativeEmoji;

    this.translations = this.i18nService.translateService.translations[this.i18nService.language];
  }

  ngOnInit() {
    setTimeout(() => {
      this.statusCheckPlanned = false;
      if ((this.messageStatus && this.messageStatus.toString() === '10') && !this.hasQueue) {
        this.messageStatus = '20';
        if (!this.detector['destroyed']) {
          this.detector.detectChanges();
        }
      }
    }, 15 * 1000);
  }

  ngOnDestroy() {
    this.unsub$.next();
this.unsub$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.seen !== undefined && this.message !== undefined && changes.seen.currentValue === true) {
      this.message.seen = changes.seen.currentValue;
      this.onMessage(this.message);
    }
    if (changes.hasTail !== undefined && this.message !== undefined && changes.hasTail.currentValue !== undefined) {
      this.message.hasTail = changes.hasTail.currentValue;
      this.onMessage(this.message);
    }
    if (changes.errorMessage !== undefined && this.message !== undefined &&
      changes.errorMessage.currentValue !== undefined) {
      this.message.errorMessage = changes.errorMessage.currentValue;
      this.onMessage(this.message);
    }
    if (changes.failedMessage !== undefined && this.message !== undefined &&
      changes.failedMessage.currentValue !== undefined) {
      this.message.failedMessage = changes.failedMessage.currentValue;
      setTimeout(() => {
        this.parentElementWidth = this.messageContainer.nativeElement.clientWidth - 11;
      });
      this.onMessage(this.message);
    }
    if (changes.message !== undefined && changes.message.currentValue !== undefined) {
      this.onMessage(changes.message.currentValue);
    }
    if (changes.progress !== undefined && changes.progress.currentValue !== undefined) {
      this.message.progress = changes.progress.currentValue;
    }
    if (changes.status !== undefined && changes.status.currentValue !== undefined) {
      this.message.status = changes.status.currentValue;
      this.messageStatus = this.message.status.toString();
    }
  }

  onMessage(message: Message) {
    this.message = {...message};
    // TODO: удалить, эта логика была перенесена в компонент аватарки
    // if (this.message.provider === 20) {
    //   this.avatar =
    //     `${window.document.location.protocol}//${window.document.location.host}/assets/img/public-api-avatar.jpg`;
    // }
    this.avatar = message.from && (message.from.avatar || message.from.avatar_default ||
      message.from.avatarDefault);

    if (this.message.type === 30) {
      const language = this.i18nService.language;
      const date = DateTime.fromISO(this.message.date.date).setLocale(language)
        .setZone(this.userService.user.timezone);
      const additionalText = language === 'ru' ? ' в ' : ' at ';
      this.message.message = this.message.message
        .replace(TIME_PLACE, date.toFormat('dd LLL yyyy') + additionalText +
          date.toFormat('HH:mm'));
    }

    this.withoutTail = message.hasTail;
    this.date = message.newDay;
    this.startDate = message.startDate;
    this.formatMessage();

    if (this.message.attachmentId !== undefined &&
      (this.message.messageType === 'text' || this.message.messageType === undefined)) {
      this.message.messageType = 'attachment';
    }
    if (this.messageType === 'attachment' || this.messageType === 'video') {
      if (message.size || !this.size) {
        this.size = this.utils.formatSize(message.size);
      }
    }

    if (this.messageType === 'audio') {
      this.audioPlayerService.getDuration(this.message.attachmentUrl)
        .pipe(takeUntil(this.unsub$)).subscribe((size) => {

        this.duration = this.audioPlayerService.formatTime(size);

        this.totalDuration = this.duration;
        this.totalDurationTime = size;

        this.detector.detectChanges();
      });
    }

    if (this.message?.repliedMessage?.messageType === 'audio') {
      this.audioPlayerService.getDuration(this.message.repliedMessage.attachmentUrl)
        .pipe(takeUntil(this.unsub$)).subscribe((size) => {
          this.repliedAudioDuration = this.audioPlayerService.formatTime(size);
          this.detector.detectChanges();
        });
    }

    this.messageStatus = (message.status && message.status.toString()) || '10';

    const defaultDelay = 60 * 6000;
    const delay: number = (!message.size || message.size < defaultDelay) ?  defaultDelay : message.size;

    if (!this.statusCheckPlanned) {
      this.statusCheckPlanned = true;
      setTimeout(() => {
        this.statusCheckPlanned = false;
        if ((this.messageStatus && this.messageStatus.toString() === '10') && !this.hasQueue) {
          this.messageStatus = '20';
          if (!this.detector['destroyed']) {
            this.detector.detectChanges();
          }
        }
      }, delay);
    }

    if (this.message.formType === 'contact') {
      this.contactForm = {
        method: 'sms',
        value: '',
        valid: false,
        placeholder: '+44'
      };

      this.contactFormValue = new UntypedFormControl();
      /*
      this.contactFormValue.valueChanges
        .startWith('')
        .debounceTime(100)
        .pipe(takeUntil(this.unsub$))
        .subscribe((value: string) => {
          this.contactForm.value = value.trim();
          this.validateForm();
        });
        */
    }

    this.messageClass = {
      'with-avatar': this.message.avatar || this.message.avatarDefault,
      'from-me-edit': this.message.isOwn && this.contentEditable,
      'from-me': this.message.isOwn,
      'from-them': !this.message.isOwn,
      'emoji-only': this.utils.isMessageEmojiOnly(this.message),
      'without-native': !this.useNativeEmoji,
      'message-with-form': this.message.formType !== undefined,
      'shown': false,
      'without-tail': this._withoutTail
    };
    setTimeout(() => {
      this.messageClass['shown'] = true;
      if (!this.detector['destroyed']) {
        this.detector.detectChanges();
      }
    }, 50);
  }

  cancelUpload(): void {
    this.cancel.emit(this.message.attachmentId);
  }

  bypass(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  bypassHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  validateForm(): void {
    this.contactForm.valid = VALIDATORS[this.contactForm.method.toLowerCase()].test(this.contactForm.value);
  }

  openPreviewer(url: string) {
    this.showPreviewer.emit(url);
  }

  changeContactFormMethod(method: string): void {
    this.contactForm.method = method;
    this.contactForm.placeholder = PLACEHOLDERS[method];
    this.validateForm();
  }

  sendContactForm(): void {
    this.formSent = !this.formSent;
  }

  adjustImageSize(width: number, height: number): void {
    this.zone.runOutsideAngular(() => {
      const ratio = height / width;

      if (width > 210) {
        width = 210;
        height = width * ratio;
      }

      this.imageWidth = width;
      this.imageHeight = height;
    });
  }

  formatMessage() {
    this.zone.runOutsideAngular(() => {
      if (this.message.messageType === 'image' || this.message.messageType === 'gif') {
        this.adjustImageSize(this.message.width, this.message.height);
      }
      if (this.message.message === undefined) {
        return;
      }
      if (this.message.emojiOnly) {
        const match = this.message.message && this.message.message.match(COLONS_REGEX) &&
          this.message.message.match(COLONS_REGEX).length > 1 && this.message.message.match(COLONS_REGEX)[1];
        if (match) {
          this.message.emojiOnly = this.emojiService.checkIfExists(match);
        }
      }
      this.message.message = this.message.message.replace(/\n/ig, '<br>');

      this.displayMessage = this.utils.parseMessage(this.message.message, this.message.emojiOnly || this.utils.isMessageEmojiOnly(this.message), true);
      this.lastMessageValue = this.displayMessage;
    });
  }

  retrySendMessage() {
    this.retry.emit(this.message);
  }

  removeMessage() {
    this.remove.emit(this.message);
  }

  isEmoji(string: string): boolean {
    if (string === undefined) {
      return false;
    }
    return string.match(EMOJI_RANGE) && this.emojiStringToArray(string).length === 1;
  }

  private emojiStringToArray(string: string): Array<string> {
    return this.zone.runOutsideAngular(() => {
      const split = string.split(/([\uD800-\uDBFF][\uDC00-\uDFFF])/);
      const arr = [];
      for (let i = 0; i < split.length; i++) {
        const char = split[i];
        if (char !== '') {
          arr.push(char);
        }
      }
      return arr;
    });
  }

  editMessage(e: any) {
    //  e.stopPropagation();
    this.contentEditable = true;
    this.messageClass['from-me'] = false;
    this.messageClass['from-me-edit'] = true;
    this.setCaretAtTheEnd((this.messageInputEdit !== undefined) ? this.messageInputEdit.nativeElement : undefined);
  }

  sendMessage() {
    this.contentEditable = false;
    this.messageClass['from-me'] = true;
    this.messageClass['from-me-edit'] = false;
  }

  restartMessage() {
    this.contentEditable = false;
    this.messageClass['from-me'] = true;
    this.messageClass['from-me-edit'] = false;
    this.displayMessage = this.lastMessageValue;
    if (!this.detector['destroyed']) {
      this.detector.detectChanges();
    }
  }

  handleSelectEmoji(event: any): void {
    let text: any = event.emoji.native;

    const nElement = this.messageInputEdit.nativeElement;

    if (!this.utils.useNativeEmoji) {
      text = this.utils.getEmojiImg(event.emoji.colons);

      try {
        nElement.appendChild(text);
      } catch (e) {

      }
    } else {
      nElement.textContent += text;
    }

    if (this.currentSelection) {
      this.restoreSelection(this.currentSelection);
    }

    this.onMessageChange(nElement.innerHTML);
    this.toggleEmojiPicker.emit(false);
    this.setCaretAtTheEnd((this.messageInputEdit !== undefined) ? this.messageInputEdit.nativeElement : undefined);
  }

  restoreSelection(savedSelection: any) {
    if (window.getSelection) {
      const sel = window.getSelection();
      sel.removeAllRanges();
      for (let i = 0, len = savedSelection.length; i < len; ++i) {
        sel.addRange(savedSelection[i]);
      }
    } else if ((<any>document).selection && (<any>document).selection.createRange) {
      if (savedSelection) {
        savedSelection.select();
      }
    }
  }

  replaceSelection(content: any) {
    if (window.getSelection) {
      let range;
      const sel = window.getSelection();
      const node = typeof content === 'string' ? document.createTextNode(content) : content;

      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0);
        range.deleteContents();
        range.insertNode(node);
        range.setStart(node, 0);

        window.setTimeout(function() {
          range = document.createRange();
          range.setStartAfter(node);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
        }, 0);
      }
    } else if ((<any>document).selection && (<any>document).selection.createRange) {
      const range = (<any>document).selection.createRange();
      if (typeof content === 'string') {
        range.text = content;
      } else {
        range.pasteHTML(content.outerHTML);
      }
    }
  }

  closePicker(): boolean {
    this.emojiPickerVisible = false;
    this.detector.detectChanges();
    return true;
  }

  onEmojiClick () {
    this.emojiPickerVisible = !this.emojiPickerVisible;
  }

  normalizeMessage(msg: string = ''): string {
    return msg.replace(/(&nbsp;)/g, ' ');
  }

  getCharactersLen(_msg: string = ''): number {
    const msg = this.normalizeMessage(_msg || this.messageInputEdit && this.messageInputEdit.nativeElement &&
      this.messageInputEdit.nativeElement.innerHTML || '');
    const elm = document.createElement('div');

    elm.innerHTML = msg;

    const imagesLen = elm.getElementsByTagName('img').length;

    return (elm.textContent || elm.innerText || '').length + imagesLen;
  }

  saveSelection() {
    if (window.getSelection) {
      const sel = window.getSelection();
      const node = (<any>sel.anchorNode);
      if (node !== null && node.parentNode.closest('[contenteditable]') === null) {
        return this.currentSelection;
      }
      const ranges = [];
      if (sel.rangeCount) {
        for (let i = 0, len = sel.rangeCount; i < len; ++i) {
          ranges.push(sel.getRangeAt(i));
        }
      }
      return ranges;
    } else if ((<any>document).selection && (<any>document).selection.createRange) {
      const sel = (<any>document).selection;
      return (sel.type.toLowerCase() !== 'none') ? sel.createRange() : null;
    }
  }

  onMessageChange(model: string) {
    const message = this.normalizeMessage(model);
    this.charactersCount = this.getCharactersLen();
    if (!this.charactersCount) {
      this.floatError.emit(true);
    }
    this.changeMessage.emit(message);
    this.detector.detectChanges();
  }

  setCaretAtTheEnd(elm: HTMLElement): void {

    if (elm === undefined) {
      return;
    }

    elm.focus();

    if (typeof window.getSelection !== 'undefined' && typeof document.createRange !== 'undefined') {
      const range = document.createRange();
      range.selectNodeContents(elm);
      range.collapse(false);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (typeof (<any>document.body).createTextRange !== 'undefined') {
      const textRange = (<any>document.body).createTextRange();
      textRange.moveToElementText(elm);
      textRange.collapse(false);
      textRange.select();
    }
  }

  showHistoryMessage(template: TemplateRef<any>, e: any) {
    this.openModal(template);
  }

  openModal(template: TemplateRef<any>) {
    const source = { class: 'modal-channels' };
    this.modalRef = this.modalService.show(template, Object.assign({
      backdrop: true,
      ignoreBackdropClick: true
    }, source));
    this.detector.detectChanges();
  }

  onShown() {
    this.zindex = true;
    this.detector.detectChanges();
  }

  onHidden() {
    this.zindex = false;
    this.detector.detectChanges();
  }

  closeModal(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.modalRef && this.modalRef.hide();
    this.dialog.close();
    if (!this.detector['destroyed']) {
      this.detector.detectChanges();
    }
  }

  rewind(event: MouseEvent) {
    if (this.audioPlayerService.currentAudioUrl !== this.message.attachmentUrl) {
      return;
    }
    const marginLeft = event.offsetX;
    const allWidth = (event.target as HTMLDivElement).clientWidth;
    const newTime = (marginLeft / allWidth) * this.totalDurationTime;
    this.audioPlayerService.seekTo(newTime);
  }

  toggleAudioMessageState(): void {
    this.playing = !this.playing;

    if (this.playing) {

      if (this.audioSubscription !== undefined) {
        this.audioSubscription.unsubscribe();
      }

      this.audioSubscription = this.audioPlayerService
        .playStream(this.message.attachmentUrl, this.duration, this.durationInt)
        .pipe(takeUntil(this.unsub$))
        .subscribe((data) => {

          let formattedTime = this.audioPlayerService.formatTime(data.duration - data.currentTime);

          if (formattedTime === '-:-') {
            formattedTime = this.duration;
          }

          this.playing = data.playing;
          this.duration = formattedTime;
          this.totalDurationTime = data.duration
          this.percentDuration = data.durationPercentage;
          this.durationInt = data.currentTime;

          if (data.event === 'ended') {
            this.duration = this.totalDuration;
            this.playing = false;
            this.percentDuration = 0;
            this.durationInt = 0;
          }

          this.detector.detectChanges();
        });
    } else {
      if (this.audioSubscription !== undefined) {

        this.audioPlayerService.pause();

        // this.audioSubscription.unsubscribe();

        // this.audioSubscription = undefined;
      }
    }
  }

  replyToMessage() {
    this.onReplay.emit(this.message);
  }

  selectPerson() {
    if (this.isMessageInGroupChat && !this.message.isOwn) {
      this.onClickToPerson.emit(this.message.from.id);
    }
  }
}
