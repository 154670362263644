import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsComponent } from '@app/shared/alerts/alerts.component';
import { AlertComponent } from './alert/alert.component';

@NgModule({
  imports: [CommonModule],
  declarations: [AlertsComponent, AlertComponent],
  exports: [AlertsComponent, AlertComponent]
})
export class AlertsComponentModule { }
