import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { RegistrationApiService } from '@app/core/api/api.registration';

@Injectable({
  providedIn: 'root'
})
export class RegisterResolver implements Resolve<any> {
  constructor(
    private api: RegistrationApiService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot) {

    const token = route.paramMap.get('token');

    return this.api.checkRegistrationToken(token).pipe(map((data) => {

      if (!data.success) {
        this.router.navigate(['/'], { replaceUrl: true });
      }

      return data.data;
    }));
  }
}
