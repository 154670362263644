import { Injectable } from '@angular/core';
import { TelephonyApiService } from '@app/core/api/api.telephony';
import { FeatureAccessService } from '@app/core/feature-access.service';
import { ReplaySubject } from 'rxjs';
import { map, filter, publishReplay, refCount } from 'rxjs/operators';
import { SipAccount } from './sipuni.service';

@Injectable({
  providedIn: 'root',
})
export class TelephonyService {

  private telephonyList = new ReplaySubject<SipAccount[]>(1);
  public telephonyList$ = this.telephonyList.asObservable().pipe(
    publishReplay(1),
    refCount()
  );

  constructor(
    private telephonyApi: TelephonyApiService,
    private featureAccessService: FeatureAccessService
  ) {
    this.featureAccessService.optionAddedInTariff('sipuni')
      .subscribe(() => {
        this.updateListTelephony();
    });
  }

  updateListTelephony() {
    this.telephonyApi.getListTelephonyIntegrations().pipe(
      filter((res) => res.success),
      map(res => res.data),
    ).subscribe(data => this.telephonyList.next(data));
  }
}
