<div class="message" [class.last]="last">
  <div class="message-head">
    <i *ngIf="message.type !== 20" class="teletype-icon-circle-right" (click)="toggleEmailDataMaximized()"
       [class.shown]="dataShow && maximized"></i>
    <div [attr.title]="message?.from?.name" class="message-name" (click)="toggleMaximized()">
      {{ message?.from?.name }}
    </div>
    <div *ngIf="message?.messageType === 'text' && !last"
         (click)="toggleMaximized()"
         class="message-cut"
         [attr.title]="message?.message">
      <div *ngIf="!maximized && message?.message">
        {{ message?.message }}
      </div>
    </div>
    <div (click)="toggleMaximized()" class="message-date">{{ dateCreated }}</div>
  </div>

  <ul *ngIf="message.type !== 20 && dataShow && maximized" class="message-data">
    <li><b>{{ 'conversation.email.from' | translate }}:</b>
      <span *ngIf="fromPerson">{{ fromPerson.name }} &lt;{{ fromPerson.email }}&gt;</span></li>
    <li><b>{{ 'conversation.email.to' | translate }}:</b>
      <span *ngIf="toPerson">{{ toPerson.name }} &lt;{{ toPerson.email }}&gt;</span></li>
    <li><b>{{ 'conversation.email.date' | translate }}:</b>
      <span *ngIf="dateCreatedFull">{{ dateCreatedFull }}</span></li>
  </ul>

  <div *ngIf="maximized || last" class="message-body">
    <div *ngIf="message?.message && message?.messageType === 'text'" class="message-text"
         [innerHTML]="bypassHtml(displayMessage)"></div>

    <ng-template [ngIf]="displayQuote && quote.messageType === 'text'">
      <div class="quote-wrap">
        <div class="quote-switcher" (click)="showQuote = !showQuote"></div>
      </div>

      <div class="quote" *ngIf="showQuote">
        <div class="quote-data">{{ dateCreatedQuote }}:</div>
        <div class="quote-body" [innerHTML]="bypassHtml(displayQuote)"></div>
      </div>
    </ng-template>

    <div
      class="message-attachments" *ngIf="message?.attachments?.length"
      [class.border-top-0]="!message?.message && !displayQuote"
    >
      <div class="attachment-wrap">
        <div class="attachment"
             *ngFor="let attachment of message?.attachments"
             [class.attachment-image]="['gif', 'image'].includes(attachment.messageType)">
          <div class="attachment-picture"
               [style.backgroundImage]="'url('+ (attachment.attachmentPreviewUrl || attachment.attachmentUrl) +')'">
            <div *ngIf="attachment.progress < 100"
                 class="attachment-progress"
                 [style.width]="(100 - attachment.progress) + '%'"
            ></div>
            <span>{{ getExtensionOfFilename(attachment.attachment) }} {{ attachment.messageType }}</span>
            <a
              *ngIf="['gif', 'image'].includes(attachment.messageType); else downloadLink"
              class="attachment-download"
              (click)="openPreviewer(attachment.attachmentUrl || attachment.attachmentPreviewUrl)"
              [attr.href]="attachment.attachmentUrl"
            ></a>
            <ng-template #downloadLink>
              <a class="attachment-download"
                 [attr.href]="attachment.attachmentUrl" download></a>
            </ng-template>
          </div>
          <div class="attachment-name">{{ shortcutName(attachment.attachment) }}</div>
          <div class="attachment-size">{{ getFileSize(attachment.size) }}</div>
          <div *ngIf="ownMessage && attachment.progress === 100"
               class="attachment-remove"
               (click)="deleteAttachment(attachment.id)"></div>
        </div>
      </div>
    </div>
  </div>
</div>
