<app-force-pay
  tariffName="telegram"
  [channelId]="currentChannelId"
  (success)="onUpdateTariff($event)"
></app-force-pay>

<ng-container *ngIf="currentStep === STEPS.SELECT_METHOD">
  <div [class.lock-screen]="isChannelUnpaid">
    <ng-container
      [ngTemplateOutlet]="channelStateMessage"
      *ngIf="isEdit && channelStateDescription"
    ></ng-container>

    <ng-container
      [ngTemplateOutlet]="selectAuthTypeTmpl"
    ></ng-container>

    <div class="auth-link">
      <div class="text">{{ 'whatsapp.copyAuthLink' | translate }}</div>
      <span class="copy-link" (click)="getCode()"></span>
    </div>

    <app-alert *ngIf="apiError" [alertContent]="apiError"></app-alert>

    <div class="form-group btn-container">
      <button
        class="btn btn-primary"
        type="button"
        (click)="createInstance()"
        [disabled]="inProgress"
      >
        <app-spinner [inProgress]="inProgress" [btnText]="'button.next' | translate"></app-spinner>
      </button>
    </div>
  </div>

  <ng-container
    [ngTemplateOutlet]="deleteButton"
    *ngIf="isEdit"
  ></ng-container>
</ng-container>

<ng-container *ngIf="currentStep === STEPS.SCAN_QR">
  <ng-container [ngTemplateOutlet]="scanQr" *ngIf="selectedAuthType === AUTH_TYPE.QR"></ng-container>

  <app-alert *ngIf="apiError" [alertContent]="apiError"></app-alert>

  <ng-container [ngTemplateOutlet]="buttonBackToSelectAuthMethod"></ng-container>
</ng-container>

<ng-container *ngIf="currentStep === STEPS.INPUT_NUMBER">
  <div class="info-container">
    <p [innerHTML]="'telegram.inputPhoneNumber' | translate"></p>
  </div>

  <form class="form-container">
    <ng-container
      [ngTemplateOutlet]="inputPhoneNumber"
    ></ng-container>

    <app-alert *ngIf="apiError" [alertContent]="apiError"></app-alert>

    <button
      class="btn btn-primary"
      type="button"
      (click)="submitPhone()"
      [disabled]="phoneNumberControl.invalid || inProgress"
    >
      <app-spinner [inProgress]="inProgress" [btnText]="'button.next' | translate"></app-spinner>
    </button>

    <ng-container [ngTemplateOutlet]="buttonBackToSelectAuthMethod"></ng-container>
  </form>
</ng-container>

<ng-container *ngIf="currentStep === STEPS.INPUT_PINCODE">
  <form class="form-container" autocomplete="off">
    <ng-container *ngIf="selectedAuthType === AUTH_TYPE.PHONE">
      <div class="info-container">
        <span [innerHTML]="'telegram.pinWasSend' | translate: { phone: phone }"></span>
      </div>

      <ng-container
        [ngTemplateOutlet]="inputPinCode"
      ></ng-container>
    </ng-container>

    <ng-container *ngIf="isTwoFactorAuthentication">
      <div class="info-container">
        <span [innerHTML]="'telegram.inputCloudPassword' | translate"></span>
      </div>

      <ng-container
        [ngTemplateOutlet]="inputPassword"
      ></ng-container>
    </ng-container>

    <app-alert *ngIf="apiError" [alertContent]="apiError"></app-alert>

    <div class="form-group btn-container">
      <button
        class="btn btn-primary"
        type="button"
        (click)="finishAuth()"
        [disabled]="(isTwoFactorAuthentication ? passwordFormControl.invalid : pinCodeFormControl.invalid) || inProgress"
      >
        <app-spinner [inProgress]="inProgress" [btnText]="'button.connect' | translate"></app-spinner>
      </button>

      <ng-container [ngTemplateOutlet]="buttonBackToSelectAuthMethod"></ng-container>
    </div>
  </form>
</ng-container>

<ng-container *ngIf="currentStep === STEPS.CREATED">
  <ng-container
    [ngTemplateOutlet]="successConnectMessage"
    *ngIf="!isEdit"
  ></ng-container>

  <ng-container
    [ngTemplateOutlet]="phoneNumber"
    *ngIf="phone"
  ></ng-container>

  <div class="form-container">
    <ng-container
      [ngTemplateOutlet]="editChannelData"
    ></ng-container>

    <app-alert *ngIf="apiError" [alertContent]="apiError"></app-alert>

    <div class="form-group btn-container">
      <button class="btn btn-primary" type="button" (click)="saveChannelData()" [disabled]="inProgress">
        <app-spinner
          [inProgress]="inProgress"
          [btnText]="(isEdit ? 'button.saveChanges' : 'button.finish') | translate"
        ></app-spinner>
      </button>

      <ng-container [ngTemplateOutlet]="deleteButton"></ng-container>
    </div>
  </div>
</ng-container>

<!--мини компоненты-->

<!-- выбор типа авторизации -->
<ng-template #selectAuthTypeTmpl>
  <div class="select-method">
    <p class="select-method__text">
      {{ 'telegram.selectAuthMethod' | translate }}
    </p>

    <label class="select-method__method">
      <input
        type="radio"
        name="select-method"
        (change)="setAuthType(AUTH_TYPE.PHONE)"
        [checked]="selectedAuthType === AUTH_TYPE.PHONE"
      />
      {{ 'telegram.authByPhone' | translate }}
    </label>

    <label class="select-method__method">
      <input
        type="radio"
        name="select-method"
        (change)="setAuthType(AUTH_TYPE.QR)"
        [checked]="selectedAuthType === AUTH_TYPE.QR"
      />
      {{ 'telegram.authByQR' | translate }}
    </label>
  </div>
</ng-template>

<!--сканирование qr кода-->
<ng-template #scanQr>
  <div>
    <div class="img-wrapper">
      <qrcode
        *ngIf="qr"
        class="qr"
        [qrdata]="qr"
        [width]="264"
        [errorCorrectionLevel]="'M'"
        [elementType]="'svg'"
      ></qrcode>

      <div *ngIf="!qr" class="qr-bg" (click)="getQr()">
        <div class="qr-color">
          <div class="refresh" [class.rotate]="inProgress"></div>
          <div class="refresh-text">
            {{ 'telegram.refreshQR' | translate }}<br />{{ 'telegram.refreshQRpart' | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="note">
      <ul>
        <li [innerHTML]="'telegram.qrInstructionTitle' | translate"></li>
        <li [innerHTML]="'telegram.qrInstructionStep1' | translate"></li>
        <li [innerHTML]="'telegram.qrInstructionStep2' | translate"></li>
        <li [innerHTML]="'telegram.qrInstructionStep3' | translate"></li>
      </ul>
    </div>
  </div>
</ng-template>

<!--сообщение об успешном отключении-->
<ng-template #successConnectMessage>
  <div class="info-container">
    <p><b>{{ 'telegram.done' | translate }}</b></p>
    <p>{{ 'telegram.doneDescription' | translate }}</p>
  </div>
</ng-template>

<!--ввод номера телефона-->
<ng-template #inputPhoneNumber>
  <div
    class="form-group"
    [ngClass]="{'filled': phoneNumberControl.value, 'error': phoneNumberControl.touched && phoneNumberControl.invalid}"
  >
    <input
      type="text"
      appAutofocus
      [skip]="phoneNumberControl.value"
      class="form-control"
      [formControl]="phoneNumberControl"
      autocomplete="off"
      [placeholder]="'telegram.phonePlaceHolder' | translate"
      required
    />
    <span class="floating-label">{{ 'telegram.phonePlaceHolder' | translate }}</span>
    <span class="form-group-error">
      {{ phoneNumberControl.hasError('pattern') ? ('error.phoneInvalid' | translate) : 'error.phoneEmpty' | translate }}
    </span>
  </div>
</ng-template>

<!--ввод пинкода-->
<ng-template #inputPinCode>
  <div
    class="form-group"
    [ngClass]="{'filled': pinCodeFormControl.value, 'error': pinCodeFormControl.touched && pinCodeFormControl.invalid}"
  >
    <input
      type="number"
      class="form-control"
      autocomplete="off"
      [formControl]="pinCodeFormControl"
      [placeholder]="'telegram.pinCode' | translate"
      required
    />
    <span class="floating-label">{{ 'telegram.pinCode' | translate }}</span>

    <ng-container *ngIf="pinCodeFormControl.touched && pinCodeFormControl.invalid">
      <span class="form-group-error" *ngIf="pinCodeFormControl.hasError('required')">
        {{ 'telegram.pinEmpty' | translate  }}
      </span>

      <span class="form-group-error" *ngIf="pinCodeFormControl.hasError('pattern')">
        {{ 'telegram.pinInvalid' | translate }}
      </span>
    </ng-container>
  </div>
</ng-template>

<!--ввод пароля-->
<ng-template #inputPassword>
  <div
    class="form-group"
    [ngClass]="{'filled': passwordFormControl.value, 'error': passwordFormControl.touched && passwordFormControl.invalid}"
  >
    <input
      type="text"
      class="form-control"
      autocomplete="off"
      [formControl]="passwordFormControl"
      [placeholder]="'telegram.password' | translate"
      required
    />
    <span class="floating-label">{{ 'telegram.password' | translate }}</span>

    <ng-container *ngIf="passwordFormControl.touched && passwordFormControl.invalid">
      <span class="form-group-error" *ngIf="passwordFormControl.hasError('required')">
        {{ 'telegram.passwordEmpty' | translate  }}
      </span>
    </ng-container>
  </div>
</ng-template>

<!--редактирование данных канала-->
<ng-template #editChannelData>
  <form [formGroup]="channelData">
    <div
      class="form-group"
      [ngClass]="{'filled': channelData.value.channelName, 'error': channelData.get('channelName')?.invalid}"
    >
      <input
        type="text"
        class="form-control"
        formControlName="channelName"
        autocomplete="off"
        [placeholder]="'placeHolder.name' | translate"
      />
      <span class="floating-label">{{ 'placeHolder.channelName' | translate }}</span>
    </div>

    <div class="form-group">
      <label class="checkbox-container" [ngClass]="{'checked': channelData.value.groupChatAllowed}">
        <input type="checkbox" formControlName="groupChatAllowed" />
        <span>{{ 'whatsapp.groupChatAllowed' | translate }}</span>
      </label>
    </div>
  </form>
</ng-template>

<!--номер телефона-->
<ng-template #phoneNumber>
  <div class="phone-number">
    {{ 'telegram.phoneNumber' | translate }}
    <span>{{ phone }}</span>
  </div>
</ng-template>

<!--сообщение о статусе канала-->
<ng-template #channelStateMessage>
  <div class="phone-number">
    {{ channelStateDescription }}
  </div>
</ng-template>

<!--кнопка удаления канала-->
<ng-template #deleteButton>
  <button class="btn btn-delete" type="button" (click)="removeTelegram()" [disabled]="removeInProgress">
    <app-spinner [inProgress]="removeInProgress" [btnText]="'button.deleteChannel' | translate"></app-spinner>
  </button>
</ng-template>

<!--кнопка возвращения к выбору методу авторизации-->
<ng-template #buttonBackToSelectAuthMethod>
  <button class="btn btn-delete" type="button" (click)="backToSelectAuthMethod()" [disabled]="inProgress">
    <span>{{ 'telegram.backToSelectAuthMethod' | translate }}</span>
  </button>
</ng-template>
