<div class="wrapper">
  <div
    class="avatar"
    [style.width.px]="size"
    [style.background-color]="backgroundColor"
    [style.height.px]="size"
  >
    <div
      *ngIf="avatarUrl()"
      class="avatar-image"
      [style.background-color]="'transparent'"
      [ngClass]="{'pointer': withPreviewer && originalAvatar}"
      (click)="withPreviewer && originalAvatar && showPreviewer()"
      [style.background-image]="avatarUrl()"
    >
    </div>

    <div
      [style.font-size.px]="fontSize"
      class="avatar-initials-helper"
    >
      <i
        *ngIf="animal"
        class="teletype-icon-{{ _animal }}"
        [ngClass]="{'big': size === 60}"
      ></i>
    </div>
  </div>

  <div class="avatar-online-status" [hidden]="!isOnline"></div>
</div>
