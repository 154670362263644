import {
  Component,
  Input,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-image-previewer',
  templateUrl: './image-previewer.component.html',
  styleUrls: ['./image-previewer.component.scss'],
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImagePreviewerComponent implements AfterViewInit {

  @Input() image: string;
  @Output() hide: EventEmitter<any> = new EventEmitter();

  public canDisplay = false;

  constructor(
    private sanitizer: DomSanitizer,
    private detector: ChangeDetectorRef
  ) { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.canDisplay = true;
      this.detector.detectChanges();
    }, 200);
  }

  bypass(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  close() {
    this.canDisplay = false;
    this.detector.detectChanges();
    setTimeout(() => {
      this.hide.emit();
    }, 300);
  }
}
