<div class="control-row" *ngIf="!isLoading">
  <app-loader
    class="saving-loader"
    [isLoading]="isSaving"
  ></app-loader>

  <ng-container *ngFor="let customField of customFields">
    <ng-container *ngIf="customFieldsFormGroup.controls[customField.id] as control">
      <div class="info-row" [class._vertical]="customField.type === CustomFieldType.Text">
        <div>{{ customField.title }}:</div>

        <ng-container [ngSwitch]="customField.type">
          <div *ngSwitchCase="CustomFieldType.Bool" class="switch-container">
            <label class="checkbox-container" [ngClass]="{'checked': Boolean(control.value)}">
              <input type="checkbox" [value]="Boolean(control.value)" (click)="setControlValue(control, !control.value, CustomFieldsDefaultValue[customField.type])" />
              <span>{{ customField.description }}</span>
            </label>
          </div>

          <div *ngSwitchCase="CustomFieldType.FixedSet" class="dropdown-container">
            <ng-select
              [items]="customField.values"
              [placeholder]="('button.choose' | translate) + '...'"
              [searchable]="false"
              [formControl]="control"
              (select)="setControlValue(control, $event, CustomFieldsDefaultValue[customField.type])"
            ></ng-select>
          </div>

          <div
            class="custom-field-input"
            contenteditable="true"
            *ngSwitchDefault
            [attr.placeholder]="customField.description"
            appTeletypeContentEditable
            [maxLength]="CustomFieldsMaxLength[customField.type]"
            (changeText)="setControlValue(control, $event, CustomFieldsDefaultValue[customField.type])"
            [defaultValue]="control.value || ''"
          >
          </div>
          <div class="popover" *ngIf="(customField.type === CustomFieldType.Text || customField.type === CustomFieldType.String) && getUrls(control.value).length" [class.for-long-text]="customField.type === CustomFieldType.Text">
            <a
              [href]="href"
              *ngFor="let href of getUrls(control.value)"
              target="_blank"
            >{{ href }}</a>
          </div>
        </ng-container>
      </div>

      <div class="error" *ngIf="control.touched && control.invalid">
        {{ CustomFieldsErrorMessage[customField.type] | translate }}
      </div>
    </ng-container>
  </ng-container>

  <!--<button-->
  <!--  *ngIf="customFieldsFormGroup.dirty"-->
  <!--  class="save-btn btn custom-fields__save-btn"-->
  <!--  [disabled]="customFieldsFormGroup.dirty && customFieldsFormGroup.invalid"-->
  <!--  (click)="setPersonCustomFields()"-->
  <!--&gt;-->
  <!--  Сохранить-->
  <!--</button>-->
</div>

<app-loader
  *ngIf="isLoading"
  class="loader"
  [isLoading]="true"
></app-loader>
