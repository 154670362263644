import { Injectable } from '@angular/core';
import { UserData } from '@app/core/api/api.profile';
import { Observable } from 'rxjs';
import { ApiService } from '@app/core/api/api.service';
import { Response } from '@app/core/interfaces/api.response';
import { routes } from '@app/core/config/apiRoutes';
import { UtilsService } from '@app/core/utils.service';
import { LoginContext } from '@app/core';

export interface SetPasswordData {
  passwordKey: string;
  password: string;
  passwordRepeat: string;
  name?: string;
  language?: string;
  lastName?: string;
}

export interface AuthResponse extends Response {
  data?: {
    accessToken: string
    language: string
  };
}

export type AuthElamaResponse = Response<{
  accessToken: string;
  language: string
  client: UserData
}>;

@Injectable({
  providedIn: 'root',
})
export class LoginApiService {
  private routes = routes;
  constructor(
    private api: ApiService,
    private utils: UtilsService,
  ) {

  }

  public authByElama(code: string): Observable<AuthElamaResponse> {
    return this.api.httpPostNew(this.routes.elamaAuth, { code })
  }

  public auth(credentials: LoginContext): Observable<AuthResponse> {
    return this.api.httpPostNew(this.routes.auth, credentials)
  }

  public setOperatorPassword(data: SetPasswordData): Observable<any> {
    return this.api.httpPost(this.routes.setPassword(data), this.utils.snakeKeys(data));
  }

  public clientRestore(data: any): Observable<any> {
    return this.api.httpPost(this.routes.clientRestore, this.utils.snakeKeys(data));
  }
}
