<div>
  <p>
    {{ 'settings.remappingWarning' | translate }}
  </p>
  <div>
    <ng-select class="ng-custom-select operator-select"
               id="selectItem"
               [items]="operators"
               bindLabel="name"
               bindValue="id"
               placeholder="{{ 'settings.remapPlaceHolder' | translate }}"
               [clearable]="false"
               [(ngModel)]="selectedOperatorId"
               [disabled]="inProgress"
               (close)="onClose()">
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <div class="avatar"
             [style.background-image]="'url(' + (item.avatar || item.avatarDefault) + ')'"></div>
        <div class="name">{{ item.name + ' ' + item.lastName | maxLength : 45 : true }}</div>
      </ng-template>
      <ng-template ng-label-tmp let-item="item">
        <div class="avatar"
             [style.background-image]="'url(' + (item.avatar || item.avatarDefault) + ')'"></div>
        <div class="name">{{ item.name + ' ' + item.lastName | maxLength : 45 : true }}</div>
      </ng-template>
      <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
        <div class="ng-option disabled">
          {{ 'crm.notFound' | translate }}
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div class="buttons-container">
    <button class="btn btn-delete"
            type="button"
            (click)="deleteTeammate()"
            [disabled]="inProgress">
      <span>
        {{ (onlyRemapping && 'button.confirm' | translate) || 'settings.deleteTeammate' | translate }}
      </span>
    </button>

    <button class="btn btn-default"
            type="button"
            (click)="deleteCancel()"
            [disabled]="inProgress">
      <span>{{ 'button.cancel' | translate }}</span>
    </button>
  </div>
</div>
