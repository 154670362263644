import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '@app/core/api/api.service';
import { ProjectService } from '@app/core/project.service';
import { combineLatest, Subject} from 'rxjs';
import { TariffService } from '@app/core/tariff.service';
import { ChannelService } from '@app/core/channel.service';
import { OperatorService } from '@app/core/operator.service';
import { UserService } from '@app/core/user.service';
import { TariffApiService } from '@app/core/api/api.tariff';
import { AnswerPatternApiService } from '@app/core/api/api.answer-pattern';
import { TeammateApiService } from '@app/core/api/api.teammate';
import { ChannelApiService } from '@app/core/api/api.channel';

@Injectable({
  providedIn: 'root'
})
export class FullLoadGuard implements CanActivate {

  private observer = new Subject();
  private fullLoadingComplete: boolean;

  constructor(
    private router: Router,
    private api: ApiService,
    private teammateApi: TeammateApiService,
    private answerPatternApi: AnswerPatternApiService,
    private channelApi: ChannelApiService,
    private apiTariffs: TariffApiService,
    private projectService: ProjectService,
    private tariffService: TariffService,
    private channelService: ChannelService,
    private operatorService: OperatorService,
    private userService: UserService,
  ) { }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> | boolean {
    if (this.fullLoadingComplete) {
      return true;
    } else {
      if (!this.projectService.projects$.getValue()) {
        this.projectService.updateProject();
      }
      if (!this.userService.user) {
        this.userService.getUser();
      } else {
        this.userService.isUserLoaded.next(true);
      }
      if (!this.operatorService.operators$.getValue()) {
        this.operatorService.updateOperatorsList();
      }
      if (!this.tariffService.tariffs$.getValue()) {
        this.tariffService.loadTariffs();
      }
      if (!this.channelService.channels$.getValue()) {
        this.channelService.updateChannelsList();
      }
      return new Promise<boolean>(resolve => {
        this.projectService.isProjectsLoaded$.pipe(
          takeUntil(this.observer)
        ).subscribe(isLoading => {
          if (isLoading) {
            combineLatest([
              this.userService.isUserLoaded$,
              this.operatorService.isOperatorsLoaded$,
              this.tariffService.isTariffsLoaded$,
              this.channelService.isChannelsLoaded$,
            ]).subscribe(([user, operator, tariff, channel]) => {
              if (user && operator && tariff && channel) {
                this.fullLoadingComplete = true;
                resolve(true);
              }
            });
          }
        });
      });
    }
  }
}
