<div class="count-down" *ngIf="days || hours || minutes"
     [class.blue]="days >= 2"
     [class.yellow]="days < 2 && days >= 1"
     [class.red]="days === 0 && minutes > 0"
>
  <div class="count-down__label">
    <span>{{'promotion.text' | translate}}</span>
  </div>

  <div class="count-down__value">
    <i class="teletype-icon-count-timer"></i>
    <ng-container *ngIf="days > 0">
      <span>{{ '0' + days }}</span>
      <span class="count-down__text">{{'promotion.day' | translate}}</span>
    </ng-container>

    <ng-container *ngIf="hours > 0">
      <span>{{ hours < 10 ? '0' + hours : hours }} </span>
      <span class="count-down__text">{{'promotion.hour' | translate}}</span>
    </ng-container>

    <span>{{ minutes < 10 ? '0' + minutes : minutes}}</span>
    <span class="count-down__text">{{'promotion.minutes' | translate}}</span>
  </div>
</div>
