import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';

const kByte = 1024;
const mByte = kByte * kByte;

@Injectable({
  providedIn: 'root'
})
export class PreviewService {
  public base64image = new BehaviorSubject<any>(null);
  public imageSendComplete = new BehaviorSubject<string>('');
  public imageSendProgress = new BehaviorSubject<number>(null);
  public imageSendError = new BehaviorSubject<boolean>(false);
  public tempImages = [];

  constructor(
    private http: HttpClient,
  ) { }

  public addNextImage(img){
    this.tempImages.push(img)
  }

  public popFirst(){
    return this.tempImages.shift()
  }


  public get base64image$() {
    return this.base64image.asObservable();
  }

  public get imageSendComplete$() {
    return this.imageSendComplete.asObservable();
  }

  public get imageSendProgress$() {
    return this.imageSendProgress.asObservable();
  }

  public get imageSendError$() {
    return this.imageSendError.asObservable();
  }

  public retrieveImageFromClipboardAsBlob(pasteEvent: ClipboardEvent) {
    const items = pasteEvent.clipboardData.items;
    if (!items) { return; }
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') === -1) { continue; }
      const blob = items[i].getAsFile();
      const overSize = blob.size > mByte * 20;
      this.blobToBase64(blob).then((img: string) => {
        this.showPreviewImage(img, overSize);
      });
    }
  }

  private showPreviewImage(img: string, overSize: boolean): void {
    this.base64image.next({ img, overSize });
  }

  private blobToBase64 = blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  }

  private upload(file: File, route: string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    const req = new HttpRequest('POST', route, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  public sendImage(file: any, route: string): void {
    this.upload(file, route).subscribe(
      (event: any) => {
        const response = event?.body || null;
        if (response && response.success) {
          this.imageSendComplete.next(response.data.attachment_id);
          this.closePreview();
        }
        if (event.type === HttpEventType.UploadProgress) {
          this.imageSendProgress.next(Math.round(100 * event.loaded / event.total));
        }
      },
      err => {
        this.imageSendError.next(true);
        console.log('Could not upload the image!');
      });
  }

  public closePreview(): void {
    this.base64image.next(undefined);
  }
}
