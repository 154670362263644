import {
  Component,
  OnInit,
  Renderer2,
  OnDestroy,
  ChangeDetectorRef,
  NgZone,
  ChangeDetectionStrategy
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CookiesService } from '@app/core/cookies.service';
import { isNavigationEnd } from '@app/core/helpers/isNavigationEnd';
import { PushNotificationsService } from '@app/core/push-notifications.service';
import { TranslateService } from '@ngx-translate/core';
import {merge, Subject} from 'rxjs';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger, I18nService } from '@app/core';
import { AlertsService } from './core/alerts/alerts.service';

const log = new Logger('App');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false
})

export class AppComponent implements OnInit, OnDestroy {

  // private previousUrl: string;
  // private file: any = `${window.document.location.protocol}//${window.document.location.host}/assets/audio/silent.mp3`;
  // private sound: HTMLAudioElement = new Audio(this.file);

  private get defaultLanguage(): string {
    const languageFromQuery = (new URLSearchParams(window.location.search)).get('language');
    let savedLanguage = languageFromQuery || this.cookies.get('language') || navigator.language || environment.defaultLanguage;
    if (environment.features.onlyRuLanguage) {
      savedLanguage = 'ru';
    }
    return savedLanguage;
  }

  private unsub$ = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private renderer: Renderer2,
    private zone: NgZone,
    private detector: ChangeDetectorRef,
    private alerts: AlertsService,
    private cookies: CookiesService,
    private pushNotificationsService: PushNotificationsService,
  ) { }

  ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    log.debug('init');

    // Setup translations
    this.i18nService.init(this.defaultLanguage, environment.supportedLanguages);

    this.activatedRoute.queryParams.pipe(takeUntil(this.unsub$))
      .subscribe((data: any) => {

        if (data.language !== undefined && this.i18nService.supportedLanguages.includes(data.language)) {
          this.i18nService.language = data.language;
        }
        this.detector.markForCheck();
      });

    const onNavigationEnd = this.router.events.pipe(filter(event => isNavigationEnd(event)));

    onNavigationEnd
      .pipe(takeUntil(this.unsub$))
      .subscribe(() => {
        this.alerts.removeAll();
        this.detector.detectChanges();
      });

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .pipe(takeUntil(this.unsub$))
      .subscribe(event => {

        const title = event['title'];
        if (title) {

          const language = this.i18nService.translateService.translations[this.i18nService.language];

          if (language !== undefined) {
            const translatedTitle = language.route[title.toLowerCase()];

            this.titleService.setTitle(translatedTitle || this.translateService.instant(title));
          }
        }

        this.renderer.setAttribute(
          document.getElementsByTagName('html')[0], 'id',
          event.bodyId || '');
        this.detector.detectChanges();
      });

    // document.addEventListener('visibilitychange', this.visibilityChangeHandler.bind(this));
  }

  ngOnDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
    // document.removeEventListener('visibilitychange', this.visibilityChangeHandler.bind(this));
  }

  // visibilityChangeHandler() {
  //   this.sound.autoplay = true;
  //   this.sound.loop = true;
  //
  //   if (document.hidden) {
  //     this.sound.addEventListener("canplaythrough", (event) => {
  //       /* аудио может быть воспроизведено; проиграть, если позволяют разрешения */
  //       this.sound.play();
  //     });
  //   } else {
  //     this.sound.currentTime = 0;
  //     this.sound.pause();
  //   }
  // }

}
