import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm, UntypedFormBuilder, Validators } from '@angular/forms';
import { extract, I18nService } from '@app/core';
import { AlertsService } from '@app/core/alerts/alerts.service';
import each from 'lodash-es/each';
import { Meta, Title } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { EMAIL_REGEX } from '@app/core/config/regExValidators';
import { ActivatedRoute } from '@angular/router';
import { AuthResponse, LoginApiService } from '@app/core/api/api.login';
import { LocationService } from '@app/core/location.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  resetPasswordForm: UntypedFormGroup;
  resetPasswordSubmitted = false;
  isLoading = false;

  errorsList: any = {};
  private unsub$ = new Subject<void>();

  @ViewChild('resetPasswordFormForm', { read: NgForm, static: true }) resetPasswordFormForm: NgForm;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private alerts: AlertsService,
    private meta: Meta,
    private api: LoginApiService,
    private detector: ChangeDetectorRef,
    private i18nService: I18nService,
    private titleService: Title,
    private route: ActivatedRoute,
    private locationService: LocationService,
  ) {
    const title = this.route.snapshot.data['title'];
    const language = this.i18nService.translateService.translations[this.i18nService.language];
    if (title) {
      this.titleService.setTitle(language.route[title.toLowerCase()]);
    }
  }

  createForm() {
    this.resetPasswordForm = this.formBuilder.group({
      email: ['', Validators.compose(([Validators.required, Validators.pattern(EMAIL_REGEX)]))]
    });
  }

  validateResetPasswordOnSubmit() {
    const emailInputState = this.resetPasswordForm.get('email');
    this.errorsList.email = emailInputState.errors !== null;
  }

  handleFieldBlur(form: string, field: string) {

    const fieldState = this[form].get(field);
    this.errorsList[field] = !fieldState.valid && fieldState.value.trim() !== '';
  }

  validateOnSubmit(formName: string = 'loginForm') {

    const form = this[formName];

    each(form.controls, (control, name) => {
      this.errorsList[name] = control.errors !== null;
    });
  }

  ngOnInit() {
    this.alerts.removeAll();
    this.createForm();
    this.meta.updateTag({
      content: 'width=device-width, initial-scale=1',
      name: 'viewport'
    });
  }

  ngOnDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  extract(string: string) {
    return extract(string);
  }

  resetPassword() {
    this.validateResetPasswordOnSubmit();

    if (this.resetPasswordForm.valid === false) {
      return;
    }

    this.isLoading = true;
    this.alerts.removeAll();
    this.api.clientRestore({ email: this.resetPasswordForm.value.email })
      .pipe(takeUntil(this.unsub$)).subscribe((data: AuthResponse) => {
        if (data.success) {
          this.resetPasswordSubmitted = true;
        }
      }, () => {}, () => {
        this.isLoading = false;
        this.detector.detectChanges();
      });
  }

  public goToLanding() {
    this.locationService.goToLanding();
  }
}
