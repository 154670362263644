import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { PermissionsService } from '@app/core/permissions.service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UserService } from '../user.service';

/**
 * Гуард проверяет: закончил ли пользователь полностью регистрацию и если нет, то отправляет его завершить ее
 * */
@Injectable()
export class UserInfoFulledGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private permissionsService: PermissionsService,
    private router: Router,
  ) { }

  canActivate(): Observable<boolean> {
    return this.userService.isUserLoaded$.pipe(
      filter(isLoaded => isLoaded),
      map(() => {
        const user = this.userService.user;
        const isOwner = this.permissionsService.isOwner();
        if (user && isOwner && user.status === 15) {
          this.router.navigate(['/registration/complete'], { replaceUrl: true });
        }
        return true;
      }),
    );
  }
}
