<button type="button"
        class="close"
        aria-label="Close"
        (click)="closeModal()">
  <i class="teletype-icon-close"></i>
</button>

<div class="container">
  <h2 class="header" [innerHTML]="'sidebar.elamaSupport.title' | translate"></h2>

  <p [innerHTML]="'sidebar.elamaSupport.write' | translate"></p>

  <p [innerHTML]="'sidebar.elamaSupport.call' | translate"></p>

  <div class="phone-numbers">
    <div class="cities-container">
      <p class="city">{{ 'sidebar.elamaSupport.moscow' | translate }}</p>
      <p class="city">{{ 'sidebar.elamaSupport.spb' | translate }}</p>
      <p class="city">{{ 'sidebar.elamaSupport.other' | translate }}</p>
    </div>

    <div class="phones-container">
      <a href="tel:84992702790" class="phone">8 (499) 270‒27‒90</a>
      <a href="tel:88123184054" class="phone">8 (812) 318‒40‒54</a>
      <a href="tel:88005003190" class="phone">8 (800) 500‒31‒90</a>
    </div>
  </div>
</div>

<img
  class="elama-logo"
  src="/assets/img/elama-logo.png"
>
