import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '@app/core';

@Pipe({
  name: 'channelName'
})
export class ChannelNamePipe implements PipeTransform {

  private readonly translations: any;

  constructor(
    private i18nService: I18nService
  ) {
    this.translations = this.i18nService.translateService.translations[this.i18nService.language];
  }

  transform(value: string): string {
    return this.translations && this.translations.channelNames[value] || value;
  }

}
