import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthorizedGuard implements CanActivate {

  constructor(
    private authenticationService: AuthenticationService
  ) { }

  canActivate(): boolean {
    if (this.authenticationService.isAuthenticated()) {
      this.authenticationService.backToPrevPage();
      return false;
    }

    return true;
  }
}
