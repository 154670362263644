import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  NgZone,
  Output,
  EventEmitter
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { GroupChatIcon } from '@app/core/interfaces/api.response';

@Component({
  selector: 'app-group-avatar',
  templateUrl: './group-avatar.component.html',
  styleUrls: ['./group-avatar.component.scss'],
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupAvatarComponent implements OnInit {

  @Input() groupAvatar: GroupChatIcon | null = null;
  @Input() size: number | string;

  avatarUrl(): SafeStyle {
    return this.zone.runOutsideAngular(() => {
      return this.sanitizer.bypassSecurityTrustStyle(`url(${this.groupAvatar.previewUrl})`);
    });
  }

  constructor(
    private zone: NgZone,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {

  }

}
