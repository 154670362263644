import { ChangeDetectorRef, Directive, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '([formControlName], [formControl])[disableControl]',
})
export class DisableControlDirective {
    @Input()
    public set disableControl(condition: boolean) {
        setTimeout(() => {
            this.toggle(condition);
        }, 100);
    }

    constructor(
        private readonly ngControl: NgControl,
        private readonly detector: ChangeDetectorRef) {
    }

    private toggle(condition: boolean): void {
        if (condition) {
            this.ngControl?.control?.disable();
        } else {
            this.ngControl?.control?.enable();
        }
        this.detector.detectChanges();
    }

}
