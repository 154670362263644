import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { ProjectService } from '@app/core/project.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectGuard implements CanActivate {

  constructor(
    private projectService: ProjectService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> | boolean {
    const projects = this.projectService.projects$.getValue();
    if (projects) {
      return true;
    }
    return new Promise<boolean>(resolve => {
      this.projectService.isProjectsLoaded$.subscribe(isLoaded => {
        if (isLoaded) {
          resolve(true);
        }
      });
    });
  }
}
