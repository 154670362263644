import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import each from 'lodash-es/each';

import { environment } from '@env/environment';
import { AlertsService } from '@app/core/alerts/alerts.service';
import { Logger } from '@app/core/logger.service';
import { AuthenticationService } from '@app/core/authentication/authentication.service';

const log = new Logger('ErrorHandlerInterceptor');

interface ApiError {
  code: number;
  message: string;
}

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(
    private auth: AuthenticationService,
    private alerts: AlertsService
  ) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((error) => throwError(this.errorHandler(error))));
  }

  // Customize the default error handler here if needed
  private errorHandler(response: any): Observable<HttpEvent<any>> {
    const { errors } = response.error;

    if (response.status === 401) {
      this.auth.redirectToLogin();
      return of(null);
    }

    if (errors !== undefined && errors.length > 0) {
      each(errors, (error: ApiError) => {
        this.alerts.addAlert({
          type: 'error',
          content: error.message,
          dismissable: true
        });
      });
    }

    if (!environment.production) {
      // Do something with the error
      log.error('Request error', response);
    }
    return response;
  }

}
