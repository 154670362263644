<div class="modal-header">
  <h4 class="modal-title pull-left">{{ title | translate }}</h4>
  <button *ngIf="showCloseButton"
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="hideModalWithCancel()">
    <i class="teletype-icon-close"></i>
  </button>
</div>
<div class="modal-body" [innerHTML]="content | translate"></div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" *ngIf="cancelButtonText" (click)="hideModalWithCancel()">
    {{ cancelButtonText | translate }}
  </button>
  <button type="button" class="btn btn-primary" (click)="hideModal()">{{ okButtonText | translate }}</button>
</div>
