import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DadataResponse} from './models/dadata-response';
import {Bounds, DadataConfig, Location} from './dadata-config';
import { map } from 'rxjs/operators';

export enum DadataType {
  fio = 'fio',
  address = 'address',
  party = 'party',
  bank = 'bank',
  email = 'email'
}

@Injectable({
  providedIn: 'root'
})
export class DadataService {
  apiKey = '';

  constructor(private http: HttpClient) {
  }

  setApiKey(key: string) {
    this.apiKey = key;
  }

  // eslint-disable-next-line max-len
  getData(value: string, type: DadataType | string = DadataType.address, config: DadataConfig): Observable<DadataResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Token ${this.apiKey}`,
      })
    };
    const body = Object.assign(
      {},
      {query: value},
      {count: config && config.limit ? config.limit : null},
      {location: config && config.locations ? config.locations : null},
      {location_bust: config && config.locationsBoost ? config.locationsBoost : null},
      {from_bound: config.bounds ? config.bounds.fromBound : null},
      {to_bound: config.bounds ? config.bounds.toBound : null}
    );
    const baseUrl = `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/${type}`;
    return this.http.post<DadataResponse>(baseUrl, body, httpOptions)
    .pipe(map((data) => {
      return data;
    }));
  }
}
