import { Component, Injectable, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Injectable()
@Component({
  selector: 'app-dialog',
  templateUrl: './modal.component.html'
})

export class DialogComponent implements OnInit {
  title: string;
  okButtonText: string;
  cancelButtonText: string;
  content: string;
  okCallback: Function;
  cancelCallback: Function;
  showCloseButton = true;

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit() { }

  hideModalWithCancel(): void {
    this.cancelCallback();
    this.bsModalRef.hide();
  }

  hideModal(): void {
    this.okCallback();
    this.bsModalRef.hide();
  }
}
