import { AppealResponseDataItem } from '@app/core/api/api.conversation';
import { Attachment } from '@app/core/interfaces/attachment.service';
import {Permission} from '@app/core/permissions.service';

export interface Response<T = any> {
  success: boolean;
  data?: T;
  errors: any;
}

export interface MissingLanguageVariableContext {
  string: string;
  lang: string;
}

export interface UploadFileResponse {
  success: boolean;
  error: Array<string>;
  errors?: any;
  data: any;
  datetime: string;
}

export interface ChannelData {
  operators?: any[];
  pipelineStatus?: any;
  pipeline?: any;
  exportLead?: boolean;
  autoUpload?: boolean;
  state?: string | any;
  active?: boolean;
  enabled?: boolean;
  creationStep?: 10 | 20 | 30 | 40;
  appealsCount?: number;
  id?: string;
  name?: string;
  type: string;
  defaultName?: string;
  phoneNumber?: any;
  email?: string;
  crms?: Array<any>;
  checked?: boolean;
  isCreated?: boolean;
  username?: string;
  instagramCommentsCreated?: boolean;
  instagramDirectCreated?: boolean;
  url?: string;
  groupId?: string;
  switchInProgress?: string;
  statusTooltip?: string;
  tooltipText?: string;
  enabledChat?: boolean;
  amoUsers?: Array<any>;
  login?: string; // для вк директ канала
  channelId?: string;
  sortOrder?: number;
  apiKey?: string;
  edna?: {
    channelId: number,
    cascadeId: number,
    name: string,
    subjectId: unknown,
    subject: unknown,
    registrationStatus: unknown,
    instruction: string
  }
}

export interface OperatorData {
  avatar?: string;
  avatarDefault?: string;
  companyName?: string;
  companyRole?: string;
  companyScope?: string;
  companySize?: string;
  email: string;
  id: string;
  language?: string;
  lastName?: string;
  login: string;
  name: string;
  online?: boolean;
  phone?: any;
  roles?: Array<string>;
  status?: any;
  timezone?: string;
  group?: any;
  statusTooltip?: string;
  enabled?: boolean;
  invited?: boolean;
  permissions?: Permission[];
}

export interface PasswordKeyData {
  projectId: string;
}

export type DateFromApi = {
  date: string;
  timezone: string;
};

export interface AvitoPost {
  id: string;
  postUrl: string;
  caption: string; // название объявления
  sentAt: DateFromApi;
  createdAt: DateFromApi;
  attachments: Omit<Attachment, 'id' | 'progress'>[];
}

export interface GroupChatIcon {
  url: string;
  previewUrl: string;
  size: number; // мб не number, на момент описания непонятно
  width?: number;
  height?: number;
}

export interface GroupChatInfo {
  caption: string;
  createdAt: DateFromApi;
  icon: GroupChatIcon | null;
  id: string;
  sentAt: DateFromApi;
}

export interface ChannelSpecific {
  post?: AvitoPost;
  groupChat?: GroupChatInfo;
  [key: string]: any;
}

export interface ConversationData {
  selectedAppealId: string;
  selectedAppealName: string | null;
  selectedAppealMessenger: string | null;
  appealUrl: string | null;
  items: AppealResponseDataItem[] | null;
  messagesCount: number;
  personAvatar: string | null;
  personId: string;
  personName: string;
  isOpen: boolean;
  subject: string | null;
  channelName: string;
  to?: number;
  from?: number;
  channelSpecific?: ChannelSpecific;
  userName?: any;
  userNameComments?: string;
  postCaption?: any;
}
