import {
  ChangeDetectionStrategy, Component, ElementRef, Input, OnInit,
} from '@angular/core';
import { ChannelService, ChannelType } from '@app/core/channel.service';

const BASE_PATH = '/assets/icons/channels-type-icons/';

const TYPE_TO_ICON = {
  'facebook': 'chat-facebook.svg',
  'messenger': 'chat-facebook.svg',
  'mail': 'chat-email.svg',
  // TODO разобраться почему в статистике тип канала определяется вот так
  'email': 'chat-email.svg',
  'telegram': 'chat-telegram.svg',
  'telegram-bot': 'chat-telegram.svg',
  'avito': 'chat-avito.svg',

  'instagram_comment': 'chat-instagram.svg',
  'instagram_comments_business': 'chat-instagram.svg',
  'instagram_direct_business': 'chat-instagram.svg',
  'instagram_direct': 'chat-instagram.svg',

  'ok': 'chat-ok.svg',
  'viber': 'chat-viber.svg',
  'vk': 'chat-vk.svg',
  'vk_direct': 'chat-vk.svg',

  'whatsapp': 'chat-whatsapp.svg',
  'whatsapp_teletype': 'chat-whatsapp.svg',
  'whatsapp_edna': 'whatsapp-business.svg',

  'telephone': 'chat-phone.svg',
  'widget': 'icon-chat.svg',
  // TODO разобраться почему в статистике тип канала определяется вот так
  'web_widget': 'icon-chat.svg',
};

@Component({
  selector: 'app-channel-type-icon',
  templateUrl: './channel-type-icon.component.html',
  styleUrls: ['./channel-type-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelTypeIconComponent implements OnInit {
  @Input() private channelType: string;
  @Input() private channelId: string;

  private get channelTypeById(): string {
    return this.channelService.getChannelTypeById(this.channelId);
  }

  private get type(): string {
    return this.channelType || this.channelTypeById;
  }

  private get iconUrl(): string {
    return BASE_PATH + TYPE_TO_ICON[this.type];
  }

  constructor(
    private channelService: ChannelService,
    private elem: ElementRef<HTMLElement>,
  ) {}

  public ngOnInit(): void {
    const elem = this.elem.nativeElement;
    elem.style.backgroundImage = `url(${this.iconUrl})`;
  }
}
