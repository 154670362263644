import {
  Directive,
  AfterContentInit,
  OnChanges,
  OnDestroy,
  ElementRef,
  SimpleChanges,
  NgZone,
  Input
} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

const BASE_TIMER_DELAY = 10;

@Directive({
  selector: '[autofocus], [appAutofocus]'
})
export class AutofocusDirective implements AfterContentInit, OnChanges, OnDestroy {

  @Input() skip: any = false;

  public shouldFocusElement: any;
  public timerDelay: number | any;
  private timer: any;
  readonly isMobile: boolean;

  constructor(
    private elementRef: ElementRef,
    private deviceService: DeviceDetectorService,
    private zone: NgZone
  ) {

    this.shouldFocusElement = '';
    this.timer = 0;
    this.timerDelay = BASE_TIMER_DELAY;

    this.isMobile = ['ios', 'iphone', 'ipad', 'android'].includes(this.deviceService.device.toLowerCase());

    this.isMobile = false;
  }
  public ngAfterContentInit(): void {

    if (!this.skip && !this.isMobile && this.shouldFocusElement === '') {
      this.startFocusWorkflow();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.zone.runOutsideAngular(() => {
      if (!this.isMobile && changes.timerDelay && (typeof (this.timerDelay) !== 'number')) {
        if (isNaN(this.timerDelay = +this.timerDelay)) {
          this.timerDelay = BASE_TIMER_DELAY;
        }
      }
      if (!this.isMobile && changes.shouldFocusElement) {
        (this.shouldFocusElement) ? this.startFocusWorkflow() : this.stopFocusWorkflow();
      }
    });
  }

  public ngOnDestroy(): void {
    this.stopFocusWorkflow();
  }

  private startFocusWorkflow(): void {
    if (this.timer || this.isMobile) {
      return;
    }
    this.timer = setTimeout(() => {
      this.timer = null;
      this.elementRef.nativeElement.focus();
    }, this.timerDelay);
  }

  private stopFocusWorkflow(): void {
    clearTimeout(this.timer);
    this.timer = null;
  }
}
