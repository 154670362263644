import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable , of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ProjectService } from '@app/core/project.service';
import { ProjectData } from '@app/core/api/api.project';

@Injectable({
  providedIn: 'root'
})
export class ShellResolver implements Resolve<ProjectData[]> {

  constructor(
    private prs: ProjectService
  ) { }

  resolve(): Observable<ProjectData[]> {
    if (!this.prs.projects || Object.keys(this.prs.projects).length === 0) {
      this.prs.getProjects().pipe(map(() => true), take(1));
    }
    return of(this.prs.projects);
  }

  update() {
    this.prs.updateProject();
  }
}
