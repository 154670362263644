import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { ChannelService } from '@app/core/channel.service';
import { ChannelData } from '@app/core/interfaces/api.response';
import { ApiService } from './api.service';
import { routes } from '../config/apiRoutes';

export enum InstagramOptions {
  COMMENTS = 'instagramComments',
  DIRECT = 'instagramDirect',
}

export enum InstagramSetupSteps {
  WELCOME = 'welcome',
  CREDENTIALS = 'credentials',
  CODE = 'code',
  AUTH = 'auth',
  COMPLETE = 'complete',
  REAUTH = 'reAuth',
}

interface UpdateData {
  instagramCommentsCreated: boolean;
  instagramDirectCreated: boolean;
  instagramCommentsName?: string;
  instagramDirectName?: string;
}

export const TYPE_SPACES = {
  instagram_comment: 'instagramComments',
  instagram_direct: 'instagramDirect',
};

@Injectable({
  providedIn: 'root',
})
export class ApiInstagramService {
  private routes = routes;
  private instagramChannels: Array<ChannelData> = [];

  constructor(
    private api: ApiService,
    private zone: NgZone,
    private channelService: ChannelService,
  ) {
    this.channelService.getAllChannels().subscribe((channels: Array<ChannelData>) => {
      if (!channels) {
        return;
      }

      this.instagramChannels = channels.filter((channel) => channel.type.indexOf('instagram') !== -1
        && channel.type.indexOf('instagram_comments_business') === -1);
    });
  }

  private getChannelById(id: string) {
    return this.instagramChannels.find((channel) => channel.id === id);
  }

  private getParentChannel(userName: string) {
    return this.instagramChannels.find((channel) => {
      const type = TYPE_SPACES[channel.type] || channel.type;
      return channel.username === userName && type === InstagramOptions.DIRECT;
    });
  }

  public getChannelsByUserName(userName: string) {
    return this.instagramChannels.filter((channel: ChannelData) => channel.username === userName);
  }

  public getSingleChannelInstagramUserName() {
    const userNames = this.instagramChannels.filter((channel) => !channel.isCreated).map((channel) => channel.username);
    return Array.from(new Set(userNames).values());
  }

  public getSingleChannels() {
    return this.instagramChannels.filter((channel) => channel.isCreated);
  }

  public create(channel: any, selectedType: InstagramOptions = InstagramOptions.DIRECT): Observable<any> {
    // return this.zone.runOutsideAngular(() => {
    const userChannels = this.getChannelsByUserName(channel.username);

    if (userChannels.length > 0) {
      const data: UpdateData = { instagramCommentsCreated: false, instagramDirectCreated: true };
      const parentChannel = this.getParentChannel(channel.username);
      return this.api.httpPost(this.routes.instagram.update(parentChannel.id), data, true);
    }
    channel.instagramCommentsCreated = false;
    channel.instagramDirectCreated = true;
    delete channel.authCode;
    delete channel.code;
    return this.api.httpPost(this.routes.instagram.create, channel, true);

    // });
  }

  public resendPassword(
    channelId: string,
    password: string,
    authMethod: string = 'phone',
  ): Observable<any> {
    return this.zone.runOutsideAngular(() => {
      const data = {
        authType: 'auth',
        authMethod,
        password,
      };

      const channel = this.getChannelById(channelId);
      const parentChannel = this.getParentChannel(channel.username);

      return this.api.httpPost(this.routes.instagram.resendPassword(parentChannel.id), data, true);
    });
  }

  public recheckPassword(data: { code: string, password: string }, channelId: string, authMethod: string = 'phone')
    : Observable<any> {
    return this.zone.runOutsideAngular(() => {
      const _data = {
        authStep: 'cp',
        password: data.password,
        code: data.code,
        authMethod,
      };
      const channel = this.getChannelById(channelId);
      const parentChannel = this.getParentChannel(channel.username);

      return this.api.httpPost(this.routes.instagram.setCode(parentChannel.id), _data, true);
    });
  }

  public auth(code: string, authStep: string, channelId: string): Observable<any> {
    return this.zone.runOutsideAngular(() => {
      const data = {
        authStep,
        code,
      };
      const channel = this.getChannelById(channelId);
      const parentChannel = this.getParentChannel(channel.username);

      return this.api.httpPost(this.routes.instagram.setCode(parentChannel.id), data, true);
    });
  }

  public update(channelId: string,
    name: string,
    selectedType: InstagramOptions = InstagramOptions.DIRECT): Observable<any> {
    return this.zone.runOutsideAngular(() => {
      const channel = this.getChannelById(channelId);
      const userChannels = this.getChannelsByUserName(channel.username);
      const commentsChannel = userChannels.find((_channel: ChannelData) => {
        const currentType = TYPE_SPACES[_channel.type] || _channel.type;
        return currentType === InstagramOptions.COMMENTS;
      });
      const createdChannels = userChannels.filter((_channel) => _channel.isCreated);
      const parentChannel = this.getParentChannel(channel.username);
      const data: UpdateData = { instagramCommentsCreated: false, instagramDirectCreated: false };

      if (createdChannels.length > 1) {
        data.instagramCommentsName = commentsChannel.name;
        data.instagramDirectName = parentChannel.name;

        if (selectedType === InstagramOptions.COMMENTS) {
          data.instagramCommentsName = name;
        } else {
          data.instagramDirectName = name;
        }
      } else {
        data.instagramDirectName = name;
      }

      return this.api.httpPost(this.routes.instagram.update(parentChannel.id), data, true);
    });
  }

  public delete(channel: ChannelData): Observable<any> {
    return this.zone.runOutsideAngular(() => {
      const userChannels = this.getChannelsByUserName(channel.username);
      const createdChannels = userChannels.filter((_channel) => _channel.isCreated);
      const parentChannel = this.getParentChannel(channel.username);

      const data = {
        instagramCommentsCreated: false,
        instagramDirectCreated: true,
      };

      const type = TYPE_SPACES[channel.type] || channel.type;

      if (createdChannels.length > 1) {
        data.instagramCommentsCreated = false;
        data.instagramDirectCreated = true;

        return this.api.httpPost(this.routes.instagram.update(parentChannel.id), data, true);
      }
      return this.api.httpGet(this.routes.instagram.delete(parentChannel.id));
    });
  }

  public logout(channel: ChannelData): Observable<any> {
    return this.zone.runOutsideAngular(() => this.api.httpGet(this.routes.instagram.logout(channel.id)));
  }

  public privateReply(messageId: string, data: any): Observable<any> {
    return this.zone.runOutsideAngular(() => this.api.httpPost(this.routes.privateReply(messageId), data));
  }
}
