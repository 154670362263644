import { Injectable } from '@angular/core';
import Favicon from 'favico.js';

@Injectable({
  providedIn: 'root',
})
export class FaviconService {

  private favicon: any;

  constructor() {
    this.favicon = new Favicon({
      bgColor: '#5CB85C',
      textColor: '#ff0',
    });
  }

  show(count: number) {
    this.favicon.badge(count);
  }
}
