import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SocketsLogService } from '@app/core/sockets-log.service';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
  Response,
  MissingLanguageVariableContext,
  PasswordKeyData,
} from '@app/core/interfaces/api.response';

import { EmojiService } from '@app/shared/emoji/emoji/public_api';
import { DeviceDetectorService } from 'ngx-device-detector';
import { routes } from '@app/core/config/apiRoutes';
import { TranslateService } from '@ngx-translate/core';
import { CookiesService } from '@app/core/cookies.service';
import { UtilsService } from '@app/core/utils.service';

interface PostRequestOptions {
  snakeCaseIsRequired?: boolean;
  skipParse?: boolean;
}

interface ApiError {
  message: string;
  response: Response<unknown>
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public routes = routes;
  public errorPrefix = 'Error, could not get ';

  constructor(
    private http: HttpClient,
    private zone: NgZone,
    private emojiService: EmojiService,
    private deviceService: DeviceDetectorService,
    public translateService: TranslateService,
    private cookies: CookiesService,
    private utils: UtilsService,
    private socketLogService: SocketsLogService,
  ) { }

  public getHeader(ignoreXProjectId: boolean = false) {
    let headers = new HttpHeaders();

    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');

    const lang: string = this.cookies.get('language') || this.translateService.getBrowserCultureLang();
    if (lang) {
      headers = headers.append('Accept-Language', lang);
    }

    const roiStat = this.cookies.get('roistat_visit');
    if (roiStat) {
      headers = headers.append('X-Roistat', roiStat);
    }

    const yandexMetrikaUID = this.cookies.get('_ym_uid');
    if (yandexMetrikaUID) {
      headers = headers.append('X-Yandex', yandexMetrikaUID);
    }

    return headers;
  }

  public httpGet(route: string, requiredXProjectId: boolean = false, needParse = true) {
    return this.zone.runOutsideAngular(() => {
      const _headers = this.getHeader();

      const logAnswer = this.socketLogService.logHttpRequest('GET', route, _headers, '-');

      return this.http.get(route, { headers: _headers })
        .pipe(
          map((body: any) => {
            logAnswer(body);
            if (needParse) {
              return this.utils.camelKeys(body);
            }

            return body;
          }),
          catchError((err) => {
            logAnswer(err, true);
            return of('Error, could not complete ' + route + ' ' + err);
          })
        );
    });
  }

  /** @deprecated Используйте httpPostNew */
  public httpPost(route: string, data: any = {}, snakeCaseIsRequired: boolean = false, needParse = true) {
    return this.zone.runOutsideAngular(() => {
      const _headers = this.getHeader();
      const logAnswer = this.socketLogService.logHttpRequest('POST', route, _headers, data);
      const sendData = snakeCaseIsRequired ? this.utils.snakeKeys(data) : data;
      return this.http.post(route, this.utils.serialize(sendData), { headers: _headers })
        .pipe(
          map((body: any) => {
            logAnswer(body);
            return needParse ? this.utils.camelKeys(body) : body;
          }),
          catchError((err) => {
            logAnswer(err, true);
            return throwError('Error, could not complete ' + route + ' ' + err);
          })
        );
    });
  }

  public httpPostNew(route: string, data: any = {}, options?: PostRequestOptions) {
    return this.zone.runOutsideAngular(() => {
      const _headers = this.getHeader();
      const logAnswer = this.socketLogService.logHttpRequest('POST', route, _headers, data);
      const sendData = options?.snakeCaseIsRequired ? this.utils.snakeKeys(data) : data;
      return this.http.post(route, this.utils.serialize(sendData), { headers: _headers })
        .pipe(
          map((body: any) => {
            logAnswer(body);
            return options?.skipParse ? body : this.utils.camelKeys(body);
          }),
          catchError((error) => {
            logAnswer(error, true);
            return throwError(error.error);
          }),
        );
    });
  }

  public logout(): Observable<Response> {
    return this.httpGet(this.routes.logout);
  }

  public getPassKey(data: PasswordKeyData): Observable<Response> {
    return this.httpGet(this.routes.passwordKey(data));
  }

  public switchPushNotification(data: any): Observable<Response> {
    return this.httpPost(this.routes.switchPushNotification, this.utils.snakeKeys(data));
  }

  // public pushNotificationInfo(data: any): Observable<Response> {
  //   return this.httpGet(this.routes.pushNotificationInfo(data.firebaseToken));
  // }

  // public setFirebaseToken(token: string): Observable<any> {
  //   return this.httpPost(this.routes.setFirebaseToken, this.utils.snakeKeys({ firebaseToken: token }));
  // }

  public getCRMList(): Observable<Response> {
    return this.httpGet(this.routes.crms);
  }

  public uploadGiphy(url: string): any {
    return this.httpPost(this.routes.uploadUrl(), { url: url });
  }

  public setNewsSeen(id: string) {
    return this.httpGet(this.routes.newsSeen(id));
  }

  public GetStatisticItem(item: string, from: number | null = null, to: number | null = null) {
    let url = `${this.routes.statistics.prefix}${this.routes.statistics.routes[item]}`;
    const pieces = [];

    if (from !== null && from > 0) {
      pieces.push(`from=${from}`);
    }

    if (to !== null && to > 0) {
      pieces.push(`to=${to}`);
    }

    if (pieces.length > 0) {
      url += `?${pieces.join('&')}`;
    }
    return this.httpGet(url);
  }

  public getAppealsInfo(): Observable<Response> {
    return this.httpGet(this.routes.appealsInfo);
  }

  public sendError(data: any): Observable<Response> {
    return this.httpPost(this.routes.createError, this.utils.snakeKeys(data));
  }

  // TODO: Review, strange method
  public updateAvatar(avatar: string | boolean) {
    return this.zone.runOutsideAngular(() => {
      return of({
        data: {
          errors: [],
          success: true
        }
      });
    });
  }
}
