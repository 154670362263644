import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input, ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-circle-progress-bar',
  templateUrl: './circle-progress-bar.component.html',
  styleUrls: ['./circle-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircleProgressBarComponent implements AfterViewInit {

  @Input() set progress(value: number) {
    this._progress = value;
    this.calcuate();
  };

  @Input() primaryColor: string = 'rgb(255,255,255, 0.25)';
  @Input() accentColor: string = '#fff';
  @Input() size: number = 30;

  @ViewChild('meter', { read: ElementRef }) meter: ElementRef;

  private _progress: number = 0;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.calcuate();
  }

  calcuate() {
    const meter = this.meter?.nativeElement as SVGPathElement;

    if (meter) {
      let length = meter.getTotalLength();

      let to = length * ((100 - this._progress) / 100);

      meter.getBoundingClientRect();
      meter.style.strokeDashoffset = Math.max(0, to).toString();

      this.cdr.markForCheck();
    }
  }
}
