import {
  ChangeDetectorRef,
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  HostListener,
  OnDestroy
} from '@angular/core';
import { ApiService } from '@app/core/api/api.service';
import { Message } from '@app/core/interfaces/message';
import { takeUntil } from 'rxjs/operators';
import { DateTime } from 'luxon';
import {fromEvent, Subject, Subscription} from 'rxjs';
import { UtilsService } from '@app/core/utils.service';

@Component({
  selector: 'app-message-input-compact',
  templateUrl: './message-input-compact.component.html',
  styleUrls: ['./message-input-compact.component.scss']
})
export class MessageInputCompactComponent implements OnInit, OnDestroy {
  @ViewChild('messageInput', { read: ElementRef, static: true }) messageInput: ElementRef;
  @Input() defaultText?: string;
  @Input() limit = 1000;
  @Output() changeMessage = new EventEmitter<string>();
  @Output() toggleEmojiPicker = new EventEmitter<boolean>();
  @Output() sendEmoji = new EventEmitter<string>();
  @Output() sendGiphy = new EventEmitter<Message>();
  @Output() floatError = new EventEmitter<boolean>();

  private subs$: Subscription;
  private unsub$ = new Subject<void>();
  public isEditing = false;
  public emojiPickerVisible = false;
  public message: string;
  public currentSelection: any;
  public charactersCount = 0;

  @HostListener('keyup', ['$event'])
  @HostListener('keydown', ['$event'])
  @HostListener('mousedown', ['$event'])
  onMouseDown(e: Event) {
    // 8 -backspace 47 - delete
    // @ts-ignore
    if (this.getCharactersLen() >= this.limit && e.type !== 'mousedown' && ![8, 46].includes(e.keyCode)) {
      e.preventDefault();
    }
    this.currentSelection = this.saveSelection();
    this.charactersCount = this.getCharactersLen();
  }

  @HostListener('blur', ['$event'])
  onBlur(e: Event) {
    this.floatError.emit(!!this.charactersCount);
  }

  @HostListener('keyup', ['$event'])
  onKeyup(e: Event) {
    this.floatError.emit(!!this.charactersCount);
  }

  constructor(
    private api: ApiService,
    private detector: ChangeDetectorRef,
    private utils: UtilsService,
  ) {
  }

  ngOnInit() {
    if (this.defaultText) {
      this.message = this.utils.parseMessage(this.defaultText, false, false);
      this.charactersCount = this.getCharactersLen(this.message);
    }
  }

  ngOnDestroy() {
    this.unsub$.next();
this.unsub$.complete();
  }

  onMessageChange(model: string) {
    const message = this.normalizeMessage(model);
    this.charactersCount = this.getCharactersLen();
    if (!this.charactersCount) {
      this.floatError.emit(true);
    }
    this.changeMessage.emit(message);
    this.detector.detectChanges();
  }

  normalizeMessage(msg: string = ''): string {
    return msg.replace(/(&nbsp;)/g, ' ');
  }

  getCharactersLen(_msg: string = ''): number {
    const msg = this.normalizeMessage(_msg || this.messageInput && this.messageInput.nativeElement &&
        this.messageInput.nativeElement.innerHTML || '');
    const elm = document.createElement('div');

    elm.innerHTML = msg;

    const imagesLen = elm.getElementsByTagName('img').length;

    return (elm.textContent || elm.innerText || '').length + imagesLen;
  }

  charactersLeft(): number {
    const left = this.limit - this.charactersCount;
    return left < 0 ? 0 : left;
  }

  onPaste(text: any) {
    const currentLen = this.getCharactersLen();
    const nElement = this.messageInput.nativeElement;
    const deleteCount = this.limit - currentLen;

    if (this.currentSelection) {
      this.restoreSelection(this.currentSelection);
    }
    this.replaceSelection(text.slice(0, deleteCount));
    this.onMessageChange(nElement.innerHTML);
  }

  onMessageInputClick(e: any) {
    e.stopPropagation();
    if (!this.isEditing) {
      this.isEditing = true;
      this.subs$ = fromEvent(window, 'click')
        .subscribe(() => {
          this.isEditing = false;
          if (this.subs$ && !this.subs$.closed) {
            this.subs$.unsubscribe();
          }
        });
    }
  }

  onEmojiClick (event: any) {
    this.emojiPickerVisible = !this.emojiPickerVisible;
    event.stopPropagation();
    if (!this.isEditing) {
      this.setCaret(this.messageInput.nativeElement, false);
    }
  }

  private setCaret(target: any, isStart: any) {
    const range = document.createRange();
    const sel = window.getSelection();
    if (isStart) {
      const newText = document.createTextNode('');
      target.appendChild(newText);
      range.setStart(target.childNodes[0], 0);
    } else {
      range.selectNodeContents(target);
    }
    range.collapse(isStart);
    sel.removeAllRanges();
    sel.addRange(range);
    target.focus();
  }

  handleSelectEmoji(event: any): void {
    if (this.getCharactersLen() >= this.limit) {
      return;
    }

    let text: any = event.emoji.native;
    const nElement = this.messageInput.nativeElement;

    if (!this.utils.useNativeEmoji) {
      text = this.utils.getEmojiImg(event.emoji.colons);
    }

    if (nElement.textContent === '') {
      this.sendEmoji.emit(text.outerHTML || text);
      this.toggleEmojiPicker.emit(false);
      return;
    }

    if (this.currentSelection) {
      this.restoreSelection(this.currentSelection);
    }

    try {
      this.replaceSelection(text);
    } catch (e) {
    }

    this.onMessageChange(nElement.innerHTML);
    this.toggleEmojiPicker.emit(false);
  }

  handleSendGiphy(giphy: any): void {
    this.api.uploadGiphy(giphy.image)
      .pipe(takeUntil(this.unsub$))
      .subscribe((data) => {
        const message: Message = {
          isOwn: true,
          date: {
            date: DateTime.local().toString(),
            timezone: DateTime.local().zoneName
          },
          attachment: 'Giphy',
          attachmentId: data.data.attachmentId,
          attachmentUrl: data.data.attachment,
          width: giphy.originalWidth || giphy.width,
          height: giphy.originalHeight || giphy.height,
          messageType: 'gif'
        };
        this.sendGiphy.emit(message);
      });
  }

  saveSelection() {
    if (window.getSelection) {
      const sel = window.getSelection();
      const node = (<any>sel.anchorNode);
      if (node !== null && node.parentNode.closest('[contenteditable]') === null) {
        return this.currentSelection;
      }
      const ranges = [];
      if (sel.rangeCount) {
        for (let i = 0, len = sel.rangeCount; i < len; ++i) {
          ranges.push(sel.getRangeAt(i));
        }
      }
      return ranges;
    } else if ((<any>document).selection && (<any>document).selection.createRange) {
      const sel = (<any>document).selection;
      return (sel.type.toLowerCase() !== 'none') ? sel.createRange() : null;
    }
  }

  restoreSelection(savedSelection: any) {
    if (window.getSelection) {
      const sel = window.getSelection();
      sel.removeAllRanges();
      for (let i = 0, len = savedSelection.length; i < len; ++i) {
        sel.addRange(savedSelection[i]);
      }
    } else if ((<any>document).selection && (<any>document).selection.createRange) {
      if (savedSelection) {
        savedSelection.select();
      }
    }
  }

  replaceSelection(content: any) {
    if (window.getSelection) {
      let range;
      const sel = window.getSelection();
      const node = typeof content === 'string' ? document.createTextNode(content) : content;

      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0);
        range.deleteContents();
        range.insertNode(node);
        range.setStart(node, 0);

        window.setTimeout(function() {
          range = document.createRange();
          range.setStartAfter(node);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
        }, 0);
      }
    } else if ((<any>document).selection && (<any>document).selection.createRange) {
      const range = (<any>document).selection.createRange();
      if (typeof content === 'string') {
        range.text = content;
      } else {
        range.pasteHTML(content.outerHTML);
      }
    }
  }

  closePicker(): boolean {
    this.emojiPickerVisible = false;
    this.detector.detectChanges();
    return true;
  }
}
