<div class="control-row" *ngIf="!isLoading">
  <app-loader
    class="saving-loader"
    [isLoading]="isSaving"
  ></app-loader>

  <ng-container *ngFor="let customField of customFields">
    <ng-container *ngIf="customFieldsFormGroup.controls[customField.id] as control">
      <div class="info-row" [class._vertical]="customField.type === CustomFieldType.Text">
        <div>{{ customField.title }}:</div>

        <div *ngIf="customField.type === CustomFieldType.Bool; else edit" class="switch-container">
          <label class="checkbox-container" [ngClass]="{'checked': Boolean(control.value)}">
            <input type="checkbox" [value]="Boolean(control.value)" (click)="setControlValue(control, !control.value, CustomFieldsDefaultValue[customField.type])" />
            <span>{{ customField.description }}</span>
          </label>
        </div>

        <ng-template #edit>
          <div
            class="custom-field-input"
            contenteditable="true"
            [attr.placeholder]="customField.description"
            appTeletypeContentEditable
            [maxLength]="CustomFieldsMaxLength[customField.type]"
            (changeText)="setControlValue(control, $event, CustomFieldsDefaultValue[customField.type])"
            [defaultValue]="control.value || ''"
          ></div>
        </ng-template>
      </div>

      <div class="error" *ngIf="control.touched && control.invalid">
        {{ CustomFieldsErrorMessage[customField.type] | translate }}
      </div>
    </ng-container>
  </ng-container>

  <!--<button-->
  <!--  *ngIf="customFieldsFormGroup.dirty"-->
  <!--  class="save-btn btn custom-fields__save-btn"-->
  <!--  [disabled]="customFieldsFormGroup.dirty && customFieldsFormGroup.invalid"-->
  <!--  (click)="setPersonCustomFields()"-->
  <!--&gt;-->
  <!--  Сохранить-->
  <!--</button>-->
</div>

<app-loader
  *ngIf="isLoading"
  class="loader"
  [isLoading]="true"
></app-loader>
