import { Injectable } from '@angular/core';
import { ANALYTICS_EVENT } from '@app/core/metrika.service';

@Injectable({
  providedIn: 'root'
})
export class FaceBookPixelService {

  constructor() {}

  track(trackName: ANALYTICS_EVENT | string, args?: Record<string, unknown>) {
    const fbq = (<any>window).fbq;

    if (fbq === undefined) {
      return;
    }

    if (args) {
      fbq('track', trackName, args);
    } else {
      fbq('track', trackName);
    }
  }
}
