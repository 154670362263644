import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';

import { Layer, Stage, Image as KonvaImage, Vector2d, ImageConfig } from 'konva';
import { MatSliderChange } from '@angular/material/slider';

@Component({
  selector: 'app-photo-editor',
  templateUrl: './photo-editor.component.html',
  styleUrls: ['./photo-editor.component.scss']
})
export class PhotoEditorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() photo: string;
  @Input() size = 400;
  @Input() defaultPosition: any;
  @Input() defaultZoom = 1.0;
  @Output() result = new EventEmitter<string>();
  @Output() zoom = new EventEmitter<string>();
  @Output() coordinates = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();

  public scale = this.defaultZoom;
  public oldScale = this.scale;
  public newScale = this.scale;

  public vW = 640;
  public vH = 480;

  private currentPos: any;

  private image: HTMLImageElement;
  private stage: Stage;
  private stageImg: KonvaImage;
  private layer;

  constructor() {
  }

  ngOnInit() {
    this.currentPos = this.defaultPosition;
    this.newScale = this.oldScale = this.scale = this.defaultZoom;

    if (this.photo !== undefined) {
      this.loadImage();
    }
  }

  ngOnDestroy() {
    this.result.emit(this.layer.getCanvas().toDataURL('image/png', 100));
    if (this.stageImg !== undefined) {
      this.stageImg.off('dragend');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // eslint-disable-next-line max-len
    if (changes.photo !== undefined && changes.photo.firstChange === false && changes.photo.currentValue !== changes.photo.previousValue) {
      this.scale = 1;
      this.defaultZoom = 1;
      this.newScale = 1;
      this.defaultPosition = null;
      this.loadImage();
    }
  }

  clear() {
  }

  getCorrectImageSize() {
    // We need to be able to keep as much picture as possible
    const d = this.image.width < this.image.height ? 'p' : 'l';
    if (d === 'l') {
      const ratio = this.image.width / this.image.height;

      this.image.height = this.size;
      this.image.width = Math.round(this.image.height * ratio);
    } else {
      const ratio = this.image.height / this.image.width;

      this.image.width = this.size;
      this.image.height = Math.round(this.image.width * ratio);
    }
  }

  loadImage() {
    this.image = new Image();
    this.image.crossOrigin = 'Anonymous';
    this.image.onload = () => {
      this.getCorrectImageSize();

      this.draw();
    };

    this.image.src = this.photo;
  }

  draw() {
    this.clear();

    this.vW = this.image.width;
    this.vH = this.image.height;

    this.stage = new Stage({
      container: 'canvas_container',
      width: this.size,
      height: this.size,
    });

    this.layer = new Layer();

    this.image.width = this.vW * this.defaultZoom;
    this.image.height = this.vH * this.defaultZoom;

    this.currentPos = {
      x: (this.size / 2) - (this.image.width / 2),
      y: (this.size / 2) - (this.image.height / 2)
    };

    if (this.defaultPosition) {
      this.currentPos = this.defaultPosition;
    }

    // this.stage.position(this.currentPos);
    this.coordinates.emit(this.currentPos);
    this.zoom.emit(this.newScale.toString());

    // this.scaleImage(false);

    const params: ImageConfig = {
      image: this.image,
      x: 0,
      y: 0,
      width: this.vW,
      height: this.vH,
      scale: {
        x: this.defaultZoom,
        y: this.defaultZoom
      },
      draggable: true,
      dragBoundFunc: (pos: Vector2d) => {
        this.currentPos = {
          x: pos.x > 0 ? 0 : pos.x - this.size < this.image.width * -1 ? (this.image.width - this.size) * -1 : pos.x,
          y: pos.y > 0 ? 0 : pos.y - this.size < this.image.height * -1 ? (this.image.height - this.size) * -1 : pos.y
        };

        this.coordinates.emit(this.currentPos);
        return this.currentPos;
      }
    };

    this.stageImg = new KonvaImage(params);
    this.stageImg.on('dragend', () => {
      this.result.emit(this.layer.getCanvas().toDataURL('image/png', 100));
    });

    this.stageImg.position(this.currentPos);
    this.layer.add(this.stageImg);
    this.stage.add(this.layer);
    this.stage.batchDraw();
    this.result.emit(this.layer.getCanvas().toDataURL('image/png', 100));
  }

  scaleImage(changePosition: boolean = true) {
    if (changePosition) {
      this.image.width = this.vW * this.newScale;
      this.image.height = this.vH * this.newScale;

      const mousePointTo = {
        x: (this.currentPos.x / this.oldScale - this.stage.x() / this.oldScale),
        y: (this.currentPos.y / this.oldScale - this.stage.y() / this.oldScale),
      };

      const delta = {
        x: mousePointTo.x * this.newScale + (mousePointTo.x - this.currentPos.x / this.newScale),
        y: mousePointTo.y * this.newScale + (mousePointTo.y - this.currentPos.y / this.newScale)
      };

      this.currentPos = {
        // eslint-disable-next-line max-len
        x: delta.x >= 0 ? 0 : delta.x - this.size < this.image.width * -1 ? (this.image.width - this.size) * -1 : delta.x,
        // eslint-disable-next-line max-len
        y: delta.y >= 0 ? 0 : delta.y - this.size < this.image.height * -1 ? (this.image.height - this.size) * -1 : delta.y
      };

      this.stageImg.position(this.currentPos);
      this.coordinates.emit(this.currentPos);
    }

    this.stageImg.scale({
      x: this.newScale,
      y: this.newScale
    });

    // this.stage.scale({
    //   x: this.newScale,
    //   y: this.newScale
    // });
    this.result.emit(this.layer.getCanvas().toDataURL('image/png', 100));
    this.zoom.emit(this.newScale.toString());
    this.stage.batchDraw();
  }

  onSliderChange(event: MatSliderChange) {
    this.oldScale = this.newScale;
    this.newScale = event.value;
    this.scaleImage();
  }
}
