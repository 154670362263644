import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';

import { SharedTariffsRoutingModule } from './shared-tariffs.routing.module';
import { ReliabilityDirectiveModule } from '@app/core/directives/reliability/reliability.module';
import { AlertsComponentModule } from '@app/shared/alerts/alerts.module';
import { SharedModule } from '@app/shared';
import { SharedTariffsComponent } from './shared-tariffs.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedTariffsRoutingModule,
    AlertsComponentModule,
    SharedModule,
    NgSelectModule,
    ReliabilityDirectiveModule
  ],
  declarations: [
    SharedTariffsComponent
  ]
})
export class SharedTariffsModule { }
