import { timer as observableTimer } from 'rxjs';

import { take } from 'rxjs/operators';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Input,
  EventEmitter,
  Output,
  SimpleChanges,
  OnChanges,
  OnDestroy
} from '@angular/core';
import each from 'lodash-es/each';
import {I18nService} from '@app/core';

@Component({
  selector: 'app-webcam',
  templateUrl: './webcam.component.html',
  styleUrls: ['./webcam.component.scss']
})
export class WebcamComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @ViewChild('video', { read: ElementRef, static: false }) video: ElementRef;
  @ViewChild('canvas', { read: ElementRef, static: false }) canvas: ElementRef;

  @Input() size = 400;
  @Input() captureTrigger: number;
  @Input() captureTimeout = 5;

  @Output() captureReady: EventEmitter<any> = new EventEmitter();
  @Output() capture: EventEmitter<string> = new EventEmitter();

  public captureTimeoutProgress = 0;
  public countDown = observableTimer(0, 1000).pipe(take(this.captureTimeout));
  public countDownDisplayText = this.captureTimeout;
  public result: string;
  public stream: any;
  public error: any = null;
  public language: any;

  public vW = 640;
  public vH = 480;

  public _vW = this.vW;
  public _vH = this.vH;

  public get sideOffset(): number {
    return (this.size - this.vW) / 2;
  }

  public get topOffset(): number {
    return (this.size - this.vH) / 2;
  }

  constructor(private i18nService: I18nService) {
    this.language = this.i18nService.translateService.translations[this.i18nService.language];
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // eslint-disable-next-line max-len
    if (changes.captureTrigger !== undefined && changes.captureTrigger.currentValue !== changes.captureTrigger.previousValue) {
      setTimeout(() => {
        this._capture();
      }, 100);
    }
  }

  getCorrectImageSize() {
    const d = this.vW < this.vH ? 'p' : 'l';

    if (d === 'l') {
      const ratio = this.vW / this.vH;
      this.vH = this.size;
      this.vW = Math.round(this.vH * ratio);
    } else {
      const ratio = this.vH / this.vW;
      this.vW = this.size;
      this.vH = Math.round(this.vW * ratio);
    }
  }

  public ngAfterViewInit() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      this.getCorrectImageSize();

      this.video.nativeElement.addEventListener('loadedmetadata', (e: any) => {
        this.vW = this.video.nativeElement.videoWidth;
        this.vH = this.video.nativeElement.videoHeight;

        // Keep original video dimensions
        this._vW = this.vW;
        this._vH = this.vH;

        this.getCorrectImageSize();
      });

      navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
        this.error = null;
        this.stream = stream;
        try {
          this.video.nativeElement.srcObject = stream;
        } catch (error) {
          // TODO: fix it (createObjectURL is deprecated as i know)
          // @ts-ignore
          this.video.nativeElement.src = window.URL.createObjectURL(stream);
        }
        this.video.nativeElement.play();
      }).catch((err) => {
        if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
          this.error = {
            name: this.language.state.error,
            message: this.language.error.webcamPermissionDenied,
            code: err.code
          };
        } else {
          this.error = err;
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.stream) {
      const tracks = this.stream.getTracks();

      each(tracks, (t) => {
        t.stop();
      });
    }
  }

  private _capture() {
    if (this.error) {
      return;
    }
    this.canvas.nativeElement.getContext('2d').clearRect(0, 0, this.vW, this.vH);
    this.captureReady.emit();
    this.countDown.subscribe((timer) => {
      timer = Number(this.captureTimeout) - Number(timer);
      this.countDownDisplayText = timer;
      this.captureTimeoutProgress = Math.round(100 / timer);

      if (timer === 1) {
        // eslint-disable-next-line max-len
        this.canvas.nativeElement.getContext('2d').drawImage(this.video.nativeElement, 0, 0, this.vW, this.vH);
        this.result = this.canvas.nativeElement.toDataURL('image/png');

        this.captureTimeoutProgress = 0;
        this.capture.emit(this.result);
      }
    });
  }
}
