import { Injectable, NgZone } from '@angular/core';
import { Toast, ToastConfig } from '@app/core/toasts/toast';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastService {

  public readonly toasts: Map<number, Toast> = new Map();
  private onToastUpdatedSubject = new Subject<void>();

  get onToastUpdated(): Observable<void> {
    return this.onToastUpdatedSubject.asObservable()
  }

  constructor() {}

  public addSimpleToast(config: ToastConfig) {
    const toast = new Toast(config);
    const toastId = new Date().getTime();

    // удалить тост из списка после его закрытия
    const sub = toast.onDelete$.subscribe(() => {
      this.deleteToast(toastId);
      sub.unsubscribe();
    });

    if (this.toasts.size === 5) {
      // получаем самый старый тост (он же первый в Map)
      const firstToast = this.toasts.values().next().value;
      firstToast.closeToast();
    }

    this.toasts.set(toastId, toast);

    this.onToastUpdatedSubject.next();
    return toast;
  }

  private deleteToast(id: number): void {
    this.toasts.delete(id);
    this.onToastUpdatedSubject.next();
  }
}
