import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';
import { Response } from '@app/core/interfaces/api.response';
import { CRM } from './interfaces/crm';
import { BehaviorSubject, Observable } from 'rxjs';
import each from 'lodash-es/each';
import { TranslateService } from '@ngx-translate/core';
import { ProjectService } from '@app/core/project.service';

@Injectable({
  providedIn: 'root'
})
export class CrmService {

  private _crms: CRM[] = [];
  private subject = new BehaviorSubject<CRM[]>(this._crms);
  public isCrmsListLoaded = new BehaviorSubject(false);

  get crms(): CRM[] {
    return this._crms;
  }

  set crms(crms: CRM[]) {
    this.transformCRMs(crms).then((data) => {
      this._crms = data;
      this.subject.next(this._crms);
      this.isCrmsListLoaded.next(true);
    });
  }

  get crmsList(): Observable<CRM[]> {
    return this.subject.asObservable();
  }

  constructor(
    private api: ApiService,
    private translateService: TranslateService,
    private projectService: ProjectService
  ) {
    this.updateCRMList();
  }

  updateCRMList(): void {
    if (this.projectService.getCurrentProject()) {
      this.api.getCRMList().subscribe((response: Response) => {
        if (response && response.success) {
          this.crms = response.data;
        }
      });
    } else {
      setTimeout(() => {
        this.updateCRMList();
      }, 250);
    }

  }

  private async transformCRMs(crms: CRM[]) {
    each(crms, async (crm, index) => {
      const crmNameTranslationKey = crm.kommo ? 'kommo' : crm.type;

      crms[index].token = crm.accessToken;
      crms[index].channelId = crm.id;
      crms[index].defaultName = await this.translateService.get(`crm.${crmNameTranslationKey}`).toPromise();
    });

    return crms;
  }
}
