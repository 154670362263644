import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CookiesService } from '@app/core/cookies.service';
import { Duration, Interval } from 'luxon';
import { environment } from '@env/environment';

export interface LoginContext {
  login: string;
  password: string;
  remember?: boolean | number;
}

const credentialsKey = 'credentials';

/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private cookies: CookiesService,
  ) {
    window.addEventListener('storage', (event) => {
      if (event.storageArea === localStorage && event.key === 'credentials' && event.newValue !== event.oldValue) {
        window.location.reload();
      }
    }, false);
  }

  private isWidgetMode = false;

  /**
   * Authenticates the user.
   * @param {string} context The login parameters.
   */
  login(accessToken: string, remember?: boolean): void {
    this.setCredentials(accessToken, remember);
  }

  /**
   * Remembering amo widget token
   * @param authToken
   */
  loginWidget(authToken: string): void {
    this.isWidgetMode = true;
    localStorage.setItem(credentialsKey, authToken);
  }

  /**
   * Logs out the user and clear credentials.
   * @return {Observable<boolean>} True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.setCredentials();
    return of(true);
  }

  /**
   * Checks is the user is authenticated.
   * @return {boolean} True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials;
  }

  /**
   * Gets the user credentials.
   * @return {string|null} The user credentials or null if the user is not authenticated.
   */
  get credentials(): string | null {
    const savedCredentials = this.cookies.get(credentialsKey) || null;
    const widgetCreds = localStorage.getItem(credentialsKey);
    return this.isWidgetMode ? widgetCreds : JSON.parse(savedCredentials);
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param {string=} token The user credentials.
   * @param {boolean=} remember True to remember credentials across sessions.
   */
  private setCredentials(token?: string, remember?: boolean) {
    if (token) {
      // eslint-disable-next-line max-len
      const expires = remember ? Interval.after(new Date(), Duration.fromObject({ years: 1 }))
        .toDuration('days').toObject().days : 0;
      this.cookies.set(credentialsKey, JSON.stringify(token), expires, '/', environment.cookiesOrigin);
    } else {
      this.cookies.delete(credentialsKey, '/', environment.cookiesOrigin);
      this.cookies.delete('lastProjectId', '/', environment.cookiesOrigin);
    }
  }

  redirectToLogin(): void {
    this.setCredentials();
    if (window.location.href.indexOf('/login') === -1) {
      window.location.href = '/login';
    }

  }

  backToPrevPage(): void {
    window.location.href = '/';
    // this.location.back();
  }

}
