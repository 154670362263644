import { NgModule } from '@angular/core';
import { MaxLengthDirective } from '@app/core/directives/maxlength';
import { MaxLength } from '@app/shared/max-length.pipe';

@NgModule({
  imports: [],
  declarations: [MaxLengthDirective, MaxLength],
  exports: [MaxLengthDirective, MaxLength]
})
export class MaxLengthDirectiveModule { }
