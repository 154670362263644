import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
  HostListener,
  OnDestroy
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import each from 'lodash-es/each';
import { EmojiEvent } from '@app/shared/emoji/emoji/emoji.component';
import { GiphyService } from '@app/core/giphy.service';
import { StorageService } from '@app/core/storage/storage.service';
import { GiphyResponse } from '@app/core/interfaces/giphyResponse';
import { UtilsService } from '@app/core/utils.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-teletype-emoji-picker',
  templateUrl: './emoji-picker.component.html',
  styleUrls: ['./emoji-picker.component.scss'],
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmojiPickerComponent implements OnInit, OnChanges, OnDestroy {

  private _pickerVisible = false;
  @Input() public isInstagramPost: boolean;
  @Input()
  get pickerVisible(): boolean {
    return this._pickerVisible;
  }
  set pickerVisible(value: boolean) {
    setTimeout(() => {
      this._pickerVisible = value;
    }, 20);
  }
  @Input() emojiSet = 'facebook';

  @Output() selectEmoji: EventEmitter<EmojiEvent> = new EventEmitter();
  @Output() selectGif: EventEmitter<string> = new EventEmitter();
  @Output() closePicker: EventEmitter<boolean> = new EventEmitter();

  public activeTab: string;
  public giphySearchQuery: UntypedFormControl;
  public giphySearchResults: any = [];
  public giphyLoading = false;
  public useNative = false;
  public windowWidth = window.innerWidth;
  public isMobile = false;

  private unsub$ = new Subject<void>();

  @ViewChild('container', { read: ElementRef, static: true }) container: ElementRef;
  @ViewChild('giphySearchControl', { read: ElementRef, static: true }) giphySearchControl: ElementRef;

  @HostListener('window:resize') onresize() {
    this.windowWidth = window.innerWidth;
  }

  @HostListener('document:click', ['$event.target']) onclick(elm: HTMLElement) {
    const clickedInside = this.container.nativeElement.contains(elm);
    if (!clickedInside && this.pickerVisible) {
      this.closePicker.emit(true);
    }
  }

  constructor(
    private detector: ChangeDetectorRef,
    private storage: StorageService,
    private giphy: GiphyService,
    private utils: UtilsService,
    private deviceDetector: DeviceDetectorService
  ) {

    this.isMobile = this.deviceDetector.isMobile();

    this.useNative = this.utils.useNativeEmoji;
    this.activeTab = this.storage.get('emoji-picker-tab', 'emoji');
    this.giphySearchQuery = new UntypedFormControl();
    this.giphySearchQuery.setValue(this.storage.get('giphy-search-query', ''));

    this.giphySearchQuery.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      takeUntil(this.unsub$))
      .subscribe((value) => {
        this.storage.set('giphy-search-query', value.trim());
        this.searchGiphy();
      });
  }

  setGiphySearchFocus() {
    if (this.activeTab === 'giphy') {
      setTimeout(() => {
        this.giphySearchControl.nativeElement.focus();
        this.detector.detectChanges();
      }, 150);
    }
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.unsub$.next();
this.unsub$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pickerVisible !== undefined && changes.pickerVisible.currentValue === true) {
      this.setGiphySearchFocus();
    }
    if (changes.isInstagramPost !== undefined && changes.isInstagramPost.currentValue !== undefined) {
      this.isInstagramPost = changes.isInstagramPost.currentValue;
      if (this.isInstagramPost) {
        this.activeTab = 'emoji';
      } else {
        this.activeTab = this.storage.get('emoji-picker-tab', 'emoji');
      }
    }
  }

  changeTab(tab: string) {
    this.activeTab = tab;
    this.storage.set('emoji-picker-tab', tab);

    if (tab === 'giphy') {
      this.setGiphySearchFocus();
    }
  }

  handleEmojiSelect(event: EmojiEvent) {
    this.selectEmoji.emit(event);
  }

  sendGiphy(giphy: any) {
    this.selectGif.emit(giphy);
  }

  searchGiphy() {
    if (this.giphyLoading) {
      return;
    }

    this.giphySearchResults = [];
    this.giphyLoading = true;

    this.detector.markForCheck();

    if (this.giphySearchQuery.value === '') {
      this.giphy.getTrending()
        .pipe(takeUntil(this.unsub$))
        .subscribe((data: any) => this.giphyCallback(data));
    } else {
      this.giphy.searchGif(this.giphySearchQuery.value)
        .pipe(takeUntil(this.unsub$))
        .subscribe((data: any) => this.giphyCallback(data));
    }
  }

  giphyCallback(response: GiphyResponse) {
    if (!response || response.meta.status !== 200) {
      return;
    }

    const tmpItems: any = [];

    each(response.data, (item) => {
      tmpItems.push({
        image: item.images.preview_gif.url,
        width: item.images.preview_gif.width,
        height: item.images.preview_gif.height,
      });
    });

    this.giphySearchResults = this.giphy.generateEvenGrid(tmpItems);
    this.giphyLoading = false;
    this.detector.detectChanges();
  }
}
