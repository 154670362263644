import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRoute } from '@angular/router';

import { Route, extract, I18nService, AuthenticationGuard } from '@app/core';
import { HomeComponent } from './home.component';
import { Title } from '@angular/platform-browser';
import { ProjectGuard } from '@app/core/project.guard';
import { TariffGuard } from '@app/core/tariff.guard';
import { UserGuard } from '@app/core/user.guard';
import { OperatorGuard } from '@app/core/operators.guard';
import { ChannelsGuard } from '@app/core/channels.guard';
import { PermissionGuard } from '@app/core/permission.guard';

const routes: Routes = [
  Route.withShell([
    {
      path: '',
      redirectTo: '/home',
      pathMatch: 'full'
    },
    {
      path: 'home',
      component: HomeComponent,
      data: {
        title: extract('Home')
      },
      canLoad: [ProjectGuard, UserGuard, OperatorGuard, ChannelsGuard, TariffGuard,
        PermissionGuard],
      canActivate: [AuthenticationGuard]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class HomeRoutingModule {
  constructor(
    private titleService: Title,
    private i18nService: I18nService,
    private route: ActivatedRoute,
  ) {
    const title = this.route.snapshot.data['title'];
    if (title) {
      this.titleService.setTitle(this.i18nService.translateService.translations[this.i18nService.language]
        .route[title.toLowerCase()]);
    }
  }
}
