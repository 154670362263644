import { Injectable } from '@angular/core';

enum YaglaEvent {
  MAIL_SEND = 'MAIL_SEND',
  PROFILE_CONFIRM = 'PROFILE_CONFIRM',
  PAID = 'PAID',
  CHANNEL_CONNECT = 'CHANNEL_CONNECT',
}

const SUCCESS_EVENTS_LIST = ['MAIL_SEND', 'PROFILE_CONFIRM', 'PAID', 'CHANNEL_CONNECT'];

@Injectable({
  providedIn: 'root'
})
export class YaglaAnalyticsService {

  constructor() {}

  // пока отключен
  track(trackName: YaglaEvent | string) {
    const yaglaaction = (<any>window).yaglaaction;
    if ( yaglaaction !== undefined && SUCCESS_EVENTS_LIST.indexOf(trackName) !== -1) {
      yaglaaction(trackName);
    }
  }
}
