import { ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  AbstractControl,
  NgForm,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AlertsService } from '@app/core/alerts/alerts.service';
import { ConversationApiService } from '@app/core/api/api.conversation';
import { ProjectApiService } from '@app/core/api/api.project';
import { RegistrationApiService } from '@app/core/api/api.registration';
import { DOMAIN_REGEX } from '@app/core/config/regExValidators';
import { slugify } from '@app/core/config/slug';
import { CustomModalService } from '@app/core/custom-modal.service';
import { FeatureAccessService } from '@app/core/feature-access.service';
import { GoogleTagService } from '@app/core/gtag.service';
import { I18nService } from '@app/core/i18n.service';
import { OperatorData } from '@app/core/interfaces/api.response';
import { ANALYTICS_EVENT, MetrikaService } from '@app/core/metrika.service';
import { OperatorService } from '@app/core/operator.service';
import { PermissionsService } from '@app/core/permissions.service';
import { ProjectService } from '@app/core/project.service';
import { ShellResolver } from '@app/core/shell/shell.resolver';
import { NAMES, TariffService } from '@app/core/tariff.service';
import { TimezoneService } from '@app/core/timezone.service';
import { UserService } from '@app/core/user.service';
import { UnescapePipe } from '@app/shared/unescape/unescape.pipe';
import { NgSelectHelper } from '@app/shared/utils/ng-select-helper';
import { environment } from '@env/environment';
import { Response } from 'app/core/interfaces/api.response';
import { each, filter } from 'lodash-es';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { combineLatest, Subject, timer } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

const START_PAGE = '/settings/start';

@Component({
  selector: 'app-project-setup',
  templateUrl: 'project-setup.component.html',
  styleUrls: ['project-setup.component.scss'],
  preserveWhitespaces: false
})

export class ProjectSetupComponent implements OnInit, OnDestroy {

  @Input() create = false;
  loading: boolean;
  inProgress: boolean;
  success: boolean;
  project: any;
  addressChanged = false;
  public addProjectForm: UntypedFormGroup;
  public errorsList: any = {};
  public timezones: any;
  public userId: string;
  public selectedOperatorId: string;
  public modalRef: BsModalRef;
  public operators: any;
  public error: string;
  public inputError: string;
  public language: any;
  public costText: string;
  public currentTariff: any;
  public hasProject: boolean;

  private unsub$ = new Subject<void>();

  // @ts-ignore
  @ViewChild('projectForm') projectForm: NgForm;

  constructor(
    private zone: NgZone,
    private detector: ChangeDetectorRef,
    private projectService: ProjectService,
    private api: ProjectApiService,
    private conversationApi: ConversationApiService,
    private router: Router,
    private modalService: BsModalService,
    private formBuilder: UntypedFormBuilder,
    private timezone: TimezoneService,
    private alertsService: AlertsService,
    private shellResolver: ShellResolver,
    private userService: UserService,
    private operatorService: OperatorService,
    private dialog: CustomModalService,
    private i18nService: I18nService,
    private featureAccessService: FeatureAccessService,
    private registrationApi: RegistrationApiService,
    private tariffService: TariffService,
    public permissionsService: PermissionsService,
    private metrika: MetrikaService,
    private gtag: GoogleTagService,
  ) {
    this.timezones = this.timezone.getZones();
  }

  ngOnInit() {
    combineLatest([
      this.projectService.getProjects(),
      this.tariffService.getTariffs(),
      this.i18nService.translateService.getTranslation(this.i18nService.language)
    ]).pipe(map(res => res), takeUntil(this.unsub$))
      .subscribe(([projects, tariffs, language]) => {
        this.hasProject = projects?.length > 0;
        this.language = language;
        if (this.hasProject && tariffs) {
          this.currentTariff = tariffs['operators'];
          // @ts-ignore
          const payedOperatorsCount = this.tariffService.getAllowOperatorsId().length;
          const cost = this.tariffService.getPrice(this.currentTariff);
          this.costText = ` (+ ${cost} ₽/${this.language.settings.bill.perMonth})`;
          const count = this.currentTariff.count;
          if (count < this.currentTariff.quantityForFree || count > payedOperatorsCount) {
            this.costText = '';
          }
        }

    });

    this.project = this.projectService.getCurrentProject(true);
    this.getUserId();
    this.createForm();
    this.fillForm();
  }

  ngOnDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  getOperatorsList() {
    this.operatorService.getOperators()
      .pipe(takeUntil(this.unsub$)).subscribe((operators: OperatorData) => {
        if (operators) {
          this.operators = filter(operators, operator => operator.id !== this.userId);
          if (!this.detector['destroyed']) {
            this.detector.detectChanges();
          }
        }
      });
  }

  getUserId() {
    this.userService.getUserData().pipe(takeUntil(this.unsub$)).subscribe((user: any) => {
      if (!user) {
        return;
      }
      this.userId = user.id;
      this.getOperatorsList();
    });
  }

  createForm() {
    this.error = null;
    this.addProjectForm = this.formBuilder.group({
      name: ['', Validators.required],
      domain: ['', Validators.compose(([
        Validators.required,
        Validators.pattern(DOMAIN_REGEX)
      ])), this.validateCardFieldValueRemotely.bind(this, 'domain')],
      timezone: [this.timezone.getFittingZone(), Validators.required],
      operator: [false],
    });
  }

  fillForm() {
    if (!this.project || this.create) {
      return;
    }

    this.addProjectForm.controls.name.setValue(new UnescapePipe().transform(this.project.name));
    this.addProjectForm.controls.domain.setValue(this.project.domain);
    this.addProjectForm.controls.operator.setValue(this.project.operator);
    this.addProjectForm.controls.timezone.setValue(this.project.timezone || this.timezone.getFittingZone());
  }

  handleFieldBlur(field: string) {
    const fieldState = this.addProjectForm.get(field);
    this.errorsList[field] = !fieldState.valid && fieldState.value.trim() !== '';
  }

  validateOnSubmit() {
    each(this.addProjectForm.controls, (control: UntypedFormControl, name: string) => {
      this.errorsList[name] = control.errors !== null;
    });
  }

  handleChange(value: string) {
    const _value = value.replace(/ /g, '');
    if (_value && _value !== this.addProjectForm.get('domain').value) {
      this.addProjectForm.controls.domain.setValue(_value);
    }
  }

  navigate(url: string) {
    if (!url) {
      return;
    }
    this.router.navigateByUrl(url);
  }

  cancelSave() {
    this.closeModal();
    this.inProgress = false;
    this.selectedOperatorId = null;
  }

  closeModal(): void {
    if (this.modalRef) {
      this.modalRef.hide();
    } else {
      // @ts-ignore: Accessing private variable as workaround for missing feature
      each(this.modalService.loaders || [], loader => loader.instance.hide());
    }
    this.dialog.close();
    if (!this.detector['destroyed']) {
      this.detector.detectChanges();
    }
  }

  doUpdate(): void {
    const data = this.getCallData();
    const reloadRequired = this.project && data.domain !== this.project.domain.toLowerCase();
    this.api.updateProject(data)
      .pipe(takeUntil(this.unsub$))
      .subscribe((response: Response) => {
        if (response.success) {
          // this.userService.getUser();
          // this.operatorService.updateOperatorsList();
          if (reloadRequired) {
            const url = window.location.href.replace(this.project.domain.toLowerCase(), data.domain);
            window.location.href = url;
          } else {
            this.projectService.updateProject();
            this.navigate(START_PAGE);
          }
        }
      }, () => {}, () => {
        this.inProgress = false;
        this.detector.detectChanges();
      });
  }

  appealsRemapping() {
    let success = false;
    this.conversationApi.appealsRemapping({
      clientId: this.userId,
      clientAppointedId: this.selectedOperatorId || null
    }).pipe(takeUntil(this.unsub$))
      .subscribe((response: any) => {
        if (response.success) {
          success = true;
          this.doUpdate();
        }
      }, () => {}, () => {
        if (!success) {
          this.inProgress = false;
        }
      });
  }

  doRemapping() {
    this.closeModal();
    this.inProgress = true;
    this.appealsRemapping();
  }

  getCallData() {
    const data = this.addProjectForm.value;
    data.domain = data.domain.toLowerCase();
    data.tariff = 10;
    if (this.project) {
      data.id = this.project.id;
    }
    return data;
  }

  saveProject(template: TemplateRef<any>) {
    this.validateOnSubmit();

    if (this.inProgress || this.addProjectForm.valid === false) {
      return;
    }

    this.inProgress = true;
    this.detector.detectChanges();

    if (this.project && !this.create) {
      if (this.project.operator && !this.addProjectForm.value.operator && this.userId) {
        this.modalRef = this.modalService.show(template, Object.assign({
          backdrop: true,
          ignoreBackdropClick: true
        }, { class: 'modal-channels' }));
      } else {
        const data = this.getCallData();

        if ((this.costText !== '' || this.currentTariff.count === 0) && data.operator && !this.project.operator) {
          const tariffName = NAMES['operators'];
          const saveTariffData = {};
          saveTariffData[tariffName] = this.currentTariff.count === 0 ? 1 : this.currentTariff.count + 1;
          this.tariffService.saveTariff(saveTariffData).pipe(takeUntil(this.unsub$))
            .subscribe((response: Response) => {
              if (response && response.success) {
                this.featureAccessService.updateAllowUnits();
                this.doUpdate();
              } else {
                this.featureAccessService.showDeclinePopup();
              }
            }, () => {}, () => {
              this.detector.detectChanges();
            });
        } else {
          this.doUpdate();
        }

      }
    } else {
      this.api.createProject(this.getCallData())
        .pipe(takeUntil(this.unsub$))
        .subscribe((response: Response) => {
          this.success = response.success;
          if (this.success) {
            this.projectService.updateProject();
            this.sendAnalyticsEvent(ANALYTICS_EVENT.SECOND_PROJECT_CREATE);
            if (!this.create) {
              window.location.href = START_PAGE;
            } else {
              setTimeout(() => {
                this.closeModal();
              }, 1000);
            }
            setTimeout(() => {
              this.success = false;
            }, 2000);
          } else {
            const message = response.errors && response.errors[0].message;
            if (message) {
              this.error = message;
            }
          }
        }, () => {}, () => {
          this.inProgress = false;
        });
    }
  }

  remove() {
    if (this.inProgress || !this.project.id) {
      return;
    }

    const isLast = this.projectService.projects.length === 1;
    let isSuccess = false;
    const removeProjectId = this.project.id;
    this.alertsService.confirmDelete({
      title: this.language.projects.deleteProjectTitle,
      content: this.language.projects.deleteProjectMessage,
      okButtonText: this.language.button.delete,
      cancelButtonText: this.language.button.cancel,
      okCallback: () => {
        this.inProgress = true;
        this.detector.detectChanges();
        this.api.removeProject({ id: this.project.id })
          .pipe(takeUntil(this.unsub$))
          .subscribe((response: Response) => {
            if (response.success) {
              isSuccess = true;
              this.projectService.projects = this.projectService.projects.filter((project) => {
                return project.id !== removeProjectId;
              });
              if (isLast) {
                let url = '';
                const domain = environment && environment.baseDomain;
                if (domain && environment.production) {
                  url = 'https://' + domain;
                }
                url += '/home';
                window.location.href = url;
              }
            } else {
              this.alertsService.addAlert({
                type: 'error',
                content: response.errors[0].message
              });
            }
            this.inProgress = false;
            this.detector.detectChanges();
          }, (error) => {
          }, () => {
            if (!isLast && isSuccess) {
              window.location.href = 'https://' + this.projectService.projects[0].url.toLowerCase() + '/projects';
            }
          });
      }
    });
  }

  trackByIndex(index: number) {
    return index;
  }

  setProjectUrl(): void {
    const slug = slugify(this.addProjectForm.controls.name.value.substring(0, 20));

    if (this.addProjectForm.controls.domain.value.trim() === '' || !this.addressChanged) {
      this.addProjectForm.controls.domain.setValue(slug);
    }
  }

  private validateCardFieldValueRemotely(name: string, control: AbstractControl) {
    return this.zone.runOutsideAngular(() => {
      const formValue = this.addProjectForm.value;
      formValue[name] = control.value;
      this.errorsList[name] = false;
      return timer(300).pipe(
        switchMap(() => {
          return this.registrationApi.checkDomain({ domain: control.value }, this.create).pipe(
            map(response => {
              let match = {};
              const hasError = response.errors[0] && response.errors[0].message;
              if (hasError) {
                this.inputError = hasError;
                match = { backend: this.inputError };
                this.handleFieldBlur(name);
                if (!this.detector['destroyed']) {
                  this.detector.detectChanges();
                }
              }
              return match;
            })
          );
        })
      );
    });
  }

  onClose() {
    NgSelectHelper.onSelectBlur('selectItem');
  }

  sendAnalyticsEvent(event: ANALYTICS_EVENT | string, args: object = null) {
    this.metrika.goal(event, args);
    this.gtag.track(event, args);
  }
}
