import { Injectable } from '@angular/core';
import { CategoriesApiService } from '@app/core/api/api.categories';
import { Response } from '@app/core/interfaces/api.response';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProjectService } from '@app/core/project.service';

export interface CategoryBase {
  category: string;
  description: string;
}

export interface Category extends CategoryBase {
  id: string;
  projectId: string
}

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  private categoriesSubject = new BehaviorSubject<Category[]>([]);

  set categories(categories: Category[]) {
    this.categoriesSubject.next(categories);
  }

  get categories(): Category[] {
    return this.categoriesSubject.value;
  }

  constructor(
    private api: CategoriesApiService,
    private projectService: ProjectService,
  ) {
    this.updateCategories();
  }

  getCategories() {
    return this.categoriesSubject.asObservable();
  }

  updateCategories() {
    if (!this.projectService.getCurrentProject()) {
      return;
    }

    this.api.getListCategory().subscribe((response: Response) => {
      if (response.success) {
        this.categories = response.data;
      }
    });
  }
}
