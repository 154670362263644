import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ChannelService } from '@app/core/channel.service';
import { ChannelData } from '@app/core/interfaces/api.response';
import { merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

const checkChannelIsNotMessengerButton = (channel: ChannelData): boolean => channel.type !== 'widget' || channel.enabledChat;

@Component({
  selector: 'app-setting-select-channel',
  templateUrl: './select-channel.component.html',
  styleUrls: ['./select-channel.component.scss'],
})
export class SelectChannelComponent {
  @Input() maxLength: number;

  @Input() channels: UntypedFormArray;
  @Output() channelsChange: EventEmitter<UntypedFormArray> = new EventEmitter();
  @Output() addElement: EventEmitter<UntypedFormControl> = new EventEmitter();
  @Output() removeElement: EventEmitter<UntypedFormControl> = new EventEmitter();

  get channelsConfigForm() {
    return this.channels.controls as UntypedFormControl[];
  }

  channelsVariants = (this.channelsService.channels || []).filter(checkChannelIsNotMessengerButton);
  allChannels = this.channelsService.allChannels;

  public model: any = '';
  focus$ = new Subject<string>();

  search: OperatorFunction<string, readonly ChannelData[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(search => {
        const usedChannelsId = this.channels.value;
        const unusedChannels = this.channelsVariants.filter(channel => !usedChannelsId.find(usedChannelId => usedChannelId === channel.id));
        return unusedChannels.filter(channel => channel.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
      })
    )
  }

  constructor(
    private channelsService: ChannelService,
  ) {
  }

  formatter = (channel: ChannelData) => channel.name;

  selectItem(event) {
    event.preventDefault();
    (document.activeElement as HTMLElement).blur();
    this.useChannel(event.item);
    this.model = '';
  }

  useChannel(channel): void {
    const control = new UntypedFormControl(channel.id);
    this.channels.push(control);
    this.channelsChange.emit(this.channels);
    this.addElement.emit(channel.id);
  }

  getChannelById(channelId: string): ChannelData {
    return this.allChannels.find(channel => channel.id === channelId);
  }

  deleteChannel(index: number) {
    const removedChannelId = this.channels.at(index).value;
    this.channels.removeAt(index);
    this.channelsChange.emit(this.channels);
    this.removeElement.emit(removedChannelId);
  }
}
