import { APP_INITIALIZER, PLATFORM_ID, NgModule, ModuleWithProviders, NgZone } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { SocketIo } from './ng-io.service';
import { NgIoConfig, SOCKET_CONFIG_TOKEN } from './ng-io-config';

export function SocketFactory(zone: NgZone, config: NgIoConfig, platformId: any) {
  return new SocketIo(zone, config, platformId);
}

export function AppInitFactory(socketIo: SocketIo, config: NgIoConfig, platformId: any) {
  return function() {
    if (isPlatformBrowser(platformId) && (config.connectOnAppLoad || config.connectOnAppLoad === undefined)) {
      socketIo.connect();
    }
  };
}

@NgModule({})
export class NgIoModule {
  static forRoot(config: NgIoConfig): ModuleWithProviders<NgIoModule> {
    return {
      ngModule: NgIoModule,
      providers: [
        { provide: SOCKET_CONFIG_TOKEN, useValue: config },
        {
          provide: SocketIo,
          useFactory: SocketFactory,
          deps: [NgZone, SOCKET_CONFIG_TOKEN, PLATFORM_ID],
        },
        {
          provide: APP_INITIALIZER,
          useFactory: AppInitFactory,
          deps: [SocketIo, SOCKET_CONFIG_TOKEN, PLATFORM_ID],
          multi: true
        }
      ]
    };
  }
}
