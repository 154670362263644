import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  Input,
  Optional,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Response } from '@app/core/interfaces/api.response';
import { I18nService } from '@app/core';
import each from 'lodash-es/each';
import find from 'lodash-es/find';
import {
  CHANNEL_LIST,
  Days,
  LIMIT,
  NAMES, Percent, PercentText,
  PromoDays,
  Tariff,
  TariffGroups,
  TARIFFS_LIST,
  TariffService,
} from '@app/core/tariff.service';
import { FeatureAccessService } from '@app/core/feature-access.service';
import { CustomModalService } from '@app/core/custom-modal.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ProjectService } from '@app/core/project.service';
import { environment } from '@env/environment';
import { CookiesService } from '@app/core/cookies.service';
import { Duration, Interval } from 'luxon';
import { ShellResolver } from '@app/core/shell/shell.resolver';
import { ProjectApiService } from '@app/core/api/api.project';
import { SumPipe } from '@app/shared/sum.pipe';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CacheService } from '@app/core/cache.service';
import { TariffApiService } from '@app/core/api/api.tariff';
import {Subject} from 'rxjs';
import { ChatService } from '@app/core/chat.service';

@Component({
  selector: 'app-tariff-setup',
  templateUrl: 'tariff-setup.component.html',
  styleUrls: ['tariff-setup.component.scss'],
  preserveWhitespaces: false
})
export class TariffSetupComponent implements OnInit, OnDestroy {

  public error: string;
  public inProgress: boolean;
  public deleteInProgress: boolean;
  public translations: any;
  public tariffs: Array<TariffGroups>;
  public selectedTariffs: Array<any>;
  public userChangedTariffs = {};
  public isDayMode = true;
  public total: number;
  public percent: string;
  public amount: number;
  public discountSum: number;
  public hasChanges: boolean;
  public optionsVisible = true;
  public balance = 0;
  public availableDays = 0;
  public dayText: string;
  public tariffAdNotYearSum: string;
  public toDate: any;
  public modalRef: BsModalRef;
  public showTariffModal = false;
  public promoDays: PromoDays;
  public dailyPayment: number;
  public availablePeriod = ['month', 'quarter', 'halfYear', 'year'];
  public paymentHistory: number;

  private tmpTariff = {
    _tariff: null,
    isPlus: null
  };

  @ViewChild('lastOperatorModalTemplate', {static: true}) public templateRef: TemplateRef<any>;

  @Input() public = false;
  public isMobile: boolean;
  public selectedPeriod = 'month';

  private unsub$ = new Subject<void>();

  get isShowSpecialOffer(): boolean {
    return this.selectedTariffs.reduce((count, tariffOption) => {
      if (CHANNEL_LIST.includes(tariffOption.id)) {
        return count + tariffOption.count;
      }

      return count;
    }, 0) > 10;
  }

  get isEng() {
    return this.i18nService.language === 'en';
  }

  constructor(
    private detector: ChangeDetectorRef,
    private i18nService: I18nService,
    private tariffService: TariffService,
    @Optional() private featureAccessService: FeatureAccessService,
    private dialog: CustomModalService,
    private modalService: BsModalService,
    private projectApi: ProjectApiService,
    private projectService: ProjectService,
    private cookies: CookiesService,
    private deviceService: DeviceDetectorService,
    @Optional() private shellResolver: ShellResolver,
    private cacheService: CacheService,
    private apiTariff: TariffApiService,
    private chatService: ChatService,
  ) {
    this.isMobile = this.deviceService.userAgent.toLowerCase().includes('mobi');
    this.translations = this.i18nService.translateService.translations[this.i18nService.language];
  }

  ngOnInit() {
    window.addEventListener('message', (event: MessageEvent) => {

      if (event.data.showTariffsModal) {
        this.showTariffModal = true;
        this.detector.detectChanges();
      }
    });

    //  пример вызова с лендинга:
    //  document.getElementById('tariffs_iframe').contentWindow.postMessage({
    //   newPeriod: 'year', // availablePeriod = ['month', 'quarter', 'halfYear', 'year'];
    //  }, window.iframeDomain);
    window.addEventListener('message', (event: MessageEvent) => {
      if (event.data.newPeriod) {
        this.setPeriod(event.data.newPeriod);
        this.detector.detectChanges();
      }
    });

    if (this.public) {
      this.tariffService.isPublic = this.public;
      this.tariffService.loadTariffs();
    }

    this.tariffService.selectedPeriod = this.selectedPeriod;
    this.tariffs = TARIFFS_LIST;
    each(this.tariffs, _tariff => {
      _tariff.extraName = this.translations.settings.titles[_tariff.name] || null;
      _tariff.name = this.translations.settings.titles[_tariff.id];
      each(_tariff.items, item => {
        const key = item.translationKey || item.id;
        item.name = this.translations.settings.tariffTitle[key];
        item.description = this.translations.settings.tariffDesc[key];
      });
      _tariff.items = _tariff.items.filter(item => !item.disabled);
    });
    this.getTariff();
    this.getHistory();
    this.tariffService.getPromoDays().pipe(takeUntil(this.unsub$))
      .subscribe((promoDays: PromoDays) => {
        if (promoDays !== undefined) {
          this.promoDays = promoDays;
        }
    });
    this.tariffService.getDailyPayment().pipe(takeUntil(this.unsub$))
      .subscribe((dailyPayment: number) => {
        if (dailyPayment !== undefined) {
          this.dailyPayment = dailyPayment;
        }
      });
  }

  ngOnDestroy() {
    this.unsub$.next();
this.unsub$.complete();
  }

  private getHistory() {
    if (this.public) {
      return;
    }
    this.apiTariff.getTariffBilling(1, 10, ['incoming'])
      .pipe(takeUntil(this.unsub$))
      .subscribe((response: Response) => {
        if (response && response.success) {
          this.paymentHistory = response.data.items.length;
        }
      });
  }

  public getPrice(tariff: Tariff) {
    return this.tariffService.getPrice(tariff);
  }

  public onChangeQuantity(_tariff: Tariff, isPlus: boolean) {

    if (_tariff.id === 'operators' && !isPlus && _tariff.count === 1) {

      if (!this.public) {
        this.showPopUp(_tariff, isPlus);
        return;
      }
    }
    this.changeQuantity(_tariff, isPlus);

    this.detector.detectChanges();
  }

  public onChangeTotalQuantity(_tariff: any, isPlus: boolean) {
    each(this.tariffs, (tariff) => {
      const matchTariff = find(tariff.items, (item) => {
        return _tariff.id === item.id;
      });
      if (matchTariff) {
        this.onChangeQuantity(matchTariff, isPlus);
      }
    });
  }

  public getSum(tariff: Tariff, isTotalCalc: boolean = false) {
    return this.tariffService.getSum(tariff, isTotalCalc);
  }

  public saveChanges() {
    if (this.inProgress) {
      return;
    }
    this.inProgress = true;
    this.hasChanges = false;
    const call = this.getPostData();

    this.tariffService.saveTariff(call).pipe(takeUntil(this.unsub$))
      .subscribe((response: Response) => {
        if (response && response.success) {
          this.userChangedTariffs = {};
          this.featureAccessService.updateAllowUnits();
        } else {
          this.hasChanges = true;
          this.featureAccessService.showDeclinePopup();
        }
      }, () => {}, () => {
        this.inProgress = false;
        this.detector.detectChanges();
      });
  }

  public closeModal(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.modalRef && this.modalRef.hide();
    this.dialog.close();
    this.deleteInProgress = false;
    if (!this.detector['destroyed']) {
      this.detector.detectChanges();
    }
  }

  public closeTariffsModal(): void {
    this.showTariffModal = false;

    if (this.public) {
      window.top.postMessage({
        showTariffsModal: false
      }, environment.landingUrl);
    }

    this.detector.detectChanges();
  }

  public getDayText(days: number): string {
    return this.tariffService.getDayText(days);
  }

  public removeOperator() {
    this.deleteInProgress = true;
  }

  public uncheckOperator() {
    if (this.inProgress) {
      return;
    }
    this.inProgress = true;
    const data = this.projectService.getCurrentProject();
    this.projectApi.updateProject({'operator': false, id: data.id})
      .pipe(takeUntil(this.unsub$))
      .subscribe((response: Response) => {
        if (response.success) {
         this.changeQuantity(this.tmpTariff._tariff, this.tmpTariff.isPlus);
         this.projectService.updateProject();
        }
      }, () => {}, () => {
        this.inProgress = false;
        this.detector.detectChanges();
      });
  }

  private clearTmpTariff() {
    this.tmpTariff._tariff = null;
    this.tmpTariff.isPlus = null;
  }

  private changeQuantity(_tariff: Tariff, isPlus: boolean) {
    if (!_tariff.id) {
      return;
    }
    this.total = 0;
    _tariff.count = isPlus ? _tariff.count + 1 : _tariff.count - 1;
    this.setSelectedTariffs();
    this.total = Number(this.total.toFixed(2));
    this.hasChanges = true;
    this.calcDiscount();
    this.getAvailableDays();
    this.clearTmpTariff();
    if (!this.userChangedTariffs[_tariff.id]) {
      this.userChangedTariffs[_tariff.id] = { count: 0 };
    }
    this.userChangedTariffs[_tariff.id].count = _tariff.count;
  }

  private loadSelectedTariffs() {
    if (this.userChangedTariffs && Object.keys(this.userChangedTariffs).length > 0) {
      each(this.tariffs, (tariff) => {
        const matchTariff = find(tariff.items, (item) => {
          return this.userChangedTariffs.hasOwnProperty(item.id);
        });
        if (matchTariff) {
          matchTariff.count = this.userChangedTariffs[matchTariff.id].count;
        }
      });
    }
  }

  private setSelectedTariffs() {
    this.total = 0;
    this.selectedTariffs = [];
    each(this.tariffs, (tariff) => {
      each(tariff.items, (item) => {
        if (item.count > 0) {
          if (item.id === 'onlineChat' || item.id === 'messengersButton') {
            item.maxCount = 1;
          }
          this.total += this.getSum(item, true);
          this.selectedTariffs.push(item);
        }
      });
    });
    this.calcDiscount();
    this.getAvailableDays();
    this.tariffService.totalPaySum = this.total;
    this.sendPostMessage();
  }

  private sendPostMessage() {
    if (this.public) {
      window.top.postMessage({
        tariffChanged: {
          price: this.total,
          servicesCount: this.selectedTariffs.length,
          period: this.translations.settings.periodMob[this.selectedPeriod]
        }
      }, environment.landingUrl);
    }
  }

  private calcTotal() {
    this.total = this.tariffService.calcTotal();
    this.calcDiscount();
  }

  private calcDiscount() {
    const percent = 0.25;
    const bonus = ((this.total / Days[this.selectedPeriod]) * 360) * percent;
    this.discountSum = this.total * (Percent[this.selectedPeriod] || 0);
    this.amount = this.total - this.discountSum;
    this.percent =  (PercentText[this.selectedPeriod] || 0);
    this.tariffAdNotYearSum = this.translations.tariffAdNotYearSum
      .replace('#[SUM]#', `<span class="sum-a">
        ${new SumPipe().transform(bonus)} ₽</span>`);
  }

  private getPostData() {
    const data = {};
    each(this.tariffs, (tariffGroup: TariffGroups) => {
      each(tariffGroup.items, (tariff: Tariff) => {
        const propName = NAMES[tariff.id];
        if (propName) {
          data[propName] = tariff.count;
        }
      });
    });
    return data;
  }

  private getAvailableDays() {
    // eslint-disable-next-line no-bitwise
    this.availableDays = Math.round(this.balance / this.total | 0) || 0;
    this.dayText = this.tariffService.getDayText(this.availableDays);

    const date = new Date();
    date.setDate(date.getDate() + this.availableDays);
    this.toDate = date.toLocaleDateString();
  }

  private fillTariffs(options: any) {
    each(this.tariffs, (tariffGroup: TariffGroups) => {
      each(tariffGroup.items, (item: Tariff) => {
        if (options[item.id]) {
          item.count = options[item.id].count;
          item.quantityForFree = options[item.id].quantityForFree;
          item.cost = options[item.id].cost;
          item.maxCount = options[item.id].category === 'binary' ? 1 : LIMIT;
        }
      });
    });
    this.calcTotal();
    this.setSumToTariffDescription();
  }

  private setSumToTariffDescription() {
    const matchMainGroup = this.tariffs.find(tariff => tariff.id === 'main');
    if (matchMainGroup) {
      const matchOperators = matchMainGroup.items.find((tariff: Tariff) => tariff.id === 'operators');
      matchOperators.description = matchOperators.description
        .replace('#[sum]#', matchOperators.cost[2] * 30);
      const matchOnlineChat = matchMainGroup.items.find((tariff: Tariff) => tariff.id === 'onlineChat');
      matchOnlineChat.description = matchOnlineChat.description
        .replace('#[sum]#', matchOnlineChat.cost[2] * 30);
    }
  }

  private getTariff() {

    this.tariffService.getTariffs().pipe(takeUntil(this.unsub$))
      .subscribe((tariffs: Array<Tariff>) => {

        if (!tariffs) {
          return;
        }

        this.fillTariffs(tariffs);
        this.loadSelectedTariffs();
        this.setSelectedTariffs();
        this.getAvailableDays();
        this.detector.markForCheck();
        this.sendPostMessage();
      });

    this.tariffService.getBalance().pipe(takeUntil(this.unsub$))
      .subscribe( (balance: number) => {
        if (balance !== undefined) {
          this.balance = balance;
        }
      });
  }

  private showPopUp(_tariff: Tariff, isPlus: boolean) {
    const source = { class: 'modal-channels' };
    this.tmpTariff = {
      _tariff: _tariff,
      isPlus: isPlus
    };

    this.modalRef = this.modalService.show(this.templateRef, Object.assign({
      backdrop: true,
      ignoreBackdropClick: true
    }, source));
  }

  goPayment() {
    const saveData = this.getPostData();
    this.cacheService.set('saveTariff', saveData);
    this.featureAccessService.goPayment();
  }

  setPeriod(value: string): void {
    this.tariffService.selectedPeriod = this.selectedPeriod = value;
    this.setSelectedTariffs();
  }

  register() {
    const interval = Interval.after(new Date(), Duration.fromObject({ years: 99 })).toDuration('days')
      .toObject().days;
    this.cookies.set('tariffConfiguration', JSON.stringify(this.getPostData()), interval,
      '/', environment.cookiesOrigin);

    if (this.public) {
      window.top.postMessage({
        goToRegistration: true
      }, environment.landingUrl);
    }
  }

  openWidget() {
    if (this.public) {
      // посылаем лендингу уведомление о том, что юзер кликнул на специальное предложение
      parent?.postMessage('onSpecialOfferClick', '*');
    } else {
      this.chatService.sendMessage(this.translations.specialOfferMessage);
    }
  }
}
