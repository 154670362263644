import { NgModule, Injector } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';
import { AvitoPostComponent } from '@app/shared/avito-post/avito-post.component';
import { ChannelTypeIconComponent } from '@app/shared/channel-type-icon/channel-type-icon.component';
import { CircleProgressBarComponent } from '@app/shared/circle-progress-bar/circle-progress-bar.component';
import { AutocompleteComponent } from '@app/shared/message-input/autocomplete/autocomplete.component';
import { PersonCustomFieldsComponent } from '@app/shared/person/person-custom-fields/person-custom-fields.component';
import { SelectChannelComponent } from '@app/shared/select-channel/select-channel.component';
import { SelectOperatorComponent } from '@app/shared/select-operator/select-operator.component';
import { TelegramSetupComponent } from '@app/shared/telegram-setup/telegram-setup.component';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { ContenteditableValueAccessorModule } from '@tinkoff/angular-contenteditable-accessor';

import { createCustomElement } from '@angular/elements';
import { NG_SCROLLBAR_OPTIONS, NgScrollbar, ScrollViewport } from 'ngx-scrollbar';

import { NgxUploaderModule } from '@angular-ex/uploader';

import { LoaderComponent } from './loader/loader.component';
import { AppealItemComponent } from './appeal-item/appeal-item.component';
import { MessageComponent } from './message/message.component';
import { MessageEmailComponent } from '@app/shared/message-email/message-email.component';
import { PersonComponent } from './person/person.component';
import { MessageInputComponent } from './message-input/message-input.component';
import { MessageInputCompactComponent } from './message-input-compact/message-input-compact.component';
import { MessageDateComponent } from './message-date/message-date.component';
import { TranslateModule } from '@ngx-translate/core';
import { PsInfiniteScrollDirective } from '@app/shared/ps-infinite-scroll.directive';
import { ProgressComponent } from './progress/progress.component';
import { ContentEditableDirective } from '@app/shared/contenteditable';
import { AutofocusDirectiveModule } from '@app/core/directives/autofocus/autofocus.module';
import { ComparableDirectiveModule } from '@app/core/directives/comparable/comparable.module';
import { ReliabilityDirectiveModule } from '@app/core/directives/reliability/reliability.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConversationChatComponent } from './conversation-chat/conversation-chat.component';
import { PickerModule } from './emoji/picker/picker.module';
import { EmojiModule } from '@app/shared/emoji/emoji/emoji.module';
import { EmojiPickerComponent } from './emoji-picker/emoji-picker.component';
import { GhostinputComponent } from '@app/shared/ghostinput/ghostinput.component';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AvatarModule } from '@app/shared/avatar/avatar.module';
import { DashedRectangleModule } from '@app/shared/dashed-rectangle/dashed-rectangle.module';
import { WebcamComponent } from './webcam/webcam.component';
import { PhotoEditorComponent } from './photo-editor/photo-editor.component';
import { DialogComponent } from '@app/core/modal/modal.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { FillPipe } from './fill.pipe';
import { UnescapePipe } from './unescape/unescape.pipe';
import { UniquePipe } from './unique.pipe';
import { ChannelNamePipe } from './channel-name/channel-name.pipe';

import { ProjectSetupComponent } from '@app/shared/project-setup/project-setup.component';
import { ImagePreviewerComponent } from '@app/shared/image-previewer/image-previewer.component';
import { ClientEditComponent } from '@app/shared/client-edit/client-edit.component';
import { ClientRemappingComponent } from '@app/shared/client-remapping/client-remapping.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MaxLengthDirectiveModule } from '@app/core/directives/maxlength/maxlength.module';
import { MaxLength } from '@app/shared/max-length.pipe';
import { QRCodeModule } from 'angularx-qrcode';
import { ProjectSwitchComponent } from '@app/shared/project-switch/project-switch.component';
import { ForcePayComponent } from '@app/shared/force-pay/force-pay.component';
import { TariffSetupComponent } from '@app/settings/tariff-setup/tariff-setup.component';
import { ScrollDirectiveModule } from '@app/core/directives/scroll/scroll.module';
import { AlertsComponentModule } from './alerts/alerts.module';
import { SumPipe } from '@app/shared/sum.pipe';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { CommentsMessageComponent } from '@app/shared/comments-message/comments-message.component';
import { PostSidebarComponent } from '@app/shared/post-sidebar/post-sidebar.component';
import { EditCompanyComponent } from '@app/settings/payment/edit-company/edit-company.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { DadataModule } from '@app/settings/payment/dadata/dadata.module';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { SafePipe } from '@app/shared/safe.pipe';
import { TutorialsComponent } from '@app/settings/tutorials/tutorials.component';
import { SpinnerModule } from './spinner/spinner.module';
import { UnescapeModule } from './unescape/unescape.module';
import { ChannelNameModule } from './channel-name/channel-name.module';
import { HelpNeededComponent } from './help-needed/help-needed.component';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { SortablejsModule } from '@everymundo/ngx-sortablejs';
import { BsDatepickerModule, BsLocaleService, BsDatepickerModule as DatepickerModule } from 'ngx-bootstrap/datepicker';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { DisableControlModule } from '@app/core/directives/disable/disable.module';

const customDefaultOptions = {
  scrollThrottlingTime: 750,
  scrollDebounceTime: 0,
  scrollAnimationTime: 750,
  checkResizeInterval: 1000,
  resizeBypassRefreshThreshold: 5,
  modifyOverflowStyleOfParentScroll: true,
  stripedTable: false,
};

@NgModule({
  imports: [
    CommonModule,
    SpinnerModule,
    TranslateModule,
    AutofocusDirectiveModule,
    ComparableDirectiveModule,
    DisableControlModule,
    ReliabilityDirectiveModule,
    FormsModule,
    ReactiveFormsModule,
    NgxUploaderModule,
    EmojiModule,
    PickerModule,
    AlertsComponentModule,
    AvatarModule,
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    AccordionModule.forRoot(),
    PaginationModule.forRoot(),
    SortablejsModule.forRoot({ animation: 200 }),
    DatepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    DashedRectangleModule,
    MatSliderModule,
    NgSelectModule,
    MaxLengthDirectiveModule,
    QRCodeModule,
    ScrollDirectiveModule,
    VirtualScrollerModule,
    VirtualScrollerModule,
    DadataModule,
    UnescapeModule,
    ChannelNameModule,
    NgbTypeaheadModule,
    NgScrollbar,
    ScrollViewport,
    NgOptimizedImage,
  ],
  declarations: [
    LoaderComponent,
    AppealItemComponent,
    MessageComponent,
    CommentsMessageComponent,
    MessageEmailComponent,
    PersonComponent,
    PersonCustomFieldsComponent,
    MessageInputCompactComponent,
    AutocompleteComponent,
    MessageDateComponent,
    PsInfiniteScrollDirective,
    ContentEditableDirective,
    ProgressComponent,
    ConversationChatComponent,
    EmojiPickerComponent,
    WebcamComponent,
    PhotoEditorComponent,
    DialogComponent,
    FillPipe,
    ProjectSetupComponent,
    ImagePreviewerComponent,
    ClientEditComponent,
    ClientRemappingComponent,
    GhostinputComponent,
    UniquePipe,
    SumPipe,
    ProjectSwitchComponent,
    ForcePayComponent,
    TariffSetupComponent,
    PostSidebarComponent,
    EditCompanyComponent,
    VideoPlayerComponent,
    SafePipe,
    TutorialsComponent,
    HelpNeededComponent,
    AvitoPostComponent,
    TelegramSetupComponent,
    CircleProgressBarComponent,
    SelectChannelComponent,
    SelectOperatorComponent,
    ChannelTypeIconComponent,
  ],
  providers: [
    BsModalService,
    BsLocaleService,
    {
      provide: NG_SCROLLBAR_OPTIONS,
      useValue: {
        visibility: 'hover',
      },
    },
    {
      provide: 'virtual-scroller-default-options',
      useValue: customDefaultOptions,
    },
  ],
  exports: [
    LoaderComponent,
    FormsModule,
    SpinnerModule,
    ReactiveFormsModule,
    AppealItemComponent,
    MessageComponent,
    CommentsMessageComponent,
    MessageEmailComponent,
    PersonComponent,
    MessageInputCompactComponent,
    MessageDateComponent,
    PsInfiniteScrollDirective,
    DisableControlModule,
    ContentEditableDirective,
    ConversationChatComponent,
    NgxUploaderModule,
    EmojiPickerComponent,
    DialogComponent,
    EmojiModule,
    PickerModule,
    TooltipModule,
    BsDropdownModule,
    AvatarModule,
    DashedRectangleModule,
    WebcamComponent,
    PhotoEditorComponent,
    MatSliderModule,
    AccordionModule,
    FillPipe,
    UnescapePipe,
    ProjectSetupComponent,
    ImagePreviewerComponent,
    ClientEditComponent,
    ClientRemappingComponent,
    GhostinputComponent,
    AutofocusDirectiveModule,
    UniquePipe,
    SumPipe,
    MaxLength,
    QRCodeModule,
    ProjectSwitchComponent,
    ForcePayComponent,
    TariffSetupComponent,
    ChannelNamePipe,
    AlertsComponentModule,
    PostSidebarComponent,
    EditCompanyComponent,
    DadataModule,
    VideoPlayerComponent,
    TutorialsComponent,
    HelpNeededComponent,
    SortablejsModule,
    ProgressComponent,
    AvitoPostComponent,
    SafePipe,
    TelegramSetupComponent,
    CircleProgressBarComponent,
    SelectChannelComponent,
    SelectOperatorComponent,
    ChannelTypeIconComponent,
    AutocompleteComponent,
  ],
})
export class SharedModule {
  constructor(
    private injector: Injector,
  ) {
  }

  ngDoBootstrap() {
    const DialogElement = createCustomElement(DialogComponent, { injector: this.injector });
    customElements.define('app-dialog', DialogElement);
  }
}
