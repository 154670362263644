import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, extract, I18nService } from '@app/core';
import { AlertsService } from '@app/core/alerts/alerts.service';
import { RegistrationApiService } from '@app/core/api/api.registration';
import { EMAIL_REGEX } from '@app/core/config/regExValidators';
import { CookiesService } from '@app/core/cookies.service';
import { FaceBookPixelService } from '@app/core/fbpixel.service';
import { GoogleTagService } from '@app/core/gtag.service';
import { LocationService } from '@app/core/location.service';
import { MailRuAnalyticsService } from '@app/core/mail-ru.service';
import { ANALYTICS_EVENT, MetrikaService } from '@app/core/metrika.service';
import { TimezoneService } from '@app/core/timezone.service';
import { VkAnalyticsService } from '@app/core/vk.service';
import { YaglaAnalyticsService } from '@app/core/yagla.service';
import { environment } from '@env/environment';
import { Angulartics2 } from 'angulartics2';
import cloneDeep from 'lodash-es/cloneDeep';
import each from 'lodash-es/each';
import { Subject } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';

const REGISTRATION_FORM_KEY = 'registrationFormData';

type ClientGoalType = 'blog' | 'omnicanal';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  private unsub$ = new Subject<void>();

  originUrl: string;

  isLoading = false;
  errorsList: any = {};
  inIframe = false;
  error: string;

  registerForm: UntypedFormGroup;

  clientGoal: null | ClientGoalType = null;

  @ViewChild('registerFormForm', { static: true }) registerFormForm: NgForm;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private i18nService: I18nService,
    private api: RegistrationApiService,
    private auth: AuthenticationService,
    private detector: ChangeDetectorRef,
    private timezone: TimezoneService,
    private alerts: AlertsService,
    private meta: Meta,
    private angulartics2: Angulartics2,
    private titleService: Title,
    private cookies: CookiesService,
    private locationService: LocationService,
    private metrika: MetrikaService,
    private fbPixel: FaceBookPixelService,
    private gtag: GoogleTagService,
    private vk: VkAnalyticsService,
    private yagla: YaglaAnalyticsService,
    private mailRu: MailRuAnalyticsService,
  ) {
    const baseDomain = environment.production ? `https://${environment.baseDomain}` : environment.baseDomain;
    if (window.self !== window.top) {
      this.inIframe = true;
    } else if ((window.location.origin.toLowerCase() !== baseDomain)) {
      window.location.href = baseDomain + '/register';
    }

    const title = this.route.snapshot.data['title'];
    if (title) {
      this.i18nService.translateService.getTranslation(this.i18nService.language)
        .pipe(take(1))
        .subscribe((language: any) => {
          if (language) {
            this.titleService.setTitle(language.route[title.toLowerCase()]);
          }
        });
    }
  }

  ngOnInit() {
    this.alerts.removeAll();

    const emailParam: string = this.route.snapshot.queryParamMap.get('email');
    const promoParam: string = this.route.snapshot.queryParamMap.get('p') ||
      this.route.snapshot.queryParamMap.get('promo');
    this.createForm();

    const registerFormData = localStorage.getItem(REGISTRATION_FORM_KEY);
    const data = JSON.parse(registerFormData);
    const time = data && parseInt(data.submitTime, 10);

    const isNotExpired = time && time > new Date().getTime() + (1000 * 60 * 10);
    if (data && isNotExpired) {
      const fields = ['email', 'name', 'lastName', 'phone', 'companyScope', 'companyName', 'companySize',
        'companyRole'];

      fields.forEach((field) => {
        this.registerForm.controls[field].setValue(data[field] || '');
      });
    }

    if (emailParam) {
      this.registerForm.controls.email.setValue(emailParam);
    }

    if (promoParam) {
      this.cookies.setPromoCode(promoParam);
    }

    if (this.inIframe) {
      window.addEventListener('message', (event: MessageEvent) => {
        this.listener(event);
      });
    }
  }

  createForm() {
    this.registerForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(EMAIL_REGEX)])],
      acceptedPrivacy: [false, [Validators.requiredTrue]],
      mailingAllowed: [false],
      fromLanding: ['']
    });

    this.route.queryParams
      .pipe(takeUntil(this.unsub$))
      .subscribe(params => this.registerForm.controls.email.setValue(params.email || ''));
  }

  validateOnSubmit(formName: string = 'loginForm') {
    const form = this[formName];

    each(form.controls, (control, name) => {
      this.errorsList[name] = control.errors !== null;
    });
  }

  handleFieldBlur(form: string, field: string) {
    const fieldState = this[form].get(field);
    this.errorsList[field] = !fieldState.valid && fieldState.value.trim() !== '';
  }

  extract(string: string) {
    return extract(string);
  }

  public register(): void {
    this.validateOnSubmit('registerForm');

    if (this.registerForm.valid === false) {
      return;
    }
    this.isLoading = true;

    this.alerts.removeAll();

    const browserData = this.getBrowser();

    const formValue = cloneDeep(this.registerForm.value);
    formValue.timezone = this.timezone.getFittingZone();
    formValue.language = this.i18nService.language;
    formValue.roistatVisit = this.cookies.get('roistat_visit') ||
      ((<any>window).roistat && (<any>window).roistat.visit);
    formValue.yandexId = this.cookies.get('_ym_uid');
    formValue.device = navigator.platform;
    formValue.browser = browserData.name;
    formValue.browserVersion = browserData.version;
    formValue.os = this.getOS();
    formValue.osVersion = navigator.appVersion;

    this.api.register(formValue).pipe(
      takeUntil(this.unsub$),
      finalize(() => {
        this.isLoading = false;
        this.detector.detectChanges();
      }),
    ).subscribe((data) => {
      this.registerForm.markAsPristine();
      if (data.success === false) {
        this.error = data.errors[0].message || 'Error, could not authorize :-(';
        return;
      }
      this.angulartics2.eventTrack.next({
        action: 'createaccount',
        properties: {
          category: 'button'
        }
      });
      this.metrika.goal(ANALYTICS_EVENT.MAIL_SEND);
      this.gtag.track(ANALYTICS_EVENT.MAIL_SEND, { formValue });
      this.fbPixel.track(ANALYTICS_EVENT.MAIL_SEND);
      this.vk.track(ANALYTICS_EVENT.MAIL_SEND);
      this.yagla.track(ANALYTICS_EVENT.MAIL_SEND);
      this.mailRu.track(ANALYTICS_EVENT.MAIL_SEND);

      if (this.inIframe) {
        window.parent.postMessage(
          {
            type: 'redirect',
            url: `${document.location.origin}/registration/profile/${data.data.token}`
            // url: `${document.location.origin}/registration/email-confirm/${data.data.token}`
          },
          environment.landingUrl
        );
      } else {
        // this.router.navigate([`/registration/email-confirm/${data.data.token}`],
        this.router.navigate([`/registration/profile/${data.data.token}`], { replaceUrl: true });
      }
    });
  }

  listener(event: MessageEvent): void {
    const data = event.data;

    if (data.type === undefined) {
      return;
    }

    if (data.type === 'typing') {
      const email = data.email;
      this.registerForm.controls.email.setValue(email);
    } else if (data.type === 'setOriginUrl') {
      this.originUrl = data.url;
      this.registerForm.controls.fromLanding.setValue(this.originUrl);
    }
  }

  showError() {
    return this.errorsList && this.errorsList.email ?
      this.registerForm.get('email').hasError('required') ||
      this.registerForm.get('email').hasError('pattern') :
      this.errorsList.email;
  }

  public goToLanding() {
    this.locationService.goToLanding();
  }

  sendAnalyticsEvent(event: ANALYTICS_EVENT, args: object = null) {
    this.metrika.goal(event, args);
    this.gtag.track(event, args);
    this.fbPixel.track(event);
  }

  getBrowser() {
    var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: (tem[1] || '') };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR|Edge\/(\d+)/)
      if (tem != null) { return { name: 'Opera', version: tem[1] }; }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
    return {
      name: M[0],
      version: M[1]
    };
  }

  getOS() {
    const userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  }

  setGoal(goal: ClientGoalType) {
    this.clientGoal = goal;
    const event = goal === 'blog' ? ANALYTICS_EVENT.CLIENT_CHOOSE_TELETYPE_IN : ANALYTICS_EVENT.CLIENT_CHOOSE_TELETYPE_APP;
    this.metrika.goal(event);
  }
}
