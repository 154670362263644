import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@app/core/api/api.service';
import { Response } from '@app/core/interfaces/api.response';
import { routes } from '@app/core/config/apiRoutes';
import { ChannelIdContext } from '@app/core/api/api.channel';
import { UtilsService } from '@app/core/utils.service';

export interface ActivateTelegramContext {
  code?: string;
  password?: string;
}

export type TelegramChannelStatus =
  'pending' | // созданный, канала, в котором не было ни одной успешной авторизации
  'need action' | // канал, который требует авторизации (например после разлогина)
  'error' | // канал в состоянии ошибки, требует повторной авторизации
  'need extra auth' | // промежуточное состояние канала, требующее ввода облачного пароля. Может возникнуть как после попытки авторизации по коду, так и после попытки авторизации по QR
  'active'; // работающий канал

export interface TelegramInstance {
  id: string;
  status: TelegramChannelStatus;
}

export interface QrDataForAuthTelegram {
  id: string; // Внешний идентификатор созданного канала
  status: TelegramChannelStatus; // Имя текущего статуса созданного канала
  qrCode: string; // URL для генерации QR кода
  expiresAt: number; // Время экспирации QR кода
}

export interface SendCodeForAuthTelegramResponse {
  id: string; //  Внешний идентификатор созданного канала
  status: TelegramChannelStatus; // 'pending' или 'need extra auth'
  phoneNumber: string; //  Нормализованный номер телефона, переданный с фронта (не сохраненный пока в модель)
}

export interface TelegramBotContext {
  id?: string;
  name?: string;
  token: string;
  linkedCrms?: Array<any>;
}

@Injectable({
  providedIn: 'root',
})
export class TelegramApiService {
  private routes = routes;

  constructor(
    private api: ApiService,
    private utils: UtilsService,
  ) {}

  public createInstance(): Observable<Response<TelegramInstance>> {
    return this.api.httpPost(this.routes.createTelegram);
  }

  public getQrForAuth(channelId: string): Observable<Response<QrDataForAuthTelegram>> {
    return this.api.httpPost(this.routes.getQrForAuthTelegram(channelId));
  }

  public sendCodeForAuth(id: string, phone: string): Observable<Response<SendCodeForAuthTelegramResponse>> {
    return this.api.httpPost(this.routes.sendPincodeForAuthTelegram(id), { phone });
  }

  public finishAuth(channelId: string, data: ActivateTelegramContext): Observable<Response<TelegramInstance>> {
    return this.api.httpPost(this.routes.finishAuthTelegram(channelId), this.utils.snakeKeys(data));
  }

  public updateTelegram(id: string, data: any): Observable<Response> {
    return this.api.httpPost(this.routes.updateTelegram(id), this.utils.snakeKeys(data));
  }

  public getAuthLink(id: string): Observable<Response> {
    return this.api.httpGet(this.routes.getAuthLinkTelegram(id));
  }

  public logoutInstance(channelId: string): Observable<Response> {
    return this.api.httpGet(this.routes.logoutTelegram(channelId));
  }

  public removeTelegram(id: ChannelIdContext): Observable<Response> {
    return this.api.httpPost(this.routes.removeTelegram(id), null);
  }

  // бот методы

  public telegramBotDelete(id: string): Observable<Response> {
    return this.api.httpGet(this.routes.telegramBotDelete(id));
  }

  public telegramBotUpdate(id: string | null, data: TelegramBotContext): Observable<Response> {
    return this.api.httpPost(this.routes.telegramBotUpdate(id), this.utils.snakeKeys(data));
  }
}
