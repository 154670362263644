import { Injectable, OnDestroy } from '@angular/core';
import {
  AnswerPatternApiService,
  TemplateDirectory,
  TemplateMessage,
  TemplateType,
} from '@app/core/api/api.answer-pattern';
import { ChannelService, ChannelType } from '@app/core/channel.service';
import { Response } from '@app/core/interfaces/api.response';
import { ProjectService } from '@app/core/project.service';
import { UtilsService } from '@app/core/utils.service';
import { WebsocketsService, WsEvent } from '@app/core/websockets.service';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

export interface PatternsByDirectories {
  directories: TemplateDirectory[];
  withoutDirectories: {
    templates: TemplateMessage[]
  };
}

@Injectable({
  providedIn: 'root',
})
export class AnswerPatternService implements OnDestroy {
  private requestPending: boolean;
  private patternsWithDirectories$: ReplaySubject<PatternsByDirectories> = new ReplaySubject(1);
  private templatesForWaba: ReplaySubject<TemplateMessage[]> = new ReplaySubject(1);
  public isPatternsLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public templateDirectories$ = new BehaviorSubject<TemplateDirectory[]>([]);

  get isPatternsLoaded$() {
    return this.isPatternsLoaded.asObservable();
  }

  private set patternsWithDirectories(data: PatternsByDirectories) {
    const wabaTemplates: TemplateMessage[] = [],
      teletypeTemplates: TemplateMessage[] = [];

    data.withoutDirectories.templates.forEach((template) => {
      if (template.type === TemplateType.Waba) {
        wabaTemplates.push(template);
      } else {
        teletypeTemplates.push(template);
      }
    });

    data.withoutDirectories.templates = teletypeTemplates;
    this.patternsWithDirectories$.next(data);
    this.templatesForWaba.next(wabaTemplates);
  }

  constructor(
    private api: AnswerPatternApiService,
    private projectService: ProjectService,
    private ws: WebsocketsService,
    private utils: UtilsService,
    private channelService: ChannelService,
  ) {}

  ngOnDestroy() {
    this.ws.removeListener(WsEvent.MESSAGES_TEMPLATES_UPDATED);
  }

  startWatchSocket() {
    this.ws.on(WsEvent.MESSAGES_TEMPLATES_UPDATED, (_data: any) => {
      const data = this.utils.camelKeys(_data);
      const { projectId } = data;

      if (projectId !== this.projectService.currentProject.id) {
        return;
      }

      // TODO: вместо полной загрузки справочника использовать данные из ws события (если есть возможность)
      this.updateListPatternsByDirectories();
      // этот метод вызывать нет нужды, т к все необходимые данные можно получить из предыдущего
      // this.updateListDirectories();
    });
  }

  getPatternsByDirectories(): Observable<PatternsByDirectories> {
    const needLoadPatterns = !this.isPatternsLoaded.getValue();
    if (needLoadPatterns) {
      this.updateListPatternsByDirectories();
      this.startWatchSocket();
    }

    return this.patternsWithDirectories$.asObservable();
  }

  getWabaTemaplatesByDirectoriesForChannel(channelId: string): Observable<PatternsByDirectories> {
    const needLoadPatterns = !this.isPatternsLoaded.getValue();
    if (needLoadPatterns) {
      this.updateListPatternsByDirectories();
      this.startWatchSocket();
    }

    return this.templatesForWaba.asObservable().pipe(
      map((templates) => {
        const filteredTemplates = templates.filter(template => template.forChannel === channelId);

        return {
          directories: [],
          withoutDirectories: {
            templates: filteredTemplates
          },
        }
      }),
    );

  }

  getTemplatesDirectories(): Observable<TemplateDirectory[]> {
    return this.getPatternsByDirectories().pipe(
      map((patternsByDirectory) => patternsByDirectory.directories),
    );
  }

  getWabaTemplatesByChannels(): Observable<Record<string, TemplateMessage[]>> {
    const needLoadPatterns = !this.isPatternsLoaded.getValue();
    if (needLoadPatterns) {
      this.updateListPatternsByDirectories();
      this.startWatchSocket();
    }

    return this.templatesForWaba.pipe(
      map(templates => {
        const result: Record<string, TemplateMessage[]> = {};

        templates.forEach((template) => {
          if (template.forChannel) {
            if (result.hasOwnProperty(template.forChannel)) {
              result[template.forChannel].push(template);
            } else {
              result[template.forChannel] = [template];
            }
          }
        });

        return result;
      }),
    );
  }

  updateListPatternsByDirectories() {
    if (this.requestPending) {
      return;
    }
    this.requestPending = true;

    this.api.getTemplatesListByDirectories().pipe(
      finalize(() => {
        this.isPatternsLoaded.next(true);
        this.requestPending = false;
      }),
    ).subscribe((response) => {
      if (response.success) {
        this.patternsWithDirectories = response.data;
      }
    });
  }

  updateListDirectories() {
    const sub = this.api.getDirectoriesList().subscribe((response: Response) => {
      if (response.success) {
        this.templateDirectories$.next(response.data);
      }

      sub.unsubscribe();
    });
  }

  uploadWabaTemplates() {
    // возвращаем вызвавшему только статус операции
    return new Observable<boolean>((observe) => {
      observe.next(true);

      const requests: Observable<Response<null>>[] = [];

      this.channelService.channels.forEach((channel) => {
        if (channel.type === ChannelType.WhatsappEdna) {
          requests.push(this.api.updateWabaTemplates(channel.id));
        }
      });

      const sub = combineLatest(requests).subscribe(responses => {
        const isActionSuccess = responses.every(res => res.success);

        if (isActionSuccess) {
          this.updateListPatternsByDirectories();
        }

        observe.next(false);
        observe.complete();
        sub.unsubscribe();
      });
    });
  }
}
