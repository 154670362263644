import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { Response } from '@app/core/interfaces/api.response';
import { AuthenticationService } from '@app/core';
import { RegistrationApiService } from '@app/core/api/api.registration';

@Injectable({
  providedIn: 'root'
})
export class InviteResolver implements Resolve<any> {
  constructor(
    private api: RegistrationApiService,
    private router: Router,
    private auth: AuthenticationService,
  ) {}

  resolve(route: ActivatedRouteSnapshot) {

    const token = route.paramMap.get('token');

    return this.api.checkInviteToken(token).pipe(map((response: Response) => {
      const data = response.success && response.data;
      const existing = data && data.status === 20;

      if (existing) {
        return this.api.completeInvite(token, null).pipe(take(1))
          .subscribe((_res: Response) => {
            const _data = _res.data;
            const accessToken = _data && _data.accessToken;
            if (accessToken) {
              this.auth.login(accessToken);
              this.router.navigate(['/home'], { replaceUrl: true });
              // Костыль. Почему-то без этой перезагрузки InviteComponent продолжал оставаться на экране.
              // Подозреваю, что виноват кто-то из resolver-ов, но не смог выяснить кто именно.
              window.location.reload();
            }
          }, () => {}, () => {});
      } else if (!response.success) {
        this.router.navigate(['/'], { replaceUrl: true });
      }

      return response.data;
    }));
  }
}
