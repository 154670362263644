import {
  Component,
  OnInit,
  Input,
  Output,
  SimpleChanges,
  OnChanges,
  ElementRef,
  ChangeDetectorRef,
  ViewChild,
  OnDestroy,
  ChangeDetectionStrategy,
  NgZone,
  EventEmitter,
  AfterViewInit, HostListener,
} from '@angular/core';
import { ChannelService } from '@app/core/channel.service';
import { FeatureFlagsService } from '@app/core/feature-flags.service';
import { Message, Person } from '@app/core/interfaces/message';
import css from 'stylefire';
import ResizeObserver from 'resize-observer-polyfill';
import each from 'lodash-es/each';

import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConversationData } from '@app/core/interfaces/api.response';

import { DateTime } from 'luxon';
import { I18nService } from '@app/core';
import { ApiService } from '@app/core/api/api.service';
import { AlertsService } from '@app/core/alerts/alerts.service';
import { AttachmentsService } from '@app/core/attachments.service';
import { UserService } from '@app/core/user.service';
import { environment } from '@env/environment';
import { IPageInfo, VirtualScrollerComponent } from '@iharbeck/ngx-virtual-scroller';
import { cloneDeep } from 'lodash-es';

interface IComplexItem {
  id: number;
  attachmentId: number;
}

@Component({
  selector: 'app-conversation-chat',
  templateUrl: './conversation-chat.component.html',
  styleUrls: ['./conversation-chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class ConversationChatComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  checkForUserScroll = false;
  conversationData: ConversationData;
  items = [];
  isNewChild: boolean;
  @Input() personTyping: boolean | any = false;
  @Input() paidEntities: any;
  @Input() allowChannelsId: Array<string>;
  @Input() conversation: Observable<any>;
  @Input() conversationBlockPaddingBottom: number;
  @Input() person: Person;
  @Input() fullHeight = false;
  @Input() selectedChannel: string; // id выбранного канала, а также фильтры, например 'all', 'close'
  @Input() selectedClientId: string;
  @Input() instagramDirectChannels: any;
  @Input() ownNewMessage = '';
  @Input() hasPermissionToArchive: boolean;
  @Input() forceScroll: boolean;
  @Input() userScrolled: boolean;
  @Input() isOpen: boolean;
  @Input() hasQueue: boolean;
  @Input() isAdmin: boolean;
  @Input() appealId: string;
  @Input() amoWidget?: boolean;
  @Input() scrollToNextThreadId: string | null;
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() loadChildMessages: EventEmitter<any> = new EventEmitter();
  @Output() answerMessages: EventEmitter<any> = new EventEmitter();
  @Output() replayMessages: EventEmitter<any> = new EventEmitter();
  @Output() writeToDirect: EventEmitter<any> = new EventEmitter();
  @Output() seenMessage: EventEmitter<any> = new EventEmitter();
  @Output() clickToPerson: EventEmitter<any> = new EventEmitter();
  @Output() resetClickToPerson: EventEmitter<any> = new EventEmitter();

  private containerCss: any;
  public personLastOnlineAt24: string;
  private ro: ResizeObserver;
  public typingMessage: Message;

  public showPreviewer = false;
  public previewerImage: string;
  public attachments: any;

  private unsub$ = new Subject<void>();
  public label: string;
  private inProgress: boolean;
  public showUpSpinner: boolean;
  private prevCount: number;
  private itemsIsLoading = true;

  // @ts-ignore
  @ViewChild('chatContainer', { read: ElementRef }) chatContainer: ElementRef;
  // @ts-ignore
  @ViewChild('scroll', { read: ElementRef }) scroll: ElementRef;
  // @ts-ignore
  @ViewChild('appealMessagesContainerInner', { read: ElementRef }) appealMessagesContainerInner: ElementRef;
  @ViewChild('scroll') private virtualScroller: VirtualScrollerComponent;

  @Output() appealDeleted: EventEmitter<string> = new EventEmitter();
  @Output() dialogStatusTrigger = new EventEmitter();
  @Output() setAllSeen = new EventEmitter();
  @Output() retry: EventEmitter<Message> = new EventEmitter();
  @Output() remove: EventEmitter<Message> = new EventEmitter();
  @Output() scrolled: EventEmitter<string> = new EventEmitter();

  public language: any;
  private _messageTails: boolean[] = [];
  private _messageDay: string[] = [];
  public floatingDate  = '';
  private scrollEvent: any;
  private maxScrollBefore: number;
  private maxScrollAfter: number;
  isAvailableDeleteAppeal = this.featureFlagsService.isAvailableDeleteAppeal;

  get isChannelSupportReplay(): boolean {
    const availableChannels = ['telegram-bot', 'telegram', 'whatsapp_teletype', 'vk', 'vk_direct'];
    const currentChannelType = this.channelService.getChannelTypeById(this.selectedChannel);
    return availableChannels.includes(currentChannelType);
  }

  get conversationName(): string {
    const { conversationData, person } = this;

    if (conversationData.selectedAppealMessenger === 'email' && conversationData.subject) {
      return conversationData.subject;
    }

    if (this.isGroupChat) {
      return conversationData?.channelSpecific?.groupChat?.caption;
    }

    if (conversationData?.postCaption) {
      return conversationData?.postCaption;
    }

    if (conversationData?.userNameComments) {
      return conversationData?.userNameComments;
    }

    if (person?.name) {
      return person?.name;
    }

    return conversationData?.userName || '';
  }

  get isGroupChat(): boolean {
    return !!this.conversationData?.channelSpecific?.groupChat;
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ((event.metaKey || event.ctrlKey) && event.code === 'KeyQ') {
      this.switchDialogState();
      event.preventDefault();
    }
  }

  constructor(
    private detector: ChangeDetectorRef,
    private zone: NgZone,
    private i18n: I18nService,
    private api: ApiService,
    private alert: AlertsService,
    private attachmentsService: AttachmentsService,
    private userService: UserService,
    private channelService: ChannelService,
    private featureFlagsService: FeatureFlagsService,
  ) {
    this.language = this.i18n.translateService.translations[this.i18n.language];
    this.ro = new ResizeObserver((entries) => {
      if (this.scroll === undefined) {
        return;
      }

      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }

        each(entries, (entry) => {
          if (entry.target === this.chatContainer.nativeElement) {
            this.scrollToBottomIfNecessary();
          }
        });
      });
    });
  }

  ngAfterViewInit(): void {
    this.scrollToBottomIfNecessary(0);
  }

  ngOnInit() {
    this.conversation.pipe(takeUntil(this.unsub$)).subscribe((data: any) => {
      if (!data) {
        this.conversationData = data;
        this.detector.markForCheck();
        return;
      }

      if (this.scroll) {
        this.containerCss = css(this.scroll.nativeElement, {});
      }
      if (this.chatContainer) {
        this.ro.observe(this.chatContainer.nativeElement);
      }

      this._messageTails = [];
      this._messageDay = [];

      if (this.conversationData) {
        this.conversationData.items = data.items;
        this.conversationData.from = data.from;
        this.conversationData.to = data.to;
        this.conversationData.messagesCount = data.messagesCount;
        this.conversationData.isOpen = data.isOpen;
      } else {
        this.conversationData = data;
        this.label = this.conversationData && this.conversationData.channelName
          || this.conversationData.selectedAppealMessenger;
        if (this.label && !this.conversationData.channelName) {
          this.label = this.label.replace('web-widget', 'online chat')
            .replace('whatsapp_teletype', 'whatsapp');
        }
      }

      if (this.conversationData.items.length > 0) {
        each(this.conversationData.items, (message: Message, i: number) => {
          if (message && message.date) {
            message.hasTail = this.hasTail(i);
            if (message && message.number === 1) {
              message.startDate = DateTime.fromISO(message.date.date.toString()).setLocale(this.i18n.language)
                .setZone(message.date.timezone).toFormat(environment.features.dateFormat[this.i18n.language]);
            }
            if (message.newDay === undefined) {
              message.newDay = this.getDate(i);
            }
          }
          if (message.appeal && message.appeal.isOpen) {
            this.conversationData.isOpen = true;
          }
        });

        if (this.conversationData?.selectedAppealMessenger === 'email') {
          this.items = [...this.mergeObjectsBySourceId(this.conversationData.items)];
        } else {
          this.items = [...this.conversationData.items];
        }

        this.isNewChild = false;
        each(this.items, (item) => {
          each(item.childs, (child) => {
            this.detector.detectChanges();
            if (child.status === 'sent') {
              const tmp = cloneDeep(item.childs);
              delete item.childs;
              item.childs = [...tmp];
              this.isNewChild = true;
            }
          });
        });
      }

      this.typingMessage = {
        isOwn: false,
        messageType: 'typing',
        from: {
          name: this.conversationData.personAvatar,
          avatar: this.conversationData.personName,
        },
      };

      if (this.showUpSpinner) {
        const startIdx = this.scrollEvent.startIndex;
        setTimeout(() => {
          if (startIdx < 3) {
            const item = this.items[this.items.length - this.prevCount - 1];
            this.showUpSpinner = false;
            this.virtualScroller.refresh();
            this.virtualScroller
              .scrollInto(item, true, 0, 0, () => {
                this.showUpSpinner = false;
                if (!this.detector['destroyed']) {
                  this.detector.detectChanges();
                }
              });
          } else {
            const endIndex = this.items.length - this.prevCount + this.scrollEvent.startIndex;
            const item = this.items[endIndex];
            this.virtualScroller.refresh();
            this.showUpSpinner = false;
            this.virtualScroller
              .scrollInto(item, false, 0, 0, () => {
                this.showUpSpinner = false;
                if (!this.detector['destroyed']) {
                  this.detector.detectChanges();
                }
              });
          }
        });
      } else {
        this.scrollToBottomIfNecessary(0);
      }

      this.detector.markForCheck();
    });

    this.attachmentsService.state$
      .pipe(takeUntil(this.unsub$))
      .subscribe((state) => {
        const {
          attachments,
          staticAttachments,
        } = state;

        this.attachments = [...attachments, ...staticAttachments];
        this.detector.detectChanges();
      });
  }

  mergeObjectsBySourceId(objects) {
    const mergedObjects = [];

    objects.forEach((obj) => {
      const groupBy = obj.isOwn ? 'sourceTemporaryId' : 'sourceExternalId';
      if (mergedObjects.length === 0 || !obj[groupBy] || mergedObjects[mergedObjects.length - 1][groupBy] !== obj[groupBy]) {
        mergedObjects.push({ ...obj });
      } else {
        mergedObjects[mergedObjects.length - 1] = {
          ...mergedObjects[mergedObjects.length - 1],
          attachments: [...(mergedObjects[mergedObjects.length - 1].attachments || []), ...(obj.attachments || [])],
        };
      }
    });

    return mergedObjects;
  }

  ngOnDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
    this.ro.unobserve(this.chatContainer?.nativeElement);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.conversationBlockPaddingBottom !== undefined && this.containerCss !== undefined) {
      // ломало скролл в инстаграм коммент канале
      // this.containerCss.set({
      //   bottom: `${changes.conversationBlockPaddingBottom.currentValue}px`,
      // });

      this.detector.detectChanges();
    }

    if (changes.scrollToNextThreadId !== undefined && changes.scrollToNextThreadId.currentValue !== undefined) {
      this.scrollToNextThreadId = changes.scrollToNextThreadId.currentValue;
      this.scrollToElement(this.scrollToNextThreadId);
    }

    if (changes.userScrolled !== undefined && changes.userScrolled.currentValue !== undefined) {
      this.userScrolled = changes.userScrolled.currentValue;
    }

    if (changes.isOpen !== undefined && changes.isOpen.currentValue !== undefined) {
      this.isOpen = changes.isOpen.currentValue;
    }

    const typingChange = changes.personTyping;

    if (typingChange && typingChange.currentValue && typingChange.currentValue.person) {
      this.typingMessage = {
        isOwn: typingChange.currentValue.isOperator || false,
        messageType: 'typing',
        message: this.personTyping.message,
        from: this.personTyping.person,
      };
    }

    if (this.person && this.person.lastOnlineAt) {
      if (this.person.lastOnlineAt.indexOf('T') !== -1) {
        this.personLastOnlineAt24 = DateTime.fromISO(this.person.lastOnlineAt)
          .setZone(this.userService.user.timezone)
          .toLocaleString(DateTime.TIME_24_SIMPLE);
      } else {
        this.personLastOnlineAt24 = DateTime.fromSQL(this.person.lastOnlineAt, { zone: 'UTC' })
          .setZone(this.userService.user.timezone)
          .toLocaleString(DateTime.TIME_24_SIMPLE);
      }
    }
  }

  public myTrackByFunction(index: number, complexItem: IComplexItem): number {
    return complexItem.id || complexItem.attachmentId;
  }

  public scrollToElement(appealId: string) {
    const nextItem = this.items.find((item) => item.appealId === appealId);
    this.virtualScroller
      .scrollInto(nextItem, false, 10, 450, () => {
        if (!this.detector['destroyed']) {
          this.detector.detectChanges();
        }
      });
  }

  switchDialogState(): void {
    if (!this.isOpen) {
      return;
    }
    if (this.hasPermissionToArchive) {
      this.isOpen = false;
      this.dialogStatusTrigger.emit(this.isOpen);
    } else {
      this.alert.addAlert({
        type: 'error',
        content: this.language.conversation.closeDialogAccess,
      });
    }
  }

  setAllMessageSeen(): void {
    if (!this.isOpen) {
      return;
    }

    this.setAllSeen.emit();
  }

  markAllCommentsAsRead(): void {
    if (!this.isOpen) {
      return;
    }
    this.isOpen = false;
    this.setAllSeen.emit();
  }

  getDate(index: number) {
    if (this._messageDay[index] !== undefined) {
      return this._messageDay[index];
    }

    const messages = this.conversationData.items || [];
    if (!messages.length || !messages[index] || !messages[index].date || !messages[index - 1]
      || !messages[index - 1].date) {
      this._messageDay[index] = '';
      return this._messageDay[index];
    }

    const messageDate = DateTime.fromISO(messages[index].date.date.toString()).setLocale(this.i18n.language)
      .setZone(messages[index].date.timezone);

    const prevMessageDate = index > 0 && DateTime.fromISO(messages[index - 1].date.date.toString())
      .setLocale(this.i18n.language).setZone(messages[index - 1].date.timezone);

    const messageDateFormatted = messageDate.toFormat(environment.features.dateFormat[this.i18n.language]
      || 'en');
    const prevMessageDateFormatted = prevMessageDate
      && prevMessageDate.toFormat(environment.features.dateFormat[this.i18n.language] || 'en');
    this._messageDay[index] = messageDateFormatted !== prevMessageDateFormatted && messageDateFormatted + this.language.th || '';
    return this._messageDay[index];
  }

  hasTail(index: number) {
    if (this._messageTails[index] !== undefined) {
      return this._messageTails[index];
    }

    const message = this.conversationData.items[index];
    if (!message.date) {
      return false;
    }
    const nextMessage = this.conversationData.items[index + 1];

    if (nextMessage === undefined) {
      this._messageTails[index] = false;
      return false;
    }

    const operatorId = message && message.from && message.from.id;
    const nextOperatorId = nextMessage && nextMessage.from && nextMessage.from.id;
    const messageType = message && message.type;
    const nextMessageType = nextMessage && nextMessage.type;
    const messageProvider = message && message.provider;
    const nextMessageProvider = nextMessage && nextMessage.provider;

    const messageDate = DateTime.fromISO(message.date.date.toString()).setLocale(this.i18n.language)
      .setZone(message.date.timezone);
    const nextMessageDate = DateTime.fromISO(nextMessage.date.date.toString()).setLocale(this.i18n.language)
      .setZone(nextMessage.date.timezone);
    const nextMessageIsOwn = nextMessage.isOwn === message.isOwn;
    const nextMessageInterval = nextMessageDate.diff(messageDate, 'seconds').seconds;
    this._messageTails[index] = nextMessageIsOwn && nextMessageInterval < 60 && operatorId === nextOperatorId
      && messageType === nextMessageType && messageProvider === nextMessageProvider;
    return this._messageTails[index];
  }

  scrollToBottomIfNecessary(timeout: number = 50): void {
    if (this.virtualScroller && (!this.userScrolled || (this.forceScroll && !this.userScrolled))) {
      setTimeout(() => {
        this.checkForUserScroll = true;
        this.virtualScroller
          .scrollToIndex(this.items.length + 1,
            true, 800, 0, () => {
              this.itemsIsLoading = false;
              if (!this.detector['destroyed']) {
                this.detector.detectChanges();
              }
            });

        this.showUpSpinner = false;
        this.detector.markForCheck();
      }, timeout);
    }
  }

  triggerPreviewer(url: string): void {
    this.showPreviewer = true;
    this.previewerImage = url;
    this.detector.detectChanges();
  }

  hideImagePreviewer(): void {
    this.showPreviewer = false;
    this.detector.detectChanges();
  }

  handleRetry(message: Message) {
    this.retry.emit(message);
  }

  handleRemoveMessage(message: Message) {
    this.remove.emit(message);
  }

  appealDelete(): void {
    if (this.inProgress) {
      return;
    }
    const appealId = this.conversationData.selectedAppealId;

    if (this.hasPermissionToArchive) {
      this.alert.confirmDelete({
        title: 'appeal.confirmDelete.title',
        content: 'appeal.confirmDelete.content',
        okButtonText: this.language.button.delete,
        cancelButtonText: this.language.button.cancel,
        okCallback: () => {
          this.inProgress = true;
          this.appealDeleted.emit(appealId);
        },
        cancelCallback: () => {},
      });
    } else {
      this.alert.addAlert({
        type: 'error',
        content: this.language.conversation.archiveDialogAccess,
      });
    }
  }

  getEmailQuote(index: number): any {
    const messages = this.conversationData.items;
    const message = messages[index];

    // если сообщение является заметкой, то цитата нужна
    if (Number(message.type) === 20) {
      return null;
    }

    const prevMessages = messages.slice(0, index).reverse();
    let quote = null;
    if (prevMessages && prevMessages.length) {
      quote = prevMessages.find((msg) => !msg.systemType && msg.isOwn !== message.isOwn);
    }
    return quote;
  }

  hasCreatingMessageEmail(): boolean {
    return !!(this.ownNewMessage || this.attachmentsService.hasAttachments());
  }

  buildNewEmailMessage(): any {
    const appealId = this.conversationData.selectedAppealId;

    return new BehaviorSubject({
      appealId,
      message: this.ownNewMessage,
      attachments: this.attachments,
      isOwn: true,
      emojiOnly: false,
      messageType: 'text',
      date: {
        date: DateTime.local().toString(),
        timezone: DateTime.local().zoneName,
      },
      from: {
        name: `${this.userService.user.name} ${this.userService.user.lastName}`,
        avatar: this.userService.user.avatar,
        avatarDefault: this.userService.user.avatarDefault,
        messenger: appealId,
      },
    });
  }

  onScroll() {
    // this.scrolled.emit('forward');
  }

  startScroll(event: any) {
    // console.log('startScroll=', event);
  }

  endScroll(event: any) {
    this.scrollEvent = event;
    this.maxScrollAfter = event.maxScrollPosition;
    if (event && event.startIndex <= 1 && event.endIndex !== 0) {
      this.onScrollUp(event);
    }
  }

  onScrollUp(event: any) {
    if (this.conversationData.from <= 2 || this.showUpSpinner || this.itemsIsLoading) {
      return;
    }

    this.maxScrollBefore = event.maxScrollPosition;
    this.prevCount = this.items.length;
    this.showUpSpinner = true;
    this.userScrolled = true;
    this.detector.detectChanges();
    this.scrolled.emit('back');
  }

  cancelUpload(attachmentId: string): void {
    this.items = this.items.filter((message: Message) => message.attachmentId !== attachmentId);
    this.cancel.emit(attachmentId);
  }

  loadChild(message: any) {
    this.loadChildMessages.emit(message);
  }

  /** для ответа на комменты инстаграма */
  onAnswer(message: any) {
    this.answerMessages.emit(message);
  }

  /** для ответа на сообщения */
  onReplay(message: any) {
    this.replayMessages.emit(message);
  }

  onDirectWrite(message: any) {
    this.writeToDirect.emit(message);
  }

  onSeen(message: any) {
    this.seenMessage.emit(message.appealId);
  }

  onClickToPerson(personId: string) {
    this.clickToPerson.emit(personId);
  }

  onClickToChatName() {
    this.resetClickToPerson.emit();
  }

  onScrollVirtualScroller() {
    this.zone.runOutsideAngular(() => {
      const container = document.querySelector('virtual-scroller');
      const topBorderContainer = container.getBoundingClientRect().top;

      let topVisibleMessageElement;

      // ищем среди всех сообщений в DOM дереве первый, который отображается (хотя бы край его)
      document.querySelectorAll('.message-container')
        .forEach(element => {
          if (element.getBoundingClientRect().bottom > topBorderContainer && !topVisibleMessageElement) {
            topVisibleMessageElement = element;
          }
        });

      const topVisibleMessage = this.items.find(item => item.id === Number(topVisibleMessageElement?.dataset.messageid));

      if (topVisibleMessage) {
        const messageDate = DateTime.fromISO(topVisibleMessage.date.date.toString()).setLocale(this.i18n.language)
          .setZone(topVisibleMessage.date.timezone);

        this.floatingDate = messageDate
          .setLocale(this.i18n.language || 'en')
          .toFormat(environment.features.dateFormat[this.i18n.language] || 'en');
      } else {
        this.floatingDate = '';
      }
    })
  }
}
