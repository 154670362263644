import { Injectable } from '@angular/core';

enum MailRuEvent {
  MAIL_SEND = 'subscribe',
  PROFILE_CONFIRM = 'PROFILE_CONFIRM',
  PAID = 'PAID',
  CHANNEL_CONNECT = 'CHANNEL_CONNECT',
}

const SUCCESS_EVENTS_LIST = ['MAIL_SEND', 'PROFILE_CONFIRM', 'PAID', 'CHANNEL_CONNECT'];

@Injectable({
  providedIn: 'root'
})
export class MailRuAnalyticsService {

  constructor() {}

  track(trackName: MailRuEvent | string) {
    const _tmr = (<any>window)._tmr || ((<any>window)._tmr = []);
    if ( _tmr !== undefined && SUCCESS_EVENTS_LIST.indexOf(trackName) !== -1) {
      _tmr.push({ id: '3247253', type: 'reachGoal', goal: trackName });
    }
  }
}
