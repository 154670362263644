import { Injectable } from '@angular/core';
import { ANALYTICS_EVENT } from '@app/core/metrika.service';

enum AdWordsEvent {
  MAIL_SEND = 'nQxvCNj2rNABEPOAiNgC',
  MAIL_CONFIRM = 'bSXqCP-rxdABEPOAiNgC',
  PROJECT_CONFIRM = 'K3ugCNX61NABEPOAiNgC',
  PROFILE_CONFIRM = 'PSaWCKPRkNMBEPOAiNgC',
  CHANNEL_CONNECT = 'F8BCCIOpkdMBEPOAiNgC',
  PAID = 'snuSCNjv49ABEPOAiNgC'
}

const SUCCESS_EVENTS_LIST = [
  'MAIL_SEND',
  'MAIL_CONFIRM',
  'PROJECT_CONFIRM',
  'FIRST_PROJECT_CREATE',
  'SECOND_PROJECT_CREATE',
  'PROFILE_CONFIRM',
  'CHANNEL_CONNECT',
  'PAID',
];

@Injectable({
  providedIn: 'root'
})
export class GoogleTagService {

  constructor() {}

  track(trackName: ANALYTICS_EVENT | string, args: object = {}) {
    const gtag = (<any>window).gtag;
    if (gtag !== undefined && SUCCESS_EVENTS_LIST.indexOf(trackName) !== -1) {
      gtag('event', 'conversion', {'send_to': `AW-721551475/${AdWordsEvent[trackName]}`});
      gtag('event', trackName, args ?? undefined);
    }
  }
}
