import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { UserInfoFulledGuard } from '@app/core/authentication/user-info-fulled.guard';
import { SearchComponent } from '@app/core/shell/header/search/search.component';
import { ToastComponent } from '@app/core/toasts/toast/toast.component';
import { ToastsComponent } from '@app/core/toasts/toasts.component';
import { environment } from '@env/environment';
import { TranslateModule } from '@ngx-translate/core';

import { ElamaSupportComponent } from '@app/core/shell/elama-support/elama-support.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NG_SCROLLBAR_OPTIONS, NgScrollbarModule } from 'ngx-scrollbar';

import { ShellComponent } from './shell/shell.component';
import { RouteReusableStrategy } from './route-reusable-strategy';
import { AuthenticationService } from './authentication/authentication.service';
import { AuthenticationGuard } from './authentication/authentication.guard';
import { AuthorizedGuard } from './authentication/authorized.guard';
import { HttpService } from './http/http.service';
import { HttpCacheService } from './http/http-cache.service';
import { ApiPrefixInterceptor } from './http/api-prefix.interceptor';
import { ErrorHandlerInterceptor } from './http/error-handler.interceptor';
import { CacheInterceptor } from './http/cache.interceptor';
import { AuthInterceptor } from '@app/core/http/auth-handler.interceptor';
import { SidebarComponent } from '@app/core/shell/sidebar/sidebar.component';
import { HeaderComponent } from './shell/header/header.component';
import { NgIoConfig } from '@app/core/sockets/ng-io-config';
import { NgIoModule } from '@app/core/sockets/ng-io.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AvatarModule } from '@app/shared/avatar/avatar.module';
import { AlertsComponentModule } from '@app/shared/alerts/alerts.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from '@app/shared';
import { PopupNewsComponent } from './popup-news/popup-news.component';
import { OnBoardComponent } from './on-board/on-board.component';
import { PromoTimerComponent } from './shell/header/promo-timer/promo-timer.component';

const wsConfig: NgIoConfig = {
  url: environment.wsUrl,
  options: {}
};

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule,
    RouterModule,
    NgIoModule.forRoot(wsConfig),
    FormsModule,
    ReactiveFormsModule,
    AvatarModule,
    AlertsComponentModule,
    ColorPickerModule,
    NgSelectModule,
    SharedModule,
    NgScrollbarModule,
    ElamaSupportComponent,
  ],
    declarations: [
        SidebarComponent,
        ShellComponent,
        HeaderComponent,
        SearchComponent,
        PopupNewsComponent,
        OnBoardComponent,
        PromoTimerComponent,
        ToastsComponent,
        ToastComponent,
    ],
    exports: [
        OnBoardComponent,
    ],
    providers: [
        AuthenticationService,
        AuthenticationGuard,
        AuthorizedGuard,
        UserInfoFulledGuard,
        HttpCacheService,
        ApiPrefixInterceptor,
        ErrorHandlerInterceptor,
        AuthInterceptor,
        CacheInterceptor,
        ColorPickerModule,
        DeviceDetectorService,
        {
          provide: NG_SCROLLBAR_OPTIONS,
          useValue: { visibility: 'hover' },
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiPrefixInterceptor,
            multi: true
        },
        {
            provide: HttpClient,
            useClass: HttpService
        },
        {
            provide: RouteReuseStrategy,
            useClass: RouteReusableStrategy
        },
    ]
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }

}
