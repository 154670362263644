import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@app/core/api/api.service';
import { Response } from '@app/core/interfaces/api.response';
import { routes } from '@app/core/config/apiRoutes';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '@app/core/utils.service';
import { ChannelIdContext, PhoneContext } from '@app/core/api/api.channel';

interface QrForWhatsapp {
  expires_at: null;
  img: string;
  /** статус канала, "need action" например */
  status: string;
}

@Injectable({
  providedIn: 'root',
})
export class WhatsappApiService {
  private routes = routes;
  constructor(
    private api: ApiService,
    private http: HttpClient,
    private utils: UtilsService,
  ) {

  }

  public getQrForWhatsappAuth(id: ChannelIdContext): Observable<Response<QrForWhatsapp>> {
    return this.api.httpGet(this.routes.getQrForWhatsappAuth(id));
  }

  /** @deprecated */
  public createWhatsappOld(data: PhoneContext): any {
    return this.api.httpPost(this.routes.createWhatsappTeletypeClassic, this.utils.snakeKeys(data));
  }

  public createWhatsappPhoneless(data: PhoneContext): any {
    return this.api.httpPost(this.routes.createWhatsappPhonelessTeletype, this.utils.snakeKeys(data));
  }

  public updateWhatsapp(id: string, data: any): any {
    return this.api.httpPost(this.routes.updateWhatsappTeletype(id), this.utils.snakeKeys(data));
  }

  public removeWhatsapp(id: ChannelIdContext): Observable<Response> {
    return this.api.httpPost(this.routes.removeWhatsappTeletype(id), null);
  }

  public getAuthLink(id: string): Observable<Response> {
    return this.api.httpGet(this.routes.getAuthLinkWhatsapp(id));
  }

  public authExternal(token: string): Observable<Response> {
    return this.api.httpGet(this.routes.authWhatsappExternal(token));
  }
}
