import { Injectable, NgZone } from '@angular/core';
import { SocketsLogService } from '@app/core/sockets-log.service';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiService } from '@app/core/api/api.service';
import { Response } from '@app/core/interfaces/api.response';
import { routes } from '@app/core/config/apiRoutes';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '@app/core/utils.service';
import { Message } from '@app/core/interfaces/message';
import each from 'lodash-es/each';

export interface CreateWhatsAppNewThread {
  phoneNumber?: string;
  email?: string;
  username?: string;
  subject?: string;
}

export interface AppealHistoryContext {
  id: string;
  from?: string | number;
  to?: string | number;
  includeChilds?: boolean;
  includeParent?: boolean;
  singleAppeal?: boolean;
  all?: boolean;
  initiated: string;
}

export interface BatchAppealHistoryContext {
  appealsIds: Array<string>;
  from?: string | number;
  to?: string | number;
  childCount?: number;
  singleAppeal?: boolean;
}

export interface AppealsRemappingContext {
  clientId: string;
  clientAppointedId: string;
}

export interface AppealHistoryResponse extends Response {
  data?: {
    personId: string;
    messagesCount: number;
    items?: Array<Message>;
    personAvatar: string;
    personName: string;
    selectedAppealId: string;
    selectedAppealMessenger: string;
    selectedAppealName: string;
    isOpen: boolean;
    from?: string | null;
    to?: string | null;
  };
}

export interface AppealResponseDataItem {
  childs: any;
  appealId?: string;
  appeal_id?: string;
  from?: {
    id?: string;
    avatar?: string;
    avatarDefault: string;
    messenger?: string;
    name: string;
    isOnline: boolean;
    isOperator: boolean;
  };
  date: {
    date: string;
    timezone: string;
  };
  isOwn: boolean;
  attachmentid?: string;
  message?: string;
  seen?: boolean;
  personName?: string;
  personId?: string;
  personColor?: string;
  personAvatar?: string;
  personAnimal?: string;
  clientId?: string;
  channelId: string;
  type: string | number;
  countNewMessages?: number | string;
  provider?: number;
  externalId?: string;
  failedMessage: string;
  isUnanswered?: boolean;
  isGroupChat?: boolean;
  systemType: number | null;
}

export interface AppealsListResponseDataItem {
  id: string;
  appeal: AppealResponseDataItem;
}

export interface AppealResponse extends Response {
  data?: {
    id: string;
    appeal: AppealResponseDataItem;
  }
}

export interface AppealsListContext {
  status?: string;
  sort?: 'newest' | 'oldest';
  page?: number;
  channel?: string;
  itemsPerPage?: number;
  messageText?: string;
  personName?: string;
  noteText?: string;
  client?: string;
  personTags?: string[];
  appointed?: number;
  search?: string;
  category?: string;
}

export interface AppealsListResponse extends Response {
  data?: {
    counters: any
    currentPage: number;
    items: AppealsListResponseDataItem[];
    totalItems: number;
    totalPages: number;
    query?: AppealsListContext;
  };
}

@Injectable({
  providedIn: 'root',
})
export class ConversationApiService {
  private routes = routes;
  constructor(
    private api: ApiService,
    private zone: NgZone,
    private http: HttpClient,
    private utils: UtilsService,
    private socketLogService: SocketsLogService,
  ) { }

  public appealsList(call: AppealsListContext): Observable<AppealsListResponse> {
    return this.zone.runOutsideAngular(() => {
      const query = call || null;
      const _headers = this.api.getHeader();
      call.page++;

      const logAnswer = this.socketLogService.logHttpRequest('GET', 'appeals/list', _headers, call);

      return this.http.get(this.routes.appealsList(this.utils.serialize(this.utils.snakeKeys(call))),
        { headers: _headers })
        .pipe(
          map((body: any) => {
            logAnswer(body);
            body = this.utils.camelKeys(body);
            body.data.query = query || {};
            return body;
          }),
          catchError((e) => {
            logAnswer(e, true);
            return of('Error, could not load appeals list :-(');
          })
        );
    });
  }

  public getAppealById(appealId: string): Observable<AppealResponse> {
    return this.api.httpGet(this.routes.getAppealById(appealId));
  }

  public setSeen(appealId: string): Observable<any> {
    return this.api.httpGet(this.routes.seen(appealId));
  }

  public setStatus(appealId: string): Observable<any> {
    return this.api.httpGet(this.routes.status(appealId));
  }

  public getAppealHistory(call: AppealHistoryContext): Observable<AppealHistoryResponse> {
    return this.zone.runOutsideAngular(() => {
      const appealId = call.id || null;
      const _headers = this.api.getHeader();

      return this.http.get(this.routes.appealHistory(call), { headers: _headers })
        .pipe(
          map((body: any) => {
            body = this.utils.camelKeys(body);
            body.data.selectedAppealId = body.data.selectedAppealId || appealId;
            return body;
          }),
          catchError((e) => {
            return of('Error, could not load appeal history :-(');
          })
        );
    });
  }

  public getBatchAppealsMessages(data: BatchAppealHistoryContext): Observable<any> {
    const _headers = this.api.getHeader();
    return this.http.post(this.routes.batchAppealHistory, this.utils.serialize(this.utils.snakeKeys(data)),
      { headers: _headers })
      .pipe(
        map((body: any) => {
          each(Object.keys(body.data.appeals), key => {
            body.data.appeals[key] = this.utils.camelKeys(body.data.appeals[key]);
          });
          each(Object.keys(body.data.persons), key => {
            body.data.persons[key] = this.utils.camelKeys(body.data.persons[key]);
          });
          return body;
        }),
        catchError((err) => {
          return of('Error, could not complete ' + this.routes.batchAppealHistory + ' ' + err);
        })
      );
  }

  public getLatestConversation(personId: string) {
    return this.api.httpGet(this.routes.getLatestConversation(personId));
  }

  public getPerson(personId: string, isCache: boolean = false) {
    return this.zone.runOutsideAngular(() => {
      const _headers = this.api.getHeader();

      return this.http.get(this.routes.getPerson(personId), { headers: _headers })
        .pipe(
          map((body: any) => {
            body = this.utils.camelKeys(body);
            body.data.isCache = isCache;
            return body;
          }),
          catchError((error) => {
            return of('Error, could not load person :-(');
          })
        );
    });
  }

  public appealDelete(appealId: string): Observable<any> {
    return this.api.httpGet(this.routes.appealDelete(appealId));
  }

  public resendMessage(messageExternalId: string): Observable<any> {
    return this.api.httpGet(this.routes.resendMessage(messageExternalId));
  }

  public cancelMessage(messageExternalId: string): Observable<any> {
    return this.api.httpGet(this.routes.cancelMessage(messageExternalId));
  }

  public createNewThread(id: string, type: string, data: CreateWhatsAppNewThread): Observable<Response> {
    return this.api.httpPost(this.routes.createNewAppeal(id, type), this.utils.snakeKeys(data));
  }

  public closeAllThread(postId: string): Observable<any> {
    return this.api.httpGet(this.routes.seenPost(postId));
  }

  public setRead(appealId: string): Observable<any> {
    return this.api.httpGet(this.routes.setRead(appealId));
  }

  public closeThread(appealId: string): Observable<any> {
    return this.api.httpGet(this.routes.closeThread(appealId));
  }

  public appealsRemapping(call: AppealsRemappingContext): Observable<any> {
    return this.api.httpPost(this.routes.appealsRemapping, this.utils.snakeKeys(call));
  }
}
