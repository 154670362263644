<svg
  #svg
  [style.opacity]="display ? 1 : 0"
  [attr.viewBox]="_viewBox"
  focusable="false">
  <rect
    #rect
    *ngIf="display"
    [attr.fill]="fill"
    [attr.stroke]="stroke"
    [attr.stroke-width]="_strokeWidth"
    [attr.stroke-dasharray]="_strokeDashArray"
    [attr.width]="_width"
    [attr.height]="_height"
    [attr.fill-opacity]="'0'"
    x="0"
    y="0"/>
</svg>
