<div class="login-form-container">
  <div class="form-container">
    <form class="login-form" (submit)="login()" autocomplete="off" [formGroup]="loginForm" #loginFormForm="ngForm"
          novalidate>

      <div class="logo pointer" (click)="goToLanding()"></div>

      <h1>{{ 'login.signIn' | translate }}</h1>

      <app-alerts></app-alerts>

      <div class="form-group" [ngClass]="{'filled': loginForm.value.login, 'error': errorsList.login}">
        <input appAutofocus
               [skip]="loginForm.value.login"
               type="email"
               class="form-control"
               formControlName="login"
               autocomplete="off"
               [placeholder]="'email.self' | translate"
               (blur)="handleFieldBlur('loginForm', 'login')"
               required />
        <span class="floating-label">{{ 'email.self' | translate }}</span>
        <span *ngIf="loginForm.get('login').hasError('required')" class="form-group-error">
          {{ 'email.error.blank' | translate }}
        </span>
        <span *ngIf="loginForm.get('login').hasError('pattern')" class="form-group-error">
          {{ 'email.error.valid' | translate:{email: loginForm.get('login').value} }}
        </span>
      </div>

      <div class="form-group" [ngClass]="{'filled': loginForm.value.password, 'error': errorsList.password}">
        <input type="password" class="form-control" formControlName="password" autocomplete="off"
               [placeholder]="'password.self' | translate"
               (blur)="handleFieldBlur('loginForm', 'password')" required />

        <span class="floating-label">{{ 'password.self' | translate }}</span>
        <span *ngIf="loginForm.get('password').hasError('required')" class="form-group-error" >
          {{ 'password.error.current' | translate }}
        </span>
      </div>

      <div class="remember-me-forgot-password">
        <label class="checkbox-container" [ngClass]="{'checked': loginForm.value.remember}">
          <input type="checkbox" formControlName="remember" />
          <span>{{ 'login.remember' | translate }}</span>
        </label>
        <a class="like-a" routerLink="/reset-password">{{ 'login.forgot' | translate }}?</a>
      </div>

      <div class="buttons-container">
        <button class="btn btn-primary with-spinner" type="submit" [disabled]="isLoading">
          <app-spinner [inProgress]="isLoading" [btnText]="'login.signInBtn' | translate"></app-spinner>
        </button>

        <app-elama-login-button class="elama-login"></app-elama-login-button>
      </div>

      <div class="register-wrapper">
        {{ 'login.noAccount' | translate }}
        <a class="like-a" (click)="goToRegister()">
          {{ 'login.register' | translate }}</a>{{ 'login.itsFree' | translate }}
      </div>
    </form>
  </div>
  <div class="bottom-bg"></div>
</div>
