import { NgIf } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-elama-support',
  templateUrl: './elama-support.component.html',
  styleUrls: ['./elama-support.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    TranslateModule
  ],
})
export class ElamaSupportComponent {
  @Output() public close: EventEmitter<boolean> = new EventEmitter();

  closeModal() {
    this.close.emit();
  }
}
