import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/api/api.service';
import { routes } from '@app/core/config/apiRoutes';
import { CreatableTag, Tag } from '@app/core/tags.service';
import { UtilsService } from '@app/core/utils.service';
import { Response } from '@app/core/interfaces/api.response';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TagsApiService {

  private routes = routes;

  constructor(
    private api: ApiService,
    private utils: UtilsService,
  ) {}

  getListTags(): Observable<Response<Tag[]>> {
    return this.api.httpGet(this.routes.listTags);
  }

  createTag(newTag: CreatableTag): Observable<Response<Tag>> {
    return this.api.httpPost(this.routes.createTag, this.utils.snakeKeys(newTag));
  }

  updateTag(id: string, tag: CreatableTag): Observable<Response<Tag>> {
    return this.api.httpPost(this.routes.updateTag(id), this.utils.snakeKeys(tag));
  }

  deleteTag(id: string): Observable<Response<boolean>> {
    return this.api.httpGet(this.routes.deleteTag(id));
  }
}
