import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ApiService } from '@app/core/api/api.service';
import { Response } from '@app/core/interfaces/api.response';
import { routes } from '@app/core/config/apiRoutes';
import { UtilsService } from '@app/core/utils.service';
import { map, take, tap } from 'rxjs/operators';

export interface Company {
  id: string,
  projectId: number,
  name: string,
  phone: string,
  inn: string,
  kpp: string,
  legalAddress: string,
  postalAddress: string,
}

export type CompanyStore = Map<Company['id'], Company>

@Injectable({
  providedIn: 'root',
})
export class ApiPaymentService {

  private companiesList = new BehaviorSubject<CompanyStore | null>(null);

  private routes = routes;

  constructor(
    private api: ApiService,
    private utils: UtilsService,
  ) {

  }

  public updateProjectCompanyList() {
    this.loadProjectCompanies().pipe(take(1)).subscribe((res) => {
      if (res.success) {
        const companyStore: CompanyStore = new Map();

        res.data.forEach((company) => companyStore.set(company.id, company));

        this.companiesList.next(companyStore);
      }
    });
  }

  public getProjectCompaniesList(): Observable<Company[] | null> {
    if (this.companiesList.value === null) {
      this.updateProjectCompanyList();
    }

    return this.companiesList.asObservable().pipe(
      map((companies: CompanyStore) => companies && Array.from(companies, ([id, value]) => value)),
    );
  }

  private setCompanyInStore(company: Company) {
    const companyMap = this.companiesList.value;
    companyMap.set(company.id, company);
    this.companiesList.next(companyMap);
  }

  private deleteCompanyInStore(id: Company['id']) {
    const companyMap = this.companiesList.value;
    companyMap.delete(id);
    this.companiesList.next(companyMap);
  }

  // далее просто роуты

  public createWidgetPayment(data: any): Observable<Response> {
    return this.api.httpPost(this.routes.createWidgetPayment, this.utils.snakeKeys(data));
  }

  public getActiveRecurrentPayments(): Observable<Response> {
    return this.api.httpGet(this.routes.getActiveRecurrentPayments);
  }

  public cancelPaymentSubscription(paymentId: string): Observable<Response> {
    return this.api.httpGet(this.routes.cancelPaymentSubscription(paymentId));
  }

  private loadProjectCompanies(): Observable<Response<Company[]>> {
    return this.api.httpGet(this.routes.companies.list);
  }

  public createProjectCompany(data: any): Observable<Response<Company>> {
    return this.api.httpPost(this.routes.companies.create, this.utils.snakeKeys(data))
      .pipe(
        take(1),
        tap((res) => {
          if (res.success) {
            this.setCompanyInStore(res.data);
          }
        }),
      );
  }

  public updateProjectCompany(data: any): Observable<Response<Company>> {
    return this.api.httpPost(this.routes.companies.update(data.id), this.utils.snakeKeys(data))
      .pipe(
        take(1),
        tap((res) => {
          if (res.success) {
            this.setCompanyInStore(res.data);
          }
        }),
      );
  }

  public deleteProjectCompany(companyId: any): Observable<Response> {
    return this.api.httpGet(this.routes.companies.delete(companyId))
      .pipe(
        take(1),
        tap((res) => {
          if (res.success) {
            this.deleteCompanyInStore(companyId);
          }
        }),
      );
  }

  public invoiceProjectCompany(companyId: any, amount: number, needSend: boolean = false): Observable<Response> {
    return this.api.httpPost(this.routes.companies.invoice(companyId), this.utils.snakeKeys({
      amount,
      needSend,
    }));
  }
}
