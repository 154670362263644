<div class="header" [class.slide-up]="isSettingsPage" [class.full]="!hasProject">
  <div class="logo" [hidden]="hasProject"></div>

  <app-search
    [hasProject]="hasProject"
    [channels]="channels"
    [operators]="operators"
    [tags]="tags"
  ></app-search>

  <div class="user-block" *ngIf="user">
    <app-avatar [avatar]="user.avatar || user.avatarDefault"></app-avatar>
    <div dropdown class="user-menu dropdown">
      <button class="btn btn-transparent no-hover dropdown-toggle" id="userDropdown" dropdownToggle>
        {{ user.fullName | maxLength: 50 : true }}
      </button>
      <div class="dropdown-to-center">
        <div *dropdownMenu class="dropdown-menu" aria-labelledby="userDropdown">
          <div class="mail">{{ user.login }}</div>
          <a routerLink="/profile" class="dropdown-item first">
            <i class="edit-user-icon"></i>{{ 'header.editProfile' | translate }}</a>
          <a routerLink="/projects" class="dropdown-item" [hidden]="!hasProject || !isOwner">
            <i class="layers-icon"></i>{{ 'header.myProjects' | translate }}</a>
          <a routerLink="/home" class="dropdown-item" [hidden]="hasProject || !hasPermission">
            <i class="layers-icon"></i>{{ 'header.createProject' | translate }}</a>
          <!--<button type="button" class="dropdown-item">-->
          <!--<i class="wallet-icon"></i>{{ 'Billing' | translate }}</button>-->
          <div class="last"></div>
          <button type="button" class="dropdown-item" (click)="logOut()">
            <i class="cancel-icon"></i>{{ 'header.logout' | translate }}
          </button>
        </div>
      </div>
    </div>

    <ng-container *ngIf="promoEndDate.date && promoIsActive">
      <div class="balance-wrapper" (click)="showPromoModal()">
        <app-promo-timer [promoEndDate]="promoEndDate"></app-promo-timer>
      </div>
    </ng-container>

    <div class="balance-wrapper"
         *ngIf="hasPermission && currentProject && promoDays && promoDays.promoDaysRemain > 0"
         (click)="goToPayment()">
      <div class="balance">{{ 'forcePay.promo' | translate }}</div>
      <div class="sum" [class.red]="promoDays.promoDaysRemain < 4">&nbsp;
        {{ promoDays.promoDaysRemain }} {{ getDayText(promoDays.promoDaysRemain) }}
      </div>
    </div>

    <div class="balance-wrapper"
         *ngIf="hasPermission && currentProject &&
         ((promoDays?.promoDaysRemain !== 0 && balance !== 0) || balance > 0 ||
         promoDays?.promoDaysRemain === 0)"
         (click)="goToPayment()">
      <div class="balance">{{ 'forcePay.balance' | translate }}</div>
      <div class="sum" [class.red]="availableDays < 4">&nbsp;{{ balance || 0 }} ₽&nbsp;</div>
      <div class="available-days" *ngIf="availableDays > 0 && dayText">
        {{availableDays === !9999 ? '(' + availableDays + ' ' + dayText + ')' : ''}}
      </div>
    </div>

    <div class="switch-container" [ngClass]="{disabled : inProgress}" [hidden]="!hasProject">
      <span class="state-label">{{ userState && 'header.online' || 'header.offline' | translate }}</span>
      <label class="switch">
        <input type="checkbox" [checked]="userState" (click)="onStateChange()" [disabled]="inProgress">
        <span class="slider round"></span>
      </label>
    </div>

    <!--<ng-template #tooltipTextTemplate>-->
    <!--  <span *ngIf="!notificationLoaded && !hasNotificationError">Loading...</span>-->
    <!--  <span *ngIf="notificationLoaded && !hasNotificationError">-->
    <!--    {{ !hasNotification && 'header.enableNotification' || 'header.disableNotification' | translate }}-->
    <!--  </span>-->
    <!--  <span *ngIf="hasNotificationError">-->
    <!--    {{ 'header.disabledNotification' | translate }}-->
    <!--  </span>-->
    <!--</ng-template>-->
    <!--<div *ngIf="hasProject"-->
    <!--     class="ring"-->
    <!--     [class.off]="!hasNotification && notificationLoaded"-->
    <!--     [class.loading]="!notificationLoaded"-->
    <!--     [class.error]="hasNotificationError"-->
    <!--     (click)="notificationSwitch()"-->
    <!--     [tooltip]="tooltipTextTemplate"-->
    <!--     containerClass="tooltip-height"-->
    <!--     placement="left">-->
    <!--</div>-->

    <ng-template #tooltipPingTextTemplate>
      <span>
        {{ 'header.ping' | translate }}
      </span>
    </ng-template>
    <div class="ping"
         [tooltip]="tooltipPingTextTemplate"
         containerClass="tooltip-height"
         placement="left">
      <span class="graph" [class.active-red]="ping > 5000"
            [class.active-yellow]="ping > 2000 && ping < 5000"
            [class.active]="ping < 2000">
      </span>
      <span class="graph" [class.active]="ping < 2000" [class.active-yellow]="ping > 2000 && ping < 5000"></span>
      <span class="graph" [class.active]="ping < 2000"></span>
      <span class="graph" [class.active]="ping < 1200"></span>
    </div>
  </div>
</div>
