import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashedRectangleComponent } from '@app/shared/dashed-rectangle/dashed-rectangle.component';

@NgModule({
  imports: [CommonModule],
  declarations: [DashedRectangleComponent],
  exports: [DashedRectangleComponent]
})
export class DashedRectangleModule { }
