<div class="form-group">
  <div class="dropdown-container">
    <ng-template #rt let-r="result">
      <img [src]="r['avatar'] || r['avatarDefault']" alt="" class="operator-icon">

      {{ r.name }} {{ r.lastName }}
    </ng-template>

    <input
      id="all-operators-typeahead-template"
      type="text"
      class="form-control"
      [placeholder]="'settings.groups.addOperatorToGroupPlaceholder' | translate"
      (selectItem)="selectOperator($event)"
      (focus)="focus.next($any($event).target.value)"
      [disabled]="clientIds.disabled || clientIds.length === maxLength"
      [(ngModel)]="modelForAllOperator"
      [ngbTypeahead]="search"
      [resultTemplate]="rt"
      [inputFormatter]="formatter"
    />
  </div>
</div>

<div class="checked-channels" *ngIf="clientIdsForm.length">
  <div
    *ngFor="let clientId of clientIdsForm; let i = index;"
    class="checked-channel"
  >
    <div class="checked-channel__info">
      <img [src]="getOperatorsById(clientId.value).avatar || getOperatorsById(clientId.value).avatarDefault" alt="" class="operator-icon big">

      <p class="checked-channel__name">
        <span class="operator-name">{{ getOperatorsById(clientId.value).name }} {{ getOperatorsById(clientId.value).lastName }}</span> <br>
        <span class="operator-email">{{ getOperatorsById(clientId.value).email }}</span>
      </p>

      <p class="checked-channel__addition-data" *ngIf="isShowMark(getOperatorsById(clientId.value).id)">
        {{ markElementsAs.mark | translate }}
      </p>

      <button class="btn btn-grid checked-channel__delete" (click)="deleteOperator(i)" [disabled]="clientIds.disabled">
        {{ 'button.delete' | translate }}
      </button>
    </div>
  </div>
</div>
