import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { LoginComponent } from './login.component';
import { ResetPasswordComponent } from '@app/login/reset-password/reset-password.component';
import { SetPasswordComponent } from '@app/login/set-password/set-password.component';
import { LanguageGuard } from '@app/core/language.guard';
import { AuthorizedGuard } from '@app/core/authentication/authorized.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: extract('Login'),
      bodyId: 'login',
      bodyClass: 'login',
    },
    canActivate: [AuthorizedGuard, LanguageGuard]
  },
  {
    path: 'login/:oathType',
    component: LoginComponent,
    data: {
      title: extract('Login'),
      bodyId: 'login',
      bodyClass: 'login',
    },
    canActivate: [AuthorizedGuard, LanguageGuard]
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: {
      title: extract('Reset password'),
      bodyId: 'reset_password',
      bodyClass: 'login',
    },
    canActivate: [LanguageGuard]
  },
  {
    path: 'client/set-password/:externalId',
    component: SetPasswordComponent,
    data: {
      title: extract('Set new password'),
      bodyId: 'complete_registration',
      bodyClass: 'login',
    },
    canActivate: [LanguageGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class LoginRoutingModule { }
