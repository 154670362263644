import { Injectable } from '@angular/core';
import { da } from 'date-fns/locale';
import { Observable } from 'rxjs';
import { ApiService } from '@app/core/api/api.service';
import { Response } from '@app/core/interfaces/api.response';
import { routes } from '@app/core/config/apiRoutes';
import { UtilsService } from '@app/core/utils.service';

export interface AppealSetOperatorContext {
  id: string;
  clientAppointedId: string;
}

export interface PersonSetResponsibleContext {
  personId: string;
  clientAppointedId: string;
}

export interface AppealTagContext {
  personId: string;
  tagId: string;
}

export interface AppealSetCategoryContext {
  // id: string;
  categoryAppointedId: string;
}

export interface NotesContext {
  id?: string;
  text?: string;
  appealId: string;
}

export interface PersonUpdateData {
  phone?: string;
  name?: string;
}

export interface GroupChatUpdateData {
  caption: string
}

export interface PersonBannedState {
  personId: string;
}

@Injectable({
  providedIn: 'root',
})
export class PersonApiService {
  private routes = routes;
  constructor(
    private api: ApiService,
    private utils: UtilsService,
  ) {

  }

  public setPersonNote(personId: string, data: NotesContext): Observable<Response> {
    return this.api.httpPost(this.routes.setPersonNote(personId), this.utils.snakeKeys(data));
  }

  public updatePerson(personId: string, data: PersonUpdateData): Observable<Response> {
    return this.api.httpPost(this.routes.updatePerson(personId), data);
  }

  public updateWhatsappGroupChatName(appealId: string, data: GroupChatUpdateData): Observable<Response> {
    return this.api.httpPost(this.routes.updateWhatsappGroupChatName(appealId), data);
  }

  public updateTelegramGroupChatName(appealId: string, data: GroupChatUpdateData): Observable<Response> {
    return this.api.httpPost(this.routes.updateTelegramGroupChatName(appealId), data);
  }

  public updatePersonBannedState(data: PersonBannedState, isBanned: boolean): Observable<Response> {
    const url = isBanned ? this.routes.banPerson(data) : this.routes.unbanPerson(data);
    return this.api.httpPost(url, this.utils.snakeKeys(data));
  }

  public getPersonLog(personId: string): Observable<Response> {
    return this.api.httpGet(this.routes.getPersonLog(personId));
  }

  public uploadConversation(appealId: string): Observable<Response> {
    return this.api.httpGet(this.routes.pushAmoCRM(appealId));
  }

  public appealSetClient(call: AppealSetOperatorContext): Observable<Response> {
    return this.api.httpPost(this.routes.appealSetClient(call), this.utils.snakeKeys(call));
  }

  public appealSetResponsibleOperator(call: PersonSetResponsibleContext): Observable<Response> {
    return this.api.httpPost(this.routes.personSetResponsibleOperator(call), this.utils.snakeKeys(call));
  }

  public personSetTag(context: AppealTagContext): Observable<Response<boolean>> {
    return this.api.httpPost(this.routes.personSetTag(context.personId), this.utils.snakeKeys({ tagId: context.tagId }));
  }

  public personRemoveTag(context: AppealTagContext): Observable<Response<boolean>> {
    return this.api.httpPost(this.routes.personRemoveTag(context.personId), this.utils.snakeKeys({ tagId: context.tagId }));
  }

  public appealSetCategory(id: string, call: AppealSetCategoryContext): Observable<Response> {
    return this.api.httpPost(this.routes.appealSetCategory(id), this.utils.snakeKeys(call));
  }

  public appealSetRandom(): Observable<Response> {
    return this.api.httpGet(this.routes.appealSetRandom);
  }

  public getBoundedPerson(personId: string): Observable<Response> {
    return this.api.httpGet(this.routes.getPersonBoundedAppeals(personId));
  }

  public deleteNote(noteId: string): Observable<Response> {
    return this.api.httpGet(this.routes.deleteNote(noteId));
  }

  public getPersonCustomFieldsValue(personId: string): Observable<Response<Record<string, string>>> {
    return this.api.httpGet(this.routes.getPersonCustomFieldsValue(personId), false, false);
  }

  public setPersonCustomFieldsValue(personId: string, data) {
    for (let key in data) {
      if (data[key] === null) {
        delete data[key];
      }
    }

    return this.api.httpPostNew(this.routes.setPersonCustomFieldsValue(personId), {
      values: data
    });
  }
}
