import { NgModule } from '@angular/core';
import { ComparableDirective } from '@app/core/directives/comparable';

@NgModule({
  imports: [],
  declarations: [ComparableDirective],
  exports: [ComparableDirective]
})
export class ComparableDirectiveModule {
}
