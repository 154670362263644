<div class="avito-post">
  <img
    *ngIf="post.attachments?.length > 0"
    [src]="post.attachments[0]?.attachmentUrl"
    [alt]="post.caption"
    class="avito-post__cover"
  >

  <div
    class="avito-post__info"
    [class._without-cover]="post?.attachments?.length === 0"
  >
    <p class="avito-post__caption" [title]="post.caption">
      {{ post.caption }}
    </p>

    <!--пока не выводим, возможно в будущем-->
    <!--<p class="avito-post__price">-->
    <!--  {{ post.price }}-->
    <!--</p>-->

    <a
      [href]="postUrl"
      target="_blank"
      class="avito-post__link"
    >
      № {{ postId }}
    </a>
  </div>
</div>

