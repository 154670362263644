<div class="teletype-progressbar-inner {{ class }}">
  <button *ngIf="cancelUpload" type="button" (click)="cancelUploadFn()">
    <i class="teletype-icon-close"></i>
  </button>
  <span class="progress-text" *ngIf="displayText !== ''">{{ displayText }}</span>
  <svg
    [style.width.px]="size"
    [style.height.px]="size"
    [attr.viewBox]="_viewBox"
    preserveAspectRatio="xMidYMid meet"
    focusable="false">

    <circle
      cx="50%"
      cy="50%"
      [attr.r]="_circleRadius"
      [style.stroke]="strokeColor"
      [style.stroke-dashoffset.px]="_strokeDashOffset"
      [style.stroke-dasharray.px]="_strokeCircumference"
      [style.stroke-width.%]="_circleStrokeWidth"></circle>
  </svg>
</div>