import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AlertsService } from '@app/core/alerts/alerts.service';
import { ProjectService } from '@app/core/project.service';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/api/api.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

const CONVERSATIONS_URL = 'conversations/open/open';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  preserveWhitespaces: false
})

export class HomeComponent implements OnInit, OnDestroy {

  public project: any;
  public inProgress: boolean;
  private unsub$ = new Subject<void>();

  constructor(
    private alertsService: AlertsService,
    private projectService: ProjectService,
    private router: Router,
    private api: ApiService,
    private detector: ChangeDetectorRef,
  ) {
    this.inProgress = true;
  }

  ngOnInit() {
    this.projectService.getObservableCurrentProject()
      .pipe(takeUntil(this.unsub$))
      .subscribe(project => {
        if (project !== undefined) {
          this.inProgress = false;
          this.project = project;
          if (this.project) {
            this.unsub$.next();
            this.unsub$.complete();
            this.router.navigateByUrl(CONVERSATIONS_URL);
            if (!this.detector['destroyed']) {
              this.detector.detectChanges();
            }
          }
        }
    });
  }

  ngOnDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }
}
