import { Injectable } from '@angular/core';

enum VKEvent {
  MAIL_SEND = 'lead',
  PROFILE_CONFIRM = 'complete_registration',
  PAID = 'purchase',
  CHANNEL_CONNECT = 'start_trial',
}

const SUCCESS_EVENTS_LIST = ['MAIL_SEND', 'PROFILE_CONFIRM', 'PAID', 'CHANNEL_CONNECT'];

@Injectable({
  providedIn: 'root'
})
export class VkAnalyticsService {

  constructor() {}

  track(trackName: VKEvent | string) {
    const vk = (<any>window).VK;
    if ( vk !== undefined && SUCCESS_EVENTS_LIST.indexOf(trackName) !== -1) {
      vk.Retargeting.Event(trackName);
      vk.Goal(trackName);
    }
  }
}
