<div *ngIf="!error" id="webcam" [style.width.px]="size" [style.height.px]="size">
  <div class="video-container" [style.width.px]="size" [style.height.px]="size">
    <video #video id="video" [style.margin-top.px]="topOffset" [style.margin-left.px]="sideOffset" [width]="vW"
           [height]="vH" autoplay></video>
  </div>
  <div class="canvas-container" [style.width.px]="size" [style.height.px]="size">
    <canvas [style.width.px]="vW" [style.height.px]="vH" #canvas id="canvas" [width]="vW" [height]="vH"></canvas>
  </div>
  <app-progress *ngIf="captureTimeoutProgress > 0 && captureTimeoutProgress < 100" [strokeColor]="'#fff'" [size]="44"
                [class]="'progress-for-image'" [displayText]="countDownDisplayText"
                [value]="captureTimeoutProgress"></app-progress>
</div>
<app-alert *ngIf="error" [alertContent]="error.name + ' : ' + error.message"></app-alert>
