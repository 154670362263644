import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import ResizeObserver from 'resize-observer-polyfill';
import { environment } from '@env/environment';
import each from 'lodash-es/each';
import { Meta } from '@angular/platform-browser';
import { TariffService } from '@app/core/tariff.service';

@Component({
  selector: 'app-shared-tariffs',
  templateUrl: './shared-tariffs.component.html',
  styleUrls: ['./shared-tariffs.component.scss'],
  preserveWhitespaces: false
})
export class SharedTariffsComponent implements OnInit, OnDestroy {

  @ViewChild('tariffContainer', { read: ElementRef, static: true }) tariffContainer: ElementRef;

  private ro: ResizeObserver;
  private timeout = null;

  constructor(
    private meta: Meta,
    private tariffService: TariffService
  ) {
    (window as any).ignoreChannelService = true;
    this.tariffService.isPublic = true;

    this.meta.updateTag({
      content: 'width=device-width, initial-scale=1',
      name: 'viewport'
    });

    this.ro = new ResizeObserver((entries) => {
      each(entries, (entry) => {
        if (entry.target === this.tariffContainer.nativeElement) {
          window.top.postMessage({
            width: entry.contentRect.height
          }, environment.landingUrl);
        }
      });
    });
  }

  ngOnInit() {
    this.ro.observe(this.tariffContainer.nativeElement);
  }

  ngOnDestroy() {

  }

  inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }
}
