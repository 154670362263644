import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PHONE_REGEX } from '@app/core/config/regExValidators';
import each from 'lodash-es/each';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ApiPaymentService } from '@app/core/api/api.payment';
import { AlertsService } from '@app/core/alerts/alerts.service';
import { I18nService } from '@app/core';
import { take } from 'rxjs/operators';
import { DadataConfig } from '../dadata/dadata-config';
import { DadataType } from '../dadata/dadata.service';

@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCompanyComponent implements OnInit {

  public companyForm: UntypedFormGroup;
  public company: any;
  public title = 'settings.payment.addCompany';
  public errorsList: any = {};
  public isLoading = false;

  public companyExists = false;

  public dadataConfig: DadataConfig;

  public error: string;

  public dadataFieldsModifications = {
    'inn': 'data.inn',
    'name': 'value',
    'legalAddress': 'data.address.value',
    'kpp': 'data.kpp'
  };

  private language: any;

  constructor(
    private api: ApiPaymentService,
    private formBuilder: UntypedFormBuilder,
    private bsModalRef: BsModalRef,
    private detector: ChangeDetectorRef,
    private alertsService: AlertsService,
    private i18nService: I18nService
  ) {
    this.dadataConfig = {
      apiKey: '3fc4c79bcad2adc81ac79b009e89eac3dfa24584',
      type: DadataType.party,
      delay: 300,
      limit: 5,
      width: 'auto',
      minWidth: '0',
      partyAddress: 'full'
    };
  }

  ngOnInit(): void {
    this.companyExists = this.company && this.company.id;
    this.language = this.i18nService.translateService.translations[this.i18nService.language];
    this.createForm();
  }

  hideModalWithCancel(): void {
    this.bsModalRef.hide();
  }

  createForm(): void {
    this.companyForm = this.formBuilder.group({
      id: [''],
      projectId: [''],
      name: ['', Validators.required],
      phone: ['', Validators.pattern(PHONE_REGEX)],
      inn: ['', Validators.required],
      kpp: [''],
      legalAddress: ['', Validators.required],
      postalAddress: [''],
    });

    this.updateFormFields();
  }

  validateOnSubmit() {
    each(this.companyForm.controls, (control, name) => {
      this.errorsList[name] = control.errors !== null;
    });
  }

  editCompany(): void {
    this.validateOnSubmit();
    if (this.companyForm.valid === false) {
      return;
    }
    this.isLoading = true;
    this.detector.detectChanges();

    const formValue = this.companyForm.value;

    const method = this.company
      ? this.api.updateProjectCompany(formValue)
      : this.api.createProjectCompany(formValue);

    method.subscribe((data: any) => {
      this.isLoading = false;

      if (!data.success && data.errors) {

        this.error = data.errors[0].message;

        this.detector.detectChanges();
        return;
      }

      if (data.success) {
        this.company = data.data;
        this.title = 'settings.payment.editCompany';
      }

      this.companyExists = !!this.company;

      this.updateFormFields();

      this.bsModalRef.hide();

      this.detector.detectChanges();
    });
  }

  updateFormFields(): void {
    if (this.company) {
      each(this.company, (item, key) => {
        if (this.companyForm.controls[key] !== undefined) {
          this.companyForm.controls[key].setValue(item);
        }
      });
    }
  }

  handleFieldBlur(field: string): void {
    const fieldState = this.companyForm.get(field);
    this.errorsList[field] = !fieldState.valid && fieldState.value.trim() !== '';
  }

  remove(): void {
    this.alertsService.confirmDelete({
      title: this.language.settings.payment.deleteCompanyTitle,
      content: this.language.settings.payment.deleteCompanyMessage,
      okButtonText: this.language.button.delete,
      cancelButtonText: this.language.button.cancel,
      okCallback: () => {
        this.isLoading = true;
        this.detector.detectChanges();
        this.api.deleteProjectCompany(this.company.id)
          .pipe(take(1)).subscribe(() => {
          this.bsModalRef.hide();
          this.isLoading = false;
        });
      },
      cancelCallback: () => {
        this.bsModalRef.hide();
      }
    });
  }

}
