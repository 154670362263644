import { AbstractControl } from '@angular/forms';

export default class EqualValidator {
  static validate(original: string, repeat: string) {
    return (AC: AbstractControl) => {
      const value = AC.get(original).value;
      const valueRepeat = AC.get(repeat).value;

      if (value !== valueRepeat) {
        AC.get(repeat).setErrors({ notEqual: true });
        return AC.get(repeat).getError('notEqual');
      } else {
        AC.get(repeat).setErrors(null);
        return null;
      }
    };
  }
}
