import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressComponent {
  @Input() value = 20;
  @Input() diameter = 34;
  @Input() strokeWidth = 1;
  @Input() size = 34;
  @Input() strokeColor = '#000';
  @Input() class = '';
  @Input() displayText = '';

  @Input() cancelUpload = false;
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  /** The view box of the spinner's svg element. */
  get _viewBox() {
    const viewBox = this._circleRadius * 2 + this.strokeWidth;
    return `0 0 ${viewBox} ${viewBox}`;
  }

  /** The stroke circumference of the svg circle. */
  get _strokeCircumference(): number {
    return 2 * Math.PI * this._circleRadius;
  }

  /** The radius of the spinner, adjusted for stroke width. */
  get _circleRadius() {
    return (this.diameter - this.strokeWidth) / 2;
  }

  /** The dash offset of the svg circle. */
  get _strokeDashOffset() {
    return this._strokeCircumference * (100 - this.value) / 100;
  }

  /** Stroke width of the circle in percent. */
  get _circleStrokeWidth() {
    return this.strokeWidth / this.size * 100;
  }

  cancelUploadFn() {
    this.cancel.emit();
  }
}
