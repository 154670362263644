<div class="page-title" *ngIf="!public">{{ 'settings.tariffSetupTitle' | translate }}</div>
<div class="page-title-note" *ngIf="!public">{{ 'settings.tariffSetupTitleNote' | translate }}</div>


<ng-template #selectedTariffTpl>
  <div *ngIf="selectedTariffs?.length === 0" class="balance-wrapper">
    <div class="title">{{ 'forcePay.yourTariff' | translate }}</div>
    <div class="finger-left"></div>
    <div class="empty-tariff" [innerHTML]="'forcePay.empty' | translate"></div>
  </div>
  <div class="balance-wrapper" [class.mobile]="isMobile" *ngIf="selectedTariffs?.length > 0">
    <div class="title tariff">{{ 'forcePay.yourTariff' | translate }}</div>

    <div class="total-tariff" *ngFor="let tariff of selectedTariffs">
      <div class="name-wrapper" [class.binary]="tariff?.maxCount !== 1">
        <div class="name" [innerHTML]="tariff.name"></div>
        <div class="counter" *ngIf="tariff?.maxCount !== 1 && getSum(tariff) !== 0">
          <button class="btn icon-circle-minus" (click)="onChangeTotalQuantity(tariff, false)"></button>
          <div class="quantity">{{ tariff.count }}</div>
          <button class="btn icon-circle-plus" (click)="onChangeTotalQuantity(tariff, true)"></button>
        </div>
      </div>
      <div class="sum" [class.zero]="getSum(tariff) === 0">
        <div class="remove-service visible-xs" *ngIf="tariff?.maxCount === 1">
          <button class="btn icon-remove" (click)="onChangeTotalQuantity(tariff, false)"></button>
        </div>
        {{ (getSum(tariff) !== 0 && (getSum(tariff) | sum)) || ('settings.free' | translate) }}
        {{ getSum(tariff) !== 0 && '₽' || ''}}
      </div>
    </div>
    <div *ngIf="total > 0 && selectedPeriod !== 'month'" class="total-tariff no-line">
      <div class="name-wrapper binary grey">
        {{ 'forcePay.cost' | translate }}{{ ('settings.bill.' + selectedPeriod) | translate }}:
      </div>
      <div class="sum grey">{{ (total | sum) || '0' }} ₽</div>
    </div>
    <div *ngIf="total > 0 && selectedPeriod !== 'month'" class="total-tariff no-line">
      <div class="name-wrapper binary zero">
        {{ 'forcePay.discount' | translate }}{{ percent }}:
      </div>
      <div class="sum zero light">+{{ (discountSum | sum) || '0' }} ₽</div>
    </div>
    <div *ngIf="total > 0 && selectedPeriod !== 'month'" class="total-tariff">
      <div class="name-wrapper binary grey">
        {{ 'forcePay.onTotal' | translate }}
      </div>
      <div class="sum grey">{{ (discountSum + total | sum) || '0' }} ₽</div>
    </div>
    <div class="total-sum">
      <div>{{ 'forcePay.amount' | translate }}{{ ('settings.bill.' + selectedPeriod) | translate }}:</div>
      <div class="total-sum__amount">{{ (total | sum) || '0' }} ₽</div>
    </div>
    <div *ngIf="public && isMobile" class="register-note">{{ 'tariffStartText' | translate}}</div>

    <button *ngIf="!public && (hasChanges || paymentHistory > 0 || promoDays.promoDaysRemain > 0 || !total)"
            class="btn btn-save with-spinner"
            [class.disabled]="!hasChanges"
            type="submit"
            [disabled]="inProgress"
            (click)="saveChanges()">
      <app-spinner [inProgress]="inProgress" [btnText]="'button.saveChanges' | translate"></app-spinner>
    </button>
    <button class="btn btn-save"
            (click)="goPayment()"
            [class.disabled]="!hasChanges"
            *ngIf="!public && paymentHistory === 0 && promoDays.promoDaysRemain === 0 && total > 0">
      {{ ('settings.payment.pay' | translate) + ' ' + (total | sum) + ' ₽' }}
    </button>
    <button *ngIf="public" class="btn btn-register with-spinner"
            type="submit"
            [disabled]="inProgress"
            (click)="register()">
      <app-spinner [inProgress]="inProgress" [btnText]="'button.promoStart' | translate"></app-spinner>
    </button>
    <div *ngIf="public && !isMobile" class="register-note">{{ 'tariffStartText' | translate}}</div>
    <div class="ad" *ngIf="selectedPeriod !== 'year' && total > 0 && !public">
      <div class="title" [innerHTML]="'tariffAdNotYear' | translate"></div>
      <div class="text" [innerHTML]="tariffAdNotYearSum"></div>
    </div>
    <div class="ad" *ngIf="selectedPeriod === 'year' && total > 0 && !public">
      <div class="title" [innerHTML]="'tariffAdYear' | translate"></div>
      <div class="text" [innerHTML]="'tariffAdYearSum' | translate"></div>
    </div>

    <div class="special-offer" *ngIf="isShowSpecialOffer">
      <span>
        {{ 'specialOfferQuestion' | translate }}
      </span>

      <a class="link" (click)="openWidget()">
        {{ 'specialOfferLink' | translate }}
      </a>
    </div>
  </div>
</ng-template>

<ng-template #tplGroup let-group>
  <div class="tiles-wrapper" [class.show]="group.id === 'main' || (group.id === 'additional' && optionsVisible)" [class.eng]="isEng">
    <div class="tile tariff-icon-{{ tariff.id }}"
         [class.additional]="group.id === 'additional'"
         *ngFor="let tariff of group.items">
      <div class="name" [innerHTML]="tariff?.name"></div>
      <div class="desc" [innerHTML]="tariff?.description"></div>
      <div class="buttons-wrapper">
        <button *ngIf="tariff?.disabled"
                class="btn btn-comingsoon">
          {{ 'button.comingsoon' | translate }}
        </button>
        <button *ngIf="tariff?.count === 0 && !tariff?.disabled"
                class="btn btn-add"
                (click)="onChangeQuantity(tariff, true)">
          {{ 'button.add' | translate }}
        </button>
        <button *ngIf="tariff?.count === tariff?.maxCount && !tariff?.disabled"
                class="btn btn-delete"
                (click)="onChangeQuantity(tariff, false)">
          {{ 'button.delete' | translate }}
        </button>
        <div class="counter" *ngIf="tariff?.count > 0 && tariff?.count !== tariff?.maxCount && !tariff?.disabled">
          <button class="btn minus icon-minus" (click)="onChangeQuantity(tariff, false)"></button>
          <div class="quantity">{{ tariff.count }}</div>
          <button class="btn plus icon-plus" (click)="onChangeQuantity(tariff, true)"></button>
        </div>

      </div>
      <div class="price" *ngIf="!tariff?.disabled">
      <span *ngIf="(tariff.count < tariff.quantityForFree && tariff.quantityForFree !== 0) ||
                    tariff.quantityForFree === tariff?.maxCount">
        {{ 'settings.free' | translate }}
      </span>
        <span *ngIf="(tariff.count >= tariff.quantityForFree || tariff.quantityForFree === 0) &&
              tariff.quantityForFree !== tariff?.maxCount">
        {{ getPrice(tariff) | sum }}&nbsp;₽/{{ 'settings.periodMob.month' | translate }}
      </span>
      </div>
      <div class="container-free">
        <div class="free" *ngIf="tariff.id === 'whatsappFree'">{{ 'settings.free' | translate }}</div>
      </div>
    </div>
  </div>

</ng-template>

<div class="payment-tabs" [class.mobile]="isMobile">
  <div class="label" *ngIf="!isMobile">{{ 'tariffsTabs.label' | translate }}</div>
  <div class="period"
       [innerHTML]="isMobile ? ('tariffsTabsMobile.' + period | translate) : ('tariffsTabs.' + period | translate)"
       [class.active]="selectedPeriod === period"
       (click)="setPeriod(period)"
       *ngFor="let period of availablePeriod"
  ></div>
</div>

<div class="notice">
  <span
    class="blue"
    [tooltip]="'settings.tariffOperatorAdviceDescription' | translate"
    placement="bottom"
    tooltipAppendToBody="true"
  >
    {{ 'settings.tariffOperatorAdviceTitle' | translate }}

    <i class="teletype-icon-help icon">
    </i>
  </span>

  <span>
    {{ 'settings.tariffOperatorAdviceTitle2' | translate }}
  </span>
</div>

<div class="tariff-layout">
  <div class="tariff-wrapper" [ngClass]="{'static': public}">
    <div class="tariff-tiles-wrapper">
      <div *ngFor="let group of tariffs" class="repeat-block">
          <div class="options-trigger"
               [class.mobile]="isMobile"
               (click)="optionsVisible = !optionsVisible"
               *ngIf="group.id !== 'main'">
            {{optionsVisible && '−' || '+'}} <span>{{ 'options' | translate }}</span>
          </div>
          <ng-template *ngTemplateOutlet="tplGroup; context: {$implicit: group}"></ng-template>
      </div>
    </div>
  </div>

  <div class="total-wrapper">
    <div *ngIf="!public" class="balance-wrapper">
      <div class="title">{{ 'forcePay.yourBalance' | translate }}</div>
      <div class="info" *ngIf="promoDays.promoDaysRemain > 0">
        <div class="label">{{ 'forcePay.promo' | translate }}</div>
        <div class="sum zero" [class.red]="promoDays.promoDaysRemain < 4">
          {{ promoDays.promoDaysRemain }} {{ getDayText(promoDays.promoDaysRemain) }}
        </div>
      </div>
      <div class="info" *ngIf="promoDays.promoDaysRemain === 0 || balance > 0">
        <div class="label">{{ 'forcePay.balance' | translate }}</div>
        <div class="sum">{{ balance }} ₽</div>
      </div>
<!--      <div class="info" *ngIf="total > 0 && hasChanges">-->
<!--        <div class="label">{{ 'tariffEnoughFor' | translate }}</div>-->
<!--        <div class="sum">{{'До ' + toDate + '' }}</div>-->
<!--      </div>-->
      <button class="btn pay" (click)="goPayment()">{{ 'button.replenishBalance' | translate }}</button>
    </div>

    <ng-template *ngTemplateOutlet="selectedTariffTpl"></ng-template>
  </div>
</div>

<div *ngIf="showTariffModal" class="selected-tariff-container">
  <button type="button" (click)="closeTariffsModal()" class="close-modal"></button>
  <ng-template *ngTemplateOutlet="selectedTariffTpl"></ng-template>
</div>

<ng-template #lastOperatorModalTemplate>
  <div class="modal-header pablo">
    <h4 class="modal-title pull-left"></h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <i class="teletype-icon-close"></i>
    </button>
  </div>
  <div class="modal-body" *ngIf="deleteInProgress">
    <div class="pablo-disable"></div>
    <app-client-remapping [isOperator]="false"
                          [onlyRemapping]="true"
                          (close)="closeModal()"
                          (complete)="uncheckOperator()"
                          (cancel)="closeModal()">
    </app-client-remapping>
  </div>

  <div class="modal-body zero-up" *ngIf="!deleteInProgress">
    <div class="pablo-disable"></div>
    <div class="warn-title">{{ 'lastOperatorModal.title' | translate }}</div>
    <div class="warn-text">{{ 'lastOperatorModal.text' | translate }}</div>
    <div class="btn-inline">
      <button type="button" class="btn btn-yes" (click)="removeOperator()">
        {{ 'lastOperatorModal.yes' | translate }}
      </button>
      <button type="button" class="btn btn-cancel" (click)="closeModal()">
        {{ 'lastOperatorModal.no' | translate }}
      </button>
    </div>
  </div>

</ng-template>
