import { Injectable, NgZone } from '@angular/core';
import { TelephonyApiService } from '@app/core/api/api.telephony';
import { I18nService } from '@app/core/i18n.service';
import { TelephonyService } from '@app/core/telephony.service';
import { ToastConfig, ToastType } from '@app/core/toasts/toast';
import { ToastService } from '@app/core/toasts/toast.service';
import { UserService } from '@app/core/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, publishReplay, refCount, switchMap, take } from 'rxjs/operators';

export interface SipAccount {
  id: string;
  type: 'sipuni';
  state: string;
  name: string;
  token: string;
  enabled: boolean;
  activeClients: string[];
  operators: SipAccountOperator[];
}

interface SipAccountOperator {
  clientId: string;
  clientSipId: string;
  sipInternalPhone: string;
  sipExternalPhone: string;
  sipPersonalToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class SipuniService {

  public sipAccountData$ = this.telephonyService.telephonyList$.pipe(
    map(list => list.find((integration => integration.type === 'sipuni'))),
    publishReplay(1),
    refCount(),
  );

  public isAvailableCalls$: Observable<boolean> = this.sipAccountData$.pipe(
    map((sipAccount) => {
      return sipAccount?.activeClients.includes(this.userService.user.id) || false;
    }),
    publishReplay(1),
    refCount(),
  );

  private readonly translations: any;

  constructor(
    private telephonyService: TelephonyService,
    private telephonyApi: TelephonyApiService,
    private userService: UserService,
    private toastService: ToastService,
    private i18nService: I18nService,
    private zone: NgZone,
  ) {
    this.translations = this.i18nService.translateService.translations[this.i18nService.language];
  }

  public makeCall(phone: string) {
    this.sipAccountData$.pipe(
      switchMap((accountData) => this.telephonyApi.makeCall(accountData.id, phone)),
      take(1),
    ).subscribe((res) => {
      let toastConfig: ToastConfig;

      if (res.success) {
        toastConfig = {
          title: this.translations.toasts.done,
          text: this.translations.toasts.callWillBeMade,
          type: ToastType.Success,
        };
      } else {
        toastConfig = {
          title: this.translations.toasts.error,
          text: res.errors[0].message,
          type: ToastType.Error,
          disableAutoClose: true,
        };
      }

      this.zone.run(() => {
        this.toastService.addSimpleToast(toastConfig);
      })
    });
  }
}
