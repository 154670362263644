<div class="dark-sidebar" [class.slide-left]="isSettingsPage" (click)="resetSearch()">
  <div
    #resizeArea
    *ngIf="isResizableSidebar | async"
    class="resize-area"
  ></div>

  <app-project-switch></app-project-switch>
  <div class="sidebar-menu" [ngStyle]="{'padding-bottom': footerHeight + 'px'}">
    <ng-scrollbar>
      <div scrollViewport class="inner">
        <div class="sidebar-section"
             *ngFor="let section of sidebarSections; let isLast = last; trackBy: sectionTrack"
             [ngClass]="{'last-of-list': isLast}">
          <!--<p class="sidebar-section-title">{{ section.name | translate }}</p>-->
          <p class="sidebar-section-title" *ngIf="section.name !== 'Channels' && section.name !== 'Teammates'">
            {{ getName(section.name) | translate }}
          </p>

          <button
            [disabled]="autoAssignLoading"
            [class.disabled]="autoAssignLoading"
            (click)="appealAutoAssignHandler()"
            class="sidebar-menu-auto-assign btn"
            *ngIf="isUserOperator && section.name == 'Channels'">
            <i class="teletype-icon-mail"></i>
            {{ 'sidebar.getNextAppeal' | translate}}
          </button>

          <span class="sidebar-section-title" *ngIf="section.name === 'Channels'">
          {{ getName(section.name) | translate }}</span>
          <span class="sidebar-section-title" *ngIf="section.name === 'Teammates'">
          {{ getName(section.name) | translate }}
        </span>
          <ul class="sidebar-section-items" [class.toggle]="section.name === 'Groups'">
            <li *ngFor="let item of section.items; let i = index; trackBy: sectionItemTrack"
                routerLinkActive="active"
                [class.error]="item?.hasError || item?.isWaiting || item?.isNeedAction">
              <a *ngIf="!item.url"
                 [routerLink]="'/conversations/' + item.id + '/open/' + item.linkType"
                 (click)="goTo(item.id, item.linkType)">
                <i *ngIf="item.icon === 'user'" class="user-avatar" [style.background-image]="sanitizeUrl(item.img)">
                  <div class="available-state" [ngClass]="{active: item.isOnline}"></div>
                </i>
                <i *ngIf="item.icon === 'category'" class="category"></i>
                <i *ngIf="item.icon === 'tag'" class="category" [style.background-color]="item.color"></i>
                <div class="menu-item _with-padding">{{ item.title }}</div>
              </a>

              <a [routerLink]="item.url" [class.white]="item.type === 'unanswered' && item.count > 0" *ngIf="item.url">
                <i *ngIf="item.icon === 'predefined'" class="teletype-icon-{{ item.type }}"></i>
                <i *ngIf="item.icon === 'lattice'" class="lattice">#</i>
                <i *ngIf="item.icon === 'user'" class="user-avatar" [style.background-image]="sanitizeUrl(item.img)">
                  <div class="available-state" [ngClass]="{active: item.isOnline}"></div>
                </i>
                <i *ngIf="item.icon === 'group'" class="group-circle" [ngStyle]="{'background-color': item.color}"></i>
                <div class="menu-item">{{ item.title }}</div>
                <div class="count-wrapper">
                <span *ngIf="item.count && !item.hasError && !item.isNeedAction" class="count">
                  {{ item.count && getNumberWithCommas(item.count) }}
                </span>
                </div>

                <div class="custom-tooltip" *ngIf="item.isNeedAction && item.type === 'instagram'">
                  <span class="tooltip-text">{{ 'sidebar.passTooltipText' | translate }}</span>
                  <div class="pin"></div>
                </div>

                <div class="custom-tooltip" *ngIf="item.isNeedAction && item.type === 'whatsapp_teletype'">
                  <span class="tooltip-text">{{ 'sidebar.scanTooltipText' | translate }}</span>
                  <div class="scan"
                       (click)="openModal(templateModalSidebar, item.id, item.type)"></div>
                </div>

                <div class="pin"
                     *ngIf="(item.hasError || item.isNeedAction)  && item.type === 'telegram'"
                     (click)="openModal(templateModalSidebar, item.id, item.type)"></div>

                <div class="error"
                     *ngIf="item.hasError && item.type !== 'whatsapp_teletype' &&
                    item.type !== 'telegram' && item.type !== 'instagram'">
                </div>

                <div class="error"
                     *ngIf="(item.hasError || item.isWaiting) && item.type === 'whatsapp_teletype'"
                     (click)="openModal(templateModalSidebar, item.id, item.type, false, item.isWaiting)">
                </div>

                <div class="error"
                     *ngIf="(item.hasError || item.isWaiting) && item.originType === 'instagram_direct'"
                     (click)="openModal(templateModalSidebar, item.id, item.type, false, item.isWaiting)">
                </div>
              </a>
              <a [routerLink]="item.secondUrl" *ngIf="item.secondUrl" class="invisible-link"></a>
              <a [routerLink]="item.thirdUrl" *ngIf="item.thirdUrl" class="invisible-link"></a>
            </li>
            <li class="no-items-placeholder" *ngIf="section.name === 'Channels' && isUserAdmin">
              <a (click)="trackChannels()">{{ 'sidebar.addChannel' | translate }}</a>
            </li>
            <li class="no-items-placeholder" *ngIf="section.name === 'Teammates' && isUserAdmin">
              <a [routerLink]="['/settings/teammates']"
                 (click)="trackOperators()">{{ 'sidebar.addTeammate' | translate }}</a>
            </li>
          </ul>
          <p class="show-toggler"
             *ngIf="section.name === 'Groups' && section.items.length > 5"
             (click)="toggled = !toggled"
             translate>
            {{ !toggled && 'sidebar.showMore' || 'sidebar.hide' | translate }}
          </p>
        </div>
      </div>
    </ng-scrollbar>
  </div>
  <div class="sidebar-footer" #sidebarFooter>
    <div class="sidebar-section" [class.allow]="hasPermissionToSettings"
         [class.bordered]="hasPermissionToSettings || allowGetProButton">
      <ul class="sidebar-section-items">
        <li *ngIf="hasPermissionToSettings">
          <a routerLink="/settings/start" routerLinkActive="active" (click)="trackSettings()">
            <i class="teletype-icon-settings"></i>
            <div class="menu-item">{{ 'sidebar.settings' | translate }}</div>
            <div class="count-wrapper"></div>
          </a>
        </li>
        <li *ngIf="isNotificationsAvailable">
          <a (click)="toggleNotifcations()">
            <i class="icon-notification"></i>
            <div class="menu-item">{{ 'sidebar.notifications' | translate }}</div>
            <div class="count-wrapper red" [ngClass]="{'loading': showSupportLoading}"></div>
          </a>
        </li>
        <li>
          <a href="https://help.teletype.app" target="_blank">
            <i class="teletype-icon-help"></i>
            <div class="menu-item">{{ 'sidebar.base' | translate }}</div>
            <div class="count-wrapper red"></div>
          </a>
        </li>
        <li>
          <a routerLink="/masterclass" routerLinkActive="active">
            <i class="teletype-icon-video"></i>
            <div class="menu-item">{{ 'sidebar.video' | translate }}</div>
            <div class="count-wrapper red"></div>
          </a>
        </li>
        <li>
          <a (click)="openSupport()">
            <i class="teletype-icon-support"></i>
            <div class="menu-item">{{ 'sidebar.supportChat' | translate }}</div>
            <div class="count-wrapper red" [ngClass]="{'loading': showSupportLoading}"></div>
          </a>
        </li>
        <li>
          <a href="https://teletype.app/blog/2023/04/19/обновления-2023-3/" target="_blank" (click)="markAsRead()">
            <i class="teletype-icon-news"></i>
            <div class="menu-item">{{ 'sidebar.news' | translate }}</div>
            <div class="count-wrapper"><div class="circle blinking" *ngIf="hasNews$ | async"></div></div>
          </a>
        </li>
      </ul>
    </div>

    <div class="brand">
      <a class="ios" href="https://apps.apple.com/us/app/teletype-app/id1531835742" target="_blank"></a>
      <a class="google" href="https://play.google.com/store/apps/details?id=com.TeletypeApp" target="_blank"></a>
    </div>
  </div>

  <ng-template #templateModalSidebar>
    <div class="modal-header"
         [class.whatsapp]="isWhatsAppTeletype"
         [class.instagram]="isInstagram"
         [class.telegram]="isTelegram">
      <h4 class="modal-title pull-left">
        {{((isWhatsAppTeletype && 'sidebar.whatsappTitle' | translate) || '') ||
          ((isTelegram && 'sidebar.telegramTitle' | translate) || '') ||
          ((isInstagram && 'sidebar.instagramTitle' | translate) || '')}}
      </h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <i class="teletype-icon-close"></i>
      </button>
    </div>
    <div class="modal-body" *ngIf="isQR">
      <div *ngIf="isWhatsAppTeletype">
        <div class="auth-title">
          {{ 'sidebar.authTitle' | translate }}
        </div>

        <div class="img-wrapper">
          <qrcode *ngIf="qr && isWhatsAppTeletype"
                  class="qr"
                  [qrdata]="qr"
                  [width]="264"
                  [errorCorrectionLevel]="'M'"
                  [elementType]="'svg'">
          </qrcode>
          <div *ngIf="!qr" class="qr-bg" (click)="showQRCode(whatsAppChannelId)">
            <div class="qr-color">
              <div class="refresh" [class.rotate]="inProgress"></div>
              <div class="refresh-text">
                {{ 'whatsapp.refreshQR' | translate }}<br/>{{ 'whatsapp.refreshQRpart' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div class="auth-link">
          <div class="text">{{ 'whatsapp.copyAuthLink' | translate }}</div>
          <span class="copy-link" (click)="getCode()"></span>
        </div>

        <div class="note">
          <p><b>{{ 'whatsapp.connect' | translate }}</b></p>
          <p>1.{{ 'whatsapp.instruction' | translate }}</p>
          <p>2.{{ 'whatsapp.press' | translate }}<b> {{ 'whatsapp.menu' | translate }}</b>&nbsp;
            <span class="first-sign"></span>
            {{ 'whatsapp.or' | translate }}<b>{{ 'whatsapp.settings' | translate }}</b>&nbsp;<span class="second-sign"></span>
            {{ 'whatsapp.select' | translate }}<b> {{ 'whatsapp.web' | translate }}</b>.
          </p>
          <p>3. {{ 'whatsapp.hover' | translate }}</p>
        </div>
        <app-alert *ngIf="error" [alertContent]="error"></app-alert>
        <div class="btn-container">
          <button class="btn btn-primary whatsapp btn-done" [disabled]="inProgress" (click)="done()">
            <span>{{ 'button.done' | translate}}</span>
          </button>
        </div>
      </div>

      <div *ngIf="isTelegram">
        <app-telegram-setup [channel]="telegramChannel"
                            [isEdit]="true"
                            (close)="closeModal()">
        </app-telegram-setup>
      </div>
    </div>

    <div class="modal-body" *ngIf="!isQR && isWhatsAppTeletype">
      <div class="error-title">{{isWaiting && 'sidebar.lostConnect' || 'sidebar.stop' | translate }}</div>
      <div class="error-text"
           [innerHTML]="isWaiting && 'sidebar.lostConnectText' || 'sidebar.stopText' | translate">
      </div>
      <div class="btn-container" *ngIf="!isWaiting">
        <button class="btn btn-primary whatsapp btn-done" (click)="startChat()">
          <span>{{ 'sidebar.openSupport' | translate}}</span>
        </button>
      </div>
    </div>

    <div class="modal-body" *ngIf="!isQR && isInstagram">
      <div class="error-title">{{'sidebar.hasLimit' | translate }}</div>
      <div class="error-text"
           [innerHTML]="'sidebar.hasLimitText' | translate">
      </div>
    </div>
  </ng-template>
</div>

<ng-template #elamaSupport>
  <app-elama-support (close)="closeModal()"></app-elama-support>
</ng-template>
