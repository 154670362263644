<svg
  viewBox="0 0 100 100"
  xmlns="http://www.w3.org/2000/svg"
  preserveAspectRatio="none"
  [style.height]="size"
  [style.widows]="size"
>
  <circle r="45" cx="50" cy="50" [style.stroke]="primaryColor" />

  <!-- 282.78302001953125 is auto-calculated by path.getTotalLength() -->
  <path
    #meter
    class="meter"
    d="M5,50a45,45 0 1,0 90,0a45,45 0 1,0 -90,0"
    stroke-linecap="round"
    stroke-linejoin="round"
    [style.stroke]="accentColor"
    stroke-dashoffset="282.78302001953125"
    stroke-dasharray="282.78302001953125"
  />
</svg>
