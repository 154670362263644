<div class="appeal-type-{{ conversationData?.selectedAppealMessenger }}" *ngIf="conversationData">
  <div class="chat-controls">
    <div class="user-details">
      <span
        class="name"
        (click)="onClickToChatName()"
        [class.availableClick]="isGroupChat"
      >
        {{ conversationName | unescape }}
      </span>
      <span class="messenger-label" *ngIf="conversationData?.selectedAppealMessenger !== 'instagram_comments_business'">
        {{ label }}
      </span>
    </div>
    <div class="chat-controls-buttons">
      <!--<button type="button">-->
      <!--<i class="teletype-icon-information"></i>-->
      <!--</button>-->
      <!--<button type="button">-->
      <!--<i class="teletype-icon-settings"></i>-->
      <!--</button>-->
      <div *ngIf="conversationData?.selectedAppealMessenger !== 'instagram_comments_business'">
        <div class="custom-tooltip" *ngIf="isAdmin && !isGroupChat && (isAvailableDeleteAppeal | async)">
          <span class="tooltip-text archive" [class.archiveTooltipAmo]="amoWidget">{{ 'conversation.sendToArchive' | translate }}</span>
          <button type="button" (click)="appealDelete()">
            <i class="teletype-icon-archive"></i>
          </button>
        </div>

        <div class="custom-tooltip">
          <span class="tooltip-text center" [class.widget]="amoWidget">{{ 'conversation.markDoneTooltip' | translate }}</span>
          <button type="button" (click)="switchDialogState()" class="btn done" [class.disabled]="!isOpen">
            <i class="teletype-icon-done"></i>
            <span>{{ 'conversation.markDone' | translate }}</span>
          </button>
        </div>
      </div>

      <div *ngIf="conversationData?.selectedAppealMessenger === 'instagram_comments_business'">
        <button type="button" (click)="markAllCommentsAsRead()" class="btn done" [class.disabled]="!isOpen">
          <i class="teletype-icon-done"></i>
          <span>{{ 'conversation.markDoneComments' | translate }}</span>
        </button>
      </div>

    </div>
  </div>

  <div class="appeal-messages-container-wrapper"
       [class.full-height]="fullHeight"
       #chatContainer>
    <div class="floating-date" *ngIf="floatingDate && !showUpSpinner">{{ floatingDate }}</div>

    <virtual-scroller #scroll
                      [items]="items"
                      [bufferAmount]="20"
                      (scroll)="onScrollVirtualScroller()"
                      (vsStart)="startScroll($event)"
                      (vsEnd)="endScroll($event)"
                      [enableUnequalChildrenSizes]="true"
                      [scrollbarWidth]="0"
                      [class.input-hidden]="fullHeight"
    >
      <ng-container [ngSwitch]="conversationData?.selectedAppealMessenger" *ngIf="virtualScroller">
        <ng-template ngSwitchCase="email">
          <ng-container *ngFor="let message of virtualScroller.viewPortItems; last as last; index as i; trackBy: myTrackByFunction">
            <!-- !hasCreatingMessageEmail()-->
            <app-message-email
              *ngIf="!message.systemType"
              class="message-container email-message"
              [class.note-type]="message?.type == 20"
              [_message]="message"
              [person]="person"
              [_quote]="getEmailQuote(i)"
              [last]="last"
              [selectedChannel]="selectedChannel"
              (showPreviewer)="triggerPreviewer($event)"
            ></app-message-email>

            <!--обычное сообщение-->
            <app-message class="message-container tech-message"
                         *ngIf="message.systemType"
                         (showPreviewer)="triggerPreviewer($event)"
                         (retry)="handleRetry($event)"
                         (remove)="handleRemoveMessage($event)"
                         (cancel)="cancelUpload($event)"
                         (onReplay)="onReplay($event)"
                         (onClickToPerson)="onClickToPerson($event)"
                         [userName]="conversationName"
                         [message]="message"
                         [seen]="message.seen"
                         [hasTail]="message.hasTail"
                         [progress]="message.progress"
                         [status]="message.status"
                         [failedMessage]="message.failedMessage"
                         [hasQueue]="hasQueue"
                         [selectedClientId]="selectedClientId"
                         [isChannelSupportReplay]="isChannelSupportReplay"
                         [class.tech-message]="message.messageType === 'internal'"
                         [class.last]="last">
            </app-message>

          </ng-container>

          <!--ранее использовалось как превью для вводимого сообщения-->
          <!--<app-message-email *ngIf="hasCreatingMessageEmail()"-->
          <!--                   [selectedChannel]="selectedChannel"-->
          <!--                   (showPreviewer)="triggerPreviewer($event)"-->
          <!--                   [person]="person"-->
          <!--                   [last]="true"-->
          <!--                   [ownMessage]="true"-->
          <!--                   [quote$]="getEmailQuote(messages.length - 1)"-->
          <!--                   [_message]="buildNewEmailMessage()"></app-message-email>-->
        </ng-template>
        <ng-template ngSwitchCase="instagram_comments_business">
          <app-comments-message class="message-container tech-message"
                                *ngFor="let message of virtualScroller.viewPortItems; let i = index;
                                  trackBy: myTrackByFunction"
                                (showPreviewer)="triggerPreviewer($event)"
                                (retry)="handleRetry($event)"
                                (remove)="handleRemoveMessage($event)"
                                (cancel)="cancelUpload($event)"
                                (loadChild)="loadChild($event)"
                                (onAnswer)="onAnswer($event)"
                                (onDirectWrite)="onDirectWrite($event)"
                                (onSeen)="onSeen($event)"
                                [message]="message"
                                [instagramDirectChannels]="instagramDirectChannels"
                                [paidEntities]="paidEntities"
                                [allowChannelsId]="allowChannelsId"
                                [failedMessage]="message.failedMessage"
                                [childs]="message?.childs"
                                [seen]="message?.seen"
                                [isOpen]="message?.appeal?.isOpen"
                                [progress]="message.progress"
                                [childsCount]="message.childsCount"
                                [hasQueue]="hasQueue"
                                [class.tech-message]="message.messageType === 'internal'"
                                [class.open]="message?.appeal?.isOpen">
          </app-comments-message>
        </ng-template>
        <ng-template ngSwitchDefault>

          <!--сообщения-->
          <app-message class="message-container tech-message"
                       *ngFor="let message of virtualScroller.viewPortItems; let i = index; let last = last; trackBy: myTrackByFunction"
                       (showPreviewer)="triggerPreviewer($event)"
                       (retry)="handleRetry($event)"
                       (remove)="handleRemoveMessage($event)"
                       (cancel)="cancelUpload($event)"
                       (onReplay)="onReplay($event)"
                       (onClickToPerson)="onClickToPerson($event)"
                       [attr.data-messageId]="message.id"
                       [userName]="conversationName"
                       [message]="message"
                       [seen]="message.seen"
                       [hasTail]="message.hasTail"
                       [progress]="message.progress"
                       [status]="message.status"
                       [failedMessage]="message.failedMessage"
                       [hasQueue]="hasQueue"
                       [selectedClientId]="selectedClientId"
                       [isChannelSupportReplay]="isChannelSupportReplay"
                       [class.tech-message]="message.messageType === 'internal'"
                       [class.last]="last">
          </app-message>

          <!--сообщение о том что пользователь печатает-->
          <app-message class="message-container" *ngIf="personTyping" [message]="typingMessage"></app-message>

          <!--сообщение о том, что пользователь покинул чат-->
          <app-message class="message-container"
                       *ngIf="person && !person.isOnline && conversationData &&
                          conversationData?.selectedAppealMessenger === 'web-widget'"
                       [message]="{message: ('conversation.personLeft' | translate) + ' ' +
                       (personLastOnlineAt24 || ''), type: 40}"></app-message>
        </ng-template>
      </ng-container>
    </virtual-scroller>
  </div>
  <app-loader [isLoading]="showUpSpinner" class="top"></app-loader>
  <app-image-previewer (hide)="hideImagePreviewer()" *ngIf="showPreviewer"
                       [image]="previewerImage"></app-image-previewer>
</div>
