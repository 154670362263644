import { Injectable } from '@angular/core';
import { play } from '@app/core/audio';
import { SocketsLogService } from '@app/core/sockets-log.service';
import { SocketIo } from '@app/core/sockets/ng-io.service';
import { UserService } from '@app/core/user.service';
import { UtilsService } from '@app/core/utils.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { AlertsService } from '@app/core/alerts/alerts.service';
import { WEBSOCKET_CHECK_CONNECTION_ONLOAD } from '@app/core/config/timeouts';
import { take } from 'rxjs/operators';
import { I18nService } from '@app/core/i18n.service';
import { ISound, ProjectService } from '@app/core/project.service';
import { AmplitudeService } from '@app/core/amplitude.service';

export enum WsEvent {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  RECONNECT = 'reconnect',

  AUTHORIZE_SUCCESS = 'authorize success',
  AUTH_PERSON = 'auth client',
  AUTH_COMPLETE = 'user was auth',
  AUTH_FAILED = 'user auth failed',
  AUTH_CHECK = 'check operator auth',
  NOTIFICATION_BOT_AUTH = 'notification bot auth',

  MESSAGE_FROM_CLIENT = 'message to client',
  FIRST_MESSAGE_FROM_CLIENT = 'first message to client',
  MESSAGE_FROM_OPERATOR = 'send message to widget',
  MESSAGE_RECEIVED = 'message received',
  MESSAGE_DELIVERED = 'message delivered',
  MESSAGE_DELIVERED_FAILED = 'message delivery failed',
  MESSAGE_FAILED = 'message failed',
  MESSAGE_DELETED = 'message deleted',
  MESSAGE_STATUS_CHANGE = 'message status change',
  UNREAD_MESSAGE = 'unread message',
  MESSAGES_SEEN = 'seen',

  OPERATOR_TYPING = 'operator typing',
  USER_TYPING = 'person typing',
  OPERATOR_STOP_TYPING = 'operator stop typing',

  USER_STATUS_CHANGE = 'operator status change',
  OPERATOR_FOR_APPEAL_CHANGE = 'operator appeal change',
  CATEGORY_FOR_APPEAL_CHANGE  = 'category appeal change',
  COUNTERS_UPDATED = 'counters appeals in project change',
  APPEAL_STATUS_CHANGE = 'appeal status change',
  COUNTER_NEW_MESSAGES = 'counter new messages in appeal change',
  COUNTER_UNREAD_APPEALS = 'counter unanswered appeals in project change',
  HAS_NEW_APPEALS = 'has new appeals',
  APPEALS_UPDATED = 'appeals updated',
  CHANNEL_STATUS_UPDATE = 'channel status update',
  SET_USER_DATA = 'set user data',

  TAG_CREATED = 'tag created',
  TAG_UPDATED = 'tag updated',
  TAG_DELETED = 'tag deleted',
  TAG_ADD_TO_PERSON = 'person tag added',
  TAG_REMOVE_FROM_PERSON = 'person tag removed',
  RESPONSIBLE_OPERATOR_PERSON_CHANGE = 'responsible operator person change',

  GROUP_CHAT_UPDATED = 'group chat updated',
  MESSAGES_TEMPLATES_UPDATED = 'messages templates updated',

  PERSON_STOP_TYPING = 'person stop typing',
  PERSON_STATUS_CHANGE = 'person status change',
  PERSON_UPDATE = 'person update',
  PERSON_PAGES_UPDATE = 'person pages update',
  PERSON_APPEALS_UPDATE = 'person appeals update',
  PERSON_CUSTOM_FIELDS_SETTINGS_CHANGED = 'person custom fields settings changed',
  APPEAL_ID_CHANGED = 'appeal id change',
  NOTE_DELETED = 'note deleted',
  APPEALS_REMAPPED = 'appeals remapped',
  APPEALS_DELETED = 'appeals deleted',
  AGGREGATE_APPEAL_DELETED = 'aggregate appeal deleted',
  CHANNEL_UPDATED = 'channel updated',
  CHANNELS_ORDER_UPDATED = 'channels order updated',
  CHANNEL_DELETED = 'channel deleted',
  MESSAGE_TO_PERSON_OUTSIDE_PANEL = 'message to person outside panel',
  SYSTEM_MESSAGE = 'system message to client',
  PAYMENT_SUCCESS = 'payment success',
  ERROR_NOTIFICATION = 'error notification',
  TARIFF_UPDATED = 'tariff updated',
  NEWS_NOTIFICATION = 'news notification',

  BACKGROUND_TASK_CREATED = 'background task created',
  BACKGROUND_TASK_STATUS_CHANGE = 'background task status change',
  BACKGROUND_TASK_PROGRESS = 'background task progress',
}

@Injectable({
  providedIn: 'root',
})
export class WebsocketsService {

  private hasConnect = new BehaviorSubject<any>(undefined);
  private disconnected = new BehaviorSubject<any>(undefined);
  private operatorChanged = new BehaviorSubject<any>(undefined);
  private categoryChanged = new BehaviorSubject<any>(undefined);
  private messageReceived = new BehaviorSubject<any>(undefined);
  private counterNewMessages = new BehaviorSubject<any>(undefined);
  private counterUnreadAppeals = new BehaviorSubject<any>(undefined);
  private firstMessageReceived = new BehaviorSubject<any>(undefined);
  private operatorTyping = new BehaviorSubject<any>(undefined);
  private appealStatusChange = new BehaviorSubject<any>(undefined);
  private appealsRemapped = new BehaviorSubject<any>(undefined);
  private errorNotification = new BehaviorSubject<any>(undefined);

  public ioSocket: any;
  public language: any;
  public soundTheme: ISound;

  constructor(
    private socket: SocketIo,
    private alerts: AlertsService,
    private i18nService: I18nService,
    private projectService: ProjectService,
    private amplitude: AmplitudeService,
    private userService: UserService,
    private utils: UtilsService,
    private socketsLogService: SocketsLogService,
  ) {
    this.i18nService.translateService.getTranslation(this.i18nService.language)
      .pipe(take(1))
      .subscribe((language: any) => {
        if (language) {
          this.language = language;
        }
      });

    this.projectService.getSoundTheme().subscribe(theme => {
      this.soundTheme = theme;
    });

    this.ioSocket = this.socket.ioSocket;

    this.socket.on(WsEvent.CONNECT, (event) => {
      if (localStorage.getItem('isShowLogs')) {
        console.log('ACTIVITIES TEST: Socket connected in', new Date());
      }
      this.hasConnect.next(event);
    });

    this.socket.on(WsEvent.OPERATOR_FOR_APPEAL_CHANGE, (event) => {
      if (event.project_id !== this.projectService.currentProject.id) {
        return;
      }
      this.operatorChanged.next(event);
    });

    this.socket.on(WsEvent.CATEGORY_FOR_APPEAL_CHANGE, (event) => {
      if (event.project_id !== this.projectService.currentProject.id) {
        return;
      }
      this.categoryChanged.next(event);
    });

    this.socket.on(WsEvent.MESSAGE_FROM_CLIENT, event => {
      if (event.project_id !== this.projectService.currentProject?.id) {
        return;
      }

      // бек иногда не ставит этот параметр на true, хотя очевидно он тут должен быть таким
      event.appeal.isUnanswered = true;

      if (!this.isChannelInUserGroup(event.channelId)) {
        return;
      }

      if (!this.userService.isUserHasAccessToAppeal(this.utils.camelKeys(event.appeal))) {
        return;
      }

      if (event && event.id) {
        try {
          const file = event.from.messenger === 'web-widget' ? this.soundTheme.incomingWidgetMessage :
            this.soundTheme.incomingMessage;
          play(file);
          // const audio = new Audio(file);
          // audio.addEventListener("canplaythrough", (event) => {
          //   /* аудио может быть воспроизведено; проиграть, если позволяют разрешения */
          //   audio.play();
          // });
        } catch (e) {
        }
      }

      this.messageReceived.next(event);
      setTimeout(() => {
        this.messageReceived.next(null);
      }, 100);
    });

    this.socket.on(WsEvent.ERROR_NOTIFICATION, event => {
      if (event.project_id !== this.projectService.currentProject.id) {
        return;
      }
      if (event) {
        this.amplitude.track('SCAN_FAIL_WHATSAPP');
        this.errorNotification.next(event);
        setTimeout(() => {
          this.errorNotification.next(null);
        }, 100);
      }
    });

    this.socket.on(WsEvent.FIRST_MESSAGE_FROM_CLIENT, event => {
      if (event.project_id !== this.projectService.currentProject.id) {
        return;
      }

      if (!this.isChannelInUserGroup(event.channelId)) {
        return;
      }

      // т к при первом сообщении диалог не имеет назначения, то проверку на доступ
      // к обращению в режиме изоляции выполнять не нужно

      if (event && event.id) {
        try {
          const file = event.from.messenger === 'web-widget' ? this.soundTheme.newConversationWidget :
            this.soundTheme.newConversation;
          play(file);
        } catch (e) {
        }
      }
      this.firstMessageReceived.next(event);
      setTimeout(() => {
        this.firstMessageReceived.next(null);
      }, 100);
    });

    this.socket.on(WsEvent.OPERATOR_TYPING, event => {
      this.operatorTyping.next(event);
    });

    this.socket.on(WsEvent.COUNTER_NEW_MESSAGES, event => {
      if (event.project_id !== this.projectService.currentProject.id) {
        return;
      }
      this.counterNewMessages.next(event);
    });

    this.socket.on(WsEvent.COUNTER_UNREAD_APPEALS, event => {
      if (event.project_id !== this.projectService.currentProject.id) {
        return;
      }
      this.counterUnreadAppeals.next(event);
    });

    this.socket.on(WsEvent.DISCONNECT, (event) => {
      this.alerts.addAlert({
        type: 'error',
        content: this.language.error.connectionInterrupted
      });
      if (localStorage.getItem('isShowLogs')) {
        console.log('ACTIVITIES TEST: Socket disconnected', new Date());
      }
      this.disconnected.next(event);
    });

    this.socket.on(WsEvent.APPEAL_STATUS_CHANGE, event => {
      if (event.project_id !== this.projectService.currentProject.id) {
        return;
      }
      this.appealStatusChange.next(event);
    });

    this.socket.on(WsEvent.APPEALS_REMAPPED, event => {
      if (event.project_id !== this.projectService.currentProject.id) {
        return;
      }
      this.appealsRemapped.next(event);
    });

    this.socket.on(WsEvent.RECONNECT, () => {
      this.alerts.removeAll();
    });

    setTimeout(() => {
      if (!this.isConnected()) {
        this.alerts.addAlert({
          type: 'error',
          content: this.language && this.language.error && this.language.error.connectionEstablished || ''
        });
      } else {
        this.alerts.removeAll();
      }
    }, WEBSOCKET_CHECK_CONNECTION_ONLOAD);
  }

  // TODO: кажется этот метод должен быть в каком-то другом сервисе
  public isChannelInUserGroup(channelId: string) {
    if (this.projectService.currentProject.useGroups) {
      // я не помню зачем здесь channelId, но при очень срочном хотфкиси не было времени разбираться
      // возможно ошибка была как раз из-за того, что я проверял на channelId, а не на useGroups
      // и сейчас тут это не надо
      return channelId ? this.userService.isUserHasAccessToChannel(channelId) : true;
    } else {
      return true;
    }
  }

  public sendEvent(event: WsEvent, data: any = null, callback: Function = Function()): void {
    this.socketsLogService.logSendSocketEvent(event, data);
    this.socket.emit(event, data, callback);
  }

  public on(event: WsEvent, callback: Function): void {
    this.socket.on(event, callback);
  }

  public removeListener(event: WsEvent, callback: Function = Function()): void {
    this.socket.removeListener(event, callback);
  }

  public isConnected(): boolean {
    return this.socket.ioSocket.connected;
  }

  public disconnect(): void {
    this.socket.disconnect();
  }

  public onConnect(): Observable<any> {
    return this.hasConnect.asObservable();
  }

  public onDisconnected(): Observable<any> {
    return this.disconnected.asObservable();
  }

  public onOperatorChanged(): Observable<any> {
    return this.operatorChanged.asObservable();
  }

  public onCategoryChanged(): Observable<any> {
    return this.categoryChanged.asObservable();
  }

  public onMessageFromClient(): Observable<any> {
    return this.messageReceived.asObservable();
  }

  public onFirstMessageFromClient(): Observable<any> {
    return this.firstMessageReceived.asObservable();
  }

  public onErrorNotify(): Observable<any> {
    return this.errorNotification.asObservable();
  }

  public onOperatorTyping(): Observable<any> {
    return this.operatorTyping.asObservable();
  }

  public onCounterNewMessages(): Observable<any> {
    return this.counterNewMessages.asObservable();
  }

  public onCounterUnreadAppeals(): Observable<any> {
    return this.counterUnreadAppeals.asObservable();
  }

  public onAppealStatusChange(): Observable<any> {
    return this.appealStatusChange.asObservable();
  }

  public onAppealsRemapped(): Observable<any> {
    return this.appealsRemapped.asObservable();
  }
}
