import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil, filter } from 'rxjs/operators';
import { RegistrationApiService } from '@app/core/api/api.registration';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-account-setup',
  templateUrl: './account-setup.component.html',
  styleUrls: ['./account-setup.component.scss']
})
export class AccountSetupComponent implements OnInit, OnDestroy {

  isLoading = false;
  private token: string;
  private unsub$ = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: RegistrationApiService,
  ) { }

  ngOnInit(): void {
    this.route.params.pipe(
      filter((params: any) => params.token)
    ).pipe(takeUntil(this.unsub$)).subscribe((params: any) => {
      this.token = params.token;
    });

    this.api.confirmEmail(this.token, '').subscribe((data: any) => {
        this.isLoading = false;
        if (data.success) {
          this.router.navigate([`/registration/profile/${data.data.token}`], { replaceUrl: true });
        }

      }, () => {},
      () => {
        this.isLoading = false;
      });
  }

  ngOnDestroy(): void {
    this.unsub$.next();
this.unsub$.complete();
  }
}
