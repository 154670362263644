import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SocketIo } from '@app/core/sockets';
import { WsEvent } from '@app/core/websockets.service';

function generateUid(): string {
  return Date.now()
    .toString(36) + Math.random()
    .toString(36)
    .substr(2);
}

export function timeStamp() {
  return new Date().getTime();
}

export const STORE_MAXIMUM_SIZE = 2e+7; // 20 мбайт

enum LogType {
  SendHTTPRequest = 'send http request',
  GetAnswerForHTTP = 'get answer for http request',
  GetSocketEvent = 'get socket event',
  SendSocketEvent = 'send socket event',
  PanelWasReloaded = 'panel was reloaded'
}

type LogAction = {
  idx: string;
  type: LogType,
  time: number;
  details: string[];
};

@Injectable({
  providedIn: 'root',
})
export class SocketsLogService {

  private startTime = timeStamp();
  private db: IDBDatabase;
  private projectId: string = null;
  private isLoggingEnabled = null;

  private logsBuffer: LogAction[] = [];

  constructor(
    private socket: SocketIo,
  ) {
    this.logReload();
  }

  public setProjectId(id: string) {
    this.projectId = id;
  }

  public switchLogging(isEnabled: boolean) {
    this.isLoggingEnabled = isEnabled;

    if (this.isLoggingEnabled) {
      this.listeningKeyboardShortcut();
      this.startLoggingSocketsEvents();
      this.openDb();
    } else {
      this.logsBuffer = [];
    }
  }

  public logSendSocketEvent(eventType: string, eventData: unknown) {
    if (!this.isLoggingEnabled) {
      return;
    }

    const details = [
      `EVENT_TYPE: ${eventType}`,
      `DATA: ${JSON.stringify(eventData)}`,
    ];

    this.saveToIndexDB({
      idx: generateUid(),
      type: LogType.SendSocketEvent,
      time: timeStamp(),
      details,
    });
  }

  public logHttpRequest(methodType: string, url: string, headers: HttpHeaders, body?: any) {
    if (this.isLoggingEnabled === false) {
      return () => {};
    }

    const requestId = generateUid();
    const details = [
      `URL: ${url}`,
      `REQUEST_ID: ${requestId}`,
      `REQUEST_TYPE: ${methodType}`,
      `HEADER: ${JSON.stringify(headers)}`,
      `BODY: ${JSON.stringify(body)}`,
    ];

    const timeOfStartRequest = timeStamp();

    this.saveToIndexDB({
      idx: generateUid(),
      type: LogType.SendHTTPRequest,
      time: timeOfStartRequest,
      details: details,
    });

    return (answer, isError?) => {
      let details = [
        ...(isError ? ['ERROR'] : []),
        `REQUEST_TIME: ${timeStamp() - timeOfStartRequest}`,
        `URL: ${url}`,
        `REQUEST_ID: ${requestId}`,
        `ANSWER: ${JSON.stringify(answer)}`,
      ];

      this.saveToIndexDB({
        idx: generateUid(),
        type: LogType.GetAnswerForHTTP,
        time: timeStamp(),
        details,
      });
    };
  }

  public startLoggingSocketsEvents() {
    if (!this.isLoggingEnabled) {
      return;
    }

    Object.values(WsEvent)
      .forEach((eventType) => {
        this.socket.on(eventType, (data) => {
          if (this.projectId && data?.project_id && this.projectId !== data?.project_id) {
            return;
          }

          const details = [
            `EVENT_TYPE: ${eventType}`,
            `DATA: ${JSON.stringify(data)}`,
          ];

          this.saveToIndexDB({
            idx: generateUid(),
            type: LogType.GetSocketEvent,
            time: timeStamp(),
            details,
          });
        });
      });
  }

  private openDb() {
    let openRequest = indexedDB.open('teletypelogs', 1);

    openRequest.onupgradeneeded = () => {
      let db = openRequest.result;
      if (!db.objectStoreNames.contains('logs')) {
        db.createObjectStore('logs', { keyPath: 'idx' });
      }
    };

    openRequest.onsuccess = () => {
      this.db = openRequest.result;
      this.logReload();
    };
  }

  private logReload() {
    this.saveToIndexDB({
      type: LogType.PanelWasReloaded,
      time: timeStamp(),
      details: [],
      idx: generateUid(),
    });
  }

  private saveToIndexDB(log: LogAction) {
    if (!this.db) {
      this.logsBuffer.push(log);
      return;
    }

    let transaction = this.db.transaction('logs', 'readwrite');
    let logsStore = transaction.objectStore('logs');

    if (this.logsBuffer.length) {
      this.logsBuffer.forEach((logAction) => {
        logsStore.add(logAction);
      });

      this.logsBuffer = [];
    }

    logsStore.add(log);

    this.clearOldLogs();
  }

  private clearOldLogs() {
    navigator.storage.estimate().then(({ usage }) => {
      if (usage > STORE_MAXIMUM_SIZE) {
        const transaction = this.db.transaction('logs', 'readwrite');

        const logStorage = transaction.objectStore('logs');

        // Создаем курсор для перебора записей
        const cursorRequest = logStorage.openCursor();
        let deleteKeys = [];
        let foundTargetRecord = false;

        cursorRequest.onsuccess = function (event) {
          let cursor = cursorRequest.result;

          if (cursor) {
            if (!foundTargetRecord) {
              deleteKeys.push(cursor.primaryKey);
              if (cursor.value.type === 'panel was reloaded') {
                foundTargetRecord = true;
              }
            }
            cursor.continue();
          } else {
            // Курсор достиг конца, удаляем собранные ключи
            deleteKeys.forEach(key => {
              const deleteRequest = logStorage.delete(key);
              deleteRequest.onsuccess = function () {};
              deleteRequest.onerror = function (event) {};
            });
          }
        };
      }
    });
  }

  private downloadLogFile() {
    const startTime = timeStamp();
    const transaction = this.db.transaction('logs');
    const logs = transaction.objectStore('logs');
    let openCursorRequest = logs.openCursor();

    let logFileData = '';

    // вызывается для каждой найденного курсом
    openCursorRequest.onsuccess = () => {
      let cursor = openCursorRequest.result;
      if (cursor) {
        let log = cursor.value; // объект книги
        let logText = `${log.time - this.startTime} ${log.type} - absolute time: ${log.time}\n`;
        log.details.forEach((detail) => logText += `        ${detail}\n`);
        logFileData = logFileData + logText + '\n';
        cursor.continue();
      } else {
        const fileType = 'data:application/octet-stream;base64,';
        const fileContent = btoa(unescape(encodeURIComponent(logFileData)));
        const file = fileType + fileContent;

        const link = document.createElement('a');
        link.download = 'log.txt';
        link.href = file;
        console.log('Скачивание логов заняло:', timeStamp() - startTime);
        link.click();
      }
    };
  }

  private listeningKeyboardShortcut() {
    document.addEventListener('keydown', (event) => {
      if (event.altKey && event.shiftKey && event.code === 'KeyL') {
        this.downloadLogFile();
      }
    });
  }
}
