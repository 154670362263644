import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { PersonApiService } from '@app/core/api/api.person';
import { ProjectService } from '@app/core/project.service';
import { WebsocketsService, WsEvent } from '@app/core/websockets.service';
import {
  CustomFieldConfig,
  CustomFieldsService,
  CustomFieldType,
} from '@app/settings/custom-fields/custom-fields.service';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import * as linkify from "linkifyjs";

const CustomFieldsMaxLength: Record<CustomFieldType, number | null> = {
  [CustomFieldType.String]: 32,
  [CustomFieldType.Bool]: null,
  [CustomFieldType.Number]: 32,
  [CustomFieldType.Text]: 512,
  [CustomFieldType.FixedSet]: null,
};

const CustomFieldsValidators: Record<CustomFieldType, ValidatorFn[]> = {
  [CustomFieldType.String]: [Validators.maxLength(CustomFieldsMaxLength[CustomFieldType.String])],
  [CustomFieldType.Bool]: [],
  [CustomFieldType.Number]: [Validators.maxLength(CustomFieldsMaxLength[CustomFieldType.Number]), Validators.pattern(/^\d+$/)],
  [CustomFieldType.Text]: [Validators.maxLength(CustomFieldsMaxLength[CustomFieldType.Text])],
  [CustomFieldType.FixedSet]: [],
};

const CustomFieldsDefaultValue: Record<CustomFieldType, CustomFieldPossibleValueType> = {
  [CustomFieldType.String]: '',
  [CustomFieldType.Bool]: false,
  [CustomFieldType.Number]: '',
  [CustomFieldType.Text]: '',
  [CustomFieldType.FixedSet]: null,
};

const CustomFieldsErrorMessage: Record<CustomFieldType, string> = {
  [CustomFieldType.String]: 'settings.customFields.typeValidationError.string',
  [CustomFieldType.Bool]: 'settings.customFields.typeValidationError.bool',
  [CustomFieldType.Number]: 'settings.customFields.typeValidationError.number',
  [CustomFieldType.Text]: 'settings.customFields.typeValidationError.text',
  [CustomFieldType.FixedSet]: '',
};

type CustomFieldPossibleValueType = string | null | number | boolean;

@Component({
  selector: 'app-person-custom-fields',
  templateUrl: 'person-custom-fields.component.html',
  styleUrls: ['./person-custom-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonCustomFieldsComponent {
  @Input() personId: string;

  public customFields: CustomFieldConfig[];
  public customFieldsFormGroup = new FormGroup<Record<string, FormControl<CustomFieldPossibleValueType>>>({});

  private unsub$ = new Subject<void>();

  isLoading = true;
  isSaving = false;

  constructor(
    private customFieldsService: CustomFieldsService,
    private ws: WebsocketsService,
    private personApiService: PersonApiService,
    private projectService: ProjectService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.getCustomFields();
  }

  ngOnDestroy() {
    this.unsub$.next();
    this.unsub$.complete();

    this.ws.removeListener(WsEvent.PERSON_UPDATE);
  }

  getCustomFields() {
    this.customFieldsService.getCustomFieldsAsync()
      .pipe(takeUntil(this.unsub$))
      .subscribe((list) => {
        if (!this.customFields) {
          list.forEach((customField) => {
            const control = new FormControl(CustomFieldsDefaultValue[customField.type], CustomFieldsValidators[customField.type]);
            if (customField.type === CustomFieldType.FixedSet) {
              control.valueChanges
                .pipe(takeUntil(this.unsub$))
                .subscribe((val) => {
                  if (!val) {
                    control.setValue(null, { emitEvent: false });
                  }
                })
            }
            this.customFieldsFormGroup.addControl(customField.id, control);
          });

          this.getCustomFieldsValue();
        }

        this.customFields = list;
      });
  }

  getCustomFieldsValue() {
    this.personApiService.getPersonCustomFieldsValue(this.personId)
      .pipe(takeUntil(this.unsub$))
      .subscribe((res) => {
        if (res.success) {
          this.customFieldsFormGroup.patchValue(res.data);
          this.watchFormChange();
          this.isLoading = false;
          this.cdr.detectChanges();
        }
      });

    this.ws.on(WsEvent.PERSON_UPDATE, (data: any) => {
      if (data.project_id !== this.projectService.currentProject.id) {
        return;
      }

      if (data.person_id === this.personId && !this.isSaving) {
        this.customFieldsFormGroup.patchValue(data.customFields);
        this.rerender();
      }
    });
  }

  // из-за того, что appTeletypeContentEditable не может обновлять значение программно,
  // приходиться его полностью перерисовывать с мощью такого хака
  rerender() {
    this.isLoading = true;
    this.cdr.detectChanges();
    this.isLoading = false;
    this.cdr.detectChanges();
  }

  watchFormChange() {
    this.customFieldsFormGroup.valueChanges.pipe(
      takeUntil(this.unsub$),
      tap(() => {
        this.isSaving = true;
      }),
      debounceTime(1000),
    ).subscribe(() => {
      this.setPersonCustomFields();
    });
  }

  setPersonCustomFields() {
    const value = this.customFieldsFormGroup.value;
    Object.keys(this.customFieldsFormGroup.value).forEach((key) => {
      if (value[key] === null && this.customFieldsService.getCustomFieldById(key).type === CustomFieldType.FixedSet) {
        value[key] = '';
      }
    })

    this.personApiService.setPersonCustomFieldsValue(this.personId, value)
      .pipe(takeUntil(this.unsub$))
      .subscribe((res) => {
        if (res.success) {
          this.isSaving = false;
          this.customFieldsFormGroup.markAsPristine();
          this.cdr.detectChanges();
        }
      });
  }

  setControlValue(control: AbstractControl, value: unknown, defaultValue: unknown) {
    control.setValue(value || defaultValue);
    control.markAsTouched();
    this.cdr.detectChanges();
  }

  getUrls(text) {
    if (!text) {
      return [];
    }

    return linkify.find(text).map(a => a.href);
  }

  protected readonly CustomFieldType = CustomFieldType;
  protected readonly CustomFieldsErrorMessage = CustomFieldsErrorMessage;
  protected readonly CustomFieldsDefaultValue = CustomFieldsDefaultValue;
  protected readonly CustomFieldsMaxLength = CustomFieldsMaxLength;
  protected readonly String = String;
  protected readonly Boolean = Boolean;
}
