import { Inject, PLATFORM_ID, NgZone } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { Observable } from 'rxjs';

import * as io from 'socket.io-client';

import { NgIoConfig, SOCKET_CONFIG_TOKEN } from './ng-io-config';

export class SocketIo {
  subscribersCounter = 0;
  ioSocket: SocketIOClient.Socket;
  private initialized = false;

  constructor(
    @Inject(NgZone) private zone: NgZone,
    @Inject(SOCKET_CONFIG_TOKEN) private config: NgIoConfig,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }

  on(eventName: string, callback: Function) {
    return this.zone.runOutsideAngular(() => {
      if (!this.ioSocket) {
        return;
      }
      this.ioSocket.on(eventName, callback);
    });
  }

  once(eventName: string, callback: Function) {
    return this.zone.runOutsideAngular(() => {
      if (!this.ioSocket) {
        return;
      }
      this.ioSocket.once(eventName, callback);
    });
  }

  connect() {
    return this.zone.runOutsideAngular(() => {
      if (isPlatformServer(this.platformId)) {
        return {};
      }
      if (!this.initialized) {
        const { url = '', options } = this.config;
        this.ioSocket = io(url, options);
      } else {
        this.ioSocket = this.ioSocket.connect();
      }
      return this.ioSocket;
    });
  }

  disconnect(close?: any) {
    const args = arguments;
    return this.zone.runOutsideAngular(() => {
      if (!this.ioSocket) {
        return;
      }
      return this.ioSocket.disconnect.apply(this.ioSocket, args);
    });
  }

  emit(eventName: string, data?: any, callback?: Function) {
    const args = arguments;
    return this.zone.runOutsideAngular(() => {
      if (!this.ioSocket) {
        return;
      }
      return this.ioSocket.emit.apply(this.ioSocket, args);
    });
  }

  removeListener(eventName: string, callback?: Function) {
    const args = arguments;
    return this.zone.runOutsideAngular(() => {
      if (!this.ioSocket) {
        return;
      }
      return this.ioSocket.removeListener.apply(this.ioSocket, args);
    });
  }

  removeAllListeners(eventName?: string) {
    const args = arguments;
    return this.zone.runOutsideAngular(() => {
      if (!this.ioSocket) {
        return;
      }
      return this.ioSocket.removeAllListeners.apply(this.ioSocket, args);
    });
  }

  fromEvent<T>(eventName: string): Observable<T> {
    return this.zone.runOutsideAngular(() => {
      if (!this.ioSocket) {
        return;
      }
      this.subscribersCounter++;
      return Observable.create((observer: any) => {
        this.ioSocket.on(eventName, (data: T) => {
          observer.next(data);
        });
        return () => {
          if (this.subscribersCounter === 1) {
            this.ioSocket.removeListener(eventName);
          }
        };
      });
    });
  }

  /* Creates a Promise for a one-time event */
  fromEventOnce<T>(eventName: string): Promise<T> {
    return this.zone.runOutsideAngular(() => {
      if (!this.ioSocket) {
        return;
      }
      return new Promise<T>(resolve => this.once(eventName, resolve));
    });
  }
}
