import { Component, EventEmitter, NgZone, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-on-board',
  templateUrl: './on-board.component.html',
  styleUrls: ['./on-board.component.scss']
})
export class OnBoardComponent implements OnInit {

  @Output() onClose: EventEmitter<boolean> = new EventEmitter(false);

  constructor(
    private zone: NgZone,
  ) { }

  ngOnInit() {
  }

  closeModal() {
    this.zone.runOutsideAngular(() => {
      this.onClose.emit(true);
    });
  }

}
