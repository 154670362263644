import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '@env/environment.prod';
import each from 'lodash-es/each';
import { GiphyResponse } from '@app/core/interfaces/giphyResponse';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GiphyService {
  private routes: any;
  private _trending: any;
  private subject = new BehaviorSubject<any>(null);

  get trending(): GiphyResponse {
    return this._trending;
  }

  set trending(trending: GiphyResponse) {
    this._trending = trending;
    this.subject.next(this._trending);
  }

  constructor(
    private http: HttpClient
  ) {
    this.routes = {
      // eslint-disable-next-line max-len
      search: (q: string, offset: number = 0) => `https://api.giphy.com/v1/gifs/search?q=${encodeURI(q)}&offset=${offset}&rating=pg&limit=20&api_key=${environment.giphyApiKey}`,
      // eslint-disable-next-line max-len
      trending: (offset: number = 0) => `https://api.giphy.com/v1/gifs/trending?rating=pg&offset=${offset}&limit=20&api_key=${environment.giphyApiKey}`
    };
    this.updateTrending();
  }

  searchGif(q: string, offset: number = 0): Observable<GiphyResponse> {
    return this.http.get(this.routes.search(q, offset))
      .pipe(
        map((body: any) => body),
        catchError(() => of('Error, could not load search results :-('))
      );
  }

  getTrending(): Observable<any> {
    return this.subject.asObservable();
  }

  updateTrending() {
    this.doTrendingRequest().subscribe((response: GiphyResponse) => {
      this.trending = response;
    });
  }

  doTrendingRequest(offset: number = 0): Observable<GiphyResponse> {
    return this.http.get(this.routes.trending(offset))
      .pipe(
        map((body: any) => body),
        catchError(() => of('Error, trending :-('))
      );
  }

  generateEvenGrid(items: any) {
    const orientations: any = [];
    let lines: any = [];
    let lineScore: any = [];
    const maxLineScore = 4;
    const containerWidth = (window.innerWidth > 767) ? 280 : 240;


    const getItemOrientation = (width: number, height: number) => {
      return (width > height) ? 'l' : 'p';
    };

    const constructLines = () => {
      let currentScore = 0;
      const imagesCount = orientations.length;
      let line: any = [];
      lines = [];
      lineScore = [];

      each(orientations, (orientation: string, index: number) => {
        if (orientation === 'l') {

          // If image still in current line
          if (currentScore + 2 <= maxLineScore) {
            if (index !== imagesCount - 1) {
              currentScore += 2;
              line.push(items[index]);
            } else {
              currentScore += 2;
              lineScore.push(currentScore);
              line.push(items[index]);
              lines.push(line);
            }
          } else {
            // If image is the first of next line

            if (index !== imagesCount - 1) {
              lineScore.push(currentScore);
              currentScore = 2;
              // We push the previous line
              lines.push(line);
              // We init it again
              line = [];
              line.push(items[index]);
            } else {
              lineScore.push(currentScore);
              currentScore = 2;
              lineScore.push(currentScore);
              // We push the previous line
              lines.push(line);
              // We init it again
              line = [];
              line.push(items[index]);
              lines.push(line);
            }
          }

        } else {
          if (currentScore + 1 <= maxLineScore) {
            // If image still in current line

            if (index !== imagesCount - 1) {
              currentScore++;
              line.push(items[index]);
            } else {
              currentScore++;
              lineScore.push(currentScore);
              line.push(items[index]);
              lines.push(line);
            }
          } else {
            // If image is the first of next line

            if (index !== imagesCount - 1) {
              lineScore.push(currentScore);
              currentScore = 1;
              // We push the previous line
              lines.push(line);
              // We init it again
              line = [];
              line.push(items[index]);
            } else {
              lineScore.push(currentScore);
              currentScore = 1;
              lineScore.push(currentScore);
              // We push the previous line
              lines.push(line);
              // We init it again
              line = [];
              line.push(items[index]);
            }
          }
        }
      });

    };

    const magic = () => {

      each(lines, (line, lineIndex) => {
        if (lineScore[lineIndex] >= maxLineScore - 1) {
          // L = Width of the Container
          const L = containerWidth;
          // m = margin
          const m = 10;
          // oH = originalHeight :: oW = originalWidth :: r = ratio
          const oW: any = [];
          const oH: any = [];
          const r: any = [];
          let count = 0;

          each(line, (item) => {
            oW.push(item.width);
            oH.push(item.height);
            r.push(item.width / item.height);
            count++;
          });

          // rW = reduced Width
          const rW: any = [];
          let sum = 0;

          for (let i = 0; i <= count - 1; i++) {
            for (let j = 0; j <= count - 1; j++) {
              sum += r[j] / r[i];
            }
            const rWi = (L - (count - 1) * m) / sum;
            rW.push(rWi);
            sum = 0;
          }

          const lineHeight = rW[0] / r[0];

          each(line, (item, itemIndex) => {
            lines[lineIndex][itemIndex] = {
              image: item.image,
              originalWidth: parseInt(item.width, 10),
              originalHeight: parseInt(item.height, 10),
              width: Math.floor(rW[itemIndex]),
              height: Math.floor(lineHeight)
            };
          });
        } else {
          let L;
          if (lineScore[lineIndex] <= maxLineScore / 2) {
            L = containerWidth / 2 - 2;
          } else {
            L = containerWidth - containerWidth / 3 - 2;
          }
          L = containerWidth;

          const m = 0;
          const oW: any = [];
          const oH: any = [];
          const r: any = [];
          let count = 0;

          each(line, (item) => {
            oW.push(item.width);
            oH.push(item.height);
            r.push(item.width / item.height);
            count += 1;
          });

          const rW: any = [];
          let sum = 0;
          for (let i = 0; i <= count - 1; i++) {
            for (let j = 0; j <= count - 1; j++) {
              sum += r[j] / r[i];
            }
            const rWi = (L - (count - 1) * m) / sum;
            rW.push(rWi);
            sum = 0;
          }

          const lineHeight = rW[0] / r[0];

          each(line, (item, itemIndex) => {
            lines[lineIndex][itemIndex] = {
              image: item.image,
              originalWidth: parseInt(item.width, 10),
              originalHeight: parseInt(item.height, 10),
              width: Math.floor(rW[itemIndex]),
              height: Math.floor(lineHeight)
            };
          });
        }
      });
    };

    // Step 1: Get orientations array

    each(items, (item) => {
      orientations.push(getItemOrientation(item.width, item.height));
    });

    // Step 2 : Construct the lines array
    constructLines();

    // Step 3: Magic
    magic();
    return lines;
  }
}
