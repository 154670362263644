<div class="login-form-container">
  <div class="form-container">
    <form class="reset-password-form" [formGroup]="confirmEmailForm" #confirmEmailFormForm="ngForm"
          novalidate>
      <div class="logo pointer" (click)="goToLanding()"></div>
      <h1>{{ 'newEmailConfirm.title' | translate }}</h1>
      <p [innerHTML]="'newEmailConfirm.weSentCode' | translate: {'email': email}"></p>

      <div class="multi-input">
        <ng-template ngFor let-item [ngForOf]="group" let-i="index">
          <input class="digit"
                 [ngClass]="{
                   'first': item === 1 || item === 4,
                   'second': item === 2 || item === 5,
                   'last': item === 3 || item === 6
                 }"
                 maxlength="1"
                 appAutofocus
                 [formControlName]="item"
                 type="number"
                 min="0"
                 max="9"
                 [attr.disabled]="isLoading ? 'disabled' : null"
                 autocomplete="off"
                 [skip]="item !== 1"
                 required
                 (input)="onInput($event, i)"
                 (keydown.backspace)="onBackspace(i)"
                 (keypress)="onPress($event)"
                 (paste)="onPaste($event, item)"
                 [name]="i"/>
          <span class="separator" *ngIf="item === 3"></span>
        </ng-template>
      </div>

      <app-alerts></app-alerts>

      <div class="text-wrapper">
        <div class="text">{{ 'newEmailConfirm.noCode' | translate }}?</div>
        <div class="text">
          <span (click)="resend()" class="link">
            {{ 'newEmailConfirm.again' | translate }}
          </span>
          {{ 'newEmailConfirm.or' | translate }}
          <a class="link" [routerLink]="'/register'">{{ 'newEmailConfirm.another' | translate }}</a>
        </div>
        <div class="text resend" [class.show]="resendCode">{{ 'newEmailConfirm.resend' | translate }}</div>
      </div>
    </form>
  </div>
  <div class="bottom-bg"></div>
</div>
