import deburr from 'lodash-es/deburr';

export const replacements = [
  // German umlauts
  ['ß', 'ss'],
  ['ä', 'ae'],
  ['Ä', 'Ae'],
  ['ö', 'oe'],
  ['Ö', 'Oe'],
  ['ü', 'ue'],
  ['Ü', 'Ue'],

  // Vietnamese
  ['à', 'a'],
  ['À', 'A'],
  ['á', 'a'],
  ['Á', 'A'],
  ['â', 'a'],
  ['Â', 'A'],
  ['ã', 'a'],
  ['Ã', 'A'],
  ['è', 'e'],
  ['È', 'E'],
  ['é', 'e'],
  ['É', 'E'],
  ['ê', 'e'],
  ['Ê', 'E'],
  ['ì', 'i'],
  ['Ì', 'I'],
  ['í', 'i'],
  ['Í', 'I'],
  ['ò', 'o'],
  ['Ò', 'O'],
  ['ó', 'o'],
  ['Ó', 'O'],
  ['ô', 'o'],
  ['Ô', 'O'],
  ['õ', 'o'],
  ['Õ', 'O'],
  ['ù', 'u'],
  ['Ù', 'U'],
  ['ú', 'u'],
  ['Ú', 'U'],
  ['ý', 'y'],
  ['Ý', 'Y'],
  ['ă', 'a'],
  ['Ă', 'A'],
  ['Đ', 'D'],
  ['đ', 'd'],
  ['ĩ', 'i'],
  ['Ĩ', 'I'],
  ['ũ', 'u'],
  ['Ũ', 'U'],
  ['ơ', 'o'],
  ['Ơ', 'O'],
  ['ư', 'u'],
  ['Ư', 'U'],
  ['ạ', 'a'],
  ['Ạ', 'A'],
  ['ả', 'a'],
  ['Ả', 'A'],
  ['ấ', 'a'],
  ['Ấ', 'A'],
  ['ầ', 'a'],
  ['Ầ', 'A'],
  ['ẩ', 'a'],
  ['Ẩ', 'A'],
  ['ẫ', 'a'],
  ['Ẫ', 'A'],
  ['ậ', 'a'],
  ['Ậ', 'A'],
  ['ắ', 'a'],
  ['Ắ', 'A'],
  ['ằ', 'a'],
  ['Ằ', 'A'],
  ['ẳ', 'a'],
  ['Ẳ', 'A'],
  ['ẵ', 'a'],
  ['Ẵ', 'A'],
  ['ặ', 'a'],
  ['Ặ', 'A'],
  ['ẹ', 'e'],
  ['Ẹ', 'E'],
  ['ẻ', 'e'],
  ['Ẻ', 'E'],
  ['ẽ', 'e'],
  ['Ẽ', 'E'],
  ['ế', 'e'],
  ['Ế', 'E'],
  ['ề', 'e'],
  ['Ề', 'E'],
  ['ể', 'e'],
  ['Ể', 'E'],
  ['ễ', 'e'],
  ['Ễ', 'E'],
  ['ệ', 'e'],
  ['Ệ', 'E'],
  ['ỉ', 'i'],
  ['Ỉ', 'I'],
  ['ị', 'i'],
  ['Ị', 'I'],
  ['ọ', 'o'],
  ['Ọ', 'O'],
  ['ỏ', 'o'],
  ['Ỏ', 'O'],
  ['ố', 'o'],
  ['Ố', 'O'],
  ['ồ', 'o'],
  ['Ồ', 'O'],
  ['ổ', 'o'],
  ['Ổ', 'O'],
  ['ỗ', 'o'],
  ['Ỗ', 'O'],
  ['ộ', 'o'],
  ['Ộ', 'O'],
  ['ớ', 'o'],
  ['Ớ', 'O'],
  ['ờ', 'o'],
  ['Ờ', 'O'],
  ['ở', 'o'],
  ['Ở', 'O'],
  ['ỡ', 'o'],
  ['Ỡ', 'O'],
  ['ợ', 'o'],
  ['Ợ', 'O'],
  ['ụ', 'u'],
  ['Ụ', 'U'],
  ['ủ', 'u'],
  ['Ủ', 'U'],
  ['ứ', 'u'],
  ['Ứ', 'U'],
  ['ừ', 'u'],
  ['Ừ', 'U'],
  ['ử', 'u'],
  ['Ử', 'U'],
  ['ữ', 'u'],
  ['Ữ', 'U'],
  ['ự', 'u'],
  ['Ự', 'U'],
  ['ỳ', 'y'],
  ['Ỳ', 'Y'],
  ['ỵ', 'y'],
  ['Ỵ', 'Y'],
  ['ỷ', 'y'],
  ['Ỷ', 'Y'],
  ['ỹ', 'y'],
  ['Ỹ', 'Y'],

  // Arabic
  ['ء', 'e'],
  ['آ', 'a'],
  ['أ', 'a'],
  ['ؤ', 'w'],
  ['إ', 'i'],
  ['ئ', 'y'],
  ['ا', 'a'],
  ['ب', 'b'],
  ['ة', 't'],
  ['ت', 't'],
  ['ث', 'th'],
  ['ج', 'j'],
  ['ح', 'h'],
  ['خ', 'kh'],
  ['د', 'd'],
  ['ذ', 'dh'],
  ['ر', 'r'],
  ['ز', 'z'],
  ['س', 's'],
  ['ش', 'sh'],
  ['ص', 's'],
  ['ض', 'd'],
  ['ط', 't'],
  ['ظ', 'z'],
  ['ع', 'e'],
  ['غ', 'gh'],
  ['ـ', '_'],
  ['ف', 'f'],
  ['ق', 'q'],
  ['ك', 'k'],
  ['ل', 'l'],
  ['م', 'm'],
  ['ن', 'n'],
  ['ه', 'h'],
  ['و', 'w'],
  ['ى', 'a'],
  ['ي', 'y'],
  ['َ‎', 'a'],
  ['ُ', 'u'],
  ['ِ‎', 'i'],
  ['٠', '0'],
  ['١', '1'],
  ['٢', '2'],
  ['٣', '3'],
  ['٤', '4'],
  ['٥', '5'],
  ['٦', '6'],
  ['٧', '7'],
  ['٨', '8'],
  ['٩', '9'],

  // Persian / Farsi
  ['چ', 'ch'],
  ['ک', 'k'],
  ['گ', 'g'],
  ['پ', 'p'],
  ['ژ', 'zh'],
  ['ی', 'y'],
  ['۰', '0'],
  ['۱', '1'],
  ['۲', '2'],
  ['۳', '3'],
  ['۴', '4'],
  ['۵', '5'],
  ['۶', '6'],
  ['۷', '7'],
  ['۸', '8'],
  ['۹', '9'],

  // Pashto
  ['ټ', 'p'],
  ['ځ', 'z'],
  ['څ', 'c'],
  ['ډ', 'd'],
  ['ﺫ', 'd'],
  ['ﺭ', 'r'],
  ['ړ', 'r'],
  ['ﺯ', 'z'],
  ['ږ', 'g'],
  ['ښ', 'x'],
  ['ګ', 'g'],
  ['ڼ', 'n'],
  ['ۀ', 'e'],
  ['ې', 'e'],
  ['ۍ', 'ai'],

  // Urdu
  ['ٹ', 't'],
  ['ڈ', 'd'],
  ['ڑ', 'r'],
  ['ں', 'n'],
  ['ہ', 'h'],
  ['ھ', 'h'],
  ['ے', 'e'],

  // Russian
  ['А', 'A'],
  ['а', 'a'],
  ['Б', 'B'],
  ['б', 'b'],
  ['В', 'V'],
  ['в', 'v'],
  ['Г', 'G'],
  ['г', 'g'],
  ['Д', 'D'],
  ['д', 'd'],
  ['Е', 'E'],
  ['е', 'e'],
  ['Ж', 'Zh'],
  ['ж', 'zh'],
  ['З', 'Z'],
  ['з', 'z'],
  ['И', 'I'],
  ['и', 'i'],
  ['Й', 'J'],
  ['й', 'j'],
  ['К', 'K'],
  ['к', 'k'],
  ['Л', 'L'],
  ['л', 'l'],
  ['М', 'M'],
  ['м', 'm'],
  ['Н', 'N'],
  ['н', 'n'],
  ['О', 'O'],
  ['о', 'o'],
  ['П', 'P'],
  ['п', 'p'],
  ['Р', 'R'],
  ['р', 'r'],
  ['С', 'S'],
  ['с', 's'],
  ['Т', 'T'],
  ['т', 't'],
  ['У', 'U'],
  ['у', 'u'],
  ['Ф', 'F'],
  ['ф', 'f'],
  ['Х', 'H'],
  ['х', 'h'],
  ['Ц', 'Cz'],
  ['ц', 'cz'],
  ['Ч', 'Ch'],
  ['ч', 'ch'],
  ['Ш', 'Sh'],
  ['ш', 'sh'],
  ['Щ', 'Shh'],
  ['щ', 'shh'],
  ['Ъ', ''],
  ['ъ', ''],
  ['Ы', 'Y'],
  ['ы', 'y'],
  ['Ь', ''],
  ['ь', ''],
  ['Э', 'E'],
  ['э', 'e'],
  ['Ю', 'Yu'],
  ['ю', 'yu'],
  ['Я', 'Ya'],
  ['я', 'ya'],
  ['Ё', 'Yo'],
  ['ё', 'yo'],

  // Romanian
  ['ș', 's'],
  ['Ș', 's'],
  ['ț', 't'],
  ['Ț', 't'],

  // Turkish
  ['ş', 's'],
  ['Ş', 's'],
  ['ç', 'c'],
  ['Ç', 'c'],
  ['ğ', 'g'],
  ['Ğ', 'g'],
  ['ı', 'i'],
  ['İ', 'i']
];

const matchOperatorsRegex = /[|\\{}()[\]^$+*?.-]/g;

const decamelize = string => {
  return string
    .replace(/([a-z\d])([A-Z])/g, '$1 $2')
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1 $2');
};

const escapeStringRegexp = string => {
  if (typeof string !== 'string') {
    throw new TypeError('Expected a string');
  }

  return string.replace(matchOperatorsRegex, '\\$&');
};

const doCustomReplacements = (string, r: any) => {
  for (const [key, value] of r) {
    string = string.replace(new RegExp(escapeStringRegexp(key), 'g'), value);
  }

  return string;
};

const removeMootSeparators = (string: string, separator: string) => {
  return string
    .replace(new RegExp(`${separator}{2,}`, 'g'), separator)
    .replace(new RegExp(`^${separator}|${separator}$`, 'g'), '');
};

export const slugify = (string, options?: any) => {
  if (typeof string !== 'string') {
    throw new TypeError(`Expected a string, got \`${typeof string}\``);
  }

  options = {
    separator: '-',
    lowercase: true,
    decamelize: true,
    customReplacements: [],
    ...options
  };

  const separator = escapeStringRegexp(options.separator);

  string = doCustomReplacements(string, replacements);
  string = deburr(string);
  string = string.normalize('NFKD');

  if (options.decamelize) {
    string = decamelize(string);
  }

  let patternSlug = /[^a-zA-Z\d]+/g;

  if (options.lowercase) {
    string = string.toLowerCase();
    patternSlug = /[^a-z\d]+/g;
  }

  string = string.replace(patternSlug, separator);
  string = string.replace(/\\/g, '');
  string = removeMootSeparators(string, separator);

  return string;
};
