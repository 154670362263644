<div class="login-form-container">
  <div class="form-container">
    <form class="reset-password-form" (submit)="resetPassword()" [formGroup]="resetPasswordForm"
          #resetPasswordFormForm="ngForm"
          novalidate>
      <div class="logo pointer" (click)="goToLanding()"></div>

      <ng-template [ngIf]="!resetPasswordSubmitted">
        <h1>{{ 'resetPassword.resetTitle' | translate }}</h1>
        <app-alerts></app-alerts>
        <div class="form-group" [ngClass]="{'filled': resetPasswordForm.value.email, 'error': errorsList.email}">
          <input appAutofocus
                 [skip]="resetPasswordForm.value.email"
                 type="email"
                 class="form-control"
                 formControlName="email"
                 autocomplete="off"
                 [placeholder]="'email.self' | translate"
                 (blur)="handleFieldBlur('resetPasswordForm', 'email')"
                 required />
          <span class="floating-label">{{ 'email.self' | translate }}</span>

          <span *ngIf="resetPasswordForm.get('email').hasError('required')" class="form-group-error">
            {{ 'email.error.blank' | translate }}
          </span>
          <span *ngIf="resetPasswordForm.get('email').hasError('pattern')" class="form-group-error">
            {{ 'email.error.valid' | translate:{email: resetPasswordForm.value.email} }}
          </span>
        </div>

        <div class="buttons-container">
          <button class="btn btn-primary" type="submit" [disabled]="isLoading">
            <span>{{ 'resetPassword.sendInstructions' | translate }}</span>
          </button>
          <a class="go-to-login" routerLink="/login">‹ {{ 'resetPassword.backSignIn' | translate }}</a>
        </div>
      </ng-template>

      <ng-template [ngIf]="resetPasswordSubmitted">
        <h1>{{ 'resetPassword.backLogInDesc' | translate }}</h1>
        <div class="buttons-container">
          <a class="btn btn-primary" routerLink="/login">{{ 'resetPassword.backLogIn' | translate }}</a>
        </div>
      </ng-template>
    </form>
  </div>
  <div class="bottom-bg"></div>
</div>
