import { Injectable } from '@angular/core';
import { TagsApiService } from '@app/core/api/api.tags';
import { FeatureAccessService } from '@app/core/feature-access.service';
import { ProjectService } from '@app/core/project.service';
import { TariffService } from '@app/core/tariff.service';
import { UtilsService } from '@app/core/utils.service';
import { WebsocketsService, WsEvent } from '@app/core/websockets.service';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

export interface Tag {
  id: string;
  tag: string;
  color: string;
  projectId: string;
  description: string;
}

export type CreatableTag = Pick<Tag, 'tag' | 'color' | 'description'>;

@Injectable({
  providedIn: 'root',
})
export class TagsService {

  private _tagList = new BehaviorSubject<Tag[]>([]);

  public isTagsAvailable = new BehaviorSubject(false);

  get tagList$(): Observable<Tag[]> {
    return this._tagList.asObservable();
  }

  constructor(
    private api: TagsApiService,
    private projectService: ProjectService,
    private ws: WebsocketsService,
    private utils: UtilsService,
    private featureAccessService: FeatureAccessService,
    private tariffService: TariffService,
  ) {
    this.projectService.getProjects()
      .subscribe(() => {
        this.updateTagList();
        this.checkTagAvailable();
      });
    this.socketEvents();
  }

  updateTagList() {
    if (!this.projectService.getCurrentProject()) {
      return;
    }
    this.api.getListTags()
      .subscribe((response) => {
        if (response.success) {
          this._tagList.next(response.data);
        }
      });
  }

  checkTagAvailable() {
    // Из-за криво написанного метода getAccessStatus приходиться делать вот такую проверку.
    // Если ее не делать, то в момент логина/смены проекта внутри параметры paid и active равны undefined т к не успевают подгрузиться
    combineLatest([
      this.tariffService.getPaidState(),
      this.tariffService.getActiveState(),
      this.tariffService.tariffs$
    ])
      .pipe(
        filter(val => val.every(value => value !== undefined)),
        switchMap(() => this.featureAccessService.getAccessStatus('tags')),
      )
      .subscribe((hasAccess) => {
        this.isTagsAvailable.next(hasAccess);
      });
  }

  socketEvents() {
    this.ws.on(WsEvent.TAG_CREATED, (_data: Tag) => {
      const data = this.utils.camelKeys(_data);
      if (data.projectId !== this.projectService.currentProject.id) {
        return;
      }

      this.addTag(data);
    });

    this.ws.on(WsEvent.TAG_UPDATED, (_data: Tag) => {
      const data = this.utils.camelKeys(_data);
      if (data.projectId !== this.projectService.currentProject.id) {
        return;
      }

      this.updateTagInList(data);
    });

    this.ws.on(WsEvent.TAG_DELETED, (_data: any) => {
      const data = this.utils.camelKeys(_data);
      if (data.projectId !== this.projectService.currentProject.id) {
        return;
      }

      this.removeTag(data.id);
    });
  }

  updateTagOnServer(tagId: string, tag: CreatableTag) {
    return this.api.updateTag(tagId, tag)
      .pipe(
        tap((result) => {
          if (result.success) {
            this.updateTagInList({
              id: tagId,
              projectId: this.projectService.currentProject.id,
              ...tag,
            });
          }
        }),
      );
  }

  updateTagInList(updatedTag: Tag) {
    let tagList = this._tagList.getValue();

    tagList = tagList.map((tag) => {
      if (tag.id === updatedTag.id) {
        return { ...tag, ...updatedTag };
      }

      return tag;
    });

    this._tagList.next(tagList);
  }

  addTag(newTag: Tag) {
    let tagList = this._tagList.getValue();
    tagList.push(newTag);
    this._tagList.next(tagList);
  }

  removeTag(removedTagId: string) {
    let tagList = this._tagList.getValue();
    tagList.filter(tag => tag.id !== removedTagId);
    this._tagList.next(tagList);
  }

  mapTagIdToTags(tagIdList: string[]) {
    return this.tagList$.pipe(
      filter(tagList => !!tagList.length),
      map((tagList) => {
        return tagIdList.map((tagId) => tagList.find(tag => tag.id === tagId));
      }),
    );
  }

}
