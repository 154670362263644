import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Duration, Interval } from 'luxon';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  private documentIsAccessible: boolean;

  constructor(
    private deviceDetector: DeviceDetectorService,
  ) {
    this.documentIsAccessible = window.document !== undefined;
  }

  check(name: string): boolean {
    if (!this.documentIsAccessible) {
      return false;
    }

    name = encodeURIComponent(name);

    const regExp: RegExp = this.getCookieRegExp(name);
    const exists: boolean = regExp.test(window.document.cookie);

    return exists;
  }

  get(name: string): string {
    if (this.documentIsAccessible && this.check(name)) {
      name = encodeURIComponent(name);

      const regExp: RegExp = this.getCookieRegExp(name);
      const result: RegExpExecArray = regExp.exec(window.document.cookie);

      return decodeURIComponent(result[1]);
    } else {
      return '';
    }
  }

  getAll(): any {
    if (!this.documentIsAccessible) {
      return {};
    }

    const cookies: any = {};

    if (window.document.cookie && document.cookie !== '') {
      const split: Array<string> = window.document.cookie.split(';');

      for (let i = 0; i < split.length; i += 1) {
        const currentCookie: Array<string> = split[i].split('=');

        currentCookie[0] = currentCookie[0].replace(/^ /, '');
        cookies[decodeURIComponent(currentCookie[0])] = decodeURIComponent(currentCookie[1]);
      }
    }

    return cookies;
  }

  set(
    name: string,
    value: string,
    expires?: number | Date,
    path?: string,
    domain?: string,
    secure?: boolean
  ): void {
    if (!this.documentIsAccessible) {
      return;
    }

    const deviceInfo = this.deviceDetector.getDeviceInfo();
    let cookieString: string = encodeURIComponent(name) + '=' + encodeURIComponent(value) + ';';

    if (expires) {
      if (typeof expires === 'number') {
        const dateExpires: Date = new Date(new Date().getTime() + expires * 1000 * 60 * 60 * 24);

        cookieString += 'expires=' + dateExpires.toUTCString() + ';';
      } else {
        cookieString += 'expires=' + expires.toUTCString() + ';';
      }
    }

    if (path) {
      cookieString += 'path=' + path + ';';
    }

    // task:1018 Stepan! please use another solution
    // if (domain && ['ie', 'ms-edge'].includes(deviceInfo.browser)) {
    if (domain) {
      cookieString += 'domain=' + domain + ';';
    }

    if (secure) {
      cookieString += 'secure;';
    }

    window.document.cookie = cookieString;
  }

  delete(name: string, path?: string, domain?: string): void {
    if (!this.documentIsAccessible) {
      return;
    }

    this.set(name, '', -1, path, domain);
  }

  /**
   * @param path   Cookie path
   * @param domain Cookie domain
   */
  deleteAll(path?: string, domain?: string): void {
    if (!this.documentIsAccessible) {
      return;
    }

    const cookies: any = this.getAll();

    for (const cookieName in cookies) {
      if (cookies.hasOwnProperty(cookieName)) {
        this.delete(cookieName, path, domain);
      }
    }
  }

  private getCookieRegExp(name: string): RegExp {
    const escapedName: string = name.replace(/([\[\]\{\}\(\)\|\=\;\+\?\,\.\*\^\$])/ig, '\\$1');

    return new RegExp('(?:^' + escapedName + '|;\\s*' + escapedName + ')=(.*?)(?:;|$)', 'g');
  }

  public setPromoCode(promo: string) {
    const interval = Interval.after(new Date(), Duration.fromObject({ hours: 12 })).toDuration('days')
      .toObject().days;
    this.set('promo', promo, interval, '/', environment.cookiesOrigin);
  }
}
