import { Injectable } from '@angular/core';
import { Permission } from '@app/core/permissions.service';
import { Observable } from 'rxjs';
import { ApiService } from '@app/core/api/api.service';
import { routes } from '@app/core/config/apiRoutes';
import { Response } from '@app/core/interfaces/api.response';

export enum UserStatus {
  // только почту ввел
  Registered = 10,
  // ввел пароль и название проекта
  Confirmed = 15,
  // ввел имя, номер телефона и инфу о проекте
  Full = 20,
}

export interface UserData {
  id: string;
  name: string;
  avatar?: boolean | string;
  avatarDefault?: boolean | string;
  login: string;
  online: boolean;
  companyName?: string | null;
  companyRole?: string | null;
  companyScope?: string | null;
  companySize?: string | null;
  email?: string | null;
  language?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  phone?: string | null;
  timezone?: string | null;
  roles?: Array<any>;
  permissions?: Array<Permission>;
  status?: UserStatus;
  hasExternalAuth?: boolean;
}

export type UserResponse = Response<UserData>;

@Injectable({
  providedIn: 'root',
})
export class ProfileApiService {
  private routes = routes;
  constructor(
    private api: ApiService,
  ) {

  }

  public confirmEmailChange(data: any): Observable<any> {
    return this.api.httpPost(this.routes.confirmPassword, data, true);
  }

  public getUser(): Observable<UserResponse> {
    return this.api.httpGet(this.routes.profile);
  }
}
