import { Injectable, NgZone } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiService } from '@app/core/api/api.service';
import { Response } from '@app/core/interfaces/api.response';
import { routes } from '@app/core/config/apiRoutes';
import { HttpClient } from '@angular/common/http';
import each from 'lodash-es/each';
import { UtilsService } from '@app/core/utils.service';

export interface TariffManage {
  options: {
    operators?: number | string;
    groups?: number | string;
    'online-chat?'?: number | string;
    whatsapp?: number | string;
    'telegram-bot'?: number | string;
    'telegram'?: number | string;
    vk?: number | string;
    vk_direct?: number | string;
    facebook?: number | string;
    viber?: number | string;
    'avito'?: number | string;
    email?: number | string;
    instagram?: number | string;
    statistic?: number | string;
    'messages-templates'?: number | string;
    'amo-crm'?: number | string;
    'telegram-notifier'?: number | string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class TariffApiService {
  private routes = routes;
  constructor(
    private api: ApiService,
    private zone: NgZone,
    private http: HttpClient,
    private utils: UtilsService,
  ) {

  }

  public getPublicTariffView(): Observable<Response> {
    return this.zone.runOutsideAngular(() => {
      const _headers = this.api.getHeader();
      return this.http.get(this.routes.publicTariffView, { headers: _headers })
        .pipe(
          map((body: any) => {
            body = this.utils.camelKeys(body);

            body.data.active = false;
            body.data.dailyPayment = 0;
            body.data.balance = {
              total: 0
            };
            body.data.paid = false;

            body.data.options = body.data.price;

            each(body.data.options, (v, i) => {
              body.data.options[i].count = 0;
            });

            return body;
          }),
          catchError((e) => {
            return of(this.api.errorPrefix + 'tariff view :-( ' + e);
          })
        );
    });
  }

  public getTariffView(): Observable<Response> {
    return this.api.httpGet(this.routes.tariffView, true);
  }

  public getTariffBilling(page: number, itemsPerPage: number, type: string[]): Observable<Response> {
    return this.api.httpGet(this.routes.tariffBilling(page, itemsPerPage, type));
  }

  public postTariffManage(data: TariffManage): Observable<Response> {
    return this.api.httpPost(this.routes.tariffManage, data);
  }

  public bayTariffPackage(packageCode: number): Observable<Response> {
    return this.api.httpPostNew(this.routes.buyPackage, { packageCode }, { snakeCaseIsRequired: true });
  }
}
