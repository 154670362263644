import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Message, Person } from '@app/core/interfaces/message';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DateTime } from 'luxon';
import { I18nService } from '@app/core';
import { UserService } from '@app/core/user.service';
import { ChannelService } from '@app/core/channel.service';
import { AttachmentsService } from '@app/core/attachments.service';
import { UtilsService } from '@app/core/utils.service';

interface FromToData {
  name: string;
  email: string;
}

@Component({
  selector: 'app-message-email',
  templateUrl: './message-email.component.html',
  styleUrls: ['./message-email.component.scss'],
})
export class MessageEmailComponent implements OnInit, OnChanges, OnDestroy {
  @Input() _message: Message;
  @Input() _quote: Message;
  @Input() person: Person;
  @Input() selectedChannel: string;
  @Input() last: boolean;
  @Input() ownMessage = false;

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() showPreviewer: EventEmitter<string> = new EventEmitter();

  private unsub$ = new Subject<void>();
  private dateToken = 'd LLL, HH:mm';
  private dateTokenFull = 'ffff';

  public message: Message;
  public quote: Message;
  public displayMessage: string;
  public displayQuote: string;
  public dateCreated: string;
  public dateCreatedFull: string;
  public dateCreatedQuote: string;
  public dataShow = false;
  public fromPerson: FromToData;
  public toPerson: FromToData;
  public imageWidth = 0;
  public imageHeight = 0;
  public maximized = false;
  public showQuote = false;

  constructor(
    private sanitizer: DomSanitizer,
    private lang: I18nService,
    private userService: UserService,
    private channelService: ChannelService,
    private zone: NgZone,
    private attachmentsService: AttachmentsService,
    private detector: ChangeDetectorRef,
    private utils: UtilsService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.person && changes.person.currentValue && this.message &&
      changes.person.previousValue !== changes.person.currentValue) {
      this.setMailData(changes.person.currentValue);
    }

    if ((changes.message$ && changes.message$.previousValue !== changes.message$.currentValue) ||
      this.attachmentsService.hasAttachments()) {
      this.init();
    }
  }

  ngOnDestroy(): void {
    this.unsub$.next();
    this.unsub$.complete();
  }

  ngOnInit(): void {
    this.showQuote = this.last;
    this.init();
  }

  init(): void {
    if (this._message) {
      const date = this.prepareDate(this._message.date.date);

      this.setMessage(this._message);
      this.dateCreated = date.toFormat(this.dateToken);
      this.dateCreatedFull = date.toFormat(this.dateTokenFull);

      if (!this.fromPerson && this.person) {
        this.setMailData(this.person);
      }
    }

    if (this._quote) {
      this.setQuote(this._quote);
    }

    if (this.ownMessage) {
      this.attachmentsService.state$
        .pipe(takeUntil(this.unsub$))
        .subscribe(_attachments => {
          const {
            attachments,
            staticAttachments,
          } = _attachments;

          this.message.isOwn = true;
          this.message.attachments = [...attachments, ...staticAttachments];
          this.detector.detectChanges();
        });
    }
  }

  setMessage(message: Message) {
    this.message = message;

    if (this.message.message === undefined) {
      return;
    }

    if (this.message.messageType === 'image' || this.message.messageType === 'gif') {
      this.adjustImageSize(this.message.width, this.message.height);
    }

    this.displayMessage = this.prepareMessage(message);
  }

  setQuote(message: Message) {
    const date = this.prepareDate(message.date.date);

    this.quote = message;
    this.displayQuote = this.prepareMessage(message);
    this.dateCreatedQuote = date.toFormat(this.dateTokenFull);
  }

  prepareDate(date: string): any {
    return DateTime.fromISO(date).setLocale(this.lang.language);
  }

  prepareMessage(message: Message): string {
    if (!message.message) {
      return '';
    }

    let displayMessage = message.message;

    if (!this.ownMessage) {
      displayMessage = this.utils.parseMessage(message.message, message.emojiOnly);
    }

    if (!message.emojiOnly) {
      displayMessage = displayMessage.replace(/\n/g, '<br/>');
    }

    return displayMessage;
  }

  bypassHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  setMailData(person: Person) {
    const { user } = this.userService;
    const { email: operatorEmail } = this.getChannelData();
    const operator = {
      name: user.fullName,
      email: operatorEmail,
    };
    const client = {
      name: person.name,
      email: person.email,
    };

    this.fromPerson = this.message.isOwn ? operator : client;
    this.toPerson = this.message.isOwn ? client : operator;
  }

  getChannelData() {
    return this.channelService.channels.find(item => item.id === this.selectedChannel);
  }

  cancelUpload(): void {
    this.cancel.emit(this.message.attachmentId);
  }

  openPreviewer(url: string) {
    this.showPreviewer.emit(url);
    return false;
  }

  adjustImageSize(width: number, height: number): void {
    this.zone.runOutsideAngular(() => {
      const ratio = height / width;

      if (width > 210) {
        width = 210;
        height = width * ratio;
      }

      this.imageWidth = width;
      this.imageHeight = height;
    });
  }

  toggleMaximized(): void {
    if (this.last) {
      return;
    }
    this.maximized = !this.maximized;

    if (!this.maximized) {
      this.dataShow = false;
    }
  }

  toggleEmailDataMaximized(): void {
    if (!this.maximized) {
      this.maximized = true;
    }
    this.dataShow = !this.dataShow;
  }

  shortcutName(name: string): string {
    if (name.length < 15) {
      return name;
    }

    return `${name.substr(0, 6)}...${name.substr(-5)}`;
  }

  getFileSize(size: number): string {
    return this.utils.formatSize(size);
  }

  deleteAttachment(id: string) {
    this.attachmentsService.removeFile(id);
  }

  getExtensionOfFilename(name: string): string {
    return name.slice(name.lastIndexOf('.') + 1);
  }
}
