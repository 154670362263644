<div class="login-form-container">
  <div class="form-container register-container">
    <form class="register-form"
          [formGroup]="completeRegistrationForm" #registerFormForm="ngForm" novalidate>
      <div class="logo pointer" (click)="goToLanding()"></div>
      <h1 [innerHTML]="'newAccountSetup.setupYourAccount' | translate"></h1>
      <app-alerts></app-alerts>

      <ng-container *ngIf="!isExteranalAuth">
        <label class="form-label">{{ 'newAccountSetup.setYourPassword' | translate }}</label>

        <div class="form-group"
             [ngClass]="{'filled': completeRegistrationForm.value.password, 'error': errorsList.password}">
          <input type="password"
                 class="form-control"
                 formControlName="password"
                 autocomplete="off"
                 [placeholder]="'password.self' | translate"
                 (blur)="handleFieldBlur('password'); sendAnalyticsEvent('PROJECT_PASSWORD')"
                 [reliability]="true"
                 required />
          <span class="floating-label">{{ 'password.self' | translate }}</span>

          <span *ngIf="completeRegistrationForm.get('password').hasError('required')" class="form-group-error">
          {{ 'password.error.blank' | translate }}
        </span>
          <span *ngIf="completeRegistrationForm.get('password').hasError('notEqual')" class="form-group-error">
          {{ 'password.error.match' | translate }}
        </span>
          <span *ngIf="completeRegistrationForm.get('password').hasError('pattern')" class="form-group-error">
          {{ 'password.error.valid' | translate }}
        </span>
        </div>

        <div class="form-group"
             [ngClass]="{'filled': completeRegistrationForm.value.passwordRepeat, 'error': errorsList.passwordRepeat}">
          <input type="password"
                 class="form-control"
                 formControlName="passwordRepeat"
                 autocomplete="off"
                 [placeholder]="'password.confirm' | translate"
                 (blur)="handleFieldBlur('passwordRepeat'); sendAnalyticsEvent('PROJECT_PASSWORD_REPEAT')"
                 required />
          <span class="floating-label">{{ 'password.confirm' | translate }}</span>
          <span *ngIf="completeRegistrationForm.get('passwordRepeat').hasError('required')" class="form-group-error">
          {{ 'password.error.blankConfirm' | translate }}
        </span>
          <span *ngIf="completeRegistrationForm.get('passwordRepeat').hasError('notEqual')" class="form-group-error">
          {{ 'password.error.match' | translate }}
        </span>
        </div>
      </ng-container>

      <label class="form-label">{{ 'newAccountSetup.projectName' | translate }}</label>
      <div class="form-group"
           [ngClass]="{'filled': completeRegistrationForm.value.projectName, 'error': errorsList.projectName}">
        <input type="text" class="ym-record-keys form-control" formControlName="projectName" autocomplete="off"
               [placeholder]="'newAccountSetup.myProject' | translate"
               (keyup)="setProjectUrl()"
               (blur)="handleFieldBlur('projectName'); sendAnalyticsEvent('PROJECT_NAME')" required />
        <span class="floating-label">{{ 'newAccountSetup.myProject' | translate }}</span>
        <span *ngIf="completeRegistrationForm.get('projectName').hasError('required')"
              class="form-group-error">
          {{ 'error.projectName' | translate }}
        </span>
        <span *ngIf="completeRegistrationForm.get('projectName').hasError('maxlength')"
              class="form-group-error">
          {{ 'error.projectName' | translate }}
        </span>
      </div>
      <ng-template [ngIf]="isAdmin">
        <label class="form-label">{{ 'newAccountSetup.panelAddress' | translate }}</label>
        <div class="form-group" [ngClass]="{'error': errorsList.projectDomain}">
          <app-ghostinput placeholder="projectname" suffix="hostname"
                          [content]="completeRegistrationForm.get('projectDomain').value">
            <input type="text"
                   class="ym-record-keys form-control"
                   formControlName="projectDomain"
                   autocomplete="off"
                   (keyup)="addressChanged = true"
                   (ngModelChange)="handleChange($event)"
                   (blur)="setProjectUrl(); handleFieldBlur('projectDomain')"
                   [maxlength]="20"
                   required />
          </app-ghostinput>

          <span *ngIf="completeRegistrationForm.get('projectDomain').hasError('required')" class="form-group-error">
            {{ 'domain.error.blank' | translate }}
          </span>
          <span *ngIf="completeRegistrationForm.get('projectDomain').hasError('pattern')" class="form-group-error">
            {{ 'domain.error.pattern' | translate }}
          </span>
          <span *ngIf="completeRegistrationForm.get('projectDomain').hasError('backend')" class="form-group-error">
            {{ inputError }}
          </span>
        </div>
      </ng-template>

      <div *ngIf="isAdmin && !isExteranalAuth" class="promo-code-wrapper">
        <a (click)="togglePromoCodeContainer()">{{ 'newAccountSetup.haveAPromoCode' | translate }}</a>
        <div class="form-group"
             [ngClass]="{
              'hidden': !promoVisible,
              'filled': completeRegistrationForm?.value?.promo,
              'error': errorsList?.promo
              }">
          <input appAutofocus
                 [skip]="completeRegistrationForm?.value?.promo"
                 type="email"
                 class="ym-record-keys form-control"
                 formControlName="promo"
                 autocomplete="off"
                 [placeholder]="'newAccountSetup.promoCode' | translate"
                 (blur)="handleFieldBlur('promo')" />
          <span *ngIf="completeRegistrationForm.get('promo').hasError('pattern')" class="form-group-error">
            {{ 'error.promoCode' | translate }}
          </span>
          <span class="floating-label">{{ 'newAccountSetup.promoCode' | translate }}</span>
        </div>
      </div>

      <div class="buttons-container">
        <button class="btn btn-primary with-spinner"
                type="button"
                [disabled]="isLoading"
                (click)="completeRegistration()">
          <app-spinner [inProgress]="isLoading" [btnText]="'button.continue' | translate"></app-spinner>
        </button>
      </div>
    </form>
  </div>
  <div class="bottom-bg"></div>
</div>
