<ng-scrollbar class="scroll-container"
     *ngIf="selectedPost">
  <div class="person-info">
    <div class="person-details">
      <img *ngIf="preview" class="preview" [src]="preview"/>
      <div class="info-wrapper">
        <div class="caption" [class.open]="collapse">
          <span class="name">{{ userName }}</span>
          {{ caption }}
        </div>
        <div class="date">{{ date }}</div>
        <div class="collapse"
             *ngIf="caption && caption.length > 30"
             (click)="collapse = !collapse"
        >{{ (!collapse && 'instagramDirectView.showPost' || 'instagramDirectView.hidePost') | translate }}</div>
      </div>
    </div>
  </div>

</ng-scrollbar>

