<div class="message-inner thread message-type-{{ messageType || 'text' }}"
     [ngClass]="messageClass"
     [class.note-type]="message?.type === 20"
     [class.zindex]="zindex"
     [class.child]="isChild"
      *ngIf="message?.messageType !== 'internal' && message?.type !== 30  && message?.type !== 40"
     #messageContainer>
  <div class="teletype-status-icon teletype-status-icon-{{ messageStatus }}">
    <div dropdown (onShown)="onShown()" (onHidden)="onHidden()" class="dropdown"
         *ngIf="this.message?.isOwn && environment.features?.messageEdit">
      <button class="btn btn-transparent no-hover project-select"  id="projectDropdown" dropdownToggle>
        <div class="tooltip-message" role="tooltip" tabindex="0">
          <i class="teletype-icon-options"></i>
          <span>{{'conversation.editOrDelete' | translate}}</span>
        </div>
      </button>
      <div *dropdownMenu class="dropdown-menu" aria-labelledby="projectDropdown">
        <div class="dropdown-item" (click)="editMessage($event)">
          <i class="teletype-icon-edit-message"></i> {{'button.edit' | translate}}
        </div>
        <div class="dropdown-item">
          <i class="teletype-icon-delete-message"></i> {{'conversation.delete' | translate}}
        </div>
      </div>
    </div>
    <i class="teletype-icon-sending" *ngIf="messageStatus && messageStatus.toString() === '10'"></i>
    <i class="teletype-icon-failed" (click)="retrySendMessage()" *ngIf="messageStatus && messageStatus === '20'"></i>
  </div>
  <div class="message-avatar">
    <app-avatar [avatar]="avatar"
                [animal]="message?.from?.animal || ''">
    </app-avatar>
  </div>
  <div class="message">
    <div *ngIf="message?.errorMessage" class="error">
      {{ message?.errorMessage | translate }}
    </div>
    <ng-template [ngIf]="messageType === 'typing'">
      <span *ngIf="message?.message">{{ message?.message }}</span>
      <div class="loading">
        <div class="dot one"></div>
        <div class="dot two"></div>
        <div class="dot three"></div>
      </div>
    </ng-template>
    <ng-template [ngIf]="(message?.messageType === 'attachment' || message?.messageType === 'video') && message?.attachmentUrl">
      <a [href]="message?.attachmentUrl" [download]="message?.attachment" class="download-link"></a>
    </ng-template>
    <ng-template [ngIf]="messageType === 'image' || messageType === 'gif'" *ngIf="!message?.errorMessage">
        <div class="image-message-container">
            <div class="progressbar" *ngIf="message?.progress < 100">
              <app-progress [class]="'progress-for-image'"
                            [value]="message?.progress"
                            (cancel)="cancelUpload()"
                            [cancelUpload]="true"
                            [size]="44"
                            [strokeColor]="'#fff'">
              </app-progress>
            </div>
          <img (click)="message?.messageType === 'image' && openPreviewer(message?.attachmentUrl ||
                  message?.attachmentPreviewUrl)"
               [ngSrc]="message?.attachmentPreviewUrl || message?.attachmentUrl"
               [width]="imageWidth || 0"
               [height]="imageHeight || 0"
               [alt]="message?.attachment" />
        </div>
    </ng-template>
    <ng-template [ngIf]="messageType === 'attachment' || messageType === 'video' || messageType === 'audio'">
        <div class="attachment-message-content">
          <ng-template [ngIf]="messageType !== 'audio'">
            <div class="progressbar" *ngIf="!message?.errorMessage && message?.progress < 100">
              <app-progress [value]="message?.progress" (cancel)="cancelUpload()" [cancelUpload]="true" [strokeColor]="'#fff'"></app-progress>
            </div>
          </ng-template>
          <ng-template [ngIf]="messageType === 'audio'">
            <div class="audio-progress-container" *ngIf="!message.errorMessage && message?.progress < 100">
              <button (click)="cancelUpload()" class="audio-cancel-upload"></button>
              <div class="audio-progressbar" [style.width.%]="message?.progress"></div>
            </div>
          </ng-template>
          <div class="attachment-icon" [ngClass]="{'playing': playing}" *ngIf="(message?.messageType === 'attachment' ||
            message?.messageType === 'video') && (message?.progress === 100 ||
            message?.progress === undefined) && message?.attachmentUrl && message?.attachmentId">
            <i class="teletype-icon-file"></i>
          </div>
          <button (click)="toggleAudioMessageState()" class="play-pause-icon" [ngClass]="{'playing': playing}" *ngIf="message?.messageType === 'audio' &&
          (message?.progress === 100 || message?.progress === undefined)"></button>
          <div class="attachment-name-wrapper"
               [ngClass]="{'with-download-block': (message?.messageType === 'attachment' ||
               message?.messageType === 'video') && (message?.progress === 100 || message?.progress === undefined) &&
               message?.attachmentUrl && message?.attachmentId}">
            <div class="attachment-name" *ngIf="message?.messageType !== 'audio'">
              {{ message?.attachment }}
            </div>
            <div class="attachment-size" *ngIf="(message?.messageType === 'attachment' ||
              message?.messageType === 'video') && (message?.progress === 100 ||
              message?.progress === undefined) && message?.attachmentUrl && message?.attachmentId">
              {{ size }} - <a href="#">{{ 'Download' | translate }}</a>
            </div>
            <div class="audio-progress" *ngIf="message?.messageType === 'audio' &&
            (message?.progress === 100 || message?.progress === undefined)">
              <span class="passed-line" [style.width.%]="percentDuration"></span>
              <span class="position-dot" [style.left.%]="percentDuration"></span>
            </div>
          </div>
          <span *ngIf="message?.messageType === 'audio' &&
          (message?.progress === 100 || message?.progress === undefined)" class="audio-duration">{{ duration }}</span>
        </div>
    </ng-template>
    <div class="messages-wrapper">
      <div *ngIf="messageType === 'text' || messageType === undefined && message?.formType === undefined"
           #messageInputEdit
           tabindex="1"
           class="teletype-new-message-input-container"
           [contentEditable]="contentEditable"
           (keydown.enter)="sendMessage()"
           (keydown.esc)="restartMessage()"
           [defaultValue]="displayMessage"
           [prefix]="message?.from?.name"
           appTeletypeContentEditable></div>
    </div>
    <button *ngIf="contentEditable"
          type="button"
          (click)="onEmojiClick()"
          class="emoji-button">
    <i class="teletype-icon-emoji"></i>
  </button>
    <app-teletype-emoji-picker
    *ngIf="contentEditable"
    (selectEmoji)="closePicker() && handleSelectEmoji($event)"
    (closePicker)="closePicker()"
    [ngClass]="{'teletype-visible': emojiPickerVisible}"
    [pickerVisible]="emojiPickerVisible"></app-teletype-emoji-picker>
    </div>
  <span class="typing-text" *ngIf="messageType === 'typing'">{{ 'conversation.typing' | translate }}</span>
  <span class="typing-text" *ngIf="contentEditable">{{'conversation.confirmOrCancel' | translate}}</span>
  <div class="message-footer">
    <app-message-date *ngIf="message?.date && message?.type !== 20 && !message?.errorMessage && !contentEditable"
                      class="message-date"
                      [date]="message?.date.date"
                      [status]="message?.isOwn ? messageStatus : undefined"
                      [seen]="message?.isOwn ? message?.seen : false"
                      [attr.date-anchor]="message?.date.date"
                      [timeOnly]="true"
                      [withOutStatus]="true"
    >
    </app-message-date>
    <div class="action-text" (click)="doAnswer()">{{ 'instagramDirectView.answer' | translate}}</div>

<!--    <div class="action-text disabled"-->
<!--         *ngIf="instagramDirectChannels && !instagramDirectChannels.length && !message?.from?.isOperator"-->
<!--         tooltip="{{(!instagramDirectChannels.length &&-->
<!--          'Добавьте в настройках Instagram Direct, чтобы иметь возможность писать в Direct') || ''}}"-->
<!--         placement="top"-->
<!--         tooltipAppendToBody="true">-->
<!--      {{ 'instagramDirectView.writeToDirect' | translate}}-->
<!--    </div>-->

<!--    <div class="action-text"-->
<!--         [class.disabled]="!isThereAnyActiveChannel"-->
<!--         (click)="handleWriteToDirectClick(directList)"-->
<!--         *ngIf="instagramDirectChannels && !message?.from?.isOperator && instagramDirectChannels.length > 1"-->
<!--         tooltip="{{ !isThereAnyActiveChannel &&-->
<!--          'Перейдите в настройки Instagram Direct и исправьте ошибки подключения' || ''}}"-->
<!--         placement="top"-->
<!--         tooltipAppendToBody="true">-->
<!--      {{ 'instagramDirectView.writeToDirect' | translate}}-->
<!--    </div>-->

<!--    <div class="action-text"-->
<!--         [class.disabled]="isChannelDisabled(instagramDirectChannels[0])"-->
<!--          (click)="writeToDirect(instagramDirectChannels[0].id, instagramDirectChannels[0].state, message.from.name);"-->
<!--         *ngIf="instagramDirectChannels && !message?.from?.isOperator && instagramDirectChannels.length === 1"-->
<!--         [tooltip]="getInstagramErrorTooltip(instagramDirectChannels[0]) | translate"-->
<!--         placement="top"-->
<!--         tooltipAppendToBody="true">-->
<!--      {{ 'instagramDirectView.writeToDirect' | translate}}-->
<!--    </div>-->


    <div class="action-text"
         *ngIf="!message?.isOwn"
         (click)="openMessageModal(sendMessageTemplate)">
      {{ 'instagramDirectView.writeToDirectSecure' | translate}}
    </div>

    <div class="action-text" *ngIf="!isChild && isOpen" (click)="setSeen()">
      {{ 'instagramDirectView.checkSeen' | translate}}
    </div>
  </div>
  <div class="message-footer" *ngIf="!isChild && message.childsCount > 0">
    <div class="action-text left" (click)="getChilds()">
      {{ !isOpened && 'instagramDirectView.showAnswers' || 'instagramDirectView.hideAnswers' | translate}}
      &nbsp;{{ (message.childsCount > 0 && '(' +  message.childsCount + ')') || ''}}
    </div>
  </div>
  <span class="message-date" *ngIf="message?.type === 20">{{ 'conversation.noteAdded' | translate }}</span>
  <span class="message-edited" *ngIf="message?.type === 12">{{ 'conversation.edited' | translate }}</span>
  <div *ngIf="isOpened && childs?.length > 0">
    <app-comments-message class="message-container child"
                          *ngFor="let childMessage of childs; let i = index;"
                          [message]="childMessage"
                          [isChild]="true"
                          [instagramDirectChannels]="instagramDirectChannels"
                          (onChildAnswer)="childAnswer($event)"
                          (onChildDirectWrite)="childWriteToDirect($event)"
                          [seen]="childMessage.seen">
    </app-comments-message>
  </div>
</div>
<ng-template [ngIf]="message?.type === 30 || message?.type === 40">
  <div class="assign-type">
    {{ message?.message }}
  </div>
</ng-template>
<div *ngIf="message?.type === 15 && message?.messageType === 'internal'" class="latest-wrapper">
  <div class="message-body">
    {{ 'conversation.markedComplete' | translate }}
    {{ message?.from && message?.from.isOperator && message?.from.name || '' }}{{ 'conversation.on' | translate }}
    {{ message?.dateString }}{{ 'conversation.in' | translate }}{{ message?.timeString }}
  </div>
</div>
<div class="failed-wrapper" [style.padding-top.px]="7">
  <div *ngIf="message?.failedMessage" class="message-failed" [style.width.px]="parentElementWidth">
    {{ message?.failedMessage | translate }}
  </div>
</div>


<ng-template #directList>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'instagramComments.writeDirect.title' | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <i class="teletype-icon-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="direct-text">{{ 'instagramComments.writeDirect.text' | translate }}</h5>

    <div class="direct-list">
      <button
        class="direct-list-item"
        placement="top"
        tooltipAppendToBody="true"
        *ngFor="let channel of instagramDirectChannels; trackBy: directChannelsTrackBy"
        [class.disabled]="isChannelDisabled(channel)"
        [tooltip]="getInstagramErrorTooltip(channel) | translate"
        (click)="writeToDirect(channel.id, channel.state, message.from.name); closeModal()">
        <i class="teletype-icon-instagram"></i>
          Direct {{channel.username}}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #sendMessageTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'instagramComments.writeSingle.title' | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <i class="teletype-icon-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="direct-text">{{ 'instagramComments.writeSingle.text' | translate }}</h5>

    <div class="text-area-wrapper">
      <textarea minlength="1"
                maxlength="1000"
                [(ngModel)]="singleMessage"
                (ngModelChange)="isValidMessageLength">
      </textarea>
      <ng-container *ngIf="error" >
        <app-alert [alertContent]="error"></app-alert><br/>
      </ng-container>
      <button class="btn btn-primary with-spinner send-message"
              (click)="sendSingleMessageToDirect()"
              [disabled]="!isValidMessageLength">
        <app-spinner [inProgress]="inProgress" [btnText]="'conversation.send' | translate"></app-spinner>
      </button>
    </div>
  </div>
</ng-template>
