import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  SimpleChanges,
  OnChanges,
  NgZone,
  OnDestroy,
  ViewChild,
  ElementRef,
  HostListener,
  TemplateRef,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { Message } from '@app/core/interfaces/message';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';
import { map, takeUntil } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomModalService } from '@app/core/custom-modal.service';
import { EmojiService } from '@app/shared/emoji/emoji/emoji.service';
import {Subject, Subscription} from 'rxjs';
import { environment } from '@env/environment';
import { COLONS_REGEX } from '@app/core/config/regExValidators';
import { UtilsService } from '@app/core/utils.service';
import { DateTime } from 'luxon';
import { I18nService } from '@app/core';
import { UserService } from '@app/core/user.service';
import { AudioPlayerService } from '@app/core/player';
import { ChannelInstagramDirect } from '@app/core/interfaces/channel';
import {ApiInstagramService} from '@app/core/api/api.instagram.service';

const VALIDATORS = {
  // eslint-disable-next-line max-len
  email: new RegExp(
    '^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
  ),
  sms: new RegExp('^[s()+-]*([0-9][s()+-]*){6,20}$'),
};

const PLACEHOLDERS = {
  email: 'email@test.com',
  sms: '+44',
};

const EMOJI_RANGE = /([\uD800-\uDBFF][\uDC00-\uDFFF])/;

const TIME_PLACE = '{{%DATE%}}';

@Component({
  selector: 'app-comments-message',
  templateUrl: './comments-message.component.html',
  styleUrls: ['./comments-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class CommentsMessageComponent implements OnInit, OnChanges, OnDestroy {
  public environment = environment;
  public modalRef: BsModalRef;
  public charactersCount = 0;
  public currentSelection: any;
  public lastMessageValue: string;
  public contentEditable = false;
  public contactForm: any;
  public messageClass: any;
  public dateToDisplay: string;
  public displayMessage: string;
  public contactFormValue: UntypedFormControl;
  public formSent = false;
  public inProgress = false;
  public singleMessage = '';
  public language: any;

  private statusCheckPlanned = false;
  public emojiPickerVisible = false;
  public imageWidth = 0;
  public imageHeight = 0;
  public emojiOnly = false;

  public size: string;

  // Audio message area
  public duration = '-:-';
  public totalDuration = this.duration;
  public playing = false;
  public percentDuration = 0;
  public durationInt = 0;
  public audioSubscription: Subscription;
  // /Audio message area

  get messageType(): string {
    return this.message ? (this.message.messageType === 'gif' ? 'image' : this.message.messageType) : 'text';
  }

  get isValidMessageLength(): boolean {
    return this.singleMessage.length && this.singleMessage.length <= 1000;
  }

  public useNativeEmoji: boolean;

  public messageStatus = 'sent';
  public avatar: string;
  public zindex = false;

  private unsub$ = new Subject<void>();
  public date: any;
  public startDate: any;
  public isOpened: boolean;
  public parentElementWidth: number;
  public error: string;

  @ViewChild('messageInputEdit', { read: ElementRef, static: false }) messageInputEdit: ElementRef;
  @ViewChild('messageContainer', { read: ElementRef, static: false }) messageContainer: ElementRef;
  @ViewChild('sendMessageTemplate', {static: true} ) public sendMessageTemplate: TemplateRef<any>;
  @Input() limit = 1000;
  @Input() message: Message;
  @Input() failedMessage: string;
  @Input() childs: Message[] = [];
  @Input() isChild: boolean;
  @Input() seen: boolean;
  @Input() isOpen: boolean;
  @Input() status: boolean;
  @Input() progress: number;
  @Input() childsCount: number;
  @Input() class: any;
  @Input() isAddDate = true;
  @Input() hasQueue: boolean;
  @Input() instagramDirectChannels: ChannelInstagramDirect[];
  @Input() paidEntities: any;
  @Input() allowChannelsId: Array<string>;
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() showPreviewer: EventEmitter<string> = new EventEmitter();
  @Output() retry: EventEmitter<Message> = new EventEmitter();
  @Output() remove: EventEmitter<Message> = new EventEmitter();
  @Output() changeMessage = new EventEmitter<string>();
  @Output() toggleEmojiPicker = new EventEmitter<boolean>();
  @Output() floatError = new EventEmitter<boolean>();
  @Output() sendEmoji = new EventEmitter<string>();
  @Output() loadChild = new EventEmitter<any>();
  @Output() onAnswer = new EventEmitter<any>();
  @Output() onDirectWrite = new EventEmitter<any>();
  @Output() onChildAnswer = new EventEmitter<any>();
  @Output() onChildDirectWrite = new EventEmitter<any>();
  @Output() onSeen = new EventEmitter<any>();

  @HostListener('keyup', ['$event'])
  @HostListener('keydown', ['$event'])
  @HostListener('mousedown', ['$event'])
  onMouseDown(e: Event) {
    // @ts-ignore
    if (e.target && e.target.className === 'teletype-icon-close') {
      return;
    }
    // 8 -backspace 47 - delete
    // @ts-ignore
    if (this.getCharactersLen() >= this.limit && e.type !== 'mousedown' && ![8, 46].includes(e.keyCode)) {
      e.preventDefault();
    }
    this.currentSelection = this.saveSelection();
    this.charactersCount = this.getCharactersLen();
  }

  constructor(
    private modalService: BsModalService,
    private dialog: CustomModalService,
    private detector: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private zone: NgZone,
    private emojiService: EmojiService,
    private utils: UtilsService,
    private i18nService: I18nService,
    private userService: UserService,
    private audioPlayerService: AudioPlayerService,
    private api: ApiInstagramService,
  ) {
    this.language = this.i18nService.translateService.translations[this.i18nService.language];
    this.useNativeEmoji = this.utils.useNativeEmoji;
  }

  get hasInstagramChannel(): boolean {
    return this.instagramDirectChannels?.length > 0;
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.unsub$.next();
this.unsub$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.failedMessage !== undefined && this.message !== undefined &&
      changes.failedMessage.currentValue !== undefined) {
      this.message.failedMessage = changes.failedMessage.currentValue;
      setTimeout(() => {
        this.parentElementWidth = this.messageContainer.nativeElement.clientWidth - 11;
      });
      this.onMessage(this.message);
    }
    if (changes.seen !== undefined && this.message !== undefined) {
      this.message.seen = changes.seen.currentValue;
    }
    if (changes.isOpen !== undefined && this.message !== undefined) {
      this.message.isOpen = changes.isOpen.currentValue;
    }
    if (changes.status !== undefined && this.message !== undefined && changes.status.currentValue !== undefined) {
      this.message.status = changes.status.currentValue;
    }
    if (changes.message !== undefined && changes.message.currentValue !== undefined) {
      this.onMessage(changes.message.currentValue);
    }
    if (changes.childs !== undefined && changes.childs.currentValue !== undefined) {
      this.isOpened = changes.childs.currentValue.length > 0;
      if (this.childs.length < this.message.childsCount && this.isOpened) {
        this.getChilds(true);
      }
    }
    if (changes.progress !== undefined && changes.progress.currentValue !== undefined) {
      this.message.progress = changes.progress.currentValue;
    }
    if (changes.childsCount !== undefined && changes.childsCount.currentValue !== undefined) {
      this.message.childsCount = changes.childsCount.currentValue;
    }
  }

  onMessage(message: Message) {
    this.message = { ...message };
    this.childs = [...(this.message.childs || [])];
    this.isOpened = this.childs.length > 0;

    if (this.message.provider === 20) {
      this.avatar = `${window.document.location.protocol}//${window.document.location.host}/assets/img/public-api-avatar.jpg`;
    } else {
      this.avatar = message.from && (message.from.avatar || message.from.avatar_default || message.from.avatarDefault);
    }

    if (this.message.type === 30) {
      const { language } = this.i18nService;
      const date = DateTime.fromISO(this.message.date.date).setLocale(language).setZone(this.userService.user.timezone);
      const additionalText = language === 'ru' ? ' в ' : '';
      this.message.message = this.message.message.replace(
        TIME_PLACE,
        date.toFormat('dd LLL yyyy') + additionalText + date.toFormat('HH:mm'),
      );
    }

    this.date = message.newDay;
    this.startDate = message.startDate;
    this.formatMessage();

    if (
      this.message.attachmentId !== undefined
      && (this.message.messageType === 'text' || this.message.messageType === undefined)
    ) {
      this.message.messageType = 'attachment';
    }
    if (this.messageType === 'attachment' || this.messageType === 'video') {
      if (message.size || !this.size) {
        this.size = this.utils.formatSize(message.size);
      }
    }

    if (this.messageType === 'audio') {
      this.audioPlayerService
        .getDuration(this.message.attachmentUrl)
        .pipe(takeUntil(this.unsub$))
        .subscribe((size) => {
          this.duration = this.audioPlayerService.formatTime(size);

          this.totalDuration = this.duration;

          this.detector.detectChanges();
        });
    }

    this.messageStatus = message.status || 'sent';

    const defaultDelay = 60 * 1000;
    const delay: number = !message.size || message.size < defaultDelay ? defaultDelay : message.size;

    if (!this.statusCheckPlanned) {
      this.statusCheckPlanned = true;
      setTimeout(() => {
        this.statusCheckPlanned = false;

        if (this.messageStatus === '10' && !this.hasQueue) {
          this.messageStatus = '20';
          if (!this.detector['destroyed']) {
            this.detector.detectChanges();
          }
        }
      }, delay);
    }

    if (this.message.formType === 'contact') {
      this.contactForm = {
        method: 'sms',
        value: '',
        valid: false,
        placeholder: '+44',
      };

      this.contactFormValue = new UntypedFormControl();
    }

    this.messageClass = {
      'with-avatar': this.message.avatar || this.message.avatarDefault,
      'from-me-edit': this.message.isOwn && this.contentEditable,
      'without-native': !this.useNativeEmoji,
      'message-with-form': this.message.formType !== undefined,
      shown: false,
    };
    setTimeout(() => {
      this.messageClass.shown = true;
      if (!this.detector['destroyed']) {
        this.detector.detectChanges();
      }
    }, 50);
  }

  cancelUpload(): void {
    this.cancel.emit(this.message.attachmentId);
  }

  bypass(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  bypassHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  validateForm(): void {
    this.contactForm.valid = VALIDATORS[this.contactForm.method.toLowerCase()].test(this.contactForm.value);
  }

  openPreviewer(url: string) {
    this.showPreviewer.emit(url);
  }

  changeContactFormMethod(method: string): void {
    this.contactForm.method = method;
    this.contactForm.placeholder = PLACEHOLDERS[method];
    this.validateForm();
  }

  sendContactForm(): void {
    this.formSent = !this.formSent;
  }

  adjustImageSize(width: number, height: number): void {
    this.zone.runOutsideAngular(() => {
      const ratio = height / width;

      if (width > 210) {
        width = 210;
        height = width * ratio;
      }

      this.imageWidth = width;
      this.imageHeight = height;
    });
  }

  formatMessage() {
    this.zone.runOutsideAngular(() => {
      if (this.message.messageType === 'image' || this.message.messageType === 'gif') {
        this.adjustImageSize(this.message.width, this.message.height);
      }
      if (this.message.message === undefined) {
        return;
      }
      if (this.message.emojiOnly) {
        const match = this.message.message
          && this.message.message.match(COLONS_REGEX)
          && this.message.message.match(COLONS_REGEX).length > 1
          && this.message.message.match(COLONS_REGEX)[1];
        if (match) {
          this.message.emojiOnly = this.emojiService.checkIfExists(match);
        }
      }
      this.message.message = this.message.message.replace(/\n/gi, '<br>');

      this.displayMessage = this.utils.parseMessage(
        this.message.message,
        this.message.emojiOnly,
        !(this.message.messageType === 'text'),
      );
      this.lastMessageValue = this.displayMessage;
    });
  }

  retrySendMessage() {
    this.retry.emit(this.message);
  }

  handleRemoveMessage(message: Message) {
    this.remove.emit(message);
  }

  isEmoji(string: string): boolean {
    if (string === undefined) {
      return false;
    }
    return string.match(EMOJI_RANGE) && this.emojiStringToArray(string).length === 1;
  }

  private emojiStringToArray(string: string): Array<string> {
    return this.zone.runOutsideAngular(() => {
      const split = string.split(/([\uD800-\uDBFF][\uDC00-\uDFFF])/);
      const arr = [];
      for (let i = 0; i < split.length; i++) {
        const char = split[i];
        if (char !== '') {
          arr.push(char);
        }
      }
      return arr;
    });
  }

  editMessage(e: any) {
    //  e.stopPropagation();
    this.contentEditable = true;
    this.messageClass['from-me'] = false;
    this.messageClass['from-me-edit'] = true;
    this.setCaretAtTheEnd(this.messageInputEdit !== undefined ? this.messageInputEdit.nativeElement : undefined);
  }

  sendMessage() {
    this.contentEditable = false;
    this.messageClass['from-me'] = true;
    this.messageClass['from-me-edit'] = false;
  }

  restartMessage() {
    this.contentEditable = false;
    this.messageClass['from-me'] = true;
    this.messageClass['from-me-edit'] = false;
    this.displayMessage = this.lastMessageValue;
    if (!this.detector['destroyed']) {
      this.detector.detectChanges();
    }
  }

  handleSelectEmoji(event: any): void {
    this.messageInputEdit.nativeElement.focus();

    let text: any = event.emoji.native;

    if (this.currentSelection && this.currentSelection.length) {
      this.restoreSelection(this.currentSelection);
    }
    if (!this.utils.useNativeEmoji) {
      document.execCommand('insertText', false, text);
    } else {
      text = this.utils.getEmojiImg(event.emoji.colons);
      document.execCommand('insertHtml', false, text.outerHTML);
    }

    this.toggleEmojiPicker.emit(false);
    this.setCaretAtTheEnd(this.messageInputEdit.nativeElement);
  }

  restoreSelection(savedSelection: any) {
    if (window.getSelection) {
      const sel = window.getSelection();
      sel.removeAllRanges();
      for (let i = 0, len = savedSelection.length; i < len; ++i) {
        sel.addRange(savedSelection[i]);
      }
    } else if ((<any>document).selection && (<any>document).selection.createRange) {
      if (savedSelection) {
        savedSelection.select();
      }
    }
  }

  replaceSelection(content: any) {
    if (window.getSelection) {
      let range;
      const sel = window.getSelection();
      const node = typeof content === 'string' ? document.createTextNode(content) : content;

      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0);
        range.deleteContents();
        range.insertNode(node);
        range.setStart(node, 0);

        window.setTimeout(() => {
          range = document.createRange();
          range.setStartAfter(node);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
        }, 0);
      }
    } else if ((<any>document).selection && (<any>document).selection.createRange) {
      const range = (<any>document).selection.createRange();
      if (typeof content === 'string') {
        range.text = content;
      } else {
        range.pasteHTML(content.outerHTML);
      }
    }
  }

  closePicker(): boolean {
    this.emojiPickerVisible = false;
    this.detector.detectChanges();
    return true;
  }

  onEmojiClick() {
    this.emojiPickerVisible = !this.emojiPickerVisible;
  }

  normalizeMessage(msg: string = ''): string {
    return msg.replace(/(&nbsp;)/g, ' ');
  }

  getCharactersLen(_msg: string = ''): number {
    const msg = this.normalizeMessage(
      _msg
      || (this.messageInputEdit
        && this.messageInputEdit.nativeElement
        && this.messageInputEdit.nativeElement.innerHTML)
      || '',
    );
    const elm = document.createElement('div');

    elm.innerHTML = msg;

    const imagesLen = elm.getElementsByTagName('img').length;

    return (elm.textContent || elm.innerText || '').length + imagesLen;
  }

  saveSelection() {
    if (window.getSelection) {
      const sel = window.getSelection();
      const node = <any>sel.anchorNode;
      if (node !== null && node.parentNode.closest('[contenteditable]') === null) {
        return this.currentSelection;
      }
      const ranges = [];
      if (sel.rangeCount) {
        for (let i = 0, len = sel.rangeCount; i < len; ++i) {
          ranges.push(sel.getRangeAt(i));
        }
      }
      return ranges;
    } if ((<any>document).selection && (<any>document).selection.createRange) {
      const sel = (<any>document).selection;
      return sel.type.toLowerCase() !== 'none' ? sel.createRange() : null;
    }
  }

  onMessageChange(model: string) {
    const message = this.normalizeMessage(model);
    this.charactersCount = this.getCharactersLen();
    if (!this.charactersCount) {
      this.floatError.emit(true);
    }
    this.changeMessage.emit(message);
    this.detector.detectChanges();
  }

  setCaretAtTheEnd(elm: HTMLElement): void {
    if (elm === undefined) {
      return;
    }

    elm.focus();

    if (typeof window.getSelection !== 'undefined' && typeof document.createRange !== 'undefined') {
      const range = document.createRange();
      range.selectNodeContents(elm);
      range.collapse(false);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (typeof (<any>document.body).createTextRange !== 'undefined') {
      const textRange = (<any>document.body).createTextRange();
      textRange.moveToElementText(elm);
      textRange.collapse(false);
      textRange.select();
    }
  }

  showHistoryMessage(template: TemplateRef<any>, e: any) {
    this.openModal(template);
  }

  openModal(template: TemplateRef<any>) {
    const source = { class: 'modal-channels' };
    this.modalRef = this.modalService.show(
      template,
      {
        backdrop: true,
        ignoreBackdropClick: true,
        ...source,
      },
    );
    this.detector.detectChanges();
  }

  onShown() {
    this.zindex = true;
    this.detector.detectChanges();
  }

  onHidden() {
    this.zindex = false;
    this.detector.detectChanges();
  }

  closeModal(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.error = '';
    this.singleMessage = '';
    this.modalRef && this.modalRef.hide();
    this.dialog.close();
    if (!this.detector['destroyed']) {
      this.detector.detectChanges();
    }
  }

  toggleAudioMessageState(): void {
    this.playing = !this.playing;

    if (this.playing) {
      if (this.audioSubscription !== undefined) {
        this.audioSubscription.unsubscribe();
      }

      this.audioSubscription = this.audioPlayerService
        .playStream(this.message.attachmentUrl, this.duration, this.durationInt)
        .pipe(takeUntil(this.unsub$))
        .subscribe((data) => {
          let formattedTime = this.audioPlayerService.formatTime(data.duration - data.currentTime);

          if (formattedTime === '-:-') {
            formattedTime = this.duration;
          }

          this.playing = data.playing;
          this.duration = formattedTime;
          this.percentDuration = data.durationPercentage;
          this.durationInt = data.currentTime;

          if (data.event === 'ended') {
            this.duration = this.totalDuration;
            this.playing = false;
            this.percentDuration = 0;
            this.durationInt = 0;
          }

          this.detector.detectChanges();
        });
    } else if (this.audioSubscription !== undefined) {
      this.audioPlayerService.pause();

      // this.audioSubscription.unsubscribe();

      // this.audioSubscription = undefined;
    }
  }

  getChilds(force: boolean = false) {
    this.isOpened = force ? true : !this.isOpened;
    if (this.isOpened) {
      this.loadChild.emit(this.message);
    }
    this.detector.detectChanges();
  }

  doAnswer() {
    if (this.message.id) {
      if (this.isChild) {
        this.onChildAnswer.emit(this.message);
      } else {
        this.onAnswer.emit(this.message);
      }
    }
  }

  writeToDirect(channelId: string, state: string, name: string) {
    if (state !== 'active' || this.message?.from?.isOperator) {
      return;
    }
    if (this.isChild) {
      this.onChildDirectWrite.emit({ channelId, state, name });
    } else {
      this.onDirectWrite.emit({ userName: name, channelId });
    }
  }

  public openMessageModal(template: TemplateRef<unknown>): void {
    if (this.message?.from?.isOperator) {
      return;
    }

    this.modalRef = this.modalService.show(template, {
      ignoreBackdropClick: true,
    });

    this.detector.detectChanges();
  }

  sendSingleMessageToDirect(): void {
    this.error = '';
    this.inProgress = true;
    this.api.privateReply(this.message.externalId, {text : this.singleMessage})
      .pipe(
        map(res => res),
        takeUntil(this.unsub$)
    )
      .subscribe((response) => {
        if (!response.success && response.errors) {
          this.error = response.errors[0].message;
          this.detector.detectChanges();
          return;
        }

        this.closeModal();
      }, () => {
        this.error = this.language.conversation.errorOfSending;
        this.inProgress = false;
      }, () => {
        this.inProgress = false;
      });
  }

  setSeen() {
    this.onSeen.emit(this.message);
  }

  childAnswer(event: any) {
    this.onAnswer.emit(event);
  }

  childWriteToDirect(event: any) {
    const { channelId, state, name } = event;
    this.writeToDirect(channelId, state, name);
  }

  public showDirectList(template: TemplateRef<unknown>): void {
    this.modalRef = this.modalService.show(template, {
      ignoreBackdropClick: true,
    });

    this.detector.detectChanges();
  }

  public handleWriteToDirectClick(template: TemplateRef<unknown>): void {
    const isOnlyChannel = this.instagramDirectChannels.length === 1;
    const isMoreThenOne = this.instagramDirectChannels.length > 1;
    const isOperator = !this.message?.from?.isOperator;

    if (!this.instagramDirectChannels?.length || !isOperator) {
      return;
    }

    if (isOnlyChannel && this.instagramDirectChannels[0]?.state) {
      this.writeToDirect(
        this.instagramDirectChannels[0].id,
        this.instagramDirectChannels[0].state,
        this.message.from.name,
      );
    } else if (isMoreThenOne) {
      this.showDirectList(template);
    }
  }

  public getInstagramErrorTooltip(instagram: ChannelInstagramDirect): string {
    if (!instagram.enabled) {
      return 'conversation.newThreadTooltips.instagram.disabled';
    } if (this.paidEntities?.instagramDirectBusiness?.length === 0 && instagram.state === 'active') {
      return 'conversation.newThreadTooltips.instagram.notInPlan';
    } if (!this.allowChannelsId.includes(instagram.id) && instagram.state === 'active') {
      return 'conversation.newThreadTooltips.instagram.notPaid';
    } if (instagram.state !== 'active') {
      return 'conversation.newThreadTooltips.instagram.error';
    }

    return instagram.tooltipText;
  }


  public isChannelDisabled(channel: ChannelInstagramDirect): boolean {
    return (
      (!channel.enabled ||
        (!this.allowChannelsId.includes(channel.id) && channel.state === 'active') ||
        channel.state !== 'active')
    );
  }

  public get isThereAnyActiveChannel(): boolean {
    return this.instagramDirectChannels.some(instagram => {
      const enabled = instagram.enabled;
      const inPlan = this.paidEntities?.instagramDirect?.length > 0;
      const paid = this.allowChannelsId.includes(instagram.id);
      const active = instagram.state === 'active';

      return enabled && inPlan && paid && active;
    });
  }


  public directChannelsTrackBy(index: number, item: ChannelInstagramDirect): string {
    return item.id;
  }
}
