import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  OnDestroy,
  NgZone,
  ChangeDetectorRef,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { I18nService, extract } from '@app/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import each from 'lodash-es/each';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '@app/core/user.service';
import { environment } from '@env/environment';
import {Subject} from 'rxjs';

const SECONDS_IN_MINUTE = 60;
const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60;
const INTERVAL = SECONDS_IN_MINUTE * 1000; // MS

@Component({
  selector: 'app-message-date',
  templateUrl: './message-date.component.html',
  encapsulation: ViewEncapsulation.None,
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageDateComponent implements OnInit, OnChanges, OnDestroy {

  dateInterval: any;
  @Input() nowText: string = extract('Now');
  @Input() date: string;
  @Input() status: string;
  @Input() seen: boolean;
  @Input() shortFormat: boolean;
  @Input() logFormat: boolean;
  @Input() timeOnly: boolean;
  @Input() withOutStatus = false;

  private _date: Date;
  private unsub$ = new Subject<void>();
  private timeZone: string;

  public dateOffset: number;
  public lastDateCheck: number;
  public dateFormated = '';
  public translatedTimeIntervals = {
    hourAgo: '',
    hoursAgo: '',
    minuteAgo: '',
    minutesAgo: ''
  };
  public language: any;
  public isNow: boolean;

  constructor(
    private detector: ChangeDetectorRef,
    private zone: NgZone,
    private lang: I18nService,
    private translateService: TranslateService,
    private userService: UserService
  ) {
    this.language = this.lang.translateService.translations[this.lang.language];
    each(this.translatedTimeIntervals, (v, key) => {
      this.translateService.get(key)
        .pipe(takeUntil(this.unsub$))
        .subscribe((res: string) => {
          this.translatedTimeIntervals[key] = extract(res);
        });
    });
    this.userService.getUserData().pipe(takeUntil(this.unsub$)).subscribe((user: any) => {
      if (!user) {
        return;
      }
      this.timeZone = user.timezone;
    });
  }

  ngOnInit() {
    this._date = DateTime.fromISO(this.date).setZone(this.timeZone).toJSDate();

    if (this.nowText === undefined) {
      this.translateService.get('Now')
        .pipe(takeUntil(this.unsub$))
        .subscribe((res: string) => {
          this.nowText = extract(res);
        });
    }
    this.formatDate();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.date !== undefined && changes.date.currentValue !== undefined) {
      const newDate = DateTime.fromISO(changes.date.currentValue).setZone(this.timeZone).toJSDate();
      this._date = newDate !== this._date ? newDate : this._date;

      clearInterval(this.dateInterval);

      this.formatDate();
    }
  }

  ngOnDestroy() {
    this.unsub$.next();
this.unsub$.complete();
    this.zone.runOutsideAngular(() => {
      clearInterval(this.dateInterval);
    });
  }

  formatDate() {
    this.zone.runOutsideAngular(() => {

      let timeInterval = INTERVAL;

      const messageDate = DateTime.fromISO(this.date).setZone(this.timeZone).setLocale(this.lang.language);

      const diff = Math.round(Math.abs(messageDate.diffNow('seconds').seconds));

      this.dateOffset = diff;

      let dateToDisplay = '';
      this.isNow = false;
      if (this.timeOnly) {
        dateToDisplay = messageDate.toFormat('HH:mm');
      } else {
        if (diff < SECONDS_IN_MINUTE) {
          dateToDisplay = this.language.justNow;
          this.isNow = true;
        } else if (diff > SECONDS_IN_MINUTE - 1 && diff < SECONDS_IN_HOUR) {
          const minutes = Math.floor(diff / SECONDS_IN_MINUTE);
          dateToDisplay = minutes + this.language.minute;
          dateToDisplay = this.logFormat && dateToDisplay + this.language.ago || dateToDisplay;
        } else if (diff >= SECONDS_IN_HOUR && diff <= SECONDS_IN_HOUR * 2) {
          const hours = Math.floor(diff / SECONDS_IN_HOUR);
          dateToDisplay = hours + this.language.hour;
          dateToDisplay = this.logFormat && dateToDisplay + this.language.ago || dateToDisplay;
          timeInterval = timeInterval * 5;
        } else if (diff > SECONDS_IN_HOUR * 2 && diff <= SECONDS_IN_HOUR * 24) {
          dateToDisplay = messageDate.toFormat('HH:mm');
          timeInterval = timeInterval * 20;
        } else if (diff > SECONDS_IN_HOUR * 24 && diff <= SECONDS_IN_HOUR * 48) {
          dateToDisplay = this.shortFormat || this.logFormat ? this.language.yesterday :
            `${this.language.yesterday} ${messageDate.toFormat('HH:mm')}`;
          timeInterval = timeInterval * 20;
        } else if (diff > SECONDS_IN_HOUR * 48 && diff <= SECONDS_IN_HOUR * 72 && this.logFormat) {
          dateToDisplay = ` 3 day ago`;
        } else if (diff > SECONDS_IN_HOUR * 72 && diff <= SECONDS_IN_HOUR * 96 && this.logFormat) {
          dateToDisplay = ` 4 day ago`;
        } else if (diff > SECONDS_IN_HOUR * 96 && diff <= SECONDS_IN_HOUR * 120 && this.logFormat) {
          dateToDisplay = ` 5 day ago`;
        } else {
          dateToDisplay = this.shortFormat ?
            messageDate.toFormat(environment.features.dateFormat[this.lang.language || 'en']) :
            messageDate.toFormat('dd LLL HH:mm');
          if (this.lang.language === 'ru') {
            dateToDisplay = dateToDisplay.substring(0, 6);
          }
        }
      }

      if (this.dateFormated !== dateToDisplay) {
        this.dateFormated = dateToDisplay;
        this.detector.detectChanges();
      }

      this.zone.runOutsideAngular(() => {
        clearInterval(this.dateInterval);
      });

      this.dateInterval = setInterval(() => {
        this.formatDate();
      }, timeInterval);
    });
  }

}
