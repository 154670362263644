import { Injectable } from '@angular/core';
import { BehaviorSubject , Observable } from 'rxjs';
import findIndex from 'lodash-es/findIndex';
import remove from 'lodash-es/remove';
import { extract } from '@app/core/i18n.service';
import { DialogService } from '@app/core/modal/modal.service';

export interface Alert {
  id?: string;
  type: string;
  title?: string;
  content?: string;
  dismissable?: boolean;
}

export interface ModalAlert {
  id?: string;
  title?: string;
  content?: string;
  okButtonText?: string;
  okCallback?: Function;
  initialState?: any;
}

export interface ModalConfirm {
  id?: string;
  title?: string;
  content?: string;
  cancelButtonText?: string;
  okButtonText?: string;
  okCallback?: Function;
  cancelCallback?: Function;
}

interface ConfirmDeleteWindow {
  title: string;
  content: string;
  okButtonText: string;
  cancelButtonText: string;
}

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  private _alerts: Alert[] = [];
  private _alertsSubject = new BehaviorSubject<Alert[]>([]);

  public onlineStateListener = new BehaviorSubject<string>('online');

  get alerts(): Observable<any> {
    return this._alertsSubject.asObservable();
  }

  constructor(
    private modal: DialogService
  ) {
    if ('serviceWorker' in navigator) {
      window.addEventListener('offline', () => {
        this.onlineStateListener.next('offline');

        if (localStorage.getItem('isShowLogs')) {
          console.log('ACTIVITIES TEST: Navigator detect offline', new Date());
        }
      });

      window.addEventListener('online', () => {
        this.onlineStateListener.next('online');
        if (localStorage.getItem('isShowLogs')) {
          console.log('ACTIVITIES TEST: Navigator detect online', new Date());
        }
      });
    }
  }

  getOnlineState() {
    return this.onlineStateListener.asObservable();
  }

  addAlert(alert: Alert): string {

    alert.id = this._generateId();

    alert.title = extract(alert.title || '');
    alert.content = extract(alert.content || '');
    alert.dismissable = alert.dismissable || true;

    this._alerts = [];

    this._alerts.push(alert);
    this._alertsSubject.next(this._alerts);

    return alert.id;
  }

  removeAlert(alertId: string): void {
    remove(this._alerts, (a: Alert) => a.id.toString() === alertId.toString());

    this._alertsSubject.next(this._alerts);
  }

  removeAll(): void {
    this._alerts = [];
    this._alertsSubject.next(this._alerts);
  }

  alert(alert: any): void {
    alert.okCallback = alert.okCallback || Function();
    alert.okButtonText = alert.okButtonText || extract('OK');
    alert.title = extract(alert.title || '');
    alert.content = extract(alert.content || '');
    this.modal.showAlert(alert);
  }

  /** @deprecated используйте вместо этого метод confirmObservable  */
  confirm(confirm: any): void {
    confirm.okCallback = confirm.okCallback || Function();
    confirm.cancelCallback = confirm.cancelCallback || Function();
    confirm.okButtonText = confirm.okButtonText || extract('OK');
    confirm.cancelButtonText = confirm.cancelButtonText || extract('Cancel');
    confirm.title = confirm.title || '';
    confirm.content = confirm.content || '';
    this.modal.showConfirm(confirm);
  }

  confirmObservable(confirm: any): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const confirmConfig = {
        okButtonText: confirm.okButtonText || extract('OK'),
        cancelButtonText: confirm.cancelButtonText || extract('Cancel'),
        title: confirm.title || '',
        content: confirm.content || '',
        okCallback: () => { observer.next(true); observer.complete(); },
        cancelCallback: () => { observer.next(false); observer.complete(); },
      };

      this.modal.showConfirm(confirmConfig);
    });
  }

  /** @deprecated используйте вместо этого метод confirmDeleteObservable  */
  confirmDelete(confirm: any): void {
    confirm.okCallback = confirm.okCallback || Function();
    confirm.cancelCallback = confirm.cancelCallback || Function();
    confirm.okButtonText = confirm.okButtonText || extract('OK');
    confirm.cancelButtonText = confirm.cancelButtonText || extract('Cancel');
    confirm.title = confirm.title || '';
    confirm.content = confirm.content || '';
    this.modal.showConfirmDelete(confirm);
  }

  /** возвращает результат в boolean формате. Не требует отписки  */
  confirmDeleteObservable(confirm: ConfirmDeleteWindow): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const confirmConfig = {
        okButtonText: confirm.okButtonText || extract('OK'),
        cancelButtonText: confirm.cancelButtonText || extract('Cancel'),
        title: confirm.title || '',
        content: confirm.content || '',
        okCallback: () => { observer.next(true); observer.complete(); },
        cancelCallback: () => { observer.next(false); observer.complete(); },
      };

      this.modal.showConfirmDelete(confirmConfig);
    });
  }

  private _generateId(length: number = 10) {
    let id = '';

    let exists = false;

    const set = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    do {
      id = '';
      for (let i = 0; i <= length; i++) {
        id += set.charAt(Math.floor(Math.random() * set.length));
      }

      exists = findIndex(this._alerts, (a: Alert) => a.id === id) > -1;

    } while (exists);

    return id;
  }
}
