import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { QuicklinkModule } from 'ngx-quicklink';

import { environment } from '@env/environment';
import { CoreModule } from '@app/core/core.module';
import { SharedModule } from '@app/shared';
import { HomeModule } from './home/home.module';
import { LoginModule } from './login/login.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';

import { Angulartics2Module } from 'angulartics2';

import { DeviceDetectorService } from 'ngx-device-detector';
import { RegisterModule } from './register/register.module';
import { SharedTariffsModule } from './shared-tariffs/shared-tariffs.module';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from "@sentry/tracing";

if (environment.production) {
  Sentry.init({
    dsn: 'https://ae1dff9bd9d8413b99ce0bc767e82676@sentry.io/2858630',
    release: "panel",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http, `${window.document.location.protocol}//${window.document.location.host}/assets/i18n/`,
    `.json?${environment.version}`);
}

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      useDefaultLang: false,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CoreModule,
    SharedModule,
    HomeModule,
    LoginModule,
    SharedTariffsModule,
    RegisterModule,
    QuicklinkModule,
    AppRoutingModule,
    Angulartics2Module.forRoot()
  ],
  declarations: [AppComponent],
  providers: [DeviceDetectorService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
