import { Injectable } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { DialogComponent } from '@app/core/modal/modal.component';

@Injectable({
  providedIn: 'root',
})

export class DialogService {
  bsModalRef: BsModalRef;

  constructor(
    private modalService: BsModalService
  ) {

  }

  showAlert(alert: any) {

    const options: ModalOptions = {
      ignoreBackdropClick: true,
      animated: false,
      class: 'alert-type',
      keyboard: false,
      initialState: alert
    };

    options.initialState['showCloseButton'] = false;

    this.bsModalRef = this.modalService.show(DialogComponent, options);
  }

  showConfirm(confirm: any) {

    const options: ModalOptions = {
      ignoreBackdropClick: true,
      animated: false,
      class: 'confirm-type',
      keyboard: false,
      initialState: confirm
    };

    this.bsModalRef = this.modalService.show(DialogComponent, options);
  }

  showConfirmDelete(confirm: any) {

    const options: ModalOptions = {
      ignoreBackdropClick: true,
      animated: false,
      class: 'confirm-type-delete',
      keyboard: false,
      initialState: confirm
    };

    this.bsModalRef = this.modalService.show(DialogComponent, options);
  }
}
