import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@app/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {

  constructor(
    private userService: UserService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> | boolean {
    const user = this.userService.user$.getValue();
    if (user) {
      return true;
    }
    return new Promise<boolean>(resolve => {
      this.userService.isUserLoaded$.subscribe(isLoaded => {
        if (isLoaded) {
          resolve(true);
        }
      });
    });
  }

}
