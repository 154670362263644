import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AvitoPost } from '@app/core/interfaces/api.response';

/** краткая информация об объявлении авито (используется только в правом сайдбаре) */
@Component({
  selector: 'app-avito-post',
  templateUrl: './avito-post.component.html',
  styleUrls: ['./avito-post.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvitoPostComponent {

  @Input('post') post: AvitoPost;

  public get postId(): string {
    // в ссылке на пост последним идет его id, который отбивается символом подчеркивания
    const startOfId = this.post.postUrl.lastIndexOf('_') + 1;
    return startOfId ? this.post.postUrl.slice(startOfId) : '';
  }

  public get postUrl(): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(this.post.postUrl);
  }

  constructor(
    private sanitizer: DomSanitizer,
  ) {}
}
