import { NgModule } from '@angular/core';
import { DadataComponent } from './dadata.component';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import { DadataDirective } from './dadata.directive';
import { HighlightPipe } from './highlight.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [DadataComponent, DadataDirective, HighlightPipe],
  exports: [DadataComponent, DadataDirective]
})
export class DadataModule { }
