import { Injectable } from '@angular/core';
import { AppealsAutoAssignMode } from '@app/core/api/api.project';
import { Observable } from 'rxjs';
import { ApiService } from '@app/core/api/api.service';
import { Response } from '@app/core/interfaces/api.response';
import { routes } from '@app/core/config/apiRoutes';
import { UtilsService } from '@app/core/utils.service';

export interface UpdateProfileContext {
  email?: string;
  name?: string;
  lastName?: string;
  timezone?: string;
  language?: string;
  avatar?: string;
  token?: string;
  online?: boolean;
  status?: number;
}

export interface UpdateProfileResponse {
  success: boolean;
  data?: any;
  errors: Array<any>;
}

export interface UpdateResponsibleOperators {
  appealsAutoAssign: boolean;
  appealsAutoAssignMaxCount: number | string | null;
  responsibleOperators: Array<any>;
  appealsAutoAssignMode: AppealsAutoAssignMode,
}

export interface ResponsibleOperatorsConfig {
  appealsAutoAssign: boolean;
  appealsAutoAssignMaxCount: number | null;
  appealsAutoAssignMode: AppealsAutoAssignMode;
  responsibleOperators: unknown[]
}

@Injectable({
  providedIn: 'root',
})
export class TeammateApiService {
  private routes = routes;

  constructor(
    private api: ApiService,
    private utils: UtilsService,
  ) {

  }

  public clientUpdate(id: string, data: UpdateProfileContext): Observable<UpdateProfileResponse> {
    return this.api.httpPost(this.routes.clientUpdate(id), this.utils.snakeKeys(data));
  }

  public clientUpdateEmail(emailKey: string): Observable<any> {
    return this.api.httpGet(this.routes.clientUpdateEmail(emailKey));
  }

  public inviteOperator(data: any): Observable<Response> {
    return this.api.httpPost(this.routes.inviteOperator, this.utils.snakeKeys(data));
  }

  public clientDelete(id: string): Observable<Response> {
    return this.api.httpGet(this.routes.clientDelete(id));
  }

  public updateProfile(data: UpdateProfileContext): Observable<UpdateProfileResponse> {
    return this.api.httpPost(this.routes.updateProfile, this.utils.snakeKeys(data));
  }

  public getClientAvatar(id: string): Observable<Response> {
    return this.api.httpGet(this.routes.getClientAvatar(id));
  }

  public getUserAvatar(): Observable<Response> {
    return this.api.httpGet(this.routes.getUserAvatar);
  }

  public getOperatorsList(): any {
    return this.api.httpGet(this.routes.operators, true);
  }

  public setRoleForClient(clientId: string, newRole: string): any {
    return this.api.httpPost(this.routes.setRoleForClient(clientId), { role: newRole });
  }

  public getResponsibleOperatorsConfig(): Observable<Response<ResponsibleOperatorsConfig>> {
    return this.api.httpGet(this.routes.responsibleOperatorsConfig, true);
  }

  public updateResponsibleOperatorsConfig(data: UpdateResponsibleOperators): Observable<UpdateResponsibleOperators> {
    return this.api.httpPost(this.routes.updateResponsibleOperators, this.utils.snakeKeys(data));
  }
}
