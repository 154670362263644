import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/api/api.service';
import { GroupData, GroupService } from '@app/core/group.service';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { I18nService } from '@app/core/i18n.service';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { ProjectService } from '@app/core/project.service';
import { ProfileApiService, UserData, UserResponse } from '@app/core/api/api.profile';
import { filter, finalize, switchMap } from 'rxjs/operators';
import { StorageService } from '@app/core/storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public user: UserData;
  public user$: BehaviorSubject<UserData> = new BehaviorSubject(null);
  public isUserLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  // TODO: тут стоит хранить полную инфу о каналах из групп
  /** каналы доступные пользователю (ограничивается группами) */
  private availableChannels = new BehaviorSubject<string[]>([]);

  /** каналы, для которых включен режим изоляции (настраивается в группах) */
  private channelsWithIsolatedMode = new BehaviorSubject<string[]>([]);
  private requestPending = false;
  private projectIsLoaded: boolean;

  get isUserLoaded$() {
    return this.isUserLoaded.asObservable();
  }

  constructor(
    private api: ApiService,
    private profileApi: ProfileApiService,
    private lang: I18nService,
    private auth: AuthenticationService,
    private projectService: ProjectService,
    private groupService: GroupService,
    private storage: StorageService,
  ) {
    this.getAvailableChannels();
    this.getChannelsWithIsolatedMode();
    if (this.auth.credentials) {
      this.getUser();
    }
  }

  getUser(): UserData {
    if (this.auth.credentials === null || this.requestPending) {
      return;
    }

    this.requestPending = true;
    if (!this.storage.currentProjectId) {
      this.projectService.isProjectsLoaded$.pipe().subscribe(isLoaded => {
        if (isLoaded) {
          this.loadUser();
        }
      });
    } else {
      this.loadUser();
    }
  }

  loadUser() {
    this.profileApi.getUser().pipe(
      finalize(() => {
        this.isUserLoaded.next(true);
        this.requestPending = false;
      })
    ).subscribe((data: UserResponse) => {
      if (data.success) {
        this.userResponse(data);
      }
    });
  }

  userResponse(data: UserResponse) {
    const userData = data.data;
    if (userData?.timezone === 'UTC') {
      userData.timezone = 'Atlantic/Reykjavik';
    }

    if (!this.lang.supportedLanguages.includes(userData?.language)) {
      userData.language = this.lang.language;
    }
    this.user = userData;
    this.user$.next(userData);
  }

  // метод с проблемой. Если запросить его слишком рано, то мы получим только один Undefined,
  // так как информация о проекте еще не загружена
  getUserData(): Observable<any> {

    if (!this.user && !this.requestPending && this.projectIsLoaded !== undefined) {
      this.getUser();
    }

    return this.user$.asObservable();
  }

  setUserParam(param: string, value: any) {
    this.user[param] = value;
    this.user$.next(this.user);
  }

  setAvatar(avatar: string | boolean) {
    this.user.avatar = avatar;
    this.user$.next(this.user);

    // TODO: what for?
    this.api.updateAvatar(avatar)
      .subscribe(() => {
        this.user$.next(this.user);
      });
  }

  getAvatar(): string | boolean {
    return this.user$.getValue().avatar;
  }

  private getAvailableChannels() {
    this.user$.asObservable().pipe(
      filter(userData => !!userData),
      switchMap(userData => this.groupService.getUserGroup(userData.id))
    ).subscribe((groupList: GroupData[]) => {
      const availableChannels = new Set<string>();

      groupList.forEach(group => {
        group.channels.forEach((channel) => availableChannels.add(channel.channelId))
      });

      this.availableChannels.next(Array.from(availableChannels));
    });
  }

  private getChannelsWithIsolatedMode() {
    this.user$.asObservable().pipe(
      filter(userData => !!userData),
      switchMap(userData => this.groupService.getUserGroup(userData.id))
    ).subscribe((groupList: GroupData[]) => {
      const channelsWithEnabledIsolatedMode = new Set<string>();
      const channelsWithDisabledIsolatedMode = new Set<string>();

      groupList.forEach((group) => {
        group.channels.forEach((channel) => {
          if (channel.isolateClients) {
            channelsWithEnabledIsolatedMode.add(channel.channelId);
          } else {
            channelsWithDisabledIsolatedMode.add(channel.channelId);
          }
        });
      });

      channelsWithDisabledIsolatedMode.forEach((channelId) => channelsWithEnabledIsolatedMode.delete(channelId))

      this.channelsWithIsolatedMode.next(Array.from(channelsWithEnabledIsolatedMode));
    });
  }

  clear() {
    this.user = undefined;
    this.user$.next(this.user);
  }

  // это надо перенсти в permissionsService
  isUserHasAccessToChannel(channelId: string): boolean {
    if (this.user.roles.includes('owner') || this.user.roles.includes('admin')) {
      return true;
    }
    return this.availableChannels.value.includes(channelId);
  }

  // это надо перенести в permissionsService
  isUserHasAccessToAppeal(updatedAppeal: {channelId: string; clientId: string}): boolean {
    if (!updatedAppeal.clientId) {
      return true;
    }

    if (this.user.roles.includes('owner') || this.user.roles.includes('admin')) {
      return true;
    }

    const isChannelInIsolatedMode = this.channelsWithIsolatedMode.value.includes(updatedAppeal.channelId);
    if (!isChannelInIsolatedMode) {
      return true;
    }

    // если пользователь является супервизором для этого канала, то обращение он видит всегда
    if (this.groupService.isUserSupervisorForChannel(updatedAppeal.channelId, this.user.id)) {
      return true;
    }

    const newClientOfAppealIsCurrentUser = updatedAppeal.clientId === this.user.id;

    return newClientOfAppealIsCurrentUser;
  }
}

