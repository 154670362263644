import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { takeUntil } from 'rxjs/operators';
import { each, filter, find } from 'lodash-es';
import { ProjectService } from '@app/core/project.service';
import { WebsocketsService, WsEvent } from '@app/core/websockets.service';
import { UtilsService } from '@app/core/utils.service';
import { ProjectData } from '@app/core/api/api.project';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-project-switch',
  templateUrl: './project-switch.component.html',
  styleUrls: ['./project-switch.component.scss'],
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectSwitchComponent implements OnInit, OnDestroy {

  @Input() isSettings = false;

  public innerHtml: any;
  public projects: Array<ProjectData>;
  public currentProjectName: string;
  private unsub$ = new Subject<void>();

  constructor(
    private sanitizer: DomSanitizer,
    private projectService: ProjectService,
    private detector: ChangeDetectorRef,
    private ws: WebsocketsService,
    private utils: UtilsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.ws.on(WsEvent.UNREAD_MESSAGE, (_data: any) => {
      let data = this.utils.camelKeys(_data);
      if (!data.projects || !data.projects.length) {
        return;
      }
      data = data.projects;
      let count = 0;
      each(data, (received: any) => {
        each(this.projects, (current: ProjectData) => {
          current.saniatzedName = this.sanitizer.bypassSecurityTrustHtml(current.name);
          if (received.projectId === current.id) {
            current.unreadCount = received.unreadCount || '';
            if (received.unreadCount > 0) {
              count++;
            }
          }
        });
      });
    });

    this.ws.onCounterUnreadAppeals()
      .pipe(takeUntil(this.unsub$)).subscribe((_data: any) => {
        const data = this.utils.camelKeys(_data);
        const currentProject = this.projectService.getCurrentProject();
        if (!currentProject || (data.projectId === currentProject.id)) {
          return;
        }
        each(this.projects, (current: ProjectData) => {
          if (data.projectId === current.id) {
            current.unreadCount = data.unansweredCount;
          }
        });
        if (!this.detector['destroyed']) {
          this.detector.detectChanges();
        }
      });

    this.getProjects();
  }

  ngOnDestroy(): void {
    this.unsub$.next();
    this.unsub$.complete();
    this.ws.removeListener(WsEvent.UNREAD_MESSAGE);
  }

  async unselectCurrentAppeal() {
    const params = { ...this.activatedRoute.snapshot.queryParams };
    delete params['appealId'];
    return this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params, replaceUrl: true });
  }

  public async clickToLink(event: MouseEvent, project: ProjectData) {
    // это нужно т к у юзера может быть выбрано какое-то обращение и в момент смены проекта
    // новый проект попытается запросить обращение с таким id, которого в другом проекте не существует
    // и вывеется ошибка
    await this.unselectCurrentAppeal();

    if (environment.switchDomainByProject) {
      return
    } else {
      event.preventDefault();
      localStorage.setItem('projectId', project.id);
      location.reload();
    }

    project.unreadCount = 0;
  }

  getLink(project: ProjectData) {
    return this.getUrl(project);
  }

  public handleSwitch() {
    setTimeout(() => {
      this.detector.detectChanges();
    });
  }

  private getProjects() {
    this.projectService.getProjects().pipe(takeUntil(this.unsub$))
      .subscribe((projects: ProjectData[]) => {
        if (projects) {
          const currentProject = this.projectService.getCurrentProject();
          if (currentProject) {
            const currentProjectId = currentProject.id;
            this.currentProjectName = currentProject.name;
            each(projects, (current: ProjectData) => {
              current.saniatzedName = this.sanitizer.bypassSecurityTrustHtml(current.name);
            });
            this.projects = filter(projects, project => project.id !== currentProjectId);
            this.detector.detectChanges();
          }
        }
    });
  }

  private getUrl(project: ProjectData) {
    const domain = environment && environment.baseDomain;
    let url = 'https://' + project.url;
    if (domain.indexOf('localhost') !== -1) {
      url = domain + '/home';
    }
    if (this.isSettings) {
      url += window.location.pathname;
    }
    return url;
  }

}
