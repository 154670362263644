<div class="autocomplete-container" #autocompleteContainer *ngIf="activeSearch">
  <div
    *ngFor="let option of matchedOptions; let i = index;"
    class="option"
    [class._directory]="option.options"
    [class._inside-directory]="checkedDirectory && checkedDirectory.id !== option.id"
    [class._pre-selected]="option.id === preSelectedOption?.id"
    (click)="selectOption(option)"
    (mouseenter)="changePreselectedElement(i)"
  >
    <span class="option__key">{{ option.key }}</span>
    <span
      *ngIf="!option.options?.length"
      class="option__value"
      [innerHTML]="bypassHtml(option.value)"
    ></span>

    <span class="option__back" *ngIf="checkedDirectory?.id === option.id">
      {{ 'button.back' | translate }}
    </span>
  </div>

  <div *ngIf="matchedOptions.length === 0 || matchedOptions.length === 1 && checkedDirectory" class="no-match">
    <ng-container *ngIf="(isTemplatesPaid$ | async); else templatesNotPaid">
      <ng-container *ngIf="isOptionsLoaded; else loadingTemplate">
        <ng-container *ngIf="options.length && !ednaTemplatesForChannel">
          {{ 'conversation.noMatch' | translate }}
        </ng-container>

        <ng-container *ngIf="!options.length && !ednaTemplatesForChannel">
          {{ 'conversation.emptyList' | translate }}
        </ng-container>

        <ng-container *ngIf="!options.length && ednaTemplatesForChannel">
          {{ 'conversation.wabaTemplatesIsNotLoaded' | translate }}
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #templatesNotPaid>
      <ng-container *ngIf="!ednaTemplatesForChannel">
        <ng-container *ngIf="!isAdmin">
          {{ 'conversation.templatesIsNotPaidForOperator' | translate }}
        </ng-container>

        <ng-container *ngIf="isAdmin">
          {{ 'conversation.templatesIsNotPaidForAdmin' | translate }}
        </ng-container>
      </ng-container>

      <ng-container *ngIf="ednaTemplatesForChannel">
        {{ 'conversation.wabaTemplatesIsNotLoaded' | translate }}
      </ng-container>
    </ng-template>

    <ng-template #loadingTemplate>
      {{ 'conversation.loading' | translate }}
    </ng-template>
  </div>
</div>
