import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TutorialsComponent implements OnInit {

  public tutorials = [
    {
      id: '486751932'
    },
    {
      id: '486751884'
    },
    {
      id: '486752096'
    },
    {
      id: '486752356'
    },
    {
      id: '486752210'
    },
    {
      id: '486752099'
    },
    {
      id: '486752306'
    }
  ];

  public basicFunctionsTutorials = [
    {
      id: '487134536'
    },
    {
      id: '486752171'
    },
    {
      id: '486752022'
    }
  ];

  public advancedFunctionsTutorials = [
    {
      id: '486752153'
    },
    {
      id: '486751982'
    }
  ];

  public get currentVideoUrl(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`https://player.vimeo.com/video/${this.currentVideo}`);
  }

  public showVideoPopup = false;
  public currentVideo: string = null;

  constructor(
    private detector: ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
  }

  openVideoPopup(videoId: string): void {
    this.currentVideo = videoId;
    this.showVideoPopup = true;
    this.detector.detectChanges();
  }

  closeVideoPopup(): void {
    this.currentVideo = null;
    this.showVideoPopup = false;
    this.detector.detectChanges();
  }

}
