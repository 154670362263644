import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from '@env/environment.prod';
import { StorageService } from '@app/core/storage/storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {


  constructor(
    private auth: AuthenticationService,
    private storage: StorageService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const id = this.storage.currentProjectId;
    if (id && request.url.indexOf('giphy.com') === -1) {
      request = request.clone({
        setHeaders: {
          'X-Project-Id': id
        }
      });
    }

    if (!request.url.match('^http(s)?') ||
      request.url.includes(`${environment.apiUrl}/v${environment.apiVersion}`)) {

      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.credentials}`
        }
      });
    }

    return next.handle(request).pipe(
      map(response => this.authHandlerFromResponse(response)),
      catchError(response => this.authHandler(response))
    );
  }

  authHandlerFromResponse(response: any): any {

    // Used when status sent in body and not in header
    // eslint-disable-next-line max-len
    if (response.body !== undefined && response.body.errors !== undefined && response.body.errors[0] !== undefined &&
      response.body.errors[0].code === 401) {
      this.auth.redirectToLogin();
      return;
    }
    return response;
  }

  authHandler(response: any): Observable<HttpEvent<any>> {
    if (response.status === 401 || response.status === 503) {
      this.auth.redirectToLogin();
      return;
    }

    return throwError(response);
  }
}
