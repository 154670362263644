import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { TariffService } from '@app/core/tariff.service';
import { environment } from '@env/environment';
import { filter } from 'rxjs/operators';

export enum ANALYTICS_EVENT {
  MAIL_SEND = 'MAIL_SEND',
  MAIL_CONFIRM = 'MAIL_CONFIRM',
  FIRST_PROJECT_CREATE = 'FIRST_PROJECT_CREATE',
  SECOND_PROJECT_CREATE = 'SECOND_PROJECT_CREATE',
  PROJECT_PASSWORD = 'PROJECT_PASSWORD',
  PROJECT_PASSWORD_REPEAT = 'PROJECT_PASSWORD_REPEAT',
  PROJECT_NAME = 'PROJECT_NAME',
  PROJECT_CONFIRM = 'PROJECT_CONFIRM',
  PROFILE_NAME = 'PROFILE_NAME',
  PROFILE_PHONE = 'PROFILE_PHONE',
  PROFILE_SITE = 'PROFILE_SITE',
  PROFILE_CONFIRM = 'PROFILE_CONFIRM',
  CHANNEL_CONNECT = 'CHANNEL_CONNECT',
  PAID = 'PAID',
  PURCHASE = 'Purchase',
  BIG_BUTTON = 'BIG_BUTTON',
  SMALL_BUTTON = 'SMALL_BUTTON',
  START_CREATE_WHATSAPP = 'START_CREATE_WHATSAPP',
  END_CREATE_WHATSAPP = 'END_CREATE_WHATSAPP',
  START_CREATE_VIBER = 'START_CREATE_VIBER',
  END_CREATE_VIBER = 'END_CREATE_VIBER',
  START_CREATE_TELEGRAM = 'START_CREATE_TELEGRAM',
  END_CREATE_TELEGRAM = 'END_CREATE_TELEGRAM',
  START_CREATE_AVITO = 'START_CREATE_AVITO',
  END_CREATE_AVITO = 'END_CREATE_AVITO',
  START_CREATE_VK = 'START_CREATE_VK',
  END_CREATE_VK = 'END_CREATE_VK',
  START_CREATE_VK_DIRECT = 'START_CREATE_VK_DIRECT',
  END_CREATE_VK_DIRECT = 'END_CREATE_VK_DIRECT',
  START_CREATE_FACEBOOK = 'START_CREATE_FACEBOOK',
  END_CREATE_FACEBOOK = 'END_CREATE_FACEBOOK',
  START_CREATE_INSTAGRAM_DIRECT = 'START_CREATE_INSTAGRAM_DIRECT',
  END_CREATE_INSTAGRAM_DIRECT = 'END_CREATE_INSTAGRAM_DIRECT',
  START_CREATE_INSTAGRAM_COMMENTS = 'START_CREATE_INSTAGRAM_COMMENTS',
  END_CREATE_INSTAGRAM_COMMENTS = 'END_CREATE_INSTAGRAM_COMMENTS',
  CLIENT_CHOOSE_TELETYPE_APP = 'CLC_T_APP',
  CLIENT_CHOOSE_TELETYPE_IN = 'CLC_T_IN',
  CREATE_CHANNEL = 'CREATE_CHANNEL'
}

@Injectable({
  providedIn: 'root'
})
export class MetrikaService {

  private get ym() {
    return window['ym'] ?? null;
  }

  private metrikaId = environment.metrikaId;

  private eventBuffer = [];

  constructor(
    private tariff: TariffService,
    private authenticationService: AuthenticationService,
  ) {
    this.checkNeedStopWebvisor();
  }

  goal(goalName: ANALYTICS_EVENT | string, args: object = null) {
    if (this.ym) {
      this.ym(this.metrikaId, 'reachGoal', goalName, args);
    } else {
      this.eventBuffer.push(goalName);
    }
  }

  initMetric(isActiveExtendedAnalytic: boolean = false) {
    const metrikaScript = `
      (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
        m[i].l=1*new Date();
        for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
        k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
      (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

      ym(${environment.metrikaId}, "init", {
        clickmap:${isActiveExtendedAnalytic},
        trackLinks:${isActiveExtendedAnalytic},
        accurateTrackBounce:${isActiveExtendedAnalytic},
        webvisor:${isActiveExtendedAnalytic}
      });
    `;

    if (!this.ym) {
      eval(metrikaScript);
    }

    this.eventBuffer.forEach((goalName) => {
      this.goal(goalName);
    })
  }

  public checkNeedStopWebvisor() {
    if (!this.authenticationService.isAuthenticated()) {
      this.initMetric(true);
      return;
    }

    const sub = this.tariff.getPromoEndDate()
      .pipe(
        filter((promoDate) => !!promoDate.date),
      )
      .subscribe((promoEndDate) => {
        const endDate = new Date(promoEndDate.date);
        const currentDate = new Date();
        this.initMetric(currentDate < endDate);
        sub.unsubscribe()
      })
  }
}
