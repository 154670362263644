import { Injectable } from '@angular/core';
import { I18nService } from '@app/core';

import { DateTime, Duration } from 'luxon';
import each from 'lodash-es/each';
import filter from 'lodash-es/filter';
import first from 'lodash-es/first';

export const zones: any = [
  {
    'value': 'Pacific/Tongatapu',
    'name': '(GMT#OFFSET) Nuku\'alofa'
  },
  {
    'value': 'Pacific/Apia',
    'name': '(GMT#OFFSET) Midway Island, Samoa'
  },
  {
    'value': 'Pacific/Fiji',
    'name': '(GMT#OFFSET) Fiji'
  },
  {
    'value': 'Pacific/Auckland',
    'name': '(GMT#OFFSET) Auckland, Wellington'
  },
  {
    'value': 'Asia/Kamchatka',
    'name': '(GMT#OFFSET) Kamchatka, Marshall Is.'
  },
  {
    'value': 'Pacific/Guadalcanal',
    'name': '(GMT#OFFSET) Magadan, Solomon Is., New Caledonia'
  },
  {
    'value': 'Asia/Vladivostok',
    'name': '(GMT#OFFSET) Vladivostok'
  },
  {
    'value': 'Australia/Hobart',
    'name': '(GMT#OFFSET) Hobart'
  },
  {
    'value': 'Pacific/Port_Moresby',
    'name': '(GMT#OFFSET) Guam, Port Moresby'
  },
  {
    'value': 'Australia/Sydney',
    'name': '(GMT#OFFSET) Canberra, Melbourne, Sydney'
  },
  {
    'value': 'Australia/Brisbane',
    'name': '(GMT#OFFSET) Brisbane'
  },
  {
    'value': 'Australia/Darwin',
    'name': '(GMT#OFFSET) Darwin'
  },
  {
    'value': 'Australia/Adelaide',
    'name': '(GMT#OFFSET) Adelaide'
  },
  {
    'value': 'Asia/Yakutsk',
    'name': '(GMT#OFFSET) Yakutsk'
  },
  {
    'value': 'Asia/Seoul',
    'name': '(GMT#OFFSET) Seoul'
  },
  {
    'value': 'Asia/Tokyo',
    'name': '(GMT#OFFSET) Osaka, Sapporo, Tokyo'
  },
  {
    'value': 'Asia/Taipei',
    'name': '(GMT#OFFSET) Taipei'
  },
  {
    'value': 'Australia/Perth',
    'name': '(GMT#OFFSET) Perth'
  },
  {
    'value': 'Asia/Singapore',
    'name': '(GMT#OFFSET) Kuala Lumpur, Singapore'
  },
  {
    'value': 'Asia/Irkutsk',
    'name': '(GMT#OFFSET) Irkutsk, Ulaan Bataar'
  },
  {
    'value': 'Asia/Shanghai',
    'name': '(GMT#OFFSET) Beijing, Chongqing, Hong Kong, Urumqi'
  },
  {
    'value': 'Asia/Bangkok',
    'name': '(GMT#OFFSET) Bangkok, Hanoi, Jakarta'
  },
  {
    'value': 'Asia/Krasnoyarsk',
    'name': '(GMT#OFFSET) Krasnoyarsk'
  },
  {
    'value': 'Asia/Rangoon',
    'name': '(GMT#OFFSET) Rangoon'
  },
  {
    'value': 'Asia/Dhaka',
    'name': '(GMT#OFFSET) Astana, Dhaka'
  },
  {
    'value': 'Asia/Novosibirsk',
    'name': '(GMT#OFFSET) Almaty, Novosibirsk'
  },
  {
    'value': 'Asia/Kathmandu',
    'name': '(GMT#OFFSET) Kathmandu'
  },
  {
    'value': 'Asia/Kolkata',
    'name': '(GMT#OFFSET) Chennai, Kolkata, Mumbai, New Delhi'
  },
  {
    'value': 'Asia/Colombo',
    'name': '(GMT#OFFSET) Sri Jayawardenepura'
  },
  {
    'value': 'Asia/Tashkent',
    'name': '(GMT#OFFSET) Islamabad, Karachi, Tashkent'
  },
  {
    'value': 'Asia/Yekaterinburg',
    'name': '(GMT#OFFSET) Ekaterinburg'
  },
  {
    'value': 'Asia/Kabul',
    'name': '(GMT#OFFSET) Kabul'
  },
  {
    'value': 'Asia/Tehran',
    'name': '(GMT#OFFSET) Tehran'
  },
  {
    'value': 'Asia/Dubai',
    'name': '(GMT#OFFSET) Abu Dhabi, Muscat'
  },
  {
    'value': 'Asia/Yerevan',
    'name': '(GMT#OFFSET) Baku, Tbilisi, Yerevan'
  },
  {
    'value': 'Africa/Nairobi',
    'name': '(GMT#OFFSET) Nairobi'
  },
  {
    'value': 'Asia/Baghdad',
    'name': '(GMT#OFFSET) Baghdad'
  },
  {
    'value': 'Europe/Minsk',
    'name': '(GMT#OFFSET) Minsk'
  },
  {
    'value': 'Europe/Moscow',
    'name': '(GMT#OFFSET) Moscow, St. Petersburg, Volgograd'
  },
  {
    'value': 'Europe/Simferopol',
    'name': '(GMT#OFFSET) Simferopol, Sevastopol'
  },
  {
    'value': 'Asia/Riyadh',
    'name': '(GMT#OFFSET) Kuwait, Riyadh'
  },
  {
    'value': 'Europe/Kiev',
    'name': '(GMT#OFFSET) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius'
  },
  {
    'value': 'Asia/Jerusalem',
    'name': '(GMT#OFFSET) Jerusalem'
  },
  {
    'value': 'Europe/Bucharest',
    'name': '(GMT#OFFSET) Bucharest'
  },
  {
    'value': 'Asia/Beirut',
    'name': '(GMT#OFFSET) Beirut'
  },
  {
    'value': 'Europe/Athens',
    'name': '(GMT#OFFSET) Athens'
  },
  {
    'value': 'Europe/Istanbul',
    'name': '(GMT#OFFSET) Istanbul'
  },
  {
    'value': 'Africa/Johannesburg',
    'name': '(GMT#OFFSET) Harare, Pretoria'
  },
  {
    'value': 'Africa/Cairo',
    'name': '(GMT#OFFSET) Cairo'
  },
  {
    'value': 'Europe/Warsaw',
    'name': '(GMT#OFFSET) Sarajevo, Skopje, Warsaw, Zagreb'
  },
  {
    'value': 'Europe/Paris',
    'name': '(GMT#OFFSET) Brussels, Copenhagen, Madrid, Paris'
  },
  {
    'value': 'Europe/Budapest',
    'name': '(GMT#OFFSET) Belgrade, Bratislava, Budapest, Ljubljana, Prague'
  },
  {
    'value': 'Europe/Berlin',
    'name': '(GMT#OFFSET) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna'
  },
  {
    'value': 'Africa/Lagos',
    'name': '(GMT#OFFSET) West Central Africa'
  },
  {
    'value': 'Europe/London',
    'name': '(GMT#OFFSET) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London'
  },
  {
    'value': 'Atlantic/Reykjavik',
    'name': '(GMT#OFFSET) Casablanca, Monrovia'
  },
  {
    'value': 'Atlantic/Azores',
    'name': '(GMT#OFFSET) Azores'
  },
  {
    'value': 'Atlantic/Cape_Verde',
    'name': '(GMT#OFFSET) Cape Verde Is.'
  },
  {
    'value': 'America/Godthab',
    'name': '(GMT#OFFSET) Greenland'
  },
  {
    'value': 'Atlantic/South_Georgia',
    'name': '(GMT#OFFSET) Mid-Atlantic'
  },
  {
    'value': 'America/St_Johns',
    'name': '(GMT#OFFSET) Newfoundland'
  },
  {
    'value': 'America/Sao_Paulo',
    'name': '(GMT#OFFSET) Brasilia'
  },
  {
    'value': 'America/Halifax',
    'name': '(GMT#OFFSET) Atlantic Time (Canada)'
  },
  {
    'value': 'America/Santiago',
    'name': '(GMT#OFFSET) Santiago'
  },
  {
    'value': 'Etc/GMT+3',
    'name': '(GMT#OFFSET) Buenos Aires, Georgetown'
  },
  {
    'value': 'America/La_Paz',
    'name': '(GMT#OFFSET) Caracas, La Paz'
  },
  {
    'value': 'America/New_York',
    'name': '(GMT#OFFSET) Eastern Time (US & Canada)'
  },
  {
    'value': 'America/Bogota',
    'name': '(GMT#OFFSET) Bogota, Lima, Quito'
  },
  {
    'value': 'Etc/GMT+5',
    'name': '(GMT#OFFSET) Indiana (East)'
  },
  {
    'value': 'America/Chicago',
    'name': '(GMT#OFFSET) Central Time (US & Canada)'
  },
  {
    'value': 'America/Mexico_City',
    'name': '(GMT#OFFSET) Guadalajara, Mexico City, Monterrey'
  },
  {
    'value': 'America/Chihuahua',
    'name': '(GMT#OFFSET) Chihuahua, La Paz, Mazatlan'
  },
  {
    'value': 'America/Denver',
    'name': '(GMT#OFFSET) Mountain Time (US & Canada)'
  },
  {
    'value': 'America/Guatemala',
    'name': '(GMT#OFFSET) Central America'
  },
  {
    'value': 'America/Regina',
    'name': '(GMT#OFFSET) Saskatchewan'
  },
  {
    'value': 'America/Phoenix',
    'name': '(GMT#OFFSET) Arizona'
  },
  {
    'value': 'America/Los_Angeles',
    'name': '(GMT#OFFSET) Pacific Time (US & Canada); Tijuana'
  },
  {
    'value': 'America/Anchorage',
    'name': '(GMT#OFFSET) Alaska'
  },
  {
    'value': 'Pacific/Honolulu',
    'name': '(GMT#OFFSET) Hawaii'
  },
  {
    'value': 'Etc/GMT+12',
    'name': '(GMT#OFFSET) International Date Line West'
  }
];

@Injectable({
  providedIn: 'root',
})
export class TimezoneService {

  public zones = zones;

  constructor(
    private i18nService: I18nService,
  ) {
    // Iterate through each available timezone and set it's offset
    const language = this.i18nService.translateService.translations[this.i18nService.language];

    each(this.zones, (v: any, i: number) => {
      const offset = DateTime.local().setZone(v.value).offset;
      if (language) {
        this.zones[i].name = language[this.zones[i].name];
      }
      this.zones[i].offset = offset;
      this.zones[i].name = this.zones[i].name.replace('#OFFSET', this.getOffset(offset));
    });
  }

  getOffset(offset: number) {
    const duration = Duration.fromObject({
      milliseconds: offset * 60 * 1000
    }).shiftTo('hours', 'minutes').toObject();

    let gmtOffset = `${this.pad(duration.hours)}:${this.pad(duration.minutes)}`;

    gmtOffset = (duration.hours < 0) ? `${gmtOffset}` : `+${gmtOffset}`;

    if (duration.hours === 0 && duration.minutes === 0) {
      gmtOffset = '';
    }

    return gmtOffset;
  }

  pad(number: number, size: number = 2) {
    let s = String(number);
    while (s.length < (size || 2)) {
      s = '0' + s;
    }
    return s;
  }

  getZones() {
    return this.zones;
  }

  getFittingZone(): string | null {
    const local = DateTime.local();
    const currentZone = local && local.zoneName;
    const zoneOffset = local && local.offset;

    let zone: any = first(filter(this.zones, { value: currentZone }));

    if (zone === undefined || !zone.value) {
      zone = first(filter(this.zones, { offset: zoneOffset }));
    }

    return zone.value || null;
  }

  // eslint-disable-next-line max-len
  getDateWithOffset(timezone: string, dateFormat: string = 'HH:mm', withTimeOffset: boolean = true, date?: string): string {
    const currentDate = date ? DateTime.fromISO(date).setZone(timezone) : DateTime.local().setZone(timezone);
    const offset = currentDate.offset;
    const formattedDate = currentDate.toFormat(dateFormat);

    return withTimeOffset ? `${formattedDate} (UTC${this.getOffset(offset)})` : formattedDate;
  }

  getTimezoneOffset(timezone: string) {
    const date = DateTime.local().setZone(timezone);

    return date.offset;
  }

}
