import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/api/api.service';
import { routes } from '@app/core/config/apiRoutes';
import { SocketsLogService } from '@app/core/sockets-log.service';
import { Observable } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';

export interface FeatureFlags {
  groups: boolean;
  categories: boolean;
  personCustomFields: boolean;
  bitrix: boolean;
  deleteAppeals: boolean;
  filesForbidden: boolean;
  kommo: boolean;
  resizableSidebar: boolean;
  hideAnsweredButton: boolean;
  aiBlocks: boolean;
  panelLogger: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {

  public flags$: Observable<FeatureFlags> = this.api.httpGet(routes.featuresFlags).pipe(
    map((res) => {
      if (res.success) {
        this.socketLogService.switchLogging(res.data.panelLogger);
        return res.data;
      } else {
        throw new Error('Can not get a Flags configuration');
      }
    }),
    publishReplay(1),
    refCount(),
  );

  public isAvailableGroups = this.flags$.pipe(
    map(flags => flags.groups),
    publishReplay(1),
    refCount()
  );

  public isNewBitrixConnection = this.flags$.pipe(
    map(flags => flags.bitrix),
    publishReplay(1),
    refCount()
  );

  public isAvailableCategories = this.flags$.pipe(
    map(flags => flags.categories),
    publishReplay(1),
    refCount()
  );

  public isAvailableCustomFields = this.flags$.pipe(
    map(flags => flags.personCustomFields),
    publishReplay(1),
    refCount()
  );

  public isAvailableDeleteAppeal = this.flags$.pipe(
    map(flags => flags.deleteAppeals),
    publishReplay(1),
    refCount()
  );

  public isAvailableSendFiles = this.flags$.pipe(
    map(flags => !flags.filesForbidden),
    publishReplay(1),
    refCount()
  );

  public isAvailableKommo = this.flags$.pipe(
    map(flags => flags.kommo),
    publishReplay(1),
    refCount()
  );

  public isResizableSidebar = this.flags$.pipe(
    map(flags => flags.resizableSidebar),
    publishReplay(1),
    refCount(),
  );

  public isHideAnsweredButton = this.flags$.pipe(
    map(flags => flags.hideAnsweredButton),
    publishReplay(1),
    refCount(),
  );

  public isAvailableAiBlocks = this.flags$.pipe(
    map(flags => flags.aiBlocks),
    publishReplay(1),
    refCount(),
  );

  public isAvailablePanelLogger = this.flags$.pipe(
    map(flags => flags.panelLogger),
    publishReplay(1),
    refCount(),
  );

  constructor(
    private api: ApiService,
    private socketLogService: SocketsLogService,
  ) {}
}
