import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {DadataType, DadataService} from './dadata.service';
import {Subject, timer} from 'rxjs';
import {debounce} from 'rxjs/operators';
import {DadataResponse} from './models/dadata-response';
import {DadataSuggestion} from './models/suggestion';
import {DadataConfig, DadataConfigDefault} from './dadata-config';
import {ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import { DadataDirective } from './dadata.directive';
import each from 'lodash-es/each';

/**
 * Autocomplete IDs need to be unique across components, so this counter exists outside of
 * the component definition.
 */
let uniqueDadataIdCounter = 0;

@Component({
  selector: 'app-dadata',
  templateUrl: './dadata.component.html',
  styleUrls: ['./dadata.component.scss'],
  providers: [
    {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DadataComponent),
    multi: true
  }, /*NGX_DADATA_VALIDATOR*/]
})
export class DadataComponent implements OnInit, AfterContentInit {
  currentFocus = -1;

  opened = false;

  data: DadataSuggestion[] = [];

  searchValue: string;

  @Input() config: DadataConfig = DadataConfigDefault;
  @Input() apiKey: string;
  @Input() disabled = null;
  @Input() type: DadataType | string = DadataType.address;
  @Input() limit = DadataConfigDefault.limit;
  @Input() placeholder = '';
  @Input() locations = null;
  @Input() fieldMapping: {[key: string]: string};
  @Input() form: UntypedFormGroup;
  @Input() formControlName: string;

  @Output() selectedSuggestion: DadataSuggestion;
  @Output() selected: EventEmitter<DadataSuggestion> = new EventEmitter<DadataSuggestion>();

  @ContentChild(DadataDirective, {static: false}) contentChildDirective!: DadataDirective;

  private inputString$ = new Subject<string>();
  private v: any = '';

  /** Unique ID to be used by autocomplete trigger's "aria-owns" property. */
  id = `ngx-dadata-${uniqueDadataIdCounter++}`;

  // onSuggestionSelected = (value: string) => {};
  onTouched = () => {};
  propagateChange: any = () => {};
  validateFn: any = () => {};

  constructor(
    private detector: ChangeDetectorRef,
    private dataService: DadataService,
    private r: Renderer2,
    private elRef: ElementRef) {
  }

  get value(): any {
    return this.v;
  }

  set value(v: any) {
    if (v !== this.v) {
      this.v = v;
      this.propagateChange(v);
    }
  }

  ngOnInit() {
    this.type = this.config.type;
    this.locations = this.config.locations;
    this.dataService.setApiKey(this.apiKey ? this.apiKey : this.config.apiKey);
    this.inputString$.pipe(
      debounce(() => timer(this.config.delay ? this.config.delay : 500)),
    ).subscribe(x => {
      this.dataService.getData(x, this.type, this.config)
        .subscribe((y: DadataResponse) => {
        this.data = y.suggestions;
        if (this.data.length) {
          this.opened = true;
        }
        this.detector.detectChanges();
      });
    });
  }

  ngAfterContentInit() {
    this.contentChildDirective.inputEventHandlerSubj.subscribe((value) => {
      this.getData(value);
    });
  }

  getData(value: string) {
    this.inputString$.next(value);
    this.searchValue = value;
    this.currentFocus = -1;
  }

  onClick(e: Event, item: DadataSuggestion) {

    this.propagateChange(item.value);
    this.selectedSuggestion = item;
    this.data = [];
    this.currentFocus = -1;
    this.opened = false;
    this.selected.emit(item);

    each(this.fieldMapping, (path, field) => {
      let tmpItem = item;
      each(path.split('.'), (chunk) => {
        tmpItem = tmpItem[chunk];
      });

      if (tmpItem) {
        this.form.controls[field].setValue(tmpItem);
      }
    });
  }

  @HostListener('document:click', ['$event'])
  onOutsideClick($event: MouseEvent) {
    if (!this.opened) {
      return;
    }
    if (!this.elRef.nativeElement.contains($event.target)) {
      this.data = [];
      this.opened = false;
    }
  }

  onArrowDown() {
    this.removeFocus(this.currentFocus);
    if (this.currentFocus >= this.data.length - 1) {
      this.currentFocus = 0;
    } else {
      this.currentFocus++;
    }
    this.setFocus(this.currentFocus);
  }

  onArrowUp() {
    this.removeFocus(this.currentFocus);
    if (this.currentFocus === 0) {
      this.currentFocus = this.data.length - 1;
    } else {
      this.currentFocus--;
    }
    this.setFocus(this.currentFocus);
  }

  onEnter(event: KeyboardEvent, item: DadataSuggestion) {
    this.selectedSuggestion = item;
    this.data = [];
    this.currentFocus = -1;
    this.propagateChange(this.selectedSuggestion.value);
    this.selected.emit(this.selectedSuggestion);
  }

  setFocus(id: number) {
    const activeEl = document.getElementById(id + 'item');
    this.r.addClass(activeEl, 'active');
  }

  removeFocus(id: number) {
    if (id !== -1) {
      const activeEl = document.getElementById(id + 'item');
      this.r.removeClass(activeEl, 'active');
    }
  }
}
