import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/api/api.service';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { CookiesService } from '@app/core/cookies.service';
import { environment } from '@env/environment';
import { Duration, Interval } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {

  private subscriptionId = '';
  private wasSet = '';

  private interval = Interval.after(new Date(), Duration.fromObject({ years: 99 }))
    .toDuration('days')
    .toObject().days;

  get isNotificationsAvailable(): boolean {
    const userAgent = navigator.userAgent
    const isSafariBrowser = userAgent.includes('Safari') && !userAgent.includes('Chrome');
    return !isSafariBrowser;
  }

  constructor(
    private httpService: ApiService,
    private authService: AuthenticationService,
    private cookiesService: CookiesService,
  ) {
    this.getSubId();
    this.watchTabState();
  }

  getSubId() {
    this.subscriptionId = this.cookiesService.get('oneSignalSubId');
    if (this.subscriptionId && this.authService.isAuthenticated()) {
      this.updateSubId(this.subscriptionId);
    }
  }

  watchTabState() {
    if (this.subscriptionId && document.visibilityState === 'visible') {
      this.changeSubscriptionStatusTo(false);
    }

    document.addEventListener('visibilitychange', (event) => {
      if (this.subscriptionId) {
        if (document.visibilityState === 'visible') {
          this.changeSubscriptionStatusTo(false);
        } else {
          this.changeSubscriptionStatusTo(true);
        }
      }
    });

    window.addEventListener('beforeunload', () => {
      this.changeSubscriptionStatusTo(true);
    });
  }

  goToNotificationsPage() {
    const origin = 'https://' + environment.baseDomain; // 'http://localhost:4200'; //
    const path = '/notifications-page.html';
    const params = `?appId=${environment.oneSignalAppId}`;
    const url = origin + path + params;

    window.open(url, '_blank');
    window.addEventListener('message', (event) => {
      if (event.origin === origin && event.data.type === 'oneSignalId') {
        this.subscriptionId = event.data.id;
        this.cookiesService.set('oneSignalSubId', event.data.id, this.interval, '/', environment.cookiesOrigin);
        this.updateSubId(this.subscriptionId);
      }
    });
  }

  updateSubId(subId: string) {
    if (subId === this.wasSet) {
      return;
    }

    const url = this.httpService.routes.setOnesignalSubscription;
    this.httpService
      .httpPost(url, { subscription_id: subId })
      .subscribe((res) => {
        if (res.success) {
          this.wasSet = subId;
        }
      });
  }

  changeSubscriptionStatusTo(newValue: boolean) {
    const ONE_SIGNAL_APP_ID = environment.oneSignalAppId;

    const route = `https://onesignal.com/api/v1/apps/${ONE_SIGNAL_APP_ID}/subscriptions/${this.subscriptionId}`;
    const notification_types = newValue ? '1' : '-2';

    fetch(route, {
      body: `{"subscription":{"notification_types":${notification_types},"enabled":${newValue}}}`,
      method: 'PATCH',
      keepalive: true,
    });
  }
}
