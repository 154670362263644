import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AlertsService, Alert } from '@app/core/alerts/alerts.service';
import { Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { ChatService } from '@app/core/chat.service';
import { UtilsService } from '@app/core/utils.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertsComponent implements OnInit, OnDestroy {

  private subscriber: Subscription;
  private canTake = true;

  public alerts: Alert[] = [];

  constructor(
    private detector: ChangeDetectorRef,
    private alertsService: AlertsService,
    private chatService: ChatService,
    private utils: UtilsService,
    private translationService: TranslateService
  ) { }

  ngOnInit() {
    this.subscriber = this.alertsService.alerts.pipe(takeWhile(() => this.canTake))
      .subscribe((alerts: Alert[]) => {
        this.alerts = alerts;
        this.detector.detectChanges();
      });
  }

  ngOnDestroy() {
    this.canTake = false;
    this.subscriber.unsubscribe();
  }

  alertTrackFn(id: number, alert: Alert): string {
    return alert.id;
  }

  closeAlert(id: string): void {
    this.alertsService.removeAlert(id);
    this.detector.detectChanges();
  }

  showWidget(alert: Alert) {
    if (alert.type !== 'error') {
      return;
    }

    const errorMessage = this.utils.trimTextFromHtml(alert.content);

    const widgetMessage = this.translationService.instant('alert.errorMessage', {
      errorMessage
    });

    this.chatService.sendMessage(widgetMessage);
  }

}
