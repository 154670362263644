<div class="news-body" tabindex="-1">
  <button type="button" class="close pull-right" (click)="closeModal()"></button>
  <div class="description-wrapper">
    <div class="course-title" [innerHTML]="'onboard.welcome' | translate"></div>
  </div>
  <app-video-player [url]="'https://www.youtube.com/embed/nhfrAZ2ouyc?autoplay=1'"
                    [width]="'100%'">
  </app-video-player>
  <div class="description-course" [innerHTML]="'onboard.description' | translate"></div>
  <div class="run" (click)="closeModal()">{{ 'onboard.startUse' | translate }}</div>
</div>



