<ng-content></ng-content>
<div class="autocomplete">
  <div *ngIf="data.length">
    <div class="autocomplete-items">
      <div class="autocomplele-item" *ngFor="let item of data; let i = index" (keyup.enter)="onClick($event, item)" (click)="onClick($event, item)" [id]="i+'item'">
        <span class="item-title" [innerHTML]="item.value | highlight: searchValue"></span>
        <ng-template [ngIf]="type==='party'">
          <span class="item-description" [innerHTML]="item.data?.inn + ' ' + item.data?.address?.value | highlight: searchValue"></span>
        </ng-template>
      </div>
    </div>
  </div>
</div>
