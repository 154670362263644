<div class="message-input-compact" [class.error]="!message">
  <div class="message-input-wrapper" #messageInputContainer>
    <div
      class="teletype-new-message-input-container"
      #messageInput
      [maxLength]="250"
      contenteditable="true"
      [defaultValue]="message"
      (onPaste)="onPaste($event)"
      (click)="onMessageInputClick($event)"
      (changeText)="onMessageChange($event)"
      appTeletypeContentEditable></div>
    <div *ngIf="!message" class="form-group-error">
      {{ 'message.error.blank' | translate }}
    </div>
    <div class="length-text">{{ charactersLeft() }}  {{ 'chat.characters' | translate }}</div>
  </div>

  <button type="button"
          (click)="onEmojiClick($event)"
          class="emoji-button">
    <i class="teletype-icon-emoji"></i>
  </button>
  <app-teletype-emoji-picker
    (selectGif)="closePicker() && handleSendGiphy($event)"
    (selectEmoji)="closePicker() && handleSelectEmoji($event)"
    (closePicker)="closePicker()"
    [ngClass]="{'teletype-visible': emojiPickerVisible}"
    [pickerVisible]="emojiPickerVisible"></app-teletype-emoji-picker>
</div>
