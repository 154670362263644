<app-alerts></app-alerts>
<app-toasts></app-toasts>

<div class="app">
  <app-sidebar *ngIf="hasProject && !hideSidebar"></app-sidebar>

  <div class="main-container">
    <app-header *ngIf="!hideHeader" [ngClass]="{'full': !hasProject}"></app-header>
    <router-outlet></router-outlet>
  </div>
</div>

<ng-template #declineModalTemplate>
  <div class="modal-header pablo">
    <h4 class="modal-title pull-left"></h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <i class="teletype-icon-close"></i>
    </button>
  </div>
  <div class="modal-body zero-up">
    <div class="no-money"></div>
    <div class="warn-title">{{ 'accessModal.insufficientFunds' | translate }}</div>
    <div class="warn-text">{{ 'accessModal.insufficientFundsText' | translate }}</div>
    <button type="button" class="btn btn-ok" (click)="goPayment()" *ngIf="!isOperatorRole">
      {{ 'button.replenishBalance' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #noMoneyModalTemplate>
  <div class="modal-header pablo">
    <h4 class="modal-title pull-left"></h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <i class="teletype-icon-close"></i>
    </button>
  </div>
  <div class="modal-body zero-up">
    <div class="pablo-disable"></div>
    <div class="warn-title">{{ 'messageSendBlockModal.title' | translate }}</div>
    <div class="warn-text">{{ 'messageSendBlockModal.text' | translate }}</div>
    <button type="button" class="btn btn-ok" (click)="goPayment()" *ngIf="!isOperatorRole">
      {{ 'button.replenishBalance' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #runningOutMoneyModalTemplate>
  <div class="modal-header pablo">
    <h4 class="modal-title pull-left"></h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <i class="teletype-icon-close"></i>
    </button>
  </div>
  <div class="modal-body zero-up">
    <div class="no-money"></div>
    <div class="warn-title">{{ 'runningOutOfMoneyModal.title' | translate }}</div>
    <div class="warn-text">{{ modalText }}</div>
    <div class="warn-text" [innerHTML]="modalNote"></div>
    <button type="button" class="btn btn-ok" (click)="goPayment()" *ngIf="!isOperatorRole">
      {{ 'button.replenishBalance' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #runningOutPromoModalTemplate>
  <div class="modal-header pablo"></div>
  <div class="modal-body zero-up">
    <div class="no-money"></div>
    <div class="warn-title">{{ 'runningOutPromoModal.title' | translate }}</div>
    <div class="warn-text" [innerHTML]="modalText"></div>
    <button type="button" class="btn btn-ok" (click)="closeModal()" *ngIf="isOperatorRole">
      {{ 'button.promoClose' | translate }}
    </button>
    <button type="button" class="btn btn-ok" (click)="goPayment()" *ngIf="!isOperatorRole">
      {{ 'button.replenishBalance' | translate }}
    </button>
    <button type="button" class="btn btn-close" (click)="closeModal()" *ngIf="!isOperatorRole">
      {{ 'button.promoCancel' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #promotionPromoModalTemplate>
  <div class="modal-header pablo"></div>
  <div class="modal-body zero-up">
    <div class="promotion"></div>
    <div class="warn-title">{{ 'promotion.promotionModal.title' | translate }}</div>
    <div class="warn-text" [innerHTML]="'promotion.promotionModal.text' | translate"></div>
    <button type="button" class="btn btn-ok" (click)="closeModal()" *ngIf="isOperatorRole">
      {{ 'button.promoClose' | translate }}
    </button>
    <button type="button" class="btn btn-ok" (click)="goPayment()" *ngIf="!isOperatorRole">
      {{ 'button.replenishBalance' | translate }}
    </button>
    <button type="button" class="btn btn-close" (click)="closeModal()" *ngIf="!isOperatorRole">
      {{ 'button.promoCancel' | translate }}
    </button>
  </div>
</ng-template>

<app-popup-news [news]="news" *ngIf="popupIsVisible"></app-popup-news>
