import {Component, OnInit} from '@angular/core';
import {I18nService} from '@app/core';
import {ChatService} from '@app/core/chat.service';

@Component({
  selector: 'app-help-needed',
  templateUrl: './help-needed.component.html',
  styleUrls: ['./help-needed.component.scss'],
})
export class HelpNeededComponent implements OnInit {
  constructor(private chatService: ChatService, private i18nService: I18nService) {}

  ngOnInit(): void {}

  openWidget(): void {
    this.chatService.startChat();
    this.chatService.sendMessage(this.i18nService.translateService.instant('chatHelp'));
  }
}
