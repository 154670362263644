import {
  Directive,
  ElementRef,
  Renderer2,
  HostListener,
  forwardRef,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges, OnDestroy, AfterContentInit, NgZone,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { I18nService } from '@app/core';
import { ToastType } from '@app/core/toasts/toast';
import { ToastService } from '@app/core/toasts/toast.service';
import { UtilsService } from '@app/core/utils.service';
import { PreviewService } from '@app/conversations/preview/preview.service';

const KEYS = {
  'backspace': 8,
  'shift': 16,
  'ctrl': 17,
  'alt': 18,
  'delete': 46,
  'leftArrow': 37,
  'upArrow': 38,
  'rightArrow': 39,
  'downArrow': 40,
};

@Directive({
  selector: '[appTeletypeContentEditable]',
  providers:
    [
      { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ContentEditableDirective), multi: true },
    ]
})
export class ContentEditableDirective implements ControlValueAccessor, OnInit, OnChanges, OnDestroy, AfterContentInit {

  @Input('contenteditable') contentEditable: boolean;
  @Input() propValueAccessor = 'innerText';
  @Input() prefix: any;
  @Input() defaultValue: any = '';
  @Input() maxLength = 1000000;
  @Output() changeText: EventEmitter<any> = new EventEmitter();

  // этот метод никогда не вызывается
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onPaste: EventEmitter<any> = new EventEmitter();

  private onChange: (value: string) => void;
  private onTouched: () => void;
  private removeDisabledState: () => void;
  private currentSelection: any;
  private charactersCount: any;

  private util = {
    special: {},
    navigational: {},
    isSpecial(e: any) {
      return typeof this.special[e.keyCode] !== 'undefined';
    },
    isNavigational(e: any) {
      return typeof this.navigational[e.keyCode] !== 'undefined';
    },
  };

  private translations: any;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private utils: UtilsService,
    private previewService: PreviewService,
    private toastService: ToastService,
    private zone: NgZone,
    private i18nService: I18nService,
  ) {
    this.translations = this.i18nService.translateService.translations[this.i18nService.language];
  }

  ngOnInit() {
    this.util.special[KEYS['backspace']] = true;
    this.util.special[KEYS['shift']] = true;
    this.util.special[KEYS['ctrl']] = true;
    this.util.special[KEYS['alt']] = true;
    this.util.special[KEYS['delete']] = true;

    this.util.navigational[KEYS['upArrow']] = true;
    this.util.navigational[KEYS['downArrow']] = true;
    this.util.navigational[KEYS['leftArrow']] = true;
    this.util.navigational[KEYS['rightArrow']] = true;

    if (this.elementRef.nativeElement) {
      let html = this.utils.parseMessage(this.defaultValue.replace(/\n/g, '<br>'), false, false);;

      if (this.prefix) {
        html = `<span style="margin-right: 5px;font-weight: 500;">${this.prefix || ''}</span>` + html;
      }

      this.elementRef.nativeElement.innerHTML = html;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contentEditable && changes.contentEditable.currentValue !== null) {
      this.contentEditable = changes.contentEditable.currentValue;
      this.setDisabledState(!this.contentEditable);
    }
  }

  public ngAfterContentInit(): void {
    this.elementRef.nativeElement.addEventListener('keydown', this.handler.bind(this));
    this.elementRef.nativeElement.addEventListener('paste', this.handler.bind(this));
  }

  public ngOnDestroy(): void {
    this.elementRef.nativeElement.removeEventListener('keydown', this.handler.bind(this));
    this.elementRef.nativeElement.removeEventListener('paste', this.handler.bind(this));
  }

  @HostListener('input')
  callOnChange() {
    let text = this.elementRef.nativeElement.innerHTML;
    if (text.length > this.maxLength) {
      text = text.substring(0, this.maxLength);
    }
    const withoutEmoji = this.utils.replaceImageEmoji(text);
    const plainText = this.utils.trimTextFromHtml(withoutEmoji);
    this.changeText.emit(plainText);
    if (typeof this.onChange === 'function') {
      this.onChange(text);
    }
  }

  @HostListener('blur')
  callOnTouched() {
    if (typeof this.onTouched === 'function') {
      this.onTouched();
    }
  }

  @HostListener('paste', ['$event'])
  callPaste(event: any) {
    event.preventDefault();
    event.stopImmediatePropagation();

    if (event.clipboardData?.types?.indexOf('Files') > -1) {
      this.previewService.retrieveImageFromClipboardAsBlob(event);
      return;
    }

    let text = event.clipboardData.getData('text/plain');

    if (text.length + this.elementRef.nativeElement[this.propValueAccessor].length > this.maxLength) {
      text = text.substring(0, this.maxLength - this.elementRef.nativeElement.innerText.length);

      this.zone.run(() => {
        this.toastService.addSimpleToast({
          title: this.translations.toasts.warning,
          text: this.translations.conversation.tooLongPaste,
          type: ToastType.Error,
        });
      });
    }

    text = this.htmlEntities(text);
    text = text.replace(/\r/g, '');
    text = text.replace(/\n/g, '<br/>');

    // если пользователь копировал текст внутри которого есть последовательность '&reg' (например url),
    // то html парсер определял ее как символ ®
    // пришлось явно декодировать этот символ в юникод формат
    // https://stackoverflow.com/questions/15532252/why-is-reg-being-rendered-as-without-the-bounding-semicolon
    text = text.replaceAll('&', '&amp;');

    this.clipboardCallback(text, true);
  }

  private handler(event: any) {
    let hasSelection = false;
    this.currentSelection = this.saveSelection();
    this.charactersCount = this.getCharactersLen();
    const len = this.charactersCount;
    const selection = this.currentSelection;
    const isSpecial = this.util.isSpecial(event);
    const isNavigational = this.util.isNavigational(event);

    // shift сюда не добавил, потому что вставка \n - немного другое поведение, которое немного ломает работу с эмодзи
    if (event.code === 'Enter' && (event.metaKey || event.ctrlKey || event.altKey)) {
      event.preventDefault();
      this.clipboardCallback('\n');
    }

    if (selection) {
      hasSelection = !!selection.toString();
    }

    if (isSpecial || isNavigational) {
      return true;
    }

    if (len >= this.maxLength && !hasSelection) {
      event.preventDefault();
      return false;
    }
  }

  private getCharactersLen(_msg: string = ''): number {
    const msg = this.normalizeMessage(_msg || this.elementRef.nativeElement.innerHTML);
    const elm = document.createElement('div');
    elm.innerHTML = msg;
    const imagesLen = elm.getElementsByTagName('img').length;
    return (elm.textContent || elm.innerText || '').length + imagesLen;
  }

  normalizeMessage(msg: string = ''): string {
    return msg.replace(/(&nbsp;)/g, ' ');
  }

  restoreSelection(savedSelection: any) {
    if (window.getSelection) {
      const sel = window.getSelection();
      sel.removeAllRanges();
      for (let i = 0, len = savedSelection.length; i < len; ++i) {
        sel.addRange(savedSelection[i]);
      }
    } else if ((<any>document).selection && (<any>document).selection.createRange) {
      if (savedSelection) {
        savedSelection.select();
      }
    }
  }

  replaceSelection(content: any) {
    if (window.getSelection) {
      let range;
      const sel = window.getSelection();
      const node = typeof content === 'string' ? document.createTextNode(content) : content;

      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0);
        range.deleteContents();
        range.insertNode(node);
        range.setStart(node, 0);

        window.setTimeout(function() {
          range = document.createRange();
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
        }, 0);
      }
    } else if ((<any>document).selection && (<any>document).selection.createRange) {
      const range = (<any>document).selection.createRange();
      if (typeof content === 'string') {
        range.text = content;
      } else {
        range.pasteHTML(content.outerHTML);
      }
    }
  }

  saveSelection() {
    if (window.getSelection) {
      const sel = window.getSelection();
      const node = (<any>sel.anchorNode);

      if (node && node.parentNode && !node.parentNode.closest('[contenteditable]')) {
        return this.currentSelection;
      }
      const ranges = [];
      if (sel.rangeCount) {
        for (let i = 0, len = sel.rangeCount; i < len; ++i) {
          ranges.push(sel.getRangeAt(i));
        }
      }
      return ranges;
    } else if ((<any>document).selection && (<any>document).selection.createRange) {
      const sel = (<any>document).selection;
      return (sel.type.toLowerCase() !== 'none') ? sel.createRange() : null;
    }
  }

  /**
   * Writes a new value to the element.
   * This method will be called by the forms API to write
   * to the view when programmatic (model -> view) changes are requested.
   *
   * See: [ControlValueAccessor](https://angular.io/api/forms/ControlValueAccessor#members)
   */
  writeValue(value: any): void {
    if (this.prefix) {
      value = `<span style="margin-right: 5px;font-weight: 500;">${this.prefix || ''}</span>` + value;
    }
    if (value && value.length > this.maxLength) {
      value = value.substring(0, this.maxLength);
    }
    const normalizedValue = value == null ? '' :  value;
    this.renderer.setProperty(this.elementRef.nativeElement, this.propValueAccessor, normalizedValue);
  }

  /**
   * Registers a callback function that should be called when
   * the control's value changes in the UI.
   *
   * This is called by the forms API on initialization so it can update
   * the form model when values propagate from the view (view -> model).
   */
  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function that should be called when the control receives a blur event.
   * This is called by the forms API on initialization so it can update the form model on blur.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * This function is called by the forms API when the control status changes to or from "DISABLED".
   * Depending on the value, it should enable or disable the appropriate DOM element.
   */
  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', 'true');
      this.removeDisabledState =
        this.renderer.listen(this.elementRef.nativeElement, 'keydown', this.listenerDisabledState);
    } else {
      if (this.removeDisabledState) {
        this.renderer.removeAttribute(this.elementRef.nativeElement, 'disabled');
        this.removeDisabledState();
      }
    }
  }

  private listenerDisabledState(e: KeyboardEvent) {
    e.preventDefault();
  }

  private clipboardCallback(content: string, asHtml: boolean = false) {
    content = this.utils.parseMessage(content, false, false);
    if (document.queryCommandSupported(asHtml ? 'insertHTML' : 'insertText')) {
      document.execCommand(asHtml ? 'insertHTML' : 'insertText', false, content);
    } else {
      document.execCommand('paste', false, content);
    }
  }

  private htmlEntities(text: string): string {
    return String(text)
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
  }
}
