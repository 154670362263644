<div class="login-form-container">
  <div class="form-container">
    <form
      class="register-form" (submit)="completeRegistration()"
      [formGroup]="completeRegistrationForm" #registerFormForm="ngForm" novalidate
    >

      <div class="logo pointer" (click)="goToLanding()"></div>
      <h1 [innerHTML]="'newRegistrationComplete.yourProfile' | translate"></h1>
      <app-alerts></app-alerts>
      <label class="page-desc" [innerHTML]="'newRegistrationComplete.yourProfileDesc' | translate"></label>

      <ng-container *ngIf="isUserLoaded; else loader">
        <div
          class="form-group"
          [ngClass]="{'error': showErrorToView('name'),
           'empty': completeRegistrationForm.get('name').hasError('required')}"
        >
          <label class="caption">{{ 'newRegistrationComplete.whatIsYourName' | translate }}</label>
          <input
            type="text" class="ym-record-keys form-control" appAutofocus formControlName="name" autocomplete="off"
            [placeholder]="'newRegistrationComplete.namePlaceholder' | translate"
            (blur)="handleFieldBlur('name'); sendAnalyticsEvent('PROFILE_NAME')" required
          />
          <span *ngIf="completeRegistrationForm.get('name').hasError('pattern')" class="form-group-error">
            {{ 'error.onlyLetter' | translate }}
      </span>
          <span class="form-group-help" *ngIf="!showErrorToView('name')">
          {{ 'newRegistrationComplete.nameDescription' | translate }}
      </span>
        </div>

        <div
          class="form-group"
          [ngClass]="{'error': showErrorToView('phone'),
           'empty': completeRegistrationForm.get('phone').hasError('required')}"
        >
          <label class="caption">{{ 'newRegistrationComplete.phone' | translate }}</label>
          <input
            type="tel" class="ym-record-keys form-control" formControlName="phone" autocomplete="off"
            [placeholder]="'+79219997788'"
            (blur)="handleFieldBlur('phone'); sendAnalyticsEvent('PROFILE_PHONE')"
            [required]="completeRegistrationForm.get('phone').hasValidator(Validators.required)"
          />
          <span *ngIf="completeRegistrationForm.get('phone').hasError('pattern')" class="form-group-error">
            {{ 'error.phoneInvalid' | translate }}
          </span>
          <span class="form-group-help" *ngIf="!showErrorToView('phone')">
              {{ 'newRegistrationComplete.phoneDescription' | translate }}
          </span>
        </div>

        <ng-container *ngIf="!hasExternalAuth">
          <div class="form-group">
            <label class="caption confirm-channel-title">{{ 'newRegistrationComplete.confirmRegistrationTitle' | translate }}</label>

            <label class="confirm-channel">
              <input
                type="radio"
                name="prior-messenger"
                formControlName="priorMessenger"
                [value]="PriorMessengerOptions.WhatAapp"
              >
              <span class="radio-indicator"></span>
              <app-channel-type-icon class="channel-icon" channelType="whatsapp"></app-channel-type-icon>
              WhatsApp
            </label>

            <label class="confirm-channel">
              <input
                type="radio"
                name="prior-messenger"
                formControlName="priorMessenger"
                [value]="PriorMessengerOptions.Telegram"
              >
              <span class="radio-indicator"></span>
              <app-channel-type-icon class="channel-icon" channelType="telegram"></app-channel-type-icon>
              Telegram
            </label>

            <label class="confirm-channel">
              <input
                type="radio"
                name="prior-messenger"
                formControlName="priorMessenger"
                [value]="PriorMessengerOptions.SMS"
              >
              <span class="radio-indicator"></span>
              <span class="channel-icon sms-icon"></span>
              SMS
            </label>
          </div>
        </ng-container>

        <div
          class="form-group company" [ngClass]="{'error': errorsList.companyName,
           'empty': completeRegistrationForm.get('companyName').hasError('required')}"
        >
          <label class="caption">{{ 'newRegistrationComplete.companyName' | translate }}</label>
          <input
            type="url" class="ym-record-keys form-control" formControlName="companyName" autocomplete="off"
            [placeholder]="'newRegistrationComplete.companyPlaceholder' | translate"
            (blur)="handleFieldBlur('companyName'); sendAnalyticsEvent('PROFILE_SITE')"
          />
          <span
            *ngIf="completeRegistrationForm.get('companyName').hasError('pattern')"
            class="form-group-error"
          >
          {{ 'error.invalidField' | translate }}
      </span>
        </div>

        <div class="split">
          <div
            class="form-group company" [ngClass]="{'error': errorsList.companyScope,
           'empty': completeRegistrationForm.get('companyScope').hasError('required')}"
          >
            <select required class="form-control" formControlName="companyScope" (change)="onCompanyScopeSelect()">
              <option hidden value="null">{{ 'registration.companyRolePlaceHolder' | translate }}</option>
              <option *ngFor="let scope of companyScopes" [value]="scope.name"> {{ scope.name }}</option>
            </select>
          </div>

          <div
            class="form-group company" [ngClass]="{'error': errorsList.companyRole,
           'empty': completeRegistrationForm.get('companyRole').hasError('required')}"
          >
            <select
              required
              class="form-control"
              formControlName="companyRole"
              (change)="onCompanyRoleSelect()"
            >
              <option hidden value="null">{{ 'registration.positionInCompanyPlaceHolder' | translate }}</option>
              <option *ngFor="let role of companyRoles" [value]="role.name"> {{ role.name }}</option>
            </select>
          </div>
        </div>

        <div class="estimated-employees">
          <label class="caption">{{ 'newRegistrationComplete.companyEmployeeAmount' | translate }}</label>
          <div class="company-size-selector-container">
            <button
              type="button"
              (click)="setUsersCount(size.value)"
              class="company-size-selector"
              *ngFor="let size of usersCounts"
              [ngClass]="{'active': completeRegistrationForm.get('usersCount').value === size.value}"
            >
              {{ size.display }}
            </button>
          </div>
        </div>

        <app-alert *ngIf="error" [alertContent]="error"></app-alert>

        <div class="buttons-container">
          <button class="btn btn-primary with-spinner" type="submit" [disabled]="isLoading">
            <app-spinner [inProgress]="isLoading" [btnText]="'registration.createBtn' | translate"></app-spinner>
          </button>
        </div>

        <p class="warning-req" *ngIf="!hasExternalAuth" [innerHTML]="'newRegistrationComplete.allFieldsAreRequired' | translate"></p>
        <p class="warning-req" *ngIf="hasExternalAuth" [innerHTML]="'newRegistrationComplete.allFieldsExceptPhoneAreRequired' | translate"></p>
      </ng-container>
    </form>
  </div>
</div>


<ng-template #loader>
  <app-loader class="loader" [isLoading]="true"></app-loader>
</ng-template>
