<span [class.now]="isNow">
  <ng-template [ngIf]="!status && !seen">{{ dateFormated }}</ng-template>
  <ng-template [ngIf]="status && status.toString() === '30' && !seen">
      {{ dateFormated }}{{!withOutStatus && '.' || ''}} {{ (!withOutStatus && 'conversation.delivered' | translate) ||
    '' }}
  </ng-template>
  <ng-template [ngIf]="seen">
      {{ dateFormated }}{{!withOutStatus && '.' || ''}} {{ (!withOutStatus && 'conversation.seen' | translate) || '' }}
  </ng-template>
  <ng-template [ngIf]="status && status.toString() === '10' && !seen">
      {{ dateFormated }}{{!withOutStatus && '.' || ''}} {{ 'conversation.sending' | translate }}
  </ng-template>
</span>
