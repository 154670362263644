// `.env.ts` is generated by the `npm run env` command
import { env } from './.env';
// import { firebaseConfig } from '@env/firebase.prod';

export const environment = {
  production: true,
  productionEnv: false,
  switchDomainByProject: true,
  version: env.version,
  baseDomain: 'panel.teletypeapp.com',
  apiUrl: '/api',
  landingUrl: 'https://teletypeapp.com',
  wsUrl: 'https://websocket.teletypeapp.com',
  cookiesOrigin: '.teletypeapp.com',
  assetsUrl: '/',
  apiVersion: '1',
  defaultLanguage: 'ru',
  supportedLanguages: [
    'en',
    'ru'
  ],
  giphyApiKey: 'OGGZ65V9rVsxTpHDUCMegJn7EJDMB2bl',
  oneSignalAppId: 'f0bee40f-5b2f-455e-8bd9-47580704417a',
  // firebaseConfig: firebaseConfig,
  widgetBaseDomain: 'teletypeapp.com',
  supportWidgetId: 'fPOcCt4uSnTiYQ-XD5bQb1nGSRfasXQ5O6op3SlOn5T3rjz5Vw68VyqRj5w3UCTC',
  vkAppId: '7009563',
  urlDoc: 'https://docs.google.com/spreadsheets/d/1-mXLLWC1eiwU4PU2S1c_gkFedCXVtg4NhRWA9Mcin9M/edit?usp=sharing',
  facebookId: '378158379475893',
  features: {
    ping: true,
    messageEdit: false,
    unsubscribe: false,
    onlyRuLanguage: false,
    groups: false,
    statistics: true,
    'affiliate-program': true,
    'tariff-setup': true,
    'public-api': true,
    'payment': true,
    'transaction': true,
    channels: {
      'instagramComments': true,
      'instagramCommentsBusiness': true,
    },
    dateFormat: {
      ru: 'dd MMM',
      en: 'LLL dd'
    },
    telegramBotInProfile: true,
    refillAccount: {
      amount: 0,
      minAmount: '5000',
      amountFrom1: '10 000 ₽',
      amountTo1: '19 999 ₽',
      bonus1: '+10%',
      amountFrom2: '20 000 ₽',
      amountTo2: '49 999 ₽',
      bonus2: '+15%',
      amountFrom3: '50 000 ₽',
      bonus3: '+25%',
      amountLess: 1000,
      refillToAmount: 10000
    }
  },
  metrikaId: '61556212'
};
