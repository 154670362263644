import { CompressedEmojiData } from './data.interfaces';
// @ts-ignore
import * as _emojis from './json/emojis.json';
// @ts-ignore
import * as _fullEmojis from './json/emojis.full.json';

// @ts-ignore
export const emojis: CompressedEmojiData[] = _emojis.default;
// @ts-ignore
export const fullEmojis: CompressedEmojiData[] = _fullEmojis.default;
