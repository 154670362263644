import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/api/api.service';
import { routes } from '@app/core/config/apiRoutes';
import { UtilsService } from '@app/core/utils.service';

export interface TemplateMessage {
  id?: string;
  key: string;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class CategoriesApiService {
  private routes = routes;
  constructor(
    private api: ApiService,
    private utils: UtilsService,
  ) {}

  getListCategory() {
    return this.api.httpGet(this.routes.listCategories);
  }

  createCategory(data: { category: string; description: string }) {
    return this.api.httpPost(this.routes.createCategory, this.utils.snakeKeys(data));
  }

  updateCategory(id: string, data: { category: string; description: string }) {
    return this.api.httpPost(this.routes.updateCategory(id), this.utils.snakeKeys(data));
  }

  deleteCategory(id: string) {
    return this.api.httpGet(this.routes.deleteCategory(id));
  }
}
