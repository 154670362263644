import { Component, Inject, Input, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-ghostinput',
  templateUrl: './ghostinput.component.html',
  styleUrls: ['./ghostinput.component.scss']
})
export class GhostinputComponent implements OnInit {
  private _suffix = '';
  private _prefix = '';
  @Input() content = '';
  @Input() placeholder = '';

  get suffix() {
    return this._suffix;
  }

  @Input()
  set suffix(host: string) {
    this._suffix = this.handleHost(host);
  }

  get prefix() {
    return this._prefix;
  }

  @Input()
  set prefix(host: string) {
    this._prefix = this.handleHost(host);
  }

  constructor(
    @Inject(DOCUMENT) private document: any
  ) {
  }

  handleHost(host: string) {
    if (host === 'hostname') {
      const teletypeHost = this.document.location.hostname.match(/\w+\.\w+$/);

      if (teletypeHost) {
        return `.${teletypeHost[0]}`;
      }

      return `.${this.document.location.hostname}`;
    }

    return host;
  }

  ngOnInit() {
  }
}
