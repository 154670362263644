export const AvatarMimeTypes = [
  'image/jpeg',
  'image/png',
];
export const ImageMimeTypes = [
  'image/bmp',
  'image/x-bmp',
  'image/x-bitmap',
  'image/x-xbitmap',
  'image/x-win-bitmap',
  'image/x-windows-bmp',
  'image/ms-bmp',
  'image/x-ms-bmp',
  'application/bmp',
  'application/x-bmp',
  'application/x-win-bitmap',
  'application/cdr',
  'application/coreldraw',
  'application/x-cdr',
  'application/x-coreldraw',
  'image/cdr',
  'image/x-cdr',
  'zz-application/zz-winassoc-cdr',
  'image/x-icon',
  'image/x-ico',
  'image/vnd.microsoft.icon',
  'image/jp2',
  'image/jpx',
  'image/jpm',
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/x-png',
  'application/x-photoshop',
  'image/vnd.adobe.photoshop',
  // 'image/tiff', // т к браузеры не умеют отображать .tif пришлось убрать их из спикска изображений
  'image/webp',
  'image/gif',
];
export const AudioMimeTypes = [
  'audio/x-acc',
  'audio/ac3',
  'audio/x-aiff',
  'audio/aiff',
  'audio/x-au',
  'audio/x-flac',
  'audio/x-m4a',
  'audio/midi',
  'audio/mpeg',
  'audio/mpg',
  'audio/mpeg3',
  'audio/mp3',
  'audio/ogg',
  'audio/x-realaudio',
  'audio/x-pn-realaudio',
  'audio/x-pn-realaudio-plugin',
  'audio/x-wav',
  'audio/wave',
  'audio/wav',
  'audio/x-ms-wma',
];
export const VideoMimeTypes = [
  'video/3gp',
  'video/3gpp2',
  'video/3gpp',
  'video/x-msvideo',
  'video/msvideo',
  'video/avi',
  'application/x-troff-msvideo',
  'application/x-director',
  'application/x-dvi',
  'video/x-f4v',
  'video/x-flv',
  'video/mj2',
  'application/vnd.mpegurl',
  'video/mp4',
  'video/mpeg',
  'video/quicktime',
  'video/x-sgi-movie',
  'video/ogg',
  'application/ogg',
  'video/vnd.rn-realvideo',
  'application/videolan',
  'video/webm',
  'video/x-ms-wmv',
  'video/x-ms-asf',
  'application/x-shockwave-flash',
];
export const OtherTypes = [
  'video/3gp',
  'application/x-compressed',
  'audio/x-acc',
  'audio/ac3',
  'audio/x-aiff',
  'audio/aiff',
  'audio/x-au',
  'video/x-msvideo',
  'video/msvideo',
  'video/avi',
  'application/x-troff-msvideo',
  'image/bmp',
  'image/x-bmp',
  'image/x-bitmap',
  'image/x-xbitmap',
  'image/x-win-bitmap',
  'image/x-windows-bmp',
  'image/ms-bmp',
  'image/x-ms-bmp',
  'application/bmp',
  'application/x-bmp',
  'application/x-win-bitmap',
  'application/cdr',
  'application/coreldraw',
  'application/x-cdr',
  'application/x-coreldraw',
  'image/cdr',
  'image/x-cdr',
  'zz-application/zz-winassoc-cdr',
  'application/mac-compactpro',
  'application/pkix-crl',
  'application/pkcs-crl',
  'application/x-x509-ca-cert',
  'application/pkix-cert',
  'text/css',
  'text/x-comma-separated-values',
  'text/comma-separated-values',
  'application/vnd.msexcel',
  'application/x-director',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/x-dvi',
  'message/rfc822',
  'video/x-f4v',
  'audio/x-flac',
  'application/gpg-keys',
  'application/x-gtar',
  'application/x-gzip',
  'application/mac-binhex40',
  'application/mac-binhex',
  'application/x-binhex40',
  'application/x-mac-binhex40',
  'text/html',
  'image/x-icon',
  'image/x-ico',
  'text/calendar',
  'application/java-archive',
  'application/x-java-application',
  'application/x-jar',
  'image/jp2',
  'video/mj2',
  'image/jpx',
  'image/jpm',
  'application/json',
  'text/json',
  'application/vnd.google-earth.kml+xml',
  'application/vnd.google-earth.kmz',
  'text/x-log',
  'audio/x-m4a',
  'application/vnd.mpegurl',
  'audio/midi',
  'application/vnd.mif',
  'video/x-sgi-movie',
  'audio/mpg',
  'audio/mpeg3',
  'audio/mp3',
  'application/oda',
  'application/ogg',
  'application/x-pkcs10',
  'application/pkcs10',
  'application/x-pkcs12',
  'application/x-pkcs7-signature',
  'application/pkcs7-mime',
  'application/x-pkcs7-mime',
  'application/x-pkcs7-certreqresp',
  'application/pkcs7-signature',
  'application/pdf',
  'application/octet-stream',
  'application/x-x509-user-cert',
  'application/x-pem-file',
  'application/pgp',
  'image/x-png',
  'application/powerpoint',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-office',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/x-photoshop',
  'image/vnd.adobe.photoshop',
  'image/heic',
  'image/heif',
  'image/vnd.adobe.photoshop',
  'audio/x-realaudio',
  'audio/x-pn-realaudio',
  'application/x-rar',
  'application/rar',
  'application/x-rar-compressed',
  'audio/x-pn-realaudio-plugin',
  'application/x-pkcs7',
  'text/rtf',
  'text/richtext',
  'video/vnd.rn-realvideo',
  'application/x-stuffit',
  'application/smil',
  'text/srt',
  'application/x-shockwave-flash',
  'application/x-tar',
  'application/x-gzip-compressed',
  'text/plain',
  'text/x-vcard',
  'application/videolan',
  'text/vtt',
  'audio/x-wav',
  'audio/wave',
  'audio/wav',
  'application/wbxml',
  'application/wmlc',
  'video/x-ms-asf',
  'application/xhtml+xml',
  'application/excel',
  'application/msexcel',
  'application/x-msexcel',
  'application/x-ms-excel',
  'application/x-excel',
  'application/x-dos_ms_excel',
  'application/xls',
  'application/x-xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/xml',
  'text/xml',
  'text/xsl',
  'text/csv',
  'application/xspf+xml',
  'application/x-compress',
  'application/x-zip',
  'application/zip',
  'application/x-zip-compressed',
  'application/s-compressed',
  'multipart/x-zip'
];

export const FileTypesList = OtherTypes.concat(AudioMimeTypes.concat(VideoMimeTypes.concat(ImageMimeTypes)));

export const InstagramTypesList = [
  'video/mp4',
  'image/jpeg',
  'image/png',
];

export const InstagramExtensionFilesList = [
  '.jpeg',
  '.jpg',
  '.png',
  '.mp4'
];

export const ExtensionFilesList = [
  '.jpeg',
  '.jpg',
  '.png',
  '.gif',
  '.pjpeg',
  '.svg',
  '.tif',
  '.tiff',
  '.wbmp',
  '.webp',
  '.au',
  '.mp4a',
  '.mpga',
  '.oga',
  '.wma',
  '.wax',
  '.weba',
  '.mpeg',
  '.mp4',
  '.ogv',
  '.qt',
  '.webm',
  '.wmv',
  '.flv',
  '.3gp',
  '.3g2',
  '.aif',
  '.avi',
  '.bmp',
  '.xbm',
  '.cpt',
  '.crl',
  '.der',
  '.cer',
  '.css',
  '.dir',
  '.docx',
  '.dvi',
  '.eml',
  '.f4v',
  '.gtar',
  '.hqx',
  '.html',
  '.ico',
  '.ics',
  '.jar',
  '.mj2',
  '.json',
  '.kml',
  '.kmz',
  '.mid',
  '.mif',
  '.movie',
  '.oda',
  '.ogx',
  '.p10',
  '.p12',
  '.p7m',
  '.p7r',
  '.p7s',
  '.pdf',
  '.bin',
  '.pgp',
  '.ppt',
  '.thmx',
  '.doc',
  '.pptx',
  '.ram',
  '.rar',
  '.rmp',
  '.rtx',
  '.sit',
  '.smi',
  '.tar',
  '.txt',
  '.vcf',
  '.wav',
  '.asf',
  '.xhtml',
  '.xlsx',
  '.dwg',
  '.xls',
  '.xml',
  '.xspf',
  '.zip',
  '.csv'
];
