import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  ViewChild,
  TemplateRef
} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { isNavigationEnd } from '@app/core/helpers/isNavigationEnd';
import { PermissionsService } from '@app/core/permissions.service';
import { WebsocketsService, WsEvent } from '@app/core/websockets.service';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { ProjectService } from '@app/core/project.service';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { WEBSOCKET_CHECK_CREDENTIALS } from '@app/core/config/timeouts';
import { AlertsService } from '@app/core/alerts/alerts.service';
import { UserService } from '../user.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomModalService } from '../custom-modal.service';
import each from 'lodash-es/each';
import { FeatureAccessService } from '@app/core/feature-access.service';
import { I18nService } from '@app/core/i18n.service';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import { PromoDays, TariffService } from '@app/core/tariff.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CacheService } from '@app/core/cache.service';
import { ChannelService } from '@app/core/channel.service';
import { CookiesService } from '@app/core/cookies.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ShellResolver } from '@app/core/shell/shell.resolver';
import { UtilsService } from '@app/core/utils.service';
import { RegistrationApiService } from '@app/core/api/api.registration';
import { News, ProjectData } from '@app/core/api/api.project';
import { UserData } from '@app/core/api/api.profile';
import { MetrikaService, ANALYTICS_EVENT } from '../metrika.service';
import { FaceBookPixelService } from '@app/core/fbpixel.service';
import { GoogleTagService } from '@app/core/gtag.service';
import { NewsService } from '@app/core/news.service';
import { Observable, Subject } from 'rxjs';

const MAX_AUTH_COUNT = 3;

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false
})
export class ShellComponent implements OnInit, OnDestroy {

  @ViewChild('declineModalTemplate', {static: true}) public declineModalTemplate: TemplateRef<any>;
  @ViewChild('noMoneyModalTemplate', {static: true}) public noMoneyModalTemplate: TemplateRef<any>;
  @ViewChild('runningOutPromoModalTemplate', {static: true}) public runningOutPromoModalTemplate: TemplateRef<any>;
  @ViewChild('promotionPromoModalTemplate', {static: true}) public promotionPromoModalTemplate: TemplateRef<any>;
  @ViewChild('runningOutMoneyModalTemplate', {static: true}) public runningOutMoneyModalTemplate: TemplateRef<any>;
  @ViewChild('onBoardingModalTemplate', {static: true}) public onBoardingModalTemplate: TemplateRef<any>;
  @ViewChild('newsTemplate', {static: true}) public newsTemplate: TemplateRef<any>;

  public hasProject: boolean;
  public isLoading = false;

  public modalRef: BsModalRef;
  public modalText: string;
  public modalNote: string;
  public dailyPayment: number;
  public balance: number;
  public promisedPayment: number;
  public dailyPaymentByPrice: number;
  public availableDays: number;
  public promoDays: PromoDays;
  public isOperatorRole: boolean;
  public isAllowOperator: boolean;
  public popupIsVisible: boolean;
  public news$: Observable<News>;
  public news: News;
  public newsIsVisible$: Observable<boolean>;

  public hideSidebar = false;
  public hideHeader = false;

  private unsub$ = new Subject<void>();
  private allowUserDataSubscribe$ = new Subject<void>();
  private userWasAuth = false;
  private userWasAuthTimer = null;
  private userWasAuthAlertErrorId = null;
  private translations: any;
  private tryCount = 0;
  private isAdmin: boolean;
  private paid: boolean;
  private hasPaidOptions: boolean;
  private readonly isMobile: boolean;
  private modalIsShown: boolean;
  private tryAuthCount = 0;

  constructor(
    private auth: AuthenticationService,
    private meta: Meta,
    private ws: WebsocketsService,
    private projectService: ProjectService,
    private detector: ChangeDetectorRef,
    private alerts: AlertsService,
    private api: RegistrationApiService,
    private userService: UserService,
    private dialog: CustomModalService,
    private modalService: BsModalService,
    private featureAccessService: FeatureAccessService,
    private i18nService: I18nService,
    private tariffService: TariffService,
    private router: Router,
    private cacheService: CacheService,
    private channelService: ChannelService,
    private cookies: CookiesService,
    private deviceService: DeviceDetectorService,
    private shellResolver: ShellResolver,
    private utils: UtilsService,
    private metrika: MetrikaService,
    private fbPixel: FaceBookPixelService,
    private gtag: GoogleTagService,
    private newsService: NewsService,
    private activatedRoute: ActivatedRoute,
    private permissionsService: PermissionsService,
  ) {
    this.isMobile = this.deviceService.userAgent.toLowerCase().includes('mobi');
    const onNavigationEnd = this.router.events.pipe(filter(event => isNavigationEnd(event)));
    onNavigationEnd
      .pipe(
        takeUntil(this.unsub$),
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
        takeUntil(this.unsub$)
      )
      .subscribe((data) => {
        this.hideSidebar = data.hideSidebar || false;
        this.hideHeader = data.hideHeader || false;

        this.meta.updateTag({
          content: data.responsive ? 'width=device-width, initial-scale=1' : 'width=1280, initial-scale=0, minimum-scale=0.25',
          name: 'viewport'
        });
        this.detector.detectChanges();
      });
  }

  ngOnInit() {
    this.translations = this.i18nService.translateService.translations[this.i18nService.language];
    this.tariffService.getDailyPayment().pipe(takeUntil(this.unsub$))
      .subscribe((dailyPayment: number) => {
        if (dailyPayment) {
          this.dailyPayment = dailyPayment;
        }
      });

    this.tariffService.getBalance().pipe(takeUntil(this.unsub$))
      .subscribe((balance: number) => {
        if (balance) {
          this.balance = balance;
        }
      });

    this.tariffService.getPromisedPayment().pipe(takeUntil(this.unsub$))
      .subscribe((payment: number) => {
        if (payment) {
          this.promisedPayment = payment;
        }
      });

    this.tariffService.getDailyPaymentByPrice().pipe(takeUntil(this.unsub$))
      .subscribe((dailyPaymentByPrice: number) => {
        if (dailyPaymentByPrice) {
          this.dailyPaymentByPrice = dailyPaymentByPrice;
        }
      });

    this.tariffService.getAvailableDays().pipe(takeUntil(this.unsub$))
      .subscribe((availableDays: number) => {
        this.availableDays = availableDays;
      });

    this.tariffService.getPromoDays().pipe(takeUntil(this.unsub$))
      .subscribe((promoDays: PromoDays) => {
        if (!promoDays) {
          return;
        }
        this.promoDays = promoDays;
      });

    this.tariffService.getPaidState().pipe(takeUntil(this.unsub$))
      .subscribe((paid: boolean) => {
        this.paid = paid;
      });

    this.tariffService.getTariffs().pipe(takeUntil(this.unsub$))
      .subscribe((tariffs: any) => {
        each(tariffs, tariff => {
          if (tariff.cost[tariff.count - tariff.quantityForFree] > 0) {
            this.hasPaidOptions = true;
          }
        });
      });
    this.projectService.getProjects()
      .pipe(takeUntil(this.unsub$))
      .subscribe((projects: ProjectData[]) => {
        if (projects) {
          this.hasProject = projects.length > 0;
          this.detector.detectChanges();
        }
      });

    this.userService.getUserData()
      .pipe(takeUntil(this.allowUserDataSubscribe$))
      .subscribe((data: UserData) => {
        if (data) {
          this.isAdmin = this.permissionsService.isAdmin();
          this.newsService.newsIsVisible$.subscribe(isVisible => {
            this.popupIsVisible = isVisible;
          });

          this.newsService.news$.subscribe(news => {
            this.news = news;
            if (news) {
              this.popupIsVisible = this.news.popup.enabled;
            }
          });
          this.allowUserDataSubscribe$.next();
          this.allowUserDataSubscribe$.complete();
          this.channelService.getChannels().pipe(takeUntil(this.unsub$))
            .subscribe((channels: any) => {
              if (!channels) {
                return;
              }
              if (channels && !this.cacheService.get('onboard') && data.status !== 15) {
                const noActiveChannels = channels.length === 0 || (channels.length === 1 &&
                  channels[0].type === 'widget' && channels[0].state === 'pending');
                if (noActiveChannels && !this.router.url.startsWith('/settings/channels')) {
                  // this.cacheService.set('onboard', true);
                } else {
                  const startDay = this.cacheService.get('currentDay');
                  const currentDay = new Date().getDay();
                  if (currentDay !== startDay && !this.modalIsShown) {
                    this.tryCount = 0;
                    if (!this.router.url.startsWith('/settings/channels')) {
                      this.checkPromo();
                      this.cacheService.set('currentDay', currentDay);
                    }
                    if (!this.router.url.startsWith('/settings/start')) {
                      this.checkMoney();
                      this.cacheService.set('currentDay', currentDay);
                    }
                  }
                }
              }
            });
        }
      });

    this.featureAccessService.subscribeDeclinePopUp().pipe(takeUntil(this.unsub$))
      .subscribe((isShow) => {
      if (isShow) {
        this.showPopUp('declineModalTemplate');
      }
    });

    this.featureAccessService.isOperator().pipe(takeUntil(this.unsub$))
      .subscribe((isOperatorRole) => {
      if (isOperatorRole) {
        this.isOperatorRole = isOperatorRole;
        this.detector.detectChanges();
      }
    });

    this.featureAccessService.getAllowOperator().pipe(takeUntil(this.unsub$))
      .subscribe((operator) => {
      if (operator) {
        this.isAllowOperator = operator;
        this.detector.detectChanges();
      }
    });

    this.tariffService.subscribePromotionPopUp().pipe(takeUntil(this.unsub$))
      .subscribe((isShow) => {
        if (isShow) {
          this.showPopUp('promotionPromoModalTemplate');
        }
      });

    this.ws.onConnect()
      .pipe(takeUntil(this.unsub$))
      .subscribe(() => {
        this.ws.on(WsEvent.AUTH_COMPLETE, () => {
          this.removeUserWasAuthAlert();
          this.userWasAuth = true;
          this.tryAuthCount = 0;
        });
        this.ws.on(WsEvent.AUTH_FAILED, () => {
          this.userWasAuth = false;
          this.auth.redirectToLogin();
        });
        this.sendCredentials();
        this.ws.on(WsEvent.AUTH_CHECK, (_data: any) => {
          const data = this.utils.camelKeys(_data);

          if (!data.isAuth) {
            this.auth.redirectToLogin();
          }
        });
        this.runCheckingAuthentication();
      });

    this.ws.onDisconnected()
      .pipe(takeUntil(this.unsub$))
      .subscribe(() => {
        this.userWasAuth = false;
        this.userWasAuthTimer = null;
        this.userWasAuthAlertErrorId = null;
        this.ws.removeListener(WsEvent.AUTH_COMPLETE);
        this.ws.removeListener(WsEvent.AUTH_FAILED);
        this.ws.removeListener(WsEvent.AUTH_CHECK);
      });
  }

  ngOnDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
    this.allowUserDataSubscribe$.next();
    this.allowUserDataSubscribe$.complete();
  }

  private removeUserWasAuthAlert() {
    if (this.userWasAuthAlertErrorId) {
      this.alerts.removeAlert(this.userWasAuthAlertErrorId);
    }
  }

  private runCheckingAuthentication() {
    this.userWasAuthTimer = setInterval(() => {
      if (this.userWasAuth) {
        clearInterval(this.userWasAuthTimer);
      } else if (this.tryAuthCount <= MAX_AUTH_COUNT) {
        this.tryAuthCount += 1;
        this.sendCredentials();
      } else {
        this.auth.redirectToLogin();
      }
    }, WEBSOCKET_CHECK_CREDENTIALS);

    setTimeout(() => {
      if (!this.userWasAuth) {
        this.userWasAuthAlertErrorId = this.alerts.addAlert({
          type: 'error',
          content: this.translations?.lostConnectionWithServer
        });
      }
    }, WEBSOCKET_CHECK_CREDENTIALS * 3.3);
  }

  private sendCredentials() {
    this.ws.sendEvent(WsEvent.AUTH_PERSON, {
      token: this.auth.credentials
    });
  }

  public closeModal(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.modalRef && this.modalRef.hide();
    this.dialog.close();
    if (!this.detector['destroyed']) {
      this.detector.detectChanges();
    }
  }

  public goPayment() {
    this.closeModal();
    setTimeout(() => {
      this.featureAccessService.goPayment();
    });
  }

  private showPopUp(templateName: string, ignoreBackdropClick: boolean = false) {
    const source = { class: 'modal-channels' };
    this.modalRef = this.modalService.show(this[templateName], Object.assign({
      backdrop: true,
      ignoreBackdropClick
    }, source));
  }

  private checkPromo() {
    const promoDaysRemain = this.promoDays && this.promoDays.promoDaysRemain;
    if (promoDaysRemain > 0 && promoDaysRemain < 4 && this.balance === 0 && this.dailyPaymentByPrice !== 0) {
      this.modalText = this.translations.runningOutPromoModal.text.replace('#[SUM]#', this.dailyPaymentByPrice * 30);
      this.showPopUp('runningOutPromoModalTemplate', true);
      this.modalIsShown = true;
    }
  }

  private checkMoney() {
    if (this.promoDays && this.promoDays.promoDaysRemain > 0 || !this.translations) {
      return;
    }

    if (this.promisedPayment) {
      if (this.availableDays > 0) {
        this.modalText = this.translations.runningOutOfMoneyModal.textOverdraft.replace('#[DAYS]', this.availableDays);
      } else {
        this.modalText = this.translations.runningOutOfMoneyModal.textOverdraftTooSmall;
      }
    } else {
      this.modalText = this.translations.runningOutOfMoneyModal.text.replace('#[DAYS]', this.availableDays);
    }

    this.modalNote = this.translations.runningOutOfMoneyModal.note
      .replace('#[SUM]', this.dailyPayment * 30);
    const upTo = this.translations.profile.upTo;

    const date = new Date();
    date.setDate(date.getDate() + this.availableDays);
    const format: DateTimeFormatOptions = {day: 'numeric', month: 'long'};
    const ts = date.getTime();
    const dt = DateTime.fromMillis(ts).setLocale(this.i18nService.language).toLocaleString(format);
    if (this.availableDays) {
      this.modalText += ' (' + upTo + ' ' + dt + ').';
    }

    if (this.availableDays !== undefined && this.isAdmin && this.paid !== undefined &&
      this.hasPaidOptions) {
      let templateName;
      if (!this.paid) {
        templateName = 'noMoneyModalTemplate';
      } else if (this.availableDays < 4) {
        templateName = 'runningOutMoneyModalTemplate';
      } else {
        templateName = undefined;
      }

      if (templateName) {
        if (templateName === 'noMoneyModalTemplate' &&
          ((!this.paid && this.dailyPayment === 0 && !this.hasPaidOptions) ||
            (!this.isAdmin && this.isAllowOperator) || this.paid)) {
          return;
        }
        if (!this.router.url.startsWith('/settings/channels')) {
          this.showPopUp(templateName);
          this.modalIsShown = true;
        }
      }
    } else if (this.tryCount < 20) {
      setTimeout(() => {
        this.tryCount += 1;
        this.checkMoney();
      }, 250);
    }
  }

  sendAnalyticsEvent(event: string | ANALYTICS_EVENT, args: object = null) {
    this.metrika.goal(event as ANALYTICS_EVENT, args);
    this.gtag.track(event as ANALYTICS_EVENT, args);
    this.fbPixel.track(event as ANALYTICS_EVENT);
  }
}
