import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { News, ProjectData } from '@app/core/api/api.project';
import { ProjectService } from '@app/core/project.service';
import { ApiService } from '@app/core/api/api.service';
import { WebsocketsService, WsEvent } from '@app/core/websockets.service';
import { UtilsService } from '@app/core/utils.service';

@Injectable({
  providedIn: 'root',
})
export class NewsService {

  private news = new BehaviorSubject<News>(null);
  private hasNews = new BehaviorSubject<boolean>(false);
  private newsIsVisible = new BehaviorSubject<boolean>(false);

  public get news$() { return this.news.asObservable(); }
  public get hasNews$() { return this.hasNews.asObservable(); }
  public get newsIsVisible$() { return this.newsIsVisible.asObservable(); }

  constructor(
    private projectService: ProjectService,
    private api: ApiService,
    private ws: WebsocketsService,
    private utils: UtilsService,
  ) {
    this.projectService.getProjects().subscribe((projects: ProjectData[]) => {
      if (projects && projects.length > 0) {
        const currentProjectNews = this.projectService.currentProject.news;
        this.news.next(currentProjectNews);
        if (currentProjectNews && currentProjectNews.mark) {
          this.hasNews.next(currentProjectNews.mark.enabled);
        }
      }
    });

    this.ws.on(WsEvent.NEWS_NOTIFICATION, (_data: any) => {
      const data = this.utils.camelKeys(_data);
      const news = this.news.getValue();

      if (data.projectId !== this.projectService.currentProject.id) {
        return;
      }

      if (data.type === 10) {
        news.popup = { ...data };
        news.popup.enabled = true;
        this.news.next(news);
      } else if (data.type === 20) {
        news.mark = { ...data };
        news.mark.enabled = true;
        this.hasNews.next(data.mark.enabled);
      }
    });
  }

  public markNewsAsRead(type: string): void {
    const news = this.news.getValue();
    if (type === 'popup') {
      this.newsIsVisible.next(false);
    } else {
      this.hasNews.next(false);
    }
    this.api.setNewsSeen(news[type].id).subscribe(response => { });
  }
}
