import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-dashed-rectangle',
  templateUrl: './dashed-rectangle.component.html',
  styleUrls: ['./dashed-rectangle.component.scss'],
  preserveWhitespaces: false
})
export class DashedRectangleComponent implements OnInit {

  @ViewChild('svg', { read: ElementRef, static: true }) svg: ElementRef;
  @ViewChild('rect', { read: ElementRef, static: true }) rect: ElementRef;

  @Input() width = 120;
  @Input() height = 120;
  @Input() fill: 'white';
  @Input() stroke = '#d6d6d6';
  @Input() strokeWidth = 1;
  @Input() strokeDashArray = '5, 5';

  public display = false;

  constructor(
    public detector: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.display = true;
      if (!this.detector['destroyed']) {
        this.detector.detectChanges();
      }
    }, 5);
  }

  get _viewBox() {
    return `0 0 ${this._width} ${this._height}`;
  }

  get _width() {
    return Math.round(this.svg.nativeElement.width.baseVal.value || 0).toString();
  }

  get _height() {
    return Math.round(this.svg.nativeElement.height.baseVal.value || 0).toString();
  }

  get _strokeWidth() {
    return this.strokeWidth;
  }

  get _strokeDashArray() {
    return this.strokeDashArray;
  }

}
