import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output, SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AnswerPatternService, PatternsByDirectories } from '@app/core/answer-pattern.service';
import { FeatureAccessService } from '@app/core/feature-access.service';
import { PermissionsService } from '@app/core/permissions.service';
import { UtilsService } from '@app/core/utils.service';
import { map } from 'rxjs/operators';

export interface AutocompleteOption {
  key: string;
  id: string;
  value: string;
  options?: AutocompleteOption[];
}

const isOptionMatch = (search: string = '') => (option: AutocompleteOption) => {
  const searchLower = search.toLowerCase();
  const keyLower = option.key.toLowerCase();

  return keyLower.includes(searchLower);
};

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteComponent {

  @Input() set inputText(newValue: string) {
    newValue = newValue || '';
    newValue = newValue.replace(/&nbsp;/g, ' ');

    const position = this.positionOfStartSymbol(newValue);
    if (position > -1 && !this.activeSearch) {
      this.activeSearch = true;
      this.getOptions();
      this.index = newValue.startsWith('/') ? 0 : position + 1;
      this._fullInputValue = [
        newValue.slice(0, this.index),
        newValue.slice(this.index, this.index + 1),
        newValue.slice(this.index + 1),
      ];
    }

    if (this.activeSearch) {
      this._fullInputValue = [
        this._fullInputValue[0],
        newValue.slice(this._fullInputValue[0].length, newValue.length - this._fullInputValue[2].length),
        this._fullInputValue[2],
      ];
      this.searchableString = this._fullInputValue[1].trim();
      this.filterOptions();
    }

    if (this.activeSearch && (!newValue.startsWith(this._fullInputValue[0]) || this._fullInputValue[1][0] !== '/' || !newValue.endsWith(this._fullInputValue[2]))) {
      this.reset();
    }
  }

  @Input() set ednaTemplatesForChannel(val: string) {
    this.#ednaTemplatesForChannel = val;
    this.isOptionsLoaded = false;
    this.getOptions();
  };
  #ednaTemplatesForChannel = ''

  @Output() onSelected: EventEmitter<{ newValue: string; selectedValue?: string, position: number }> = new EventEmitter();

  @ViewChild('autocompleteContainer', { read: ElementRef }) autocompleteContainer: ElementRef;

  @HostListener('document:keydown.escape', ['$event'])
  handlePressEscape(event: KeyboardEvent) {
    this.reset();
  }

  @HostListener('document:keydown.arrowup', ['$event'])
  handlePressArrowUp(event: KeyboardEvent) {
    if (this.activeSearch) {
      event.preventDefault();
      this.changePreselectedElement('up');
    }
  }

  @HostListener('document:keydown.arrowdown', ['$event'])
  handlePressArrowDown(event: KeyboardEvent) {
    if (this.activeSearch) {
      event.preventDefault();
      this.changePreselectedElement('down');
    }
  }

  @HostListener('document:keydown.enter', ['$event'])
  handlePressEnter(event: KeyboardEvent) {
    if (this.activeSearch) {
      event.preventDefault();
      this.selectOption(this.preSelectedOption);
    }
  }

  @HostListener('document:click', ['$event'])
  clickOutsideComponent(event) {
    if (this.activeSearch && !this.autocompleteContainer.nativeElement.contains(event.target)) {
      this.reset();
    }
  }

  _fullInputValue: string[] = [];
  index;
  activeSearch: boolean = false;
  searchableString = '';
  options: AutocompleteOption[] = [];
  matchedOptions = this.options;
  preSelectedOption: AutocompleteOption;
  preSelectedOptionIndex = 0;
  checkedDirectory: AutocompleteOption;
  isOptionsLoaded = false;

  isTemplatesPaid$ = this.featureAccessService.getAccessStatus('messagesTemplates');
  isAdmin = this.permissionsService.isAdmin();

  get ednaTemplatesForChannel() {
    return this.#ednaTemplatesForChannel;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private featureAccessService: FeatureAccessService,
    private answerPatternService: AnswerPatternService,
    private permissionsService: PermissionsService,
    private sanitizer: DomSanitizer,
    private utils: UtilsService,
  ) {}

  reset() {
    this.index = null;
    this.activeSearch = false;
    this.searchableString = '';
    this.checkedDirectory = null;
    this.cdr.detectChanges();
  }

  scroll() {
    let el = document.getElementsByClassName('_pre-selected')[0];
    if (el) {
      (el as any).scrollIntoViewIfNeeded(false);
    }
  }

  filterOptions() {
    const searchByLevel = this.searchableString.split('/')
      .filter(elem => elem !== '/');
    const searchInGroup = searchByLevel[2];

    if (searchInGroup === undefined && this.checkedDirectory) {
      this.resetDirectory();
    }

    if (searchInGroup === '' && !this.checkedDirectory && this.matchedOptions.length === 1) {
      this.toggleDirectory(this.matchedOptions[0]);
    }

    const search = searchByLevel[this.checkedDirectory ? 2 : 1];

    const searchAmongOptions = this.checkedDirectory ?
      this.options.find(option => option.id === this.checkedDirectory.id).options :
      this.options;

    this.matchedOptions = [...(search ? searchAmongOptions.filter(isOptionMatch(search)) : searchAmongOptions)];

    if (this.checkedDirectory) {
      this.matchedOptions.unshift(this.checkedDirectory);
    }

    this.preSelectedOption = this.matchedOptions[0];
    this.preSelectedOptionIndex = 0;
    this.cdr.detectChanges();
  }

  selectOption(option: AutocompleteOption) {
    if (!option) {
      return;
    }

    if (option.options) {
      this.toggleDirectory(option);
    } else {
      const value = this.utils.replaceImageEmoji(option.value);
      this.reset();
      this.onSelected.emit({
        newValue: this._fullInputValue[0] + value + this._fullInputValue[2],
        selectedValue: value,
        position: this.utils.trimTextFromHtml(this._fullInputValue[0] + value).length - this.utils.trimTextFromHtml(this._fullInputValue[0] + value).split('').filter(a => a === '\n').length,
      });
    }
  }

  private toggleDirectory(option: AutocompleteOption) {
    if (this.checkedDirectory) {
      this.checkedDirectory = null;

      this.onSelected.emit({
        newValue: this._fullInputValue[0] + '/' + this._fullInputValue[2],
        position: (this._fullInputValue[0] + `/`)?.length,
      });
    } else {
      this.checkedDirectory = option;
      this.onSelected.emit({
        newValue: this._fullInputValue[0] + `/${option.key}/` + this._fullInputValue[2],
        position: (this._fullInputValue[0] + `/${option.key}/`)?.length,
      });
    }
  }

  private resetDirectory() {
    this.checkedDirectory = null;
  }

  changePreselectedElement(directionOrIndex: 'down' | 'up' | number) {
    let newPreselectedOptionIndex = this.preSelectedOptionIndex;

    if (typeof directionOrIndex === 'number') {
      newPreselectedOptionIndex = directionOrIndex;
    } else {
      if (directionOrIndex === 'down') {
        newPreselectedOptionIndex += 1;
      } else {
        newPreselectedOptionIndex -= 1;
      }

      if (newPreselectedOptionIndex < 0) {
        newPreselectedOptionIndex = this.matchedOptions.length - 1;
      }

      if (newPreselectedOptionIndex === this.matchedOptions.length) {
        newPreselectedOptionIndex = 0;
      }

      // по таймауту, чтобы успела перерисовываться
      setTimeout(() => {
        this.scroll();
      }, 10);
    }

    this.preSelectedOption = this.matchedOptions[newPreselectedOptionIndex];
    this.preSelectedOptionIndex = newPreselectedOptionIndex;
    this.cdr.detectChanges();
  }

  start(withSpace: boolean = false) {
    if (!this.activeSearch) {
      document.execCommand('insertText', false, withSpace ? ' /' : '/');
    } else {
      this.reset();
    }
  }

  bypassHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  positionOfStartSymbol(value: string) {
    if (value.startsWith('/')) {
      return 0;
    } else {
      return Math.max(value.indexOf(' /'), value.indexOf('\n/'));
    }
  }

  getOptions() {
    const generateOptions = (patterns: PatternsByDirectories) => {
      const {
        directories,
        withoutDirectories,
      } = patterns;

      const templatesPatternWithoutDirectories = withoutDirectories.templates.map((pattern) => {
        return {
          key: pattern.key,
          id: pattern.id,
          value: this.utils.parseMessage(pattern.message.replace(/\n/g, '<br>'),
            false, false),
        };
      });

      const templatesDirectory = directories.map((directory) => ({
        key: directory.name,
        value: '',
        id: directory.id,
        options: directory.templates.map((pattern) => {
          return {
            key: pattern.key,
            id: pattern.id,
            value: this.utils.parseMessage(pattern.message.replace(/\n/g, '<br>'),
              false, false),
          };
        }),
      }));

      return [...templatesDirectory, ...templatesPatternWithoutDirectories];
    };

    if (!this.isOptionsLoaded) {
      const templates = this.#ednaTemplatesForChannel ? this.answerPatternService.getWabaTemaplatesByDirectoriesForChannel(this.ednaTemplatesForChannel) : this.answerPatternService.getPatternsByDirectories();

      templates
        // .pipe(takeUntil(this.unsub$))
        .subscribe((patterns) => {
          this.isOptionsLoaded = true;
          this.options = generateOptions(patterns);
          this.filterOptions();
        });
    }
  }
}
