import { Injectable } from '@angular/core';

enum AmplitudeEvent {
  BIG_BUTTON = 'main_screen_big_button_click',
  SMALL_BUTTON = 'main_screen_small_button_click',
  SETTINGS_CLICK = 'main_screen_settings_click',
  ADD_CHANNEL_BUTTON = 'settings_screen_add_channel_button_click',
  ADD_CHANNEL_TEXT = 'settings_screen_add_channel_text_click',
  CLOSE_CHANNEL_MODAL = 'create_channel_screen_cancel_button_click',
  START_CREATE_WHATSAPP = 'create_channel_screen_wa_button_click',
  SELECTED_PAID_WHATSAPP = 'wa_create_screen_paid_next_click',
  SELECTED_FREE_WHATSAPP = 'wa_create_screen_free_next_click',
  CANCEL_CREATE_WHATSAPP = 'wa_create_screen_cancel_button_click',
  SCAN_SUCCESS_WHATSAPP = 'wa_qr_screen_success_scan',
  SCAN_FAIL_WHATSAPP = 'wa_qr_screen_fail_scan',
  SCAN_REPEAT_WHATSAPP = 'wa_qr_screen_repeat_request',
  SCAN_SCREEN_DELETE_WHATSAPP = 'wa_qr_screen_delete_button_click',
  SCAN_SCREEN_CLOSE_WHATSAPP = 'wa_qr_screen_cancel_button_click',
  END_CREATE_WHATSAPP = 'wa_finish_screen_final_button_click',
  FINISH_SCREEN_DELETE_WHATSAPP = 'wa_finish_screen_delete_button_click',
  FINISH_SCREEN_CLOSE_WHATSAPP = 'wa_finish_screen_cancel_button_click',
  SIDEBAR_ADD_OPERATOR = 'main_screen_add_operators_button_click',
  SETTINGS_ADD_OPERATOR = 'settings_screen_add_operators_button_click',

  CREATE_TG_BTN = 'create_channel_screen_tg_button_click',
  TG_CLICK_BOT_FATHER_NO_BOT = 'tg_create_screen_no_bot_click_bot_father',
  TG_CLICK_BOT_FATHER_HAVE_BOT_SCREEN = 'tg_create_screen_have_bot_click_bot_father',
  TG_ENTER_TOKEN_NO_BOT_SCREEN = 'tg_create_screen_no_bot_enter_token',
  TG_ENTER_TOKEN_HAVE_BOT_SCREEN = 'tg_create_screen_have_bot_enter_token',
  TG_SUCCESS_CONNECT = 'tg_create_screen_no_bot_success',
  TG_SUCCESS_CONNECT_HAVE_BOT_SCREEN = 'tg_create_screen_have_bot_success',
  TG_FAILED_CONNECT = 'tg_create_screen_no_bot_wrong_token',
  TG_FAILED_CONNECT_HAVE_BOT_SCREEN = 'tg_create_screen_have_bot_wrong_token',
  TG_CANCEL = 'tg_create_screen_no_bot_cancel_button_click',
  TG_CANCEL_HAVE_BOT_SCREEN = 'tg_create_screen_have_bot_cancel_button_click',
  TG_CHANGE_NAME_FINAL_SCREEN = 'tg_finish_screen_change_name',
  TG_CHANGE_GREETING_FINAL_SCREEN = 'tg_finish_screen_change_greeting',
  TG_TUMBLER_ON_FINAL_SCREEN = 'tg_finish_screen_tumbler_on_finish',
  TG_TUMBLER_OFF_FINAL_SCREEN = 'tg_finish_screen_tumbler_off_finish',
  new_main_screen_crm_button_click = 'new_main_screen_crm_button_click',
  exernal_id = 'exernal_id',
}

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {

  constructor() {}

  track(trackName: AmplitudeEvent | string, args: object = null) {
    const normalizedTrackName = AmplitudeEvent[trackName] || trackName
    const amplitude = (<any>window).amplitude;
    if (amplitude !== undefined) {
      amplitude.logEvent(normalizedTrackName, args);
    }
  }
}
