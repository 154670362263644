<div class="page-title">{{ 'settings.tutorials.title' | translate }}</div>

<div class="grid-container-wrapper">
  <h3>{{ 'settings.tutorials.addingAndConfiguringChannels'| translate }}</h3>
  <div class="grid-container">
    <div class="video-container" *ngFor="let tutorial of tutorials">
      <a class="video-popup-opener" (click)="openVideoPopup(tutorial.id)"></a>
      <div class="img-wrapper">
        <img src="/assets/img/tutorials/{{ tutorial.id }}.png">
      </div>
      <span class="tutorial-name" [innerHTML]="'settings.tutorials.description.' + tutorial.id | translate"></span>
    </div>
  </div>
</div>
<div class="grid-container-wrapper">
  <h3>{{ 'settings.tutorials.basicFunctionsTutorials'| translate }}</h3>
  <div class="grid-container">
    <div class="video-container" *ngFor="let tutorial of basicFunctionsTutorials">
      <a class="video-popup-opener" (click)="openVideoPopup(tutorial.id)"></a>
      <div class="img-wrapper">
        <img src="/assets/img/tutorials/{{ tutorial.id }}.png">
      </div>
      <span class="tutorial-name" [innerHTML]="'settings.tutorials.description.' + tutorial.id | translate"></span>
    </div>
  </div>
</div>
<div class="grid-container-wrapper">
  <h3>{{ 'settings.tutorials.advancedFunctionsTutorials'| translate }}</h3>
  <div class="grid-container">
    <div class="video-container" *ngFor="let tutorial of advancedFunctionsTutorials">
      <a class="video-popup-opener" (click)="openVideoPopup(tutorial.id)"></a>
      <div class="img-wrapper">
        <img src="/assets/img/tutorials/{{ tutorial.id }}.png">
      </div>
      <span class="tutorial-name" [innerHTML]="'settings.tutorials.description.' + tutorial.id | translate"></span>
    </div>
  </div>
</div>

<div class="video-popup" *ngIf="showVideoPopup">
  <div class="video-popup__closer" (click)="closeVideoPopup()"></div>
  <div class="video-popup__close" (click)="closeVideoPopup()">
    <span></span>
    <span></span>
  </div>
  <iframe [src]="currentVideoUrl" frameborder="0" allow="autoplay; fullscreen" allowfullscreen id="video"></iframe>
</div>
