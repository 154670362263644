

const memoization = {};
function loadAudio(url: string): Promise<ArrayBuffer> {
  if (memoization[url]) {
    return memoization[url];
  }

  const promise = new Promise<ArrayBuffer>((resolve) => {
    const request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.responseType = 'arraybuffer';

    request.onload = function () {
      resolve(request.response);
    };

    request.send();
  });

  // Сохраняем промис в кэше перед отправкой запроса
  memoization[url] = promise;
  return promise;
}

const audioContext = new (window.AudioContext || window['webkitAudioContext'])();
export function play(audioFileUrl: string): void {
  loadAudio(audioFileUrl).then((audio) => {
    const audioCopy = audio.slice(0);

    audioContext.decodeAudioData(audioCopy, function(buffer) {
      const source = audioContext.createBufferSource();
      source.buffer = buffer;
      source.connect(audioContext.destination);
      source.start(0);
    }, function(err) {
      console.log('Ошибка декодирования аудио: ' + err);
    });
  });
}
