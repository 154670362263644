<div class="wrapper">
    <div (click)="goHome()" class="close-button"></div>
    <div class="text-description">
        <h2>{{"registration.setupOnComputer" | translate}}</h2>
        <div class="warning-description">
            <p>{{"registration.warningDescription_1" | translate}}</p>
            <p>{{"registration.warningDescription_2" | translate}}</p>
        </div>
    </div>
    <div class="image-wrap">
        <img src="../../../assets/img/mobile-warn.png" alt="Teletype App">
    </div>
    <div class="button-wrap">
        <button (click)="goHome()"><span class="button-text">{{"registration.continue" | translate}}</span></button>
    </div>
</div>

<div class="app-links">
  <a href="https://apps.apple.com/app/teletype-app/id1531835742" target="_blank">
    <img class="app-links__link" src="/assets/svg/ios.svg" alt="App Store">
  </a>

  <a href="https://play.google.com/store/apps/details?id=com.TeletypeApp" target="_blank">
    <img class="app-links__link" src="/assets/svg/google.svg" alt="Google Play">
  </a>
</div>
