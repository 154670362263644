import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Toast } from '@app/core/toasts/toast';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent {

  @Input() toast: Toast;

  constructor() {}
}
