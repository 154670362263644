import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
  @Input() inProgress: boolean;
  @Input() btnText: string;
  @Input() costText: string;

  constructor() {
    this.inProgress = this.inProgress || false;
  }
}
