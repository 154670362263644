import { Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public useNativeEmoji = false;
  private projectId = '';
  constructor(
    private zone: NgZone
  ) { }

  get currentProjectId(): string {
    return this.projectId;
  }

  public setCurrentProjectId(projectId: string): void {
    this.projectId = projectId;
  }

  get(key: string, defaultValue: any = false): any {

    return this.zone.runOutsideAngular(() => {
      let data = localStorage.getItem(`teletype-${key}`);

      if (data === undefined || data === null) {
        return defaultValue;
      }

      try {
        data = JSON.parse(data);
      } catch (e) { }

      return data;
    });
  }

  set(key: string, value: any): void {
    this.zone.runOutsideAngular(() => {
      localStorage.setItem(`teletype-${key}`, JSON.stringify(value));
    });
  }

  remove(key: string): void {
    this.zone.runOutsideAngular(() => {
      localStorage.removeItem(`teletype-${key}`);
    });
  }
}
