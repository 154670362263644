import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomModalService {

  private hasNeedAction = new BehaviorSubject<string>('');
  public get hasNeedAction$() { return this.hasNeedAction.asObservable(); }

  constructor() { }

  public close() {
    const modals = document.getElementsByClassName('modal') as HTMLCollectionOf<HTMLElement>;
    if (modals && modals.length) {
      Array.from(modals).forEach(modal => {
        modal.style.zIndex = '-1';
      });

    }
    const modalBackdrops = document.getElementsByClassName('modal-backdrop') as HTMLCollectionOf<HTMLElement>;
    if (modalBackdrops && modalBackdrops.length) {
      Array.from(modalBackdrops).forEach(modalBackdrop => {
        modalBackdrop.style.zIndex = '-1';
      });
    }
  }

  public showQRScan(id: string) {
    this.hasNeedAction.next(id);
  }
}
