<div class="emoji-mart"
  [style.width]="getWidth()"
  [ngStyle]="style">
  <div class="emoji-mart-section">
  <ng-scrollbar #scrollRef class="emoji-mart-scroll scroll-container _inline-scroll" (scroll)="handleScroll()" [attr.aria-label]="i18n.emojilist">
    <emoji-category
      *ngFor="let category of activeCategories; let idx = index; trackBy: categoryTrack"
      #categoryRef
      [id]="category.id"
      [name]="category.name"
      [emojis]="category.emojis"
      [perLine]="perLine"
      [totalFrequentLines]="totalFrequentLines"
      [hasStickyPosition]="isNative"
      [i18n]="i18n"
      [hideObsolete]="hideObsolete"
      [notFoundEmoji]="notFoundEmoji"
      [custom]="category.id == RECENT_CATEGORY.id ? CUSTOM_CATEGORY.emojis : undefined"
      [recent]="category.id == RECENT_CATEGORY.id ? recent : undefined"
      [emojiIsNative]="isNative"
      [emojiSkin]="skin"
      [emojiSize]="emojiSize"
      [emojiSet]="set"
      [emojiSheetSize]="sheetSize"
      [emojiForceSize]="isNative"
      [emojiTooltip]="emojiTooltip"
      [emojiBackgroundImageFn]="backgroundImageFn"
      (emojiOver)="handleEmojiOver($event)"
      (emojiLeave)="handleEmojiLeave()"
      (emojiClick)="handleEmojiClick($event)"
    ></emoji-category>
  </ng-scrollbar>
</div>
