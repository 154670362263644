import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/api/api.service';
import { UtilsService } from '@app/core/utils.service';
import { CustomFieldConfig, CustomFieldType } from '@app/settings/custom-fields/custom-fields.service';
import { CreatableCustomField } from '@app/settings/custom-fields/edit-custom-field/edit-custom-field.component';
import { routes } from '../config/apiRoutes';

@Injectable({
  providedIn: 'root',
})
export class CustomFieldsApi {

  private routes = routes;

  constructor(
    private api: ApiService,
    private utils: UtilsService,
  ) {

  }

  createCustomField(customField: CreatableCustomField) {
    const body = {
      insert: {
        types: {
          [customField.name]: customField.type,
        },
        titles: {
          [customField.name]: customField.title,
        },
        descriptions: {
          [customField.name]: customField.description,
        },
        values: {
          [customField.name]: customField.values,
        },
      },
    };

    return this.api.httpPostNew(this.routes.customFieldsUpdateSettings, body);
  };

  updateCustomField(id: string, customField: CreatableCustomField) {
    const body = {
      update: {
        names: {
          [id]: customField.name,
        },
        types: {
          [id]: customField.type,
        },
        titles: {
          [id]: customField.title,
        },
        descriptions: {
          [id]: customField.description,
        },
        orders: {
          [id]: customField.order,
        },
        values: {
          [id]: customField.values,
        },
      },
    };

    return this.api.httpPostNew(this.routes.customFieldsUpdateSettings, body);
  }

  removeCustomField(id: string) {
    const body = {
      delete: [id],
    };

    return this.api.httpPostNew(this.routes.customFieldsUpdateSettings, body);
  }

  updateOrder(list: CustomFieldConfig[]) {
    const orders = {};
    const titles = {};
    const descriptions = {};
    const names = {};
    const types = {};
    const values = {};

    list.forEach((field, index) => {
      orders[field.id] = index + 1;
      titles[field.id] = field.title;
      descriptions[field.id] = field.description;
      names[field.id] = field.name;
      types[field.id] = field.type;
      values[field.id] = field.values;
    });

    const body = {
      update: { orders, titles, descriptions, names, types, values },
    };

    return this.api.httpPostNew(this.routes.customFieldsUpdateSettings, body);
  }
}
