import { Injectable, NgZone } from '@angular/core';
import { Message } from '@app/core/interfaces/message';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import each from 'lodash-es/each';
import snakeCase from 'lodash-es/snakeCase';
import camelCase from 'lodash-es/camelCase';
import { ALT_COLONS_REGEX, COLONS_REGEX, EMOJI_RANGE } from '@app/core/config/regExValidators';
import linkifyHtml from 'linkifyjs/html';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EmojiService } from '@app/shared/emoji/emoji/emoji.service';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  private _useNativeEmoji: boolean;

  public get useNativeEmoji(): boolean {

    if (this._useNativeEmoji === undefined) {
      if (this.deviceService.getDeviceInfo().os.toLowerCase() === 'windows') {
        this._useNativeEmoji = false;
        return false;
      }

      if (!document.createElement('canvas').getContext) {
        this._useNativeEmoji = false;
      }

      const canvas = document.createElement('canvas');

      const context = canvas.getContext('2d');
      if (typeof context.fillText !== 'function') {
        this._useNativeEmoji = false;
      }

      const smile = String.fromCodePoint(0x1F604);

      context.textBaseline = 'top';
      context.font = '32px Arial';
      context.fillText(smile, 0, 0);

      this._useNativeEmoji = context.getImageData(16, 16, 1, 1).data[0] !== 0;
    }

    return this._useNativeEmoji;
  }

  constructor(
    private zone: NgZone,
    private http: HttpClient,
    private deviceService: DeviceDetectorService,
    private emojiService: EmojiService,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.useNativeEmoji;
  }

  public imageToBase64(pathToImage: string): Observable<any> {
    return this.zone.runOutsideAngular(() => {
      return new Observable(observer => {
        this.http.get(pathToImage, { responseType: 'blob' }).subscribe((res) => {
          const reader = new FileReader();

          reader.readAsDataURL(res);
          reader.addEventListener('loadend', () => {
            observer.next(reader.result);
            observer.complete();
          });
        });
      });
    });
  }

  public serialize(obj: any, prefix: string = ''): string {
    const str = [];
    let p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        const k = prefix ? `${prefix}[${p}]` : p;
        const v = obj[p];
        str.push((v !== null && typeof v === 'object' && (!Array.isArray(v) || v.length > 0)) ?
          this.serialize(v, k) : `${encodeURIComponent(k)}=${encodeURIComponent(this.formatBoolean(v))}`);
      }
    }
    return str.join('&');
  }

  public formatBoolean(value: any) {
    return typeof value === 'boolean' ? value === true ? 1 : 0 : value;
  }

  public snakeKeys(obj: any) {
    if (obj === null) {
      return '';
    }

    const newObj = Object.prototype.toString.call(obj) === '[object Array]' ? [] : {};
    each(obj, (v, i) => {
      newObj[typeof i === 'number' ? i : snakeCase(i)] = (typeof (v) === 'object') ? this.snakeKeys(v) : v;
    });

    return newObj;
  }

  public camelKeys(obj: any): any {
    if (obj === null) {
      return '';
    }

    const newObj = Object.prototype.toString.call(obj) === '[object Array]' ? [] : {};

    each(obj, (v, i) => {
      newObj[typeof i === 'number' ? i : camelCase(i)] = (typeof (v) === 'object') ? this.camelKeys(v) : v;
    });

    return newObj;
  }

  public parseMessage(message: string = '', emojiOnly: boolean = false, dontAddAlt: boolean = true) {
    const notFoundColons = [];
    const foundColons = [];
    const useNativeEmoji = this.useNativeEmoji;
    message = message.replace(/::/g, ': :');

    while (message.match(COLONS_REGEX) !== null) {
      const match = message.match(COLONS_REGEX);

      if (this.emojiService.checkIfExists(match[1])) {
        const emojiData = this.emojiService.getData(match[1]);

        if (useNativeEmoji) {
          message = message.replace(match[0], emojiData.native);
        } else {
          let nonNativeEmoji = '';
          if (emojiOnly) {
            // eslint-disable-next-line max-len
            const emojiUrl = `https://unpkg.com/emoji-datasource-facebook@4.0.3/img/facebook/64/${emojiData.unified.toLocaleLowerCase()}.png`;
            nonNativeEmoji = `<span style="background-image: url('${emojiUrl}')" class="custom-emoji"></span>`; // если сделать эту строку многострочной, то это немного все ломает
          } else {
            nonNativeEmoji = this.getEmojiImg(match[0], dontAddAlt).outerHTML;
          }
          const index = foundColons.push(nonNativeEmoji);
          message = message.replace(match[0], `emoji[[[#${index - 1}]]]`);
          // message = message.replace(match[0], nonNativeEmoji);
        }
      } else {
        const index = notFoundColons.push(match[0]);

        message = message.replace(match[0], `[[[#${index - 1}]]]`);
      }
    }

    while (message.match(/emoji\[\[\[\#\d+\]\]\]/) !== null) {
      const match = message.match(/emoji\[\[\[\#(\d+)\]\]\]/);

      message = message.replace(match[0], foundColons[match[1]]);
    }

    while (message.match(/\[\[\[\#\d+\]\]\]/) !== null) {
      const match = message.match(/\[\[\[\#(\d+)\]\]\]/);

      message = message.replace(match[0], notFoundColons[match[1]]);
    }

    message = linkifyHtml(message);

    return message;
  }

  public getEmojiImg(emoji: string, dontAddAlt: boolean = false): HTMLImageElement {
    const match = emoji.match(COLONS_REGEX);

    const emojiData = this.emojiService.getData(match[1]);
    const styles = this.emojiService.getStyles(emojiData, 1, 'facebook');
    const img = document.createElement('img');
    img.alt = dontAddAlt ? '' : emoji;
    // eslint-disable-next-line max-len
    img.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
    img.width = 18;
    img.height = 18;
    img.draggable = false;
    img.style.backgroundImage = styles.backgroundImage;
    img.style.backgroundPosition = styles.backgroundPosition;
    img.style.backgroundSize = styles.backgroundSize;
    img.style.display = 'inline';
    img.style.marginBottom = '-3px';
    img.style.cursor = 'text';

    return img;
  }

  public trimTextFromHtml(text: string = ''): string {
    if (!text) {
      return '';
    }

    // First we trim every real html tag
    text = text
      // .replace(/[\n\r\t]/g, '') // это строка нарушает преобразование
      // .replace(/<div>(<br>)?<\/div>/g, '\n') // это строка нарушает преобразование
      .replace(/<div><br><\/div>/g, '\n')
      .replace(/<div>(.*?)<\/div>/g, '\n$1')
      .replace(/<br>/g, '\n')
      // .replace(/<\/div>/g, '</div>\n'); // эта строка добавляет лишние переносы строк

    const elm = document.createElement('div');
    elm.innerHTML = text;

    const escapedText = elm.innerText.trim();

    // Then we get encoded text
    elm.innerText = escapedText;

    return elm.innerHTML.replace(/<br>/g, '\n').trim();
  }

  /** заменит в тексте все изображения на их alt (например на :smile:) */
  public replaceImageEmoji(message: string) {
    const elm = document.createElement('div');
    elm.innerHTML = message;

    const images = elm.getElementsByTagName('img');

    each(images, (image) => {
      const imgString = image.outerHTML;

      const isEmoji = imgString.match(ALT_COLONS_REGEX);

      if (isEmoji && isEmoji.length > 0 && isEmoji[1] !== undefined) {
        message = message.replace(imgString, `:${isEmoji[1]}:`);
      } else {
        message = message.replace(imgString, '');
      }
    });
    return message;
  }

  public isMessageEmojiOnly(message: Message) {
    if (message.messageType !== 'text') {
      return false;
    } else {
      return this.isTextEmojiOnly(message.message)
    }
  }

  public isTextEmojiOnly(message: string): boolean {
    const emojiSupported = message.match(COLONS_REGEX) && this.emojiService.checkIfExists(message.match(COLONS_REGEX)[1]);
    if (!emojiSupported) {
      return false;
    }

    let withoutSmiles = message
      .replace('\ufe0f', '') // невидимый символ вариативности эмодзи
      .replace(COLONS_REGEX, '')
      .replace(EMOJI_RANGE, '');
    return withoutSmiles === '';
  }

  public formatSize(size: number = 0) {
    let i = -1;
    const byteUnits = ['Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];
    do {
      size = size / 1024;
      i++;
    } while (size > 1024);

    return `${Math.max(size, 0.1).toFixed(1)} ${byteUnits[i]}`;
  }

  public clearOldMessagesFromStorage() {
    const messageKeys = Object.keys(localStorage).filter(key => key.indexOf('saved-message-') > -1);

    messageKeys.forEach((key) => {
      let savedValue = null;
      try {
        savedValue = JSON.parse(localStorage.getItem(key) || '{}');
      } catch (e) {}
      const timeLiveSavedMessage = 1000 * 60 * 60; // 1 час

      if (!savedValue || (!savedValue.updateTime || new Date().getTime() - savedValue.updateTime > timeLiveSavedMessage)) {
        localStorage.removeItem(key);
      }
    });
  }
}
