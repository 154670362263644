<div *ngIf="date && isAddDate" class="new-date">{{ date }}</div>

<div *ngIf="startDate" class="new-date">{{ startDate }}</div>


<!--дубликат контейнера сообщения, нужен для отображения цитированного сообщения отдельным блоком, если есть вложение-->
<div class="message-inner message-type-text only-replay"
     [ngClass]="messageClass"
     [class.note-type]="message?.type === 20"
     [class.zindex]="zindex"
     *ngIf="message?.messageType !== 'internal' && message?.type !== 30 && message?.type !== 40 &&
      message?.type !== 5 && message.repliedMessage && message.attachment "
     #messageContainer>

  <!--цитируемое сообщение-->
  <!--этот блок также используется в компоненте создания сообщения при цитировании (компонента message-input)-->
  <!--TODO: вынести его в отдельный компонент-->
  <div class="replied-message">
    <img
      *ngIf="message.repliedMessage?.attachmentPreviewUrl"
      [src]="message.repliedMessage?.attachmentPreviewUrl"
      class="replied-message__attachment-preview"
    >

    <div class="replied-message__content">
      <div class="replied-message__user-name">
        {{ message.repliedMessage.from?.name || '' }}
      </div>

      <div class="replied-message__text">
        <div
          *ngIf="message.repliedMessage?.messageType === 'audio'"
          class="replied-message__attachment-icon _audio">
        </div>

        <i
          *ngIf="message.repliedMessage?.messageType === 'attachment'"
          class="replied-message__attachment-icon teletype-icon-file _attachment">
        </i>

        {{ repliedMessageText }}

        <span *ngIf="message.repliedMessage?.messageType === 'audio'">
          &nbsp; {{ repliedAudioDuration }} &nbsp;
        </span>
      </div>
    </div>
  </div>
</div>

<div class="message-inner message-type-{{ messageType || 'text' }}"
     [ngClass]="messageClass"
     [class.note-type]="message?.type === 20"
     [class.zindex]="zindex"
      *ngIf="message?.messageType !== 'internal' && message?.type !== 30 && message?.type !== 40 &&
      message?.type !== 5"
      #messageContainer>
  <div *ngIf="message?.messageType !== 'typing'" class="teletype-status-icon teletype-status-icon-{{ messageStatus }}">
    <div dropdown (onShown)="onShown()" (onHidden)="onHidden()" class="dropdown"
         *ngIf="this.message?.isOwn && environment.features?.messageEdit">
      <button class="btn btn-transparent no-hover project-select"  id="projectDropdown" dropdownToggle>
        <div class="tooltip-message" role="tooltip" tabindex="0">
          <i class="teletype-icon-options"></i>
          <span>{{'conversation.editOrDelete' | translate}}</span>
        </div>
      </button>
      <div *dropdownMenu class="dropdown-menu" aria-labelledby="projectDropdown">
        <div class="dropdown-item" (click)="editMessage($event)">
          <i class="teletype-icon-edit-message"></i> {{'button.edit' | translate}}
        </div>
        <div class="dropdown-item" (click)="showHistoryMessage(template, $event)">
          <i class="teletype-icon-history-message"></i> {{'conversation.showEditHistory' | translate}}
        </div>
        <div class="dropdown-item">
          <i class="teletype-icon-delete-message"></i> {{'conversation.delete' | translate}}
        </div>
      </div>
    </div>
    <i class="teletype-icon-sending" *ngIf="messageStatus && messageStatus.toString() === '10'"></i>
    <div dropdown container="body" *ngIf="messageStatus && messageStatus.toString() === '20'">
      <i class="teletype-icon-failed"
         [tooltip]="'conversation.messageNotSent' | translate"
         placement="top"
         tooltipAppendToBody="true"
         id="sendFailDropdown"
         dropdownToggle></i>
      <div *dropdownMenu class="dropdown-menu" aria-labelledby="sendFailDropdown">
        <div class="dropdown-item first" (click)="retrySendMessage(message)">{{ 'conversation.sendAgain' | translate }}</div>
        <div class="dropdown-item" (click)="removeMessage(message)">{{ 'conversation.deleteMessageWithError' | translate }}</div>
      </div>
    </div>
    </div>

  <div (click)="selectPerson()" class="message-avatar" [class.availableClick]="isMessageInGroupChat && !this.message.isOwn">
    <app-avatar [avatar]="avatar"
                [animal]="message?.from?.animal || ''"
                [provider]="message.provider"
                *ngIf="!_withoutTail">
    </app-avatar>
  </div>

  <!--цитируемое сообщение-->
  <!--этот блок также используется в компоненте создания сообщения при цитировании (компонента message-input)-->
  <!--TODO: вынести его в отдельный компонент-->
  <div
    class="replied-message"
    [class._white-color]="!message.isOwn"
    *ngIf="message.repliedMessage && !message.attachment"
  >
    <img
      *ngIf="message.repliedMessage?.attachmentPreviewUrl"
      [src]="message.repliedMessage?.attachmentPreviewUrl"
      class="replied-message__attachment-preview"
    >

    <div class="replied-message__content">
      <div class="replied-message__user-name">
        {{ message.repliedMessage.from.name || '' }}
      </div>

      <div class="replied-message__text">
        <div
          *ngIf="message.repliedMessage?.messageType === 'audio'"
          class="replied-message__attachment-icon _audio">
        </div>

        <i
          *ngIf="message.repliedMessage?.messageType === 'attachment'"
          class="replied-message__attachment-icon teletype-icon-file _attachment">
        </i>

        {{ repliedMessageText }}

        <span *ngIf="message.repliedMessage?.messageType === 'audio'">
          &nbsp; {{ repliedAudioDuration }} &nbsp;
        </span>
      </div>
    </div>
  </div>

  <div
    (click)="selectPerson()"
    class="message"
    [class.availableClick]="isMessageInGroupChat && !this.message.isOwn"
  >
    <!--пользователь печатает-->
    <ng-template [ngIf]="messageType === 'typing'">
      <span *ngIf="message?.message">{{ message?.message }}</span>
      <div class="loading">
        <div class="dot one"></div>
        <div class="dot two"></div>
        <div class="dot three"></div>
      </div>
    </ng-template>

    <!--автор сообщения (для групповых чатов)-->
    <div
      class="teletype-message-author"
      *ngIf="!this.message.isOwn && isMessageInGroupChat">
      {{ nameOfAuthorMessage }}
    </div>

    <!--ссылка на вложение или видео-->
    <ng-template [ngIf]="(message?.messageType === 'attachment' || message?.messageType === 'video') && message?.attachmentUrl">
      <a [href]="message?.attachmentUrl" [download]="message?.attachment" class="download-link"></a>
    </ng-template>

    <!--изображение-->
    <ng-template [ngIf]="messageType === 'image' || messageType === 'gif'" *ngIf="!message?.errorMessage">
        <div class="image-message-container">
            <div class="progressbar" *ngIf="message?.progress < 100">
              <app-progress [class]="'progress-for-image'"
                            [value]="message?.progress"
                            (cancel)="cancelUpload(message?.attachmentId)"
                            [cancelUpload]="true"
                            [size]="44"
                            [strokeColor]="'#fff'">
              </app-progress>
            </div>
          <img (click)="message?.messageType === 'image' && openPreviewer(message?.attachmentUrl ||
                  message?.attachmentPreviewUrl)"
               [ngSrc]="message?.attachmentPreviewUrl || message?.attachmentUrl"
               [width]="imageWidth || 0"
               [height]="imageHeight || 0"
               [alt]="message?.attachment"/>
        </div>
    </ng-template>

    <!--отображение вложения, видео или аудио-->
    <ng-template [ngIf]="messageType === 'attachment' || messageType === 'video' || messageType === 'audio'">
        <div class="attachment-message-content">
          <ng-template [ngIf]="messageType !== 'audio'">
            <div class="progressbar" *ngIf="!message?.errorMessage && message?.progress < 100">
              <app-progress [value]="message?.progress" (cancel)="cancelUpload(message?.attachmentId)" [cancelUpload]="true" [strokeColor]="'#fff'"></app-progress>
            </div>
          </ng-template>
          <ng-template [ngIf]="messageType === 'audio'">
            <div class="audio-progress-container" *ngIf="!message.errorMessage && message?.progress < 100">
              <button (click)="cancelUpload()" class="audio-cancel-upload"></button>
              <div class="audio-progressbar" [style.width.%]="message?.progress"></div>
            </div>
          </ng-template>
          <div class="attachment-icon" [ngClass]="{'playing': playing}" *ngIf="(message?.messageType === 'attachment' ||
            message?.messageType === 'video') && (message?.progress === 100 ||
            message?.progress === undefined) && message?.attachmentUrl && message?.attachmentId">
            <i class="teletype-icon-file"></i>
          </div>
          <button (click)="toggleAudioMessageState()" class="play-pause-icon" [ngClass]="{'playing': playing}" *ngIf="message?.messageType === 'audio' &&
          (message?.progress === 100 || message?.progress === undefined)"></button>
          <div class="attachment-name-wrapper"
               [ngClass]="{'with-download-block': (message?.messageType === 'attachment' ||
               message?.messageType === 'video') && (message?.progress === 100 || message?.progress === undefined) &&
               message?.attachmentUrl && message?.attachmentId}">
            <div class="attachment-name" *ngIf="message?.messageType !== 'audio'">
              {{ message?.attachment }}
            </div>
            <div class="attachment-size" *ngIf="(message?.messageType === 'attachment' ||
              message?.messageType === 'video') && (message?.progress === 100 ||
              message?.progress === undefined) && message?.attachmentUrl && message?.attachmentId">
              {{ size }} - <a href="#">{{ 'Download' | translate }}</a>
            </div>
            <div class="audio-progress" (click)="rewind($event)" *ngIf="message?.messageType === 'audio' &&
            (message?.progress === 100 || message?.progress === undefined)">
              <span class="passed-line" [style.width.%]="percentDuration"></span>
              <span class="position-dot" [style.left.%]="percentDuration"></span>
            </div>
          </div>
          <span *ngIf="message?.messageType === 'audio' &&
          (message?.progress === 100 || message?.progress === undefined)" class="audio-duration">{{ duration }}</span>
        </div>
    </ng-template>

    <!--текст сообщения-->
    <div *ngIf="messageType === 'text' || messageType === undefined && message?.formType === undefined"
         #messageInputEdit
         tabindex="1"
         class="teletype-new-message-input-container"
         [contentEditable]="contentEditable"
         (keydown.enter)="sendMessage()"
         (keydown.esc)="restartMessage()"
         [defaultValue]="displayMessage"
         appTeletypeContentEditable></div>
        <button *ngIf="contentEditable"
                type="button"
                (click)="onEmojiClick()"
                class="emoji-button">
          <i class="teletype-icon-emoji"></i>
        </button>
        <app-teletype-emoji-picker
          *ngIf="contentEditable"
          (selectEmoji)="closePicker() && handleSelectEmoji($event)"
          (closePicker)="closePicker()"
          [ngClass]="{'teletype-visible': emojiPickerVisible}"
          [pickerVisible]="emojiPickerVisible"></app-teletype-emoji-picker>
  </div>

  <span class="typing-text" *ngIf="messageType === 'typing'">{{ 'conversation.typing' | translate }}</span>
  <span class="typing-text" *ngIf="contentEditable">{{'conversation.confirmOrCancel' | translate}}</span>

  <app-message-date *ngIf="message?.date && message?.type !== 20 && !message?.errorMessage && !contentEditable"
                    class="message-date"
                    [date]="message?.date.date"
                    [status]="message?.isOwn ? messageStatus : undefined"
                    [seen]="message?.isOwn ? message?.seen : false"
                    [attr.date-anchor]="message?.date.date"
                    [timeOnly]="true">
  </app-message-date>

  <!--кнопка ответа на сообщение-->
  <button
    class="replay-button"
    *ngIf="isCanReplay"
    (click)="replyToMessage()"
    [disabled]="!selectedClientId"
  ></button>

  <span class="message-date" *ngIf="message?.type === 20">{{ 'conversation.noteAdded' | translate }}</span>
  <span class="message-edited" *ngIf="message?.type === 12">{{ 'conversation.edited' | translate }}</span>
</div>

<ng-template [ngIf]="message?.type === 30 || message?.type === 40">
  <div class="assign-type-wrapper">
    <div class="assign-type" [class.yellow]="message?.type === 40">{{ message?.message }}</div>
  </div>
</ng-template>

<ng-template [ngIf]="message?.type === 5">
  <div class="assign-type-wrapper">
    <div class="assign-type red">{{ message?.message }}</div>
  </div>
</ng-template>

<div *ngIf="message?.type === 15 && message?.messageType === 'internal'" class="latest-wrapper">
  <div class="message-body">
    {{ 'conversation.markedComplete' | translate }}
    {{ message?.from && message?.from.isOperator && message?.from.name || '' }}{{ 'conversation.on' | translate }}
    {{ message?.dateString }}{{ 'conversation.in' | translate }}{{ message?.timeString }}
  </div>
</div>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left" translate>{{'conversation.editHistory' | translate}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <i class="teletype-icon-close"></i>
    </button>
  </div>
  <div class="modal-body">

  </div>
  <div class="modal-footer">
    <div class="pull-right">
      <button type="button" class="btn btn-primary" (click)="closeModal()">{{ 'button.close' | translate }}</button>
    </div>
  </div>
</ng-template>

<div class="failed-wrapper" [style.padding-top.px]="!hasTail ? 7 : 28">
  <div *ngIf="message?.errorMessage" class="message-failed">
    {{ message?.errorMessage | translate }}
  </div>
  <div *ngIf="message?.failedMessage && messageStatus === '20'"
       class="message-failed"
       [style.width.px]="parentElementWidth">
    {{ message?.failedMessage | translate }}
  </div>
</div>
