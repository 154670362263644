import { Injectable } from '@angular/core';
import { Response } from '@app/core/interfaces/api.response';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { ProjectApiService } from '@app/core/api/api.project';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private api: ProjectApiService,
    private router: Router
  ) {
  }

  setUserProject() {
    this.api.getProjects().subscribe((response: Response) => {
      const HOME_PATH = '/home';
      const PROFILE_PATH = '/profile/success';
      const isEmailUpdate = document.location.pathname.indexOf('/client/update-email/') !== -1;
      const path = isEmailUpdate ? PROFILE_PATH : HOME_PATH;
      if (response.success && response.data && response.data.length && environment.production) {
        const urls = response.data.map((project) => {
          return project.url;
        })

        if (environment.switchDomainByProject) {
          if (urls.indexOf(window.document.location.host) !== -1) {
            this.router.navigate(['/'], { replaceUrl: true });
          } else {
            window.document.location.href = response.data[0].url + path;
          }
        } else {
          location.reload();
        }

      } else if (environment && environment.baseDomain) {
        const url = environment.baseDomain.indexOf('http') !== -1 ? '' : 'https://';
        window.document.location.href = url + environment.baseDomain + path;
      } else {
        this.router.navigate(['/'], { replaceUrl: true });
      }
    });
  }
}
