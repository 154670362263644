import { ApplicationRef, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService, extract, I18nService } from '@app/core';
import { AlertsService } from '@app/core/alerts/alerts.service';
import { AuthResponse, LoginApiService } from '@app/core/api/api.login';
import { UserStatus } from '@app/core/api/api.profile';
import { EMAIL_REGEX } from '@app/core/config/regExValidators';
import { CookiesService } from '@app/core/cookies.service';
import { LocationService } from '@app/core/location.service';
import { ProjectService } from '@app/core/project.service';
import { UserService } from '@app/core/user.service';
import { LoginService } from '@app/login/login.service';
import { environment } from '@env/environment';

import { Angulartics2 } from 'angulartics2';
import each from 'lodash-es/each';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  preserveWhitespaces: false,
})
export class LoginComponent implements OnInit, OnDestroy {

  private unsub$ = new Subject<void>();

  originUrl: string;
  isLoading = false;
  error: string;
  loginForm: UntypedFormGroup;
  resetPasswordForm: UntypedFormGroup;
  registerForm: UntypedFormGroup;
  displayForm = 'login';
  resetPasswordSubmitted = false;
  inIframe = false;
  errorsList = {
    login: false,
    password: false,
    name: false,
    phone: false,
    companyName: false,
    businessScope: false,
    companySize: false,
    yourRole: false,
  };
  baseDomain = environment.baseDomain + '/register';

  // @ts-ignore
  @ViewChild('loginFormForm') loginFormForm: NgForm;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private i18nService: I18nService,
    private api: LoginApiService,
    private auth: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private detector: ChangeDetectorRef,
    private meta: Meta,
    private alerts: AlertsService,
    private appRef: ApplicationRef,
    private angulartics2: Angulartics2,
    private userService: UserService,
    private projectService: ProjectService,
    private loginService: LoginService,
    private titleService: Title,
    private locationService: LocationService,
    private cookies: CookiesService,
  ) {

  }

  ngOnInit() {
    this.handlerOAuth();

    if (window.self !== window.top) {
      this.inIframe = true;
    }

    const title = this.activatedRoute.snapshot.data['title'];
    const language = this.i18nService.translateService.translations[this.i18nService.language];
    if (title) {
      this.titleService.setTitle(language.route[title.toLowerCase()]);
    }

    if (this.inIframe) {
      window.addEventListener('message', (event: MessageEvent) => {
        this.listener(event);
      });

      this.appRef.tick();
    }

    if (this.activatedRoute.routeConfig.path === 'reset-password') {
      this.displayForm = 'reset-password';
    } else if (this.activatedRoute.routeConfig.path === 'register') {
      this.displayForm = 'register';
    }

    this.createForm();

    this.meta.updateTag({
      content: 'width=device-width, initial-scale=1',
      name: 'viewport',
    });

    if (this.cookies.check('hideEaseWorkBanner')) {
      this.cookies.delete('hideEaseWorkBanner');
    }

    if (this.cookies.check('ignoreWelcomeScreen')) {
      this.cookies.delete('ignoreWelcomeScreen');
    }

    (<any>window).angulartics2 = this.angulartics2;
    this.userService.clear();
    this.projectService.clear();
    this.alerts.removeAll();
  }

  ngOnDestroy() {
    this.unsub$.next();
    this.unsub$.complete();

    this.alerts.removeAll();
  }

  handlerOAuth() {
    // это elama код
    const { code } = this.activatedRoute.snapshot.queryParams;

    if (code) {
      this.isLoading = true;

      this.api.authByElama(code).subscribe((res) => {
        if (res.success) {
          switch (res.data.client.status) {
            case UserStatus.Registered:
              this.router.navigate([`/registration/profile/${res.data.client.id}`], { replaceUrl: true, queryParams: { oauth: 'elama-auth'} });
              return;
            case UserStatus.Confirmed:
              this.router.navigate([`/registration/complete`], { replaceUrl: true, queryParams: { oauth: 'elama-auth'} });
              return;
            case UserStatus.Full:
              this.auth.login(res.data.accessToken, true);
              this.loginService.setUserProject();
              return;
          }
        } else {
          this.error = res.errors[0].message || 'Error, could not authorize :-(';
        }
      })
    }
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  handleFieldBlur(form: string, field: string) {
    const fieldState = this[form].get(field);
    this.errorsList[field] = !fieldState.valid && fieldState.value.trim() !== '';
  }

  validateOnSubmit(formName: string = 'loginForm') {
    const form = this[formName];

    each(form.controls, (control, name) => {
      this.errorsList[name] = control.errors !== null;
    });
  }

  login() {
    this.validateOnSubmit();

    if (this.loginFormForm.valid === false) {
      return;
    }
    this.isLoading = true;

    this.alerts.removeAll();

    this.api.auth(this.loginForm.value).pipe(takeUntil(this.unsub$))
      .subscribe((response: AuthResponse) => {
        this.loginForm.markAsPristine();

        if (response.data?.language) {
          this.setLanguage(response.data.language);
        }

        this.detector.detectChanges();

        this.auth.login(response.data.accessToken, this.loginForm.value.remember);
        this.loginService.setUserProject();
      }, (error) => {
        this.error = error.errors[0].message || 'Error, could not authorize :-(';
        this.isLoading = false;
        this.detector.detectChanges();
      });
  }

  extract(string: string) {
    return extract(string);
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      login: ['', Validators.compose(([Validators.required, Validators.pattern(EMAIL_REGEX)]))],
      password: ['', Validators.required],
      remember: false,
    });
  }

  listener(event: MessageEvent): void {

    if (this.loginForm === undefined) {
      return;
    }

    const data = event.data;

    if (data.type === undefined) {
      return;
    }

    if (data.type === 'typing') {
      const email = data.email;
      this.loginForm.controls.login.setValue(email);
    } else if (data.type === 'setOriginUrl') {
      this.originUrl = data.url;
      this.registerForm.controls.fromLanding.setValue(this.originUrl);
    }
  }

  public goToLanding() {
    this.locationService.goToLanding();
  }

  public goToRegister() {
    const url = environment && environment.production ?
      `https://${environment.baseDomain}/register` : `${environment.baseDomain}/register`;
    window.location.href = url;
  }

}
