import { Component, Input, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {
  @Input() public url: SafeResourceUrl;
  @Input() public width = '100%';
  public isMobile: boolean;
  public inProgress: boolean;

  constructor() { }

  ngOnInit() {
    this.isMobile = this.width === '100%';
  }

  play() {
    this.inProgress = true;
  }

}
