import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject, fromEvent, Subscription } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { each } from 'lodash-es';
import { Duration } from 'luxon';

export const SECONDS_IN_MINUTE = 60;
export const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60;

export interface StreamState {
  playing: boolean;
  durationPercentage: number;
  readableDurationLeft: string;
  readableCurrentTime: string;
  readableDuration: string;
  duration: number | undefined;
  currentTime: number | undefined;
  canplay: boolean;
  error: boolean;
  event?: string;
}

export interface SubscribedEvent {
  [key: string]: Subscription;
}

@Injectable({
  providedIn: 'root'
})
export class AudioPlayerService {

  public subscribedEvents: SubscribedEvent = {};

  public currentAudioUrl: string;

  public audioEvents = [
    'ended', 'error', 'play', 'playing', 'pause', 'timeupdate', 'canplay', 'loadedmetadata', 'loadstart'
  ];
  public state: StreamState = {
    playing: false,
    durationPercentage: 0,
    readableDurationLeft: '',
    readableCurrentTime: '',
    readableDuration: '',
    duration: undefined,
    currentTime: undefined,
    canplay: false,
    error: false,
  };
  public stop$ = new Subject<void>();
  public audioObj = new Audio();
  public stateChange: BehaviorSubject<StreamState> = new BehaviorSubject(this.state);

  getDuration(url: string): Observable<number> {

    const audioObj = new Audio(url);
    audioObj.load();

    return fromEvent(audioObj, 'loadedmetadata').pipe(map(() => audioObj.duration));
  }

  playStream(url: string, duration?: string, durationInt: number = 0) {
    return this.streamObservable(url, duration, durationInt).pipe(takeUntil(this.stop$));
  }

  play() {
    this.audioObj.play().then(r => {});
  }

  pause() {
    this.audioObj.pause();
  }

  stop() {
    this.stop$.next();
  }

  seekTo(seconds: number) {
    this.audioObj.currentTime = seconds;
  }

  formatTime(time: number, fmt: string = 'HH:mm:ss') {

    if (isNaN(time)) {
      return '-:-';
    }

    time = Math.ceil(time);

    if (time < SECONDS_IN_MINUTE) {
      fmt = '00:ss';
    } else if (time < SECONDS_IN_HOUR) {
      fmt = 'mm:ss';
    }

    return Duration.fromObject({seconds: Math.ceil(time)}).toFormat(fmt);
  }

  getState(): Observable<StreamState> {
    return this.stateChange.asObservable();
  }

  streamObservable(url: string, duration?: string, durationInt: number = 0): Observable<StreamState> {
    if (this.currentAudioUrl !== undefined && this.currentAudioUrl !== url) {
      // Stop Playing
      this.audioObj.pause();
      this.audioObj.currentTime = 0;
      this.resetState(duration);
    }

    return new Observable(observer => {
      setTimeout(() => {
        this.currentAudioUrl = url;
        // remove event listeners
        this.removeEvents(this.audioEvents);
        // reset state
        this.resetState(duration);

        // Play audio
        this.audioObj.src = url;
        this.audioObj.currentTime = durationInt;
        this.audioObj.load();
        this.audioObj.play();

        this.state.currentTime = durationInt;
        this.state.readableDuration = duration;

        const handler = (event: Event) => {
          this.updateStateEvents(event);
          observer.next(this.state);
        };

        this.addEvents(this.audioEvents, handler);
        return () => {
          // Stop Playing
          this.audioObj.pause();
          this.audioObj.currentTime = 0;
          // remove event listeners
          this.removeEvents(this.audioEvents);
          // reset state
          this.resetState(duration);
        };
      }, 0);
    });
  }

  private addEvents(events: string[], handler: (event: Event) => void): void {

    each(events, (event) => {
      this.subscribedEvents[event] = fromEvent(this.audioObj, event).subscribe(evt => handler(evt));
    });
  }

  private removeEvents(events: string[]): void {
    if (Object.keys(this.subscribedEvents).length === 0) {
      return;
    }

    each(events, (event) => {
      this.subscribedEvents[event].unsubscribe();
    });
  }

  private updateStateEvents(event: Event): void {

    this.state.event = event.type;

    switch (event.type) {
      case 'canplay':
        this.state.duration = this.audioObj.duration;
        //this.state.readableDurationLeft = this.formatTime(this.state.duration - this.state.currentTime);
        //this.state.readableDuration = this.formatTime(this.state.duration);
        this.state.canplay = true;
        this.state.durationPercentage = Math.ceil((this.state.currentTime / this.state.duration) * 100);
        break;
      case 'playing':
        this.state.playing = true;
        break;
      case 'pause':
        this.state.playing = false;
        break;
      case 'timeupdate':
        this.state.currentTime = this.audioObj.currentTime;
        // this.state.readableDurationLeft = this.formatTime(this.state.duration - this.state.currentTime);
        // this.state.readableCurrentTime = this.formatTime(this.state.currentTime);
        this.state.durationPercentage = Math.ceil((this.state.currentTime / this.state.duration) * 100);
        break;
      case 'error':
        this.resetState();
        this.state.error = true;
        break;
    }
    this.stateChange.next(this.state);
  }

  private resetState(duration?: string) {
    this.state = {
      playing: false,
      durationPercentage: 0,
      readableDurationLeft: duration || '',
      readableCurrentTime: '',
      readableDuration: duration || '',
      duration: undefined,
      currentTime: undefined,
      canplay: false,
      error: false
    };
  }
}
