import { Injectable, NgZone } from '@angular/core';
import {
  TranslateService,
  LangChangeEvent,
  MissingTranslationHandler,
  MissingTranslationHandlerParams
} from '@ngx-translate/core';
import includes from 'lodash-es/includes';

import { Logger } from './logger.service';
import { ApiService } from '@app/core/api/api.service';
import { Duration, Interval } from 'luxon';
import { environment } from '@env/environment';
import { CookiesService } from '@app/core/cookies.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

const log = new Logger('I18nService');
const languageKey = 'language';

/**
 * Pass-through function to mark a string for translation extraction.
 * Running `npm translations:extract` will include the given string by using this.
 * @param {string} s The string to extract for translation.
 * @return {string} The same string.
 */
export function extract(string: string): string {
  return string;
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {

  private sentTranslations: string[] = [];

  constructor(
    private zone: NgZone,
    private api: ApiService,
  ) {

  }

  public handle = (params: MissingTranslationHandlerParams) => {
    return this.zone.runOutsideAngular(() => {
      // log.info(`Translation not found for: ${params.key}`);
      return params.key;
    });
  }
}


@Injectable({
  providedIn: 'root',
})
export class I18nService {

  defaultLanguage: string;
  supportedLanguages: string[];

  constructor(
    private zone: NgZone,
    private api: ApiService,
    public translateService: TranslateService,
    private cookies: CookiesService,
    private bsLocale: BsLocaleService
  ) {
  }

  /**
   * Initializes i18n for the application.
   * Loads language from local storage if present, or sets default language.
   * @param {!string} useLang The language to use.
   * @param {Array.<String>} supportedLanguages The list of supported languages.
   */
  init(useLang: string, supportedLanguages: string[]) {
    this.defaultLanguage = supportedLanguages[0];
    this.supportedLanguages = supportedLanguages;
    this.translateService.addLangs(supportedLanguages);
    this.language = useLang;

    this.translateService.onLangChange
      .subscribe((event: LangChangeEvent) => {
        const interval = Interval.after(new Date(), Duration.fromObject({ years: 99 })).toDuration('days')
          .toObject().days;
        this.cookies.set(languageKey, event.lang, interval, '/', environment.cookiesOrigin);
      });

    this.translateService.missingTranslationHandler = new MyMissingTranslationHandler(this.zone, this.api);
  }

  /**
   * Sets the current language.
   * Note: The current language is saved to the local storage.
   * If no parameter is specified, the language is loaded from local storage (if present).
   * @param {string} language The IETF language code to set.
   */
  set language(language: string) {
    language = language || this.cookies.get(languageKey) || this.translateService.getBrowserCultureLang();
    if (environment.features.onlyRuLanguage) {
      language = 'ru';
    }
    let isSupportedLanguage = includes(this.supportedLanguages, language);

    // If no exact match is found, search without the region
    if (language && !isSupportedLanguage) {
      language = language.split('-')[0];
      language = this.supportedLanguages.find(supportedLanguage => supportedLanguage.startsWith(language)) || '';
      isSupportedLanguage = Boolean(language);
    }

    // Fallback if language is not supported
    if (!isSupportedLanguage) {
      language = this.defaultLanguage;
    }

    log.debug(`Language set to ${language}`);
    this.translateService.setDefaultLang(language);
    this.translateService.use(language);
    const interval = Interval.after(new Date(), Duration.fromObject({ years: 99 })).toDuration('days')
      .toObject().days;
    this.cookies.set(languageKey, language, interval, '/', environment.cookiesOrigin);

    this.bsLocale.use(language);
  }

  /**
   * Gets the current language.
   * @return {string} The current language code.
   */
  get language(): string {
    return this.translateService.currentLang;
  }
}
