<div class="login-form-container">
  <div class="form-container register-container">
    <form class="reset-password-form"
          (submit)="completeRegistration()"
          [formGroup]="completeRegistrationForm"
          #completeRegistrationFormForm="ngForm"
          novalidate>
      <div class="logo pointer" (click)="goToLanding()"></div>
      <h1 *ngIf="isPasswordChanged">{{ 'resetPassword.changeSuccess' | translate }}</h1>
      <h1 *ngIf="!isPasswordChanged">{{ 'resetPassword.enterNewPass' | translate }}</h1>
      <div class="wrapper" *ngIf="!isPasswordChanged">
        <app-alerts></app-alerts>
        <div class="form-group"
             [ngClass]="{'filled': completeRegistrationForm.value.password, 'error': errorsList.password}">
          <input type="password"
                 class="form-control"
                 formControlName="password"
                 autocomplete="off"
                 [placeholder]="'password.self' | translate"
                 (blur)="handleFieldBlur('password')"
                 [reliability]="true"
                 required />
          <span class="floating-label">{{ 'password.self' | translate }}</span>

          <span *ngIf="completeRegistrationForm.get('password').hasError('required')" class="form-group-error">
            {{ 'password.error.blank' | translate }}
          </span>
          <span *ngIf="completeRegistrationForm.get('password').hasError('notEqual')" class="form-group-error">
            {{ 'password.error.match' | translate }}
          </span>
          <span *ngIf="completeRegistrationForm.get('password').hasError('pattern')" class="form-group-error">
            {{ 'password.error.valid' | translate }}
          </span>
        </div>

        <div class="form-group"
             [ngClass]="{'filled': completeRegistrationForm.value.passwordRepeat, 'error': errorsList.passwordRepeat}">
          <input type="password"
                 class="form-control"
                 formControlName="passwordRepeat"
                 autocomplete="off"
                 [placeholder]="'password.confirm' | translate"
                 (blur)="handleFieldBlur('passwordRepeat')"
                 required />
          <span class="floating-label">{{ 'password.confirm' | translate }}</span>
          <span *ngIf="completeRegistrationForm.get('passwordRepeat').hasError('required')" class="form-group-error">
            {{ 'password.error.blankConfirm' | translate }}
          </span>
          <span *ngIf="completeRegistrationForm.get('passwordRepeat').hasError('notEqual')" class="form-group-error">
            {{ 'password.error.match' | translate }}
          </span>
        </div>
        <div class="buttons-container">
          <button class="btn btn-primary with-spinner" type="submit" [disabled]="isLoading">
            <app-spinner [inProgress]="isLoading" [btnText]="'resetPassword.setNewPass' | translate"></app-spinner>
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="bottom-bg"></div>
</div>
