<div class="wrapper">
  <div class="avatar" [style.width.px]="size"
       [style.height.px]="size">
    <div class="avatar-image" *ngIf="groupAvatar"
         [style.backgroundColor]="'transparent'"
         [style.backgroundImage]="avatarUrl()">
    </div>
    <div [style.width.px]="size"
         [style.height.px]="size"
         class="avatar-initials-helper">
      <i *ngIf="!groupAvatar"
         class="teletype-icon-group"
         [ngClass]="{'big': size === 60}"></i>
    </div>
  </div>
</div>
