import { Injectable } from '@angular/core';
import { SocketsLogService } from '@app/core/sockets-log.service';
import find from 'lodash-es/find';
import each from 'lodash-es/each';
import { environment } from '@env/environment';
import { CookiesService } from '@app/core/cookies.service';
import { Interval, Duration } from 'luxon';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProjectApiService, ProjectData, ProjectsResponse } from '@app/core/api/api.project';
import { UtilsService } from '@app/core/utils.service';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { StorageService } from '@app/core/storage/storage.service';

export interface ISound {
  incomingWidgetMessage: string;
  newConversationWidget: string;
  incomingMessage: string;
  newConversation: string;
  outgoingMessage: string;
  reminder: string;
}

const HOST = `${window.document.location.protocol}//${window.document.location.host}`;
const FILE_PATH = `${HOST}/assets/audio/`;

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private defaultSoundTheme = {
    incomingWidgetMessage: `${FILE_PATH}tadah_04.wav`,
    newConversationWidget: `${FILE_PATH}tadah_06.wav`,
    incomingMessage: `${FILE_PATH}incoming_message.wav`,
    newConversation: `${FILE_PATH}new_appeals.wav`,
    outgoingMessage: `${FILE_PATH}send_message.wav`,
    reminder: `${FILE_PATH}fh.wav`,
  };
  public projects$: BehaviorSubject<ProjectData[]> = new BehaviorSubject(null);
  public isProjectsLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private _currentProject: ProjectData;
  private currentProjectData = new BehaviorSubject<any>(undefined);
  private soundThemeSubject = new BehaviorSubject<ISound>(this.defaultSoundTheme);

  public soundTheme = {...this.defaultSoundTheme};

  get isProjectsLoaded$() {
    return this.isProjectsLoaded.asObservable();
  }

  get projects(): ProjectData[] {
    return this.projects$.getValue();
  }

  set projects(projects: ProjectData[]) {
    projects = this.handleSetProjects(projects);
    this.getCurrentProject();
    this.projects$.next(projects);
  }

  get isShowCustomFields() {
    const { personCustomFieldsSettings } = this.currentProject;
    return personCustomFieldsSettings && personCustomFieldsSettings.length > 0;
  }

  get domain(): string {
    return window.document.location.host.replace(/(https?\:\/\/)/ig, '');
  }

  get currentProject() {
    return this._currentProject;
  }

  set currentProject(project: ProjectData) {
    this.socketLogService.setProjectId(project.id);
    this._currentProject = project;
    this.currentProjectData.next(this._currentProject);
  }

  private requestPending: boolean;

  constructor(
    private api: ProjectApiService,
    private cookies: CookiesService,
    private utils: UtilsService,
    private authenticationService: AuthenticationService,
    private storage: StorageService,
    private socketLogService: SocketsLogService,
  ) {
    this.utils.clearOldMessagesFromStorage();
    this.updateProject();
  }

  handleSetProjects(projects: ProjectData[]): ProjectData[] {
    each(projects, (v: ProjectData, i: number) => {
      if (!environment.production) {
        projects[i].url = HOST;
      }
      projects[i].url = projects[i].url.replace(/(https?\:\/\/)/ig, '');
    });
    return projects;
  }

  setProjectDirect(projects: ProjectData[]) {
    this.projects = projects;
    this.getCurrentProject();
    this.isProjectsLoaded.next(true);
  }

  updateProject() {
    if (this.requestPending || !this.authenticationService.isAuthenticated()) {
      return;
    }
    this.requestPending = true;

    this.api.getProjects().pipe(
      finalize(() => {
        this.isProjectsLoaded.next(true);
        this.requestPending = false;
      })
    ).subscribe((data: ProjectsResponse) => {
      let projects = [];
      each(data.data, (p: ProjectData) => {
        p.domain = p.domain.toLowerCase();
        p.url = p.url.toLowerCase();
        projects.push(p);
      });
      projects = this.handleSetProjects(projects);
      this.getCurrentProject();
      this.projects$.next(projects);
      if (projects.length === 0) {
        this.clearLastProject();
      }
    });
  }

  private getParams(key: string) {
    // don't use 'this.route.snapshot.queryParamMap.get()' it doesn't work here
    return new URLSearchParams(window.location.search).get(key);
  }

  public getProjects(): Observable<any> {
    return this.projects$.asObservable();
  }

  public getCurrentProject(force: boolean = false): ProjectData | null {
    if (!this.projects) {
      return null;
    }
    /*
      В localStorage храниться два поля lastProjectId, один со значением null,
      а другой с нормальным. Из-за того, что cookies.get возвращает первый встречный, то
      возвращалось значение null. Я так и не понял откуда брался null, возможно из-за кривого метода
      clearLastProject, который не удалял значение, а просто переписывал на null (других причин
      не нашел).
     */
    const lastProjectId =  this.cookies.getAll()['lastProjectId'];
    let currentProject;
    let currentProjectFromCookies = find(this.projects, { id: lastProjectId });
    if (environment.switchDomainByProject) {
      currentProject = find(this.projects, { url: this.domain });
    } else {
      currentProject = find(this.projects, { id: localStorage.getItem('projectId') });
    }
    currentProject = currentProject || currentProjectFromCookies || this.projects[0];
    if (this.currentProject && !force) {
      this.currentProject = currentProject;
      this.storage.setCurrentProjectId(currentProject.id);
      return this.currentProject;
    }
    const callbackParam = this.getParams('callback');
    let callbackStateParam = this.getParams('state');
    let facebookProjectId: string;
    if (callbackStateParam) {
      callbackStateParam = JSON.parse(callbackStateParam);
      facebookProjectId = callbackStateParam['projectId'] || '';
    }

    const callbackArr = callbackParam && callbackParam.split(',') || null;
    const vkProjectId = callbackArr && callbackArr.length > 1 && callbackArr[1].length > 10 && callbackArr[1] || '';
    const callBackProjectId = vkProjectId || facebookProjectId;

    if (!currentProject && this.projects !== undefined && this.projects.length > 0) {
      const savedProjectId = callBackProjectId ? callBackProjectId : this.cookies.get('lastProjectId') || null;
      currentProject = savedProjectId ? find(this.projects, { id: savedProjectId }) ||
        this.projects[0] : this.projects[0];
    }

    if (currentProject && currentProject.id) {
      const interval = Interval.after(new Date(), Duration.fromObject({ years: 1 })).toDuration('days')
        .toObject().days;
      this.cookies.set('lastProjectId', currentProject.id, interval, '/', environment.cookiesOrigin);
    }

    if (
      currentProject &&
      currentProject.domain &&
      window.location.pathname.indexOf('registration') === -1 &&
      location.origin.toLowerCase().indexOf('localhost') === -1 &&
      window.location.pathname.indexOf('notifications') === -1
    ) {
      const subDomain = window.location.hostname.split('.')[0];
      if (subDomain !== currentProject.domain) {
        if (environment.switchDomainByProject) {
          window.location.href = location.href.replace(subDomain, currentProject.domain);
        }

        localStorage.setItem('projectId', currentProject.id);
      }
    }

    this.currentProject = currentProject || null;
    const theme = currentProject?.soundSettings?.theme || null;
    if (theme) {
      this.setCurrentSoundTheme(theme);
    }

    if (currentProject?.id) {
      this.storage.setCurrentProjectId(currentProject.id);
    }

    return this.currentProject;
  }

  public getObservableCurrentProject(): Observable<ProjectData | any> {
    return this.currentProjectData.asObservable();
  }

  public clearLastProject() {
    if (this.projects.length === 0) {
      this.cookies.delete('lastProjectId');
    }
  }

  public clear() {
    this.projects$.next(null);
  }

  public getSoundTheme(): Observable<ISound> {
    return this.soundThemeSubject.asObservable();
  }

  public setCurrentSoundTheme(theme: number) {
    this.soundTheme = {
      incomingWidgetMessage: `${FILE_PATH}${theme === 10 ? 'tadah_04.wav' : 'tadah_04.wav'}`,
      newConversationWidget: `${FILE_PATH}${theme === 10 ? 'tadah_06.wav' : 'tadah_06.wav'}`,
      incomingMessage: `${FILE_PATH}${theme === 10 ? 'incoming_message.wav' : 'incoming_message.wav'}`,
      newConversation: `${FILE_PATH}${theme === 10 ? 'new_appeals.wav' : 'new_appeals.wav'}`,
      reminder: `${FILE_PATH}incoming_message.wav`,
      outgoingMessage: theme === 10 ? `${FILE_PATH}send_message.wav` : null
    };
    this.soundThemeSubject.next(this.soundTheme);
  }
}
