<div class="modal-header">
  <h4 class="modal-title pull-left">{{ title | translate }}</h4>
  <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="hideModalWithCancel()">
    <i class="teletype-icon-close"></i>
  </button>
</div>
<div class="modal-body">
  <form class="login-form" (submit)="editCompany()" autocomplete="off" [formGroup]="companyForm" #companyFormForm="ngForm"
          novalidate>
            <div class="form-group" [ngClass]="{'filled': companyForm.value.name, 'error': errorsList.name}">
              <app-dadata [config]="dadataConfig" [fieldMapping]="dadataFieldsModifications" [form]="companyForm">
                <input appAutofocus appDadata [form]="companyForm"
                [skip]="companyForm.valid"
                type="text"
                class="form-control"
                formControlName="name"
                autocomplete="off"
                [placeholder]="'settings.payment.form.name' | translate"
                (blur)="handleFieldBlur('name')"
                required />
                <span class="floating-label">{{ 'settings.payment.form.name' | translate }}</span>
              </app-dadata>
              <span *ngIf="companyForm.get('name').hasError('required')" class="form-group-error">
                {{ 'blank' | translate }}
              </span>
            </div>
          <div class="row-line">
            <div class="column">
              <div class="form-group" [ngClass]="{'filled': companyForm.value.inn, 'error': errorsList.inn}">
                <app-dadata [config]="dadataConfig" [fieldMapping]="dadataFieldsModifications" [form]="companyForm">
                  <input appDadata [form]="companyForm"
                  type="number"
                  class="form-control"
                  formControlName="inn"
                  autocomplete="off"
                  [placeholder]="'settings.payment.form.inn' | translate"
                  (blur)="handleFieldBlur('inn')"
                  required />
                  <span class="floating-label">{{ 'settings.payment.form.inn' | translate }}</span>
                </app-dadata>
                <span *ngIf="companyForm.get('inn').hasError('required')" class="form-group-error">
                  {{ 'blank' | translate }}
                </span>
                <span *ngIf="companyForm.get('inn').hasError('pattern')" class="form-group-error">
                  {{ 'settings.payment.form.error.numeric' | translate }}
                </span>
              </div>
            </div>
            <div class="column">
              <div class="form-group" [ngClass]="{'filled': companyForm.value.kpp, 'error': errorsList.kpp}">
                <input
                       type="number"
                       class="form-control"
                       formControlName="kpp"
                       autocomplete="off"
                       [placeholder]="'settings.payment.form.kpp' | translate"
                       (blur)="handleFieldBlur('kpp')"
                />
                <span class="floating-label">{{ 'settings.payment.form.kpp' | translate }}</span>
                <span *ngIf="companyForm.get('kpp').hasError('required')" class="form-group-error">
                  {{ 'blank' | translate }}
                </span>
                <span *ngIf="companyForm.get('kpp').hasError('pattern')" class="form-group-error">
                  {{ 'settings.payment.form.error.numeric' | translate }}
                </span>
              </div>
            </div>
          </div>
          <div class="form-group" [ngClass]="{'filled': companyForm.value.legalAddress, 'error': errorsList.legalAddress}">
            <textarea
            class="form-control"
            formControlName="legalAddress"
            autocomplete="off"
            [placeholder]="'settings.payment.form.legalAddress' | translate"
            (blur)="handleFieldBlur('legalAddress')"
            required></textarea>
            <span class="floating-label">{{ 'settings.payment.form.legalAddress' | translate }}</span>
            <span *ngIf="companyForm.get('legalAddress').hasError('required')" class="form-group-error">
              {{ 'blank' | translate }}
            </span>
          </div>
          <div class="form-group" [ngClass]="{'filled': companyForm.value.postalAddress, 'error': errorsList.postalAddress}">
            <textarea
            type="phone"
            class="form-control"
            formControlName="postalAddress"
            autocomplete="off"
            [placeholder]="'settings.payment.form.postalAddress' | translate"
            (blur)="handleFieldBlur('postalAddress')"></textarea>
            <span class="floating-label">{{ 'settings.payment.form.postalAddress' | translate }}</span>
            <span *ngIf="companyForm.get('postalAddress').hasError('required')" class="form-group-error">
              {{ 'blank' | translate }}
            </span>
          </div>
          <div class="form-group" [ngClass]="{'filled': companyForm.value.phone, 'error': errorsList.phone}">
            <input
                   type="tel"
                   class="form-control"
                   formControlName="phone"
                   autocomplete="off"
                   [placeholder]="'settings.payment.form.phone' | translate"
                   (blur)="handleFieldBlur('phone')"
            />
            <span class="floating-label">{{ 'settings.payment.form.phone' | translate }}</span>
            <span *ngIf="companyForm.get('phone').hasError('required')" class="form-group-error">
              {{ 'blank' | translate }}
            </span>
            <span *ngIf="companyForm.get('phone').hasError('pattern')" class="form-group-error">
              {{ 'error.phoneInvalid' | translate }}
            </span>
          </div>
          <app-alert *ngIf="error" [alertContent]="error"></app-alert>
          <div class="form-group btn-container">
            <button class="btn btn-primary with-spinner"
                    type="button"
                    [disabled]="isLoading"
                    (click)="editCompany()">
              <app-spinner [inProgress]="isLoading"
                           [btnText]="companyExists && 'button.save' || 'settings.payment.create' | translate "></app-spinner>
            </button>
            <button *ngIf="companyExists" class="btn btn-delete" type="button" (click)="remove()">
              <span>{{ 'settings.payment.deleteCompany' | translate }}</span>
            </button>
          </div>
  </form>
</div>
