import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { PromoEndDate } from '@app/core/tariff.service';
import { DateTime } from 'luxon';

const MilliSecondsInASecond = 1000;
const HoursInADay = 24;
const MinutesInAnHour = 60;
const SecondsInAMinute  = 60;

@Component({
  selector: 'app-promo-timer',
  templateUrl: './promo-timer.component.html',
  styleUrls: ['./promo-timer.component.scss']
})
export class PromoTimerComponent implements OnInit, OnDestroy {
  @Input() promoEndDate: PromoEndDate;
  public minutes;
  public hours;
  public days;
  private subscription: Subscription;

  ngOnInit() {
    this.subscription = interval(1000)
      .subscribe(x => { this.getTimeDifference(); });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private getTimeDifference() {
    const dDay = new Date(this.promoEndDate.date);
    const timezone = this.promoEndDate.timezone.toUpperCase() || 'UTC';
    const timeDifference = dDay.getTime() - new Date(DateTime.local().setZone(timezone).toSQL()).getTime();

    this.minutes =
      Math.floor((timeDifference) / (MilliSecondsInASecond * MinutesInAnHour) % SecondsInAMinute);
    this.hours =
      Math.floor((timeDifference) / (MilliSecondsInASecond * MinutesInAnHour * SecondsInAMinute) % HoursInADay);
    this.days =
      Math.floor((timeDifference) / (MilliSecondsInASecond * MinutesInAnHour * SecondsInAMinute * HoursInADay));
  }
}
