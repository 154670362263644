import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { UserData, UserResponse } from '../api/api.profile';
import { ProjectData } from '../api/api.project';
import { ProjectService } from '../project.service';
import { UserService } from '../user.service';
import { UtilsService } from '../utils.service';

import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {

  constructor(
    private projectService: ProjectService,
    private userService: UserService,
    private utils: UtilsService,
    private authenticationService: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      const authToken = route.queryParams.authToken;
      const widget = route?.data?.widget;

      if (authToken) {
        if (this.isFromAmoWidget(widget)) {
          this.authenticationService.loginWidget(authToken);
        } else {
          this.authenticationService.login(authToken, true);
        }
      }

      if (route.queryParams.project) {
        let project = JSON.parse(this.b64ToUtf8(route.queryParams.project)) as ProjectData;

        project = this.utils.camelKeys(project);

        this.projectService.setProjectDirect([project]);

      }

      if (route.queryParams.client) {
        let client = JSON.parse(this.b64ToUtf8(route.queryParams.client)) as UserData;

        client = this.utils.camelKeys(client);

        this.userService.userResponse({data: client} as UserResponse);
      }

      if (!this.isFromAmoWidget(widget)) {
        if (this.authenticationService.isAuthenticated()) {
          return true;
        }
        this.authenticationService.redirectToLogin();
        return false;
      } else {
        return true;
      }
  }

  private isFromAmoWidget (isWidget?: boolean): boolean {
    const url = new URL(window.location.href);
    const crmAuthToken = url.searchParams.get('authToken');
    return isWidget || crmAuthToken !== null;
  }

  private b64ToUtf8(str: string): string {
    return decodeURIComponent(escape(window.atob(str)));
  }
}
