import { Injectable } from '@angular/core';
import { OperatorData, Response } from '@app/core/interfaces/api.response';
import { Role } from '@app/core/permissions.service';
import { BehaviorSubject, Observable } from 'rxjs';
import map from 'lodash-es/map';
import each from 'lodash-es/each';
import { WebsocketsService, WsEvent } from '@app/core/websockets.service';
import { ProjectService } from '@app/core/project.service';
import { UtilsService } from '@app/core/utils.service';
import { TeammateApiService } from '@app/core/api/api.teammate';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { StorageService } from '@app/core/storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class OperatorService {

  public operators$: BehaviorSubject<OperatorData[]> = new BehaviorSubject(null);
  public allOperators$: BehaviorSubject<OperatorData[]> = new BehaviorSubject(null);
  public isOperatorsLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private requestPending: boolean;

  get operators(): OperatorData[] {
    return this.operators$.getValue();
  }

  get allOperators(): OperatorData[] {
    return this.allOperators$.getValue();
  }

  set operators(operators: OperatorData[]) {
    this.operators$.next(operators);
  }

  set allOperators(operators: OperatorData[]) {
    this.allOperators$.next(operators);
  }

  get operatorStatuses() {
    return {
      10: 'Pending',
      15: 'Setup',
      20: 'Active',
      30: 'Busy',
      40: 'Hidden',
      50: 'Deleted'
    };
  }

  get positionByStatus() {
    return {
      10: 2,
      20: 1,
      30: 3,
      40: 4,
      50: 5
    };
  }

  get positionByRole() {
    return {
      'owner': 0,
      'admin': 1,
      'helper': 2,
      'operator': 3,
    };
  }

  /** return count of users with role "operator" */
  get operatorsCount(): number {
    if (!this.operators) {
      return 0;
    }

    const isUserOperator = (operator: OperatorData) => operator.roles.includes(Role.Operator);
    const operatorsList = this.operators.filter(isUserOperator);
    return operatorsList.length;
  }

  get isOperatorsLoaded$() {
    return this.isOperatorsLoaded.asObservable();
  }

  constructor(
    private api: TeammateApiService,
    private ws: WebsocketsService,
    private projectService: ProjectService,
    private utils: UtilsService,
    private translateService: TranslateService,
    private storage: StorageService,
  ) {
    this.projectService.isProjectsLoaded$.subscribe(isLoading => {
      if (isLoading) {
        this.updateOperatorsList();
      }
    });

    this.ws.on(WsEvent.USER_STATUS_CHANGE, (_data: any) => {
      const data = this.utils.camelKeys(_data);
      const currentProjectId = this.projectService?.currentProject?.id || null;
      if (!data || !data.clientId || (currentProjectId && data.projectId !== currentProjectId)) {
        return;
      }
      this.updateOperatorsList();
    });
  }

  // делает первую букву заглавной
  ucfirst(str: string) {
    return `${str.charAt(0).toUpperCase()}${str.substr(1, str.length - 1)}`;
  }

  /** список активных операторов */
  getOperators(): Observable<any> {
    return this.operators$.asObservable();
  }

  /** список всех операторов (включая удаленных и не принявших приглашение) */
  getAllOperators(): Observable<any> {
    return this.allOperators$.asObservable();
  }

  onOperatorsComplete(response: Response) {
    const operators = response && response.data;
    if (operators) {
      operators.sort((a: any, b: any) => {
        return Number(this.positionByStatus[a.status]) - Number(this.positionByStatus[b.status]);
      }).sort((a: any, b: any) => {
        return Number(this.positionByRole[a.roles[0]]) - Number(this.positionByRole[b.roles[0]]);
      });

      each(operators, (operator: any) => {
        operator.status = this.operatorStatuses[parseInt(operator.status, 10)];

        // я абсолютно не понимаю зачем это было сделано, но на всякий пока оставил
        // (в паре мест, где делала проверка на определённую роль поменял на маленькую букву)
        // if (operator.roles && operator.roles.length > 0) {
        //   operator.roles = map(operator.roles, this.ucfirst);
        // }

        operator.statusTooltip = this.getStatusTooltipText(operator);
      });

      this.allOperators = operators;
      this.operators = operators.filter(operator => ['Pending', 'Deleted'].indexOf(operator.status) === -1 &&
        operator.roles.indexOf('operator') !== -1);
    }
  }

  updateOperatorsList() {
    if (this.requestPending) {
      return;
    }
    this.requestPending = true;

    if (this.storage.currentProjectId) {
      this.loadClients();
    } else {
      this.projectService.isProjectsLoaded$.pipe().subscribe((isLoaded) => {
        if (isLoaded && this.storage.currentProjectId) {
          this.loadClients();
        }
      });
    }
  }

  private loadClients() {
    this.api.getOperatorsList().pipe(
      finalize(() => {
        this.isOperatorsLoaded.next(true);
        this.requestPending = false;
      })
    ).subscribe((response: Response) => {
      this.onOperatorsComplete(response);
    });
  }

  getStatusTooltipText(operator: any) {
    const tooltipKey = `operatorStatusTooltip.${operator.status.toLowerCase()}`;
    const result = this.translateService.instant(tooltipKey);
    return result === tooltipKey ? null : result;
  }

  getOperatorById(operatorId: string): OperatorData {
    const match = this.allOperators.find(operator => operator.id === operatorId);
    if (match) {
      return match;
    } else {
      throw new Error(`Operator with id: ${operatorId} doesn't exist`)
    }
  }

  getOperatorAvatar(operatorId: string): string {
    const match = this.operators.find(operator => operator.id === operatorId);
    return match ? match.avatar || match.avatarDefault : '';
  }

  getOperatorFullName(operatorId: string): string {
    const match = this.operators.find(operator => operator.id === operatorId);
    return match ? `${match.name} ${match.lastName}` : '';
  }

  setRoleForClient(clientId: string, newRole: string) {
    this.api.setRoleForClient(clientId, newRole).subscribe((res) => {
      if (res.success) {
        this.updateOperatorsList();
      }
    });
  }
}
