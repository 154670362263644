<div class="appeal-selector" [ngClass]="class" [class.person]="isPerson">
  <div class="appeal-avatar">
    <app-avatar
      *ngIf="!appeal.appeal?.channelSpecific?.groupChat"
      [animal]="personAnimal"
      [avatar]="avatar"
      [size]="avatarSize"
      [fontSize]="avatarFontSize"
    ></app-avatar>

    <app-group-avatar
      *ngIf="appeal.appeal?.channelSpecific?.groupChat"
      [groupAvatar]="appeal.appeal?.channelSpecific?.groupChat?.icon"
      [size]="avatarSize"
    ></app-group-avatar>
  </div>
  <div class="appeal-message-container">
    <div class="appeal-user-name-date">
      <div class="appeal-user-name">
        {{ userName | unescape }}
      </div>
      <ng-template [ngIf]="isNewAppeal && !failedMessage && !sendFailed">
        <i class="teletype-icon-close" (click)="appealDelete()"></i>
      </ng-template>

      <ng-container  *ngIf="(isTagsPaid$ | async) && (personTags$ | async) as personTags">
        <div class="tags" *ngIf="personTags.length && (this.isTagsAvailable | async)" [tooltip]="tagDescription" placement="right" container="body" containerClass="tags-description">
          <span *ngFor="let tag of personTags" class="tags__tag" [style.background-color]="tag.color"></span>

          <ng-template #tagDescription>
            <div class="tags-description-tag" *ngFor="let tag of personTags">
              <span class="tags-description-tag-color" [style.background-color]="tag.color"></span>
              {{ tag.tag }}
            </div>
          </ng-template>
        </div>
      </ng-container>
      <ng-template [ngIf]="!isNewAppeal && !failedMessage && !sendFailed">
        <app-message-date [date]="messageDate" [shortFormat]="true"></app-message-date>
      </ng-template>
      <ng-template [ngIf]="failedMessage || sendFailed">
        <i class="teletype-icon-failed"
           [tooltip]="'errorSendMessage' | translate"
           placement="top"
           tooltipAppendToBody="true">
        </i>
      </ng-template>
    </div>
    <div class="last-message-wrapper">
      <div class="appeal-last-message">
        <ng-template [ngIf]="!userTyping">
          <span *ngIf="fromOperator && message" class="from-operator-name">
            {{ fromOperatorText | unescape | translate }}:
          </span>

          <span *ngIf="isNewAppeal">{{ 'startConversation' | translate }}</span>

          <span *ngIf="!isNewAppeal" [innerHtml]="message"></span>
        </ng-template>
        <ng-template [ngIf]="userTyping">
          <span class="user-typing">{{ userTyping.person }} {{ 'isTyping' | translate }}</span>
        </ng-template>
      </div>
      <div class="unread-count" *ngIf="!isActive && countNewMessages">
        {{ countNewMessages }}
      </div>
    </div>
    <div class="label-row"
         [class.only-assign]="!hasLabel && clientName"
         [class.only-label]="hasLabel && isAppointed">
      <span class="messenger-label" *ngIf="hasLabel">{{ messenger }}</span>
      <span class="assign-label" *ngIf="clientName && !isAppointed">
        <span class="assign-name">{{ clientName }}</span>
        <span class="assign-avatar" *ngIf="clientAvatar" [style.background-image]="sanitizeUrl(clientAvatar)"></span>
      </span>
    </div>
  </div>
</div>
