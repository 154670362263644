import { Injectable } from '@angular/core';
import { SipAccount } from '@app/core/sipuni.service';
import { UtilsService } from '@app/core/utils.service';
import { Observable } from 'rxjs';
import { ApiService } from '@app/core/api/api.service';
import { routes } from '@app/core/config/apiRoutes';
import { Response } from '@app/core/interfaces/api.response';

type TelephonyIntegration = SipAccount;

export interface SipResponse extends Response {
  data?: TelephonyIntegration[];
}

@Injectable({
  providedIn: 'root',
})
export class TelephonyApiService {
  private routes = routes;

  constructor(
    private api: ApiService,
    private utils: UtilsService,
  ) {

  }

  public getListTelephonyIntegrations(): Observable<SipResponse> {
    return this.api.httpGet(this.routes.sipConfig);
  }

  public makeCall(id: string, phone: string): Observable<Response> {
    return this.api.httpPost(this.routes.sipCall(id), this.utils.snakeKeys({ phone }));
  }
}
