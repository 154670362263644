import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { OperatorService } from '@app/core/operator.service';

@Injectable({
  providedIn: 'root'
})
export class OperatorGuard implements CanActivate {

  constructor(
    private router: Router,
    private operatorService: OperatorService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> | boolean {
    const operators = this.operatorService.operators$.getValue();
    if (operators) {
      return true;
    }
    return new Promise<boolean>(resolve => {
      this.operatorService.isOperatorsLoaded$.subscribe(isLoaded => {
        if (isLoaded) {
          resolve(true);
        }
      });
    });
  }

}
