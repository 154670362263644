import { Component, Input, OnInit } from '@angular/core';
import { NewsService } from '@app/core/news.service';
import { News } from '@app/core/api/api.project';

@Component({
  selector: 'app-popup-news',
  templateUrl: './popup-news.component.html',
  styleUrls: ['./popup-news.component.scss']
})
export class PopupNewsComponent implements OnInit {

  @Input() news: News;

  constructor(
    private newsService: NewsService
  ) { }

  ngOnInit() {
  }

  closeModal() {
    this.newsService.markNewsAsRead('popup');
  }

}
