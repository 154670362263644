// `.env.ts` is generated by the `npm run env` command
import { env } from './.env';
// import { firebaseConfig } from '@env/firebase.prod';

export const environment = {
  production: true,
  productionEnv: true,
  switchDomainByProject: true,
  version: env.version,
  baseDomain: 'panel.teletype.app',
  apiUrl: 'https://api.teletype.app/api',
  landingUrl: 'https://teletype.app',
  wsUrl: 'https://websocket.teletype.app',
  cookiesOrigin: '.teletype.app',
  assetsUrl: 'https://panel.teletype.app/',
  apiVersion: '1',
  defaultLanguage: 'ru',
  supportedLanguages: [
    'en',
    'ru'
  ],
  giphyApiKey: 'OGGZ65V9rVsxTpHDUCMegJn7EJDMB2bl',
  oneSignalAppId: '7331f2b3-827c-411a-8a5d-361bf5a392c8',
  // firebaseConfig: firebaseConfig,
  widgetBaseDomain: 'teletype.app',
  supportWidgetId: 'c0Vwp5kOf1OJOqfA23g0HOJUjw1WgJHyPF4gkRegWvY-Rmst-FbNNzepPCUEasDI',
  vkAppId: '7028732',
  urlDoc: 'https://docs.google.com/spreadsheets/d/1-mXLLWC1eiwU4PU2S1c_gkFedCXVtg4NhRWA9Mcin9M/edit?usp=sharing',
  facebookId: '451527618624988',
  features: {
    ping: true,
    messageEdit: false,
    unsubscribe: false,
    onlyRuLanguage: false,
    groups: false,
    statistics: true,
    'affiliate-program': true,
    'tariff-setup': true,
    'public-api': true,
    'payment': true,
    'transaction': true,
    channels: {
      'instagramComments': false,
      'instagramCommentsBusiness': false,
    },
    dateFormat: {
      ru: 'dd LLL',
      en: 'LLL dd'
    },
    telegramBotInProfile: true,
    refillAccount: {
      amount: 0,
      minAmount: '5000',
      amountFrom1: '10 000 ₽',
      amountTo1: '19 999 ₽',
      bonus1: '+10%',
      amountFrom2: '20 000 ₽',
      amountTo2: '49 999 ₽',
      bonus2: '+15%',
      amountFrom3: '50 000 ₽',
      bonus3: '+25%',
      amountLess: 1000,
      refillToAmount: 10000
    }
  },
  metrikaId: '54576100'
};
