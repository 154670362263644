<div
  class="teletype-toast"
  [class._ready-for-delete]="toast.isReadyForDelete | async"
  [class._ready-for-appearance]="toast.isReadyForAppear | async"
>
  <div class="teletype-toast-icon emoji-icon__{{toast.icon}}"></div>

  <div class="teletype-toast-main">
    <div class="teletype-toast-main__title">
      {{ toast.title | translate }}
    </div>

    <div class="teletype-toast-main__text">
      {{ toast.text | translate }}
    </div>
  </div>

  <div class="teletype-toast-actions">
    <button class="teletype-toast-actions__close-button" (click)="toast.closeToast()">
      <i class="teletype-icon-close"></i>

      <app-circle-progress-bar
        *ngIf="!toast.disableAutoClose"
        size="26"
        primaryColor="#E0E0E0"
        [accentColor]="toast.colorProgressBar"
        [progress]="toast.progress | async"
        class="teletype-progress-bar"
      ></app-circle-progress-bar>
    </button>
  </div>
</div>
