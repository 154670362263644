import {
  Directive, ElementRef, HostListener, OnInit, OnDestroy, Renderer2, Inject, Injectable, Input
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';


const WEAK = new RegExp('^(?=.{6,})');
// eslint-disable-next-line max-len
const NORMAL = new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})');
// eslint-disable-next-line max-len
const GOOD = new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})');
const STRONG = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
const PASSWORD_LEVELS = ['weak', 'normal', 'good', 'strong'];
const ELEMENT_ID = '#level';

@Directive({
  selector: '[reliability], [appReliability]'
})
@Injectable()
export class ReliabilityDirective implements OnInit, OnDestroy {

  @Input() reliability: boolean;
  private lastLevel: string;
  private translatedList: any;

  constructor(
    private translateService: TranslateService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.translateService
      .get('password.reliability', { value: 'reliability' }).subscribe((res: any) => {
        this.translatedList = res;
      });
  }

  private clear(element: any) {
    if (!element) {
      return;
    }
    PASSWORD_LEVELS.forEach((item) => {
      element.classList.remove(item);
    });
  }

  @HostListener('input', ['$event']) onEvent($event: any) {
    const value = $event && $event.target && $event.target.value;
    let level;
    if (value && this.reliability) {
      const levelElement = this.document.querySelector(ELEMENT_ID);
      this.clear(levelElement);

      levelElement.innerHTML = '';
      if (STRONG.test(value)) {
        level = PASSWORD_LEVELS[3];
      } else if (GOOD.test(value)) {
        level = PASSWORD_LEVELS[2];
      } else if (NORMAL.test(value)) {
        level = PASSWORD_LEVELS[1];
      } else if (WEAK.test(value)) {
        level = PASSWORD_LEVELS[0];
      }

      if (level) {
        this.lastLevel = level;
        levelElement.innerHTML = this.translatedList[level];
        this.renderer.addClass(levelElement, level);

        setTimeout(() => {
          this.renderer.addClass(levelElement, 'run');
          setTimeout(() => {
            this.renderer.removeClass(levelElement, 'run');
          }, 2000);
        });
      }
    }
  }

  ngOnInit() {
    if (this.elementRef.nativeElement.getAttribute('type') === 'password' && this.reliability) {
      const levelElement = this.document.querySelector(ELEMENT_ID);
      const child = levelElement ? levelElement : this.document.createElement('div');
      child.setAttribute('id', 'level');
      this.renderer.insertBefore(this.elementRef.nativeElement.parentNode, child, this.elementRef.nativeElement);
    }
  }

  ngOnDestroy() {
    const levelElement = this.document.querySelector(ELEMENT_ID);
    if (levelElement) {
      levelElement.remove();
    }
  }
}
