import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  NgZone,
  ViewChild,
} from '@angular/core';
import { I18nService } from '@app/core';
import { DateTime } from 'luxon';
import { UserService } from '@app/core/user.service';
import { AlertsService } from '@app/core/alerts/alerts.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { PersonApiService } from '@app/core/api/api.person';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-post-sidebar',
  templateUrl: './post-sidebar.component.html',
  styleUrls: ['./post-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false
})
export class PostSidebarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedPost: any;
  @Input() userName: string;
  @Input() selectedAppealId: string;

  public translations: any;
  public preview: string;
  public caption: string;
  public date: string;
  public collapse = false;
  public isMobile = false;

  private unsub$ = new Subject<void>();

  constructor(
    private zone: NgZone,
    private api: PersonApiService,
    private detector: ChangeDetectorRef,
    private userService: UserService,
    private lang: I18nService,
    private alerts: AlertsService,
    private sanitizer: DomSanitizer,
    private deviceDetector: DeviceDetectorService
  ) {
    this.isMobile = this.deviceDetector.isMobile();
    this.translations = this.lang.translateService.translations[this.lang.language];
  }

  ngOnInit() {
    this.preview = this.selectedPost.channelSpecific.instagramPost &&
      this.selectedPost.channelSpecific.instagramPost.attachments &&
      this.selectedPost.channelSpecific.instagramPost.attachments[0] &&
      this.selectedPost.channelSpecific.instagramPost.attachments[0].attachmentPreviewUrl;
    this.caption = this.selectedPost.channelSpecific.instagramPost.caption;
    this.getDate();
  }

  ngOnDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.zone.runOutsideAngular(() => {
      if (changes.selectedPost && changes.selectedPost.currentValue !== undefined) {
        this.selectedPost = changes.selectedPost.currentValue;
        const instagramPost = this.selectedPost.channelSpecific.instagramPost;
        this.preview = instagramPost && instagramPost.attachments && instagramPost.attachments[0] &&
          instagramPost.attachments[0].attachmentPreviewUrl;
        this.caption = instagramPost.caption;
        this.getDate();

        if (!this.detector['destroyed']) {
          this.detector.detectChanges();
        }
      }
    });
  }

  htmlEntities(text: string): string {
    return String(text).replace(/</g, '&lt;').replace(/>/g, '&gt;');
  }

  sanitizeUrl(url: string): SafeStyle {
    return this.zone.runOutsideAngular(() => {
      return this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
    });
  }

  getDate() {
    const language = this.lang.language;
    const createdAt = this.selectedPost.channelSpecific.instagramPost.createdAt;
    const date = DateTime.fromISO(createdAt.date).setLocale(language)
      .setZone(this.userService.user.timezone);
    this.date = date.toFormat('dd MMMM yyyy');
  }
}
