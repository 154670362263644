import { Injectable } from '@angular/core';
import { TemplateDirectory } from '@app/core/api/api.answer-pattern';
import { Response } from '@app/core/interfaces/api.response';
import { ProjectService } from '@app/core/project.service';
import { GroupApiService } from '@app/core/api/api.group';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, skip, take, tap } from 'rxjs/operators';

export interface GroupData {
  id: string;
  projectId: string;
  name: string;
  description: string;
  color: string;
  supervisorIds: string[];
  directories: TemplateDirectory[];
  activeOperators: {
    id: string;
    name: string;
    isOnline: boolean;
    isOperator: boolean;
    avatar: string;
    avatarDefault: string;
  }[];
  channels: {
    channelId: string;
    type: number;
    typeName: string;
    name: string;
    status: number;
    statusName: string;
    mode: number;
    modeName: 'categories_all' | 'categories_constraint'
    categories: string[];
    isolateClients: boolean;
  }[];
}

export interface Group {
  name: string;
  description: string;
  clientIds: string;
  channelsConfig: {
    channelId: string;
    mode: 'categories_all' | 'categories_constraint';
    isolateClients: boolean;
  }[];
}

@Injectable({
  providedIn: 'root',
})
export class GroupService {

  readonly listGroup$ = new BehaviorSubject<GroupData[]>([]);

  get listGroup(): GroupData[] {
    return this.listGroup$.value;
  }

  constructor(
    private api: GroupApiService,
    private projectService: ProjectService,
  ) {
    this.projectService.getProjects()
      .pipe(
        skip(1),
        take(1),
      )
      .subscribe(() => {
          this.updateListGroup();
        },
      );
  }

  updateListGroup() {
    const projectData = this.projectService.getCurrentProject();

    if (!projectData) {
      return;
    }

    this.api.getListGroup()
      .subscribe((response: Response) => {
        if (response.success) {
          const filteredGroups = response.data.filter((group) => group.projectId === projectData.id);
          this.listGroup$.next(filteredGroups);
        }
      });
  }

  createGroup(data: Group) {
    return this.api.groupCreate(data)
      .pipe(
        tap(() => this.updateListGroup()),
      );
  }

  updateGroup(groupId: string, data: Group) {
    return this.api.groupUpdate(groupId, data)
      .pipe(
        tap(() => this.updateListGroup()),
      );
  }

  deleteGroup(groupId: string) {
    return this.api.groupDelete(groupId)
      .pipe(
        tap(() => this.updateListGroup()),
      );
  }

  getUserGroup(userId: string): Observable<GroupData[]> {
    return this.listGroup$.asObservable().pipe(
      filter(listGroup => !!listGroup.length),
      map(listGroup => listGroup.filter(group => group.activeOperators.find(operator => operator.id === userId)))
    );
  }

  isUserSupervisorForChannel(channelId: string, userId: string): boolean {
    return !!this.listGroup.find(
      (group) => {
        const isGroupHasChannel = group.channels.find(channel => channel.channelId === channelId);
        const isUserSupervisorInGroup = group.supervisorIds.includes(userId);

        return isGroupHasChannel && isUserSupervisorInGroup;
      }
    )
  }
}
