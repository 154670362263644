<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'button.saveChanges' | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="cancelSave()">
      <i class="teletype-icon-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <p>
        {{ 'projects.reAssign' | translate }}
      </p>
      <div>
        <ng-select class="ng-custom-select operator-select"
                   id="selectItem"
                   [items]="operators"
                   bindLabel="name"
                   bindValue="id"
                   placeholder="{{ 'projects.attachedToAnotherTeammate' | translate }}"
                   [clearable]="false"
                   [(ngModel)]="selectedOperatorId"
                   (close)="onClose()">
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div class="avatar"
                 [style.background-image]="'url(' + (item.avatar || item.avatarDefault) + ')'"></div>
            <div class="name">{{ item.name + ' ' + item.lastName | maxLength: 30 : true }}</div>
          </ng-template>
          <ng-template ng-label-tmp let-item="item">
            <div class="avatar"
                 [style.background-image]="'url(' + (item.avatar || item.avatarDefault) + ')'"></div>
            <div class="name">{{ item.name + ' ' + item.lastName | maxLength: 30 : true }}</div>
          </ng-template>
          <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
            <div class="ng-option disabled">
              {{ 'crm.notFound' | translate }}
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="popup-buttons-container">
        <button class="btn btn-primary"
                type="button"
                (click)="doRemapping()">
          <span>{{ 'button.confirm' | translate }}</span>
        </button>

        <button class="btn btn-default"
                type="button"
                (click)="cancelSave()">
          <span>{{ 'button.cancel' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<div class="page-title" *ngIf="!create">
  {{ project && 'projects.editProject' || 'projects.createProject' | translate }}
</div>

<div class="note" [hidden]="project">{{'projects.nullProjectsFirst' | translate }}<br />
  {{'projects.nullProjectsSecond' | translate }}
</div>
<div class="form-container" *ngIf="addProjectForm">
  <form class="add-project-form"
        [class.indent]="!create"
        (submit)="saveProject(template)"
        [formGroup]="addProjectForm"
        autocomplete="off"
        #projectForm="ngForm"
        novalidate>

    <div class="form-group" [ngClass]="{'filled': addProjectForm.value.name, 'error': errorsList.name}">
      <input appAutofocus
             [skip]="addProjectForm.value.name"
             type="text"
             class="form-control"
             formControlName="name"
             autocomplete="off"
             [placeholder]="'placeHolder.projectName' | translate"
             (keyup)="setProjectUrl()"
             (blur)="handleFieldBlur('name')"
             maxlength="50"
             required />
      <span class="floating-label">{{ 'placeHolder.projectName' | translate }}</span>
      <span *ngIf="addProjectForm.get('name').hasError('required')" class="form-group-error">
        {{ 'error.projectName' | translate }}
      </span>
    </div>

    <div class="form-group" [ngClass]="{'error': errorsList.domain}">
      <app-ghostinput placeholder="{{ 'placeHolder.yourproject' | translate }}"
                      suffix="hostname"
                      [content]="addProjectForm.get('domain').value"
      >
        <input type="text"
               class="form-control"
               formControlName="domain"
               autocomplete="off"
               (keyup)="addressChanged = true"
               (ngModelChange)="handleChange($event)"
               (blur)="setProjectUrl(); handleFieldBlur('domain')"
               required />
      </app-ghostinput>
      <span *ngIf="addProjectForm.get('domain').hasError('required')" class="form-group-error">
        {{ 'domain.error.blank' | translate }}
      </span>
      <span *ngIf="addProjectForm.get('domain').hasError('pattern')" class="form-group-error">
        {{ 'domain.error.pattern' | translate }}
      </span>
      <span *ngIf="addProjectForm.get('domain').hasError('backend')" class="form-group-error">
        {{ inputError }}
      </span>
    </div>

    <div class="form-group" [ngClass]="{'filled': addProjectForm.value.timezone, 'error': errorsList.timezone}">
      <select class="form-control" formControlName="timezone" (blur)="handleFieldBlur('timezone')">
        <option value="">{{ 'placeHolder.selectTimezone' | translate }}</option>
        <option *ngFor="let item of timezones" [value]="item.value">{{ item.name }}</option>
      </select>
      <span *ngIf="addProjectForm.get('timezone').hasError('required')" class="form-group-error">
        {{ 'error.timeZone' | translate }}
      </span>
    </div>

    <div class="remember-me-forgot-password" *ngIf="permissionsService.isOwner()">
      <label class="checkbox-container" [ngClass]="{'checked': addProjectForm?.value?.operator}">
        <input type="checkbox" formControlName="operator" />
        <span *ngIf="!hasProject">{{'projects.willBeOperator' | translate }}</span>
        <span *ngIf="hasProject">{{'projects.willBeOperator' | translate }} {{ (!project.operator && costText) || '' }}
          <div class="help" role="tooltip" tabindex="0">
                <img src="../../../assets/svg/icon-info.svg" width="15" height="15" alt="">
                <span>{{ 'additionalInformationText' | translate }}</span>
              </div>
        </span>
      </label>
    </div>

    <div class="form-group">
      <app-alert *ngIf="error" [alertContent]="error"></app-alert>
    </div>

    <div class="buttons-container">
      <button class="btn btn-primary with-spinner"
              type="submit"
              [disabled]="inProgress"
              [class.done]="success">
        <app-spinner class="caption-normal"
                     [class.send]="inProgress"
                     [inProgress]="inProgress"
                     [btnText]="create && 'projects.createProject' || project && 'button.saveChanges' ||
                      'projects.createProject' | translate">
        </app-spinner>
      </button>
    </div>
    <div
      class="buttons-container"
      *ngIf="permissionsService.isOwner()"
      [hidden]="!project || create"
    >
      <button class="btn btn-delete"
              type="button"
              (click)="remove()"
              [disabled]="inProgress">
        <span>{{ 'projects.btnDelete'  | translate }}</span>
      </button>
    </div>
  </form>
</div>
