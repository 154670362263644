import { Component, OnInit, ViewChild, NgZone, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Validators, UntypedFormBuilder, NgForm, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import each from 'lodash-es/each';
import { AlertsService } from '@app/core/alerts/alerts.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, extract, I18nService } from '@app/core';
import { filter, takeUntil } from 'rxjs/operators';
import { Meta, Title } from '@angular/platform-browser';
import { Angulartics2 } from 'angulartics2';
import EqualValidator from '@app/core/validators/equal.validator';
import { PASSWORD_REGEX } from '@app/core/config/regExValidators';
import { LoginApiService } from '@app/core/api/api.login';
import { LocationService } from '@app/core/location.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit, OnDestroy {

  public completeRegistrationForm: UntypedFormGroup;
  public passwordKey: string;
  public isLoading: boolean;
  public errorsList: any = {};
  public externalId: string;
  public isPasswordChanged: boolean;

  private unsub$ = new Subject<void>();

  // @ts-ignore
  @ViewChild('completeRegistrationFormForm') completeRegistrationFormForm: NgForm;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private api: LoginApiService,
    private auth: AuthenticationService,
    private alerts: AlertsService,
    private zone: NgZone,
    private route: ActivatedRoute,
    private meta: Meta,
    private angulartics2: Angulartics2,
    private detector: ChangeDetectorRef,
    private i18nService: I18nService,
    private titleService: Title,
    private locationService: LocationService,
  ) {

  }

  validateResetPasswordOnSubmit() {
    each(this.completeRegistrationForm.controls, (control: UntypedFormControl, name: string) => {
      this.errorsList[name] = control.errors !== null;
    });
  }

  handleFieldBlur(field: string) {
    const fieldState = this.completeRegistrationForm.get(field);
    this.errorsList[field] = !fieldState.valid && fieldState.value.trim() !== '';
  }

  validateOnSubmit(formName: string = 'loginForm') {

    const form = this[formName];

    each(form.controls, (control, name) => {
      this.errorsList[name] = control.errors !== null;
    });
  }

  ngOnInit() {
    this.isLoading = true;
    const title = this.route.snapshot.data['title'];
    const language = this.i18nService.translateService.translations[this.i18nService.language];
    if (title) {
      this.titleService.setTitle(language.route[title.toLowerCase()]);
    }
    this.alerts.removeAll();
    this.createForm();
    this.completeRegistrationForm.setValidators(
      EqualValidator.validate('password', 'passwordRepeat')
    );

    this.zone.runOutsideAngular(() => {
      this.route.params.pipe(
        filter((params: any) => params.externalId)
      ).pipe(takeUntil(this.unsub$)).subscribe((params: any) => {
        this.externalId = params.externalId;
        this.isLoading = false;
        this.detector.detectChanges();
      });
    });
    this.meta.updateTag({
      content: 'width=device-width, initial-scale=1',
      name: 'viewport'
    });
  }

  ngOnDestroy() {
    this.unsub$.next();
this.unsub$.complete();
  }

  createForm() {
    this.completeRegistrationForm = this.formBuilder.group({
      password: ['', Validators.compose([Validators.required, Validators.pattern(PASSWORD_REGEX)])],
      passwordRepeat: ['', Validators.compose([Validators.required, Validators.pattern(PASSWORD_REGEX)])],
    });
  }

  extract(string: string) {
    return extract(string);
  }

  completeRegistration() {

    this.validateResetPasswordOnSubmit();

    if (this.completeRegistrationForm.valid === false) {
      return;
    }

    this.isLoading = true;
    const data = this.completeRegistrationForm.value;
    data.passwordKey = this.externalId;

    this.api.setOperatorPassword(data).subscribe((response: any) => {
      if (response.success) {
        this.isPasswordChanged = true;
        setTimeout(() => {
          this.router.navigate(['/'], { replaceUrl: true });
        }, 2000);
      }
    });

    this.alerts.removeAll();
    this.isLoading = false;
  }

  public goToLanding() {
    this.locationService.goToLanding();
  }
}
