import { EventEmitter, Injectable } from '@angular/core';
import { UploadFile, UploadInput, UploadOutput, UploadStatus } from '@angular-ex/uploader';
import { ApiService } from '@app/core/api/api.service';
import { BehaviorSubject } from 'rxjs';
import { assign, clone } from 'lodash-es';
import each from 'lodash-es/each';
import { UploadFileResponse } from '@app/core/interfaces/api.response';
import { ImageMimeTypes } from '@app/core/config/fileTypes';
import { Attachment, AttachmentsData } from '@app/core/interfaces/attachment.service';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsService {
  private uploadInput: EventEmitter<UploadInput>;

  public state$ = new BehaviorSubject<AttachmentsData>(AttachmentsService.getDefaultData());

  constructor(
    private api: ApiService,
  ) {
  }

  static getDefaultData() {
    return clone({
      eventName: null,
      staticAttachments: [],
      attachments: [],
      files: [],
      dragOver: false,
      error: null,
    });
  }

  setState(newState: {}): this {
    const state = assign({}, this.state$.value, newState);

    this.state$.next(state);
    return this;
  }

  destroy(): this {
    this.uploadInput = null;
    this.state$.next(AttachmentsService.getDefaultData());
    return this;
  }

  reset(): this {
    this.state$.next(AttachmentsService.getDefaultData());
    this.uploadInput.emit({ type: 'removeAll' });
    return this;
  }

  on(input: EventEmitter<UploadInput>): this {
    this.uploadInput = input;
    return this;
  }

  watch(output: UploadOutput): this {
    const {
      files,
      attachments,
    } = this.state$.value;

    switch (output.type) {
      case 'addedToQueue':
        if (typeof output.file !== 'undefined') {
          files.push(output.file);
          this.setState({ eventName: output.type, files });
        }
        break;
      case 'uploading':
      case 'done':
        if (typeof output.file !== 'undefined') {
          // eslint-disable-next-line max-len
          const index = files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
          files[index] = output.file;

          this.setState({ eventName: output.type, files });
          this.handleUploading();
          this.handleDone();
        }
        break;
      case 'removed':
        this.setState({
          eventName: output.type,
          files: files.filter((file: UploadFile) => file.id !== output.file.id),
          attachments: attachments.filter((file: any) => file.id !== output.file.id),
        });
        break;
      case 'removedAll':
        this.state$.next(AttachmentsService.getDefaultData());
        break;
      case 'dragOver':
        this.setState({ eventName: output.type, dragOver: true });
        break;
      case 'dragOut':
      case 'drop':
        this.setState({ eventName: output.type, dragOver: false });
        break;
    }

    return this;
  }

  startUpload(output: UploadOutput, data: {}): this {
    if (output.type === 'allAddedToQueue') {
      const event: UploadInput = {
        type: 'uploadAll',
        url: this.api.routes.uploadUrl(true),
        method: 'POST',
        data,
      };

      this.uploadInput.emit(event);
    }

    return this;
  }

  handleUploading(): this {
    const {
      files,
      attachments,
    } = this.state$.value;
    const uploadingFiles = files.filter((file: UploadFile) => file.progress.status !== UploadStatus.Done);

    if (uploadingFiles.length) {
      each(uploadingFiles, (file: UploadFile) => {
        const messageType = ImageMimeTypes.includes(file.type) ? 'image' : 'attachment';

        this.getImageData(file, messageType, (imageData: any): any => {
          const attachId = attachments.findIndex(item => file.id === item.id);
          const attachData = {
            ...imageData,
            messageType,
            attachment: file.name,
            attachmentId: file.id,
            attachmentPreviewUrl: null,
            size: file.size,
            id: file.id,
            progress: file.progress.data.percentage
          };

          if (attachId === -1) {
            attachments.push(attachData);
          } else {
            attachments[attachId] = attachData;
          }

          this.setState({
            error: null,
            attachments,
          });
        });
      });
    }

    return this;
  }

  handleDone() {
    const files = this.state$.value.files;
    const attachments = [];
    const finishedFiles = files.filter((file: UploadFile) => file.progress.status === UploadStatus.Done);

    if (finishedFiles.length) {
      each(finishedFiles, (file: UploadFile) => {
        const response: UploadFileResponse = file.response;

        if (!response.success) {
          this.setState({
            error: response.errors[0].message,
          });
        } else {
          const {
            attachment_id: attachmentId,
            attachment_preview: attachmentPreviewUrl,
            attachment: attachmentUrl,
          } = response.data;
          const messageType = ImageMimeTypes.includes(file.type) ? 'image' : 'attachment';

          this.getImageData(file, messageType, (imageData: any): any => {
            attachments.push({
              ...imageData,
              messageType,
              attachment: file.name,
              attachmentId,
              attachmentPreviewUrl,
              attachmentUrl,
              size: file.size,
              id: file.id,
              progress: 100,
            });

            this.setState({
              error: null,
              attachments,
            });
          });
        }
      });
    }

    return this;
  }

  getImageData(file: UploadFile, messageType: string, callback: (size: any) => {}): this {
    const reader = new FileReader;

    if (messageType !== 'image') {
      callback({
        attachmentUrl: null,
      });
      return this;
    }

    reader.onload = () => {
      const image = new Image;

      image.onload = () => {
        const width = image.width;
        const height = image.height;

        callback({
          width,
          height,
          attachmentUrl: reader.result,
        });
      };
      // @ts-ignore
      image.src = reader.result;
    };
    reader.readAsDataURL(file.nativeFile);

    return this;
  }

  removeFile(id: string): this {
    const {
      staticAttachments,
    } = this.state$.value;

    this.uploadInput.emit({ type: 'remove', id });
    this.setState({
      staticAttachments: staticAttachments.filter((attachment: Attachment) => attachment.id !== id),
    });

    return this;
  }

  getAttachments() {
    const {
      attachments,
      staticAttachments,
    } = this.state$.value;

    return [...attachments, ...staticAttachments];
  }

  hasAttachments(): boolean {
    const {
      attachments,
      staticAttachments,
    } = this.state$.value;

    return !!(attachments.length || staticAttachments.length);
  }

  addAttachment(attach: Attachment) {
    const { staticAttachments } = this.state$.value;

    staticAttachments.push(attach);

    this.setState({
      staticAttachments,
    });
  }
}
