import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { LanguageGuard } from '@app/core/language.guard';
import { SharedTariffsComponent } from './shared-tariffs.component';

const routes: Routes = [
  {
    path: 'shared-tariffs',
    component: SharedTariffsComponent,
    data: {
      title: extract('Tariffs'),
      bodyId: 'tariffs',
      bodyClass: 'tariffs',
    },
    canActivate: [LanguageGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class SharedTariffsRoutingModule { }
