import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthenticationGuard, Route } from '@app/core';
import { UserInfoFulledGuard } from '@app/core/authentication/user-info-fulled.guard';
import { ProjectGuard } from '@app/core/project.guard';
import { OperatorGuard } from '@app/core/operators.guard';
import { UserGuard } from '@app/core/user.guard';
import { FullLoadGuard } from '@app/core/full-load.guard';

const routes: Routes = [
  Route.withShell([
    {
      path: 'api-conversations',
      loadChildren: () => import('./conversations/conversations.module')
        .then(m => m.ConversationsModule),
      data: {
        noSidebar: true,
        hideSidebar: true,
        hideHeader: true,
        widget: true,
        bodyId: 'widget_conversations',
        bodyClass: 'widget-conversations',
      },
      canActivate: [AuthenticationGuard, FullLoadGuard]
    },
    {
      path: 'conversations',
      loadChildren: () => import('./conversations/conversations.module')
        .then(m => m.ConversationsModule),
      canActivate: [AuthenticationGuard, FullLoadGuard, UserInfoFulledGuard]
    },
    {
      path: 'settings',
      loadChildren: () => import('./settings/settings.module')
        .then(m => m.SettingsModule),
      canActivate: [AuthenticationGuard, UserInfoFulledGuard]
    },
    {
      path: 'profile',
      loadChildren: () => import('./profile/profile.module')
        .then(m => m.ProfileModule),
      canActivate: [ProjectGuard, UserGuard, OperatorGuard, FullLoadGuard, UserInfoFulledGuard],
    },
    {
      path: 'projects',
      loadChildren: () => import('./project/project.module')
        .then(m => m.ProjectModule),
      canActivate: [ProjectGuard, UserGuard, OperatorGuard, UserInfoFulledGuard]
    },
    {
      path: 'version',
      loadChildren: () => import('./version/version.module')
        .then(m => m.VersionModule),
      canActivate: [AuthenticationGuard, UserInfoFulledGuard],
    },
    {
      path: 'fallback',
      loadChildren: () => import('./fallback/fallback.module')
        .then(m => m.FallbackModule)
    },
    {
      path: 'client',
      loadChildren: () => import('./update-email/update-email.module')
        .then(m => m.UpdateEmailModule),
      canActivate: [ProjectGuard, UserGuard, OperatorGuard, UserInfoFulledGuard],
    },
    {
      path: 'masterclass',
      loadChildren: () => import('./masterclass/masterclass.module')
        .then(m => m.MasterclassModule),
    },
    {
      path: 'welcome',
      loadChildren: () => import('./welcome-screen/welcome-screen.module')
        .then(m => m.WelcomeScreenModule),
      canActivate: [AuthenticationGuard, UserInfoFulledGuard],
    },
  ]),
  {
    path: '**',
    redirectTo: 'fallback',
    pathMatch: 'full',
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {
}
