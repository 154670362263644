import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@app/core/api/api.service';
import { Response } from '@app/core/interfaces/api.response';
import { routes } from '@app/core/config/apiRoutes';
import { UtilsService } from '@app/core/utils.service';

export interface CheckSubDomainContext {
  domain: string;
}

export interface ReSendMail {
  email: string;
}

export interface RegisterResponse extends Response {
  data?: {
    id?: string;
    token?: string;
  };
}

export interface RegisterContext {
  email?: string;
  name?: string;
  lastName?: string;
  phone?: string;
  companyName?: string;
  companyScope?: string;
  companySize?: string;
  companyRole?: string;
  password?: string;
  login?: string;
  timezone?: string;
  language?: string;
  status?: string;
  device?: string;
  browser?: string;
  browserVersion?: string;
  os?: string;
  osVersion?: string;
}

export interface RegisterResponse extends Response {
  data?: {
    id?: string;
    token?: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class RegistrationApiService {
  private routes = routes;
  constructor(
    private api: ApiService,
    private utils: UtilsService,
  ) {

  }

  public completeRegistration(token: string, data: any): Observable<any> {
    return this.api.httpPost(this.routes.completeRegistration(token), this.utils.snakeKeys(data));
  }

  public checkDomain(data: CheckSubDomainContext, isCreateNew: boolean = false): Observable<Response> {
    return this.api.httpPost(this.routes.checkDomain, this.utils.snakeKeys(data));
  }

  public initClient(data: any) {
    return this.api.httpPost(this.routes.initClient, this.utils.snakeKeys(data));
  }

  public resend(call: ReSendMail): Observable<any> {
    return this.api.httpPost(this.routes.resend, call);
  }

  public confirmEmail(token: string, code: string): Observable<any> {
    return this.api.httpPost(this.routes.confirmEmail(token), { code });
  }

  public completeInvite(token: string, data: any): Observable<any> {
    return this.api.httpPost(this.routes.invite(token), this.utils.snakeKeys(data));
  }

  public register(data: RegisterContext): Observable<RegisterResponse> {
    return this.api.httpPost(this.routes.register, this.utils.snakeKeys(data));
  }

  public checkInviteToken(token: string): Observable<Response> {
    return this.api.httpGet(this.routes.invite(token));
  }

  public checkRegistrationToken(token: string, isConfirm: boolean = true): Observable<Response> {
    const route = isConfirm ? this.routes.confirmEmail(token) : this.routes.completeRegistration(token);
    return this.api.httpGet(route);
  }

  // TODO: research, not used?
  public registrationCompletion(externalId: string): Observable<Response> {
    return this.api.httpGet(this.routes.completeRegistration(externalId));
  }
}
