import { Injectable } from '@angular/core';
import { PatternsByDirectories } from '@app/core/answer-pattern.service';
import { ApiService } from '@app/core/api/api.service';
import { routes } from '@app/core/config/apiRoutes';
import { UtilsService } from '@app/core/utils.service';
import { Observable } from 'rxjs';
import { Response } from '@app/core/interfaces/api.response';

export type TemplateTypeName = 'teletype_template' | 'edna_waba_operator_template';

export enum TemplateType {
  Teletype = 10,
  Waba = 20,
}

export interface TemplateMessage {
  id?: string;
  key: string;
  message: string;
  directory?: null | string;
  directoryId?: null | string;
  forChannel?: null | string; // используется в waba каналах
  type?: TemplateType;
  typeName?: TemplateTypeName;
}

export interface TemplateDirectory {
  id?: string;
  projectId?: string;
  name: string;
  description?: string;
  templates?: TemplateMessage[];
}

export interface TemplateDirectoryDeleteContext {
  directory?: string;
  deleteTemplates: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AnswerPatternApiService {
  private routes = routes;
  constructor(
    private api: ApiService,
    private utils: UtilsService,
  ) {

  }

  public getTemplatesList(): Observable<Response> {
    return this.api.httpGet(this.routes.templateList);
  }

  public getDirectoriesList(): Observable<Response> {
    return this.api.httpGet(this.routes.directoryList);
  }

  public getTemplatesListByDirectories(): Observable<Response<PatternsByDirectories>> {
      return this.api.httpGet(this.routes.templateListWithDirectories);
  }

  public createTemplate(data: TemplateMessage): Observable<Response> {
    return this.api.httpPost(this.routes.createTemplate, this.utils.snakeKeys(data));
  }

  public updateTemplate(patternId: string, data: TemplateMessage): Observable<Response> {
    return this.api.httpPost(this.routes.editTemplate(patternId), this.utils.snakeKeys(data));
  }

  public deleteTemplate(patternId: string): Observable<Response> {
    return this.api.httpGet(this.routes.deleteTemplate(patternId));
  }

  public createDirectory(data: TemplateDirectory): Observable<Response> {
    return this.api.httpPost(this.routes.createTemplateDirectory, this.utils.snakeKeys(data));
  }

  public updateDirectory(directoryId: string, data: TemplateDirectory): Observable<Response> {
    return this.api.httpPost(this.routes.editTemplateDirectory(directoryId), this.utils.snakeKeys(data));
  }

  public deleteDirectory(directoryId: string, context: TemplateDirectoryDeleteContext): Observable<Response> {
    return this.api.httpPost(this.routes.deleteTemplateDirectory(directoryId), this.utils.snakeKeys(context));
  }

  public updateWabaTemplates(channelId: string): Observable<Response<null>> {
    return this.api.httpGet(this.routes.whatsappEdnaLoadTemplates(channelId))
  }
}
