import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { ChannelService } from '@app/core/channel.service';
import {CookiesService} from '@app/core/cookies.service';
import {PermissionsService} from '@app/core/permissions.service';

@Injectable({
  providedIn: 'root'
})
export class ChannelsGuard implements CanActivate {

  constructor(
    private channelService: ChannelService,
    private cookies: CookiesService,
    private permissionsService: PermissionsService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> | boolean {
    const channels = this.channelService.channels$.getValue();
    if (channels) {
      return true;
    }
    return new Promise<boolean>(resolve => {
      this.channelService.isChannelsLoaded$.subscribe(isLoaded => {
        if (isLoaded) {
          resolve(true);
        }
      });
    });
  }
}
