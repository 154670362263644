import { Injectable } from '@angular/core';
import { CustomFieldsApi } from '@app/core/api/api.custom-fields';
import { ProjectService } from '@app/core/project.service';
import { WebsocketsService, WsEvent } from '@app/core/websockets.service';
import { CreatableCustomField } from '@app/settings/custom-fields/edit-custom-field/edit-custom-field.component';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

export enum CustomFieldType {
  String = 's',
  Bool = 'b',
  Number = 'n',
  Text = 't',
  // выпадающий список
  FixedSet = 'fs',
}

export const CustomFieldTypeNames: Record<CustomFieldType, string> = {
  [CustomFieldType.String]: 'settings.customFields.typeName.string',
  [CustomFieldType.Bool]: 'settings.customFields.typeName.bool',
  [CustomFieldType.Number]: 'settings.customFields.typeName.number',
  [CustomFieldType.Text]: 'settings.customFields.typeName.text',
  // TODOME
  [CustomFieldType.FixedSet]: 'settings.customFields.typeName.fixedSet',
};

export const CustomFieldTypeNameLabels: Record<CustomFieldType, string> = {
  [CustomFieldType.String]: 'settings.customFields.typeNameLabel.string',
  [CustomFieldType.Bool]: 'settings.customFields.typeNameLabel.bool',
  [CustomFieldType.Number]: 'settings.customFields.typeNameLabel.number',
  [CustomFieldType.Text]: 'settings.customFields.typeNameLabel.text',
  // TODOME
  [CustomFieldType.FixedSet]: 'settings.customFields.typeNameLabel.fixedSet',
};

export interface CustomFieldConfig {
  description: string,
  id: string
  name: string,
  order: number
  title: string
  type: CustomFieldType
  // это для типа CustomFieldType.FixedSet
  values?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class CustomFieldsService {

  private customFields = new BehaviorSubject<CustomFieldConfig[] | null>(null);

  constructor(
    private projectService: ProjectService,
    private customFieldsApi: CustomFieldsApi,
    private ws: WebsocketsService
  ) {
    this.projectService.isProjectsLoaded.subscribe((isLoaded) => {
      if (isLoaded) {
        this.loadCustomFieldsList();
      }
    });

    this.ws.on(WsEvent.PERSON_CUSTOM_FIELDS_SETTINGS_CHANGED, (data: any) => {
      if (data.project_id !== this.projectService.currentProject.id) {
        return;
      }

      this.customFields.next(data.personCustomFieldsSettings || []);
    });
  }

  private loadCustomFieldsList() {
    const list = this.projectService.currentProject.personCustomFieldsSettings;
    this.customFields.next(list || []);
  }

  public getCustomFieldsValue() {
    return this.customFields.value;
  }

  public getCustomFieldsAsync() {
    return this.customFields.asObservable();
  }

  public getCustomFieldById(id: string) {
    return this.customFields.value.find((field) => field.id === id);
  }

  createCustomField(data: CreatableCustomField) {
    return this.customFieldsApi.createCustomField(data).pipe(
      tap((res) => {
        if (res.success) {
          this.customFields.next(res.data);
        }
      })
    );
  }

  updateCustomField(id: string, data: CreatableCustomField) {
    return this.customFieldsApi.updateCustomField(id, data).pipe(
      tap((res) => {
        if (res.success) {
          this.customFields.next(res.data);
        }
      })
    );
  }

  deleteCustomField(id: string) {
    return this.customFieldsApi.removeCustomField(id).pipe(
      tap((res) => {
        if (res.success) {
          this.customFields.next(res.data);
        }
      })
    );
  }

  updateOrder(list: CustomFieldConfig[]) {
    return this.customFieldsApi.updateOrder(list).pipe(
      tap((res) => {
        if (res.success) {
          this.customFields.next(res.data);
        }
      }),
    );
  }

}
