<div class="pay-widget" *ngIf="!hasAccess">
  <div class="pay-info">{{ title }}</div>

  <div class="btn-wrapper" *ngIf="isAdmin">
    <button class="btn btn-connect with-spinner"
            [class.disabled]="inProgress"
            [disabled]="inProgress"
            (click)="add()">
      <app-spinner [inProgress]="inProgress" [btnText]="caption" [costText]="costText"></app-spinner>
    </button>
  </div>

  <div class="balance-wrapper" *ngIf="isAdmin">
    <span class="pay-balance">{{ 'forcePay.yourBalance' | translate }}</span>
    <span class="balance-sum">{{ balance }}</span>
  </div>

  <div *ngIf="!isAdmin" class="connect-owner">
    {{ 'optionBlockByMoney' | translate }}
  </div>
</div>
