import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
  TemplateRef,
  HostListener,
} from '@angular/core';
import { AuthenticationService, extract, I18nService } from '@app/core';
import { Location } from '@angular/common';
import { ApiService } from '@app/core/api/api.service';
import { CategoriesService } from '@app/core/categories.service';
import { FeatureFlagsService } from '@app/core/feature-flags.service';
import { isNavigationEnd } from '@app/core/helpers/isNavigationEnd';
import { PermissionsService } from '@app/core/permissions.service';
import { PushNotificationsService } from '@app/core/push-notifications.service';
import { StorageService } from '@app/core/storage/storage.service';
import { TagsService } from '@app/core/tags.service';
import { TariffService } from '@app/core/tariff.service';
import { ToastType } from '@app/core/toasts/toast';
import { ToastService } from '@app/core/toasts/toast.service';
import { environment } from '@env/environment.dev';
import {
  take, filter as filter$, takeUntil, switchMap, finalize,
} from 'rxjs/operators';
import { OperatorData } from '@app/core/interfaces/api.response';
import { ChannelService } from '@app/core/channel.service';
import { UserService } from '@app/core/user.service';
import { WebsocketsService, WsEvent } from '@app/core/websockets.service';
import { OperatorService } from '@app/core/operator.service';
import { ConversationService } from '@app/core/conversation.service';
import { each, filter, set } from 'lodash-es';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomModalService } from '@app/core/custom-modal.service';
import {
  combineLatest,
  Observable, Subject, throwError,
} from 'rxjs';
import { FaviconService } from '@app/core/favicon.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeHtml, SafeStyle } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CacheService } from '@app/core/cache.service';
import { ChatService } from '@app/core/chat.service';
import { WhatsappApiService } from '@app/core/api/api.whatsapp';
import { UtilsService } from '@app/core/utils.service';
import { ConversationApiService } from '@app/core/api/api.conversation';
import { ProjectService } from '@app/core/project.service';
import { AlertsService } from '@app/core/alerts/alerts.service';
import { NewsService } from '@app/core/news.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MetrikaService } from '@app/core/metrika.service';
import { AmplitudeService } from '@app/core/amplitude.service';
import { PersonApiService } from '@app/core/api/api.person';

const TEAMMATES = 'Teammates';
const CATEGORIES = 'Categories';
const TAGS = 'Tags';
const DESIGN_INDENT = 60;
const CONVERSATION_SECTION = {
  name: 'CONVERSATIONS',
  position: 0,
  items: [
    {
      id: 'all',
      title: 'All',
      url: '/conversations/all/all',
      secondUrl: '/conversations/all/closed',
      icon: 'predefined',
      type: 'all-channels',
      count: 0,
    },
    {
      id: 'unanswered',
      type: 'unanswered',
      title: 'Unread',
      url: '/conversations/unanswered/unanswered',
      icon: 'predefined',
      count: 0,
    },
    {
      id: 'conversations',
      type: 'read-message',
      title: 'Open',
      url: '/conversations/open/open',
      icon: 'predefined',
      count: 0,
    },
    {
      id: 'assigned',
      type: 'assigned',
      title: 'Assigned to me',
      url: '/conversations/all/open/appointed',
      icon: 'predefined',
      count: 0,
    },
  ],
};
const CODE_REGEX = /^([0-9]){5}$/;

const SIDEBAR_WIDTH_LOCAL_STORAGE_KEY = 'sidebar-width';
const BASE_SIDEBAR_WIDTH = 270;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  preserveWhitespaces: false,
})

export class SidebarComponent implements OnInit, OnDestroy {
  isWindowFocused = true;
  countNewMessage = 0;
  isSelectedAppealId = false;
  // @ts-ignore
  public footerHeight: number;
  public allowGetProButton: boolean;
  public toggled: boolean;
  public hasNews$: Observable<boolean>;
  public showSupportLoading = false;
  public supportChatLoading = true;
  public supportChatLoaded = false;

  private unsub$ = new Subject<void>();
  public sidebarSections: Array<any> = [];

  public innerHtml: SafeHtml;

  public channels: any;
  public operators: any;
  public categories: any;
  public tags: any;
  /** текущий пользователь */
  public userId: string;
  public isUserAdmin: boolean;
  public isUserOperator: boolean;
  public hasNewMessages: boolean;

  public modalRef: BsModalRef;
  public qr: any;
  public inProgress: boolean;
  public whatsAppChannelId: string;
  public telegramChannelId: string;
  public error: string;
  public isWhatsAppTeletype: boolean;
  public isQR: boolean;
  public isWaiting: boolean;
  public isTelegram: boolean;
  public isInstagram: boolean;
  public isSettingsPage: boolean;
  public autoAssignLoading: boolean;

  public errorsList = {
    code: false,
    password: false,
  };

  public telegramChannel;
  public whatsAppChannel;

  public isResizableSidebar = this.featureFlagsService.isResizableSidebar;
  isActiveResizeSidebar = false;
  sidebarWidth = this.getSidebarWidthFromLocalStorage();

  @ViewChild('sidebarFooter', {
    read: ElementRef,
    static: true,
  }) elementView: ElementRef;
  @ViewChild('resizeArea', { read: ElementRef }) resizeArea: ElementRef;
  @ViewChild('elamaSupport', { read: TemplateRef }) elamaSupportModal: TemplateRef<any>;

  @HostListener('window:focus', ['$event'])
  onFocus(): void {
    if (this.isWindowFocused) {
      return;
    }
    this.isWindowFocused = true;
    this.isSelectedAppealId = false;
    this.countNewMessage = 0;
  }

  @HostListener('window:blur', ['$event'])
  onBlur(): void {
    this.isWindowFocused = false;
  }

  @HostListener('document:mousedown', ['$event'])
  mouseDown(event: MouseEvent) {
    if (this.resizeArea && this.resizeArea?.nativeElement === event.target) {
      event.preventDefault();
      this.isActiveResizeSidebar = true;
    }
  }

  @HostListener('document:mousemove', ['$event'])
  mouseMove(event: MouseEvent) {
    if (this.isActiveResizeSidebar) {
      this.sidebarWidth = Math.max(event.clientX, BASE_SIDEBAR_WIDTH);
      this.elementRef.nativeElement.style.width = this.sidebarWidth + 'px';
      localStorage.setItem(SIDEBAR_WIDTH_LOCAL_STORAGE_KEY, String(this.sidebarWidth));
    }
  }

  @HostListener('document:mouseup', ['$event'])
  mouseUp(event: MouseEvent) {
    this.isActiveResizeSidebar = false;
  }

  @ViewChild('templateModalSidebar', { static: true }) public template: TemplateRef<any>;

  get hasPermissionToSettings(): boolean {
    return this.permissionsService.hasPermissionToSettings();
  }

  get isNotificationsAvailable(): boolean {
    return this.pushNotificationService.isNotificationsAvailable;
  }

  get connectedPhoneHidden(): string {
    const phone = this.whatsAppChannel.phoneNumber;
    const formattedPhone = `${phone.substr(0, 2)} (***) ***-**-${phone.substr(10, 2)}`;
    return phone ? formattedPhone : '';
  }

  constructor(
    private zone: NgZone,
    private detector: ChangeDetectorRef,
    private api: ApiService,
    private apiWhatsapp: WhatsappApiService,
    private apiPersonService: PersonApiService,
    private channelService: ChannelService,
    private ws: WebsocketsService,
    private userService: UserService,
    private operatorService: OperatorService,
    private categoriesService: CategoriesService,
    private tagsService: TagsService,
    private featureFlagsService: FeatureFlagsService,
    private conversationService: ConversationService,
    private modalService: BsModalService,
    private dialog: CustomModalService,
    private i18nService: I18nService,
    private faviconService: FaviconService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private cacheService: CacheService,
    private utils: UtilsService,
    private conversationApi: ConversationApiService,
    private projectService: ProjectService,
    public chatService: ChatService,
    private alerts: AlertsService,
    private newsService: NewsService,
    private deviceDetector: DeviceDetectorService,
    private metrika: MetrikaService,
    private amplitude: AmplitudeService,
    private location: Location,
    private permissionsService: PermissionsService,
    private elementRef: ElementRef,
    private pushNotificationService: PushNotificationsService,
    private toastService: ToastService,
    private tariffService: TariffService,
  ) {
    this.isSettingsPage = this.router.url.startsWith('/settings');
  }

  ngOnInit() {
    this.router.events.pipe(takeUntil(this.unsub$))
      .subscribe((event: any) => {
        if (isNavigationEnd(event)) {
          this.isSettingsPage = this.router.url.startsWith('/settings');
          this.detector.detectChanges();
        }
      });

    const language = this.i18nService.translateService.translations[this.i18nService.language];
    if (language) {
      each(CONVERSATION_SECTION.items, (item) => {
        item.title = language.sidebar.menuItems[item.id];
      });
    }

    this.ws.on(WsEvent.COUNTERS_UPDATED, (_data) => {
      const data = this.utils.camelKeys(_data);
      if (data.projectId !== this.projectService.currentProject.id) {

      }
      // TODO: Update counters for another projects;
      // if (this.isWindowFocused) {
      //   this.getAppealsInfo();
      // }
    });

    this.ws.onCounterUnreadAppeals()
      .pipe(takeUntil(this.unsub$))
      .subscribe((_data: any) => {
        const data = this.utils.camelKeys(_data);
        const currentProject = this.projectService.getCurrentProject();
        if (this.sidebarSections.length === 0 || data.projectId !== currentProject.id) {
          return;
        }
        this.channelService.setUnansweredCount(data.unansweredCount);
      });

    this.ws.on(WsEvent.HAS_NEW_APPEALS, (_data: any) => {
      const data = this.utils.camelKeys(_data);
      this.markBadgeOfChannel(data.channelId, data.hasNewAppeals);
    });

    this.ws.onMessageFromClient()
      .pipe(takeUntil(this.unsub$))
      .subscribe((_data) => {
        if (!_data) {
          return;
        }
        this.showMessageFromClient(_data);
      });

    this.ws.onFirstMessageFromClient()
      .pipe(takeUntil(this.unsub$))
      .subscribe((_data) => {
        if (!_data) {
          return;
        }
        const data = this.utils.camelKeys(_data);
        this.showMessageFromClient(data);
        if (data) {
          this.markBadgeOfChannel(data.channelId);
        }
      });

    this.sidebarSections.push(CONVERSATION_SECTION);
    this.getUserId();
    this.getChannelsList();
    this.getOperatorsList();
    this.getCategoryList();
    this.getTagsList();
    this.getAppealsInfo();
    this.onCountersChange();
    this.allowGetProButton = true;

    this.chatService.hideLoaderButton.pipe(takeUntil(this.unsub$))
      .subscribe(() => {
        this.showSupportLoading = false;
        if (!this.detector['destroyed']) {
          this.detector.detectChanges();
        }
      });

    this.chatService.chatLoaded.pipe(filter$((isLoaded) => isLoaded), take(1))
      .subscribe(() => {
        this.supportChatLoading = false;
        if (!this.detector['destroyed']) {
          this.detector.detectChanges();
        }
      });

    this.ws.onErrorNotify()
      .pipe(takeUntil(this.unsub$))
      .subscribe((event) => {
        if (event && event.channel && event.channel.type === 'whatsapp_teletype') {
          if (this.isWhatsAppTeletype) {
            this.error = event.text;
            this.showQRCode(event.channel.id);
          } else if (!this.isSettingsPage) {
            this.alerts.addAlert({
              type: 'error',
              content: event.text,
            });
          }
        }
      });

    this.hasNews$ = this.newsService.hasNews$;
    this.dialog.hasNeedAction$.pipe(takeUntil(this.unsub$))
      .subscribe((id) => {
        if (id) {
          this.openModal(this.template, id, 'whatsapp_teletype');
        }
      });
  }

  ngOnDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
    this.ws.removeListener(WsEvent.UNREAD_MESSAGE);
    this.ws.removeListener(WsEvent.APPEAL_STATUS_CHANGE);
    this.ws.removeListener(WsEvent.HAS_NEW_APPEALS);
    this.ws.removeListener(WsEvent.COUNTERS_UPDATED);
  }

  handleFieldBlur(form: string, field: string) {
    const fieldState = this[form].get(field);
    this.errorsList[field] = !fieldState.valid && fieldState.value.trim() !== '';
  }

  openModal(template: TemplateRef<any>, id: string, type: string, isQR: boolean = true, isWaiting: boolean = false) {
    this.error = '';
    this.isQR = isQR;
    this.isWaiting = isWaiting;
    this.modalRef = this.modalService.show(template, {
      backdrop: 'static',
      ignoreBackdropClick: true,
      class: type === 'telegram' ? 'modal-channels' : '',
    });
    if (type === 'whatsapp_teletype') {
      this.isWhatsAppTeletype = true;
      this.whatsAppChannelId = id;
      this.whatsAppChannel = this.channels.find(channel => channel.id === this.whatsAppChannelId);
      if (!isWaiting) {
        this.showQRCode(id);
      }
    } else if (type === 'telegram') {
      this.isTelegram = true;
      this.telegramChannelId = id;
      this.telegramChannel = this.channels.find(channel => channel.id === this.telegramChannelId);
    } else if (type === 'instagram') {
      this.isInstagram = true;
    }
  }

  openElamaSupportModal() {
    this.modalRef = this.modalService.show(this.elamaSupportModal, {
      class: 'elama-support',
    });
  }

  validateOnSubmit(formName: string) {
    const form = this[formName];
    each(form.controls, (control, name) => {
      this.errorsList[name] = control.errors !== null;
    });
  }

  handlerDetect(): boolean {
    this.detector.detectChanges();
    return true;
  }

  closeModal(): void {
    this.isWhatsAppTeletype = false;
    this.isTelegram = false;
    this.isWaiting = false;
    this.isInstagram = false;
    this.isQR = false;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.modalRef && this.modalRef.hide();
    this.dialog.close();
    if (!this.detector['destroyed']) {
      this.detector.detectChanges();
    }
  }

  done() {
    if (!this.whatsAppChannelId) {
      return;
    }
    if (this.isWhatsAppTeletype) {
      this.closeModal();
      this.inProgress = false;
    }
  }

  startTimer() {
    setTimeout(() => {
      this.qr = null;
      if (!this.detector['destroyed']) {
        this.detector.detectChanges();
      }
    }, 20000);
  }

  showQRCode(id: string) {
    if (this.inProgress) {
      return;
    }
    this.qr = null;
    this.inProgress = true;
    this.whatsAppChannelId = id;
    this.amplitude.track('SCAN_REPEAT_WHATSAPP');
    this.apiWhatsapp.getQrForWhatsappAuth({ channelId: id })
      .pipe(takeUntil(this.unsub$))
      .subscribe((response) => {
        response.data.status;
        if (response.success) {
          const { img } = response.data;
          if (img) {
            this.qr = img;
            this.startTimer();
          } else {
            this.error = 'QR code not received, please try again';
          }
        } else {
          this.error = response.errors[0].message;
        }
      }, () => { }, () => {
        this.inProgress = false;
      });
  }

  sortMenuItems() {
    this.sidebarSections.sort((a: any, b: any) => a.position - b.position);
  }

  getUserId() {
    this.userService.isUserLoaded$.subscribe(isLoaded => {
      if (isLoaded) {
        const user = this.userService.user$.getValue();
        if (user) {
          this.userId = user.id;
          this.isUserAdmin = this.permissionsService.isAdmin();
          this.isUserOperator = this.permissionsService.isOperator();
          this.detector.detectChanges();
        }
      }
    });
  }

  onCountersChange() {
    this.channelService.getAppealsInfo()
      .pipe(takeUntil(this.unsub$))
      .subscribe((counters: any) => {
        if (counters) {
          this.sidebarSections[0].items[1].count = counters.unanswered;
          this.sidebarSections[0].items[2].count = counters.open;
          this.sidebarSections[0].items[3].count = counters.assigned;
          if (this.isWindowFocused) {
            this.faviconService.show(counters.unanswered);
          } else if (this.isSelectedAppealId) {
            this.faviconService.show(counters.unanswered);
            this.isSelectedAppealId = false;
          } else {
            this.faviconService.show(counters.unanswered + this.countNewMessage);
          }
          this.detector.detectChanges();
        }
      });
  }

  getAppealsInfo() {
    this.channelService.updateAppealsCounter();
  }

  getNumberWithCommas(num: number) {
    return num.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  sectionTrack(index: number, section: any) {
    return section.id;
  }

  sectionItemTrack(index: number, item: any) {
    return item.id;
  }

  getName(name: string) {
    return `sidebar.${name.toLowerCase()}`;
  }

  getOperatorsList(): void {
    if (this.userId) {
      const matchMain = this.sidebarSections.find((section) => section.name === 'CONVERSATIONS');

      if (matchMain) {
        const assigned = matchMain.items.find((item) => item.id === 'assigned');
        if (assigned) {
          assigned.secondUrl = `/conversations/${this.userId}/`;
        }
      }
      this.operatorService.getOperators()
        .pipe(takeUntil(this.unsub$))
        .subscribe((operators: OperatorData) => {
          const items: Array<any> = [];
          this.operators = operators || [];
          this.operators = this.operators
            .filter((operator: any) => operator.id !== this.userId)
            .forEach((operator: any) => {
              items.push({
                id: operator.id,
                title: extract(`${operator.name} ${operator.lastName}`),
                icon: 'user',
                isOnline: operator.online,
                img: operator.avatar || operator.avatarDefault,
                secondUrl: `/conversations/${operator.id}/`,
                linkType: 'user',
              });
            });

          const matchTeammates = this.sidebarSections.find((section) => section.name === TEAMMATES);

          if (matchTeammates) {
            matchTeammates.items = items;
          } else {
            this.sidebarSections.push({
              name: extract(TEAMMATES),
              position: 4,
              items,
            });
          }
          this.sortMenuItems();
          this.detector.detectChanges();
        });
    } else {
      setTimeout(() => {
        this.getOperatorsList();
      }, 300);
    }
  }

  getCategoryList(): void {
    if (this.userId) {
      const matchMain = this.sidebarSections.find((section) => section.name === 'CONVERSATIONS');

      if (matchMain) {
        const assigned = matchMain.items.find((item) => item.id === 'assigned');
        if (assigned) {
          assigned.secondUrl = `/conversations/${this.userId}/`;
        }
      }
      this.featureFlagsService.isAvailableCategories
        .pipe(
          takeUntil(this.unsub$),
          filter$((isAvailableCategories) => isAvailableCategories),
          switchMap(() => this.categoriesService.getCategories()),
        )
        .subscribe((categories) => {
          const categoryBlockName = extract(CATEGORIES);
          const items: Array<any> = [];
          this.categories = categories || [];
          if (categories.length) {
            this.categories = this.categories.forEach((category: any) => {
              items.push({
                id: category.id,
                title: extract(category.category),
                icon: 'category',
                secondUrl: `/conversations/${category.id}/`,
                linkType: 'category',
              });
            });

            const matchCategories = this.sidebarSections.find((section) => section.name === categoryBlockName);

            if (matchCategories) {
              matchCategories.items = items;
            } else {
              this.sidebarSections.push({
                name: categoryBlockName,
                position: 3,
                items,
              });
            }
          } else {
            this.sidebarSections = this.sidebarSections.filter((section) => section.name !== categoryBlockName);
          }

          this.sortMenuItems();
          this.detector.detectChanges();
        });
    } else {
      setTimeout(() => {
        this.getCategoryList();
      }, 300);
    }
  }

  getTagsList(): void {
    if (this.userId) {
      const matchMain = this.sidebarSections.find((section) => section.name === 'CONVERSATIONS');

      if (matchMain) {
        const assigned = matchMain.items.find((item) => item.id === 'assigned');
        if (assigned) {
          assigned.secondUrl = `/conversations/${this.userId}/`;
        }
      }

      combineLatest([
        this.tagsService.isTagsAvailable,
        this.tagsService.tagList$,
        this.tariffService.isOptionPaid$('tags'),
      ])
        .pipe(takeUntil(this.unsub$))
        .subscribe(([isAvailable, tags, isTagPaid]) => {
          const tagsBlockName = extract(TAGS);
          const items: Array<any> = [];
          this.tags = tags || [];
          if (tags.length && isAvailable && isTagPaid) {
            this.tags = this.tags.forEach((tag: any) => {
              items.push({
                id: tag.id,
                title: extract(tag.tag),
                color: tag.color,
                icon: 'tag',
                secondUrl: `/conversations/${tag.id}/`,
                linkType: 'tag',
              });
            });

            const matchTags = this.sidebarSections.find((section) => section.name === tagsBlockName);

            if (matchTags) {
              matchTags.items = items;
            } else {
              this.sidebarSections.push({
                name: tagsBlockName,
                position: 3,
                items,
              });
            }
          } else {
            this.sidebarSections = this.sidebarSections.filter((section) => section.name !== tagsBlockName);
          }

          this.sortMenuItems();
          this.detector.detectChanges();
        });
    } else {
      setTimeout(() => {
        this.getTagsList();
      }, 300);
    }
  }

  updateSection(channels: any) {
    if (!channels || !channels.length) {
      return;
    }
    let allChannelsAppealCount = 0;
    const items = [];
    channels.forEach((channel: any) => {
      allChannelsAppealCount += channel.appealsCount;
      items.push({
        id: channel.id,
        title: channel.name || channel.defaultName,
        url: `/conversations/${channel.id}/open`,
        secondUrl: `/conversations/${channel.id}/all`,
        thirdUrl: `/conversations/${channel.id}/unanswered`,
        icon: 'predefined',
        type: channel.type
          .replace('instagram_direct', 'instagram')
          .replace('instagram_comments', 'instagram'),
        originType: channel.type,
        count: channel.appealsCount || 0,
        hasError: channel.state === 'error',
        isWaiting: channel.state === 'waiting',
        isNeedAction: channel.state === 'need action',
      });
    });

    return items;
  }

  getChannelsList(): void {
    this.channelService
      .getChannels()
      .pipe(takeUntil(this.unsub$))
      .subscribe((channels: any) => {
        channels = channels || [];
        const createdChannels = channels.filter((channel) => channel.isCreated === undefined || channel.isCreated);
        this.channels = filter(createdChannels, (channel) => channel.state.toLowerCase() !== 'pending');
        this.channels = filter(this.channels, (channel) => channel.enabledChat === undefined || channel.enabledChat);
        this.channels = filter(this.channels, (channel) => {
            const isNotInstagram = (!channel.hasOwnProperty('directVisible') && !channel.hasOwnProperty('commentsVisible'));
            const directIsNotVisible = (channel.hasOwnProperty('directVisible') && !channel.direct_visible);
            const commentsIsNotVisible = (channel.hasOwnProperty('commentsVisible') && !channel.comments_visible);

            return isNotInstagram || directIsNotVisible || commentsIsNotVisible;
          },
        );
        const matchChannels = this.sidebarSections.find((section) => section.name === 'Channels');

        const whatsAppsIsActive = this.channels.filter((channel) => channel.type === 'whatsapp_teletype'
          && channel.state === 'active');

        if (whatsAppsIsActive && whatsAppsIsActive.length > 0 && this.isWhatsAppTeletype) {
          each(whatsAppsIsActive, (whatsApp) => {
            if (this.whatsAppChannelId === whatsApp.id) {
              this.closeModal();
            }
          });
        }

        if (matchChannels) {
          matchChannels.items = this.updateSection(this.channels);
        } else {
          const items = this.updateSection(this.channels);
          this.sidebarSections.push({
            name: extract('Channels'),
            position: 2,
            items,
          });
        }
        this.footerHeight = this.elementView.nativeElement.offsetHeight + DESIGN_INDENT;
        this.sortMenuItems();
        this.detector.detectChanges();
      });
  }

  markBadgeOfChannel(channelId: string, hasNewAppeals: boolean = true): void {
    const matchChannels = this.sidebarSections.find((section) => section.name === 'Channels');

    if (!matchChannels) {
      return;
    }

    const freshChannel = matchChannels.items.find((channel) => channel.id === channelId);

    if (freshChannel) {
      freshChannel.hasNewAppeals = hasNewAppeals;
      this.detector.detectChanges();
    }
  }

  sanitizeUrl(url: string): SafeStyle {
    return this.zone.runOutsideAngular(() => this.sanitizer.bypassSecurityTrustStyle(`url(${url})`));
  }

  goTo(id: string, linkType: string) {
    if (linkType === 'user') {
      this.cacheService.set('search', { selectedOperator: escape(id) });
    }

    if (linkType === 'category') {
      this.cacheService.set('search', { selectedCategory: escape(id) });
    }

    if (linkType === 'tag') {
      this.cacheService.set('search', { selectedTags: [escape(id)] });
    }
  }

  resetSearch() {
    this.cacheService.delete('hasActiveSearch');
  }

  showMessageFromClient(data: any) {
    if (data && data.appealId === this.conversationService.appealId) {
      this.isSelectedAppealId = true;
      this.countNewMessage = 1;
    }
  }

  markAsRead() {
    this.newsService.markNewsAsRead('mark');
  }

  startChat(): void {
    if (this.showSupportLoading) {
      return;
    }

    if (this.supportChatLoading) {
      this.showSupportLoading = true;
    }
    this.chatService.startChat();
    this.chatService.sendMessage('');
    this.closeModal();
    this.detector.detectChanges();
  }

  openSupport() {
    if (this.userService.user.hasExternalAuth) {
      this.openElamaSupportModal();
    } else {
      this.startChat();
    }
  }

  toggleNotifcations() {
    this.pushNotificationService.goToNotificationsPage();
  }

  trackChannels(): void {
    this.metrika.goal('SMALL_BUTTON');
    this.amplitude.track('SMALL_BUTTON');
    this.router.navigate(['/settings/add']);
  }

  trackOperators(): void {
    this.amplitude.track('SIDEBAR_ADD_OPERATOR');
  }

  trackSettings(): void {
    this.amplitude.track('SETTINGS_CLICK');
  }

  appealAutoAssignHandler() {
    this.autoAssignLoading = true;
    this.detector.detectChanges();
    this.apiPersonService.appealSetRandom()
      .pipe(
        takeUntil(this.unsub$),
        switchMap((data) => {
          if (data.success) {
            return this.conversationApi.getAppealById(data.data?.appealId);
          }
          return throwError(data.errors[0]);
        }),
        finalize(() => {
          this.autoAssignLoading = false;
          this.detector.detectChanges();
        }),
      )
      .subscribe((data) => {
        this.router.navigate(['/conversations/all/open/appointed'])
          .then(() => {
            this.location.go(`/conversations/${data.data.appeal.channelId}/open/appointed/?appealId=${data.data.id}`);
            this.conversationService.switchAppeal(data.data.id);
            this.conversationService.openAppeal(data.data.id);
          });
      }, (err) => {
        this.alerts.addAlert({
          type: 'error',
          content: err.message,
        });
      });
  }

  getSidebarWidthFromLocalStorage() {
    const width = Number(localStorage.getItem(SIDEBAR_WIDTH_LOCAL_STORAGE_KEY)) || BASE_SIDEBAR_WIDTH;
    this.elementRef.nativeElement.style.width = width + 'px';
    return width;
  }

  getCode() {
    this.apiWhatsapp.getAuthLink(this.whatsAppChannelId).subscribe((res) => {
      if (res.success) {
        const domain = window.location.origin;
        const page = '/assets/auth-whatsapp/auth-whatsapp.html';

        const params =  new URLSearchParams();
        params.set('projectId', this.projectService.getCurrentProject().id);
        params.set('token', res.data.token);
        params.set('lang', this.i18nService.translateService.currentLang);
        params.set('phone', this.connectedPhoneHidden);

        const link = domain + page + '?' + params.toString();

        navigator.clipboard.writeText(link);
        this.toastService.addSimpleToast({
          title: 'toasts.done',
          text: 'whatsapp.linkCopied',
          type: ToastType.Success,
          time: 2000,
        });
      }
    })
  }
}
