import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ToastService } from '@app/core/toasts/toast.service';

@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss'],
})
export class ToastsComponent implements OnDestroy {

  public toasts = this.toastService.toasts;

  private sub = this.toastService.onToastUpdated
    .subscribe(() => {
      this.cdr.detectChanges();
    });

  constructor(
    private toastService: ToastService,
    private cdr: ChangeDetectorRef,
  ) {}

  public trackByKey(index: number, item: any) {
    return item.key;
  }

  public ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
