import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-mobile-warn',
  templateUrl: './mobile-warn.component.html',
  styleUrls: ['./mobile-warn.component.scss']
})
export class MobileWarnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public goHome() {
    window.location.href = `${environment.assetsUrl}conversations/open/open`;
  }

}
