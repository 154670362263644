import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormGroup, NgForm, UntypedFormBuilder, Validators, UntypedFormControl, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, extract, I18nService } from '@app/core';
import { AlertsService } from '@app/core/alerts/alerts.service';
import { takeUntil, switchMap, map, filter, take } from 'rxjs/operators';
import { PASSWORD_REGEX, DOMAIN_REGEX, PROMO_CODE } from '@app/core/config/regExValidators';
import each from 'lodash-es/each';
import EqualValidator from '@app/core/validators/equal.validator';
import { Subject, timer } from 'rxjs';
import { slugify } from '@app/core/config/slug';
import { TimezoneService } from '@app/core/timezone.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CookiesService } from '@app/core/cookies.service';
import { RegistrationApiService } from '@app/core/api/api.registration';
import { Title } from '@angular/platform-browser';
import { LocationService } from '@app/core/location.service';
import { ANALYTICS_EVENT, MetrikaService } from '@app/core/metrika.service';
import { FaceBookPixelService } from '@app/core/fbpixel.service';
import { GoogleTagService } from '@app/core/gtag.service';
import { StorageService } from '@app/core/storage/storage.service';

function generatePassword(length = 32) {
  const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
  const digits = '0123456789';
  const specialChars = '-,!№@*/';

  const allChars = upperCaseLetters + lowerCaseLetters + digits + specialChars;

  let password = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * allChars.length);
    password += allChars[randomIndex];
  }

  return password;
}


@Component({
  selector: 'app-account-setup-profile',
  templateUrl: './account-setup-profile.component.html',
  styleUrls: ['./account-setup-profile.component.scss']
})
export class AccountSetupProfileComponent implements OnInit, OnDestroy {

  isLoading = false;
  isExteranalAuth: boolean = false;
  errorsList: any = {};
  error: string;
  inputError: string;
  isAdmin = false;
  promoVisible = false;
  addressChanged = false;

  completeRegistrationForm: UntypedFormGroup;

  private token: string;
  private unsub$ = new Subject<void>();
  readonly isMobile: boolean;

  @ViewChild('registerFormForm', {static: true}) registerFormForm: NgForm;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private api: RegistrationApiService,
    private auth: AuthenticationService,
    private alerts: AlertsService,
    private timezone: TimezoneService,
    private i18nService: I18nService,
    private deviceService: DeviceDetectorService,
    private cookies: CookiesService,
    private titleService: Title,
    private locationService: LocationService,
    private metrika: MetrikaService,
    private fbPixel: FaceBookPixelService,
    private gtag: GoogleTagService,
    private storage: StorageService,
  ) {
    this.isMobile = this.deviceService.userAgent.toLowerCase().includes('mobi');
    const title = this.route.snapshot.data['title'];
    if (title) {
      this.i18nService.translateService.getTranslation(this.i18nService.language)
        .pipe(take(1))
        .subscribe((language: any) => {
          if (language) {
            this.titleService.setTitle(language.route[title.toLowerCase()]);
          }
        });
    }
  }

  ngOnInit(): void {
    this.isAdmin = this.route.snapshot.data.isAdmin;

    this.createForm();
    this.completeRegistrationForm.setValidators(
      EqualValidator.validate('password', 'passwordRepeat')
    );

    this.route.params.pipe(
      filter((params: any) => params.token)
    ).pipe(takeUntil(this.unsub$)).subscribe((params: any) => {
      if (this.route.snapshot.queryParams.oauth === 'elama-auth') {
        this.isExteranalAuth = true;
        this.promoVisible = true;
        const generatedPassword = generatePassword();
        this.completeRegistrationForm.patchValue({
          password: generatedPassword,
          passwordRepeat: generatedPassword,
          promo: 'ELAMA_PROMO',
        });
      }

      this.token = params.token;
    });

    const promo: string = this.route.snapshot.queryParamMap.get('p') ||
      this.route.snapshot.queryParamMap.get('promo') || this.cookies.get('promo');

    if (promo) {
      this.promoVisible = true;
      this.completeRegistrationForm.controls.promo.setValue(promo);
    }
  }

  extract(string: string): string {
    return extract(string);
  }

  handleFieldBlur(field: string): void {
    const fieldState = this.completeRegistrationForm.get(field);
    this.errorsList[field] = !fieldState.valid && fieldState.value.trim() !== '';
  }

  validateOnSubmit(): void {
    each(this.completeRegistrationForm.controls, (control, name) => {
      this.errorsList[name] = control.errors !== null;
    });
  }

  completeRegistration(): void {
    this.validateResetPasswordOnSubmit();

    if (this.completeRegistrationForm.valid === false) {
      return;
    }

    this.alerts.removeAll();

    this.isLoading = true;

    const data = this.completeRegistrationForm.value;
    if (!this.promoVisible) {
      data.promo = '';
    }
    // data.token = this.token;

    data.timezone = this.timezone.getFittingZone();
    data.language = this.i18nService.language;
    this.api.completeRegistration(this.token, data)
    // eslint-disable-next-line import/no-deprecated
    .pipe(takeUntil(this.unsub$)).subscribe((res: any) => {

      this.completeRegistrationForm.markAsPristine();
      this.storage.setCurrentProjectId(res?.data?.projectId);
      this.auth.login(res.data.accessToken);
      this.sendAnalyticsEvent(ANALYTICS_EVENT.FIRST_PROJECT_CREATE, {
        projectName: this.completeRegistrationForm.controls.projectName.value,
        projectDomain: this.completeRegistrationForm.controls.projectDomain.value
      });
      this.isLoading = false;
      this.router.navigate(['/registration/complete'], {
        replaceUrl: true,
        queryParams: { oauth: this.isExteranalAuth ? 'elama-auth' : undefined },
      });


      }, null,
    () => {
      this.isLoading = false;
    });
  }

  ngOnDestroy(): void {
    this.unsub$.next();
this.unsub$.complete();
  }

  handleChange(value: string): void {
    const _value = value.replace(/ /g, '');
    if (_value && _value !== this.completeRegistrationForm.get('projectDomain').value) {
      this.completeRegistrationForm.controls.projectDomain.setValue(_value);
    }
  }

  togglePromoCodeContainer(): void {
    this.promoVisible = !this.promoVisible;
    // const validator = this.promoVisible ? [Validators.pattern(PROMO_CODE)] : [];
    // this.completeRegistrationForm.controls.promo.setValidators(validator);
    // if (!this.promoVisible) {
    //   delete this.errorsList.promo;
    //   this.completeRegistrationForm.controls.promo.setErrors(null);
    // } else if (this.completeRegistrationForm.controls.promo.value !== '') {
    //   this.completeRegistrationForm.controls.promo.setErrors({'pattern': true});
    //   setTimeout(() => {
    //     this.validateResetPasswordOnSubmit();
    //   });
    // }
  }

  setProjectUrl(): void {
    // const _value = this.completeRegistrationForm.controls.name.value.replace(/\s+/g, ' ');
    // this.completeRegistrationForm.controls.name.setValue(_value);
    const slug = slugify(this.completeRegistrationForm.controls.projectName.value.substring(0, 20));

    if (this.completeRegistrationForm.controls.projectDomain.value.trim() === '' || !this.addressChanged) {
      this.completeRegistrationForm.controls.projectDomain.setValue(slug);
    }
  }

  private createForm(): void {
    this.completeRegistrationForm = this.formBuilder.group({
      password: ['', Validators.compose([Validators.required, Validators.pattern(PASSWORD_REGEX)])],
      passwordRepeat: ['', Validators.compose([Validators.required, Validators.pattern(PASSWORD_REGEX)])],
      projectName: ['', Validators.compose([Validators.required, Validators.maxLength( 50)])],
      projectDomain: ['', Validators.compose(([
        Validators.required,
        Validators.pattern(DOMAIN_REGEX)
      ])), this.validateDomainNameRemotely.bind(this, 'projectDomain')],
      projectOperator: [false, [Validators.required]],
      promo: ['']
    });
  }

  private validateResetPasswordOnSubmit(): void {
    each(this.completeRegistrationForm.controls, (control: UntypedFormControl, name: string) => {
      this.errorsList[name] = control.errors !== null;
    });
  }

  private validateDomainNameRemotely(name: string, control: AbstractControl) {
    return timer(300).pipe(
      switchMap(() => {
        return this.api.checkDomain({ domain: control.value }).pipe(
          map(res => {
            let match = {};
            this.inputError = res.errors[0] && res.errors[0].message;
            if (this.inputError) {
              match = { backend: this.inputError };
              this.handleFieldBlur(name);
            }
            return match;
          })
        );
      })
    );
  }

  public goToLanding() {
    this.locationService.goToLanding();
  }

  sendAnalyticsEvent(event: ANALYTICS_EVENT | string, args: object = null) {
    this.metrika.goal(event, args);
    this.gtag.track(event, args);
    this.fbPixel.track(event);
  }
}
