import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { isNavigationEnd } from '@app/core/helpers/isNavigationEnd';
import { TagsService } from '@app/core/tags.service';
import { UserService } from '@app/core/user.service';
import { AuthenticationService, extract, I18nService } from '@app/core';
import { ApiService } from '@app/core/api/api.service';
import { takeUntil } from 'rxjs/operators';
import { AlertsService } from '@app/core/alerts/alerts.service';
import { OperatorData, Response } from '@app/core/interfaces/api.response';
import { ProjectService } from '@app/core/project.service';
import { Router } from '@angular/router';
import { PingService } from '@app/core/ping.service';
// import { NotificationService } from '@app/core/notification.service';
import { cloneDeep, each, filter } from 'lodash-es';
import { CacheService } from '@app/core/cache.service';
import { ChannelService } from '@app/core/channel.service';
import { UnescapePipe } from '@app/shared/unescape/unescape.pipe';
import { environment } from '@env/environment';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { OperatorService } from '@app/core/operator.service';
import { ProjectData } from '@app/core/api/api.project';
import { TeammateApiService } from '@app/core/api/api.teammate';
import {PromoDays, PromoEndDate, TariffService} from '@app/core/tariff.service';
import {DateTime} from 'luxon';
import { PermissionsService } from '@app/core/permissions.service';

// const MAX_TRY = 120;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  preserveWhitespaces: false
})
export class HeaderComponent implements OnInit, OnDestroy {

  private unsub$ = new Subject<void>();
  private tryCount = 0;
  public language: any;
  public userState: boolean;
  public user: any;
  public inProgress: boolean;
  public switchInProgress: boolean;
  public hasProject: boolean;
  public hasNotification: boolean;
  public hasNotificationError: boolean;
  public notificationLoaded: boolean;

  public hasPermission: boolean;
  public isOwner: boolean;
  public ping: number;
  public currentProject: ProjectData;
  public promoDays: PromoDays;
  public promoEndDate: PromoEndDate = {date: '', timezone: ''};
  public promoIsActive: boolean;
  public channels: any;
  public operators: any;
  public tags: any;
  public isSettingsPage: boolean;
  private teammates: any;
  public captionText: string;
  private widgetEventTracked = false;
  private setPersonInfoTimeout: any;
  public balance: number;
  public dayText: string;
  public availableDays: string | number;

  constructor(
    private detector: ChangeDetectorRef,
    public userService: UserService,
    private auth: AuthenticationService,
    private api: ApiService,
    private teammateApi: TeammateApiService,
    private alerts: AlertsService,
    private projectService: ProjectService,
    private router: Router,
    private pingService: PingService,
    // private notificationService: NotificationService,
    private i18nService: I18nService,
    private cacheService: CacheService,
    private channelService: ChannelService,
    private operatorService: OperatorService,
    private tariffService: TariffService,
    private permissionsService: PermissionsService,
    private tagsService: TagsService,
  ) {
    this.isSettingsPage = this.router.url.startsWith('/settings');
  }

  setPersonDataForSupportWidget(): void {

    // If widget is not yet initialized, wait until it's fully ready
    if (window['_teletypeWidget'] === undefined || window['_teletypeWidget'].setPersonData === undefined) {

      clearTimeout(this.setPersonInfoTimeout);

      this.setPersonInfoTimeout = setTimeout(() => {
        this.setPersonDataForSupportWidget();
      }, 500);
      return;
    }

    window['_teletypeWidget'].setPersonData({
      email: this.user.email,
      name: this.user.fullName,
      avatar: this.user.avatar,
      phone: this.user.phone,
      payload: {
        companyName: this.user.companyName,
        id: this.user.id,
        roles: this.user.roles.join(', ')
      }
    });
  }

  ngOnInit() {
    this.router.events.pipe(takeUntil(this.unsub$)).subscribe((event: any) => {
      if (isNavigationEnd(event)) {

        this.isSettingsPage = this.router.url.startsWith('/settings');

        this.detector.detectChanges();
      }
    });

    this.alerts.getOnlineState().pipe(takeUntil(this.unsub$))
      .subscribe((state: string) => {
        if (state === 'offline') {
          this.alerts.addAlert({
            type: 'error',
            content: this.i18nService.translateService.instant('error.connectionLost')
          });
        } else {
          this.alerts.removeAll();
        }
      });


    // переделал получения языка на асинхронное, потому что эта компонента успевает инициализироваться до
    // того, как файл перевода сохраниться в translateService
    this.i18nService.translateService.getTranslation(this.i18nService.language)
      .pipe(takeUntil(this.unsub$))
      .subscribe(language => {
        this.language = language;

        if (this.language) {
          this.captionText = this.language.sidebar.menuItems.all;
          this.getChannelsList();
          this.getOperatorsList();
          this.getTagsList();
        }
      });

    if (environment.features.ping) {
      this.pingService.getPingData()
        .pipe(takeUntil(this.unsub$))
        .subscribe((ping: any) => {

          if (!ping) {
            return;
          }

          this.ping = ping;
          this.detector.markForCheck();
        });
    }
    this.userService.getUserData()
      .pipe(takeUntil(this.unsub$))
      .subscribe((user: any) => {

        if (!user) {
          return;
        }

        this.user = user;
        this.user.name = this.user.name || '';
        this.user.lastName = this.user.lastName || '';
        this.user.fullName = `${this.user.name} ${this.user.lastName}`.trim();

        this.userState = this.user.online;
        this.hasPermission = this.permissionsService.isAdmin();
        this.isOwner = this.permissionsService.isOwner();
        this.setOperatorsList();

        if (!this.widgetEventTracked) {
          this.widgetEventTracked = true;
          document.addEventListener('teletype.ready', () => {
            this.setPersonDataForSupportWidget();
          });
        } else {
          this.setPersonDataForSupportWidget();
        }

        this.detector.markForCheck();
      });

    this.projectService.getProjects()
      .pipe(takeUntil(this.unsub$)).subscribe((projects: ProjectData[]) => {
        if (projects) {
          this.hasProject = projects.length > 0;
          if (this.hasProject) {
            // this.getToken();
            this.currentProject = this.projectService.getCurrentProject();
          }
          this.detector.detectChanges();
        }
      });
    this.tariffService.getBalance().pipe(takeUntil(this.unsub$))
      .subscribe( (balance: number) => {
        if (balance) {
          this.balance = balance;
          if (this.availableDays) {
            this.dayText = this.tariffService.getDayText(Number(this.availableDays));
          }
        }
      });
    this.tariffService.getAvailableDays().pipe(takeUntil(this.unsub$))
      .subscribe((availableDays: number) => {
        if (!availableDays) {
          return;
        }

        this.availableDays = availableDays;
        this.dayText = this.tariffService.getDayText(Number(this.availableDays));
      });
    this.tariffService.getPromoDays().pipe(takeUntil(this.unsub$))
      .subscribe((promoDays: PromoDays) => {
        if (promoDays) {
          this.promoDays = promoDays;
        }
      });
    this.tariffService.getPromoEndDate().pipe(takeUntil(this.unsub$))
      .subscribe((promoEndDate: PromoEndDate) => {
        if (promoEndDate) {
          this.promoEndDate = promoEndDate;
          const dDay = new Date(this.promoEndDate.date);
          const timezone = this.promoEndDate.timezone.toUpperCase() || 'UTC';
          this.promoIsActive = dDay.getTime() >= new Date(DateTime.local().setZone(timezone).toSQL()).getTime();
        }
      });
  }

  ngOnDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  public getDayText(days: number): string {
    return this.tariffService.getDayText(days);
  }

  getChannelsList(): void {
    this.channelService
      .getChannels()
      .pipe(takeUntil(this.unsub$))
      .subscribe((channels: any) => {
        if (channels) {
          const _channels = [{ name: this.captionText, id: 'all' }];
          const createdChannels = channels.filter(channel => {
            return channel.isCreated === undefined || channel.isCreated;
          });
          const userChannels = filter(createdChannels, channel => channel.state.toLowerCase() !== 'pending');
          each(userChannels, channel => channel.name =
            new UnescapePipe().transform(channel.name || channel.defaultName));
          this.channels = _channels.concat(userChannels);
          this.detector.detectChanges();
        }
      });
  }

  // getToken() {
  //   const token = localStorage.getItem('firebaseToken');
  //   if (token) {
  //     this.getNotificationInfo(token);
  //   } else {
  //     this.notificationService.getToken().then((_token: string) => {
  //       this.getNotificationInfo(_token);
  //     }, (error) => {
  //       if (error && error === 'loading' && this.tryCount < MAX_TRY) {
  //         setTimeout(() => {
  //           this.tryCount += 1;
  //           console.log('retry');
  //           this.getToken();
  //         }, 1000);
  //       } else {
  //         this.hasNotificationError = true;
  //       }
  //     });
  //   }
  // }

  // notificationSwitch() {
  //   if (this.switchInProgress) {
  //     return;
  //   }
  //   let success = false;
  //   this.hasNotification = !this.hasNotification;
  //   this.switchInProgress = true;
  //   this.detector.detectChanges();
  //
  //   this.currentProject.pushNotificationActive = this.hasNotification;
  //   this.api.switchPushNotification({ notification: this.hasNotification })
  //     .pipe(takeUntil(this.unsub$))
  //     .subscribe((response: Response) => {
  //       success = response.success;
  //     }, null, () => {
  //       if (!success) {
  //         this.hasNotification = !this.hasNotification;
  //       }
  //       this.switchInProgress = false;
  //     });
  // }

  // getNotificationInfo(token: string) {
  //   this.api.pushNotificationInfo({ firebaseToken: token }).pipe(takeUntil(this.unsub$))
  //     .subscribe((response: Response) => {
  //       if (response.success) {
  //         const data = response.data;
  //         this.hasNotification = data.pushNotificationActive || null;
  //         if (!data.pushNotificationExist) {
  //           this.notificationService.resendToken();
  //         }
  //         this.notificationLoaded = true;
  //         this.detector.detectChanges();
  //       }
  //     });
  // }

  setOperatorsList() {
    if (this.teammates && this.user && this.language) {
      let items: any;
      items = cloneDeep(this.teammates);
      const id = items.findIndex(item => item.id === this.user.id);
      if (id > -1) {
        items[id].name = this.language.header.me;
      }
      const _operator = [{ name: this.captionText, id: 'all' }];
      this.operators = _operator.concat(items);
    }
  }

  getOperatorsList(): void {
    this.operatorService.getOperators()
      .pipe(takeUntil(this.unsub$)).subscribe((teammates: OperatorData) => {
        if (!teammates) {
          return;
        }
        this.teammates = teammates;
        this.setOperatorsList();
    });
  }

  getTagsList(): void {
    this.tagsService.tagList$
      .pipe(takeUntil(this.unsub$))
      .subscribe((tags) => {
        this.tags = [...tags];
    });
  }

  onStateChange() {
    if (this.inProgress) {
      return;
    }
    this.inProgress = true;
    this.userState = !this.userState;
    this.user.online = this.userState;
    this.teammateApi.updateProfile({ online: this.userState })
      .pipe(takeUntil(this.unsub$))
      .subscribe((data: Response) => {
        this.userService.setUserParam('online', this.userState);
        this.detector.detectChanges();
      }, () => {}, () => {
        this.inProgress = false;
      });
  }

  logOut(): void {
    this.api.logout().subscribe();
    this.auth.logout();
    this.auth.redirectToLogin();
  }

  extract(value: string) {
    return extract(value);
  }

  goToPayment() {
    this.router.navigateByUrl('/settings/payment', { replaceUrl: true });
  }


  public showPromoModal(): void {
    this.tariffService.showPromotionPopUp();
  }
}
