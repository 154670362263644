import {
  Directive,
  AfterContentInit,
  OnDestroy,
  ElementRef,
  Input,
  forwardRef,
  Renderer2,
  HostListener,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const KEYS = {
  'backspace': 8,
  'shift': 16,
  'ctrl': 17,
  'alt': 18,
  'delete': 46,
  'leftArrow': 37,
  'upArrow': 38,
  'rightArrow': 39,
  'downArrow': 40,
};

@Directive({
  selector: '[appMaxLength]',
  providers:
    [
      { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MaxLengthDirective), multi: true }
    ]
})
export class MaxLengthDirective implements ControlValueAccessor, AfterContentInit, OnDestroy {
  @Input() appMaxLength: number;
  @Input() propValueAccessor = 'innerHTML';
  private onChange: (value: string) => void;
  private onTouched: () => void;
  private currentSelection: any;
  private charactersCount: any;

  private utils = {
    special: {},
    navigational: {},
    isSpecial(e: any) {
      return typeof this.special[e.keyCode] !== 'undefined';
    },
    isNavigational(e: any) {
      return typeof this.navigational[e.keyCode] !== 'undefined';
    }
  };

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.appMaxLength = this.appMaxLength || 0;
    this.utils.special[KEYS['backspace']] = true;
    this.utils.special[KEYS['shift']] = true;
    this.utils.special[KEYS['ctrl']] = true;
    this.utils.special[KEYS['alt']] = true;
    this.utils.special[KEYS['delete']] = true;

    this.utils.navigational[KEYS['upArrow']] = true;
    this.utils.navigational[KEYS['downArrow']] = true;
    this.utils.navigational[KEYS['leftArrow']] = true;
    this.utils.navigational[KEYS['rightArrow']] = true;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    const normalizedValue = value == null ? '' : value;
    this.renderer.setProperty(this.elementRef.nativeElement, this.propValueAccessor, normalizedValue);
  }

  @HostListener('input')
  callOnChange() {
    if (typeof this.onChange === 'function') {
      this.onChange(this.elementRef.nativeElement[this.propValueAccessor]);
    }
  }

  @HostListener('paste', ['$event'])
  callPaste(event: any) {
    event.preventDefault();
    event.stopImmediatePropagation();

    let text = event.clipboardData.getData('text/plain');

    text = this.htmlEntities(text);
    text = text.replace(/([^>])\n/g, '$1<br/>');
    this.elementRef.nativeElement[this.propValueAccessor] = this.onPaste(text);
  }

  htmlEntities(text: string): string {
    return String(text).replace(/</g, '&lt;').replace(/>/g, '&gt;');
  }

  normalizeMessage(msg: string = ''): string {
    return msg.replace(/(&nbsp;)/g, ' ');
  }

  private getCharactersLen(_msg: string = ''): number {
    const msg = this.normalizeMessage(_msg || this.elementRef.nativeElement.innerHTML);
    const elm = document.createElement('div');
    elm.innerHTML = msg;
    const imagesLen = elm.getElementsByTagName('img').length;
    return (elm.textContent || elm.innerText || '').length + imagesLen;
  }

  private onPaste(text: any) {
    text = text.replace(/(\r?\n)?(<br\/?>)(\r?\n)?/, '');
    const currentLen = this.getCharactersLen();
    const nElement = this.elementRef.nativeElement;
    const selectionLength = window.getSelection().getRangeAt(0).toString().length || 0;
    const deleteCount = this.appMaxLength - currentLen + selectionLength;

    if (this.currentSelection) {
      this.restoreSelection(this.currentSelection);
    }
    text = text.slice(0, deleteCount);
    this.replaceSelection(text);

    return nElement.innerHTML;
  }

  restoreSelection(savedSelection: any) {
    if (window.getSelection) {
      const sel = window.getSelection();
      sel.removeAllRanges();
      for (let i = 0, len = savedSelection.length; i < len; ++i) {
        sel.addRange(savedSelection[i]);
      }
    } else if ((<any>document).selection && (<any>document).selection.createRange) {
      if (savedSelection) {
        savedSelection.select();
      }
    }
  }

  replaceSelection(content: any) {
    if (window.getSelection) {
      let range;
      const sel = window.getSelection();
      const node = typeof content === 'string' ? document.createTextNode(content) : content;

      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0);
        range.deleteContents();
        range.insertNode(node);
        range.setStart(node, 0);

        window.setTimeout(function() {
          range = document.createRange();
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
        }, 0);
      }
    } else if ((<any>document).selection && (<any>document).selection.createRange) {
      const range = (<any>document).selection.createRange();
      if (typeof content === 'string') {
        range.text = content;
      } else {
        range.pasteHTML(content.outerHTML);
      }
    }
  }

  saveSelection() {
    if (window.getSelection) {
      const sel = window.getSelection();
      const node = (<any>sel.anchorNode);

      if (node !== null && node.parentNode && !node.parentNode.closest('[contenteditable]')) {
        return this.currentSelection;
      }
      const ranges = [];
      if (sel.rangeCount) {
        for (let i = 0, len = sel.rangeCount; i < len; ++i) {
          ranges.push(sel.getRangeAt(i));
        }
      }
      return ranges;
    } else if ((<any>document).selection && (<any>document).selection.createRange) {
      const sel = (<any>document).selection;
      return (sel.type.toLowerCase() !== 'none') ? sel.createRange() : null;
    }
  }

  private handler(event: any) {
    let hasSelection = false;
    this.currentSelection = this.saveSelection();
    this.charactersCount = this.getCharactersLen();
    const len = this.charactersCount;
    const selection = this.currentSelection;
    const isSpecial = this.utils.isSpecial(event);
    const isNavigational = this.utils.isNavigational(event);

    if (selection) {
      hasSelection = !!selection.toString();
    }

    if (isSpecial || isNavigational) {
      return true;
    }

    if (len >= this.appMaxLength && !hasSelection) {
      event.preventDefault();
      return false;
    }
  }

  public ngAfterContentInit(): void {
    this.propValueAccessor = this.propValueAccessor;
    this.elementRef.nativeElement.addEventListener('keydown', this.handler.bind(this));
    this.elementRef.nativeElement.addEventListener('paste', this.handler.bind(this));
  }

  public ngOnDestroy(): void {
    this.elementRef.nativeElement.removeEventListener('keydown', this.handler.bind(this));
    this.elementRef.nativeElement.removeEventListener('paste', this.handler.bind(this));
  }
}
