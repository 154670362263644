import { Injectable } from '@angular/core';
import { UserService } from '@app/core/user.service';
import { UserData } from '@app/core/api/api.profile';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export enum Role {
  Owner = 'owner', // создатель проекта, может делать все (кроме операторских функций, если он сам не изъявил подобного желания)
  Admin = 'admin',  // может делать все, назначается создателем
  Helper = 'helper',
  Operator = 'operator',
}

export enum Permission {
  PermissionSet = 'permissionSet',
  AppealUpdate = 'appealUpdate',
  StatisticView = 'statisticView',
  BackgroundTasksManage = 'backgroundTasksManage',
  TemplatesManage = 'templatesManage',
  PersonResponsibleOperatorUpdate = 'personResponsibleOperatorUpdate'
}

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  private unsub$ = new Subject<void>();

  private get user(): UserData {
    const user = this.userService.user$.getValue();
    if (user) {
      return user;
    } else {
      this.userService.isUserLoaded$.pipe().subscribe(isLoaded => {
        if (isLoaded) {
          return this.userService.user$.getValue();
        }
      });
    }
  }

  private get roles(): Role[] {
    if (this.user) {
      return this.user.roles;
    } else {
      this.userService.isUserLoaded$.pipe().subscribe(isLoaded => {
        if (isLoaded) {
          return this.userService.user$.getValue()?.roles || [];
        }
      });
    }
  }

  private get permissions(): Permission[] | Permission {
    if (this.user) {
      return this.user?.permissions;
    } else {
      this.getPermissions().then(permissions => {
        return permissions;
      });
    }
  }

  constructor(
    private userService: UserService,
  ) { }

  private getPermissions(): Promise<Permission[]> {
    return new Promise<Permission[]>(resolve => {
      this.userService.isUserLoaded$.pipe(takeUntil(this.unsub$)).subscribe((isLoaded) => {
        if (isLoaded) {
          this.unsub$.next();
          this.unsub$.complete();
          const value = this.userService.user$.getValue();
          resolve(value?.permissions);
        }
      });
    });
  }

  /** только создатель проекта */
  public isOwner(): boolean {
    return this.roles?.includes(Role.Owner) && !this.roles?.includes(Role.Admin);
  }

  /** суперюзеры, им доступно все вообще */
  public isAdmin(): boolean {
    return this.roles?.includes(Role.Admin) || this.roles?.includes(Role.Owner);
  }

  /** наделен ли юзер способностями оператора */
  public isOperator(): boolean {
    return this.roles?.includes(Role.Operator);
  }

  /** юзер является только оператором */
  public isPureOperator() {
    return this.roles.length === 1 && this.roles?.includes(Role.Operator);
  }

  /** наделен ли юзер способностями помошника */
  public isHelper(): boolean {
    return this.roles?.includes(Role.Helper);
  }

  /** имеет ли юзер доступ к разделу */
  public hasAccessTo(permission: Permission): boolean {
    if (this.permissions) {
      return this.permissions.includes(permission);
    } else {
      return Boolean(this.getPermissions().then(res => {
        return res && res.includes(permission);
      }));
    }
  }

  public hasPermissionToSettings(): boolean {
    const permissionSettings = [Permission.PermissionSet, Permission.TemplatesManage, Permission.StatisticView];
    if (this.permissions) {
      return !!permissionSettings.find(permission => this.permissions && this.permissions.includes(permission));
    } else {
      return Boolean(this.getPermissions().then(res => {
        return !!permissionSettings.find(permission => res && res.includes(permission));
      }));
    }
  }

  public getPermissionForRoute(url: string): Permission | null {
    if (url.includes('roles')) {
      return Permission.PermissionSet;
    }

    if (url.includes('answer-pattern')) {
      return Permission.TemplatesManage;
    }

    if (url.includes('statistics')) {
      return Permission.StatisticView;
    }

    // это нужно для того, что бы помшник мой заходить на глвную страницу настроек
    if (url.includes('start/main')) {
      return Permission.TemplatesManage;
    }

    return null;
  }

}
