import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { PermissionsService } from '@app/core/permissions.service';
import { UserService } from '@app/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  private state: RouterStateSnapshot;

  constructor(
    private router: Router,
    private userService: UserService,
    private permissionsService: PermissionsService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> | boolean {
    this.state = state;
    const user = this.userService.user$.getValue();
    if (user) {
      return this.checkPermission();
    }
    return new Promise<boolean>(resolve => {
      this.userService.isUserLoaded$.subscribe(isLoaded => {
        if (isLoaded) {
          resolve(this.checkPermission());
        }
      });
    });
  }

  checkPermission(): boolean {
    let hasPermission;

    const necessarySpecialPermission = this.permissionsService.getPermissionForRoute(this.state.url);
    if (necessarySpecialPermission) {
      hasPermission = this.permissionsService.hasAccessTo(necessarySpecialPermission);
    } else {
      hasPermission = this.permissionsService.isAdmin();
    }

    if (!hasPermission) {
      this.router.navigate(['fallback/403']);
    }
    return hasPermission;
  }
}
