import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

@Directive({
  selector: '[appDadata]'
})
export class DadataDirective implements OnInit {

  public inputEventHandlerCallback: Function;
  public keyUpEventHandlerCallback: Function;

  private _inputEventHandlerSubj: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _keyUpEventHandlerSubj: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public get inputEventHandlerSubj(): Observable<string> {
    return this._inputEventHandlerSubj.asObservable();
  }

  public get keyUpEventHandlerSubj(): Observable<boolean> {
    return this._keyUpEventHandlerSubj.asObservable();
  }

  @Input() form: UntypedFormGroup;
  @Input() formControlName: string;

  constructor(
    private elementRef: ElementRef,
  ) { }

  @HostListener('input')
  inputEventHandler() {
    this._inputEventHandlerSubj.next(this.elementRef.nativeElement.value);
  }

  @HostListener('focus')
  focusEventHandler() {
    this._inputEventHandlerSubj.next(this.elementRef.nativeElement.value);
  }

  @HostListener('keyup', ['$event'])
  keyUpEventHandler(event: KeyboardEvent) {

  }

  ngOnInit() {

  }

}
