import { Injectable } from '@angular/core';
import { Observable , BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConversationService {

  public appealId: string = null;

  public unreadMessageCounter = new BehaviorSubject<number>(0);
  private _currentAppealIdSubject = new BehaviorSubject<string>(this.appealId);
  private _openAppealIdSubject = new BehaviorSubject<string>(this.appealId);

  public get currentConversationId(): Observable<string> {
    return this._currentAppealIdSubject.asObservable();
  }

  
  public get openConversationId(): Observable<string> {
    return this._openAppealIdSubject.asObservable();
  }

  constructor() { }

  public switchAppeal(appealId: string): void {
    this._currentAppealIdSubject.next(appealId);
  }

  public openAppeal(appealId: string): void {
    this._openAppealIdSubject.next(appealId);
  }

  getUnreadMessage() {
    return this.unreadMessageCounter.asObservable();
  }
}
