import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/api/api.service';
import { routes } from '@app/core/config/apiRoutes';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '@app/core/utils.service';

@Injectable({
  providedIn: 'root',
})
export class ApiBitrixService {
  private routes = routes;
  constructor(
    private api: ApiService,
    private http: HttpClient,
    private utils: UtilsService,
  ) {
  }

  public uploadConversationToBitrix(appealId: string) {
    return this.api.httpGet(this.routes.pushBitrixCRM(appealId));
  }

  public deleteBitrixCRM(crmId: string): any {
    return this.api.httpGet(this.routes.deleteBitrixCRM(crmId));
  }

  public verifyBitrixCRM(crmId: string): any {
    return this.api.httpGet(this.routes.verifyBitrixCRM(crmId));
  }

  public updateConnectors(crmId: string): any {
    return this.api.httpGet(this.routes.updateBitrixCRMConnectors(crmId));
  }

  public updateBitrixCRM(data: any): any {
    return this.api.httpPost(this.routes.updateBitrixCRM(data.channelId), this.utils.snakeKeys(data));
  }
}
