import { Injectable } from '@angular/core';

interface CacheContent {
  value: any;
}

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private cache: Map<string, CacheContent> = new Map<string, CacheContent>();

  get(key: string): any {
    return this.cache.get(key) && this.cache.get(key).value || '';
  }

  set(key: string, value: any): void {
    this.cache.set(key, { value: value });
  }

  has(key: string): boolean {
    return this.cache.has(key);
  }

  delete(key: string): boolean {
    return this.cache.delete(key);
  }
}
