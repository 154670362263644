import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class PingService {
  private pingStream = new BehaviorSubject<number>(null);
  private ping = 0;
  private list = {};
  private avgList = [];

  public add(tempId: string): void {
    this.list[tempId] = performance.now();
  }

  public receive(tempId: string): void {
    if (tempId && this.list[tempId]) {
      this.ping = performance.now() - this.list[tempId];
      this.addToAvgList(this.ping);
      this.pingStream.next(this.ping);
      delete this.list[tempId];
    }
  }

  public getPing(): number {
    return this.ping;
  }

  public getAveragePing(): number {
    let sum = 0;
    const length = this.avgList.length || 1;
    this.avgList.forEach(ping => sum += ping);
    return sum / length;
  }

  public getPingData(): Observable<any> {
    return this.pingStream.asObservable();
  }

  private addToAvgList(ping: number): void {
    if (this.avgList.length >= 30) {
      this.avgList.shift();
    }
    this.avgList.push(ping);
  }
}
