<div class="shadow"></div>
<div class="news-body" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header pablo">
        <button type="button" class="close pull-right close-news" aria-label="Close"
                (click)="closeModal()">
          <i class="teletype-icon-close"></i>
        </button>
      </div>
      <div class="modal-body zero-up">
        <div class="warn-title">{{ 'newsModalTitle' | translate }}</div>
        <div class="html-content" [innerHTML]="news?.popup?.text"></div>
      </div>
    </div>
  </div>
</div>

