import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormGroup, NgForm, UntypedFormBuilder, Validators, UntypedFormControl, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { I18nService, AuthenticationService, extract } from '@app/core';
import { AlertsService } from '@app/core/alerts/alerts.service';
import { Title } from '@angular/platform-browser';
import { takeUntil, take, switchMap, map, filter } from 'rxjs/operators';
import { ONLY_LETTERS_REGEX, PASSWORD_REGEX } from '@app/core/config/regExValidators';
import each from 'lodash-es/each';
import EqualValidator from '@app/core/validators/equal.validator';
import { slugify } from '@app/core/config/slug';
import { TimezoneService } from '@app/core/timezone.service';
import { Response } from '@app/core/interfaces/api.response';
import { RegistrationApiService } from '@app/core/api/api.registration';
import { LocationService } from '@app/core/location.service';
import { MetrikaService, ANALYTICS_EVENT } from '@app/core/metrika.service';
import { FaceBookPixelService } from '@app/core/fbpixel.service';
import { GoogleTagService } from '@app/core/gtag.service';
import { Subject } from 'rxjs';
import { StorageService } from '@app/core/storage/storage.service';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit, OnDestroy {

  isLoading = false;
  errorsList: any = {};
  error: string;
  inputError: string;
  promoVisible = false;
  addressChanged = false;
  invitedEmail: string;

  completeRegistrationForm: UntypedFormGroup;

  private token: string;
  private unsub$ = new Subject<void>();

  @ViewChild('registerFormForm', {static: true}) registerFormForm: NgForm;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private i18nService: I18nService,
    private api: RegistrationApiService,
    private auth: AuthenticationService,
    private alerts: AlertsService,
    private titleService: Title,
    private timezone: TimezoneService,
    private locationService: LocationService,
    private metrika: MetrikaService,
    private fbPixel: FaceBookPixelService,
    private gtag: GoogleTagService,
    private storage: StorageService,
  ) {

  }

  ngOnInit(): void {
    this.invitedEmail = this.route.snapshot.data['customerData'].email;

    const title = this.route.snapshot.data['title'];
    if (title) {
      this.i18nService.translateService.getTranslation(this.i18nService.language)
        .pipe(take(1))
        .subscribe((language: any) => {
          if (language) {
            this.titleService.setTitle(language.route[title.toLowerCase()]);
          }
        });
    }
    this.createForm();
    this.completeRegistrationForm.setValidators(
      EqualValidator.validate('password', 'passwordRepeat')
    );

    this.route.params.pipe(
      filter((params: any) => params.token)
    ).pipe(takeUntil(this.unsub$)).subscribe((params: any) => {
      this.token = params.token;
    });
  }

  extract(string: string): string {
    return extract(string);
  }

  handleFieldBlur(field: string): void {
    const fieldState = this.completeRegistrationForm.get(field);
    this.errorsList[field] = !fieldState.valid && fieldState.value.trim() !== '';
    if (field === 'name' && fieldState.value.trim() === '') {
      this.errorsList[field] = fieldState.value.trim() === '';
      this.completeRegistrationForm.controls[field].setErrors({
        required: true
      });
    }
  }

  validateOnSubmit(): void {
    each(this.completeRegistrationForm.controls, (control, name) => {
      this.errorsList[name] = control.errors !== null;
    });
  }

  completeRegistration(): void {
    this.validateResetPasswordOnSubmit();

    if (this.completeRegistrationForm.valid === false) {
      return;
    }

    this.alerts.removeAll();

    this.isLoading = true;

    const data = this.completeRegistrationForm.value;
    data.token = this.token;

    data.timezone = this.timezone.getFittingZone();
    data.language = this.i18nService.language;

    this.api.completeInvite(this.token, data).pipe(takeUntil(this.unsub$))
      .subscribe((response: Response) => {
        const projectId = response?.data?.projectId;
        if (projectId) {
          this.storage.setCurrentProjectId(response?.data?.projectId);
        }
        const accessToken = response.success && response.data && response.data.accessToken;
        if (accessToken) {
          this.completeRegistrationForm.markAsPristine();
          this.auth.login(accessToken);
          document.location.href = `${document.location.origin}/conversations/open/open`;
        }
      }, () => {},
        () => {
        this.isLoading = false;
      });
  }

  ngOnDestroy(): void {
    this.unsub$.next();
this.unsub$.complete();
  }

  handleChange(value: string): void {
    const _value = value.replace(/ /g, '');
    if (_value && _value !== this.completeRegistrationForm.get('projectDomain').value) {
      this.completeRegistrationForm.controls.projectDomain.setValue(_value);
    }
  }

  togglePromoCodeContainer(): void {
    this.promoVisible = !this.promoVisible;
  }

  setProjectUrl(): void {
    const slug = slugify(this.completeRegistrationForm.controls.projectName.value);

    if (this.completeRegistrationForm.controls.projectDomain.value.trim() === '' || !this.addressChanged) {
      this.completeRegistrationForm.controls.projectDomain.setValue(slug);
    }
  }

  private createForm(): void {
    this.completeRegistrationForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required, Validators.pattern(ONLY_LETTERS_REGEX)])],
      password: ['', Validators.compose([Validators.required, Validators.pattern(PASSWORD_REGEX)])],
      passwordRepeat: ['', Validators.compose([Validators.required, Validators.pattern(PASSWORD_REGEX)])],
    });
  }

  private validateResetPasswordOnSubmit(): void {
    each(this.completeRegistrationForm.controls, (control: UntypedFormControl, name: string) => {
      this.errorsList[name] = control.errors !== null;
    });
  }

  showErrorToView() {
    return this.errorsList && this.errorsList.name ?
      this.completeRegistrationForm.get('name').hasError('required') ||
      this.completeRegistrationForm.get('name').hasError('pattern') :
      this.errorsList.name;
  }

  public goToLanding() {
    this.locationService.goToLanding();
  }

  sendAnalyticsEvent(event: ANALYTICS_EVENT | string, args: object = null) {
    this.metrika.goal(event, args);
    this.gtag.track(event, args);
    this.fbPixel.track(event);
  }
}
