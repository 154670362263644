import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  ElementRef,
  EventEmitter,
  TemplateRef,
  OnDestroy,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, NgForm, UntypedFormControl } from '@angular/forms';

import { I18nService, extract } from '@app/core';
import each from 'lodash-es/each';
import { TimezoneService } from '@app/core/timezone.service';
import { UserService } from '@app/core/user.service';
import { UploadInput, UploadOutput } from '@angular-ex/uploader';
import { StorageService } from '@app/core/storage/storage.service';
import { AvatarMimeTypes } from '@app/core/config/fileTypes';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { takeUntil } from 'rxjs/operators';
import cloneDeep from 'lodash-es/cloneDeep';
import { ProjectService } from '@app/core/project.service';
import { CustomModalService } from '@app/core/custom-modal.service';
import { OperatorService } from '@app/core/operator.service';
import { GroupService } from '@app/core/group.service';
import { Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { PASSWORD_REGEX, EMAIL_REGEX, ONLY_LETTERS_REGEX } from '@app/core/config/regExValidators';
import { UtilsService } from '@app/core/utils.service';
import { AlertsService } from '@app/core/alerts/alerts.service';
import { environment } from '@env/environment';
import { TeammateApiService, UpdateProfileResponse } from '@app/core/api/api.teammate';
import { MetrikaService, ANALYTICS_EVENT } from '@app/core/metrika.service';
import { FaceBookPixelService } from '@app/core/fbpixel.service';
import { GoogleTagService } from '@app/core/gtag.service';
import {Subject} from 'rxjs';

const kbyte = 1024;
const mbyte = kbyte * kbyte;

@Component({
  selector: 'app-client-edit',
  templateUrl: 'client-edit.component.html',
  styleUrls: ['client-edit.component.scss'],
  preserveWhitespaces: false
})
export class ClientEditComponent implements OnInit, OnDestroy, OnChanges {

  @Input() user: any;
  @Input() isOperator: boolean; // это роли не текущего пользователя, а того, что редактируется
  @Input() isOwner: boolean; // это роли не текущего пользователя, а того, что редактируется
  @Output() close: EventEmitter<boolean> = new EventEmitter();
  @Output() delete: EventEmitter<string> = new EventEmitter();

  public isOnlyRuLanguage = environment.features.onlyRuLanguage;
  public error: string;
  public projects: any;
  public profileForm: UntypedFormGroup;
  public errorAvatar: string | boolean;
  public errorsList: any = {};
  public availableLanguages: any = [];
  public loading = true;
  public timezones: any;
  public capture = 0;
  public showWebcamCapture = false;
  public newPhotoUploaded = false;
  public requestPending = false;
  public showEditorModal = false;

  public oldAvatar: string;
  public oldAvatarPosition: any;
  public oldAvatarZoom: number;

  public newAvatar: string;
  public tmpAvatar: string;
  public editedAvatar: string;

  public newAvatarCoordinates: string;
  public newAvatarZoom: string;

  public modalRef: BsModalRef;

  public uploadInput: EventEmitter<UploadInput> = new EventEmitter();

  private unsub$ = new Subject<void>();
  public dragOver: boolean;
  public hasProjects: boolean;
  public uploadVisible: boolean;
  public editAvatarVisible: boolean;
  public groups: any = [];
  public selectedIds: any = [];
  public userOrigin: any;
  public passwordFields: Array<any>;
  public emailFields: Array<any>;
  public passwordEditMode: boolean;
  public language: any;

  // @ts-ignore
  @ViewChild('avatarInput', { read: ElementRef }) avatarInput: ElementRef;
  // @ts-ignore
  @ViewChild('profileFormForm') profileFormForm: NgForm;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private i18nService: I18nService,
    private api: TeammateApiService,
    private timezone: TimezoneService,
    private userService: UserService,
    private detector: ChangeDetectorRef,
    private storage: StorageService,
    private modalService: BsModalService,
    private projectService: ProjectService,
    private dialog: CustomModalService,
    private operatorService: OperatorService,
    private groupService: GroupService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private utils: UtilsService,
    private alerts: AlertsService,
    private metrika: MetrikaService,
    private fbPixel: FaceBookPixelService,
    private gtag: GoogleTagService,
  ) {
    this.timezones = this.timezone.getZones();
    this.language = this.i18nService.translateService.translations[this.i18nService.language];

    each(i18nService.supportedLanguages, (v: string) => {
      this.availableLanguages.push({
        value: v,
        name: `lang.${v}`,
        flagUrl: `${window.document.location.protocol}//${window.document.location.host}/assets/flags/${v}.png`
      });
    });
  }

  static validateImageFile(output: UploadOutput): string | boolean | null {
    if (output.file.size > mbyte * 5) {
      return 'avatar.error.tooBig';
    }

    if (!AvatarMimeTypes.includes(output.file.type)) {
      return 'avatar.error.badExtension';
    }

    return true;
  }

  ngOnInit() {
    this.hasProjects = this.projectService.projects && this.projectService.projects.length > 0;
    this.loading = false;

    this.isOwner = this.user.roles[0].toLowerCase() === 'owner';
    this.userOrigin = cloneDeep(this.user);
    this.createForm();
    this.setPlaceHolderContent();
  }

  setPlaceHolderContent() {
    const ngSelect = document.getElementsByClassName('operator-select');
    if (ngSelect.length === 0) {
      setTimeout(() => {
        this.setPlaceHolderContent();
      }, 1000);
    } else {
      const ngSelectContainer = ngSelect[0].getElementsByClassName('ng-placeholder');
      ngSelectContainer[0].setAttribute('data-content', this.language.profile.searchPlaceHolder);
    }
  }

  bypass(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  unselect(id: string) {
    this.selectedIds = this.selectedIds.filter((selectedId) => {
      return selectedId !== id;
    });
  }

  ngOnDestroy() {
    this.unsub$.next();
this.unsub$.complete();
  }

  handleFieldBlur(field: string) {
    const fieldState = this.profileForm.get(field);
    this.errorsList[field] = !fieldState.valid && fieldState.value && fieldState.value.trim() !== '';
    if (field === 'name' && fieldState.value.trim() === '') {
      this.errorsList[field] = fieldState.value.trim() === '';
      this.profileForm.controls[field].setErrors({
        required: true
      });
    }
  }

  validateOnSubmit() {
    each(this.profileForm.controls, (control: UntypedFormControl, name: string) => {
      this.errorsList[name] = control.errors !== null;
    });
  }

  complete() {
    this.requestPending = false;
    if (!this.detector['destroyed']) {
      this.detector.detectChanges();
    }
  }

  isPasswordChanged(formValue: any) {
    return formValue.hasOwnProperty('password') && formValue.hasOwnProperty('passwordCurrent');
  }

  resetForm() {
    this.createForm();
    this.passwordEditMode = false;
    this.alerts.addAlert({
      type: 'success',
      content: this.language.successMessages.passwordChange
    });
  }

  updateProfile() {
    this.validateOnSubmit();

    if (this.profileForm.valid === false) {
      return;
    }

    this.error = '';
    this.requestPending = true;

    this.profileForm.controls.name.setValue(this.profileForm.value.name.trim());
    this.profileForm.controls.lastName.setValue(this.profileForm.value.lastName.trim());

    const formValue = cloneDeep(this.profileForm.value);
    const showPasswordChangeMessage = this.isPasswordChanged(formValue);

    if (!this.userOrigin.avatar || this.userOrigin.avatar.indexOf('base64') !== -1) {
      formValue.avatar = this.userOrigin.avatar;
      formValue.avatarZoom = this.userOrigin.avatarZoom || '';
      // eslint-disable-next-line max-len
      formValue.avatarCoordinates = this.userOrigin.avatarCoordinates ? JSON.stringify(this.userOrigin.avatarCoordinates) : '';
      formValue.avatarOriginal = this.userOrigin.avatarOriginal || '';
    }

    if (this.isOperator) {
      this.saveClient(formValue, true);
    } else {
      this.api.updateProfile(formValue)
        .pipe(takeUntil(this.unsub$))
        .subscribe((data: UpdateProfileResponse) => {
          if (data.errors && data.errors.length) {
            this.error = data.errors[0].message;
            return;
          }

          if (this.i18nService.language !== formValue.language) {
            this.i18nService.language = formValue.language;
            window.location.href = window.location.href;
          }

          formValue.avatar = formValue.avatar || this.userOrigin.avatar;
          each(formValue, (v: any, i: string) => {
            this.userService.setUserParam(i, v);
          });

          this.operatorService.updateOperatorsList();
          this.userService.getUser();

          if (showPasswordChangeMessage) {
            this.resetForm();
          }

          this.checkEmailUpdate(data);
        }, () => {}, this.complete.bind(this));
    }

  }

  createForm() {
    this.profileForm = this.formBuilder.group({
      avatar: '',
      avatarCoordinates: '',
      avatarZoom: '',
      avatarOriginal: '',
      email: [
        {
          value: this.user.email || '',
          disabled: !!this.user.email
        },
        Validators.compose([Validators.required, Validators.pattern(EMAIL_REGEX)])
      ],
      emailRepeat: [
        {
          value: this.user.email || '',
          disabled: true
        },
        Validators.pattern(EMAIL_REGEX)
      ],
      password: [
        {
          value: 'FishPas0',
          disabled: true
        },
        Validators.compose([Validators.required, Validators.pattern(PASSWORD_REGEX)])
      ],
      passwordCurrent: [
        {
          value: '',
          disabled: true
        },
        Validators.compose([Validators.required, Validators.pattern(PASSWORD_REGEX)])
      ],
      passwordRepeat: [
        {
          value: this.user.passwordRepeat || '',
          disabled: true
        }],
      name: [this.user.name || '',
        Validators.compose([Validators.required, Validators.pattern(ONLY_LETTERS_REGEX)])],
      lastName: [this.user.lastName || '', Validators.pattern(ONLY_LETTERS_REGEX)],
      timezone: [this.user.timezone || this.timezone.getFittingZone(), Validators.required],
      language: [this.user.language || this.i18nService.language, Validators.required],
      groups: '',
    });

    this.passwordFields = [{
      name: 'password',
      placeholder: 'password.self'
    }, {
      name: 'password',
      placeholder: 'password.new'
    }, {
      name: 'passwordRepeat',
      placeholder: 'password.confirm'
    }];

    if (this.isOwner || this.user.roles.includes('operator')) {
      this.passwordFields.splice(1, 0, {
        name: 'passwordCurrent',
        placeholder: 'password.current'
      });
    }

    this.emailFields = [{
      name: 'email',
      placeholder: 'email.self'
    }, {
      name: 'email',
      placeholder: 'email.new'
    }, {
      name: 'emailRepeat',
      placeholder: 'email.confirm'
    }];
  }

  extract(string: string) {
    return extract(string);
  }

  openFileSelector(): void {
    this.avatarInput.nativeElement.value = null;
    this.avatarInput.nativeElement.click();
  }

  onUploadOutput(output: UploadOutput, openModal: boolean = false, template?: any): void {
    if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
      const validImage = ClientEditComponent.validateImageFile(output);

      if (validImage !== true) {
        this.errorAvatar = validImage;
        this.cancelUpload(output.file.id);
        return;
      }

      const file = output.file;
      const reader = new FileReader;

      reader.onloadend = (e: ProgressEvent) => {
        const image = new Image();

        image.onload = () => {
          // @ts-ignore
          this.handleWebcamCapture(reader.result);

          if (openModal) {
            // @ts-ignore
            this.oldAvatar = reader.result;
            this.openModal(template);
          }

          this.errorAvatar = false;
        };

        // @ts-ignore
        image.src = reader.result;
        // @ts-ignore
        this.tmpAvatar = reader.result;
        // @ts-ignore
        this.oldAvatar = reader.result;
      };

      reader.readAsDataURL(file.nativeFile);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut' || output.type === 'drop') {
      this.dragOver = false;
    }

    this.detector.detectChanges();
  }

  cancelUpload(id: string, remove: boolean = true): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
    if (remove) {
      this.removeFile(id);
    }
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
    this.storage.remove(`pending-attachment-${id}`);

    this.detector.detectChanges();
  }

  /** */
  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }

  openModal(template: TemplateRef<any>) {
    if (this.isOperator) {
      this.uploadVisible = true;
    } else {
      this.modalRef = this.modalService.show(template, {
        backdrop: 'static',
        ignoreBackdropClick: true
      });
    }
    this.detector.detectChanges();
  }

  closeModal(): void {
    this.showEditorModal = false;
    this.showWebcamCapture = false;
    if (this.isOperator) {
      this.close.emit();
    } else {
      if (this.modalRef) {
        this.modalRef.hide();
      } else if (this.modalService) {
        // @ts-ignore: Accessing private variable as workaround for missing feature
        each(this.modalService.loaders || [], loader => loader.instance.hide());
      }
      this.dialog.close();
    }
    this.newPhotoUploaded = false;
    this.errorAvatar = false;
  }

  triggerCapture(): void {
    this.showWebcamCapture = true;
    this.capture++;
  }

  handleWebcamCapture(avatar: string) {
    this.newPhotoUploaded = true;
    this.tmpAvatar = avatar;
    this.oldAvatar = avatar;
    this.detector.markForCheck();
  }

  handlePhotoEdit(avatar: string) {
    this.editedAvatar = avatar;
  }

  setUserPhoto(): void {
    this.newAvatar = this.editedAvatar || this.tmpAvatar;
    this.oldAvatar = this.oldAvatar || this.tmpAvatar;

    this.profileForm.controls.avatarZoom.setValue(this.newAvatarZoom);
    this.profileForm.controls.avatarCoordinates.setValue(this.newAvatarCoordinates);
    this.profileForm.controls.avatarOriginal.setValue(this.tmpAvatar || '');
    this.showEditorModal = false;

    this.userOrigin.avatar = this.newAvatar;
    this.userOrigin.avatarZoom = this.newAvatarZoom;
    this.userOrigin.avatarCoordinates = this.newAvatarCoordinates;
    this.userOrigin.avatarOriginal = this.tmpAvatar || '';

    if (this.isOperator) {
      this.uploadVisible = false;
      this.editAvatarVisible = false;
    } else {
      this.closeModal();
    }
  }

  handleCoordinates(event: any): void {
    this.newAvatarCoordinates = event;
  }

  handleZoom(event: string): void {
    this.newAvatarZoom = event;
  }

  checkEmailUpdate(response: any) {
    if (response && response.success && response.data.updateEmail) {
      this.router.navigate(['profile/change-email']);
    }
  }

  saveClient(data: any, close: boolean) {
    this.api.clientUpdate(this.user.id, data)
      .pipe(takeUntil(this.unsub$))
      .subscribe((response: any) => {
        if (response.errors && response.errors.length) {
          this.error = response.errors[0].message;
          return;
        }
        this.operatorService.updateOperatorsList();
        this.userService.getUser();

        this.checkEmailUpdate(response);

        if (close) {
          this.closeModal();
        }
      }, () => {}, this.complete.bind(this));
  }

  deleteTeammate() {
    this.delete.emit(this.user.id);
  }

  successHandler(avatar: any, template: any) {
    if (avatar.avatarOriginal) {
      this.oldAvatar = avatar.avatarOriginal;
      this.oldAvatarPosition = JSON.parse(avatar.avatarCoordinates);
      this.oldAvatarZoom = avatar.avatarZoom;
      this.editAvatarVisible = true;
    }

    if (template) {
      this.modalRef = this.modalService.show(template, {
        backdrop: true,
        ignoreBackdropClick: true
      });
    }
    this.detector.detectChanges();
  }

  openPhotoEditor(template: TemplateRef<any>): void {
    this.showEditorModal = true;

    if (this.newPhotoUploaded) {
      this.tmpAvatar = this.tmpAvatar || this.oldAvatar;
      if (this.isOperator) {
        this.editAvatarVisible = true;
      } else {
        this.modalRef = this.modalService.show(template, {
          backdrop: true,
          ignoreBackdropClick: true
        });
      }

      this.detector.detectChanges();
      return;
    }

    if (this.isOperator) {
      this.api.getClientAvatar(this.user.id)
        .pipe(takeUntil(this.unsub$)).subscribe((response: any) => {
          this.successHandler(response.data, null);
        });
    } else {
      this.api.getUserAvatar().pipe(takeUntil(this.unsub$)).subscribe((response: any) => {
        this.successHandler(response.data, template);
      });
    }
  }

  deleteAvatar(): void {
    this.userOrigin.avatar = false;
    this.userOrigin.avatarZoom = '';
    this.userOrigin.avatarCoordinates = '';
    this.userOrigin.avatarOriginal = '';

    this.detector.markForCheck();
  }

  showError(errorName: string) {
    return this.errorsList && this.errorsList[errorName] ?
      this.profileForm.get(errorName).hasError('required') ||
      this.profileForm.get(errorName).hasError('pattern') :
      this.errorsList[errorName];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user) {
      this.user = changes.user.currentValue;
      this.createForm();
    }
  }

  sendAnalyticsEvent(event: ANALYTICS_EVENT, args: object = null) {
    this.metrika.goal(event, args);
    this.gtag.track(event, args);
    this.fbPixel.track(event);
  }
}
