import { Operator } from '@app/core/interfaces/operator';
import { Tag } from '@app/core/tags.service';

export interface InputMessage {
  trackBy: number;
  messages: Array<Message>;
}

export interface DateObject {
  date: string;
  timezone: string;
}

export interface FormContentButtons {
  buttonClass?: string;
  buttonName: string;
  buttonValue: string | boolean;
}

export interface FormContents {
  labelName: string;
  inputField?: string;
  inputValidation?: RegExp;
  buttons: Array<FormContentButtons>;
}

export interface SendFileEvent {
  file: string;
  type: string;
}

export interface UtmTag {
  utmCampaign: string;
  utmContent: string;
  utmMedium: string;
  utmSource: string;
  utmTerm: string;
}

export enum MessageProvider {
  Main = 10,
  PublicApi = 20,
  ExternalSystem = 30,
  TelegramBot = 40,
  Crm = 50,
  MobileApplication = 60,
  Bot = 70,
  WebWidgetAutoInvite = 80
}

export interface Message {
  id?: string;
  tempId?: string;
  appealId?: string;
  message?: string;
  attachment?: string;
  attachments?: Array<any>;
  attachmentUrl?: string;
  attachmentPreviewUrl?: string;
  attachmentId?: string;
  isOwn?: boolean;
  date?: DateObject;
  emojiOnly?: boolean;
  dateToDisplay?: string;
  avatar?: string;
  avatarDefault?: string;
  messageType?: 'text' | 'typing' | 'attachment' | 'image' | 'gif' | 'audio' | 'video' | 'internal';
  progress?: number;
  isForm?: boolean;
  formId?: string;
  formType?: 'rating' | 'boolean' | 'faq' | 'input' | 'contact';
  formContents?: FormContents;
  operator?: Operator | Array<any>;
  errorMessage?: string;
  width?: number;
  height?: number;
  from?: any;
  unread?: boolean;
  unanswered?: boolean;
  seen?: boolean;
  status?: string;
  size?: number;
  type?: number | string;
  hasTail?: boolean;
  newDay?: string;
  startDate?: any;
  dateString?: string;
  number?: number;
  provider?: MessageProvider;
  value?: any;
  parentAppealMessageId?: string;
  externalId?: string;
  childs?: Array<any>;
  childsCount?: number;
  isOpen?: boolean;
  appeal?: any;
  failedMessage?: string;
  /** цитируемое сообщение */
  repliedMessage?: Message | null;
  /** Внешний идентификатор источника сообщения */
  sourceExternalId?: string | null;
  /** Внешний идентификатор отправленного сообщения */
  sourceTemporaryId?: string | null;
}

export interface SocialProfile {
  id?: string;
  url?: string;
}

export interface HumanDates {
  lastOnlineAt?: string;
  firstMessageAt?: string;
  lastMessageAt?: string;
}

export interface PersonAmoProfile {
  url: string;
}

export interface PersonTelegramProfile {
  id?: string;
  url?: string;
  username?: string;
}

export interface Person {
  id: string;
  name: string;
  location: string;
  language: string;
  userAgent: string;
  userAgentVersion: string;
  devicePlatform: string;
  devicePlatformVersion: string;
  date: string;
  phone: string;
  email: string;
  avatar: string;
  avatarOriginal?: string;
  isOnline: boolean;
  lastOnlineAt?: string;
  notes: Array<any>;
  pagesHistory: Array<any>;
  referring: string;
  appealsHistory: Array<any>;
  banned: boolean;
  vk?: SocialProfile;
  phoneIsLocked?: boolean;
  averageRate?: any;
  firstMessageAt?: string;
  lastMessageAt?: string;
  utmCampaign?: any;
  humanDates?: HumanDates;
  emailIsLocked?: boolean;
  isCache?: boolean;
  info?: UtmTag;
  ip?: string;
  amo: PersonAmoProfile | null;
  telegramBot?: PersonTelegramProfile;
  telegram?: PersonTelegramProfile;
  tags: string[];
}
