import {
  Component, OnInit, OnChanges, OnDestroy, NgZone, ChangeDetectorRef, Input, Output, EventEmitter
} from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { OperatorService } from '@app/core/operator.service';
import { OperatorData } from '@app/core/interfaces/api.response';
import filter from 'lodash-es/filter';
import { NgSelectHelper } from '@app/shared/utils/ng-select-helper';
import { ShellResolver } from '@app/core/shell/shell.resolver';
import { UserService } from '@app/core/user.service';
import { ConversationApiService } from '@app/core/api/api.conversation';
import { TeammateApiService } from '@app/core/api/api.teammate';
import { Subject } from 'rxjs';
import { ProjectService } from '@app/core/project.service';

@Component({
  selector: 'app-client-remapping',
  templateUrl: 'client-remapping.component.html',
  styleUrls: ['client-remapping.component.scss'],
  preserveWhitespaces: false
})

export class ClientRemappingComponent implements OnInit, OnChanges, OnDestroy {

  @Input('user') user: any;
  @Input() isOperator: boolean;
  @Input() onlyRemapping: boolean;
  @Output() close: EventEmitter<boolean> = new EventEmitter();
  @Output() complete: EventEmitter<boolean> = new EventEmitter();
  @Output() cancel: EventEmitter<boolean> = new EventEmitter();

  private unsub$ = new Subject<void>();
  public operators: any;
  public detector: ChangeDetectorRef;
  public selectedOperatorId: string;
  public inProgress: boolean;

  constructor(
    private zone: NgZone,
    private api: TeammateApiService,
    private conversationApi: ConversationApiService,
    private operatorService: OperatorService,
    private shellResolver: ShellResolver,
    private userService: UserService,
    private projectService: ProjectService,
  ) {
    if (!this.user) {
      this.getUser();
    }
  }

  ngOnInit() {
    this.getOperatorsList();
  }

  ngOnChanges() {
    //
  }

  ngOnDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  getUser() {
    this.userService.getUserData().pipe(takeUntil(this.unsub$)).subscribe((user: any) => {
      if (!user) {
        return;
      }
      this.user = user;
    });
  }

  getOperatorsList(): void {
    this.zone.runOutsideAngular(() => {
      this.operatorService.getOperators()
        .pipe(takeUntil(this.unsub$)).subscribe((operators: OperatorData) => {
          if (operators) {
            this.operators = filter(operators, operator => operator.id !== this.user.id);
            if (this.detector && !this.detector['destroyed']) {
              this.detector.detectChanges();
            }
          }
        });
    });
  }

  closeModal(): void {
    this.close.emit();
  }

  deleteClient() {
    this.api.clientDelete(this.user.id)
      .pipe(takeUntil(this.unsub$))
      .subscribe((response: any) => {
        if (response.success) {
          this.operatorService.updateOperatorsList();
          this.projectService.updateProject();
        }
      }, () => {}, () => {
        this.inProgress = false;
        this.close.emit();
      });
  }

  deleteTeammate() {
    if (this.inProgress || !this.user.id) {
      return;
    }
    let success = false;
    this.inProgress = true;
    const data = {
      clientId: this.user.id,
      clientAppointedId: this.selectedOperatorId || null
    };
    this.conversationApi.appealsRemapping(data)
      .pipe(takeUntil(this.unsub$))
      .subscribe((response: any) => {
        if (response.success) {
          success = true;
          this.operatorService.updateOperatorsList();
          this.doDelete();
        }
      }, () => {}, () => {
        if (!success) {
          this.inProgress = false;
        }
      });
  }

  doDelete() {
    if (!this.onlyRemapping) {
      this.deleteClient();
    } else {
      this.complete.emit(true);
      this.close.emit();
    }
  }

  deleteCancel() {
    this.cancel.emit();
  }

  onClose() {
    NgSelectHelper.onSelectBlur('selectItem');
  }
}
