import { Component } from '@angular/core';
import { ApiService } from '@app/core/api/api.service';
import { routes } from '@app/core/config/apiRoutes';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-elama-login-button',
  templateUrl: './elama-login-button.component.html',
  styleUrls: ['./elama-login-button.component.scss'],
  standalone: true,
  imports: [
    TranslateModule
  ]
})
export class ElamaLoginButtonComponent {
  constructor(private api: ApiService) {}

  public loginWithElama() {
    this.api.httpGet(routes.elamaGetLink).subscribe((res) => {
      window.location.href = res.data.authorizationUrl;
    })
  }
}
