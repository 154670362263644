<ng-template #editAvatarTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'profile.editAvatarTitle' | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <i class="teletype-icon-close"></i>
    </button>
  </div>
  <div class="modal-body uploader-modal">
    <div class="photo-editor-container" *ngIf="tmpAvatar || oldAvatar">
      <app-alert *ngIf="errorAvatar" [alertContent]="errorAvatar | translate"></app-alert>
      <app-photo-editor [photo]="tmpAvatar || oldAvatar"
                        (delete)="deleteAvatar(); closeModal(); openModal(template)"
                        [defaultPosition]="newAvatarCoordinates || oldAvatarPosition"
                        [defaultZoom]="newAvatarZoom || oldAvatarZoom || 1"
                        (coordinates)="handleCoordinates($event)"
                        (zoom)="handleZoom($event)"
                        (result)="handlePhotoEdit($event)">
      </app-photo-editor>
      <app-webcam *ngIf="showWebcamCapture"
                  (captureReady)="newPhotoUploaded = false"
                  (capture)="handleWebcamCapture($event)"
                  [captureTrigger]="capture">
      </app-webcam>
    </div>
  </div>
  <div class="modal-footer clearfix">
    <div class="pull-left">
      <button type="button"
              (click)="openFileSelector()"
              class="btn btn-default">
        {{ 'profile.btnSelectFile' | translate }}
      </button>
      <button type="button" (click)="triggerCapture()" class="btn btn-default">
        {{ 'profile.btnTakePhoto' | translate }}
      </button>
    </div>
    <div class="pull-right">
      <button type="button" class="btn btn-primary btn-done" (click)="setUserPhoto()">
        {{ 'button.done' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'profile.uploadAvatarTitle' | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <i class="teletype-icon-close"></i>
    </button>
  </div>
  <div class="modal-body uploader-modal">
    <app-alert *ngIf="errorAvatar" [alertContent]="errorAvatar | translate"></app-alert>
    <div class="dropzone-container"
         ngFileDrop
         (uploadOutput)="onUploadOutput($event)"
         [uploadInput]="uploadInput"
         [ngClass]="{ 'is-drop-over': dragOver }"
         *ngIf="!showWebcamCapture && !newPhotoUploaded">
      <app-dashed-rectangle></app-dashed-rectangle>
      <div class="inner">
        <i class="finger-down"></i>
        <span>{{ 'profile.dragAndDrop' | translate }}
          <br />{{ dragOver && 'profile.file' || 'profile.photo' | translate }} {{ 'profile.here' | translate }}
        </span>
      </div>
    </div>
    <div class="photo-editor-container" *ngIf="showWebcamCapture || newPhotoUploaded">
      <app-webcam *ngIf="showWebcamCapture"
                  (captureReady)="newPhotoUploaded = false"
                  (capture)="handleWebcamCapture($event)"
                  [captureTrigger]="capture">
      </app-webcam>
      <app-photo-editor *ngIf="newPhotoUploaded && tmpAvatar"
                        (delete)="deleteAvatar(); closeModal(); openModal(template)"
                        [photo]="tmpAvatar"
                        (coordinates)="handleCoordinates($event)"
                        (zoom)="handleZoom($event)"
                        (result)="handlePhotoEdit($event)">
      </app-photo-editor>
    </div>
    <p translate *ngIf="!newPhotoUploaded">{{ 'profile.photoWarning' | translate }}</p>
  </div>
  <div class="modal-footer clearfix">
    <div class="pull-left">
      <button type="button"
              (click)="openFileSelector()"
              class="btn btn-default">
        {{ 'profile.btnSelectFile' | translate }}
      </button>
      <button type="button" (click)="triggerCapture()" class="btn btn-default">
        {{ 'profile.btnTakePhoto' | translate }}</button>
    </div>
    <div class="pull-right">
      <button type="button"
              class="btn btn-primary"
              [disabled]="!newPhotoUploaded"
              (click)="setUserPhoto()">
        {{ 'button.done' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<div *ngIf="editAvatarVisible && isOperator">
  <div class="modal-body uploader-modal p-0">
    <div class="photo-editor-container" *ngIf="showEditorModal && oldAvatar">
      <app-alert *ngIf="errorAvatar" [alertContent]="errorAvatar | translate"></app-alert>
      <app-photo-editor [photo]="oldAvatar"
                        (delete)="deleteAvatar(); editAvatarVisible = false; newPhotoUploaded = false; openModal(template);"
                        [defaultPosition]="newAvatarCoordinates || oldAvatarPosition"
                        [defaultZoom]="newAvatarZoom || oldAvatarZoom || 1"
                        (coordinates)="handleCoordinates($event)"
                        (zoom)="handleZoom($event)"
                        (result)="handlePhotoEdit($event)">
      </app-photo-editor>
      <app-webcam *ngIf="showWebcamCapture"
                  (captureReady)="newPhotoUploaded = false"
                  (capture)="handleWebcamCapture($event)"
                  [captureTrigger]="capture">
      </app-webcam>
    </div>
  </div>
  <div class="modal-footer clearfix pl-0 pr-0">
    <div class="pull-left">
      <button type="button"
              (click)="openFileSelector()"
              class="btn btn-default">
        {{ 'profile.btnSelectFile' | translate }}
      </button>
      <button type="button" (click)="triggerCapture()" class="btn btn-default">
        {{ 'profile.btnTakePhoto' | translate }}
      </button>
    </div>
    <div class="pull-right">
      <button type="button" class="btn btn-primary btn-done" (click)="setUserPhoto()">
        {{ 'button.done' | translate }}
      </button>
    </div>
  </div>
</div>

<div *ngIf="uploadVisible && isOperator">
  <div class="uploader-modal">
    <app-alert *ngIf="errorAvatar" [alertContent]="errorAvatar | translate"></app-alert>
    <div class="dropzone-container"
         ngFileDrop
         (uploadOutput)="onUploadOutput($event)"
         [uploadInput]="uploadInput"
         [ngClass]="{ 'is-drop-over': dragOver }"
         *ngIf="!showWebcamCapture && !newPhotoUploaded">
      <app-dashed-rectangle></app-dashed-rectangle>
      <div class="inner">
        <i class="finger-down"></i>
        <span>{{ 'profile.dragAndDrop' | translate }}
          <br />{{ dragOver && 'profile.file' || 'profile.photo' | translate }} {{ 'profile.here' | translate }}
        </span>
      </div>
    </div>
    <div class="photo-editor-container" *ngIf="showWebcamCapture || newPhotoUploaded">
      <app-webcam *ngIf="showWebcamCapture"
                  (captureReady)="newPhotoUploaded = false"
                  (capture)="handleWebcamCapture($event)"
                  [captureTrigger]="capture">
      </app-webcam>
      <app-photo-editor *ngIf="newPhotoUploaded && tmpAvatar"
                        (delete)="deleteAvatar(); uploadVisible = false; newPhotoUploaded = false; openModal(template);"
                        [photo]="tmpAvatar"
                        (coordinates)="handleCoordinates($event)"
                        (zoom)="handleZoom($event)"
                        (result)="handlePhotoEdit($event)">
      </app-photo-editor>
    </div>
    <p translate *ngIf="!newPhotoUploaded">{{ 'profile.photoWarning' | translate }}</p>
  </div>
  <div class="modal-footer pl-0 pr-0 clearfix">
    <div class="pull-left">
      <button type="button"
              (click)="openFileSelector()"
              class="btn btn-default">
        {{ 'profile.btnSelectFile' | translate }}
      </button>
      <button type="button" (click)="triggerCapture()" class="btn btn-default">
        {{ 'profile.btnTakePhoto' | translate }}
      </button>
    </div>
    <div class="pull-right">
      <button type="button"
              class="btn btn-primary"
              [disabled]="!newPhotoUploaded"
              (click)="setUserPhoto()">
        {{ 'button.done' | translate }}
      </button>
    </div>
  </div>
</div>

<form class="profile-update-form"
      (submit)="updateProfile()"
      [formGroup]="profileForm"
      autocomplete="off"
      #profileFormForm="ngForm"
      novalidate
      [hidden]="(uploadVisible || editAvatarVisible) && isOperator">
  <div class="avatar-container">
    <div class="avatar-inner-container"
         ngFileDrop
         (uploadOutput)="onUploadOutput($event, true, editAvatarTemplate)"
         [uploadInput]="uploadInput"
         [ngClass]="{ 'is-drop-over': dragOver, 'avatar-chosen': userOrigin.avatar }">
      <app-avatar [size]="100"
                  (click)="userOrigin.avatar && openPhotoEditor(editAvatarTemplate)"
                  [fontSize]="42"
                  [avatar]="userOrigin.avatar || user.avatarDefault">
      </app-avatar>
      <button type="button" *ngIf="userOrigin.avatar" class="delete-avatar" (click)="deleteAvatar()">
        <i class="teletype-icon-close"></i>
      </button>
    </div>
    <p class="muted">JPG {{ 'profile.or' | translate }} PNG, {{ 'profile.min' | translate }} 100 x 100 {{ 'profile.px' |
      translate }}, {{ 'profile.upTo' | translate }} 5 Mb</p>
    <p class="upload-instructions">
      <a class="like-a" translate *ngIf="userOrigin.avatar" (click)="openPhotoEditor(editAvatarTemplate)">
        {{ 'profile.editPhoto' | translate }}
      </a>
      <span *ngIf="userOrigin.avatar">&ngsp;{{ 'profile.or' | translate }}&ngsp;</span>
      <span *ngIf="!userOrigin.avatar">{{ 'profile.dragAndDropMessage' | translate }}</span>&ngsp;
      <a class="like-a" (click)="newPhotoUploaded = false; openModal(template)">
        {{ 'profile.uploadPhoto' | translate }}
      </a>
    </p>
    <input type="file" ngFileSelect [uploadInput]="uploadInput" (uploadOutput)="onUploadOutput($event)" #avatarInput />
  </div>

  <ng-container *ngIf="!user.hasExternalAuth">
    <div
      *appComparable="let fieldData of emailFields from profileForm; let editMode = editMode; let doCompare = doCompare"
      class="form-group"
      [ngClass]="{'filled': profileForm.get(fieldData.name).value, 'error': errorsList[fieldData.name]}">
      <input class="form-control"
             formControlName="{{fieldData.name}}"
             autocomplete="off"
             [placeholder]="fieldData.placeholder | translate"
             (blur)="handleFieldBlur(fieldData.name)"
             required />
      <span class="floating-label" translate>{{fieldData.placeholder}}</span>
      <span *ngIf="profileForm.get(fieldData.name).hasError('required')" class="form-group-error">
      {{ 'email.error.blankNew' | translate }}
    </span>
      <span *ngIf="profileForm.get(fieldData.name).hasError('notEqual')" class="form-group-error">
      {{ 'email.error.matchNew' | translate }}
    </span>
      <span *ngIf="profileForm.get(fieldData.name).hasError('pattern')" class="form-group-error">
      {{ 'email.error.valid' | translate:{email: profileForm.get(fieldData.name).value} }}
    </span>
      <a [hidden]="editMode || !profileForm.get(fieldData.name).value"
         (click)="doCompare(true)"
         class="comparable-edit-btn like-a" href="">{{ 'email.edit' | translate }}</a>
    </div>

    <div
      *appComparable="let fieldData of passwordFields from profileForm;
    let doCompare = doCompare;"
      class="form-group"
      [ngClass]="{'filled': profileForm.get(fieldData.name).value, 'error': errorsList[fieldData.name]}">
      <input class="form-control"
             [class.pass]="fieldData.name === 'password'"
             formControlName="{{ fieldData.name }}"
             autocomplete="off"
             type="password"
             [placeholder]="fieldData.placeholder | translate"
             (blur)="handleFieldBlur(fieldData.name)"
             [reliability]="fieldData.name === 'password'"
             required />
      <span class="floating-label">{{ fieldData.placeholder | translate }}</span>
      <span *ngIf="profileForm.get(fieldData.name).hasError('required') && fieldData.name === 'passwordCurrent'"
            class="form-group-error">
      {{ 'password.error.current' | translate }}
    </span>
      <span *ngIf="profileForm.get(fieldData.name).hasError('required') && fieldData.name === 'password'"
            class="form-group-error">
      {{ 'password.error.blankNew' | translate }}
    </span>
      <span *ngIf="profileForm.get(fieldData.name).hasError('notEqual')" class="form-group-error">
      {{ 'password.error.matchNew' | translate }}
    </span>
      <span *ngIf="profileForm.get(fieldData.name).hasError('pattern')" class="form-group-error">
      {{ 'password.error.valid' | translate }}
    </span>
      <a [hidden]="passwordEditMode"
         (click)="doCompare(true); passwordEditMode = true"
         class="comparable-edit-btn like-a">{{ 'password.edit' | translate }}</a>
    </div>
  </ng-container>

  <div class="form-group"
       [ngClass]="{
          'filled': profileForm.value.name,
          'error': showError('name')
        }">
    <input appAutofocus
           [skip]="profileForm.value.name"
           type="text"
           class="form-control"
           formControlName="name"
           autocomplete="off"
           [placeholder]="'placeHolder.firstName' | translate"
           (blur)="handleFieldBlur('name')"
           required />
    <span class="floating-label">{{ 'placeHolder.firstName' | translate }}</span>
    <span class="form-group-error" *ngIf="profileForm.get('name').hasError('required')">
      {{ 'error.firstName' | translate }}
    </span>
    <span class="form-group-error" *ngIf="profileForm.get('name').hasError('pattern')">
      {{ 'error.onlyLetter' | translate }}
    </span>
  </div>

  <div class="form-group" [ngClass]="{'filled': profileForm.value.lastName, 'error': showError('lastName')}">
    <input type="text"
           class="form-control"
           formControlName="lastName"
           autocomplete="off"
           [placeholder]="'placeHolder.lastName' | translate"
           (blur)="handleFieldBlur('lastName')" />
    <span class="floating-label">{{ 'placeHolder.lastName' | translate }}</span>
    <span class="form-group-error">
    {{ profileForm.get('lastName').hasError('pattern') && 'error.onlyLetter' | translate }}</span>
  </div>

  <div class="form-group"
       [hidden]="isOperator"
       [ngClass]="{'filled': profileForm.value.timezone, 'error': errorsList.timezone}">
    <ng-select class="ng-custom-select"
               formControlName="timezone"
               bindLabel="name"
               bindValue="value"
               [clearable]="false"
               [items]="timezones"
               placeholder="{{ 'placeHolder.timeZone' | translate }}"
               (blur)="handleFieldBlur('timezone')">
      <ng-template ng-option-tmp let-item="item">
        <div class="name">{{ item.name | translate }}</div>
      </ng-template>
      <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
        <div class="ng-option disabled">
          {{ 'crm.notFound' | translate }}
        </div>
      </ng-template>
    </ng-select>
    <span class="form-group-error">
    {{ profileForm.get('timezone').hasError('required') && 'error.timeZone' | translate }}</span>
  </div>

  <div class="form-group"
       [hidden]="isOperator || isOnlyRuLanguage"
       [ngClass]="{'filled': profileForm.value.language, 'error': errorsList.language}">
    <ng-select class="ng-custom-select"
               formControlName="language"
               bindLabel="name"
               bindValue="value"
               [clearable]="false"
               [items]="availableLanguages"
               placeholder="{{ 'profile.selectLanguage' | translate }}"
               (blur)="handleFieldBlur('language')">
      <ng-template ng-label-tmp let-item="item">
        <img class="flag" [src]="bypass(item.flagUrl)" [alt]="item.value">
        <div class="name">{{ item.name | translate }}</div>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        <img class="flag" [src]="bypass(item.flagUrl)" [alt]="item.value">
        <div class="name">{{ item.name | translate }}</div>
      </ng-template>
      <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
        <div class="ng-option disabled">
          {{ 'crm.notFound' | translate }}
        </div>
      </ng-template>
    </ng-select>
    <span class="form-group-error">
    {{ profileForm.get('language').hasError('required') && 'error.role' | translate }}</span>
  </div>

  <app-alert *ngIf="error" [alertContent]="error"></app-alert>

  <div class="buttons-container">
    <button class="btn btn-primary with-spinner" type="submit" [disabled]="requestPending">
      <app-spinner [inProgress]="requestPending" [btnText]="'button.saveChanges' | translate"></app-spinner>
    </button>
  </div>

  <button class="btn btn-delete"
          type="button"
          (click)="deleteTeammate()"
          *ngIf="isOperator && !isOwner">
    <span>{{ 'profile.btnDeleteTeammate' | translate }}</span>
  </button>
</form>
