import { NgModule } from '@angular/core';
import { AvatarComponent } from '@app/shared/avatar/avatar/avatar.component';
import { CommonModule } from '@angular/common';
import { GroupAvatarComponent } from '@app/shared/avatar/group-avatar/group-avatar.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    AvatarComponent,
    GroupAvatarComponent
  ],
  exports: [
    AvatarComponent,
    GroupAvatarComponent
  ]
})
export class AvatarModule { }
