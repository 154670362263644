import { BehaviorSubject, interval, Observable, of, Subject, timer } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

const TIME_FADE_OUT_ANIMATION = 300;
const TIME_FADE_IN_ANIMATION = 300;
const TIME_SHIFT_ANOTHER_TOAST_ANIMATION = 300;
const DEFAULT_TIME_TOAST_AUTO_CLOSE = 5000;
const TIME_BEFORE_START_AUTO_CLOSE = TIME_FADE_IN_ANIMATION + TIME_SHIFT_ANOTHER_TOAST_ANIMATION;

export enum ToastType {
  Success,
  Error,
}

export interface ToastConfig {
  title: string;
  text: string;
  type: ToastType;
  time?: number;
  disableAutoClose?: boolean;
}

export class Toast implements ToastConfig {

  private timerOfAutoClose = null;

  public title: string;
  public text: string;
  public type: ToastType;
  public time: number;
  public disableAutoClose: boolean = false;

  public isReadyForAppear = new BehaviorSubject(true);
  public isReadyForDelete = new Subject();

  public progress: Observable<number>;

  public readonly onDelete$ = this.isReadyForDelete.pipe(
    tap(() => {
      // для оптимизации, так как of сразу завершится
      this.progress = of(100);
    }),
    delay(TIME_FADE_OUT_ANIMATION),
  );

  public get colorProgressBar() {
    switch (this.type) {
      case ToastType.Success:
        return '#00C200';
      case ToastType.Error:
        return '#EE6953';
      default:
        return '#0a86f9';
    }
  }

  public get icon() {
    switch (this.type) {
      case ToastType.Success:
        return 'check-mark-button';
      case ToastType.Error:
        return 'prohibited';

      default:
        return 'prohibited'; // сделать, например, info
    }
  }

  constructor(toastConfig: ToastConfig) {
    this.title = toastConfig.title;
    this.text = toastConfig.text;
    this.type = toastConfig.type;
    this.time = toastConfig.time || DEFAULT_TIME_TOAST_AUTO_CLOSE;
    this.disableAutoClose = toastConfig.disableAutoClose;

    const timerForAppearance = setTimeout(() => {
      this.isReadyForAppear.next(false);

      if (!this.disableAutoClose) {
        this.startTimerOfClose(this.time);
      }

      clearTimeout(timerForAppearance);
    }, TIME_BEFORE_START_AUTO_CLOSE);
  }

  private startTimerOfClose(time: number) {
    this.timerOfAutoClose = setTimeout(this.closeToast.bind(this), time);
    const TIME_BETWEEN_PERCENT_OF_PROGRESS_BAR = time / 100;
    this.progress = interval(TIME_BETWEEN_PERCENT_OF_PROGRESS_BAR);
  }

  public closeToast() {
    this.isReadyForDelete.next(true);
    clearTimeout(this.timerOfAutoClose);
  }
}
