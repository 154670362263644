import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ChannelService } from '@app/core/channel.service';
import { ChannelData, OperatorData } from '@app/core/interfaces/api.response';
import { OperatorService } from '@app/core/operator.service';
import { merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

const checkNameIsMatch = (operator: OperatorData, search: string): boolean => {
  const fullName = `${operator.name} ${operator.lastName}`.toLowerCase();
  const fullNameReverse = `${operator.lastName} ${operator.name}`.toLowerCase();
  // удаляем лишние пробелы и уменьшаем буквы
  const formattedSearch = search.split(' ').filter(n => n).join(' ').toLowerCase();

  return fullName.includes(formattedSearch) || fullNameReverse.includes(formattedSearch)
}

export interface MarkOperatorsAsContext {
  operators: string[];
  mark: string;
}

@Component({
  selector: 'app-settings-select-operator',
  templateUrl: './select-operator.component.html',
  styleUrls: ['./select-operator.component.scss'],
})
export class SelectOperatorComponent {

  operators = this.operatorService.operators;
  allOperators = this.operatorService.allOperators;

  focus = new Subject<string>();

  public modelForAllOperator: any = '';

  @Input() searchOnlyIn: string[];
  @Input() excluded: string[];
  @Input() markElementsAs: MarkOperatorsAsContext;
  @Input() maxLength: number;

  @Input() clientIds: UntypedFormArray;
  @Output() clientIdsChange: EventEmitter<UntypedFormArray> = new EventEmitter();

  @Output() addOperator: EventEmitter<string> = new EventEmitter();
  @Output() removeOperator: EventEmitter<string> = new EventEmitter<string>();

  get clientIdsForm() {
    return this.clientIds.controls as UntypedFormControl[]
  }

  constructor(
    private operatorService: OperatorService,
  ) {}

  formatter = (operator: OperatorData) => operator.name;

  useOperator(operator): void {
    const control = new UntypedFormControl(operator.id)
    this.clientIds.push(control);
    this.clientIdsChange.emit(this.clientIds);
    this.addOperator.emit(operator.id)
  }

  selectOperator(event) {
    event.preventDefault();
    (document.activeElement as HTMLElement).blur();
    this.useOperator(event.item);
    this.modelForAllOperator = '';
  }

  deleteOperator(index: number) {
    const operatorId = this.clientIds.at(index).value;
    this.clientIds.removeAt(index);
    this.clientIdsChange.emit(this.clientIds);
    this.removeOperator.emit(operatorId);
  }

  getOperatorsById(operatorId: string): OperatorData {
    return this.allOperators.find(operator => operator.id === operatorId);
  }

  search: OperatorFunction<string, readonly OperatorData[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
    );
    const inputFocus$ = this.focus;

    return merge(debouncedText$, inputFocus$).pipe(
      map(searchText => {
        const usedOperatorsId = this.clientIds.value;

        let availableOperators;

        if (this.searchOnlyIn) {
          availableOperators = this.operators.filter(
            operator => this.searchOnlyIn.includes(operator.id) && !usedOperatorsId.includes(operator.id)
          );
        } else if (this.excluded) {
          availableOperators = this.operators.filter(
            operator => !this.excluded.includes(operator.id) && !usedOperatorsId.includes(operator.id)
          );
        } else {
          availableOperators = this.operators.filter(operator => !usedOperatorsId.includes(operator.id))
        }

        return availableOperators.filter(operator => checkNameIsMatch(operator, searchText));
      })
    )
  }

  isShowMark(clientId: string): boolean {
    if (!this.markElementsAs) {
      return false;
    }
    return this.markElementsAs.operators.includes(clientId);
  }
}
