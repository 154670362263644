import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { LanguageGuard } from '@app/core/language.guard';
import { RegistrationComponent } from './registration/registration.component';
import { AccountSetupComponent } from './account-setup/account-setup.component';
import { ConfirmCodeComponent } from './confirm-code/confirm-code.component';
import { RegisterResolver } from './register.resolver';
import { RegisterCompleteResolver } from './register-complete.resolver';
import { InviteComponent } from './invite/invite.component';
import { InviteResolver } from './invite.resolver';
import { AuthorizedGuard } from '@app/core/authentication/authorized.guard';
import { CompleteComponent } from './complete/complete.component';
import { AccountSetupProfileComponent } from '@app/register/account-setup-profile/account-setup-profile.component';
import { MobileWarnComponent } from './mobile-warn/mobile-warn.component';

const routes: Routes = [
  {
    path: 'register',
    component: RegistrationComponent,
    data: {
      title: extract('Register'),
      bodyId: 'new_registration',
      bodyClass: 'login',
    },
    canActivate: [AuthorizedGuard, LanguageGuard]
  },
  {
    path: 'registration/email-confirm/:token',
    component: ConfirmCodeComponent,
    data: {
      title: extract('Confirm email'),
      bodyId: 'new_email_confirm',
      bodyClass: 'login',
    },
    resolve: {
      customerData: RegisterResolver
    },
    canActivate: [AuthorizedGuard, LanguageGuard]
  },
  {
    path: 'registration/completion/:token',
    component: AccountSetupComponent,
    data: {
      title: extract('Setup your account'),
      bodyId: 'new_account_setup',
      bodyClass: 'login',
    },
    canActivate: [AuthorizedGuard, LanguageGuard]
  },
  {
    path: 'registration/profile/:token',
    component: AccountSetupProfileComponent,
    data: {
      title: extract('Setup your account'),
      bodyId: 'new_account_setup',
      bodyClass: 'login',
      isAdmin: true
    },
    resolve: {
      customerData: RegisterCompleteResolver
    },
    canActivate: [AuthorizedGuard, LanguageGuard]
  },
  {
    path: 'client/invite/:token',
    component: InviteComponent,
    data: {
      title: extract('Setup your account'),
      bodyId: 'new_account_setup',
      bodyClass: 'login',
      isAdmin: false
    },
    resolve: {
      customerData: InviteResolver
    },
    canActivate: [LanguageGuard]
  },
  {
    path: 'registration/complete',
    component: CompleteComponent,
    data: {
      title: extract('Complete your registration'),
      bodyId: 'new_account_setup',
      bodyClass: 'login',
      isAdmin: true
    },
    canActivate: [LanguageGuard]
  },
  {
    path: 'registration/mobile-warn',
    component: MobileWarnComponent,
    data: {
      title: extract('warning'),
      bodyId: 'new_registration',
      bodyClass: 'login',
      isAdmin: true
    },
    canActivate: [LanguageGuard]
  },
  {
    path: 'registration/confirm/:token',
    redirectTo: 'conversations/open/open',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class RegisterRoutingModule { }
