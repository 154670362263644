import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  OnChanges,
} from '@angular/core';
import { each } from 'lodash-es';
import { UntypedFormGroup } from '@angular/forms';
import EqualValidator from '../../validators/equal.validator';

interface FieldData {
  name: string;
  placeholder: string;
}

@Directive({
  selector: '[appComparable]'
})
export class ComparableDirective implements OnInit, OnChanges {
  private editMode = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
  }

  @Input() private appComparableOf: Array<FieldData>;
  @Input() private appComparableFrom: UntypedFormGroup;

  ngOnInit() {
  }

  ngOnChanges() {
    this.render();
  }

  editableFields(): void {
    this.editMode = true;
    this.appComparableOf.shift();
    each(this.appComparableOf, (field) => {
      const control = this.appComparableFrom.get(field.name);

      control.enable();
      control.reset();
    });
    const fieldsLen = this.appComparableOf.length;

    this.appComparableFrom.setValidators(
      EqualValidator.validate(this.appComparableOf[fieldsLen - 2].name, this.appComparableOf[fieldsLen - 1].name)
    );
  }

  render(printAll?: boolean): boolean {
    this.viewContainer.clear();

    if (!this.appComparableOf.length) {
      return;
    }

    if (printAll) {
      this.editableFields();
    }

    each(this.appComparableOf, (field) => {
      this.viewContainer.createEmbeddedView(this.templateRef, {
        $implicit: field,
        editMode: this.editMode,
        doCompare: this.render.bind(this)
      });

      if (!printAll) {
        return false;
      }
    });

    return false;
  }
}
