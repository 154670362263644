import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { take, map } from 'rxjs/operators';
import { I18nService } from '@app/core/i18n.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageGuard implements CanActivate {

  constructor(
    private i18nService: I18nService,
  ) { }

  canActivate() {
    return this.i18nService.translateService.getTranslation(this.i18nService.language)
      .pipe(map(() => true), take(1));
  }
}
