import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  AfterContentChecked,
  NgZone
} from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';

import css from 'stylefire';

@Directive({
  selector: '[appPsInfiniteScroll]'
})
export class PsInfiniteScrollDirective implements AfterContentChecked, OnChanges {

  @Input() itemsCount = 0;
  @Input() scrollOffset = 200;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('appPsInfiniteScroll') appPsInfiniteScroll: NgScrollbar;
  @Input() checkForUserScroll = false;

  @Output() appPsInfiniteScrollTrigger = new EventEmitter();
  @Output() userScrolled = new EventEmitter();

  private elementRef: ElementRef;
  private cssInstance: any;

  @HostListener('ps-scroll-y') OnYAxisScroll() {
    this.calculateBottomOffset();
  }
  @HostListener('scroll') OnNativeScroll() {
    if (this.appPsInfiniteScroll === undefined) {
      this.calculateBottomOffsetNative();
    }
  }

  constructor(
    elementRef: ElementRef,
    private zone: NgZone
  ) {
    this.elementRef = elementRef;
    this.cssInstance = css(this.elementRef.nativeElement, {});
  }

  calculateBottomOffset() {
    this.zone.runOutsideAngular(() => {
      if (this.appPsInfiniteScroll === undefined) {
        return;
      }
      const viewport = this.appPsInfiniteScroll.viewport;
      const containerHeight = viewport.clientHeight;
      const scrollHeight = viewport.scrollHeight;
      const scrolledFromTop = viewport.scrollTop;
      const spaceToBottom = Math.round(scrollHeight - containerHeight - scrolledFromTop);

      if (spaceToBottom <= this.scrollOffset) {
        this.appPsInfiniteScrollTrigger.emit();
      }

      if (this.checkForUserScroll) {
        this.userScrolled.emit(spaceToBottom >= 4);
      }
    });
  }

  calculateBottomOffsetNative() {
    this.zone.runOutsideAngular(() => {
      const containerHeight = Math.round(this.cssInstance.get('height'));
      const geometry = {
        h: this.elementRef.nativeElement.scrollHeight,
        y: this.elementRef.nativeElement.scrollTop
      };
      const scrolledFromTop = geometry.h - geometry.y;
      const spaceToBottom = Math.round(scrolledFromTop - containerHeight);

      if (spaceToBottom <= this.scrollOffset) {
        this.appPsInfiniteScrollTrigger.emit();
      }
      if (this.checkForUserScroll) {
        this.userScrolled.emit(spaceToBottom >= 4);
      }
    });
  }

  ngAfterContentChecked(): void {
    this.calculateBottomOffset();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calculateBottomOffset();
  }
}
