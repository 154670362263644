import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElamaLoginButtonComponent } from '@app/shared/elama-login-button/elama-login-button.component';
import { RegistrationComponent } from './registration/registration.component';
import { ConfirmCodeComponent } from './confirm-code/confirm-code.component';
import { AccountSetupComponent } from './account-setup/account-setup.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RegisterRoutingModule } from './register-routing.module';
import { AlertsComponentModule } from '@app/shared/alerts/alerts.module';
import { SharedModule } from '@app/shared';
import { ReliabilityDirectiveModule } from '@app/core/directives/reliability/reliability.module';
import { InviteComponent } from './invite/invite.component';
import { CompleteComponent } from './complete/complete.component';
import { CoreModule } from '@app/core/core.module';
import { AccountSetupProfileComponent } from '@app/register/account-setup-profile/account-setup-profile.component';
import { MobileWarnComponent } from './mobile-warn/mobile-warn.component';

@NgModule({
  declarations: [
    RegistrationComponent,
    ConfirmCodeComponent,
    AccountSetupComponent,
    AccountSetupProfileComponent,
    InviteComponent,
    CompleteComponent,
    MobileWarnComponent,
  ],
  imports: [
      CommonModule,
      ReactiveFormsModule,
      TranslateModule,
      RegisterRoutingModule,
      AlertsComponentModule,
      SharedModule,
      ReliabilityDirectiveModule,
      CoreModule,
      ElamaLoginButtonComponent
  ]
})
export class RegisterModule { }
